import { FC, ReactNode } from 'react';
import { ObPill } from '../../elements/ob-pill/ob-pill';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObGridHeaderProps {
  title: string;
  count: string | number;
  controls: ReactNode;
  mobileControls?: ReactNode;
}

export const ObGridHeader: FC<ObGridHeaderProps> = ({
  title,
  count,
  controls,
  mobileControls,
}: ObGridHeaderProps) => {
  return (
    <div className='flex justify-between'>
      <div className='flex justify-center items-end	gap-2'>
        <ObTypography variant='h3'>{title}</ObTypography>
        {(typeof count === 'number' || !!count) && (
          <>
            <ObPill data-testid='gridHeaderCount'>{count}</ObPill>
          </>
        )}
      </div>
      <div className='hidden sm:flex justify-center items-center gap-2'>
        {controls}
      </div>
      <div className='flex sm:hidden justify-center items-center gap-2'>
        {mobileControls}
      </div>
    </div>
  );
};
