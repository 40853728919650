export interface ObTableHeaderProps {
  children?: React.ReactNode;
}

export const ObTableHeader = ({ children }: ObTableHeaderProps) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};
