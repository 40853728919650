import { AssetBackgroundType, AssetResource } from '@outbound/types';
import { useMemo, useState } from 'react';
import {
  FormFieldType,
  ObFormRenderer,
  ObThumbnail,
  ObTypography,
  useObFormRendererContext,
} from '../..';
import { ObPeekabooFooterTemplate } from '../../drawer';
import { ObReadOnlyText } from '../../forms/components/read-only-text/read-only-text';

export interface AssetDetailsEditForm {
  description: string;
}

export interface AssetDetailsDrawerProps {
  assetResource: AssetResource | undefined;
  onAssetDetailsFormSubmit: (data: AssetDetailsEditForm) => Promise<void>;
}

const ASSET_DETAILS_FORM_ID = 'asset-details-form';

export const AssetDetailsDrawer = ({
  assetResource,
  onAssetDetailsFormSubmit,
}: AssetDetailsDrawerProps) => {
  const { getRegisteredFormById } = useObFormRendererContext();
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  //This only needs to be updated if the assetResource's description changes
  const initialValues: AssetDetailsEditForm = useMemo(() => {
    return {
      description: assetResource?.description || '',
    };
    // We also want to update the form if the form swaps from dirty to clean
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetResource?.description, isFormDirty]);
  return (
    <ObPeekabooFooterTemplate
      showFooter={isFormDirty}
      affirmativeActionButtonLabel={'Save'}
      affirmativeActionButtonCallback={async () =>
        getRegisteredFormById(ASSET_DETAILS_FORM_ID)?.submitFunction()
      }
      dismissiveActionButtonLabel={'Discard Changes'}
      dismissiveActionButtonCallback={() => {
        getRegisteredFormById(ASSET_DETAILS_FORM_ID)?.discardChangesFunction();
        return Promise.resolve();
      }}
    >
      {assetResource && (
        <div className='py-6'>
          <ObThumbnail
            size='responsive'
            includeBorder={true}
            publicUrl={assetResource.publicUrl}
            altText={assetResource.description}
            preferredBackground={
              assetResource.recommendedBackground === AssetBackgroundType.DARK
                ? 'dark'
                : 'light'
            }
          />

          <ObFormRenderer
            hideSubmitButton={true}
            defaultValues={initialValues}
            onFormDirtyChangeCallback={setIsFormDirty}
            formDefinition={{
              id: ASSET_DETAILS_FORM_ID,
              fullWidthSubmitButton: false,
              submitButtonLabel: undefined,

              heading: '',
              subHeading: '',
              sections: [
                {
                  id: 'asset-meta',
                  heading: '',
                  subHeading: '',
                  fields: [
                    {
                      id: 'description',
                      autofocus: true,
                      label: 'Description',
                      type: FormFieldType.TEXT_AREA,

                      validationSchema: {
                        isRequired: true,
                        maxLength: 255,
                      },
                      helperText: '',
                      fieldTypeSettings: {},
                    },
                  ],
                },
              ],
            }}
            onSubmitCallback={onAssetDetailsFormSubmit}
          />
          <div className='flex flex-col gap-2'>
            <ObTypography variant='h4'>Details</ObTypography>
            <ObReadOnlyText label='Format'>
              {assetResource?.mimeType.toLocaleLowerCase()}
            </ObReadOnlyText>
            <ObReadOnlyText label='Dimensions'>
              {assetResource?.dimensions?.width}px x{' '}
              {assetResource?.dimensions?.height}px
            </ObReadOnlyText>
            <ObReadOnlyText label='Transparent Background'>
              {assetResource.hasAlphaChannel ? 'Yes' : 'No'}
            </ObReadOnlyText>
            <ObReadOnlyText label='Best on Background Color'>
              {assetResource.recommendedBackground === AssetBackgroundType.DARK
                ? 'Dark Background'
                : 'Light Background'}
            </ObReadOnlyText>
          </div>
        </div>
      )}
    </ObPeekabooFooterTemplate>
  );
};
