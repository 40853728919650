import { Deferred } from '@otbnd/utils';
import { useDialogService } from '@outbound/design-system';
import { IntegrationConfigurationType } from '@outbound/types';
import { GOOGLE_AD_MANGER_USER_INPUT_FORM } from './google-ad-manager/google-ad-manager-user-input-form';

/**
 * Hook that opens a configuration form for a given integration type. (If required) to collect user input before starting the integration.
 */
const useConfigureIntegration = () => {
  const { openFormDialog } = useDialogService();
  /**
   * Called as soon as a user clicks the "Enable" button on an integration.
   * If no additional user input is required, this function should return a resolved promise.
   * @returns
   */
  const collectRequiredUserInputForIntegration = (
    integrationConfigType: IntegrationConfigurationType
  ): Promise<any> => {
    switch (integrationConfigType) {
      case 'GOOGLE_AD_MANAGER': {
        const deffer = new Deferred<{
          connectExistingAccount: boolean;
          customerId: string | undefined;
        }>();
        //Open Dialog, Populated with Form Fields
        openFormDialog({
          formDefinition: GOOGLE_AD_MANGER_USER_INPUT_FORM,
          defaultValues: {
            useExistingOrNewAccount: 'CREATE_NEW_ACCOUNT',
            googleAdsCustomerNumber: '',
          },
          onFormSubmitCallback: function (data: any): Promise<any> {
            deffer.resolve({
              connectExistingAccount:
                data.useExistingOrNewAccount === 'USE_EXISTING_ACCOUNT',
              customerId: data.googleAdsCustomerNumber,
            });
            return deffer.promise;
          },
          title: 'Configure Your Google Ads Integration',
        });
        return deffer.promise;
      }
      case 'GOOGLE_AD_MANAGER_GRANT':
        return Promise.resolve();
      case 'META_AD_MANAGER':
        return Promise.resolve();

      default:
        throw new Error('Integration Not Implemented');
    }
  };
  return {
    collectRequiredUserInputForIntegration,
  };
};

export default useConfigureIntegration;
