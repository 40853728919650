import { ObButton, ObIcon, ObTypography } from '@outbound/design-system';
import { MarketingPlanCard } from '../common/marketing-plan-card';
import { MarketingPlanCardTitle } from '../common/marketing-plan-card-title';
import { ReactNode } from 'react';

/**
 * Takes a max width and makes the contents scrollable vertically.
 * When using it is recommended to calculate a width using javascript
 * @returns
 */
const VerticalScrollContainer = ({
  maxWidth,
  children,
}: {
  maxWidth: number;
  children?: ReactNode;
}) => {
  return (
    <div
      style={{
        maxWidth: `${maxWidth}px`,
      }}
      className='max-h-[264px] overflow-y-hidden overflow-x-scroll w-full flex flex-nowrap gap-8 flex-shrink-0'
    >
      {children}
    </div>
  );
};

const VisitWebsiteLink = () => {
  return (
    <div className='flex flex-row gap-2 items-center'>
      <ObTypography
        variant='body3'
        color='primary'
      >
        Visit Website
      </ObTypography>
      <ObIcon
        color='primary'
        icon={'externalLink'}
        size={'x-small'}
      />
    </div>
  );
};

const LocationDetails = () => {
  return (
    <section className='flex flex-col gap-2 max-w-[168px]'>
      <div className='map-placeholder w-[180px] h-[112px] bg-bg-gradient-top-right-dark'></div>
      <div className='flex flex-col gap-2 p-2'>
        <ObTypography variant='h6'>Location Name</ObTypography>
        <VisitWebsiteLink />
        <ObTypography variant='body3'>
          1234 Main Street St. Petersburg, FL 12345
        </ObTypography>
        <ObTypography variant='body3'>123-456-7890</ObTypography>
      </div>
    </section>
  );
};

interface LocationCardProps {
  innerWidth: number;
  onlineReach: Array<any>;
  physicalLocations: Array<any>;
  serviceAreas: Array<any>;
  onClickEditButtonCallback: () => any | void;
}

export const LocationCard = ({
  innerWidth,
  physicalLocations = [],
  serviceAreas = [],
  onClickEditButtonCallback,
}: LocationCardProps) => {
  const CARD_PADDING = 20;
  const CONTENT_PADDING = 20;
  const CARD_BORDER_WIDTH = 1;
  return (
    <MarketingPlanCard>
      <MarketingPlanCardTitle
        title='Where Your Business Operates'
        actions={
          <ObButton
            label='Edit'
            size='medium'
            variant='outline'
            onClick={onClickEditButtonCallback}
          />
        }
      />
      <ObTypography variant='h5'>Online</ObTypography>
      <div className='flex flex-row gap-2 justify-start items-center'>
        <VisitWebsiteLink />
      </div>
      {physicalLocations && physicalLocations.length > 0 && (
        <>
          <ObTypography variant='h5'>Physical Locations</ObTypography>
          <div className='flex flex-row gap-9 overflow-x-scroll overflow-y-hidden scrollbar-hide'>
            <LocationDetails />
            <LocationDetails />
            <LocationDetails />
          </div>
        </>
      )}
      {serviceAreas && serviceAreas.length > 0 && (
        <>
          <ObTypography variant='h5'>Service Areas</ObTypography>
          <VerticalScrollContainer
            maxWidth={
              innerWidth -
              CARD_PADDING * 2 -
              CONTENT_PADDING * 2 -
              CARD_BORDER_WIDTH * 2
            }
          >
            <LocationDetails />
            <LocationDetails />
            <LocationDetails />
          </VerticalScrollContainer>
        </>
      )}
    </MarketingPlanCard>
  );
};
