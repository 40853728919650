import { Transition } from '@headlessui/react';
import {
  ObAiGeneratingLoadingIndicator,
  ObBrand,
  ObButton,
  ObFormRenderer,
  ObTypography,
} from '@outbound/design-system';
import { cx } from 'class-variance-authority';
import { AnimatePresence, motion } from 'motion/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth0Extended } from '../../../auth/use-auth0-extended';
import { BasePageLayout } from '../../../components/layouts/base-page';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import { usePollForAIGeneratedBusinessDetails } from '../../../query/playbook/use-playbook-settings-business-details-endpoints';
import { useStore } from '../../../state/store';
import { CustomerPersonasSection } from '../../dashboard/playbookv2/pages/customer-personas/sections/customer-personas-section';
import { BrickAndMortarSection } from '../../dashboard/playbookv2/pages/locations/sections/brick-and-mortar-section';
import { ServiceAreaSection } from '../../dashboard/playbookv2/pages/locations/sections/service-area-section';
import { ServiceSection } from '../../dashboard/playbookv2/pages/products-and-services/sections/service-section';
import { useBusinessNameQuestion } from './question-hooks/use-business-name-question';
import { useIndustryQuestion } from './question-hooks/use-industry-question';
import { useProductsActivitiesQuestion } from './question-hooks/use-products-activity-question';
import { useProductsOrServicesQuestion } from './question-hooks/use-products-or-services-question';
import { useServicesActivitiesQuestion } from './question-hooks/use-services-activity-question';
import { QuestionnaireNavigator } from './question-navigator';
import { OnboardingQuestion } from './questionnaire.type';
import { questions } from './questions';

const messages: Array<string> = [
  'Generating Customer Profiles',
  'Analyzing Services',
  'Identifying Products',
];

export const QuestionnairePage = () => {
  /**
   * Poll for AI Generated Business Details status
   * This will be used to determine if the questionnaire is ready to be displayed
   *
   * TODO: add max attempts and error handling
   */
  const { isBusinessDetailsReady } = usePollForAIGeneratedBusinessDetails();

  /**
   * For Each Question that requires Server Side Fetching or Submitting add a Question Specific Hook
   * that decorates the questions with fetching and saving logic
   */
  useIndustryQuestion(questions);
  useBusinessNameQuestion(questions);
  useProductsOrServicesQuestion(questions);
  useProductsActivitiesQuestion(questions);
  useServicesActivitiesQuestion(questions);

  /**
   * App Routing Logic
   */
  const { navigateToFirstCampaignForm } = useAppNavigation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [animateIn, setAnimateIn] = useState<boolean>(false);

  const [builtLabel, setBuiltLabel] = useState<string>();
  const [builtHelperText, setBuiltHelperText] = useState<string>();

  const [workspaceId, setWorkspaceId] = useState<string>();

  const { authenticatedWorkspaceId, isAuthenticatedWithWorkspace } =
    useAuth0Extended();

  useEffect(() => {
    if (authenticatedWorkspaceId != null) {
      setWorkspaceId(authenticatedWorkspaceId);
    }
  }, [authenticatedWorkspaceId]);

  /**
   * Used to cascade our submission effect hooks
   * Set to true buy the submitQuestionAnswer callback
   * (Which is also responsible for updating answer state with the new answers from the submitted question)
   * Set to false once the useEffect responsible for server submission and next question navigation has been
   * invoked. Necessary due to the dependency of the server submit side-effect on the latest version of the
   * answers state.
   */
  const [submitFlag, setSubmitFlag] = useState(false);

  const [submittingPromise, setSubmittingPromise] = useState<any>();

  const [isQuestionnaireReady, setIsQuestionnaireReady] = useState(false);

  /**
   * Set false each time the page is set to a new question and set true once any server side state has been
   * loaded for the question and the server side state has had a chance to update the answers state
   */
  const [isQuestionReady, setIsQuestionReady] = useState(false);
  /**
   * Tracks if server state is loading for the current question
   */
  const [isServerStateForQuestionLoading, setIsServerStateForQuestionLoading] =
    useState(true);

  /**
   * Tracks the current question we are presenting to the user
   */
  const [currentQuestion, setCurrentQuestion] = useState<OnboardingQuestion>();

  /**
   * Manages the front-end state of the answers to the questions. A key value store of question ids to question values.
   * This data is also used to populate default values for the form renderer.
   * This data is also passed to the question navigator to determine the next question
   * due to the fact that some questions are conditionally shown based on the answers to previous questions
   */
  const { answers, setAnswer } = useStore();

  /**
   * Our question navigator class which deals with traversing from question to question including conditional
   * questions that are based on the answers of previous questions.
   */
  const questionNavigator = useMemo(
    () => new QuestionnaireNavigator(questions),
    []
  );

  /**
   * Helper function that will go to the first question in the question list
   */
  const setQuestionnaireToFirstQuestion = useCallback(() => {
    //This will always return the first question since we have not specified a current question
    const firstQuestion = questionNavigator.getNextQuestion({});
    setSearchParams(
      { question: firstQuestion?.fullPath || '' },
      { replace: true } //Don't double up on history
    );
  }, [setSearchParams, questionNavigator]);

  /**
   * Run Each Time the Search Params Are Updated
   * - Update the URL with the current question (If no question search param)
   * - Check if the question has server side state
   *   - Yes -> Load the State
   *   - No  -> Set Question Ready
   */
  useEffect(() => {
    /**
     * 1. Check For Search Param
     **/
    const currentQuestionSearchParam = searchParams.get('question');
    if (
      currentQuestionSearchParam == null ||
      currentQuestionSearchParam.trim() === ''
    ) {
      /**
       * 1a. When there is not a question search param we will set the current question to the first question.
       */
      setQuestionnaireToFirstQuestion();
    } else {
      /**
       * 2. Since we have found a question in the questions query param we will request the question's data from
       *    the question navigator
       */
      const currentQuestion = questionNavigator.getQuestionByFullPath(
        currentQuestionSearchParam
      );
      if (currentQuestion == null) {
        /**
         * 2a. If for some reason the question navigator doesn't have data for the question
         *     we will load the page to the first question.
         *     (This would occur in the case that someone put non-sense in the search param)
         */
        setQuestionnaireToFirstQuestion();
      } else {
        /**
         * 3. We have found question data.
         *    We will now prepare the page to carry out the side effects of loading a new question.
         *    Setting isQuestionReady to false will show a loading indicator while we run the
         *    side effects (Such as server state fetching etc.)
         */
        setIsQuestionReady(false);
        setCurrentQuestion(currentQuestion);
        setBuiltLabel(undefined);
      }
    }
  }, [
    questionNavigator,
    searchParams,
    setQuestionnaireToFirstQuestion,
    setSearchParams,
  ]);

  /**
   * Run any side effects of the currentQuestion being updated such as server state loading
   */
  useEffect(() => {
    /**
     * The side effects here should only be run once.
     * We use the isQuestionReady flag to enforce this desired behavior
     */
    if (currentQuestion && !isQuestionReady) {
      /**
       * Questions optionally can include a function to load async data
       * that is used to populate values for the question. This is helpful
       * when we are revisiting the form for some reason or we have some default data.
       */
      if (currentQuestion?.fetchServerState) {
        currentQuestion.fetchServerState().then(
          (response: any) => {
            /**
             * The fetch Server state response must be formatted as value that the UI
             * expects for the "answer" to the question. This may be a string for text fields
             * or an Array of options for checkbox groups etc.
             */
            setAnswer(currentQuestion.fullPath!.replace('.', '#'), response);

            setBuiltLabel(buildLabel(currentQuestion, response));
            setBuiltHelperText(buildHelperText(currentQuestion, response));

            /**
             * Set our flags so our UI can stop showing the loading states and show the actual question.
             */
            setIsServerStateForQuestionLoading(false);
            setIsQuestionReady(true);
            setTimeout(() => {
              setAnimateIn(true);
            }, 200);
          },
          () => {
            console.error(
              'An Error occurred fetching server state for question'
            );
          }
        );
      } else {
        /**
         * Since there is no fetch server side state function configured for this question we can
         * move to display the question directly.
         */
        setIsServerStateForQuestionLoading(false);
        setIsQuestionReady(true);
        setTimeout(() => {
          setAnimateIn(true);
        }, 200);
      }
    }
  }, [currentQuestion, isQuestionReady, setAnswer]);

  const navigateToNextQuestion = useCallback(
    async (answers: any, currentQuestionId: string) => {
      const nextQuestion = questionNavigator.getNextQuestion(
        answers,
        currentQuestionId
      );
      if (nextQuestion) {
        setSearchParams(
          { question: nextQuestion.fullPath! },
          { replace: false }
        );
      } else {
        setIsServerStateForQuestionLoading(true);
        if (isAuthenticatedWithWorkspace) {
          console.log('Authenticated with workspace', workspaceId);
          navigateToFirstCampaignForm(workspaceId!);
        } else {
          //Why are we here?
          console.error(
            'What workspace are we currently authenticated with?',
            workspaceId
          );
          navigateToFirstCampaignForm(workspaceId!);
        }
      }
    },

    [
      isAuthenticatedWithWorkspace,
      navigateToFirstCampaignForm,
      questionNavigator,
      setSearchParams,
      workspaceId,
    ]
  );

  //Currently being called by the form renderer
  const submitQuestionAnswer = useCallback(
    async (answer: any, currentQuestion: OnboardingQuestion) => {
      let formattedAnswer: any;
      if (currentQuestion.useFormRenderer) {
        /**
         * The form render has all of it's answers wrapped in an object with the keys being question ids.
         * We will need to extract just the value from the form-renderer to set our answer state.
         **/
        formattedAnswer = answer[currentQuestion.fullPath!.replace('.', '#')];
      } else {
        /**
         * If not using the form renderer for the question we will assume that the component is returning a plain value
         */
        formattedAnswer = answer;
      }
      setAnswer(currentQuestion.fullPath!.replace('.', '#'), formattedAnswer);
      setSubmitFlag(true);
      return new Promise((resolve, reject) => {
        const resolution = {
          resolve,
          reject,
        };
        setSubmittingPromise(resolution);
      });
    },
    [setAnswer]
  );

  useEffect(() => {
    const submitAndNavigate = async (currentQuestion: OnboardingQuestion) => {
      /**
       * These side effects are only applicable once per question submission
       * The submit flag is set only once question are ready for submission
       */
      let moveToNextQuestion = false;
      /**
       * If the current question has a defined saved function we will call it;
       */
      if (currentQuestion.submitServerState) {
        try {
          await currentQuestion.submitServerState(answers);
          moveToNextQuestion = true;
        } catch (error) {
          return Promise.reject();
        }
      } else {
        moveToNextQuestion = true;
      }
      if (moveToNextQuestion) {
        if (submittingPromise) {
          submittingPromise.resolve();
        }
        setTimeout(() => {
          navigateToNextQuestion(answers, currentQuestion.fullPath!);
        }, 400);

        setAnimateIn(false);

        return Promise.resolve();
      } else {
        if (submittingPromise) {
          submittingPromise.reject();
        }
        return Promise.reject();
      }
    };
    //We will only run this once and if the submit flag is true
    if (submitFlag && currentQuestion) {
      setSubmitFlag(false);
      submitAndNavigate(currentQuestion);
    }
  }, [
    answers,
    currentQuestion,
    submitFlag,
    setSubmitFlag,
    navigateToNextQuestion,
    submittingPromise,
  ]);

  /**
   * FUTURE USE: For questions that are not using the form renderer
   * @returns
   */
  const renderQuestion = () => {
    //We cannot render a question that does not exist.
    if (currentQuestion == null) {
      return <></>;
    }
    switch (currentQuestion.id) {
      case 'confirm-personas':
        return (
          <div className='flex justify-center items-center w-full flex-col gap-8'>
            <CustomerPersonasSection />
            <ObButton
              label='Confirm Customer Profiles'
              fullWidth='always'
              size='large'
              onClick={() => submitQuestionAnswer({}, currentQuestion)}
            />
          </div>
        );

      case 'confirm-services':
        return (
          <div className='flex justify-center items-center w-full flex-col gap-8'>
            <ServiceSection hideHeader={true} />
            <ObButton
              label='Confirm My Services'
              fullWidth='always'
              size='large'
              onClick={() => submitQuestionAnswer({}, currentQuestion)}
            />
          </div>
        );
      case 'confirm-service-areas':
        return (
          <div className='flex justify-center items-center w-full flex-col gap-8'>
            <ServiceAreaSection hideHeader={true} />
            <ObButton
              label='Confirm My Service Areas'
              fullWidth='always'
              size='large'
              onClick={() => submitQuestionAnswer({}, currentQuestion)}
            />
          </div>
        );
      case 'confirm-my-brick-and-mortar-locations':
        return (
          <div className='flex justify-center items-center w-full flex-col gap-8'>
            <BrickAndMortarSection hideHeader={true} />
            <ObButton
              label='Confirm My Physical Locations'
              fullWidth='always'
              size='large'
              onClick={() => submitQuestionAnswer({}, currentQuestion)}
            />
          </div>
        );
      default:
        return (
          <>
            <ObButton
              label='Continue'
              fullWidth='mobile'
              size='large'
              onClick={() => submitQuestionAnswer({}, currentQuestion)}
            />
          </>
        );
    }
  };

  const buildLabel = (currentQuestion: OnboardingQuestion, answer: any) => {
    if (
      answer == null ||
      answer === '' ||
      (Array.isArray(answer) && answer.length === 0)
    ) {
      return currentQuestion.noValueLabel ?? currentQuestion.label;
    } else {
      return currentQuestion.label;
    }
  };

  const buildHelperText = (
    currentQuestion: OnboardingQuestion,
    answer: any
  ) => {
    if (
      answer == null ||
      answer === '' ||
      (Array.isArray(answer) && answer.length === 0)
    ) {
      return currentQuestion.noValueHelperText ?? currentQuestion.helperText;
    } else {
      return currentQuestion.helperText;
    }
  };

  const [setupMessage, setSetupMessage] = useState<string>(
    `Getting your business details`
  );

  useEffect(() => {
    if (isBusinessDetailsReady) {
      setTimeout(() => {
        setIsQuestionnaireReady(true);
      }, 100);
    }
  }, [isBusinessDetailsReady]);

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      setSetupMessage(messages[index]);
      index += 1;

      // Clear interval when all messages are shown
      if (index >= messages.length) {
        clearInterval(intervalId);
      }
    }, 4000); // 5 seconds delay between each message

    // Cleanup function to clear interval if the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <BasePageLayout
      background='scrollable-gradient'
      contentPosition='center-between'
      fullScreen={true}
    >
      <Transition
        show={true}
        enter='transition-opacity duration-500'
      >
        <header
          className={'w-full flex flex-row justify-center items-center p-4'}
        >
          <ObBrand
            variant={'icon'}
            size='medium'
          />
        </header>
      </Transition>

      {isServerStateForQuestionLoading ||
      currentQuestion == null ||
      !isQuestionReady ||
      !isQuestionnaireReady ? (
        <div className='flex justify-center items-center h-full'>
          {!isQuestionnaireReady && (
            /**
             * we have this in a few places, would consider refactoring this into an util component,
             */
            <motion.div
              className='flex flex-col gap-1 justify-center items-center'
              initial='hidden'
              animate='visible'
              variants={{
                visible: {
                  opacity: 1,
                  transition: {
                    delay: 0.5,
                    staggerChildren: 0.4, // Restores staggered effect
                    ease: 'easeIn',
                  },
                },
                hidden: { opacity: 0 },
              }}
            >
              {/* Spinner with Fade and Scale */}
              <motion.div
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0.8 },
                }}
                transition={{
                  delay: 0.5,
                  duration: 0.5, // Animation duration
                  ease: 'easeOut',
                }}
              >
                <ObAiGeneratingLoadingIndicator
                  variant='spinner'
                  size={120}
                />
              </motion.div>

              {/* Text Animation with AnimatePresence */}
              <AnimatePresence mode='wait'>
                <motion.div
                  key={setupMessage} // Ensures re-render for new message
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 10 },
                  }}
                  initial='hidden'
                  animate='visible'
                  exit='hidden'
                  transition={{ duration: 0.3, delay: 0.5 }}
                >
                  <ObTypography
                    variant='body2'
                    color='secondary'
                  >
                    {setupMessage}
                  </ObTypography>
                </motion.div>
              </AnimatePresence>
            </motion.div>
          )}
          {/* <ObLoadingSpinner
            fadeIn={true}
            includeBrand={true}
          /> */}
        </div>
      ) : (
        <>
          <Transition
            show={true}
            enter='transition-opacity duration-500'
          >
            <article
              className={cx(
                'p-4 flex flex-col gap-4 max-w-3xl w-full transition-opacity duration-500 ',
                animateIn ? 'opacity-100' : 'opacity-0'
              )}
            >
              <div>
                <ObTypography variant='h2'>
                  {builtLabel ?? currentQuestion.label}
                </ObTypography>
                <ObTypography
                  variant='h4'
                  color='secondary'
                >
                  {builtHelperText ?? currentQuestion.helperText}
                </ObTypography>
              </div>

              {isQuestionReady &&
                currentQuestion.useFormRenderer &&
                currentQuestion.formDefinition && (
                  <ObFormRenderer
                    submitButtonFullWidth='mobile'
                    submitButtonLabel='Continue'
                    defaultValues={answers}
                    disableAfterSuccessfulSubmit={true}
                    formDefinition={currentQuestion.formDefinition}
                    onSubmitCallback={(data: any) =>
                      submitQuestionAnswer(data, currentQuestion)
                    }
                  />
                )}
              {!currentQuestion.useFormRenderer && renderQuestion()}
            </article>
          </Transition>
          <footer className='w-full flex flex-col p-4 max-w-3xl'>
            {/* Potentially will use to move the submit button to the bottom of the screen */}
            <ObTypography>{animateIn}</ObTypography>
          </footer>
        </>
      )}
    </BasePageLayout>
  );
};
