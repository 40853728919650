import { CampaignDeploymentStatusType } from '@outbound/types';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantProps } from '../card.type';
import { CampaignDeploymentStatusIcon } from './components/campaign-deployment-status-icon';

export interface CampaignDeploymentCardProps extends CardVariantProps {
  stage: CampaignDeploymentStatusType;
}

type CampaignDeploymentCardImplementationProps = Omit<
  CampaignDeploymentCardProps,
  'variant'
>;

export const CampaignDeploymentCard = ({
  variant,
  ...props
}: CampaignDeploymentCardProps) => {
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'micro':
      return <MicroCard {...props} />;
    case 'mini':
    case 'table':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};

const GridCard = ({ stage }: CampaignDeploymentCardImplementationProps) => {
  return (
    <ObCardSurface>
      <CampaignDeploymentStatusIcon stage={stage} />
      <ObTypography variant='subtitle2'>
        January 1, 2024 10:00am EST
      </ObTypography>
    </ObCardSurface>
  );
};

const MicroCard = ({ stage }: CampaignDeploymentCardImplementationProps) => {
  let message = '';
  if (stage === 'FAILED') {
    message = 'Publishing Failed';
  } else if (stage === 'SUCCEEDED') {
    message = 'Successfully Published';
  } else {
    message = 'Publishing in Progress';
  }
  return (
    <div>
      <div className='flex flex-row justify-start items-center gap-1  hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active rounded-lg p-1'>
        <CampaignDeploymentStatusIcon stage={stage} />
        <ObTypography variant='subtitle2'>{message}</ObTypography>
      </div>
    </div>
  );
};
