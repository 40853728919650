import {
  FormDefinition,
  FormFieldType,
  FullPageAction,
} from '@outbound/design-system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getJwtTokenPayload } from '../../utilties/jwt-utilities';
import axios from 'axios';
import { legalLinks } from './signup-footer-links';
import { OUTBOUND_API_BASE_URL } from '../../environment';
import { PatchCompleteProfileOnLoginRequestResource } from '@outbound/types';

/**
 * Interface of the token generated by auth0 during the post-signin action.
 * To update what is included in this token you must do so via Auth0's dashboard.
 */
interface CompleteProfileSessionToken {
  iss: string;
  sub: string;
  exp: number;
  ip: string;
  email: string;
  externalUserId: string; //Outbound's internal user id
  continueUri: string; //The URL we will redirect back to at the end of the process
}

/**
 * Utilty function to construct the redirect URL for Auth0; If we end up doing this multiple times it would make sense to move
 * this into a utility class
 * @param baseUri
 * @param state
 * @returns
 */
export const buildRedirectUrl = (baseUri: string, state: string) => {
  const url = new URL(baseUri);
  url.searchParams.append('state', state);
  return url.toString();
};

export const CompleteProfilePage = () => {
  const [searchParams] = useSearchParams();
  const [isTokenInvalid, setIsTokenInvalid] = useState<boolean>(false);
  const [sessionToken, setSessionToken] =
    useState<CompleteProfileSessionToken | null>(null);
  /**
   * Generated by Auth0 Redirect Action.
   * This token is expected to contain information about the user
   * including their auth0 user id and their email address.
   */
  const rawSessionToken = searchParams.get('session_token');

  /**
   * Generated by Auth0 in order to resume an authentication flow
   * after we redirect back to the continueUri provided in the session token
   * If this is not present we should not continue as the user will not be
   * able to resume their authentication and it means someone tampered with
   * the URL.
   */
  const opaqueStateToken = searchParams.get('state');

  /**
   * Attempt to parse the session token and use it to configure the page
   * If for some reason the token is invalid on not present we cannot continue
   * with the profile completion process.
   */
  useEffect(() => {
    //We only want to run this logic once and never again
    if (sessionToken != null) {
      return;
    }
    if (rawSessionToken == null || opaqueStateToken == null) {
      setIsTokenInvalid(true);
    } else {
      try {
        setSessionToken(
          getJwtTokenPayload<CompleteProfileSessionToken>(rawSessionToken)
        );
        console.log('Session Token', sessionToken);
      } catch (error) {
        console.log('Raw Session Token', error);
        setIsTokenInvalid(true);
      }
    }
  }, [opaqueStateToken, rawSessionToken, sessionToken]);

  const submitUpdateProfileRequest = async (
    values: PatchCompleteProfileOnLoginRequestResource
  ) => {
    const response = await axios.patch(
      `${OUTBOUND_API_BASE_URL}/auth-0-actions/complete-profile-on-login`,
      values,
      {
        headers: {
          Authorization: `Bearer ${rawSessionToken}`,
        },
      }
    );
    /** allow time for the success animation to complete */
    setTimeout(() => {
      redirectToContinueAuthenticationFlow();
    }, 1000);
    return response.data;
  };

  const redirectToContinueAuthenticationFlow = () => {
    const url = buildRedirectUrl(sessionToken!.continueUri, opaqueStateToken!);
    window.location.href = url;
  };

  const heading = 'Tell us about you';
  const subHeading = 'We will use this information to set up your user profile';

  const completeProfileForm: FormDefinition = {
    id: '1',
    heading: '',
    subHeading: '',
    sections: [
      {
        id: '1',
        heading: '',
        subHeading: '',
        fields: [
          {
            id: 'givenName',
            label: 'First Name',
            type: FormFieldType.TEXT,
            helperText: '',
            autofocus: true,
            disablePasswordManagers: true,
            validationSchema: {
              isRequired: true,
            },
            fieldTypeSettings: {},
          },
          {
            id: 'familyName',
            label: 'Last Name',
            type: FormFieldType.TEXT,
            helperText: '',
            disablePasswordManagers: true,
            validationSchema: {
              isRequired: true,
            },
            fieldTypeSettings: {},
          },
        ],
      },
    ],
  };

  if (sessionToken == null) {
    //TODO Complete this
    return <></>;
  }
  if (isTokenInvalid) {
    //TODO Complete this
    return <></>;
  } else {
    return (
      <FullPageAction
        heading={heading}
        subHeading={subHeading}
        successHeading='Profile Updated'
        successSubHeading='Your profile has been updated successfully. You will be redirected in a moment'
        errorHeading='Something went wrong'
        errorSubHeading='There was an issue updating your profile. Please try again later'
        form={completeProfileForm}
        onSubmitCallback={submitUpdateProfileRequest}
        footerLinks={legalLinks}
      />
    );
  }
};
