import { Outlet } from 'react-router-dom';

import { NavItem } from '../../../../components/navigation/dashboard-route.type';
import { notificationSettingsRoute } from './notifications/routes';
import { profileSettingsRoute } from './profile/routes';

export const myAccountSettingsRoutes: NavItem = {
  title: 'My Account',
  route: 'my-account',
  component: () => <Outlet />,
  showInNavMenu: ['workspace-menu-nav'],
  childRoutes: [profileSettingsRoute, notificationSettingsRoute],
};
