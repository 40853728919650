import { ObIcon, ObTypography } from '@outbound/design-system';
import { IconSystemKeys } from '@outbound/design-system/src/tokens/icons/icons';

import { cx } from 'class-variance-authority';
import React, { FC, ReactNode, useMemo } from 'react';
import {
  NavLink,
  useMatch,
  useParams,
  useResolvedPath,
} from 'react-router-dom';

interface ButtonProps {
  /**
   * Optional On Click Handler for cases where we are not navigating
   */
  onClickHandler?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  tabIndex?: number;
}

interface NavLinkProps extends ButtonProps {
  /**
   * Optional React Dom Router route to navigate to
   */
  navigatesToRoute?: string;
}

interface ItemComponentProps {
  children: ReactNode;
  className: string;
  testID: string;
}

export interface DrawerItemProps extends NavLinkProps {
  icon?: IconSystemKeys;
  label: string;
  expanded?: boolean;
}

const NavLinkComponent = ({
  children,
  className,
  testID,
  navigatesToRoute,
  onClickHandler,
  tabIndex,
}: ItemComponentProps & NavLinkProps) => {
  const resolved = useResolvedPath(navigatesToRoute as string);
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <NavLink
      data-testid={testID}
      data-active-route={match != null}
      className={({ isActive }) =>
        cx(
          className,
          isActive || match
            ? 'dark:bg-dark/action/primary/normal bg-light/action/primary/on-subtle dark:hover:data-[active-route=true]:bg-dark/action/primary/hover text-black dark:text-white'
            : ''
        )
      }
      end
      to={navigatesToRoute as string}
      onClick={onClickHandler}
      tabIndex={tabIndex}
    >
      {children}
    </NavLink>
  );
};
const ButtonComponent = ({
  children,
  className,
  testID,
  tabIndex,
  onClickHandler,
}: ItemComponentProps & ButtonProps) => (
  <button
    data-testid={testID}
    className={className}
    type='button'
    onClick={onClickHandler}
    tabIndex={tabIndex}
  >
    {children}
  </button>
);

export const DrawerItem: FC<DrawerItemProps> = ({
  icon,
  label,
  expanded = true,
  navigatesToRoute,
  onClickHandler,
  tabIndex = undefined,
}) => {
  const params = useParams();

  const route = useMemo(() => {
    const campaignId = params?.campaignId;
    const sectionId = params?.sectionId;
    const pageId = params?.pageId;

    // This need's to be refactored as part of: OD-421
    if (campaignId && navigatesToRoute?.includes(':campaignId')) {
      return navigatesToRoute?.replace(':campaignId', campaignId);
    } else if (
      sectionId &&
      pageId &&
      navigatesToRoute?.includes(':sectionId')
    ) {
      return navigatesToRoute
        ?.replace(':sectionId', sectionId)
        ?.replace(':pageId', pageId);
    }

    return navigatesToRoute;
  }, [navigatesToRoute, params]);

  const ItemComponent = navigatesToRoute ? NavLinkComponent : ButtonComponent;
  return (
    <ItemComponent
      testID={`navigation-drawer-item__${label}`}
      className={cx(
        'flex items-center gap-x-2',
        'text-contentSecondaryLight dark:text-contentSecondaryDark data-[active-route=false]:dark:hover:bg-dark/action/primary/on-subtle',
        ' rounded p-2 mx-3   focus:outline-none focus:shadow-interactive'
      )}
      navigatesToRoute={route}
      onClickHandler={onClickHandler}
      tabIndex={tabIndex}
    >
      <span>
        {icon && (
          <ObIcon
            icon={icon}
            size='medium'
            color='inherit'
          />
        )}
      </span>
      <ObTypography
        variant='h6'
        color='inherit'
        className={cx(
          'transition-all duration-300 ease-in-out overflow-hidden whitespace-nowrap',
          expanded ? 'opacity-100' : 'translate-x-full opacity-0'
        )}
      >
        {label}
      </ObTypography>
    </ItemComponent>
  );
};
