import { FC } from 'react';
import {
  ObMetricCardHeader,
  ObMetricCardHeaderProps,
} from '../ob-metric-card-header/ob-metric-card-header';
import { ObMetricCardSurface } from '../ob-metric-card-surface/ob-metric-card-surface';

export interface ObMetricCardBaseProps extends ObMetricCardHeaderProps {
  children?: React.ReactNode;
}
export const ObMetricCardBase: FC<ObMetricCardBaseProps> = ({
  title,
  status,
  context,
  children,
}: ObMetricCardBaseProps) => {
  return (
    <ObMetricCardSurface>
      <ObMetricCardHeader
        title={title}
        status={status}
        context={context}
      />
      {children}
    </ObMetricCardSurface>
  );
};
