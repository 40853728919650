export const UserSquare = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g
        id='solid/users/user-square'
        clipPath='url(#clip0_1390_82)'
      >
        <g id='Solid'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.66663 6.33332C4.66663 4.49237 6.15901 2.99999 7.99996 2.99999C9.84091 2.99999 11.3333 4.49237 11.3333 6.33332C11.3333 8.17427 9.84091 9.66666 7.99996 9.66666C6.15901 9.66666 4.66663 8.17427 4.66663 6.33332Z'
            fill='currentColor'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.4942 0.666657H4.50576C3.96912 0.666649 3.5262 0.666642 3.16541 0.69612C2.79068 0.726737 2.44621 0.792446 2.12265 0.957307C1.62089 1.21297 1.21294 1.62092 0.957276 2.12268C0.792416 2.44624 0.726706 2.79071 0.696089 3.16544C0.666611 3.52623 0.666618 3.96914 0.666626 4.50578V11.4942C0.666618 12.0308 0.666611 12.4737 0.696089 12.8345C0.726706 13.2093 0.792416 13.5537 0.957276 13.8773C1.21294 14.3791 1.62089 14.787 2.12265 15.0427C2.44621 15.2075 2.79068 15.2732 3.16541 15.3039C3.5262 15.3333 3.9691 15.3333 4.50574 15.3333H11.4942C12.0308 15.3333 12.4737 15.3333 12.8345 15.3039C13.2092 15.2732 13.5537 15.2075 13.8773 15.0427C14.379 14.787 14.787 14.3791 15.0426 13.8773C15.2075 13.5537 15.2732 13.2093 15.3038 12.8345C15.3333 12.4737 15.3333 12.0308 15.3333 11.4942V4.50578C15.3333 3.96914 15.3333 3.52623 15.3038 3.16544C15.2732 2.79071 15.2075 2.44624 15.0426 2.12268C14.787 1.62092 14.379 1.21297 13.8773 0.957307C13.5537 0.792446 13.2092 0.726737 12.8345 0.69612C12.4737 0.666642 12.0308 0.666649 11.4942 0.666657ZM2.72797 2.14532C2.83224 2.09219 2.98171 2.0489 3.27399 2.02503C3.57404 2.00051 3.96224 1.99999 4.53329 1.99999H11.4666C12.0377 1.99999 12.4259 2.00051 12.7259 2.02503C13.0182 2.0489 13.1677 2.09219 13.2719 2.14532C13.5228 2.27315 13.7268 2.47712 13.8546 2.728C13.9078 2.83227 13.951 2.98174 13.9749 3.27402C13.9994 3.57408 14 3.96227 14 4.53332V11.4667C14 12.0377 13.9994 12.4259 13.9749 12.726C13.9576 12.9377 13.9301 13.0745 13.8961 13.1734C13.554 11.8332 12.4052 10.8218 10.9934 10.6827C10.8298 10.6666 10.6443 10.6666 10.3719 10.6667H5.62806C5.35566 10.6666 5.17015 10.6666 5.00658 10.6827C3.59476 10.8218 2.44597 11.8332 2.10381 13.1734C2.06978 13.0745 2.04229 12.9377 2.02499 12.726C2.00048 12.4259 1.99996 12.0377 1.99996 11.4667V4.53332C1.99996 3.96227 2.00048 3.57408 2.02499 3.27402C2.04887 2.98174 2.09216 2.83227 2.14528 2.728C2.27312 2.47712 2.47709 2.27315 2.72797 2.14532Z'
            fill='currentColor'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1390_82'>
          <rect
            width='16'
            height='16'
            fill='currentColor'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
