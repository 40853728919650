import { makeObservable, observable } from 'mobx';
import ImageAsset from '../../../assets/image-asset';
import { BaseModel } from '../../../base-model';

import { toObrn } from '@otbnd/utils';
import { ServerDelta } from '@outbound/types';
import { RootStore } from '../../../root-store';
import { Service } from '../service';

class ServiceOffering extends BaseModel {
  private _name: string;
  private _description: string;
  private _serviceId: string;
  private _keyPhotoAssetId: string | undefined;
  private _otherPhotos: string[] = [];

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    name: string,
    description: string,
    serviceId: string,
    keyPhotoAssetId: string | undefined,
    otherPhotoAssetIds: Array<string>
  ) {
    super(
      rootStore,
      'service/service-offering',
      '1',
      id,
      workspaceId,
      //TEMP - This should be added to the API
      toObrn({
        workspaceId,
        objectType: 'service/service-offering',
        localPathId: `${serviceId}/${id}`,
      })
    );
    this._name = name;
    this._description = description;
    this._serviceId = serviceId;
    this._keyPhotoAssetId = keyPhotoAssetId;
    this._otherPhotos = otherPhotoAssetIds;
  }

  applyPatch(_patch: ServerDelta[]): void {
    throw new Error('Method not implemented.');
  }
  toJson(): Record<string, any> {
    throw new Error('Method not implemented.');
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _name: observable,
      _description: observable,
    } as any);
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get service(): Service | null {
    return this._rootStore.serviceStore.getById(this._serviceId);
  }

  /**
   * Throws a promise if the key photo needs to be lazy loaded
   */
  get keyPhoto(): ImageAsset | undefined {
    if (this._keyPhotoAssetId) {
      return this._rootStore.assetStore.getById(
        this._keyPhotoAssetId
      ) as ImageAsset;
    } else {
      return undefined;
    }
  }
}

export default ServiceOffering;
