import { FormDefinition, FormFieldType } from '@outbound/design-system';

export const ONLINE_PRESENCE_FORM_DEF: FormDefinition = {
  id: 'online-presence-form',
  fullWidthSubmitButton: true,
  submitButtonLabel: 'Save',
  heading: '',
  subHeading: '',
  sections: [
    {
      id: 'website',
      heading: 'Website',
      subHeading: '',
      fields: [
        {
          id: 'website',
          label: 'Website',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },

    {
      id: 'social-media',
      heading: 'Social Media',
      subHeading: '',
      fields: [
        {
          id: 'facebookUrl',
          label: 'Facebook Profile URL',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'instagramUrl',
          label: 'Instagram Profile URL',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'xUrl',
          label: 'X (Formerly Twitter) Profile URL',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'tiktokUrl',
          label: 'Tiktok Profile URL',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'business-listing',
      heading: 'Business Listings',
      subHeading: '',
      fields: [
        {
          id: 'googleBusinessUrl',
          label: 'Google Business URL',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
  ],
};
