import {
  ObCustomerProfileCard,
  ObLoadingSpinner,
  ObNoContentCta,
} from '@outbound/design-system';
import {
  TargetingConditionNodeResource,
  TargetingGroup,
} from '@outbound/types';
import { useCallback } from 'react';
import { useFetchPlaybookSettings } from '../../../../../../query/playbook/use-playbook-endpoints';
import { PlaybookCardAddNewButton } from '../../_common/playbook-card-add-new-button';
import { PlaybookCardButtonWrapper } from '../../_common/playbook-card-button-wrapper';
import { PlaybookCardGrid } from '../../_common/playbook-card-grid';
import { CUSTOMER_PERSONAS_PAGE_DEF } from '../customer-personas.def';
import { useCustomerPersonaCreateDrawer } from '../drawer/customer-persona-create-drawer';
import { useCustomerPersonaEditDrawer } from '../drawer/customer-persona-edit-drawer';

export const CustomerPersonasSection = () => {
  const { data: playbookSettings, isLoading: isPlaybookSettingsLoading } =
    useFetchPlaybookSettings();

  const { openNewCustomerPersonaDrawer } = useCustomerPersonaCreateDrawer();

  const { openEditCustomerPersonaDrawer } = useCustomerPersonaEditDrawer();

  const atLeastOneCustomerPersonaExists =
    !isPlaybookSettingsLoading && (playbookSettings?.personas?.length ?? 0) > 0;

  const noCustomerPersonasExist =
    !isPlaybookSettingsLoading && playbookSettings?.personas?.length === 0;

  const demographicDescriptions = useCallback(
    (demographics?: TargetingGroup): Array<string> => {
      if (!demographics) {
        return [];
      }
      return demographics
        .children!.map((node) => {
          if (node.operator === 'AND' || node.operator === 'OR') {
            return;
          } else {
            const targetingNode: TargetingConditionNodeResource =
              node as TargetingConditionNodeResource;
            return {
              label: targetingNode.fieldKeyLabel ?? '',
              operatorLabel: targetingNode.operatorLabel ?? '',
              operationValueAsString: targetingNode.operationValueAsText,
              operationValue: targetingNode.operationValue,
              formattedString: targetingNode.formattedString ?? '',
              displayValue: targetingNode.displayValue ?? '',
            };
          }
        })
        .filter((node) => node != null)
        .map(
          (node) =>
            `${node?.label}: ${
              node?.operationValueAsString ?? node?.displayValue
            }`
        );
    },
    []
  );

  return (
    <section className='w-full'>
      {isPlaybookSettingsLoading && (
        <div className='w-full h-full flex justify-center items-center mt-[50%]'>
          <ObLoadingSpinner includeBrand={true} />
        </div>
      )}
      {!isPlaybookSettingsLoading && atLeastOneCustomerPersonaExists && (
        <PlaybookCardGrid>
          {playbookSettings?.personas.map((persona) => (
            <PlaybookCardButtonWrapper
              key={persona.id}
              onClickCallback={() => openEditCustomerPersonaDrawer(persona.id)}
              label={`View ${persona.role} details`}
            >
              <ObCustomerProfileCard
                role={persona.role}
                description={persona.description ?? ''}
                demographicDescriptions={demographicDescriptions(
                  persona.demographics
                )}
              />
            </PlaybookCardButtonWrapper>
          ))}
          <PlaybookCardButtonWrapper
            className='h-[224px]'
            onClickCallback={openNewCustomerPersonaDrawer}
            label={'Create New Customer Profile'}
          >
            <PlaybookCardAddNewButton label='Create New Customer Profile' />
          </PlaybookCardButtonWrapper>
        </PlaybookCardGrid>
      )}

      {!isPlaybookSettingsLoading && noCustomerPersonasExist && (
        <ObNoContentCta
          includeGradient={true}
          title={'Create Your First Customer Profile'}
          description={
            'Start adding customer profiles to target your ideal customers for your campaigns!'
          }
          buttonText={'Create Customer Profile'}
          iconName={CUSTOMER_PERSONAS_PAGE_DEF.icon}
          onButtonClick={openNewCustomerPersonaDrawer}
        />
      )}
    </section>
  );
};
