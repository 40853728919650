import { PopoverButton } from '@headlessui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { StateManagedByParentInputValueOnly } from '../../../base-component-props.type';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObColorPicker } from '../../elements/ob-color-picker/ob-color-picker';
import { ObInputColor } from '../../elements/ob-input-color/ob-input-color';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import { ObField } from '../../molecules/ob-field/ob-field';

export interface ObColorFieldColor {
  /**
   * The label for the color, visible to the user
   */
  label: string;
  /**
   * A unique key to identify the color
   */
  key: string;
  /**
   * Indicates whether the color is required to be set by the user
   */
  isRequired?: boolean;
}

/**
 * Represents the value of one of the colors to select.
 * The value field of the ObColorsField component will return an array of these objects
 */
export interface ObColorsFieldValue {
  /**
   * A unique identifier for the field
   */
  key: string;
  /**
   * The value to be returned by the field when the option is selected.
   * This is intentionally set to any so we can accept any object which is helpful
   * to not need to do key lookups on selected values
   */
  value: any;
}

/**
 * A map of color keys to boolean values indicating whether the picker is open or closed.
 */
export interface ObColorPickerOpenState {
  [key: string]: boolean;
}

export interface ObColorsFieldProps
  extends StateManagedByParentInputValueOnly<Array<ObColorsFieldValue>> {
  /**
   * Unique Id for the input, used for accessibility purposes
   */
  inputId: string;
  /**
   * Configures the colors input with a set of colors that should be picked.
   * Each value in the array will render one color input field in this component.
   */
  colorsToSelect: Array<ObColorFieldColor>;

  /**
   * Called any time a picker is opened or closed.
   * Will be called with a map of color keys to boolean values indicating whether the picker is open or closed.
   *
   * This is useful when a parent component wants to wait for the picker to close before responding to an update.
   * This occurs often due to the fact that dragging the color picker will trigger a change event and when going from
   * no value to setting a value a user may continue to fine tune the color before closing the picker.
   */
  onColorPickerOpenedStateChangedCallback?: (
    colorPickerOpenState: ObColorPickerOpenState
  ) => void;
}

export const ObColorsField = ({
  inputId,
  colorsToSelect = [],
  value,
  onValueChangedCallback,
  onColorPickerOpenedStateChangedCallback,
}: ObColorsFieldProps) => {
  /**
   * Prevent side effects from running on initial mount
   */
  const isInitialMount = useRef(true);
  /**
   * Tracks whether the user is currently setting the initial color value
   * We show two different UXs for setting the initial color value and changing an existing color value
   * Without this logic the color picker for the initial color value UX is immediately closed after selecting a valid
   * color which is not what a user expects when using a color picker.
   *
   * This state is used to hold off the changing of the UX from the initial color value to the existing color value
   * until the user manually closes the color picker.
   */
  const [isSettingInitialColorValue, setIsSettingInitialColorValue] = useState(
    () => {
      const map: { [key: string]: boolean } = {};
      colorsToSelect.forEach((color) => {
        map[color.key] = false;
      });
      return map;
    }
  );

  const [isPickerOpenForColor, setIsPickerOpenForColor] =
    useState<ObColorPickerOpenState>(() => {
      const map: { [key: string]: boolean } = {};
      colorsToSelect.forEach((color) => {
        map[color.key] = false;
      });
      return map;
    });

  const setPickerOpenStateForColor = useCallback(
    (colorKey: string, isOpen: boolean) => {
      setIsPickerOpenForColor((currentValue) => {
        return {
          ...currentValue,
          [colorKey]: isOpen,
        };
      });
    },
    []
  );

  const configurePickerForFirstPick = useCallback(
    (color: ObColorFieldColor, firstPickMode: boolean) => {
      setIsSettingInitialColorValue((currentValue) => {
        return {
          ...currentValue,
          [color.key]: firstPickMode,
        };
      });
    },
    []
  );

  /**
   * Side Effect to update the value when the picker is opened or closed
   */
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    onColorPickerOpenedStateChangedCallback?.(isPickerOpenForColor); // Propagate changes to the parent component
  }, [isPickerOpenForColor, onColorPickerOpenedStateChangedCallback]);

  const handleInputChange = useCallback(
    (
      key: string,
      newValue: string,
      existingValues: Array<ObColorsFieldValue>
    ) => {
      let updatedValue: Array<ObColorsFieldValue>;

      if (existingValues.find((color) => color.key === key)) {
        updatedValue = existingValues?.map((color) => {
          if (color.key === key) {
            return {
              ...color,
              value: newValue?.toUpperCase() ?? '',
            };
          }
          return color;
        });
      } else {
        updatedValue = [
          ...existingValues,
          {
            key,
            value: newValue?.toUpperCase() ?? '',
          },
        ];
      }
      onValueChangedCallback(updatedValue); // Propagate changes to the parent component
    },
    [onValueChangedCallback]
  );

  return (
    <div className='flex flex-col sm:flex-row sm:gap-8'>
      {colorsToSelect.map((color) => {
        const id = `${inputId}__${color.key}`;
        const selectedColor = value?.find((c) => c.key === color.key);
        return (
          <div
            key={id}
            className='max-w-[136px]'
          >
            <ObField
              label={color.label}
              inputId={id}
              variant={'field-in-group'}
              isRequired={color.isRequired}
            >
              {(!selectedColor?.value ||
                isSettingInitialColorValue[selectedColor.key]) && (
                <ObColorPicker
                  selectedColor={selectedColor?.value ?? ''}
                  optionalColors={[]}
                  onOpen={() => {
                    configurePickerForFirstPick(color, true);
                    setPickerOpenStateForColor(color.key, true);
                  }}
                  onClose={() => {
                    configurePickerForFirstPick(color, false);
                    setPickerOpenStateForColor(color.key, false);
                  }}
                  onChange={(nextColor) => {
                    handleInputChange(color.key, nextColor.hex, value);
                  }}
                  popoverButton={
                    <PopoverButton
                      aria-label={`Add a ${color.label} color`}
                      className='h-[45px] w-[136px] flex justify-center items-center border-actionSecondaryDark dark:bg-bgSurfaceDark/[.84] border rounded border-dashed dark:border-actionSecondaryHoverBorderDark/[.16] hover:dark:bg-bgSurfaceDark'
                    >
                      <div className='flex flex-row gap-1 justify-center items-center mr-2'>
                        <ObIcon
                          icon='add'
                          size='small'
                          color='positive'
                        />
                        <ObTypography color='secondary'>
                          Add a Color
                        </ObTypography>
                      </div>
                    </PopoverButton>
                  }
                />
              )}
              {selectedColor?.value &&
                !isSettingInitialColorValue[selectedColor.key] && (
                  <div className='relative group '>
                    <ObInputColor
                      inputId={id}
                      label={color.label}
                      value={selectedColor?.value}
                      onPickerOpenedCallback={() => {
                        setPickerOpenStateForColor(selectedColor.key, true);
                      }}
                      onPickerClosedCallback={() =>
                        setPickerOpenStateForColor(selectedColor.key, false)
                      }
                      onChangeCallback={(nextColor) => {
                        handleInputChange(color.key, nextColor, value);
                      }}
                    />
                    <div className='absolute -top-3 -right-3 opacity-0 group-hover:opacity-100 transition-opacity'>
                      <button
                        className='origin-center hover:h-7 hover:w-7 transition-all duration-300 rounded-full h-6 w-6 flex justify-center items-center bg-contextualNegativeDark border border-borderNegativeNormalDark'
                        type='button'
                        aria-label={`Remove ${color.label} color`}
                        onClick={() => handleInputChange(color.key, '', value)}
                      >
                        <ObIcon
                          icon='close'
                          color='content'
                          size={'x-small'}
                        />
                      </button>
                    </div>
                  </div>
                )}
            </ObField>
          </div>
        );
      })}
    </div>
  );
};
