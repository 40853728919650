import { AxiosInstance } from 'axios';

class CampaignDeploymentTransport {
  private axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  public postCampaignDeployment = async (campaignId: string) => {
    return this.axios.post(`/campaigns/${campaignId}/deployments`);
  };
}

export default CampaignDeploymentTransport;
