'use client';
import { cx } from 'class-variance-authority';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { ObIcon, ObTypography } from '../../../index';
import { IconSystemKeys } from '../../../tokens/icons/icons';

export type ObWizardStepProps = {
  children: React.ReactNode;
  complete: boolean;
  expanded: boolean;
  title?: string;
  subTitle?: string;
  icon: IconSystemKeys;
};

export type ObWizardStepRefProps = {
  toggleExpandedState: () => void;
  toggleCompleteState: () => void;
  setExpandedState: (value: boolean) => void;
  setCompleteState: (value: boolean) => void;
};

export const ObWizardStep = forwardRef<any, ObWizardStepProps>(
  (
    {
      children,
      complete = false,
      expanded = true,
      title = '',
      subTitle = '',
      icon,
    },
    ref
  ) => {
    const [isExpanded, setIsExpanded] = useState(expanded);
    const [isComplete, setIsComplete] = useState(complete);

    useEffect(() => setIsExpanded(expanded), [expanded]);
    useEffect(() => setIsComplete(complete), [complete]);

    useImperativeHandle(
      ref,
      () => ({
        toggleExpandedState: () => setIsExpanded(!isExpanded),
        toggleCompleteState: () => setIsComplete(!isComplete),
        setExpandedState: (value: boolean) => setIsExpanded(value),
        setCompleteState: (value: boolean) => setIsComplete(value),
      }),
      [isComplete, isExpanded]
    );

    return (
      <>
        <div
          data-testid='ob-wizard-step'
          className={cx(
            'px-6 py-4 rounded-[8px] flex font-sans border-box border dark:border-borderDefaultNormalDark dark:bg-actionNeutralNormalDark transition-all ease-in-out duration-[350] delay-[450] justify-between items-center',
            isExpanded && 'rounded-b-[0] border-b-[0]'
          )}
        >
          <div className='flex'>
            <div className='w-[48px] h-[48px] p-3 rounded-[8px] dark:bg-bgSurface1Dark'>
              <ObIcon
                icon={icon}
                size='medium'
              />
            </div>

            <div className='h-[48px] ml-4 flex flex-col justify-between'>
              <ObTypography className='!font-bold text-[14px]'>
                {title}
              </ObTypography>
              <ObTypography
                className='!font-medium text-[12px]'
                color='secondary'
              >
                {subTitle}
              </ObTypography>
            </div>
          </div>

          <div
            className={cx(
              'w-5 h-5 rounded-full flex items-center justify-center',
              isComplete
                ? 'dark:bg-actionPrimaryDark'
                : 'border-box border dark:border-borderDefaultNormalLight dark:bg-contentSecondaryDark'
            )}
          >
            {isComplete ? (
              <ObIcon
                icon='check'
                size='x-small'
                data-testid='Completed Step'
              />
            ) : null}
          </div>
        </div>

        <div
          className={cx(
            'px-6 py-4 rounded-t-[0] flex font-sans border-box border rounded-[8px] border-borderDefaultNormalDark  dark:border-t-whiteAlpha-5 dark:bg-actionNeutralNormalDark transition-all ease-in-out duration-[500]',
            isExpanded ? 'min-h-[200px]' : 'h-0 invisible !py-0'
          )}
        >
          <div
            className={cx(
              'w-full flex rounded-[8px]',
              isExpanded ? 'visible' : 'invisible'
            )}
          >
            {children}
          </div>
        </div>
      </>
    );
  }
);
