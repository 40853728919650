import {
  ObGridHeader,
  ObImageCheckboxContainer,
  ObThinkingButton,
  ObTypography,
} from '@outbound/design-system';
import { AssetScanResource, SelectedAssetResource } from '@outbound/types';
import { FC, useState, useEffect } from 'react';

/**
 * Props for the WebsiteAssetResults component
 */
export type WebsiteAssetResultsProps = {
  availableAssets?: Array<AssetScanResource>;
  onSubmitCallback: (selectedAssets: AssetScanResource[]) => Promise<any>;
  onAssetSelected?: (asset: AssetScanResource) => any;
  onAssetDeselected?: (asset: AssetScanResource) => any;
  initialAssets?: SelectedAssetResource[];
};

export type AssetScanResourceWithCategory = AssetScanResource & {
  classification: 'CONTENT' | 'LOGO';
};

/**
 * Displays and allows the user to select assets from the website scan.
 * @param param0
 * @returns
 */
export const WebsiteAssetResults: FC<WebsiteAssetResultsProps> = ({
  availableAssets = [],
  onSubmitCallback,
  onAssetSelected,
  onAssetDeselected,
  initialAssets,
}: WebsiteAssetResultsProps) => {
  /**
   * Tracks the currently selected assets.
   */
  const [selectedAssets, setSelectedAssets] = useState<
    AssetScanResourceWithCategory[]
  >([]);

  useEffect(() => {
    if (initialAssets?.length && availableAssets?.length) {
      /**
       * Since the user has already selected some assets we will rebuild the page
       * from their previous selections.
       */
      setSelectedAssets(
        initialAssets.flatMap((initialSelectedAsset) => {
          const assetScanResource = availableAssets.find(
            (availableAsset) => availableAsset.id === initialSelectedAsset.id
          );
          /**
           * Combine the selected asset's categorization with the asset scan resource
           * so we can repopulate the page with the user's previous selections.
           *
           * If for some reason there is a selected asset that is not in the available assets (Which should never happen)
           * we will return an empty array which will cause the flatMap function to effectively ignore it.
           */
          return assetScanResource
            ? [{ ...initialSelectedAsset, ...assetScanResource }]
            : [];
        })
      );
    } else {
      /**
       * The default category for assets is CONTENT.
       */
      setSelectedAssets(
        availableAssets.map((asset) => ({
          ...asset,
          classification: 'CONTENT',
        }))
      );
    }
  }, [initialAssets, availableAssets]);

  /**
   * Tracks the number of selected assets.
   */
  const [selectedCount, setSelectedCount] = useState<number>(0);

  /**
   * Update the Selected Count when the selected assets change.
   */
  useEffect(() => {
    setSelectedCount(selectedAssets.length);
  }, [selectedAssets]);

  /**
   * Called when the user changes category on an asset.
   * Provides a way for the parent component to be notified of the change
   * @param asset
   */
  const handleCategoryChanged = (
    changedImageId: string,
    category: 'CONTENT' | 'LOGO'
  ) => {
    const newAssets = selectedAssets.map((asset) =>
      asset.id === changedImageId ? { ...asset, category } : asset
    );
    setSelectedAssets(newAssets);
  };

  /**
   * Called when the user deselects an asset.
   * Provides a way for the parent component to be notified of the deselection in real time
   * @param asset
   */
  const handleAssetDeselected = (asset: AssetScanResource) => {
    if (onAssetDeselected) {
      onAssetDeselected(asset);
    }
  };

  /**
   * Called when the user selects an asset.
   * Provides a way for the parent component to be notified of the selection in real time
   * @param asset
   */
  const handleAssetSelected = (asset: AssetScanResource) => {
    if (onAssetSelected) {
      onAssetSelected(asset);
    }
  };

  /**
   * Called when the user clicks the "Continue" button.
   * Returns the selected assets to the parent component.
   * @param selectedAssets
   */
  const onAssetsImportsFinalized = async () => {
    if (onSubmitCallback) {
      await onSubmitCallback(selectedAssets);
    }
  };

  const onCheckboxChange = (
    checked: boolean,
    classification: 'CONTENT' | 'LOGO',
    image: AssetScanResource
  ) => {
    if (checked) {
      handleAssetSelected(image);
      setSelectedAssets([...selectedAssets, { ...image, classification }]);
    } else {
      handleAssetDeselected(image);
      setSelectedAssets(
        selectedAssets.filter((a: AssetScanResource) => a.id !== image.id)
      );
    }
  };
  return (
    <div
      data-testid='asset-management-step'
      className='flex flex-col gap-8 items-center justify-center p-8 '
    >
      <ObTypography variant='h1'>
        Select images to use in your ads!
      </ObTypography>
      <ObTypography
        variant='h3'
        color='secondary'
        className='text-center'
      >
        "Content" is for Ads, "My Logo" lets us know the image represents your
        brand.
      </ObTypography>

      <div className='gap-4 flex flex-col h-full'>
        <ObGridHeader
          title={'Selected for Import'}
          count={selectedCount}
          controls={<></>}
        />

        <section className='gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
          {availableAssets.map((image, index) => {
            const initialAsset = initialAssets?.find(
              (asset) => asset.id === image.id
            );

            const initialChecked = initialAssets?.length
              ? initialAssets.some((asset) => asset.id === image.id)
              : true;

            return (
              <ObImageCheckboxContainer
                id={`image-checkbox-${index}`}
                label={`Image ${index}`}
                key={image.publicUrl}
                imageUrl={image.publicUrl}
                onCheckboxChange={(checked, classification) =>
                  onCheckboxChange(checked, classification, image)
                }
                initialChecked={initialChecked}
                onCategoryChange={(category) =>
                  handleCategoryChanged(image.id, category)
                }
                classification={initialAsset?.classification}
              />
            );
          })}
        </section>
      </div>

      <div className='w-full max-w-[400px]'>
        <ObThinkingButton
          variant='primary'
          size='xlarge'
          label='Continue'
          fullWidth={'always'}
          onClickCallback={onAssetsImportsFinalized}
          type='submit'
        />
      </div>
    </div>
  );
};
