import { ServerDelta, TemplateSettingFieldType } from '@outbound/types';
import { BaseModel } from '../../../base-model';

/**
 * Defines a Setting of a Template.
 * This definition has no value since it is used to describe the setting
 * that a creative that uses this template will have.
 */
class SettingDefinition extends BaseModel {
  private _name: string;
  private _type: TemplateSettingFieldType;
  private _assignedSectionIds: Array<string> = [];

  constructor(
    rootStore: any,
    id: string,
    name: string,
    type: TemplateSettingFieldType,
    assignedSectionIds: Array<string>
  ) {
    super(rootStore, 'creative-template/setting-definition', '1', id, 'system');
    this._name = name;
    this._type = type;
    this._assignedSectionIds = assignedSectionIds;
  }

  get name(): string {
    return this._name;
  }

  get type(): TemplateSettingFieldType {
    return this._type;
  }

  get assignedSettingIds(): Array<string> {
    return this._assignedSectionIds;
  }

  protected makeObservableInternal(): void {
    //Read Only Model. No need to make observable
  }

  applyPatch(_patch: ServerDelta[]): void {
    throw new Error('Templates are Readonly');
  }
  toJson(): Record<string, any> {
    return {
      id: this.id,
      name: this._name,
      type: this._type,
      assignedSectionIds: this._assignedSectionIds,
    };
  }
}

export default SettingDefinition;
