import { BaseFormFieldOption } from '@outbound/design-system';
import {
  BrandGuideResource,
  BrandGuideResourceIncludeAsset,
} from '@outbound/types';

export interface BrandGuideFormValues {
  colors: Array<BaseFormFieldOption>;
  logos: Array<BaseFormFieldOption>;
  icons: Array<BaseFormFieldOption>;
  tagLine: string;
}

const getLogoByKeyFromForm = (
  logoKey: string,
  formValues: BrandGuideFormValues
): string => {
  return formValues.logos.find((asset) => asset.key === logoKey)?.value ?? '';
};

const getIconByKeyFromForm = (
  iconKey: string,
  formValues: BrandGuideFormValues
): string => {
  return formValues.icons.find((asset) => asset.key === iconKey)?.value ?? '';
};

export const mapFormValuesToApiResource = (
  formValues: BrandGuideFormValues
): BrandGuideResource => {
  return {
    primaryColor:
      formValues.colors.find((color) => color.key === 'primaryColor')?.value ??
      '',
    secondaryColor:
      formValues.colors.find((color) => color.key === 'secondaryColor')
        ?.value ?? '',
    tagLine: formValues.tagLine,
    logoSingleColorWhiteForDarkBackgroundAssetId: getLogoByKeyFromForm(
      'logoSingleColorWhiteForDarkBackgroundAssetId',
      formValues
    ),
    logoSingleColorBlackForLightBackgroundAssetId: getLogoByKeyFromForm(
      'logoSingleColorBlackForLightBackgroundAssetId',
      formValues
    ),
    logoSingleColorBrandForLightBackgroundAssetId: getLogoByKeyFromForm(
      'logoSingleColorBrandForLightBackgroundAssetId',
      formValues
    ),
    logoSingleColorBrandForDarkBackgroundAssetId: getLogoByKeyFromForm(
      'logoSingleColorBrandForDarkBackgroundAssetId',
      formValues
    ),
    logoGreyScaleForDarkBackgroundAssetId: getLogoByKeyFromForm(
      'logoGreyScaleForDarkBackgroundAssetId',
      formValues
    ),
    logoGreyScaleForLightBackgroundAssetId: getLogoByKeyFromForm(
      'logoGreyScaleForLightBackgroundAssetId',
      formValues
    ),
    logoFullColorForLightBackgroundAssetId: getLogoByKeyFromForm(
      'logoFullColorForLightBackgroundAssetId',
      formValues
    ),
    logoFullColorForDarkBackgroundAssetId: getLogoByKeyFromForm(
      'logoFullColorForDarkBackgroundAssetId',
      formValues
    ),
    iconSingleColorWhiteForDarkBackgroundAssetId: getIconByKeyFromForm(
      'iconSingleColorWhiteForDarkBackgroundAssetId',
      formValues
    ),
    iconSingleColorBlackForLightBackgroundAssetId: getIconByKeyFromForm(
      'iconSingleColorBlackForLightBackgroundAssetId',
      formValues
    ),
    iconSingleColorBrandForLightBackgroundAssetId: getIconByKeyFromForm(
      'iconSingleColorBrandForLightBackgroundAssetId',
      formValues
    ),
    iconSingleColorBrandForDarkBackgroundAssetId: getIconByKeyFromForm(
      'iconSingleColorBrandForDarkBackgroundAssetId',
      formValues
    ),
    iconGreyScaleForDarkBackgroundAssetId: getIconByKeyFromForm(
      'iconGreyScaleForDarkBackgroundAssetId',
      formValues
    ),
    iconGreyScaleForLightBackgroundAssetId: getIconByKeyFromForm(
      'iconGreyScaleForLightBackgroundAssetId',
      formValues
    ),
    iconFullColorForLightBackgroundAssetId: getIconByKeyFromForm(
      'iconFullColorForLightBackgroundAssetId',
      formValues
    ),
    iconFullColorForDarkBackgroundAssetId: getIconByKeyFromForm(
      'iconFullColorForDarkBackgroundAssetId',
      formValues
    ),
  };
};

export const mapApiResourceToFormValues = (
  resource: BrandGuideResourceIncludeAsset & BrandGuideResource
): BrandGuideFormValues => {
  return {
    colors: [
      { key: 'primaryColor', value: resource?.primaryColor ?? '' },
      { key: 'secondaryColor', value: resource?.secondaryColor ?? '' },
    ],
    tagLine: resource?.tagLine ?? '',
    logos: [
      {
        key: 'logoSingleColorWhiteForDarkBackgroundAssetId',
        value: resource?.logoSingleColorWhiteForDarkBackgroundAssetId ?? '',
        data: resource?.logoSingleColorWhiteForDarkBackgroundAsset,
      },
      {
        key: 'logoSingleColorBlackForLightBackgroundAssetId',
        value: resource?.logoSingleColorBlackForLightBackgroundAssetId ?? '',
        data: resource?.logoSingleColorBlackForLightBackgroundAsset,
      },
      {
        key: 'logoSingleColorBrandForDarkBackgroundAssetId',
        value: resource?.logoSingleColorBrandForDarkBackgroundAssetId ?? '',
        data: resource?.logoSingleColorBrandForDarkBackgroundAsset,
      },
      {
        key: 'logoSingleColorBrandForLightBackgroundAssetId',
        value: resource?.logoSingleColorBrandForLightBackgroundAssetId ?? '',
        data: resource?.logoSingleColorBrandForLightBackgroundAsset,
      },
      {
        key: 'logoGreyScaleForDarkBackgroundAssetId',
        value: resource?.logoGreyScaleForDarkBackgroundAssetId ?? '',
        data: resource?.logoGreyScaleForDarkBackgroundAsset,
      },
      {
        key: 'logoGreyScaleForLightBackgroundAssetId',
        value: resource?.logoGreyScaleForLightBackgroundAssetId ?? '',
        data: resource?.logoGreyScaleForLightBackgroundAsset,
      },
      {
        key: 'logoFullColorForDarkBackgroundAssetId',
        value: resource?.logoFullColorForDarkBackgroundAssetId ?? '',
        data: resource?.logoFullColorForDarkBackgroundAsset,
      },
      {
        key: 'logoFullColorForLightBackgroundAssetId',
        value: resource?.logoFullColorForLightBackgroundAssetId ?? '',
        data: resource?.logoFullColorForLightBackgroundAsset,
      },
    ],
    icons: [
      {
        key: 'iconSingleColorWhiteForDarkBackgroundAssetId',
        value: resource?.iconSingleColorWhiteForDarkBackgroundAssetId ?? '',
        data: resource?.iconSingleColorWhiteForDarkBackgroundAsset,
      },
      {
        key: 'iconSingleColorBlackForLightBackgroundAssetId',
        value: resource?.iconSingleColorBlackForLightBackgroundAssetId ?? '',
        data: resource?.iconSingleColorBlackForLightBackgroundAsset,
      },
      {
        key: 'iconSingleColorBrandForLightBackgroundAssetId',
        value: resource?.iconSingleColorBrandForLightBackgroundAssetId ?? '',
        data: resource?.iconSingleColorBrandForLightBackgroundAsset,
      },
      {
        key: 'iconSingleColorBrandForDarkBackgroundAssetId',
        value: resource?.iconSingleColorBrandForDarkBackgroundAssetId ?? '',
        data: resource?.iconSingleColorBrandForDarkBackgroundAsset,
      },
      {
        key: 'iconGreyScaleForDarkBackgroundAssetId',
        value: resource?.iconGreyScaleForDarkBackgroundAssetId ?? '',
        data: resource?.iconGreyScaleForDarkBackgroundAsset,
      },
      {
        key: 'iconGreyScaleForLightBackgroundAssetId',
        value: resource?.iconGreyScaleForLightBackgroundAssetId ?? '',
        data: resource?.iconGreyScaleForLightBackgroundAsset,
      },
      {
        key: 'iconFullColorForLightBackgroundAssetId',
        value: resource?.iconFullColorForLightBackgroundAssetId ?? '',
        data: resource?.iconFullColorForLightBackgroundAsset,
      },
      {
        key: 'iconFullColorForDarkBackgroundAssetId',
        value: resource?.iconFullColorForDarkBackgroundAssetId ?? '',
        data: resource?.iconFullColorForDarkBackgroundAsset,
      },
    ],
  };
};
