import { AssetResource } from '../asset-manager/asset-manager.api.type';
import { ColorResource } from '../playbook/playbook.api.type';
/**
 * API Request for Creating a new creative
 */
export interface PostCreativeRequestResource {
  /**
   * Optional : The ID of the template that we want the creative to use.
   * If not supplied the default template for the medium will be used.
   * If supplied it must be a valid template for the medium
   */
  templateId?: string;

  /**
   * The human readable name of the creative
   */
  name: string;

  /**
   * The format of creative that we are making
   * This will limit the types of templates that can be used for this creative to those that are in scope for the medium.
   */
  medium: TemplateMedium;

  /**
   * What personas are we targeting with this campaign
   */
  personaIds: string[];

  /**
   * What services are we advertising
   */
  serviceIds: string[];

  /**
   * What physical locations are we targeting
   */
  physicalLocationIds: string[];

  /**
   * What service areas are we targeting
   */
  serviceAreaIds: string[];
  /**
   * Optional : Context that can be used to generate suggested creative
   */
  mediumContext?: Record<string, any>;
}

/**
 * API Request body when updating one or more of the template settings for a creative
 */
export type PatchTemplateValuesRequestResource = {
  /**
   * A partial set of the template settings. You can / should include only those that we want to update
   */
  templateSettingValues: Array<CreativeTemplateValueResource>;
};

export interface CreativePathParameters {
  creativeId: string;
}

export interface CreativeQueryParameters {
  cursor?: string;
}

export interface CreativeVersionQueryParameters {
  at?: string;
  limit?: number;
}

export interface AlternativeCreativeContentResource {
  /**
   * A Unique ID that can be used to identify the set of alternative values
   **/
  id: string;
  label?: string;
  templateValues: Array<BaseTemplateValueResource>;
}

export const CreativeStatusTypeValues = [
  /**
   * The creative id has been persisted but the template settings have not been initialized.
   * The creative is not ready to be displayed or edited while in this phase. Consumers should wait until the creative is in the DRAFT state
   * before attempting to display it.
   */
  'PENDING_INITIALIZATION',
  /**
   * The creative is available to be displayed and edited in this phase
   */
  'ACTIVE',
] as const;
export type CreativeStatusType = (typeof CreativeStatusTypeValues)[number];

/**
 * The resource that is returned when a creative is requested
 */
export interface CreativeResource {
  /**
   * Unique identifier of the creative
   */
  id: string;

  workspaceId: string;
  /**
   * The human readable name of the creative
   */
  name: string;
  /**
   * The current state of the creative
   */
  status: CreativeStatusType;

  /**
   * Defines the medium that the creative is for.
   * This will limit the types of templates that can be used for this creative to those that are in scope for the medium.
   *
   * Additionally this information is used when the creative is first created to generate suggested content for the values of the template settings
   * based on the business's playbook
   */
  medium: TemplateMedium | TemplateMediumType;

  /**
   * The specific version of the template that this creative is populating
   */
  templateId: string;

  /**
   * The specific version of the template that this creative is populating
   */
  templateVersion: string;

  /**
   * The template schema that corresponds to the templateId.
   * This value should not be persisted from the DB but instead be populated by the resource assembler on the resources way out of the API.
   * The template schema is static and does not need to be stored over and over in creative
   */
  template: TemplateSchemaResource;

  /**
   * The current values of the template settings
   * This is a snapshot from the db. In the future our frontend
   * will interact with these over a web-socket connection and this may
   * be replaced at that point but for now we will assume single player editing
   * and have a simple api state management of posting and getting the complete set of fields
   */
  templateSettingFieldValues: Array<CreativeTemplateValueResource>;

  /**
   * Indicates the minimum number of alternative values that this template wants for each field
   */
  minimumAlternativeTemplateValues?: number;

  /**
   * Users may want to provide, or we may request additional values for the template settings that we would want to use
   * as alternatives to run experimentation on the creative in order to maximize return on investment.
   */
  alternativeTemplateValues: Array<AlternativeCreativeContentResource>;

  /**
   *
   */
  suggestedContent: Array<SuggestedContentGroup>;

  validationResult: ValidationResultResource;
}

export interface ValidationResultResource {
  results: {
    [key: string]: {
      value: any;
      isValid: boolean;
      errors: string[];
      id: string;
    };
  };
  isValid: boolean;
}

/**
 * Temporary shape. This is what the frontend wants to show to the user for each specific
 * field with issues.
 *
 * The goals are, what is wrong with what setting and do we have enough data to navigate to it.
 * The label answers "What Field", the errors answer "What is wrong", and the id answers "How can we navigate to it"
 *
 *  I am not sure if this will be generated in the API Resource Mapper or if we will
 * do this in the frontend in the State Layer. Adding it here so we can import it
 * to both places and will defer the choice to later.
 */
export interface CreativeTemplateSettingValidationResultResource {
  /**
   * The ID of the template setting that this validation result is for
   */
  templateFieldSetting: Pick<
    BaseTemplateSettingDefinitionResource,
    'id' | 'label'
  >;
  /**
   * Array of error descriptions that are issues with the field.
   * Assumed that this is ordered by priority with the most important issues first.
   * Only the first issue will be displayed to the user.
   */
  errors: Array<string>;
}

/**
 * Response returned from the API  when requesting the values of a creative's template settings
 */
export interface GetCreativeTemplateSettingValuesResponseResource {
  templateSettingValues: Array<CreativeTemplateValueResource>;
}

/**
 * The actual content that is suggested for a specific setting field.
 * Example: "Summer Sale" for a heading
 */
export interface SuggestedFieldContent {
  /**
   * Unique identifier that allows us to track this suggestion
   */
  id: string;

  /**
   * The suggestion group that this suggestion belongs to
   */
  suggestionGroupId: string;

  /**
   * The id of the field that this suggestion is for
   */
  settingFieldId: string;

  /**
   * The type of field this suggestion is for
   */
  type: TemplateSettingFieldType;

  /**
   * The actual suggested content
   * We may extend this interface and override this based on the field type
   * to allow for different types of content to be suggested
   */
  value: string;

  //FUTURE USE IDEAS TO BUILD DATA AROUND WHAT OUR USERS LIKE AND DON"T LIKE
  /**
   * //Track if the user has seen this value
   * viewedByUser: boolean;
   *
   * //Track if the user has selected this value and published creative with it
   * selectedByUser: boolean;
   *
   * //Track if the user has selected this value and than modified it
   * modifiedByUser: boolean;
   *
   * //Track if the user would like to see more content like this
   * seeMoreLikeThis: boolean;
   *
   * //Track if the user would like to see less content like this
   * seeLessLikeThis: boolean;
   */
}

/**
 * A group of suggested content that is applicable to a set of setting fields and are conceptually related.
 * Example: A suggestion group may contain suggestions for a heading and a description that are related to each other.
 *          Heading: "Summer Sale"
 *          Description: "Get 50% off all summer items"
 */
export interface SuggestedContentGroup {
  /**
   * Randomly generated id that allows us to track this suggestion group
   */
  id: string;
  /**
   *The ids of the fields that this suggestion group contains content for
   */
  settingFieldIdsInScopeForSuggestion: Array<string>;
  /**
   * The actual suggested content
   */
  suggestedContent: Array<SuggestedFieldContent>;
  /**
   * The ids of the fields that this suggestion group is based on
   */
  settingFieldIdsSuggestionIsBasedOn: Array<string>;

  /**
   * Indicates which prompt level this suggestion group is associated with
   */
  level: 'medium' | 'template' | 'field';
}

export interface TemplateFieldValue {
  id: string;
  value: string;
}

/**
 * Describes the Type of the field. This information is used to determine what
 * component to use to render the field and the shape of its value data
 */
export type TemplateSettingFieldType = 'text' | 'image' | 'color';

export const FIELD_NON_NULL_DEFAULT_VALUE__TEXT = '';
export const FIELD_NON_NULL_DEFAULT_VALUE__TEXT_AREA = '';

export type CreativeTemplateValueResource =
  | BaseBuilderTemplateValueProps
  | TextTemplateValueResource
  | ColorTemplateValueResource
  | ImageTemplateValueResource;

/**
 * API resource the describes the VALUE of a template setting field.
 * Each value's id and type should match the id and type of the BaseTemplateSettingsFieldResource it is associated with.
 *
 * Example:
 * If there is a BaseTemplateSettingsFieldResource who's
 *   label = "Primary Color"
 *    type = "color"
 *      id = 'PRIMARY_COLOR'
 * Than this value field should look like:
 *    type = "color"
 *      id = 'PRIMARY_COLOR'
 *   value = "#FF0000"
 */
export type BaseTemplateValueResource = {
  /**
   * A ID that uniquely identifies the value of the field within the creative service
   */
  id: string;

  /**
   * The outbound resource name that uniquely identifies this creative setting value withing the outbound platform
   */
  obrn: string;

  /**
   * Describes the Type of the field. This information is used to determine what
   * component to use to render the field and the shape of its value data
   */
  type: TemplateSettingFieldType;
  /**
   * The current value of the field.
   */
  value: any | null;
};

export interface TemplateValueBuilderProps {
  /**
   * Indicates that this field is currently locked for remixing
   */
  remixLocked: boolean;
  /**
   * Indicates the last time this value was updated.
   * This value will be used in last update wins conflict resolution
   */
  updatedAtTimestamp: string;
}

export interface BaseBuilderTemplateValueProps
  extends TemplateValueBuilderProps,
    BaseTemplateValueResource {}

export interface TextTemplateValueResource
  extends BaseTemplateValueResource,
    TemplateValueBuilderProps {
  type: 'text';
  value: {
    text: string;
  };
}

/**
 * Type Guard for TextTemplateValueResource
 * @param baseTemplateValue
 * @returns
 */
export const isTextTemplateValueResource = (
  baseTemplateValue: BaseTemplateValueResource
): baseTemplateValue is TextTemplateValueResource => {
  return baseTemplateValue.type === 'text';
};

export type ColorSettingFieldValue = ColorResource | null;

export interface ColorTemplateValueResource
  extends BaseTemplateValueResource,
    TemplateValueBuilderProps {
  type: 'color';
  value: ColorSettingFieldValue;
}

/**
 * Type Guard for ColorTemplateValueResource
 * @param baseTemplateValue
 * @returns
 */
export const isColorTemplateValueResource = (
  baseTemplateValue: BaseTemplateValueResource
): baseTemplateValue is ColorTemplateValueResource => {
  return baseTemplateValue.type === 'color';
};

export interface ImageTemplateValueResource
  extends BaseTemplateValueResource,
    TemplateValueBuilderProps {
  type: 'image';
  value: ImageSettingFieldValue;
}

/**
 * Type Guard for ImageTemplateValueResource
 * @param baseTemplateValue
 * @returns
 */
export const isImageTemplateValueResource = (
  baseTemplateValue: BaseTemplateValueResource
): baseTemplateValue is ImageTemplateValueResource => {
  return baseTemplateValue.type === 'image';
};

export interface BasePublishingValidation {
  /**
   * Indicates that the field is required to be filled out before the creative can be published
   */
  isRequired: boolean;
}

/**
 * API resource that describes the required properties for a template settings field.
 * It is expected that each field type will extend this.
 *
 * This interface is useful for logic that needs to work across several field types on
 * required properties such as id or type
 */
export interface BaseTemplateSettingDefinitionResource {
  id: string;

  label: string;
  /**
   * Describes the Type of the field. This information is used to determine what
   * component to use to render the field and the shape of its value data
   */
  type: TemplateSettingFieldType;
  /** Indicates what groups this field should appear in from a UI perspective. The data model acts as tags and allows the field to appear in more than one group */
  sectionAssignment?: Array<string>;

  /**
   * Validation rules that must be satisfied before the creative can be published
   */
  publishingValidation: BasePublishingValidation;
}

/**
 * API resource that describes the value of a color setting field
 */
export interface ColorSettingDefinitionResource
  extends BaseTemplateSettingDefinitionResource {
  /**
   * Controls what the purpose of the color setting is for.
   * This will impact the default value and the suggested content that is provided.
   */
  colorIntent: 'primary' | 'secondary' | 'any';
  /**
   * Hex Code that should be used when no other value is available.
   * When the intent is primary or secondary this value will only be used
   * if there is not a value in the brand's playbook for the primary or secondary color.
   */
  defaultValue: string;
}

/**
 * Indicates the source of the default value for a field
 * String means that the value is a string literal that should be used.
 * Context means that the value should be pulled from context object passed to the creative. The value
 * will be a JSON path to the value in the context object.
 */
export type DefaultValueSourceType = 'string-literal' | 'context-json-path';

export interface DefaultValueSource {
  source: DefaultValueSourceType;
  value: string;
}

export interface TextSettingValidation extends BasePublishingValidation {
  /**
   * The minimum number of characters this this field can accept
   */
  minLength?: number;
  /**
   * The maximum number of characters that the field can accept
   */
  maxLength?: number;
}

/**
 * API resource that describes the value of a text setting field
 */
export interface TextSettingDefinitionResource
  extends BaseTemplateSettingDefinitionResource {
  /**
   * An array of sources that we can use to get a default value for this field.
   * The first source that has a value will be used.
   */
  defaultValueSource: Array<DefaultValueSource>;
  publishingValidation: TextSettingValidation;
}

export type ImageSettingFieldIntent = 'brand-logo' | 'brand-icon' | 'any';

/**
 * API resource that describes the value of a image setting field
 */
export interface ImageSettingDefinitionResource
  extends BaseTemplateSettingDefinitionResource {
  /**
   * Controls what the purpose of the image setting is for which allows us to
   * handle how the value is remixed, what the defaults are etc.
   */
  imageIntent: ImageSettingFieldIntent;
  /**
   * Indicates that the template prefers a transparent background for the image
   * such as a PNG file. This typically indicates that the image will be used over some content
   * that we want set the subject of the image against.
   */
  imagePrefersTransparentBackground?: boolean;
}

/**
 * Specialized value type for Image Settings.
 * We want to allow our image fields to specify a specific resource (AssetReference) by an asset Id
 * or to use a abstract reference to a brand asset such as logo or icon so that we can dynamically
 * populate the value based on the brand's playbook.
 */
export type ImageSettingFieldValue = AssetReference | null;

export interface AssetReference {
  type: 'assetReference';
  assetId: string;
  asset: AssetResource;
}

/**
 * API Resource that describes the structure of the settings for a template
 */
export interface TemplateSettingsSectionResource {
  /**
   * A string that uniquely identifies the section within the template
   */
  id: string;
  /**
   * The heading for the Section, displayed on the accordion
   */
  heading: string;
  /**
   * An optional way to further organize fields under a common header
   */
  subSections: Array<TemplateSettingsSubSectionResource>;
}

/**
 * API Resource that describes the structure of the sub section of a settings section for a template
 
 */
export interface TemplateSettingsSubSectionResource {
  /**
   * A string that uniquely identifies the section within the parent section
   */
  id: string;
  /**
   * An Optional Heading for a Sub Section
   **/
  heading?: string;
}

export type SettingDefinitionResource =
  | ColorSettingDefinitionResource
  | TextSettingDefinitionResource
  | ImageSettingDefinitionResource;

export const TemplateMediumValues = [
  'LANDING_PAGE',
  'GOOGLE_SEARCH',
  'DISPLAY_AD_ANIMATED',
  'DISPLAY_AD_STATIC',
] as const;

export type TemplateMediumType = (typeof TemplateMediumValues)[number];

// FUTURE CONVERT THIS TO A TYPE USING THE VALUES ABOVE
export enum TemplateMedium {
  LANDING_PAGE = 'LANDING_PAGE',
  GOOGLE_SEARCH = 'GOOGLE_SEARCH',
  DISPLAY_AD_ANIMATED = 'DISPLAY_AD_ANIMATED',
  DISPLAY_AD_STATIC = 'DISPLAY_AD_STATIC',
}

export interface TemplateMediumFieldResource
  extends BaseTemplateSettingDefinitionResource {
  prompt?: string;
}

export interface TemplateMediumResource {
  id: TemplateMedium;
  name: string;
  prompt: string;
  fields: Array<TemplateMediumFieldResource>;
}

export interface CreativeCompletionPrompt {
  promptTemplateId: string;
}

export interface TemplateSchemaResource {
  /**
   * A UUID unique identifier for the template
   */
  id: string;
  /**
   * The human readable name of the template
   */
  name: string;

  /**
   * Describes the type of creative that the template is used for and where creative
   * generated by the template will be shown.
   */
  medium: TemplateMedium | TemplateMediumType;
  /**
   * Prompts for this template that are executed when the template is selected.
   * These prompts will receive the context of the business's playbook for generating the creative.
   *
   * There are also Template Type level prompts that can be used to generate values for fields shared across all templates of a given type.
   * We should first use the type level prompts as those values will be shared across all templates of the same type.
   */
  generativeAiPrompts: Array<CreativeCompletionPrompt>;
  /**
   * Setting Fields for the Template
   */
  settingDefinitions: Array<SettingDefinitionResource>;
  /**
   * Organization for the template settings fields
   */
  settingSections: Array<TemplateSettingsSectionResource>;
  /**
   * The name of the react component that will be used to render the template
   */
  renderComponent: string;
}

export interface CreativeVersionResource {
  id: string;
  templateSettingFieldValues: Array<CreativeTemplateValueResource>;
  isValid: boolean;
  templateId: string;
  medium: TemplateMedium;
  tag: string;
}
