import {
  OnboardingQuestion,
  QUESTION_LEVEL_DELIMITER,
} from './questionnaire.type';

export class QuestionnaireNavigator {
  private questions: OnboardingQuestion[];

  constructor(questions: OnboardingQuestion[]) {
    this.questions = this.decorateQuestionsWithPaths(questions);
  }

  // Decorate each question with its full path
  private decorateQuestionsWithPaths(
    questions: OnboardingQuestion[],
    parentPath: string = ''
  ): OnboardingQuestion[] {
    return questions.map((question) => {
      const fullPath = parentPath
        ? `${parentPath}.${question.id}`
        : question.id;
      const decoratedQuestion = { ...question, fullPath };

      if (question.subQuestions && question.subQuestions.length > 0) {
        decoratedQuestion.subQuestions = this.decorateQuestionsWithPaths(
          question.subQuestions,
          fullPath
        );
      }

      return decoratedQuestion;
    });
  }

  // Retrieve a question by its full path
  getQuestionById(questionPath: string): OnboardingQuestion | null {
    const flatQuestionList = this.flattenQuestions(this.questions);
    return flatQuestionList.find((q) => q.fullPath === questionPath) || null;
  }

  // Adjust getNextQuestion to consider question conditionality based on current answers

  getNextQuestion(
    currentAnswers: { [key: string]: any },
    currentPath: string = ''
  ): OnboardingQuestion | null {
    // Flag indicating if the current question has been found.
    let foundCurrent = false;

    const findNextQuestion = (
      questions: OnboardingQuestion[],
      parentIsInScope: boolean = true,
      startSearch: boolean = false
    ): OnboardingQuestion | null => {
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        const isCurrentQuestion = question.fullPath === currentPath;
        const isInScope =
          question.isQuestionInScope?.(currentAnswers) !== false &&
          parentIsInScope;

        // If we found the current question, start searching for the next in-scope question.
        if (isCurrentQuestion) {
          foundCurrent = true;
          // If there are sub-questions and we're in scope, search them next.
          if (isInScope && question.subQuestions?.length) {
            const subQuestion = findNextQuestion(
              question.subQuestions,
              isInScope,
              true
            );
            if (subQuestion) return subQuestion; // Found next question within sub-questions.
          }
          continue; // Move to search for the next question at the same level or above.
        }

        // If searching for the next question and it's in scope, return it.
        if ((foundCurrent || startSearch) && isInScope) {
          return question;
        }

        // Explore sub-questions if not specifically searching or if the current question is in scope.
        if (question.subQuestions?.length && (!foundCurrent || isInScope)) {
          const nextSubQuestion = findNextQuestion(
            question.subQuestions,
            isInScope,
            foundCurrent
          );
          if (nextSubQuestion) return nextSubQuestion;
        }
      }

      return null; // No next question found.
    };

    return findNextQuestion(this.questions, true, currentPath === '');
  }

  public getQuestionByFullPath(fullPath: string): OnboardingQuestion | null {
    const findQuestion = (
      questions: OnboardingQuestion[],
      path: string
    ): OnboardingQuestion | null => {
      for (const question of questions) {
        if (question.fullPath === path) return question;
        if (question.subQuestions) {
          const found = findQuestion(question.subQuestions, path);
          if (found) return found;
        }
      }
      return null;
    };

    return findQuestion(this.questions, fullPath);
  }

  // New method to account for conditional questions when flattening
  private flattenQuestions(
    questions: OnboardingQuestion[],
    parentPath: string = '',
    currentAnswers: { [key: string]: any } = {}
  ): OnboardingQuestion[] {
    let flatList: OnboardingQuestion[] = [];
    questions.forEach((question) => {
      if (question.isQuestionInScope?.(currentAnswers) !== false) {
        // Include question if it's in scope
        const fullPath = parentPath
          ? `${parentPath}${QUESTION_LEVEL_DELIMITER}${question.id}`
          : question.id;
        const decoratedQuestion = { ...question, fullPath };
        flatList.push(decoratedQuestion);

        if (question.subQuestions && question.subQuestions.length > 0) {
          flatList = flatList.concat(
            this.flattenQuestions(
              question.subQuestions,
              fullPath,
              currentAnswers
            )
          );
        }
      }
    });
    return flatList;
  }
}
