import { useCallback, useEffect, useState } from 'react';
import {
  BaseExposedTextInputProps,
  InputSizeProps,
  StateManagedByParentInputValueOnly,
} from '../../../../base-component-props.type';
import { ObInputControlled } from '../../../elements/ob-input-controlled/ob-input-controlled';

export interface ObInputNumberProps
  extends StateManagedByParentInputValueOnly<number | undefined>,
    InputSizeProps,
    BaseExposedTextInputProps {}

/**
 * An OBInput used for Numeric Values
 *
 * Provides a mask so that the user can only enter in valid numbers and formats the number with commas
 *
 * The value expected and returned by this input is a javascript number.
 *
 * This input is controlled by the parent component.
 * This component will notify the parent that the value has changed by calling the onValueChangedCallback function provided by the parent.
 *
 * @returns
 */
export const ObInputNumber = ({
  inputId,
  value,
  onValueChangedCallback,
  isLoading,
  isErrored,
  isDisabled,
  autoFocus,
  placeholder,
  size,
}: ObInputNumberProps) => {
  /**
   * This component assumes a number is provided as the value. The internal
   * state is here to manage the value as a string on the input.
   */
  const [internalState, setInternalState] = useState<string>(() => {
    //If the value is undefined, we want to display an empty string in the input
    if (value == undefined) {
      return '';
    }
    return value.toString();
  });

  const isValueMathematicallyEqual = useCallback(
    (value: number | undefined, nextValue: string) => {
      if (value == null && nextValue === '') {
        return true;
      } else {
        return value === parseFloat(nextValue);
      }
    },
    []
  );

  /**
   * Side effect of the user changing the input field
   */
  useEffect(() => {
    if (internalState === '' && value != null) {
      onValueChangedCallback(undefined);
    } else if (isValueMathematicallyEqual(value, internalState)) {
      /**
       * Since the values are mathematically equal we don't need to inform the parent about the update.
       * The intent here is prevent unnecessary re-renders but also prevents the parent from updating the value if it is the same.
       * from updating the value of the input and interfering with the user's input when they
       * are in the middle of typing a number such as 10.00001
       */
      return;
    } else {
      const nextValue = parseFloat(internalState);
      onValueChangedCallback(nextValue);
    }
  }, [
    internalState,
    isValueMathematicallyEqual,
    onValueChangedCallback,
    value,
  ]);

  return (
    <ObInputControlled
      size={size}
      inputId={inputId}
      isErrored={isErrored}
      isDisabled={isDisabled}
      autoFocus={autoFocus}
      placeholder={placeholder}
      value={internalState}
      type='number'
      onValueChangedCallback={setInternalState}
      isLoading={isLoading}
      min={0}
      max={100}
      step={'5'}
    />
  );
};
