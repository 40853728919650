import { FC } from 'react';
import { ObSkeleton, ObTypography } from '../../../..';

export interface ObMetricCardProps {
  isLoading: boolean;
  label: string;
  value: string;
}

export const ObMetricCard: FC<ObMetricCardProps> = ({
  isLoading,
  label,
  value,
}) => {
  return (
    <div className='rounded-xl flex flex-1 bg-dark/background/surface p-4  flex-col justify-between'>
      <ObTypography
        variant='h5'
        color='secondary'
      >
        {label}
      </ObTypography>
      {isLoading ? (
        <ObSkeleton
          variant='text'
          height='64px'
        >
          <ObTypography variant='h1'>30</ObTypography>
        </ObSkeleton>
      ) : (
        <ObTypography variant='h1'>{value}</ObTypography>
      )}
    </div>
  );
};
