'use client';
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
} from '@headlessui/react';

import { cx } from 'class-variance-authority';
import { FC, ReactNode } from 'react';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObLink } from '../../elements/ob-link/ob-link';

const labelIsString = (label: string | ReactNode): label is string =>
  typeof label === 'string';

export interface ObPopoverMenuProps {
  label: string | ReactNode;
  children: ReactNode;
  iconRight?: IconSystemKeys | null;
  iconLeft?: IconSystemKeys | null;

  hasOverlay?: boolean;
  hasBorder?: boolean;
  panelClassName?: string;
  popoverClassName?: string;
}

const ObPopoverMenuComponent: FC<ObPopoverMenuProps> = ({
  label,
  children,
  iconRight = 'down',
  iconLeft,
  hasOverlay = false,
  hasBorder = false,
  panelClassName,
  popoverClassName,
}: ObPopoverMenuProps) => {
  const labelContent = labelIsString(label) ? (
    <PopoverButton>
      <ObLink
        iconRight={iconRight ?? undefined}
        iconLeft={iconLeft ?? undefined}
        variant='secondary'
        label={label}
      />
    </PopoverButton>
  ) : (
    <div>{label}</div>
  );

  return (
    <Popover className={cx('relative', popoverClassName)}>
      {labelContent}
      {hasOverlay ? (
        <></> // <PopoverOverlay className='fixed inset-0 cursor-default bg-dark/background/default opacity-30' />
      ) : (
        /**
         * Prevent user clicks from interaction with elements behind the popover (We don't need to see the overlay)
         * There may be a more direct way of doing this but I am on a side-quest while updating headlessui here to v2
         * so I don't have time to dive in deep.
         * */
        <PopoverOverlay className='fixed inset-0 cursor-default ' />
      )}
      <PopoverPanel
        anchor='bottom end'
        className={cx(
          ' dark:bg-bgDefaultDark bg-bgInvertedDark px-2 py-3 shadow-lg ring-1 ring-black ring-opacity-5',
          hasBorder &&
            'border rounded-xl border-borderDefaultNormalDark dark:border-borderDefaultNormalDark',
          panelClassName
        )}
      >
        {children}
      </PopoverPanel>
    </Popover>
  );
};

type ObPopoverMenuType = typeof ObPopoverMenuComponent & {
  Button: typeof PopoverButton;
};

export const ObPopoverMenu: ObPopoverMenuType = Object.assign(
  ObPopoverMenuComponent,
  {
    Button: PopoverButton,
  }
);
