import { cva, cx } from 'class-variance-authority';
import { useEffect, useRef, useState } from 'react';
import { useOnDragAndDropFile } from '../../../hooks/use-on-drag-and-drop-file';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObLoadingSpinner } from '../ob-loading-spinner/ob-loading-spinner';
import { ObProgressBar } from '../ob-progress-bar/ob-progress-bar';
import { ObTypography } from '../ob-typography/ob-typography';
import { useObUploadAllowedFileTypes } from '../ob-upload-allowed-file-types/use-ob-upload-allowed-file-types';
import { ObUploadStatus } from '../ob-upload-status/ob-upload-status';
import { BaseObUploadProps } from '../ob-upload/ob-upload.type';
import { useObUpload } from '../ob-upload/use-ob-upload';

export interface ObUploadButtonProps extends BaseObUploadProps {
  ariaLabel?: string;
  disabled: boolean;
  enableAssetLibrary?: boolean;
  /**
   * The shape of the upload button. Used in conjunction with the size prop
   * If the size is set to 'responsive', the shape will be used to maintain the aspect ratio
   * of either 1:1 or 16:9 depending on the shape. To allow the button to assume the size of the parent container, use 'responsive'
   * for the shape as well.
   */
  shape?: 'square' | 'rectangle' | 'responsive';
  /**
   * The size of the upload button; to allow the button to assume
   * the size of the parent container, use 'responsive'
   */
  size?: 'small' | 'medium' | 'responsive';
}

/**
 * Upload Button Component
 */
export const ObUploadButton: React.FC<ObUploadButtonProps> = ({
  disabled,
  ariaLabel,
  fileUploadHandlerFactory,
  onFileUploadComplete,
  enableAssetLibrary,
  shape = 'rectangle',
  size = 'medium',
  acceptedFileTypes = [],
}: ObUploadButtonProps) => {
  const fileUploadInputRef = useRef<HTMLInputElement>(null);
  const {
    setIsUploading,
    uploadFiles,
    setProgress,
    setIsError,
    setIsSuccess,
    progress,
    isError,
    isUploading,
    isProgressSupported,
    isSuccess,
  } = useObUpload(fileUploadHandlerFactory);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (enableAssetLibrary) {
    } else {
      fileUploadInputRef.current?.click();
    }
  };

  const onNativeFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = fileUploadInputRef.current?.files;
    onFilesDropped(Array.from(files ?? []));
    e.target.value = ''; //Clear the file input
  };

  const [errorMessage, setErrorMessage] = useState('');
  const setErrorState = (message: string) => {
    setErrorMessage(message);
    setIsError(true);
    setIsUploading(false);
    setIsSuccess(false);
    setProgress(0);
    setTimeout(() => {
      setIsError(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 600);
    }, 3000);
  };

  const {
    acceptedExtensionsString,
    findInvalidFileTypes,
    buildFileTypeErrorMessage,
  } = useObUploadAllowedFileTypes(acceptedFileTypes);

  const onFilesDropped = (files: Array<File> | null) => {
    if (uploadFiles == null) {
      return;
    }
    if (files == null || files?.length === 0 || disabled) {
      return;
    }
    const rejectedFileTypes = findInvalidFileTypes(files);
    if (rejectedFileTypes.length > 0) {
      setErrorState(
        buildFileTypeErrorMessage(acceptedFileTypes, rejectedFileTypes)
      );
    } else {
      setIsUploading(true);
      uploadFiles(files).subscribe(
        (progress) => {
          setProgress(progress.files[0]?.progress ?? 0);
          if (
            progress.files[0]?.status === 'complete' &&
            onFileUploadComplete
          ) {
            onFileUploadComplete({
              fileId: progress.files[0]?.uploadedFileId ?? '',
              data: progress.files[0]?.data,
            });
          }
        },
        (error) => {
          setIsError(true);
          setIsUploading(false);
          setIsSuccess(false);
          setProgress(0);
          setTimeout(() => {
            setIsError(false);
          }, 2000);
          setErrorState('Unable to upload file');
          console.error('Error:', error);
        },
        () => {
          setProgress(100);
          setIsUploading(false);
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
        }
      );
    }
  };

  const { setIsDragAndDropDisabled, handleDrag, handleDrop, dragActive } =
    useOnDragAndDropFile(onFilesDropped);

  useEffect(() => {
    setIsDragAndDropDisabled(disabled);
  }, [disabled, setIsDragAndDropDisabled]);

  const label = 'Upload File';
  const buttonStyles = cva(
    [
      'group',
      'relative overflow-hidden',
      'flex flex-col justify-center items-center p-4 ',
      'border rounded border-dashed',
      'transition-colors ease-in-out duration-300',
      'dark:border-dark/border/default/normal',
      '',
    ],
    {
      compoundVariants: [
        {
          size: 'small',
          shape: 'square',
          class: 'h-[64px] w-[64px]',
        },
        {
          size: 'medium',
          shape: 'square',
          class: 'h-[116px] w-[116px]',
        },
        {
          size: 'small',
          shape: 'rectangle',
          class: 'h-[64px] w-[108px]',
        },
        {
          size: 'medium',
          shape: 'rectangle',
          class: 'h-[88px] w-[152px]',
        },
        {
          size: 'responsive',
          shape: 'square',
          class: 'aspect-square',
        },
        {
          size: 'responsive',
          shape: 'rectangle',
          class: 'aspect-video',
        },
        {
          size: 'responsive',
          shape: 'responsive',
          class: 'flex-1 flex-grow h-full',
        },
        {
          isUploading: false,
          dragActive: true,
          disabled: false,
          class: 'dark:bg-dark/action/neutral/active',
        },
        {
          isUploading: true,
          dragActive: false,
          disabled: false,
          class: 'dark:bg-dark/action/neutral/normal',
        },
        {
          isUploading: false,
          dragActive: false,
          disabled: false,
          class: [
            'dark:bg-dark/action/neutral/normal dark:hover:bg-dark/action/neutral/hover dark:active:bg-dark/action/neutral/active',
            'dark:hover:dark/border/default/hover dark:active:dark/border/default/active',
          ],
        },
      ],
      variants: {
        shape: {
          square: '',
          rectangle: '',
          responsive: '',
        },
        size: {
          small: '',
          medium: '',
          responsive: '',
        },
        dragActive: {
          true: ' ',
          false: '',
        },
        isUploading: {
          true: '',
          false: '',
        },
        disabled: {
          true: 'opacity-50 cursor-not-allowed',
          false: ['opacity-100 cursor-pointer'],
        },
      },
    }
  );

  const labelStyles = cva('transition-all ease-in-out duration-500', {
    variants: {
      disabled: {
        true: 'cursor-not-allowed',
      },
      isUploading: {
        true: '',
        false: '',
      },
      isSuccess: {
        true: '',
        false: '',
      },
      isError: {
        true: 'opacity-0',
        false: '',
      },
    },
    compoundVariants: [
      {
        isUploading: false,
        isSuccess: true,
        class: 'opacity-0',
      },
      {
        isUploading: true,
        isSuccess: false,
        class: 'opacity-0',
      },
      {
        isUploading: false,
        isSuccess: false,
        isError: false,
        class: 'opacity-100',
      },
    ],
  });

  const progressStyles = cva(
    ' flex justify-center items-center w-full transition-all ease-in-out duration-300 h-0 mt-0',
    {
      variants: {
        disabled: {
          true: 'cursor-not-allowed',
          false: '',
        },
        isUploading: {
          true: 'opacity-100 h-full',
          false: 'opacity-0',
        },
        shape: {
          square: '',
          rectangle: '',
          responsive: '',
        },
      },
      compoundVariants: [
        {
          isUploading: true,
          shape: 'square',
          class: 'mt-5',
        },
        {
          isUploading: false,
          shape: 'square',
          class: 'mt-0',
        },
      ],
    }
  );

  return (
    <>
      <div
        data-testid='ob-upload-button'
        className={buttonStyles({
          disabled,
          dragActive,
          isUploading,
          shape,
          size,
        })}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <button
          className='flex flex-col justify-center items-center h-full w-full disabled:cursor-not-allowed'
          disabled={disabled || isUploading}
          onClick={handleOnClick}
          aria-label={ariaLabel ?? label}
        >
          <div
            className={cx(
              labelStyles({ isUploading, isSuccess, disabled, isError })
            )}
          >
            <ObIcon
              icon='upload'
              color='secondary'
              size={'small'}
            />
          </div>
          <div className={progressStyles({ isUploading, disabled, shape })}>
            {isProgressSupported && (
              <ObProgressBar percentage={progress ?? 0} />
            )}
            {!isProgressSupported && (
              <ObLoadingSpinner
                includeBrand={false}
                size={24}
              />
            )}
          </div>
          <div
            className={labelStyles({
              isUploading,
              isSuccess,
              disabled,
              isError,
            })}
          >
            <ObTypography
              variant='body2'
              color='secondary'
            >
              {label}
            </ObTypography>
          </div>
        </button>
        <ObUploadStatus
          isSuccess={isSuccess}
          isError={isError}
          errorMessage={errorMessage}
        />
      </div>
      <input
        ref={fileUploadInputRef}
        className='hidden'
        multiple={false}
        accept={acceptedExtensionsString}
        onChange={onNativeFileInputChange}
        type='file'
      />
    </>
  );
};
