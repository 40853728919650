import {
  createTheme as createMuiTheme,
  Direction,
  responsiveFontSizes,
  Theme,
} from '@mui/material';

import { themeOptionsBase } from './theme-options-base';
import { themeOptionsDark } from './theme-options-dark';
import { themeOptionsLight } from './theme-options-light';

/**
 * Add custom palette options to MUI Theme via Module Augmentation
 */
declare module '@mui/material/styles' {
  interface TypeBackground {
    paperSubtle: string;
  }
}

interface ThemeConfig {
  direction?: Direction;
  mode?: 'light' | 'dark';
}

export const createTheme = (config: ThemeConfig): Theme => {
  return responsiveFontSizes(
    createMuiTheme(
      themeOptionsBase,
      config.mode === 'dark' ? themeOptionsDark : themeOptionsLight,
      {
        direction: config.direction,
      }
    )
  );
};
