export const CampaignChannelTypeValues = [
  'GOOGLE_DISPLAY_ADS',
  'GOOGLE_SEARCH_ADS',
  'FACEBOOK_ADS',
  'INSTAGRAM_ADS',
  'PODCAST_ADS',
  'BILLBOARD_ADS',
  'LINKEDIN_ADS',
  'STREAMING_TV_ADS',
  'MAILER_ADS',
] as const;

/**
 * Use this over the enum going forward.
 * We will eventually deprecate the enum until then we should keep the enum and this in sync.
 * We still get type safety and do not need to import the enum which leads to a faster DX when manually typing out the values.
 */
export type CampaignChannel = (typeof CampaignChannelTypeValues)[number];

//Deprecated
export enum CampaignChannelType {
  GOOGLE_DISPLAY_ADS = 'GOOGLE_DISPLAY_ADS',
  GOOGLE_SEARCH_ADS = 'GOOGLE_SEARCH_ADS',
  FACEBOOK_ADS = 'FACEBOOK_ADS', //NOT IMPLEMENTED
  INSTAGRAM_ADS = 'INSTAGRAM_ADS', //NOT IMPLEMENTED
  PODCAST_ADS = 'PODCAST_ADS', //NOT IMPLEMENTED
  BILLBOARD_ADS = 'BILLBOARD_ADS', //NOT IMPLEMENTED
  LINKEDIN_ADS = 'LINKEDIN_ADS', //NOT IMPLEMENTED
  STREAMING_TV_ADS = 'STREAMING_TV_ADS', //NOT IMPLEMENTED
  MAILER_ADS = 'MAILER_ADS', //NOT IMPLEMENTED
}

//Deprecated
export enum CampaignGoal {
  LEAD_GEN = 'LEAD_GEN',
  PURCHASE = 'PURCHASE',
  AWARENESS = 'AWARENESS',
}

export const getDisplayNameForChannel = (channel: CampaignChannelType) => {
  switch (channel) {
    case CampaignChannelType.GOOGLE_DISPLAY_ADS:
      return 'Google Display';
    case CampaignChannelType.GOOGLE_SEARCH_ADS:
      return 'Google Search';
    case CampaignChannelType.FACEBOOK_ADS:
      return 'Facebook';
    case CampaignChannelType.INSTAGRAM_ADS:
      return 'Instagram';
    case CampaignChannelType.PODCAST_ADS:
      return 'Podcast';
    case CampaignChannelType.BILLBOARD_ADS:
      return 'Billboard';
    case CampaignChannelType.LINKEDIN_ADS:
      return 'LinkedIn';
    case CampaignChannelType.STREAMING_TV_ADS:
      return 'Streaming TV';
    case CampaignChannelType.MAILER_ADS:
      return 'Mailers';
  }
};
