import { ObColorType } from '../../../tokens/colors/colors';
import { IconSystemKeys } from '../../../tokens/icons/icons';

export const ChangeStatusValues = [
  'in-review',
  'unpublished',
  'rejected',
  'unpublished-in-review',
  'ready-to-launch',
] as const;

export type ChangeStatusType = (typeof ChangeStatusValues)[number];

export interface ChangeManagementBarContent {
  title: string;
  subtitle: string;
  icon: IconSystemKeys;
  backgroundColor: string;
  iconColor: ObColorType;
  primaryButtonText: string;
  secondaryButtonText?: string;
}
export const changeManagementBarContent: Record<
  ChangeStatusType,
  ChangeManagementBarContent
> = {
  'in-review': {
    title: 'Your campaign has changes in review',
    subtitle:
      'Your changes are in the process of being published. This may take several days to complete',
    icon: 'internalActionRequired',
    backgroundColor: '#5353E4',
    iconColor: 'primaryV2',
    primaryButtonText: 'Recall Changes In Review',
    secondaryButtonText: 'View Changes In Review',
  },
  unpublished: {
    title: 'Your campaign has unpublished changes',
    subtitle:
      'Once you are happy with your changes submit them and we will get them published!',
    icon: 'suggestion',
    backgroundColor: '#054A70',
    iconColor: 'cobalt',
    primaryButtonText: 'Submit Changes',
    secondaryButtonText: 'Discard Changes',
  },
  rejected: {
    title: 'Your changes need attention',
    subtitle: 'There are issues with your campaign that must be resolved',
    icon: 'alertTriangle',
    backgroundColor: '#C46520',
    iconColor: 'warning',
    primaryButtonText: 'View Issues',
    secondaryButtonText: 'Cancel Changes',
  },
  'unpublished-in-review': {
    title:
      'Your campaign has unpublished changes with previous changes in review',
    subtitle:
      'Your previously submitted changes have not yet been published and you have additional changes that you have not submitted',
    icon: 'textNotification',
    backgroundColor: '#00535A',
    iconColor: 'teal',
    primaryButtonText: 'Submit Changes',
    secondaryButtonText: 'Discard Changes',
  },
  'ready-to-launch': {
    title: 'Your campaign is ready to be published',
    subtitle: 'Publish your campaign to Google today!',
    icon: 'nominal',
    backgroundColor: '#1BA37B',
    iconColor: 'positive',
    primaryButtonText: 'Publish',
  },
};
