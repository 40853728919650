import { parseObrn } from '@otbnd/utils';
import { CampaignHighlightResource, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import CampaignHighlight from '../campaign-highlight/campaign-highlight';

/**
 * There is a lot of heavy lifting that goes into creating the user facing concept of a campaign highlight from
 * the backend API data. We may explore migrating some of this logic to the backend in the future and exposing
 * highlights directly on the Campaign Resource vs having to transform them here.
 */
class CampaignHighlightTransformer extends BaseTransformer<
  CampaignHighlightResource,
  CampaignHighlight
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: CampaignHighlight,
    _resource: CampaignHighlightResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }

  /**
   * Because campaign highlights do not currently exist in the API as a first class object we are manually
   * creating them on the frontend. Once we are able to move the highlights to the backend we will be able to
   * simplify this method. Ideally this should only return a single highlight object vs the array to keep in line with
   * the expected behavior of the state layer.
   * @param campaignResource
   * @returns
   */
  public fromApiResource(
    highlightResource: CampaignHighlightResource
  ): CampaignHighlight {
    const { localPathId: localId } = parseObrn(
      highlightResource.highlightObjectObrn
    );

    if (highlightResource.highlightType === 'service') {
      const serviceId = localId;

      return new CampaignHighlight(
        this._rootStore,
        highlightResource.id,
        highlightResource.obrn,
        {
          highlightedObjectContext: { type: 'service', serviceId },
          associatedCreativeIds: [highlightResource.creativeId],
          isEnabled: highlightResource.isEnabled,
          highlightedObjectObrn: highlightResource.highlightObjectObrn,
          highlightType: highlightResource.highlightType,
          associatedLandingPageObrn: highlightResource.landingPageId,
        }
      );
    }

    if (highlightResource.highlightType === 'service-offering') {
      const [serviceId, serviceOfferingId] = localId.split('/');

      return new CampaignHighlight(
        this._rootStore,
        highlightResource.id,
        highlightResource.obrn,
        {
          highlightedObjectContext: {
            type: 'service-offering',
            serviceId,
            serviceOfferingId,
          },
          associatedCreativeIds: [highlightResource.creativeId],
          isEnabled: highlightResource.isEnabled,
          highlightedObjectObrn: highlightResource.highlightObjectObrn,
          highlightType: highlightResource.highlightType,
          associatedLandingPageObrn: highlightResource.landingPageId,
        }
      );
    }

    throw new Error('Unsupported highlight type');
  }
}

export default CampaignHighlightTransformer;
