import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { cx } from 'class-variance-authority';
import { FC, Fragment, useRef, useState } from 'react';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObColorType } from '../../tokens/colors/colors';
import { IconSystemKeys } from '../../tokens/icons/icons';
import {
  ObIndicatorDot,
  ObIndicatorDotProps,
} from '../ob-indicator-dot/ob-indicator-dot';

export interface ObNeedsAttentionBadgeProps
  extends Pick<ObIndicatorDotProps, 'priority'> {
  /**
   * Provides the ability to disable the popover from appearing.
   * This should be used carefully and should act consistently
   * between all instances of the parent component that implements this
   * component so users don't get frustrated when there is a popover
   */
  disablePopover?: boolean;
  /**
   * Required if enablePopover is true.
   * This component is what will be used to populate the popover.
   */
  popoverContent?: React.ReactNode;

  label: string;

  /**
   * If enabled will add an Indicator dot This seems redundant with variant.
   */
  showIndicatorDot?: boolean;

  /**
   * The color of the indicator dot
   */
  indicatorDotColor?: ObColorType;

  icon?: IconSystemKeys;

  variant: 'indicator' | 'indicator-with-label';
}

/**
 * Intent is to delay opening a small amount to prevent
 * mouse fly-overs from triggering the popover
 */
const DEFAULT_OPEN_DELAY_MS = 300;

export const ObNeedsAttentionBadge: FC<ObNeedsAttentionBadgeProps> = ({
  label,
  disablePopover = false,
  popoverContent,
  showIndicatorDot,
  indicatorDotColor,
  priority,
  variant,
}) => {
  const hoverTimeoutRef = useRef<number | null>(null);

  const closeTimeoutRef = useRef<number | null>(null);

  /**
   * Tracks if the popover is open or not
   */
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    //If we are about to close the popover, cancel that
    if (closeTimeoutRef.current) {
      window.clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
    /**
     * Ignore the mouse movement if the popover is disabled
     * of if there is no popover content provided
     */
    if (disablePopover || popoverContent == null) {
      return;
    }
    hoverTimeoutRef.current = window.setTimeout(() => {
      setIsOpen(true);
    }, DEFAULT_OPEN_DELAY_MS);
  };

  const handleMouseLeave = () => {
    //If we are about to open the popover, cancel that
    if (hoverTimeoutRef.current) {
      window.clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }

    closeTimeoutRef.current = window.setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  return (
    <Popover>
      <PopoverButton as={Fragment}>
        <button
          className={cx(
            ' inline-flex items-center  py-1 px-2 rounded  transition ease-out duration-200 cursor-pointer',
            variant === 'indicator'
              ? ' hover:bg-dark/action/ghost/hover'
              : 'bg-dark/action/neutral/normal hover:bg-dark/action/neutral/hover'
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className='flex items-center gap-1'>
            {(variant === 'indicator' || showIndicatorDot) && (
              <ObIndicatorDot
                priority={priority}
                color={indicatorDotColor ?? 'primary'}
              />
            )}
            <ObTypography
              variant='body3'
              className={variant === 'indicator' ? 'sr-only' : ''}
              color='secondary'
            >
              {label}
            </ObTypography>
          </div>
        </button>
      </PopoverButton>

      <Transition
        show={isOpen}
        enter='transition-opacity duration-200'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <PopoverPanel
          anchor={{ to: 'bottom end', gap: '4px', padding: '8px' }}
          portal={true}
          static={true}
          className={'[--anchor-gap:4px]  shadow-lg'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {popoverContent}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};
