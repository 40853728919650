import { ObLink } from '../../elements/ob-link/ob-link';

export interface ObDrawerTopBarProps {
  /**
   * Primary Heading for the Drawer
   */
  backLabel?: string;
  /**
   * Optional Secondary Heading for the Drawer
   */
  actionsLabel?: string;

  /**
   * Function to be called when the back button is clicked
   * @returns
   */
  onBackCallback?: () => Promise<void>;

  onActionMenuOpenCallback?: () => Promise<void>;

  actions?: Array<any>;
}

export const ObDrawerTopBar: React.FC<ObDrawerTopBarProps> = ({
  backLabel = 'Back',
  actionsLabel = 'Actions',
  onBackCallback,
  onActionMenuOpenCallback,
  actions,
}: ObDrawerTopBarProps) => {
  return (
    <header className='flex dark:bg-bgSurfaceDark justify-between border-b border-b-borderDefaultNormalDark py-3 px-4'>
      <div className='flex flex-grow justify-between items-center '>
        <ObLink
          onClick={onBackCallback}
          iconLeft='left'
          label={backLabel}
          variant={'secondary'}
        />
        {actions && (
          <ObLink
            onClick={onActionMenuOpenCallback}
            iconRight='down'
            label={actionsLabel}
            variant={'secondary'}
          />
        )}
      </div>
    </header>
  );
};
