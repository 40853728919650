import { Deferred } from '@otbnd/utils';
import { useState } from 'react';
import { useDocumentManagerPollAndDownloadHandler } from '../../../../hooks/use-document-manager-poll-and-download-handler';
import { useExportPlaybookHandout } from '../../../../query/playbook/use-playbook-endpoints';

/**
 * Thin wrapper around the document manager and export playbook handout hooks.
 * Intent is to continue to add any more downloads functionality needed by the playbook service here
 * such as the presentation format download etc.
 */
export const usePlaybookDownloads = () => {
  /**
   * It may be helpful in the future to expose additional state transitions
   * from this hook like:
   * 'exporting-data', 'generating-document', 'downloading-document', 'document-ready'
   * so that the consuming component can display more granular loading states to keep the user informed / aware that something is happening.
   * We should only do this if the UI needs it due to some user feedback or usability testing.
   */
  const { exportAndDownloadDocument } =
    useDocumentManagerPollAndDownloadHandler();

  const { mutateAsync: generateExport } = useExportPlaybookHandout();
  const [isHandoutGenerating, setIsHandoutGenerating] = useState(false);
  /**
   * Indicates that the handout is has been downloaded and is ready to be opened
   */
  const [isHandoutReady, setIsHandoutReady] = useState(false);

  const [handoutLabel, setHandoutLabel] = useState('Export Handout');

  /**
   * Returns a promise that resolves when the handout is generated and downloaded
   * Use isHandoutGenerating state for a more react-y way to show loading state
   * @returns
   */
  const generateAndDownloadHandout = async (): Promise<void> => {
    const deferred = new Deferred<void>();
    try {
      setIsHandoutGenerating(true);
      setHandoutLabel('Generating Handout...');
      await exportAndDownloadDocument(() => generateExport());
      setIsHandoutGenerating(false);
      setIsHandoutReady(true);
      setHandoutLabel('Handout Downloaded');
      setTimeout(() => {
        setHandoutLabel('Export Handout');
        setIsHandoutReady(false); // Reset the state after 5 seconds
      }, 5000);
      deferred.resolve();
    } catch (error) {
      setIsHandoutGenerating(false);
      deferred.reject(error);
    }
    return deferred.promise;
  };

  return {
    generateAndDownloadHandout,
    isHandoutGenerating,
    isHandoutReady,
    handoutLabel,
  };
};
