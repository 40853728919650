import {
  ObDivider,
  ObSubItemList,
  ObSubItemProps,
  ObTypography,
} from '@outbound/design-system';
import { useEffect } from 'react';
import { useSubServiceCreateDrawer } from '../sub-service-drawer/sub-service-create-drawer-hook';
import { useSubServiceEditDrawer } from '../sub-service-drawer/sub-service-edit-drawer-hook';

interface SubServiceListProps {
  serviceId: string;
  subItems: Array<ObSubItemProps>;
  openToServiceOfferingId?: string;
}

export const SubServiceList: React.FC<SubServiceListProps> = ({
  serviceId,
  subItems,
  openToServiceOfferingId,
}: SubServiceListProps) => {
  const { openSubServiceEditDrawer } = useSubServiceEditDrawer();

  const { openSubServiceCreateDrawer } = useSubServiceCreateDrawer();

  useEffect(() => {
    if (openToServiceOfferingId) {
      openSubServiceEditDrawer({
        serviceId,
        subServiceId: openToServiceOfferingId,
      }).catch(() => {
        //No-op
      });
    }
  }, [openToServiceOfferingId, openSubServiceEditDrawer, serviceId]);

  return (
    <div className='pb-4'>
      <ObTypography variant='h3'>Subservices</ObTypography>
      <ObDivider className='my-3' />
      <ObSubItemList
        subItems={subItems ?? []}
        noSubItemsCallToActionHeader={'You Have Not Added Any Subservices Yet'}
        noSubItemsCallToActionDescription={
          'Subservices are the various parts of a service that you offer. For example, if you offer "Lawn Care" as a service, you might have subservices like "Mowing", "Edging", and "Weed Eating".'
        }
        addSubItemButtonLabel={'Add Subservice'}
        onClickAddSubItemCallback={async () => {
          openSubServiceCreateDrawer({
            serviceId,
          }).catch(() => {
            //No-op
          });
        }}
        onClickViewSubItemDetailsCallback={(subItem) => {
          /**
           * If serviceId is present, we are editing a sub-service of an existing service
           */
          openSubServiceEditDrawer({
            serviceId,
            subServiceId: subItem.id,
          }).catch(() => {
            //No-op
          });
        }}
      />
    </div>
  );
};
