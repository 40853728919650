import { CreativeResource } from '@outbound/types';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Creative from './creative';
import CreativeTransformer from './creative-transformer';

class CreativeStore extends BaseStore<Creative> {
  readonly creativeTransformer: CreativeTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'creative');
    this.creativeTransformer = new CreativeTransformer(rootStore);
    this.rootStore.transport.creativeTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
  }

  /**
   * Get a Creative By Its Id.
   * @param id The ID of the Creative to Fetch
   * @returns The Requested Creative or Null if it does not exist.
   * @throws Promise for Suspense if the creative needs to be lazy loaded
   */
  public getById(id: string): Creative | null {
    const model = this.getModelFromStoreOrLazyLoadFromServerById(id);
    model?.makeObservable();
    return model;
  }

  protected async requestLoadModelFromServer(id: string) {
    this.rootStore.transport.creativeTransport.requestResourceById(id);
  }

  /**
   * Internal method that fetches a model from the observable map.
   * This cannot be accessed directly from the UI as it bypasses lazy loading.
   * @param id
   * @returns
   */
  protected getModelFromStore(id: string): Creative | null {
    return this.modelMap.get(id) ?? null;
  }

  /**
   * Updates the store with the latest data from the server.
   * This looks like it can be made generic and moved to the base store.
   * @param id
   * @param resource
   */
  private handleServerUpdate(
    id: string,
    resource: CreativeResource | null
  ): void {
    console.log('Handling Server Update For Creative', id, resource);
    const clientCachedResource = this.modelMap.get(id);
    //We only expect a null resource if the model is not found on the server
    if (resource == null) {
      this.updateSyncMetaForNotFoundModel(id);
      this.modelMap.delete(id); //Remove the model from the store
    } else if (clientCachedResource) {
      const serverDelta =
        this.creativeTransformer.createPatchForCurrentModelAndIncomingResource(
          clientCachedResource,
          resource
        );
      if (serverDelta.length > 0) {
        console.log('Applying Patch', serverDelta);
        clientCachedResource.applyPatch(serverDelta);
      }
      this.updateSyncMetaForUpdatedModel(id, clientCachedResource);
    } else {
      console.log('Added New Creative From Server', resource);
      const creative = this.creativeTransformer.fromApiResource(resource);
      creative.makeObservable();
      this.modelMap.set(id, creative);
      this.updateSyncMetaForLoadedModel(id, creative);
    }
  }
}

export default CreativeStore;
