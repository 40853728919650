import { ReactNode } from 'react';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';

export const OverLineField = ({
  label,
  content,
}: {
  label: string;
  content?: ReactNode;
}) => {
  return (
    <div className='flex flex-col'>
      <ObTypography
        variant='body3'
        color='secondary'
        className='uppercase mb-[2px]'
      >
        {label}
      </ObTypography>
      <div className='pt-1/2'>{content}</div>
    </div>
  );
};
