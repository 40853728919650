import {
  useDrawerService,
  useObFormRendererContext,
} from '@outbound/design-system';
import { useNotificationService } from '@outbound/design-system/src/components/services/notification-service-provider/notification-service-provider';

import { useCallback, useState } from 'react';

/**
 * Common hook for all drawer edit forms in the playbook
 */
export const useDrawerEditForm = () => {
  const drawerService = useDrawerService();

  const notificationService = useNotificationService();

  const [isInitialValuesFetched, setIsInitialValuesFetched] =
    useState<boolean>(false);

  const [areChangesUnsaved, setAreChangesUnsaved] = useState<boolean>(false);

  const onFormDirtyChange = useCallback((isDirty: boolean) => {
    setAreChangesUnsaved(isDirty);
  }, []);

  const [initialValues, setInitialValues] = useState<any>({});

  const { getRegisteredFormById } = useObFormRendererContext();

  return {
    drawerService,
    notificationService,
    isInitialValuesFetched,
    setIsInitialValuesFetched,
    areChangesUnsaved,
    onFormDirtyChange,
    initialValues,
    setInitialValues,
    getRegisteredFormById,
  };
};
