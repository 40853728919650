import { FormDefinition, FormFieldType } from '@outbound/design-system';
import { PERSONA_TARGETING_SCHEMA, referenceInterests } from '@outbound/types';

export const CUSTOMER_PERSONA_FORM_DEF: FormDefinition = {
  id: 'customer-persona-form',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'role',
          label: 'Customer Profile Name',
          helperText: 'A short 1-3 word description of the customer profile',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'description',
          label: 'Customer Description',
          helperText: '',
          type: FormFieldType.TEXT_AREA,
          fieldTypeSettings: {},
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'demographics',
      heading: 'Customer Profile',
      subHeading: '',
      fields: [
        {
          id: 'demographics',
          label: 'Demographics',
          helperText:
            'What attributes uniquely identify this customer profile?',
          fieldTypeSettings: {
            ruleBuilderSchema: PERSONA_TARGETING_SCHEMA,
            allowAdvancedMode: false,
          },
          type: FormFieldType.RULE_BUILDER,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'interests',
          label: 'Interests',
          helperText:
            'What are the interests of this customer profile? What do they like to do in their free time? What are they reading, watching, or listening to? What are they passionate about?',
          type: FormFieldType.COMBO_BOX,
          fieldTypeSettings: {
            options: referenceInterests.map((refInterest) => {
              return {
                key: refInterest.id,
                value: refInterest.id,
                displayValue: refInterest.id
                  .replaceAll('#', ' / ')
                  .replaceAll('_', ' '),
              };
            }),
          },

          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
  ],
  heading: '',
  subHeading: '',
};
