import { isValid, parse } from 'date-fns';

const TIME_PATTERNS = [
  'HH:mm',
  'HH:mm:ss',
  'hh:mm',
  'hh:mm aa',
  'hh:mmaa',
  'HH',
];

export const validateTimeInput = (inputDate: string): Date | undefined => {
  let parsedDates: any = [];

  TIME_PATTERNS.forEach((formatString) => {
    const parsedDate: any = parse(inputDate, formatString, new Date());

    if (isValid(parsedDate)) {
      parsedDates.push(parsedDate);
    }
  });

  if (parsedDates.length) {
    return parsedDates[0] as Date;
  }
};
