import {
  buildYupValidationForTextField,
  validateTextSetting,
} from '@otbnd/utils';
import { ServerDelta, TextSettingValidation } from '@outbound/types';
import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import * as Yup from 'yup';
import { RootStore } from '../../root-store';
import BaseSetting from './base-setting';

class TextSetting extends BaseSetting {
  static readonly paths = {
    ...BaseSetting.paths,
    value: '/value',
  };

  private _value: { text: string };
  private _validationRules: TextSettingValidation;
  protected _yupValidationSchema: Yup.StringSchema; //Not sold on this being in here

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    obrn: string,
    name: string,
    isRemixLocked: boolean,
    value: { text: string },
    validationRules: TextSettingValidation
  ) {
    super(rootStore, id, workspaceId, obrn, name, 'text', isRemixLocked);
    this._value = value;
    this._validationRules = validationRules;
    this._yupValidationSchema = buildYupValidationForTextField(
      this._name,
      this._validationRules.isRequired,
      this._validationRules.minLength,
      this._validationRules.maxLength
    );
    this.runValidation();
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      patch.forEach((delta) => {
        if (delta.path === TextSetting.paths.value) {
          this._value = delta.value;
          this.runValidation();
        }
      });
    });
  }

  private runValidation(): void {
    this._fieldValidationResult = validateTextSetting(
      this.id,
      this._value.text,
      this._yupValidationSchema
    );
  }

  private setupReactions() {
    /**
     * Anytime the user changes the value, we need to update the server
     */
    reaction(
      () => this._value,
      () => {
        const clientDelta = this.createDelta(
          this._value,
          TextSetting.paths.value,
          'replace'
        );
        this._clientDeltas.set(clientDelta.path, clientDelta); //Keep Reference to the last change sent to the server
        console.log('Updated value', clientDelta);
        this._rootStore.transport.creativeTransport.enqueue(clientDelta);
      }
    );
  }

  makeObservableInternal() {
    super.makeObservableInternal();
    makeObservable(this, {
      _value: observable,
      applyPatch: action,
      toJson: action,
    } as any);
    this.setupReactions();
  }

  get value(): { text: string } {
    return this._value;
  }

  set value(value: { text: string }) {
    runInAction(() => {
      this._value = value;
      this.runValidation(); //Must be run immediately to update validation errors
    });
  }

  get validationRules(): TextSettingValidation {
    return this._validationRules;
  }
}

export default TextSetting;
