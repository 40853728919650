import { Skeleton } from '@mui/material';
import { FC, ReactNode } from 'react';

import { cva, cx } from 'class-variance-authority';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

interface SettingCardRowProps {
  /**
   * The name of the setting
   */
  title: string;
  /**
   * A text description of the value of the setting or the action of the action button
   */
  body: string;
  /**
   * Sets the component to a loading State.
   * Useful for when the data needed to populate the component is fetched asynchronously
   */
  loading: boolean;
  /**
   * Sets the color of the card to a supported theme color.
   */
  color?: 'error' | 'info';

  stackOnMobile?: boolean;
  children?: ReactNode;
}

/**
 * Useful as a base component for settings with a control such as a button or switch or as a way to display a read only
 * information in a setting card such as an objects ID
 * @param title
 * @param body
 * @param children
 * @param loading
 * @param color
 * @constructor
 */
export const SettingCardRow: FC<SettingCardRowProps> = ({
  title,
  body,
  children,
  loading,
  color,
  stackOnMobile = true,
}) => {
  const containerStyles = cva('', {
    variants: {
      color: {
        undefined: '',
        info: 'border rounded-lg border-dark/border/default/normal bg-bgPositiveSubtleDark p-4',
        error:
          'border rounded-lg border-borderNegativeSubtleDark bg-bgNegativeSubtleDark p-4',
      },
    },
  });

  return (
    <div
      data-testid='setting-card-row'
      className={cx(
        containerStyles({ color }),
        `flex sm:flex-row  gap-2 w-full items-center ${
          stackOnMobile ? 'flex-col' : 'flex-row'
        }`
      )}
    >
      <div className='sm:w-full flex flex-col flex-grow gap-0.5 '>
        <ObTypography variant='subtitle1'>
          {loading ? (
            <Skeleton
              className='w-1/3'
              data-testid='loading-title'
              animation='wave'
            />
          ) : (
            title
          )}
        </ObTypography>
        {(loading || body) && (
          <ObTypography
            variant='body2'
            color='secondary'
            data-testid={`setting-card-body-value__${title}`}
          >
            {loading ? (
              <Skeleton
                className='w-3/4'
                data-testid='loading-body'
                animation='wave'
              />
            ) : (
              body
            )}
          </ObTypography>
        )}
      </div>
      {children && <div className='w-full sm:flex-1'>{children}</div>}
    </div>
  );
};
