import {
  CampaignChannelType,
  IntegrationCategory,
  IntegrationConfigurationLifecycleStatus,
  IntegrationConfigurationOperationalStatus,
} from '@outbound/types';

import { ObAvatar, ObIndicatorDot } from '../..';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantProps } from '../card.type';
import { lookupStatusDetailsFromOperationalAndLifecycleStatus } from './utils';

export interface IntegrationXConfigurationCardProps extends CardVariantProps {
  releaseStatus?: 'BETA' | 'FUTURE';
  integrationIconUrl: string;
  name: string;
  shortDescription: string;
  operationalStatus: IntegrationConfigurationOperationalStatus;
  lifecycleStatus?: IntegrationConfigurationLifecycleStatus;
  category: IntegrationCategory;
  onNavigateCallback?: (
    event?:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

export interface AdChannelIntegrationCardProps
  extends IntegrationXConfigurationCardProps {
  channel: CampaignChannelType;
}

type CampaignCardImplementationProps = Omit<
  IntegrationXConfigurationCardProps,
  'variant'
>;

export const IntegrationXConfigurationCard = ({
  variant,
  ...props
}: IntegrationXConfigurationCardProps) => {
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'micro':
      return <MicroCard {...props} />;
    case 'mini':
      return <MiniCard {...props} />;
    case 'table':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};

const GridCard = ({
  integrationIconUrl,
  name,
  shortDescription,
  onNavigateCallback,
  operationalStatus,
  lifecycleStatus,
}: CampaignCardImplementationProps) => {
  return (
    <div
      tabIndex={0}
      role='button'
      onClick={(e) => {
        e.stopPropagation();
        onNavigateCallback?.(e);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          onNavigateCallback?.(event);
        }
      }}
      className=' focus-visible:shadow-interactive focus:outline-none'
    >
      <ObCardSurface isClickable={true}>
        <div
          className='flex flex-col gap-2 p-3 justify-start items-start self-stretch flex-1 h-[120px]
        hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active rounded-lg'
        >
          <div className='flex flex-row gap-2 justify-between items-center self-stretch'>
            <div className='flex flex-row gap-2 justify-start items-center self-stretch'>
              <ObAvatar
                shape='rounded'
                size={'medium'}
                profileImageUrl={integrationIconUrl}
              />
              <ObTypography
                variant='h3'
                className='line-clamp-2'
              >
                {name}
              </ObTypography>
            </div>
            <div className='flex items-start'>
              <ObIndicatorDot
                color={
                  lookupStatusDetailsFromOperationalAndLifecycleStatus(
                    operationalStatus,
                    lifecycleStatus
                  ).indicatorColor
                }
                priority={'low'}
              />
            </div>
          </div>
          <ObTypography
            variant='body3'
            color='secondary'
            className='line-clamp-3'
          >
            {shortDescription}
          </ObTypography>
        </div>
      </ObCardSurface>
    </div>
  );
};

const MiniCard = ({
  integrationIconUrl,
  name,
  onNavigateCallback,
  operationalStatus,
  lifecycleStatus,
}: CampaignCardImplementationProps) => {
  return (
    <div
      tabIndex={0}
      role='button'
      onClick={(e) => {
        e.stopPropagation();
        onNavigateCallback?.(e);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          onNavigateCallback?.(event);
        }
      }}
      className=' focus-visible:shadow-interactive focus:outline-none'
    >
      <ObCardSurface
        isClickable={true}
        showBorder={false}
      >
        <div
          className='flex flex-col gap-2 p-2 justify-start items-start self-stretch flex-1 
        hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active rounded-lg'
        >
          <div className='flex flex-row gap-2 justify-between items-center self-stretch'>
            <div className='flex flex-row gap-2 justify-start items-center self-stretch'>
              <ObAvatar
                shape='rounded'
                size={'small'}
                profileImageUrl={integrationIconUrl}
              />
              <ObTypography
                variant='subtitle1'
                className='line-clamp-2'
              >
                {name}
              </ObTypography>
            </div>
            <div className='flex items-start'>
              <ObIndicatorDot
                color={
                  lookupStatusDetailsFromOperationalAndLifecycleStatus(
                    operationalStatus,
                    lifecycleStatus
                  ).indicatorColor
                }
                priority={'low'}
              />
            </div>
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};

const MicroCard = ({
  integrationIconUrl,
  name,
  onNavigateCallback,
  operationalStatus,
  lifecycleStatus,
}: CampaignCardImplementationProps) => {
  return (
    <div
      tabIndex={0}
      role='button'
      onClick={(e) => onNavigateCallback?.(e)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNavigateCallback?.(event);
        }
      }}
      className=' focus-visible:shadow-interactive focus:outline-none'
    >
      <ObCardSurface
        isClickable={true}
        showBorder={false}
      >
        <div
          className='flex flex-col gap-2 p-1 justify-start items-start self-stretch flex-1 
        hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active rounded-lg'
        >
          <div className='flex flex-row gap-2 justify-between items-center self-stretch'>
            <div className='flex flex-row gap-2 justify-start items-center self-stretch'>
              <ObAvatar
                shape='rounded'
                size={'xx-small'}
                profileImageUrl={integrationIconUrl}
              />
              <ObTypography
                variant='subtitle2'
                className='line-clamp-2'
              >
                {name}
              </ObTypography>
            </div>
            <div className='flex items-start'>
              <ObIndicatorDot
                color={
                  lookupStatusDetailsFromOperationalAndLifecycleStatus(
                    operationalStatus,
                    lifecycleStatus
                  ).indicatorColor
                }
                priority={'low'}
              />
            </div>
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};
