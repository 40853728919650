'use client';
import React, { ReactNode, useMemo } from 'react';
import { checkeredBgDk, checkeredBgLt } from '../../../assets';

export interface ObCheckerboardCanvasProps {
  mode: 'light' | 'dark';
  className?: string;
  children?: ReactNode;
}

export const ObCheckerboardCanvas: React.FC<ObCheckerboardCanvasProps> = ({
  mode,
  className,
  children,
}) => {
  const backgroundCheck = useMemo(
    () => (mode === 'light' ? checkeredBgLt : checkeredBgDk),
    [mode]
  );

  return (
    <div
      className={className}
      style={{
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        backgroundImage: `url(${backgroundCheck})`,
        backgroundSize: '24px',
      }}
    >
      {children}
    </div>
  );
};
