import { animated } from 'react-spring';

export interface ObPinProps {
  lineHeight: any;
}
export const ObPin = ({ lineHeight }: ObPinProps) => {
  return (
    <animated.svg
      viewBox={lineHeight.value.to(
        (value: number) => `0 0 7 ${value.toString()}`
      )}
    >
      <g>
        <circle
          fill={'#FFFFFF'}
          cx='3.5'
          cy='3.5'
          r='3.5'
        />
        <circle
          fill={'#1B1D2C'}
          cx='3.5'
          cy='3.5'
          r='1.5'
        />

        <animated.linearGradient
          id='SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1='3.501'
          y1={lineHeight.value.to((value: number) =>
            (-1.002 * value + 26.056).toString()
          )}
          x2='3.502'
          y2='18.6343'
          gradientTransform='matrix(1 0 0 -1 0 26.0078)'
        >
          <stop
            offset='0'
            style={{ stopColor: '#2E3249' }}
          />
          <stop
            offset='1'
            style={{ stopColor: '#FFFFFF' }}
          />
        </animated.linearGradient>
        <animated.line
          fill='none'
          stroke='url(#SVGID_1_)'
          x1='3.5'
          y1='6'
          x2='3.5'
          y2={lineHeight.value}
        />
      </g>
    </animated.svg>
  );
};
