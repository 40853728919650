import { FormFieldType } from '@outbound/design-system';
import { CheckboxGroupCardOption } from '@outbound/design-system/src/components/molecules/ob-card-option-checkbox-group/ob-card-option-checkbox-group.types';
import { referenceIndustries } from '@outbound/types';
import { OnboardingQuestion } from './questionnaire.type';

export const PRODUCT_OR_SERVICE_QUESTION = 'product-or-service';
export const SERVICE_ACTIVITIES_QUESTION = 'service-location';

export const SERVICE_ONLINE = 'SERVICE_ONLINE';
export const SERVICE_IN_CUSTOMER_LOCATION = 'SERVICE_IN_CUSTOMER_LOCATION';
export const SERVICE_IN_BUSINESS_LOCATION = 'SERVICE_IN_BUSINESS_LOCATION';

/**
 * Questions for the Onboarding Process
 */
export const questions: Array<OnboardingQuestion> = [
  {
    id: 'industry',
    label: 'We’ve Identified Your Industry!',
    helperText: 'Feel free to change if if we got it wrong',
    noValueLabel: 'Select Your Industry',
    noValueHelperText: 'This will help us build your marketing plan',
    useFormRenderer: true,
    playBookPageId: 'system:company_business_details',
    formDefinition: {
      id: 'industry-form',
      heading: '',
      subHeading: '',
      sections: [
        {
          id: 'section-1',
          heading: '',
          subHeading: '',
          fields: [
            {
              id: 'industry',
              label: 'Industry',
              helperText: '',
              hideLabel: true,
              hideHelperText: true,
              type: FormFieldType.COMBO_BOX,
              validationSchema: {
                isRequired: true,
              },
              fieldTypeSettings: {
                allowMultiple: false,
                options: referenceIndustries.map((industry) => {
                  return {
                    key: industry.id,
                    value: industry.id,
                    displayValue: industry.name,
                  };
                }),
              },
            },
          ],
        },
      ],
    },
  },
  {
    id: 'business-name',
    label: 'What is the Customer facing name of your Business?',
    helperText: 'How you would want it to appear in an Ad',
    useFormRenderer: true,
    formDefinition: {
      id: 'business-name-form',
      heading: '',
      subHeading: '',
      sections: [
        {
          id: 'section-1',
          heading: '',
          subHeading: '',
          fields: [
            {
              id: 'business-name',
              label: 'Business Name',
              helperText: '',
              hideLabel: true,
              hideHelperText: true,
              type: FormFieldType.TEXT,
              validationSchema: {
                isRequired: true,
              },
              fieldTypeSettings: {},
            },
          ],
        },
      ],
    },
  },
  {
    id: PRODUCT_OR_SERVICE_QUESTION,
    label: 'Does your business sell products, provide services, or both?',
    helperText: 'Check all that apply!',
    useFormRenderer: true,
    formDefinition: {
      id: PRODUCT_OR_SERVICE_QUESTION,
      heading: '',
      subHeading: '',
      sections: [
        {
          id: 'section-1',
          heading: '',
          subHeading: '',
          fields: [
            {
              id: PRODUCT_OR_SERVICE_QUESTION,
              label: 'Products or Services',
              helperText: '',
              hideLabel: true,
              hideHelperText: true,
              type: FormFieldType.CHECKBOX_GROUP_CARDS,
              validationSchema: {
                isRequired: true,
              },
              fieldTypeSettings: {
                options: [
                  {
                    key: 'PRODUCTS',
                    displayValue: 'We Sell Products',
                    title: 'We Sell Products',
                    description: 'These can be physical or digital products',
                    value: 'PRODUCTS',
                  },
                  {
                    key: 'SERVICES',
                    displayValue: 'We Provide Services',
                    title: 'We Provide Services',
                    description:
                      'These can be any type of service including in-person or virtual services.',
                    value: 'SERVICES',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    subQuestions: [],
  },
  {
    id: 'product-location',
    label: 'Where do you sell your products?',
    helperText: 'Check all that apply!',
    useFormRenderer: true,
    formDefinition: {
      id: 'product-location',
      heading: '',
      subHeading: '',
      sections: [
        {
          id: 'section-1',
          heading: '',
          subHeading: '',
          fields: [
            {
              id: `product-location`,
              label: 'Products or Services',
              helperText: '',
              hideLabel: true,
              hideHelperText: true,
              type: FormFieldType.CHECKBOX_GROUP_CARDS,
              validationSchema: {
                isRequired: true,
              },
              fieldTypeSettings: {
                options: [
                  {
                    key: 'ONLINE_RETAIL',
                    displayValue: 'Online',
                    title: 'Online',
                    description:
                      'Sales via company websites, e-commerce platforms, marketplaces (like Amazon, eBay), social media, and mobile apps.',
                    value: 'ONLINE_RETAIL',
                  },
                  {
                    key: 'IN_STORE_RETAIL',
                    displayValue: 'Retail Stores',
                    title: 'Retail Stores',
                    description:
                      'Permanent physical locations where products are displayed and sold directly to consumers.',
                    value: 'IN_STORE_RETAIL',
                  },
                  {
                    key: 'ON_LOCATION_RETAIL',
                    displayValue: 'Pop-Up & Markets',
                    title: 'Pop-Up & Markets',
                    description:
                      'Temporary selling spaces including pop-up shops, markets, and trade shows, offering limited-time direct sales.',
                    value: 'ON_LOCATION_RETAIL',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    isQuestionInScope: (answers: { [key: string]: any }) => {
      /**
       * Question is only in scope if the user selected PRODUCTS
       * during the product or service question
       */
      const productAndServiceAnswers: Array<CheckboxGroupCardOption> =
        answers[PRODUCT_OR_SERVICE_QUESTION];
      return (
        productAndServiceAnswers != null &&
        productAndServiceAnswers.some((i) => i.value === 'PRODUCTS')
      );
    },
  },
  {
    id: SERVICE_ACTIVITIES_QUESTION,
    label: 'Where do you provide your services?',
    helperText: 'Check all that apply!',
    useFormRenderer: true,
    formDefinition: {
      id: SERVICE_ACTIVITIES_QUESTION,
      heading: '',
      subHeading: '',
      sections: [
        {
          id: 'section-1',
          heading: '',
          subHeading: '',
          fields: [
            {
              id: SERVICE_ACTIVITIES_QUESTION,
              label: 'Where do you provide your services?',
              helperText: '',
              hideLabel: true,
              hideHelperText: true,
              type: FormFieldType.CHECKBOX_GROUP_CARDS,
              validationSchema: {
                isRequired: true,
              },
              fieldTypeSettings: {
                options: [
                  {
                    key: SERVICE_ONLINE,
                    value: SERVICE_ONLINE,
                    displayValue: 'Online',
                    title: 'Online',
                    description:
                      'You provide services to your customers completely remote',
                  },
                  {
                    key: SERVICE_IN_CUSTOMER_LOCATION,
                    value: SERVICE_IN_CUSTOMER_LOCATION,
                    displayValue: "In our Customer's Homes or Business",
                    title: "In our Customer's Homes or Business",
                    description:
                      'You travel to your customer locations to provide services.',
                  },
                  {
                    key: SERVICE_IN_BUSINESS_LOCATION,
                    value: SERVICE_IN_BUSINESS_LOCATION,
                    displayValue: 'From My Locations',
                    title: 'From My Locations',
                    description:
                      'Your customers travel to your business for services.',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    isQuestionInScope: (answers: { [key: string]: any }) => {
      /**
       * Question is only in scope if the user selected SERVICES
       * during the product or service question
       */
      const productAndServiceAnswers: Array<CheckboxGroupCardOption> =
        answers[PRODUCT_OR_SERVICE_QUESTION];
      return (
        productAndServiceAnswers != null &&
        productAndServiceAnswers.some((i) => i.value === 'SERVICES')
      );
    },
  },

  {
    id: 'confirm-personas',
    label: 'Confirm Your Customer Profiles',
    helperText:
      'We have created some customer profiles based on your website. Select the ones you want to keep. Feel free to update them or add more as needed.',
    noValueLabel: 'Tell Us About Your Customers',
    noValueHelperText:
      'Describe your ideal customer. We will use this information to maximize your marketing efforts',
    useFormRenderer: false,
  },
  {
    id: 'confirm-services',
    label: 'Confirm Your Services',
    helperText:
      'We have created services you may want to advertise based on your website. Select the ones you want to keep. Feel free to update them or add more as needed.',
    noValueLabel: 'Describe Your Services',
    noValueHelperText:
      'Describe the services you offer. We will use this information to maximize your marketing efforts',
    useFormRenderer: false,
  },
  {
    id: 'confirm-service-areas',
    label: 'Confirm Your Service Areas',
    helperText:
      'We have created service areas based on your website. Select the ones you want to keep. Feel free to update them or add more as needed.',
    noValueLabel: 'Describe Your Service Areas',
    noValueHelperText:
      'Describe your ideal customer. We will use this information to maximize your marketing efforts',
    useFormRenderer: false,
  },
  {
    id: 'confirm-my-brick-and-mortar-locations',
    label: 'Confirm Your Locations',
    helperText:
      'We have setup your brick and mortar locations based on your website. Select the ones you want to keep. Feel free to update them or add more as needed.',
    noValueLabel: 'Describe Your Locations',
    noValueHelperText:
      'Tell us about the locations you want to advertise for. We will use this information to maximize your marketing efforts',
    useFormRenderer: false,
    isQuestionInScope: (answers: { [key: string]: any }) => {
      /**
       * Question is only in scope if the user selected SERVICES
       * during the product or service question
       */
      const serviceActivitiesAnswers: Array<CheckboxGroupCardOption> =
        answers[SERVICE_ACTIVITIES_QUESTION];

      const retailActivitiesAnswers: Array<CheckboxGroupCardOption> =
        answers['product-location'];

      return (
        (serviceActivitiesAnswers != null &&
          serviceActivitiesAnswers.some(
            (i) => i.value === SERVICE_IN_BUSINESS_LOCATION
          )) ||
        (retailActivitiesAnswers != null &&
          retailActivitiesAnswers.some((i) => i.value === 'IN_STORE_RETAIL'))
      );
    },
  },
];
