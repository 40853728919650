import { ObButton } from '../../../../index';

interface AddNodeButtonProps {
  nodeType: 'group' | 'rule';
  onAdd: () => void;
}

export const AddNodeButton = ({
  nodeType = 'rule',
  onAdd,
}: AddNodeButtonProps) => (
  <div className='relative flex items-center justify-center mt-3'>
    <ObButton
      iconLeft='plus'
      label={nodeType == 'rule' ? 'Add Rule' : 'Add Group'}
      type='button'
      variant={nodeType == 'rule' ? 'primary' : 'secondary'}
      onClick={onAdd}
    />
  </div>
);
