/**
 * line/alerts/thumbs-down
 */
export const ThumbsDown = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.4991 2V13M22.4991 9.8V5.2C22.4991 4.07989 22.4991 3.51984 22.2811 3.09202C22.0893 2.71569 21.7834 2.40973 21.4071 2.21799C20.9792 2 20.4192 2 19.2991 2H8.61709C7.15561 2 6.42486 2 5.83466 2.26743C5.31447 2.50314 4.87238 2.88242 4.56032 3.36072C4.20625 3.90339 4.09513 4.62564 3.8729 6.07012L3.34983 9.47012C3.05672 11.3753 2.91017 12.3279 3.19288 13.0691C3.44102 13.7197 3.90773 14.2637 4.513 14.6079C5.20261 15 6.16641 15 8.09401 15H8.89908C9.45913 15 9.73916 15 9.95307 15.109C10.1412 15.2049 10.2942 15.3578 10.3901 15.546C10.4991 15.7599 10.4991 16.0399 10.4991 16.6V19.5342C10.4991 20.896 11.6031 22 12.9649 22C13.2897 22 13.5841 21.8087 13.716 21.5119L17.0768 13.9502C17.2296 13.6062 17.3061 13.4343 17.4269 13.3082C17.5337 13.1967 17.6648 13.1115 17.81 13.0592C17.9743 13 18.1625 13 18.5389 13H19.2991C20.4192 13 20.9792 13 21.4071 12.782C21.7834 12.5903 22.0893 12.2843 22.2811 11.908C22.4991 11.4802 22.4991 10.9201 22.4991 9.8Z'
        stroke='currentColor'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
