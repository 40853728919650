import { OutboundCampaignGoal } from '@outbound/types';
import { RootStore } from '../../root-store';

class CampaignOutboundGoalContext {
  protected _rootStore: RootStore;
  protected readonly _type: OutboundCampaignGoal;

  constructor(_rootStore: RootStore, type: OutboundCampaignGoal) {
    this._rootStore = _rootStore;
    this._type = type;
  }
}

export default CampaignOutboundGoalContext;
