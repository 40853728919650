import {
  ObButton,
  ObDivider,
  ObIcon,
  ObTypography,
} from '@outbound/design-system';
import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';
import { useNavigate } from 'react-router-dom';
import { useCreatePaymentBillingPortalForUserMutation } from '../../../../../query/payment-manager/use-payment-manager-endpoints';
import { useFetchWorkspaceDetails } from '../../../../../query/workspace/use-workspace-endpoints';

export const BillingSettingPage = () => {
  const navigate = useNavigate();
  const { data: workspace } = useFetchWorkspaceDetails();
  const { mutateAsync: billingPortal } =
    useCreatePaymentBillingPortalForUserMutation();

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-4'>
        <ObTypography variant='h2'>Billing</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          Manage your payment information and invoices. For billing questions
          please contact{' '}
          <ObLink
            href='mailto:accounts-receivable@outbound.com'
            label='accounts-receivable@outbound.com'
            variant={'primary'}
          />
        </ObTypography>
      </div>
      <ObDivider />
      <ObTypography variant='h4'>Payment Method</ObTypography>
      <div className='rounded border border-dark/border/default/normal bg-dark/background/surface p-4 flex justify-between'>
        <div className='flex flex-row gap-4 flex-grow'>
          <ObIcon
            icon={'creditCard'}
            size={'medium'}
            color='secondary'
          />
          <div>
            <ObTypography
              variant='subtitle3'
              color='secondary'
            >
              Payment Method
            </ObTypography>
            <ObTypography
              variant='body2'
              color='secondary'
            >
              {workspace?.paymentProcessorCustomerId != null
                ? 'Visa ending in 1234'
                : 'To add a payment method, please pick a plan.'}
            </ObTypography>
          </div>
        </div>
        <div className='flex justify-center items-center flex-shrink'>
          {workspace?.paymentProcessorCustomerId && (
            <ObButton
              label='Update'
              variant='secondary'
              size='medium'
              onClick={() => {
                billingPortal({
                  customerId: workspace.paymentProcessorCustomerId ?? '',
                });
              }}
            />
          )}
          <ObButton
            label='View Plans'
            variant='secondary'
            size='medium'
            onClick={() => {
              navigate('../plan');
            }}
          />
        </div>
      </div>
      <ObDivider />
      <ObTypography variant='h4'>Billing Details</ObTypography>
      <ObDivider />
      <ObTypography variant='h4'>Current Plan</ObTypography>
      <div className='flex flex-col gap-2'>
        <div>
          <ObTypography color='secondary'>Outbound Good - Monthly</ObTypography>
          <ObTypography color='secondary'>
            $99 per Month, Billed Yearly - Renews Jan 1, 2025
          </ObTypography>
        </div>
        <div>
          <ObButton
            label='Update Plan'
            variant='secondary'
            size='medium'
            onClick={() => {
              navigate('../plan');
            }}
          />
        </div>
      </div>

      <ObDivider />
      <ObTypography variant='h4'>Billing History</ObTypography>
      <ObTypography
        variant='body2'
        color='tertiary'
        className='text-center'
      >
        No billing history available.
      </ObTypography>
    </div>
  );
};
