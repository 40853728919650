import { useDrawerService } from '@outbound/design-system';
import {
  SubServiceEditDrawer,
  SubServiceEditDrawerProps,
} from './sub-service-edit-drawer';

/**
 * Hook to open the sub-service create drawer
 * This is a self contained hook that opens the sub-service edit drawer for the given sub-service
 */
export const useSubServiceEditDrawer = () => {
  const drawerService = useDrawerService();

  const openSubServiceEditDrawer = (props: SubServiceEditDrawerProps) => {
    return drawerService.pushDrawer({
      title: 'Edit Subservice',
      description: 'Make changes to your subservice.',
      drawerContent: <SubServiceEditDrawer {...props} />,
      size: 'medium',
    });
  };

  return { openSubServiceEditDrawer };
};
