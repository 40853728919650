import { ObTypography } from '@outbound/design-system';
import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';

export const MarketingPlanCardBaseTitle = ({
  title,
  level,
  actions,
  wrapOnMobile = false,
}: {
  title: string;
  level: 'h2' | 'h3' | 'h4';
  actions?: ReactNode;
  wrapOnMobile?: boolean;
}) => {
  return (
    <div
      className={cx(
        'flex  gap-2 justify-between items-center',
        wrapOnMobile ? 'flex-col sm:flex-row' : 'flex-row'
      )}
    >
      <ObTypography variant={level}>{title}</ObTypography>
      <div>{actions}</div>
    </div>
  );
};
