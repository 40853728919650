import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';

interface CustomColorPickerPointerProps {
  className?: string;
}

export const CustomColorPickerPointer = ({
  className,
}: CustomColorPickerPointerProps) => {
  return (
    <div
      className={cx(
        'w-[32px] h-[32px] -ml-4 -mt-4 rounded-full bg-[white] border-[1px] flex items-center cursor-pointer fixed',
        className
      )}
      style={{ borderColor: 'rgba(0, 0, 0, .1)' }}
    >
      <div
        className='w-[16px] h-[16px] -ml-4 -mt-4 rounded-full bg-[#313E48] border-[1px] !mx-[auto] !my-[0]'
        style={{ borderColor: 'rgba(0, 0, 0, .1)' }}
      />
    </div>
  );
};

export const CustomColorPickerPointerHue = (): ReactNode => {
  return <CustomColorPickerPointer className='-mt-[10px]' />;
};
