import { IntegrationResource } from './integration-transport';

import facebookIcon from './integration-data-asset/facebook-icon.svg';
import googleAdsIcon from './integration-data-asset/google-ads-icon.svg';
import instagramIcon from './integration-data-asset/instagram-icon.svg';
import metaIcon from './integration-data-asset/meta-ads-icon-01.svg';

export const integrationData: Array<IntegrationResource> = [
  {
    id: '3c0b7577-95fe-4f32-b868-7314b74d90d0',
    slug: 'google-ads-manager',
    name: 'Google Ads',
    shortDescription:
      'Publish Google Search and Display Ads directly from Outbound.com',
    iconImageUrl: googleAdsIcon,
    categories: ['AD_CHANNEL'],
    documentationUrl: '',
    integrationWebsiteUrl: 'https://ads.google.com/intl/en_us/home/',
    supportProvider: '',
    supportLink: '',
    configurationType: 'GOOGLE_AD_MANAGER',
    overview: {
      title: 'Google Ads Manager Integration Overview for Outbound.com',
      content: {
        intro:
          'At Outbound.com, we make it simple for you to manage, optimize, and publish your digital ad campaigns across all major marketing channels, including Google Ads. Our Google Ads Manager integration provides a seamless, user-friendly experience, enabling you to create, track, and scale your ads without leaving the Outbound.com platform.',
        features: [
          {
            title: 'Easily Create and Manage Google Ads',
            description:
              "Design and launch ads directly from Outbound.com, leveraging Google's powerful advertising network to target the right audience.",
          },
          {
            title: 'Centralized Campaign Management',
            description:
              'Manage your Google Ads alongside other marketing channels like Facebook, podcasts, and more, from a single dashboard.',
          },
          {
            title: 'Real-Time Analytics',
            description:
              "Track the performance of your Google Ads campaigns in real time, including impressions, clicks, conversions, and more, to ensure you're getting the best ROI.",
          },
          {
            title: 'Optimized Budgeting',
            description:
              'Set and adjust budgets for your Google Ads campaigns with ease, making sure your spend is optimized for performance.',
          },
          {
            title: 'Multi-Channel Synchronization',
            description:
              'Keep your messaging and branding consistent across all your marketing channels with our fully integrated campaign tools.',
          },
        ],
        conclusion:
          'Outbound.com simplifies ad publishing, allowing you to focus on scaling your business while we handle the complexities of running successful ad campaigns across Google and beyond.',
      },
    },
  },
  {
    id: 'acf31967-d1ab-4089-aea7-3795621dbd9d',
    slug: 'google-ads-manager-non-profit',
    name: 'Google Ads for Non-Profit',
    shortDescription: 'Publish Google Search Free Ads for your Non Profit',
    iconImageUrl: googleAdsIcon,
    categories: ['AD_CHANNEL'],
    configurationType: 'GOOGLE_AD_MANAGER_GRANT',
    documentationUrl: '',
    integrationWebsiteUrl:
      'https://www.google.com/nonprofits/offerings/google-ad-grants/',
    supportProvider: '',
    supportLink: '',
    overview: {
      title: 'Google Ads Grant Accounts Overview for Outbound.com',
      content: {
        intro:
          'At Outbound.com, we support non-profits and charitable organizations by helping them maximize their impact through Google Ads Grant Accounts. These accounts are designed specifically for search ads and provide up to $10,000 per month in ad spend to eligible organizations.',
        features: [
          {
            title: 'Exclusive for Search Ads',
            description:
              'Google Ads Grant Accounts are designed exclusively for search ads, allowing your organization to increase its visibility on Google Search results.',
          },
          {
            title: 'Up to $10,000 in Monthly Ad Spend',
            description:
              'Eligible non-profits can receive up to $10,000 per month in free Google Ads spend, ensuring that your organization can reach the right audience without the usual budgetary concerns.',
          },
          {
            title: 'Centralized Campaign Management',
            description:
              'Manage your Google Ads Grant campaigns alongside other channels like Facebook and Podcasts directly from Outbound.com, ensuring consistency in your messaging across platforms.',
          },
          {
            title: 'Real-Time Analytics',
            description:
              'Track your Google Ads Grant campaigns in real time, monitoring key metrics such as impressions, clicks, and conversions to maximize your campaign effectiveness.',
          },
          {
            title: 'Optimized Ad Performance',
            description:
              "Leverage Outbound.com's optimization tools to ensure that your Google Ads Grant campaigns are reaching the right audience and driving meaningful engagement.",
          },
        ],
        conclusion:
          'With Outbound.com and Google Ads Grant Accounts, non-profits can harness the power of digital advertising to raise awareness, drive engagement, and amplify their mission without the usual costs.',
      },
    },
  },
  {
    id: 'a4aa8ea2-2783-4d2b-b708-84f9c52a6450',
    slug: 'meta-ads-manager',
    name: 'Meta Ads',
    shortDescription:
      'Publish to any Meta property including Facebook and Instagram Ads directly from Outbound.com',
    iconImageUrl: metaIcon,
    categories: ['AD_CHANNEL'],
    documentationUrl: '',
    integrationWebsiteUrl: 'https://www.facebook.com/business/m/ads-manager',
    supportProvider: '',
    configurationType: 'META_AD_MANAGER',
    supportLink: '',
    overview: {
      title: 'Meta Ads Integration Overview for Outbound.com',
      content: {
        intro:
          "At Outbound.com, we simplify the process of creating and managing Meta Ads (formerly Facebook Ads) campaigns, allowing you to reach your audience across Facebook, Instagram, and Messenger. Our integration ensures that your ads are optimized for engagement and performance on Meta's vast social media network.",
        features: [
          {
            title: 'Multi-Platform Reach',
            description:
              'With Meta Ads, you can easily target users across Facebook, Instagram, and Messenger, ensuring your ads are seen on the platforms where your audience spends their time.',
          },
          {
            title: 'Advanced Audience Targeting',
            description:
              "Leverage Meta's powerful audience segmentation tools to target users based on demographics, interests, behaviors, and more, ensuring your ads reach the right people.",
          },
          {
            title: 'Centralized Campaign Management',
            description:
              'Manage your Meta Ads campaigns alongside Google Ads, Podcasts, and other marketing channels directly from Outbound.com, ensuring consistent messaging across all platforms.',
          },
          {
            title: 'Real-Time Performance Tracking',
            description:
              'Monitor key performance indicators such as reach, engagement, conversions, and more in real time, allowing you to adjust your Meta Ads campaigns for maximum effectiveness.',
          },
          {
            title: 'Optimized Budgeting and Bidding',
            description:
              "Set and control your ad spend with ease, using Outbound.com's optimization tools to manage bidding strategies and ensure you're getting the best ROI from your Meta Ads campaigns.",
          },
        ],
        conclusion:
          "With Outbound.com's Meta Ads integration, you can drive engagement and grow your audience across the Meta platforms, all from one easy-to-use dashboard.",
      },
    },
  },
  {
    id: '8395ebff-d25e-41f9-baba-70f0e9a0e305',
    slug: 'facebook-ads-manager',
    name: 'Facebook Ads',
    shortDescription: 'Publish Facebook directly from Outbound.com',
    iconImageUrl: facebookIcon,
    categories: ['AD_CHANNEL'],
    configurationType: 'META_AD_MANAGER',
    documentationUrl: '',
    integrationWebsiteUrl: 'https://www.facebook.com/business/m/ads-manager',
    supportProvider: '',
    supportLink: '',
    overview: {
      title: 'Meta Ads Integration Overview for Outbound.com',
      content: {
        intro:
          "At Outbound.com, we simplify the process of creating and managing Meta Ads (formerly Facebook Ads) campaigns, allowing you to reach your audience across Facebook, Instagram, and Messenger. Our integration ensures that your ads are optimized for engagement and performance on Meta's vast social media network.",
        features: [
          {
            title: 'Multi-Platform Reach',
            description:
              'With Meta Ads, you can easily target users across Facebook, Instagram, and Messenger, ensuring your ads are seen on the platforms where your audience spends their time.',
          },
          {
            title: 'Advanced Audience Targeting',
            description:
              "Leverage Meta's powerful audience segmentation tools to target users based on demographics, interests, behaviors, and more, ensuring your ads reach the right people.",
          },
          {
            title: 'Centralized Campaign Management',
            description:
              'Manage your Meta Ads campaigns alongside Google Ads, Podcasts, and other marketing channels directly from Outbound.com, ensuring consistent messaging across all platforms.',
          },
          {
            title: 'Real-Time Performance Tracking',
            description:
              'Monitor key performance indicators such as reach, engagement, conversions, and more in real time, allowing you to adjust your Meta Ads campaigns for maximum effectiveness.',
          },
          {
            title: 'Optimized Budgeting and Bidding',
            description:
              "Set and control your ad spend with ease, using Outbound.com's optimization tools to manage bidding strategies and ensure you're getting the best ROI from your Meta Ads campaigns.",
          },
        ],
        conclusion:
          "With Outbound.com's Meta Ads integration, you can drive engagement and grow your audience across the Meta platforms, all from one easy-to-use dashboard.",
      },
    },
  },
  {
    id: '050ba878-b716-4857-bfc8-3374f6a445dd',
    slug: 'instagram-ads-manager',
    name: 'Instagram Ads',
    shortDescription: 'Publish Instagram directly from Outbound.com',
    iconImageUrl: instagramIcon,
    categories: ['AD_CHANNEL'],
    configurationType: 'META_AD_MANAGER',
    documentationUrl: '',
    integrationWebsiteUrl: 'https://www.facebook.com/business/m/ads-manager',
    supportProvider: '',
    supportLink: '',
    overview: {
      title: 'Meta Ads Integration Overview for Outbound.com',
      content: {
        intro:
          "At Outbound.com, we simplify the process of creating and managing Meta Ads (formerly Facebook Ads) campaigns, allowing you to reach your audience across Facebook, Instagram, and Messenger. Our integration ensures that your ads are optimized for engagement and performance on Meta's vast social media network.",
        features: [
          {
            title: 'Multi-Platform Reach',
            description:
              'With Meta Ads, you can easily target users across Facebook, Instagram, and Messenger, ensuring your ads are seen on the platforms where your audience spends their time.',
          },
          {
            title: 'Advanced Audience Targeting',
            description:
              "Leverage Meta's powerful audience segmentation tools to target users based on demographics, interests, behaviors, and more, ensuring your ads reach the right people.",
          },
          {
            title: 'Centralized Campaign Management',
            description:
              'Manage your Meta Ads campaigns alongside Google Ads, Podcasts, and other marketing channels directly from Outbound.com, ensuring consistent messaging across all platforms.',
          },
          {
            title: 'Real-Time Performance Tracking',
            description:
              'Monitor key performance indicators such as reach, engagement, conversions, and more in real time, allowing you to adjust your Meta Ads campaigns for maximum effectiveness.',
          },
          {
            title: 'Optimized Budgeting and Bidding',
            description:
              "Set and control your ad spend with ease, using Outbound.com's optimization tools to manage bidding strategies and ensure you're getting the best ROI from your Meta Ads campaigns.",
          },
        ],
        conclusion:
          "With Outbound.com's Meta Ads integration, you can drive engagement and grow your audience across the Meta platforms, all from one easy-to-use dashboard.",
      },
    },
  },
];
