import { FC } from 'react';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';

export interface ObSelectedOptionGroupProps {
  /**
   * A callback function to be called when the Edit button is clicked
   */
  onEditClickedCallback: () => void;
  children?: React.ReactNode;
}

export const ObSelectedOptionGroup: FC<ObSelectedOptionGroupProps> = ({
  onEditClickedCallback,
  children,
}: ObSelectedOptionGroupProps) => {
  return (
    <div className='flex flex-row items-start justify-between'>
      <div className='flex flex-col justify-center gap-3'>{children}</div>
      <div className='mt-[2px]'>
        <ObButton
          variant='ghost'
          label='Edit'
          onClick={onEditClickedCallback}
        />
      </div>
    </div>
  );
};
