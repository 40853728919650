import { cva, cx } from 'class-variance-authority';
import { HTMLAttributes, ReactNode } from 'react';
import { ObColorType } from '../../../tokens/colors/colors';

export type TagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

export type HeadingType = HTMLAttributes<HTMLHeadingElement>;
export type ParagraphType = HTMLAttributes<HTMLParagraphElement>;
export type SpanType = HTMLAttributes<HTMLSpanElement>;

export type ObTypographyProps = (HeadingType | ParagraphType | SpanType) & {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'subtitle1'
    | 'subtitle2'
    | 'subtitle3'
    | 'buttonLarge'
    | 'buttonSmall'
    | 'avatar1'
    | 'avatar2'
    | 'avatar3'
    | 'avatar4'
    | 'avatar5';
  children?: ReactNode;
  color?: ObColorType;
  className?: string;
  truncate?: boolean;
  as?: TagType;
  contrast?: boolean;
};

export type ObTypographyVariant = ObTypographyProps['variant'];

const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const paragraphs = [
  'body1',
  'body2',
  'body3',
  'subtitle1',
  'subtitle2',
  'subtitle3',
];
const spans = [
  'buttonLarge',
  'buttonSmall',
  'caption',
  'overline',
  'avatar1',
  'avatar2',
  'avatar3',
  'avatar4',
  'avatar5',
];

export const ObTypography = ({
  children,
  className,
  variant = 'body2',
  color = 'primary',
  truncate,
  as,
  contrast = false,
  ...props
}: ObTypographyProps) => {
  const Tag =
    as ||
    (cx({
      [`${variant}`]: headings.includes(variant),
      [`p`]: paragraphs.includes(variant),
      [`span`]: spans.includes(variant),
    }) as TagType) ||
    ('p' as TagType);

  const typography = cva('typography transform-color duration-300', {
    variants: {
      contrast: {
        true: [],
        false: [],
      },
      color: {
        primary: ['text-contentPrimaryLight', 'dark:text-contentPrimaryDark'],
        invertedPrimary: [
          'dark:text-dark/content/inverted-primary',
          'text-light/content/inverted-primary',
        ],
        primaryV2: [
          'text-contentPrimaryLight',
          'dark:text-actionPrimaryV2NormalDark',
        ],
        secondary: [
          'text-contentSecondaryLight',
          'dark:text-contentSecondaryDark',
        ],
        tertiary: [
          'text-contentTertiaryLight',
          'dark:text-contentTertiaryDark',
        ],
        negative: [
          'text-contentNegativeLight',
          'dark:text-contentNegativeDark',
        ],

        content: [],
        positive: [
          'text-contentPositiveLight',
          'dark:text-contentPositiveDark',
        ],
        informative: [],
        warning: ['dark:text-dark/content/warning'],
        lime: [],
        cyan: [],
        mint: [],
        yellow: [],
        cobalt: [],
        teal: [],
        inherit: ['text-inherit'],
      },
      intent: {
        h1: [
          'font-sans',
          'font-semibold',
          'text-5xl',
          'sm:leading-[64px]',
          'xl:text-6xl',
        ],
        h2: [
          'font-sans',
          'font-semibold',
          'text-3xl',
          'sm:leading-[40px]',
          'xs:max-xl:text-4xl',
          'xl:text-5xl',
        ],
        h3: [
          'font-sans',
          'font-semibold',
          'text-2xl',
          'xs:max-xl:text-3xl',
          'xl:text-4xl',
        ],
        h4: [
          'font-sans',
          'font-semibold',
          'text-xl',
          'xs:max-xl:text-2xl',
          'xl:text-3xl',
        ],
        h5: ['font-sans', 'font-semibold', 'text-xl', 'xl:text-2xl'],
        h6: ['font-sans', 'font-semibold', 'text-xl'],
        subtitle1: ['font-sans', 'font-medium', 'text-base'],
        subtitle2: ['font-sans', 'font-medium', 'text-sm'],
        subtitle3: ['font-sans', 'font-medium', 'text-xs'],
        body1: ['font-sans', 'text-xs', 'xs:text-base'],
        body2: ['font-sans', 'text-xs', 'xs:text-xl'],
        body3: ['font-sans', 'text-xs'],
        buttonLarge: ['font-sans', 'font-medium', 'text-sm', 'select-none'],
        buttonSmall: ['font-sans', 'font-medium', 'text-xs', 'select-none'],
        avatar1: ['font-sans', 'font-medium', 'text-xs'],
        avatar2: ['font-sans', 'font-medium', 'text-xl'],
        avatar3: ['font-sans', 'font-medium', 'text-2xl'],
        avatar4: ['font-sans', 'font-medium', 'text-3xl'],
        avatar5: ['font-sans', 'font-medium', 'text-xs'],
      },
      truncate: {
        true: 'truncate',
        false: '',
      },
    },
    compoundVariants: [
      {
        color: 'primary',
        contrast: true,
        className: ['dark:text-contentPrimaryLight', 'text-contentPrimaryDark'],
      },
    ],
    defaultVariants: {
      intent: 'body2',
      color: 'primary',
    },
  });

  return (
    <Tag
      data-variant={variant}
      data-color={color}
      {...props}
      className={cx(
        typography({ intent: variant, color, truncate, contrast }),
        className
      )}
    >
      {children}
    </Tag>
  );
};
