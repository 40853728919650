import { PlaybookExportWhiteLabel } from '../playbook/playbook.api.type';

export enum DocumentTemplateType {
  PLAYBOOK_REPORT_PDF = 'PLAYBOOK_REPORT_PDF',
  PLAYBOOK_PRESENTATION_PDF = 'PLAYBOOK_PRESENTATION_PDF',
  MARKETING_PLAN_PDF = 'MARKETING_PLAN_PDF',
  RECURRING_REPORT_EXPORT_PDF = 'RECURRING_REPORT_EXPORT_PDF',
}

export enum DocumentContentDispositionType {
  INLINE = 'INLINE',
  ATTACHMENT = 'ATTACHMENT',
}

export interface PostDocumentsRequestResource {
  templateName: DocumentTemplateType;
  content: Record<string, any>;
  whiteLabel?: PlaybookExportWhiteLabel;
}

export interface PostDocumentsGenerateDownloadUrlRequestResource {
  contentDisposition?: DocumentContentDispositionType;
}

export interface DocumentPathParameters {
  id: string;
}

export enum DocumentStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  PENDING_GENERATION = 'PENDING_GENERATION',
}

export interface DocumentResource {
  id: string;
  templateName: DocumentTemplateType;
  content: Record<string, any>;
  status: DocumentStatus;
  createdAtTimestamp: string;
  updatedAtTimestamp: string;
}
