import { ReactNode } from 'react';
import { MarketingPlanCardBaseTitle } from './marketing-plan-card-base-title';

export const MarketingPlanCardTitle = ({
  title,
  variant = 'secondary-card',
  actions,
  wrapOnMobile,
}: {
  title: string;
  /**
   * In the design some cards are more important than others. The primary cards have larger titles whereas the secondary
   * cards have smaller titles and are typically in the right column on desktop such as campaign details.
   */
  variant?: 'primary-card' | 'secondary-card';
  actions?: ReactNode;
  wrapOnMobile?: boolean;
}) => {
  return (
    <MarketingPlanCardBaseTitle
      actions={actions}
      title={title}
      level={variant === 'primary-card' ? 'h2' : 'h3'}
      wrapOnMobile={wrapOnMobile}
    />
  );
};
