export const Building07 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.91683 5.83333H12.0835M7.91683 9.16667H12.0835M7.91683 12.5H12.0835M15.0002 17.5V5.16667C15.0002 4.23325 15.0002 3.76654 14.8185 3.41002C14.6587 3.09641 14.4038 2.84144 14.0901 2.68166C13.7336 2.5 13.2669 2.5 12.3335 2.5H7.66683C6.73341 2.5 6.2667 2.5 5.91018 2.68166C5.59658 2.84144 5.34161 3.09641 5.18182 3.41002C5.00016 3.76654 5.00016 4.23325 5.00016 5.16667V17.5M16.6668 17.5H3.3335'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fillRule='evenodd'
        clipRule='evenodd'
      />

      <path
        d='M6.24984 9.16667H3.83317C3.36646 9.16667 3.13311 9.16667 2.95485 9.25749C2.79804 9.33739 2.67056 9.46487 2.59067 9.62167C2.49984 9.79993 2.49984 10.0333 2.49984 10.5V17.5M13.7498 9.16667H16.1665C16.6332 9.16667 16.8666 9.16667 17.0448 9.25749C17.2016 9.33739 17.3291 9.46487 17.409 9.62167C17.4998 9.79993 17.4998 10.0333 17.4998 10.5V17.5M13.7498 17.5V5.16667C13.7498 4.23325 13.7498 3.76654 13.5682 3.41002C13.4084 3.09641 13.1534 2.84144 12.8398 2.68166C12.4833 2.5 12.0166 2.5 11.0832 2.5H8.9165C7.98308 2.5 7.51637 2.5 7.15985 2.68166C6.84625 2.84144 6.59128 3.09641 6.43149 3.41002C6.24984 3.76654 6.24984 4.23325 6.24984 5.16667V17.5M18.3332 17.5H1.6665M9.1665 5.83333H10.8332M9.1665 9.16667H10.8332M9.1665 12.5H10.8332'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </svg>
  );
};
