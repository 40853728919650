import { SubscriptionPlanProps } from '../../../../../base-component-props.type';
import { ObButton } from '../../../../../components/elements/ob-button/ob-button';
import { ObTypography } from '../../../../../components/elements/ob-typography/ob-typography';

export interface LandingPageTypeCardProps extends SubscriptionPlanProps {
  title: string;
  userIntentCopy: string;
  planFeatures: string[];
  requiresStarterPlanOrHigher: boolean;
  ctaLabel: string;
  ctaOnClickCallback: () => void;
}

/**
 *
 */
export const LandingPageTypeCard = ({
  title,
  userIntentCopy,
  planFeatures,
  requiresStarterPlanOrHigher,
  currentSubscriptionPlan,
  ctaLabel,
  ctaOnClickCallback,
}: LandingPageTypeCardProps) => {
  return (
    <div className='flex flex-col flex-1 max-w-[328px] justify-between gap-6 border dark:border-dark/border/default/normal rounded-md p-3'>
      <div className='flex flex-col justify-center items-center gap-2'>
        <ObTypography
          variant='h2'
          className='text-center'
        >
          {title}
        </ObTypography>
        <ObTypography
          className='text-center'
          variant='body2'
          color='secondary'
        >
          {userIntentCopy}
        </ObTypography>
      </div>
      <ul className='list-disc pl-8 pb-3 dark:text-dark/content/primary text-light/content/primary'>
        {planFeatures.map((feature, index) => (
          <li
            className='list-item pb-1'
            key={index}
          >
            <ObTypography>{feature}</ObTypography>
          </li>
        ))}
      </ul>
      <ObButton
        onClick={ctaOnClickCallback}
        label={
          requiresStarterPlanOrHigher && currentSubscriptionPlan === 'FREE'
            ? 'Upgrade Plan'
            : ctaLabel
        }
        iconLeft={
          requiresStarterPlanOrHigher && currentSubscriptionPlan === 'FREE'
            ? 'lock'
            : undefined
        }
        variant='primary'
        size='large'
        fullWidth='mobile'
      />
    </div>
  );
};
