import { ObButton, ObIconAvatar, ObTypography } from '@outbound/design-system';
import { IconSystemKeys } from '@outbound/design-system/src/tokens/icons/icons';

export const FullPageErrorMessage = ({
  icon,
  heading,
  message,
  actionLabel,
  actionOnClick,
}: {
  icon?: IconSystemKeys;
  heading: string;
  message: string;
  actionLabel: string;
  actionOnClick: () => any;
}) => {
  return (
    <div className='max-w-screen-xl m-auto min-h-screen flex items-center justify-center'>
      <div className='flex flex-col gap-3 max-w-xl'>
        {icon && (
          <div className='pt-1'>
            <ObIconAvatar
              shape='rounded'
              icon={icon}
              size='large'
            />
          </div>
        )}
        <div>
          <ObTypography variant='h4'>{heading}</ObTypography>
          <ObTypography
            variant='body3'
            color='secondary'
          >
            {message}
          </ObTypography>
        </div>

        <div>
          <ObButton
            size='medium'
            fullWidth='mobile'
            onClick={actionOnClick}
            variant='primary'
            label={actionLabel}
          />
        </div>
      </div>
    </div>
  );
};
