import {
  ObFormRenderer,
  useObFormRendererContext,
} from '@outbound/design-system';
import { useCallback, useEffect, useState } from 'react';
import {
  useFetchBusinessDetails,
  usePatchBusinessDetails,
} from '../../../../../query/playbook/use-playbook-settings-business-details-endpoints';
import { PlaybookEditFooter } from '../_common/playbook-edit-footer';
import { PlaybookPageContent } from '../_common/playbook-page-content';
import { PlaybookPageHeading } from '../_common/playbook-page-heading';
import {
  mapApiResourceToFormValues,
  mapFormValuesToApiResource,
} from './forms/online-presence-form-utils';
import { ONLINE_PRESENCE_FORM_DEF } from './forms/online-presence-form.def';
import { ONLINE_PRESENCE_PAGE_DEF } from './online-presence.def';

export const OnlinePresence = () => {
  const [isInitialValuesFetched, setIsInitialValuesFetched] =
    useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});

  const { data: onlinePresence, isFetched: isOnlinePresenceFetched } =
    useFetchBusinessDetails();

  const { mutateAsync: patchBusinessDetails } = usePatchBusinessDetails();

  useEffect(() => {
    if (isOnlinePresenceFetched && !isInitialValuesFetched) {
      setInitialValues(mapApiResourceToFormValues(onlinePresence!));
      setIsInitialValuesFetched(true);
    }
  }, [isInitialValuesFetched, isOnlinePresenceFetched, onlinePresence]);

  const submitForm = useCallback(
    async (data: any) => {
      return patchBusinessDetails({ values: mapFormValuesToApiResource(data) });
    },
    [patchBusinessDetails]
  );

  const [areChangesUnsaved, setAreChangesUnsaved] = useState<boolean>(false);

  const { getRegisteredFormById } = useObFormRendererContext();

  const onFormDirtyChange = useCallback((isDirty: boolean) => {
    setAreChangesUnsaved(isDirty);
  }, []);

  return (
    <>
      <article className='flex flex-col gap-8  flex-grow '>
        <PlaybookPageHeading title={ONLINE_PRESENCE_PAGE_DEF.title} />
        {isInitialValuesFetched && (
          <>
            <PlaybookPageContent>
              <ObFormRenderer
                formDefinition={ONLINE_PRESENCE_FORM_DEF}
                onSubmitCallback={submitForm}
                defaultValues={initialValues}
                hideSubmitButton={true}
                onFormDirtyChangeCallback={onFormDirtyChange}
              />
            </PlaybookPageContent>
          </>
        )}
      </article>

      <PlaybookEditFooter
        areChangesUnsaved={areChangesUnsaved}
        onSaveClickedCallback={async () => {
          return getRegisteredFormById(
            ONLINE_PRESENCE_FORM_DEF.id
          )?.submitFunction();
        }}
        onDiscardClickedCallback={() => {
          getRegisteredFormById(
            ONLINE_PRESENCE_FORM_DEF.id
          )?.discardChangesFunction();
        }}
      />
    </>
  );
};
