import { formatDistance, parseISO } from 'date-fns';

export const timeAgoFromIsoString = (iso8601String: string): string => {
  if (iso8601String == null || iso8601String.trim().length === 0) {
    return '';
  }
  return formatDistance(parseISO(iso8601String), new Date(), {
    addSuffix: true,
  });
};
