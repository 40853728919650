import { LandingPageResource, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../base-transformer';
import { simulatePatch } from '../sync-framework/patch-simulator/patch-simulator';
import LandingPage from './landing-page';
import LandingPageSelfHosted, {
  LandingPageSelfHostedConstructorData,
} from './self-hosted/landing-page-self-hosted';

class LandingPageTransformer extends BaseTransformer<
  LandingPageResource,
  LandingPage
> {
  fromApiResource(resource: LandingPageResource): LandingPage {
    const {
      id,
      obrn,
      lifecycleStatus,
      url,
      thumbnailUrlPublicUrl,
      thumbnailGenerationStatus,
      type,
      title,
      httpsEnabled,
    } = resource;

    const data: LandingPageSelfHostedConstructorData = {
      url: url,
      lifecycleStatus: lifecycleStatus,
      thumbnailGenerationStatus: thumbnailGenerationStatus,
      pageTitle: title,
      thumbnailUrlPublicUrl: thumbnailUrlPublicUrl,
      httpsEnabled,
    };

    if (type === 'SELF_HOSTED') {
      return new LandingPageSelfHosted(this._rootStore, id, obrn, data);
    } else {
      throw new Error('Unsupported Landing Page Type');
    }
  }
  createPatchForCurrentModelAndIncomingResource(
    currentModel: LandingPage,
    incomingResource: LandingPageResource
  ): ServerDelta[] {
    if (currentModel.id !== incomingResource.id) {
      throw new Error('Model and Resource ID Mismatch');
    }

    //Convert Incoming Resource to Model
    const incomingModel = this.fromApiResource(incomingResource);

    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(), //This is a simulated server timestamp
      this._rootStore.clientId //This makes it seem like the update is coming from this client which is not true. A quick fix to satisfy the contact. Need to review of the implications of this and if it matters for now.
    );
  }
}

export default LandingPageTransformer;
