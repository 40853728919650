import { cva } from 'class-variance-authority';
import { useEffect, useRef, useState } from 'react';
import { useOnDragAndDropFile } from '../../../hooks/use-on-drag-and-drop-file';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObButton } from '../ob-button/ob-button';
import { ObLoadingSpinner } from '../ob-loading-spinner/ob-loading-spinner';
import { ObProgressBar } from '../ob-progress-bar/ob-progress-bar';
import { ObTypography } from '../ob-typography/ob-typography';
import { useObUploadAllowedFileTypes } from '../ob-upload-allowed-file-types/use-ob-upload-allowed-file-types';
import { ObUploadStatus } from '../ob-upload-status/ob-upload-status';
import { BaseObUploadProps } from '../ob-upload/ob-upload.type';
import { useObUpload } from '../ob-upload/use-ob-upload';

export interface ObUploadDropzoneProps extends BaseObUploadProps {
  /**
   * Indicates if the picker field is disabled.
   */
  disabled: boolean;
  /**
   * Controls if the user is able to use an existing asset from the asset library in lieu of uploading a new file.
   */
  enableAssetLibrary?: boolean;
  /**
   * Indicates the width of the dropzone. If set to `responsive`, the dropzone will take up the full width of its parent container.
   * If set to `medium`, the dropzone will be 330px wide.
   *
   * Prefer the `responsive` option and allow the parent to control the width of the dropzone;
   * The `medium` option is provided for backwards compatibility with the previous usages
   *
   * Default: responsive
   */
  size?: 'medium' | 'responsive';
}
export const ObUploadDropzone = ({
  fileUploadHandlerFactory,
  disabled = false,
  onFileUploadComplete,
  enableAssetLibrary,
  acceptedFileTypes = [],
  size = 'responsive',
}: ObUploadDropzoneProps) => {
  const fileUploadInputRef = useRef<HTMLInputElement>(null);

  const [errorMessage, setErrorMessage] = useState('');

  const {
    setIsUploading,
    uploadFiles,
    setProgress,
    setIsError,
    setIsSuccess,
    progress,
    isError,
    isUploading,
    isProgressSupported,
    isSuccess,
  } = useObUpload(fileUploadHandlerFactory);

  const onNativeFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = fileUploadInputRef.current?.files;
    onFilesDropped(Array.from(files ?? []));
    e.target.value = ''; //Clear the file input
  };

  const {
    acceptedExtensionsString,
    acceptedExtensionsUiString,
    findInvalidFileTypes,
    buildFileTypeErrorMessage,
  } = useObUploadAllowedFileTypes(acceptedFileTypes);

  const setErrorState = (message: string) => {
    setErrorMessage(message);
    setIsError(true);
    setIsUploading(false);
    setIsSuccess(false);
    setProgress(0);
    setTimeout(() => {
      setIsError(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 600);
    }, 3000);
  };

  const onFilesDropped = (files: Array<File> | null) => {
    if (files == null || files?.length === 0 || disabled) {
      return;
    }
    const rejectedFileTypes = findInvalidFileTypes(files);
    if (rejectedFileTypes.length > 0) {
      setErrorState(
        buildFileTypeErrorMessage(acceptedFileTypes, rejectedFileTypes)
      );
    } else {
      setIsUploading(true);
      const uploadQ = uploadFiles!(files);

      uploadQ.subscribe(
        (progress) => {
          setProgress(progress.files[0]?.progress ?? 0);
          if (
            progress.files[0]?.status === 'complete' &&
            onFileUploadComplete
          ) {
            onFileUploadComplete({
              fileId: progress.files[0]?.uploadedFileId ?? '',
              data: progress.files[0]?.data,
            });
          }
        },
        (error) => {
          setErrorState('Unable to upload file');
          console.error('Error:', error);
        },
        () => {
          setProgress(100);
          setIsUploading(false);
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
        }
      );
    }
  };

  const { setIsDragAndDropDisabled, handleDrag, handleDrop, dragActive } =
    useOnDragAndDropFile(onFilesDropped);

  useEffect(() => {
    setIsDragAndDropDisabled(disabled);
  }, [disabled, setIsDragAndDropDisabled]);

  const buttonContainerStyles = cva(
    ['flex flex-row gap-2 transition-all duration-500'],
    {
      variants: {
        isUploading: {
          true: ['opacity-0'],
          false: [],
        },
        dragActive: {
          true: [],
          false: [],
        },
        disabled: {
          true: [],
          false: [],
        },
        isSuccess: {
          true: ['opacity-0'],
          false: [],
        },
        isError: {
          true: 'opacity-0',
          false: [],
        },
      },
      compoundVariants: [
        {
          dragActive: false,
          isUploading: false,
          isSuccess: false,
          isError: false,
          class: 'opacity-100',
        },
      ],
    }
  );

  const dropzoneStyles = cva(
    [
      'group',
      'relative overflow-hidden',
      'flex flex-col justify-center items-center p-4',
      'h-[180px]',
      'border rounded border-dashed',
      'transition-all ease-in-out duration-300',
      'dark:border-dark/border/default/normal',
      '',
    ],
    {
      compoundVariants: [
        {
          isUploading: false,
          dragActive: true,
          disabled: false,
          class: 'dark:bg-dark/action/neutral/active',
        },
        {
          isUploading: true,
          dragActive: false,
          disabled: false,
          class: 'dark:bg-dark/action/neutral/normal',
        },
        {
          isUploading: false,
          dragActive: false,
          disabled: false,
          class: [
            'dark:bg-dark/action/neutral/normal',
            'dark:hover:dark/border/default/hover dark:active:dark/border/default/active',
          ],
        },
      ],
      variants: {
        size: {
          responsive: ['flex-1'],
          medium: ['w-[330px]'],
        },
        dragActive: {
          true: ' opacity-0',
          false: '',
        },
        isUploading: {
          true: '',
          false: '',
        },
        disabled: {
          true: 'opacity-50 cursor-not-allowed',
          false: ['opacity-100'],
        },
      },
    }
  );

  const labelStyles = cva('transition-all ease-in-out duration-500', {
    variants: {
      disabled: {
        true: 'cursor-not-allowed',
      },
      isUploading: {
        true: '',
        false: '',
      },
      isSuccess: {
        true: '',
        false: '',
      },
      isError: {
        true: 'opacity-0',
        false: '',
      },
    },
    compoundVariants: [
      {
        isUploading: false,
        isSuccess: true,
        class: 'opacity-0',
      },
      {
        isUploading: true,
        isSuccess: false,
        class: 'opacity-0',
      },
      {
        isUploading: false,
        isSuccess: false,
        isError: false,
        class: 'opacity-100',
      },
    ],
  });

  const progressStyles = cva(
    'flex flex-col justify-center items-center gap-2 w-full transition-all ease-in-out duration-300 h-0 mt-0',
    {
      variants: {
        disabled: {
          true: 'cursor-not-allowed',
          false: '',
        },
        isUploading: {
          true: 'opacity-100 h-full mt-6',
          false: 'opacity-0',
        },
      },
    }
  );

  return (
    <>
      <div
        data-testid='ob-upload-dropzone'
        className={dropzoneStyles({ disabled, dragActive, isUploading, size })}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className='flex flex-col gap-2 justify-center items-center'>
          <div
            className={labelStyles({
              isUploading,
              isSuccess,
              disabled,
              isError,
            })}
          >
            <ObIcon
              icon='upload'
              color='secondary'
              size='small'
            />
          </div>

          <ObTypography
            className={labelStyles({
              isUploading,
              isSuccess,
              disabled,
              isError,
            })}
            variant='body2'
          >
            Drag and Drop{dragActive}
          </ObTypography>
        </div>
        <div className={progressStyles({ isUploading, disabled })}>
          <ObTypography
            color='secondary'
            variant='subtitle3'
          >
            Uploading File
          </ObTypography>
          {isProgressSupported && <ObProgressBar percentage={progress ?? 0} />}
          {!isProgressSupported && (
            <ObLoadingSpinner
              includeBrand={false}
              size={24}
            />
          )}
        </div>
        <div className='flex flex-col gap-2 justify-center items-center'>
          <ObTypography
            className={labelStyles({
              isUploading,
              isSuccess,
              disabled,
              isError,
            })}
            variant='body3'
          >
            or
          </ObTypography>

          <div
            className={buttonContainerStyles({
              isUploading,
              dragActive,
              disabled,
              isSuccess,
              isError,
            })}
          >
            <ObButton
              label={enableAssetLibrary ? 'Upload' : 'Browse Files'}
              variant='primary'
              disabled={disabled || isUploading}
              size={'medium'}
              onClick={(e) => {
                e.preventDefault(); //Prevents any parent form from submitting
                e.stopPropagation();
                fileUploadInputRef.current?.click(); //Open Native File Picker
              }}
            />
            {enableAssetLibrary && (
              <ObButton
                label='Library'
                variant='outline'
                disabled={disabled || isUploading}
                size={'medium'}
                onClick={(e) => {
                  e.preventDefault(); //Prevents any parent form from submitting
                  e.stopPropagation();
                }}
              />
            )}
          </div>
          <ObTypography
            className={buttonContainerStyles({
              isUploading,
              dragActive,
              disabled,
              isSuccess,
              isError,
            })}
            variant='body3'
            color='secondary'
          >
            {acceptedExtensionsUiString}
          </ObTypography>
        </div>
        <ObUploadStatus
          isSuccess={isSuccess}
          isError={isError}
          errorMessage={errorMessage}
        />
      </div>

      <input
        ref={fileUploadInputRef}
        className='hidden'
        multiple={false}
        accept={acceptedExtensionsString}
        onChange={onNativeFileInputChange}
        type='file'
      />
    </>
  );
};
