import { CreateServiceResourceRequest, ServiceResource } from '@outbound/types';

/**
 * Interface for the value shape of the service form
 * used to populate and submit the form
 */
export interface ServiceFormValues {
  name: string;
  description: string;
  //Pricing Section
  averagePricePerSale: number;
  //Keyword Section
  relatedKeywords: Array<string>;
  negativeKeywords: Array<string>;
}

/**
 * Converts the Service Form Values to the API shape
 * @param values
 * @returns
 */
export const mapFormValuesToApiResource = (
  values: ServiceFormValues
): CreateServiceResourceRequest => {
  return {
    name: values.name,
    description: values.description,
    relatedKeywords: values.relatedKeywords ?? [],
    negativeKeywords: values.negativeKeywords ?? [],
    pricing: {
      averagePrice: values.averagePricePerSale ?? 0,
    },
  };
};

/**
 * Converts a ServiceAreaResource to a shape that can be used to populate the create / edit service area form
 * @param resource
 * @returns
 */
export const mapApiResourceToFormValues = (
  resource: ServiceResource
): ServiceFormValues => {
  return {
    name: resource.name,
    description: resource.description ?? '',
    relatedKeywords: resource.relatedKeywords ?? [],
    negativeKeywords: resource.negativeKeywords ?? [],
    averagePricePerSale: resource.pricing?.averagePrice ?? 0,
  };
};
