import {
  BaseLocationResource,
  PhysicalLocationResource,
  ServerDelta,
  ServiceAreaResource,
} from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import { simulatePatch } from '../../sync-framework/patch-simulator/patch-simulator';
import Location from './location';
import PhysicalLocationTransformer from './physical-location/physical-location-transformer';
import ServiceAreaLocationTransformer from './service-area/service-area-transformer';

class LocationTransformer extends BaseTransformer<
  BaseLocationResource,
  Location
> {
  private physicalLocationTransformer: PhysicalLocationTransformer;
  private serviceAreaLocationTransformer: ServiceAreaLocationTransformer;
  constructor(rootStore: RootStore) {
    super(rootStore);
    this.physicalLocationTransformer = new PhysicalLocationTransformer(
      rootStore
    );
    this.serviceAreaLocationTransformer = new ServiceAreaLocationTransformer(
      rootStore
    );
  }

  fromApiResource(resource: BaseLocationResource): Location {
    if (resource.type === 'SERVICE_AREA') {
      const serviceArea = resource as ServiceAreaResource;
      return this.serviceAreaLocationTransformer.fromApiResource(serviceArea);
    } else if (resource.type === 'PHYSICAL') {
      const physicalLocation = resource as PhysicalLocationResource;
      return this.physicalLocationTransformer.fromApiResource(physicalLocation);
    } else {
      throw new Error(`Unknown location type: ${resource.type}`);
    }
  }

  createPatchForCurrentModelAndIncomingResource(
    currentModel: Location,
    resource: BaseLocationResource
  ): ServerDelta[] {
    if (currentModel.id !== resource.id) {
      throw new Error('Model and Resource ID Mismatch');
    }

    //Convert Incoming Resource to Model
    const incomingModel = this.fromApiResource(resource);

    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(), //This is a simulated server timestamp
      this._rootStore.clientId //This makes it seem like the update is coming from this client which is not true. A quick fix to satisfy the contact. Need to review of the implications of this and if it matters for now.
    );
  }
}

export default LocationTransformer;
