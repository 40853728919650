import { BehaviorSubject, Observable } from '@reactivex/rxjs/dist/package';

/**
 * Reports back the progress of the file upload.
 * (All Files in an upload request)
 */
export interface FilesUploaderProgress {
  files: Array<FilesUploaderFileProgress>;
}

/**
 * Reports back the progress of an individual file in the upload.
 */
export interface FilesUploaderFileProgress {
  /**
   * Unique identifier for the file generated by the client.
   */
  key: string;
  status: 'uploading' | 'complete' | 'error';
  /**
   * If supported by the file upload handler, the progress of the file upload.
   */
  progress?: number;
  error?: string;
  file: File;
  /**
   * The ID Generated by the server for the file.
   * In the case of uploading to the Asset Manger this would be the asset ID.
   */
  uploadedFileId?: string;
  /**
   * Any additional data that the file upload handler wants to report back.
   * In the case of uploading to the Asset Manger this could be used for the AssetResource.
   */
  data?: any;
}

/**
 * An interface for a file upload handler class.
 * A class that implements this interface should be able to upload files to a server for our file upload components.
 */
export interface IFileUploadHandler {
  uploadFiles: (files: Array<File>) => Observable<FilesUploaderProgress>;
  supportsProgressReporting: () => boolean;
}

export interface IFileUploadHandlerFactory {
  createFileUploadHandler: () => IFileUploadHandler;
}

/**
 * Used for testing purposes. A mock file upload handler that simulates a file upload.
 */
export class MockFileUploadHandler implements IFileUploadHandler {
  private subject: BehaviorSubject<FilesUploaderProgress>;

  constructor() {
    this.subject = new BehaviorSubject<FilesUploaderProgress>({
      files: [],
    });
  }

  public supportsProgressReporting(): boolean {
    return true;
  }

  public uploadFiles(files: Array<File>): Observable<FilesUploaderProgress> {
    let mockProgress = 0;
    // Simulate a file upload

    const interval = setInterval(() => {
      const progress: FilesUploaderProgress = {
        files: files.map((file) => {
          return {
            key: file.name,
            progress: mockProgress,
            status: 'uploading',
            file,
          };
        }),
      };

      if (mockProgress < 100) {
        mockProgress += 20;
        this.subject.next(progress);
      } else {
        this.subject.next({
          files: files.map((file) => {
            return {
              key: file.name,
              status: 'complete',
              progress: 100,
              file,
            };
          }),
        });
        setTimeout(() => {
          this.subject.complete();
        }, 1000); //Time for the progress bar to finish

        clearInterval(interval); //Stop the interval
      }
    }, 500);

    console.log('Uploading files:', this.subject);
    return this.subject.asObservable();
  }
}

/**
 * Used for testing purposes. A mock file upload handler that simulates a file upload.
 */
export class MockFileUploadHandlerFailure implements IFileUploadHandler {
  private subject: BehaviorSubject<FilesUploaderProgress>;

  constructor() {
    this.subject = new BehaviorSubject<FilesUploaderProgress>({
      files: [],
    });
  }

  public supportsProgressReporting(): boolean {
    return true;
  }

  public uploadFiles(files: Array<File>): Observable<FilesUploaderProgress> {
    let mockProgress = 0;
    // Simulate a file upload

    const interval = setInterval(() => {
      const progress: FilesUploaderProgress = {
        files: files.map((file) => {
          return {
            key: file.name,
            progress: mockProgress,
            status: 'uploading',
            file,
          };
        }),
      };

      if (mockProgress < 100) {
        mockProgress += 20;
        this.subject.next(progress);
      } else {
        this.subject.next({
          files: files.map((file) => {
            return {
              key: file.name,
              status: 'complete',
              progress: 100,
              file,
            };
          }),
        });

        setTimeout(() => {
          this.subject.error('');
        }, 1000); //Time for the progress bar to finish
        clearInterval(interval); //Stop the interval
      }
    }, 500);

    console.log('Uploading files:', this.subject);
    return this.subject.asObservable();
  }
}
