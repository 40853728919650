import { stringHasContent } from '../string';

export interface ParseDeltaPathResult {
  localPathSegment: string;
  id: string | undefined;
  childSegments: string | undefined;
  childSegmentsDecoded: string | undefined;
  hasChildSegment: boolean;
  hasIdSegment: boolean;
}

/**
 * Takes a Delta Path and returns the:
 * 1. Local Path Segment
 * 2. Whether there are additional segments
 * 3. Optional: The Second Segment as an ID (if it exists)
 * 4. Optional: Everything after the Second Segment (if it exists)
 *
 * Example
 * highlight/123456/creative/123456
 * would return
 * {
 * localPathSegment: '/highlight',
 * hasChildSegment: true,
 * id: '123456',
 * childSegment: '/creative/123456'
 * }
 * @param path
 * @returns
 */
export const parseDeltaPath = (path: string): ParseDeltaPathResult => {
  if (!path.startsWith('/')) {
    throw new Error('Path must start with a /');
  }
  let id: string | undefined;
  let childSegments: string | undefined;
  let childSegmentsDecoded: string | undefined;
  let hasChildSegment = false;

  const segments = path.split('/');
  //Put the forward slash back on the local path segment
  const localPathSegment = `/${segments[1]}`;
  const hasIdSegment = stringHasContent(segments[2]);
  if (hasIdSegment) {
    id = decodeURIComponent(segments[2]);
    hasChildSegment = segments.length > 3 && hasIdSegment;
    if (hasChildSegment) {
      if (segments.length > 3) {
        childSegments = `/${segments.slice(3).join('/')}`;
        childSegmentsDecoded = decodeURIComponent(childSegments);
      }
    }
  }

  return {
    localPathSegment,
    id,
    hasIdSegment,
    childSegments,
    childSegmentsDecoded,
    hasChildSegment,
  };
};
