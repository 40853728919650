import {
  SetupIntegrationFlow,
  SetupIntegrationStage,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import obProfileIcon from '../../assets/ob-profile-icon.png';
import { IntegrationXWorkspace } from '../../state/mobx-experiment/workspace/types/integration-x-workspace.type';

export interface SetupIntegrationFlowObservableProps {
  integrationXWorkspace: IntegrationXWorkspace;
}

const getInstructionForStage = (stage: SetupIntegrationStage) => {
  switch (stage) {
    case 'configuring':
      return 'Please wait while the integration is being configured.';
    case 'success':
      return 'Integration is now connected!';
    case 'error':
      return 'An error occurred while connecting the integration. Please try again.';
    case 'check-mail':
      return 'Please check your email to complete the integration setup.';
  }
};

export const SetupIntegrationFlowObservable = observer(
  ({ integrationXWorkspace }: SetupIntegrationFlowObservableProps) => {
    const { integration, configuration } = integrationXWorkspace;

    return (
      <SetupIntegrationFlow
        header={'Connecting To Google Ads'}
        integrationLogoUrl={integration.iconImagePublicUrl}
        integrationName={integration.name}
        stage={configuration!.calculateSetupFlowState}
        instructions={getInstructionForStage(
          configuration!.calculateSetupFlowState
        )}
        agencyName={'Outbound.com'} //Future White Label from Agency Model
        agencyIconUrl={obProfileIcon} //Future White Label from Agency Model
      />
    );
  }
);
