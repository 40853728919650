import { ListResponse, WorkspaceResource } from '@outbound/types';
import { useQuery } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

export const useFetchMyWorkspaces = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, ListResponse<WorkspaceResource>>(
    ['my-workspaces:get'],
    async () => {
      const response = await auth0AxiosClient.get('/my-workspaces');
      return response.data;
    },
    {
      onSuccess: () => {
        // Additional actions on query success
      },
    }
  );
};

export const useFetchMyWorkspacesOnDemand = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, ListResponse<WorkspaceResource>>(
    ['my-workspaces:get-on-demand'],
    async () => {
      const response = await auth0AxiosClient.get('/my-workspaces');
      return response.data;
    },
    {
      enabled: false,
      onSuccess: () => {
        // Additional actions on query success
      },
    }
  );
};
