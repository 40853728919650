import LoadingIndicator from '../../elements/LoadingIndicator';
import Box from '@mui/material/Box';

export const ObDataGridOverlayLoading = () => {
  return (
    <Box
      data-testid={'ob-data-grid-loading-overlay'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <LoadingIndicator />
    </Box>
  );
};
