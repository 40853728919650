import { cva, cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObIcon } from '../../tokens/icons/ob-icon/ob-icon';
import {
  avatarShapeStyles,
  avatarSizeStyles,
  getIconSizeForAvatarSize,
} from '../avatar-styles';
import { AvatarIconStyleProps, ObAvatarSize } from '../avatar.types';

/**
 * Public API for the ObIconAvatar component
 */
export interface ObIconAvatarProps extends AvatarIconStyleProps {
  size?: ObAvatarSize;
}

const iconAvatarColorStyles = cva('', {
  variants: {
    color: {
      negative: ['bg-dark/content/negative'],
      inherit: ['text-inherit bg-inherit'],
      ghost: [''],
      cyan: ['bg-dark/non-contextual/cyan'],
      purple: ['bg-dark/non-contextual/purple'],
      blue: ['bg-dark/non-contextual/blue'],
    },
  },
});

/**
 * The ObIconAvatar component is a simple avatar that displays an icon inside of a rounded square.
 * The consumer can configure the icon color and size
 * @returns
 */
export const ObIconAvatar: FC<ObIconAvatarProps> = ({
  icon,
  size = 'medium',
  color = 'ghost',
  shape = 'rounded',
}: ObIconAvatarProps) => {
  const iconColor = color === 'ghost' ? 'content' : 'invertedPrimary';

  return (
    <div
      className={cx(
        iconAvatarColorStyles({ color }),
        avatarSizeStyles({ size }),
        avatarShapeStyles({ shape }),
        'border border-dark/border/default/normal',
        'flex justify-center items-center'
      )}
    >
      <ObIcon
        icon={icon}
        color={iconColor}
        size={getIconSizeForAvatarSize(size)}
      />
    </div>
  );
};
