import { ObButton, ObGridHeader } from '@outbound/design-system';
import { useParams } from 'react-router-dom';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import { LandingPageListObserver } from '../../../object-card/landing-page/landing-page-list-observer';
import { useRootStore } from '../../../state/mobx-experiment/use-root-store';
import Workspace from '../../../state/mobx-experiment/workspace/workspace';
import { FullPageErrorMessage } from '../campaigns/components/full-page-error-message';
import { DashboardRouteParams } from '../dashboard-route-param.type';
import { useLandingPageCta } from './call-to-actions/use-landing-page-cta';

export const LandingPageListPage = () => {
  const { workspaceSlug } = useParams<DashboardRouteParams>();
  const { navigateToLandingPageWithObrn } = useAppNavigation();

  const { landingPageStore, workspaceStore } = useRootStore();
  const { startAddLandingPageWorkflow } = useLandingPageCta();

  const workspace: Workspace | null = workspaceStore.getById(workspaceSlug!);
  const landingPages = landingPageStore.list();

  //Here to keep TS Happy. Handled by parent component
  if (workspace == null) {
    return <></>;
  }

  if (landingPages.length === 0) {
    return (
      <FullPageErrorMessage
        icon={'LandingPageIcon'}
        heading={'No Landing Pages'}
        message={
          'Landing Pages are the first step in your customer journey. Create one to get started.'
        }
        actionLabel={'Add Landing Page'}
        actionOnClick={() => {
          startAddLandingPageWorkflow({
            onExistingLandingPageSelected: () => {
              // Do Nothing
            },

            availableLandingPages: [],
            landingPageRenderFunction: () => <></>,
            onSelfHostedLandingPageUrlProvided: (url: string) => {
              const landingPage = workspace.createLandingPage(url);
              navigateToLandingPageWithObrn(landingPage.obrn);
            },
            currentSubscriptionPlan: workspace.plan,
          });
        }}
      />
    );
  }

  return (
    <div className='max-w-screen-lg w-full m-auto min-h-screen flex'>
      <div className='flex flex-col p-6 h-full flex-1'>
        <header>
          <ObGridHeader
            title={'Landing Pages'}
            count={landingPages.length}
            controls={
              <ObButton
                label={'Add Landing Page'}
                size='large'
                iconLeft={'plus'}
                onClick={() =>
                  startAddLandingPageWorkflow({
                    onExistingLandingPageSelected: () => {
                      // Do Nothing
                    },
                    availableLandingPages: [],
                    landingPageRenderFunction: () => <></>,
                    onSelfHostedLandingPageUrlProvided: (url: string) => {
                      const landingPage = workspace.createLandingPage(url);
                      navigateToLandingPageWithObrn(landingPage.obrn);
                    },
                    currentSubscriptionPlan: workspace.plan,
                  })
                }
              />
            }
          />
        </header>
        <div className='pt-8'>
          <LandingPageListObserver
            landingPages={landingPages}
            variant={'grid'}
          />
        </div>
      </div>
    </div>
  );
};
