import {
  IntegrationCategory,
  IntegrationConfigurationType,
} from '@outbound/types';
import { AxiosInstance } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';
import { integrationData } from './integrations.data';

export interface IntegrationResource {
  id: string;
  /**
   * The path to use for the integration in the url of the app
   */
  slug: string;
  /**
   * The User facing name of the Integration
   */
  name: string;
  /**
   * A short description of the integration
   */
  shortDescription: string;
  /**
   * An icon image of the integration
   */
  iconImageUrl: string;

  /**
   * What Categories the integration should be tagged in
   */
  categories: Array<IntegrationCategory>;

  documentationUrl: string;

  integrationWebsiteUrl: string;

  supportProvider: string;

  supportLink: string;

  overview: any;

  /**
   * The type of configuration that the integration requires
   */
  configurationType: IntegrationConfigurationType;
}

export class IntegrationTransport extends BaseTransport<IntegrationResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }
  async internalBootstrap() {
    integrationData.forEach((integration) => {
      this.notifyStoreOfServiceUpdateCallback?.(integration.id, integration);
    });
  }

  protected fetchById(id: string): Promise<IntegrationResource | null> {
    const resource = integrationData.filter((integration) => {
      return integration.id === id;
    });
    return Promise.resolve(resource[0]);
  }
  public acceptEmbeddedResource(_resource: IntegrationResource): void {
    throw new Error('Method not implemented.');
  }
}
