import { useDrawerService } from '@outbound/design-system';
import {
  SubServiceCreateDrawer,
  SubServiceCreateDrawerProps,
} from './sub-service-create-drawer';

export const useSubServiceCreateDrawer = () => {
  const drawerService = useDrawerService();

  const openSubServiceCreateDrawer = (
    props: SubServiceCreateDrawerProps
  ): Promise<any> => {
    return drawerService.pushDrawer({
      title: 'Add New Subservice',
      description: 'Fill out the information below to add a new subservice.',
      drawerContent: <SubServiceCreateDrawer {...props} />,
      size: 'medium',
    });
  };

  return { openSubServiceCreateDrawer };
};
