import { useRef, useEffect, useMemo } from 'react';
import { CustomInputRefsProps } from '../ob-form-renderer.types';

const CUSTOM_INPUTS_REF_NAMES = [
  'dateStartRef',
  'timeStartRef',
  'dateEndRef',
  'timeEndRef',
  'ruleBuilderRef',
];

export const getRefsById = (
  customInputsRefs: CustomInputRefsProps,
  fieldId: string
) => {
  const { dateStartRef, dateEndRef, timeStartRef, timeEndRef } =
    customInputsRefs;

  return {
    dateStartRef: dateStartRef?.[fieldId + '_dateStart']?.current,
    dateEndRef: dateEndRef?.[fieldId + '_dateEnd']?.current,
    timeStartRef: timeStartRef?.[fieldId + '_timeStart']?.current,
    timeEndRef: timeEndRef?.[fieldId + '_timeEnd']?.current,
  };
};

export const getRefValues = (
  customInputsRefs: CustomInputRefsProps,
  fieldId: string
) => {
  const { dateStartRef, dateEndRef, timeStartRef, timeEndRef } =
    customInputsRefs;

  const datePartValue =
    dateStartRef?.[fieldId + '_dateStart']?.current?.getValue();
  const datePartEndValue =
    dateEndRef?.[fieldId + '_dateEnd']?.current?.getValue();
  const timePartValue =
    timeStartRef?.[fieldId + '_timeStart']?.current?.getValue();
  const timePartEndValue =
    timeEndRef?.[fieldId + '_timeEnd']?.current?.getValue();

  return {
    datePartValue,
    datePartEndValue,
    timePartValue,
    timePartEndValue,
  };
};

export const useCustomRefs = ({
  setCustomInputsRefs,
}: {
  setCustomInputsRefs: (refs: any) => void;
}) => {
  const dateStartRef = useRef(null);
  const timeStartRef = useRef(null);
  const dateEndRef = useRef(null);
  const timeEndRef = useRef(null);

  const customRefs: any = useMemo(() => {
    return {
      dateStartRef,
      timeStartRef,
      dateEndRef,
      timeEndRef,
    };
  }, []);

  useEffect(() => {
    if (!setCustomInputsRefs) return;

    setCustomInputsRefs((prevState: any) => {
      let refs = {};

      CUSTOM_INPUTS_REF_NAMES.forEach((ref) => {
        const customRef = {
          [ref]: {
            ...(prevState?.[ref] ? prevState?.[ref] : []),
            ...(customRefs?.[ref]?.current?.id && {
              [customRefs?.[ref]?.current?.id]: customRefs?.[ref],
            }),
          },
        };

        refs = { ...refs, ...customRef };
      });

      return refs;
    });
  }, [customRefs, setCustomInputsRefs]);

  return customRefs;
};
