import { ReactNode } from 'react';
import { ObDrawerFooter } from '../../../components/molecules/ob-drawer-footer/ob-drawer-footer';

export interface ObPeekabooFooterTemplateProps {
  /**
   * The content of the drawer body
   */
  children?: ReactNode;
  /**
   * Whether the footer should be shown
   */
  showFooter?: boolean;
  /**
   * The label for the affirmative action
   */
  affirmativeActionButtonLabel: string;
  /**
   * The callback for the affirmative action
   */
  affirmativeActionButtonCallback: () => Promise<void>;
  /**
   * The label for the dismissive action
   */
  dismissiveActionButtonLabel: string;
  /**
   * The callback for the dismissive action
   */
  dismissiveActionButtonCallback: () => Promise<void>;
}
export const ObPeekabooFooterTemplate = ({
  children,
  showFooter = true,
  affirmativeActionButtonCallback,
  affirmativeActionButtonLabel,
  dismissiveActionButtonCallback,
  dismissiveActionButtonLabel,
}: ObPeekabooFooterTemplateProps) => {
  return (
    <aside className='flex flex-col justify-between flex-1 h-full '>
      {/* ADD Margin Bottom on Footer Show to Move elements fixed to the bottom
          of the page so they are not covered (When there is room) */}
      <div
        data-footer-visible={showFooter}
        className={`px-6 pb-6 flex flex-col transition-all duration-300 h-full overflow-scroll ${
          showFooter ? ' mb-[72px] ' : ' mb-0 '
        }`}
      >
        {/* Primary Drawer Content Renders Here */}
        {children}
      </div>
      {/* Animates in the footer when showFooter = true */}
      <div
        className={`absolute bottom-0 transition-all duration-300 ease-in-out w-full  ${
          showFooter ? 'opacity-100 ' : 'opacity-0 translate-y-[80px]'
        }`}
      >
        <ObDrawerFooter
          primaryActionLabel={affirmativeActionButtonLabel}
          primaryActionCallback={affirmativeActionButtonCallback}
          secondaryActionLabel={dismissiveActionButtonLabel}
          secondaryActionCallback={dismissiveActionButtonCallback}
        ></ObDrawerFooter>
      </div>
    </aside>
  );
};
