import { ObInputListbox } from '../../../atoms/inputs/ob-input-listbox/ob-input-listbox';
import { BaseFormFieldOption } from '../ob-form-renderer.types';
import type { CustomFieldComponentsProps } from './custom-field-components';

type SelectCustomFieldProps = Omit<
  CustomFieldComponentsProps,
  'fieldType' | 'fieldTypeSettings'
> & {
  options?: BaseFormFieldOption[];
};

export const SelectCustomField = ({
  size,
  id,
  field,
  isDisabled = false,
  errors,
  options,
}: SelectCustomFieldProps) => {
  return (
    <div data-testid={`select-${id}`}>
      <ObInputListbox
        inputId={id}
        isErrored={!!errors[id]}
        size={size}
        isDisabled={isDisabled}
        value={field.value}
        options={
          options?.map((option) => ({
            key: option.value,
            label: option.displayValue ?? option.value,
            value: option.value,
            description: '',
            data: option,
          })) ?? []
        }
        onValueChangedCallback={(option) => field.onChange(option ?? '')}
        isLoading={false}
      />
    </div>
  );
};
