import {
  PageResource,
  PlaybookPageSystemPage,
  PlaybookSettingsResourceIncludeAssets,
  SectionResource,
} from '@outbound/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import {
  playbookSettingsKeys,
  serviceKeys,
  subServiceKeys,
} from './playbook-query-key';

const SERVICE_RESOURCE_PATH = '/playbook';

export const useFetchPlaybook = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, SectionResource[]>(
    ['playbook:get'],
    async () => {
      const response = await auth0AxiosClient.get(SERVICE_RESOURCE_PATH);
      return response.data?.sections;
    }
  );
};

export const FETCH_PLAYBOOK_SETTINGS_QUERY = 'playbook:settings:get';

export const useFetchPlaybookSettings = () => {
  const { axiosInstance: auth0AxiosClient, isAuthenticatedWithWorkspace } =
    useAuth0Axios();
  const queryClient = useQueryClient();

  return useQuery<undefined, undefined, PlaybookSettingsResourceIncludeAssets>(
    playbookSettingsKeys.all,
    async () => {
      const response = await auth0AxiosClient.get(
        `${SERVICE_RESOURCE_PATH}/settings`,
        {
          params: {
            include: 'assets',
          },
        }
      );
      return response.data;
    },
    {
      enabled: isAuthenticatedWithWorkspace, //This API requires the user to be authenticated with a workspace
      onSuccess(data) {
        //Place each service into the cache
        data.services?.forEach((service) => {
          queryClient.setQueryData(serviceKeys.detail(service.id), service);
          service.subServices?.forEach((subService) => {
            queryClient.setQueryData(
              subServiceKeys.detail(service.id, subService.id),
              subService
            );
          });
        });
      },
    }
  );
};

export const useFetchPlaybookPageById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, PageResource>(
    ['playbook:page', id, 'get'],
    async () => {
      const response = await auth0AxiosClient.get(
        `${SERVICE_RESOURCE_PATH}/pages/${id}`
      );
      return response.data;
    },
    {
      enabled: !!id,
      onSuccess: () => {
        queryClient.invalidateQueries(['playbook:get']);
      },
    }
  );
};

/**
 * Generic polling function for playbook pages
 * @param id The id of the playbook page to poll
 * @param pollingFunction A function that returns true if the page should be polled, receives the page resource as an argument
 * @param pollingInterval Optional polling interval. Default 2000ms
 * @returns
 */
export const usePollPlaybookPage = (
  id: PlaybookPageSystemPage | null,
  pollingFunction: (page?: PageResource) => boolean,
  pollingInterval?: number
) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, PageResource>(
    ['playbook:page:business:industry', id, 'get'],
    async () => {
      const response = await auth0AxiosClient.get(
        `${SERVICE_RESOURCE_PATH}/pages/${id}`
      );
      return response.data;
    },
    {
      enabled: !!id,
      onSuccess: () => {
        // queryClient.invalidateQueries(['playbook:get']);
      },
      refetchInterval: (data?: PageResource) => {
        const shouldPoll = pollingFunction && pollingFunction(data);
        return shouldPoll ? pollingInterval ?? 2000 : false;
      },
      // Poll even when the tab is in the background
      refetchIntervalInBackground: false,
    }
  );
};

/**
 * Creates a new document
 * @returns
 */
export const useExportPlaybookHandout = () => {
  const flags = useFlags();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<string, undefined, void>(
    [`playbook:export:handout`],
    async () => {
      const response = await auth0AxiosClient.post(
        `${SERVICE_RESOURCE_PATH}/export/handout`,
        {
          whiteLabel: flags.documentServiceWhiteLabel,
        }
      );
      return response.data.documentId;
    },
    {
      onSuccess: () => {
        //
      },
    }
  );
};
