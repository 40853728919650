'use client';
import {
  createContext,
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import { ObFullDialog } from '../../molecules/ob-full-dialog/ob-full-dialog';
import { ObDialog } from '../../organisms/ob-dialog/ob-dialog';

import { Deferred } from '@otbnd/utils';
import { ObFormRenderer } from '../../organisms/ob-form-renderer/ob-form-renderer';
import { FormDefinition } from '../../organisms/ob-form-renderer/ob-form-renderer.types';
import {
  renderDialogTextContent,
  renderDialogTextContentWithInput,
} from './dialog-utils';
import {
  AffirmativeActionButtonProps,
  DialogTitleProps,
  DismissiveActionButtonProps,
} from './dialog.types';

/**
 * Public API for the DialogService
 */
export interface DialogServiceContextValue {
  /**
   * Lower level API to open a custom dialog. Should only be used if the other methods do not fit the use case.
   * @param dialogConfig Dialog configuration
   * In the future it would be nice to return an object that includes the dialog state as well as a function to close the dialog.
   * @returns  Promise that resolves when the dialog is closed with the affirmative action or rejects when the dialog is closed with the dismissive action.
   */
  openDialog: <T = void>(dialogConfig: DialogProps) => Promise<T>;
  /**
   * Opens a simple alert dialog with a single affirmative action button.
   * Should be used when the user needs to be informed about something but no action is possible.
   * @param alertConfig The configuration for the alert dialog including the title and body.
   * @returns A promise that resolves when the dialog is closed.
   */
  openAlert: (alertConfig: AlertDialogProps) => Promise<void>;
  /**
   * Opens a confirmation dialog with an affirmative and dismissive action button.
   * Should be used when the user needs to confirm an action before proceeding and has the option to cancel before proceeding.
   * @param confirmationConfig The configuration for the confirmation dialog including the title, body, and action button labels.
   * @returns A promise that resolves when the dialog is closed with the affirmative action or rejects when the dialog is closed with the dismissive action.
   */
  openConfirmation: (
    confirmationConfig: ConfirmationDialogProps
  ) => Promise<void>;
  /**
   * Opens a confirmation dialog that requires the user to type a specific value to confirm the action.
   * Should be used when the action is destructive and cannot be undone so we want to ensure the user is sure before proceeding.
   * Should Not be used for non-destructive actions. Use `openConfirmation` instead.
   * @param typeToConfirmConfig The configuration for the confirmation dialog including the title, body, and action button labels.
   * @returns A promise that resolves when the dialog is closed with the affirmative action or rejects when the dialog is closed with the dismissive action.
   */
  openTypeToConfirmation: (
    typeToConfirmConfig: ConfirmationByTypingValueDialogProps
  ) => Promise<void>;

  /**
   * Opens a dialog that renders a form using the ObFormRenderer component.
   * @param formRendererDialogConfig
   * @returns
   */
  openFormDialog: <T>(
    formRendererDialogConfig: FormRendererDialogProps
  ) => Promise<T>;

  /**
   * Used to programmatically close the top-most dialog.
   * Should typically not be used directly but instead use the affirmative or dismissive action callbacks to close the dialog.
   * Some use-cases may be on navigation or other global actions that should close the dialog.
   * Calling this method will reject the promise returned by the `openDialog`, `openAlert`, `openConfirmation`, or `openTypeToConfirmation` methods.
   */
  popDialog: (behavior?: 'affirmative' | 'dismissive') => Promise<void>;
}

/**
 * Used by the default implementation of the context.
 * Throws an error if the DialogServiceContext is not provided.
 */
const throwError = (): never => {
  throw new Error('DialogServiceContext not provided');
};

/**
 * Default context value for the DialogServiceContext when no provider is found.
 */
const DialogServiceContext = createContext<DialogServiceContextValue>({
  openDialog: throwError,
  openAlert: throwError,
  openConfirmation: throwError,
  openTypeToConfirmation: throwError,
  popDialog: throwError,
  openFormDialog: throwError,
});

/**
 * Provider public interface.
 * This provider accepts no arguments. It should be used as a wrapper around the application to provide the dialog service to all children.
 */
interface DialogServiceProviderProps {
  children?: ReactNode;
}

export interface DialogProps
  extends AffirmativeActionButtonProps,
    DismissiveActionButtonProps,
    DialogTitleProps {
  dialogContent: ReactNode;
  shouldCloseEscapePress?: boolean;
  fullScreen?: boolean;
  lightbox?: boolean;
  hasCloseButton?: boolean;
  hideDefaultActions?: boolean;
  hideTitle?: boolean;
}

/**
 * The public API for the dialog service's alert dialog.
 */
export interface AlertDialogProps
  extends DialogTitleProps,
    AffirmativeActionButtonProps {
  body: string | string[];
}

/**
 * The public API for the dialog service's confirmation dialog.
 */
export interface ConfirmationDialogProps
  extends AffirmativeActionButtonProps,
    DismissiveActionButtonProps,
    DialogTitleProps {
  body: string | string[];
}

/**
 * The public API for the dialog service's confirm by typing dialog.
 */
export interface ConfirmationByTypingValueDialogProps
  extends ConfirmationDialogProps {
  inputLabel: string;
  expectedValue: string;
}

/**
 * The public API for the dialog service's form renderer dialog.
 * This dialog renders a form using the ObFormRenderer component,
 * closes the dialog when the dismissive button is clicked
 * and submits the form when the affirmative action button is clicked.
 */
export interface FormRendererDialogProps extends DialogTitleProps {
  /**
   * The form definition to be rendered in the dialog.
   */
  formDefinition: FormDefinition;

  defaultValues?: any;

  /**
   * The callback function to be called when the form is submitted.
   * It will only be called after all form validations have passed.
   *
   * This is where the consumer can perform any async actions such as calling an API.
   * If the async action is successful, the promise should be resolved which will signal
   * the dialog to close and resolve the promise returned by the openDialog method.
   *
   * If the async action fails, the promise should be rejected which will signal the dialog to stay open
   */
  onFormSubmitCallback: (data: any) => Promise<any>;

  /**
   * The minimum height of the form. This is helpful for when you have a minor height adjustment due
   * to conditional fields that you want to eliminate.
   */
  minHeight?: string;
}

export interface FullScreenDialogProps {
  dialogContent: ReactNode;
}

/**
 * Defines additional props for the dialog that are not part of the public API
 * but are used internally by the DialogServiceProvider to manage the dialog stack.
 */
interface DialogPropsInternal extends DialogProps {
  /**
   * Unique identifier for the dialog.
   */
  id: string;
  /**
   * Controls whether the dialog is shown or hidden.
   */
  show: boolean;

  /**
   * Indicates that this dialog has been popped and only exists in the stack to animate out.
   * It will be removed from the stack after the animation completes.
   */
  isAnimatingOut: boolean;

  primaryActionCallback: any;
  secondaryActionCallback: () => void;
  hideDefaultActions?: boolean;
  dialogContainerStyles?: Record<string, string>;
}

interface DialogWrapperProps extends DialogProps {
  show: boolean;
  stackIndex: number;
  hideDefaultActions?: boolean;
  hasCloseButton?: boolean;
}

/**
 * Renders a Dialog component with the provided dialog content with a transition.
 */
const DialogWrapper: FC<DialogWrapperProps> = ({
  show = false,
  dialogContent,
  title,
  hideTitle = false,
  affirmativeActionButtonLabel,
  dismissiveActionButtonLabel,
  affirmativeActionButtonCallback,
  dismissiveActionButtonCallback,
  fullScreen = false,
  hideDefaultActions = false,
  hasCloseButton = false,
  lightbox = false,
}: DialogWrapperProps) => {
  /**
   * Intent here is to provide a way to open a full screen edge to edge UI over the current page
   * It doesn't have any styling or way to close the content and expects the template provided to
   * handle the close functionality from a visual UI perspective. keyboard events are still handled.
   */
  if (lightbox) {
    return (
      <Transition
        show={show}
        appear={true}
        as={Fragment}
        enter='duration-200 ease-out'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='duration-300 ease-out'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <Dialog
          as='div'
          className='relative z-10'
          onClose={dismissiveActionButtonCallback}
        >
          <div
            className='fixed inset-0 bg-black/30'
            aria-hidden='true'
          />
          {/* Full-screen container to center the panel */}
          <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
            <DialogPanel className={'flex-1 justify-center items-center'}>
              {dialogContent}
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    );
  }
  //This may be deprecated. Not sure if we are using this or if it is useful
  if (fullScreen) {
    return (
      <ObFullDialog
        open={show}
        onHide={affirmativeActionButtonCallback}
        scrollable
      >
        {dialogContent}
      </ObFullDialog>
    );
  }

  return (
    <Transition
      show={show}
      appear={true}
      as={Fragment}
      enter='duration-200 ease-out'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='duration-300 ease-out'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <Dialog
        as='div'
        className='relative z-10'
        onClose={dismissiveActionButtonCallback}
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div
          className='fixed inset-0 bg-black/30'
          aria-hidden='true'
        />
        {/* Full-screen container to center the panel */}
        <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
          <DialogPanel className={'flex-1 justify-center items-center'}>
            <ObDialog
              title={title}
              hideTitle={hideTitle}
              hideDefaultActions={hideDefaultActions}
              primaryFooterActionLabel={affirmativeActionButtonLabel}
              secondaryFooterActionLabel={dismissiveActionButtonLabel}
              onPrimaryActionCallback={affirmativeActionButtonCallback}
              onSecondaryActionCallback={dismissiveActionButtonCallback}
              hasCloseButton={hasCloseButton}
            >
              {dialogContent}
            </ObDialog>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
};

export const DialogServiceProvider: FC<DialogServiceProviderProps> = ({
  children,
}) => {
  const [dialogStack, setDialogStack] = useState<Array<DialogPropsInternal>>(
    []
  );

  /**
   * Closes the top-most dialog by removing it from the stack.
   * This implementation currently does not support transitioning because of the immediate removal of the dialog.
   * If we want to support transitions, we would need to add a `show` property to the dialog and animate the dialog out before removing it from the stack.
   * This is where the Promise that is currently returned may become useful.
   * @returns Promise that resolves when the dialog is closed. (NOT the promise returned by the openDialog method to the caller)
   */
  const popDialog = useCallback(
    (behavior: 'affirmative' | 'dismissive' = 'dismissive') => {
      const deferredTillTransition = new Deferred<void>();
      /**
       * Find the Top Most Dialog that is not already animating out
       */
      let dialogIdOfDialogBeingClosed: string | undefined;

      let affirmativeCallback: any;

      /**
       * Update the Dialog Stack with the new state of the dialog that is being closed.
       */
      setDialogStack((currentStack) => {
        const dialogBeingClosed = currentStack.find((d) => !d.isAnimatingOut);
        if (dialogBeingClosed) {
          dialogIdOfDialogBeingClosed = dialogBeingClosed.id;
          affirmativeCallback = dialogBeingClosed.primaryActionCallback;
        }
        return currentStack.map((d) => {
          if (d.id === dialogIdOfDialogBeingClosed) {
            return {
              ...d,
              isAnimatingOut: true,
              show: false,
            };
          } else {
            return d;
          }
        });
      });
      //After Transition is complete, remove the top most dialog from the stack
      setTimeout(() => {
        /**
         * Find the dialog in the stack and remove it after the animation completes.
         */
        if (behavior == 'affirmative' && affirmativeCallback != null) {
          affirmativeCallback?.();
        }
        setDialogStack((prev) =>
          prev.filter((d) => d.id !== dialogIdOfDialogBeingClosed)
        );
        deferredTillTransition.resolve();
      }, 400);
      /**
       * Not to be confused with the promise returned by the openDialog method to the caller.
       * This promise is used internally to manage the dialog stack and signals when the dialog is closed.
       */
      return deferredTillTransition.promise;
    },
    []
  );

  /**
   * Internal method to dismiss the top-most dialog and resolve the promise
   */
  const popDialogAffirmatively = useCallback(
    <T extends any = void>(deferred: Deferred<T>, data: T) => {
      console.log('Popping Dialog Affirmatively');
      deferred.resolve(data);
      popDialog();
    },
    [popDialog]
  );

  /**
   * Internal method to dismiss the top-most dialog and reject the promise
   */
  const popDialogDismissively = useCallback(
    <T extends any = void>(deferred: Deferred<T>, data?: T) => {
      deferred.reject(data);
      popDialog();
    },
    [popDialog]
  );

  /**
   * Opens a dialog and returns a promise that resolves when the dialog is closed.
   */
  const openDialog = useCallback(
    <T extends any = void>(dialog: DialogProps): Promise<T> => {
      const deferred = new Deferred<T>();
      setDialogStack((currentStack) => [
        ...currentStack,
        {
          ...dialog,
          show: true,
          isAnimatingOut: false,
          id: uuidv4(),
          primaryActionCallback: (callback: T) => {
            deferred.resolve(callback);
            popDialog();
          },
          secondaryActionCallback: () => {
            deferred.reject();
            popDialog();
          },
        },
      ]);

      return deferred.promise;
    },
    [popDialog]
  );

  const openConfirmation = useCallback(
    (dialog: ConfirmationDialogProps) => {
      const Content = renderDialogTextContent(dialog.body);
      return openDialog<void>({
        dialogContent: <Content />,
        title: dialog.title,
        affirmativeActionButtonLabel:
          dialog.affirmativeActionButtonLabel ?? 'Confirm',
        dismissiveActionButtonLabel:
          dialog.dismissiveActionButtonLabel ?? 'Cancel',
        shouldCloseEscapePress: true,
      });
    },
    [openDialog]
  );

  /**
   * Opens a dialog that renders a form using the ObFormRenderer component.
   */
  const openFormDialog = useCallback(
    <T extends any = void>({
      title,
      formDefinition,
      onFormSubmitCallback,
      defaultValues = {},
      minHeight = '',
    }: FormRendererDialogProps) => {
      const deferred = new Deferred<T>();

      openDialog({
        dialogContent: (
          <div style={{ minHeight }}>
            <ObFormRenderer
              formDefinition={formDefinition}
              defaultValues={defaultValues}
              /**
               * When the form is submitted we will run the onSubmitCallback and than if successful resolve the openDialog promise
               */
              onSubmitCallback={(data: any) => {
                /**
                 * Controls the forms "Thinking" state and allows us to control how the form renderer is behaving
                 */
                const internalFormRendererDeferred = new Deferred<void>();

                onFormSubmitCallback(data)
                  .then((response) => {
                    internalFormRendererDeferred.resolve(); //Let the Form Renderer know that the submission was successful
                    setTimeout(() => {
                      console.log('Form Submitted Successfully');
                      popDialogAffirmatively(deferred, response);
                    }, 500); //Close the dialog after the form renderer has had time to update the UI with a success indicator
                  })
                  .catch((e) => {
                    internalFormRendererDeferred.reject(e); //Let the Form Renderer know that the submission failed so it can update it's UI accordingly
                  });

                return internalFormRendererDeferred.promise;
              }}
              /**
               * When the form is cancelled we will dismiss the dialog and reject the openDialog promise
               */
              onCancelCallback={() => {
                popDialogDismissively<T>(deferred);
                return Promise.resolve();
              }}
              hideSubmitButton={false}
            />
          </div>
        ),
        title: title ?? 'No Title Provided',
        affirmativeActionButtonLabel: 'Submit',
        dismissiveActionButtonLabel: 'Cancel',
        dismissiveActionButtonCallback: () => {
          popDialogDismissively<T>(deferred);
        },
        shouldCloseEscapePress: true,
        hideDefaultActions: true,
      });

      return deferred.promise;
    },

    [openDialog, popDialogAffirmatively, popDialogDismissively]
  );

  const openTypeToConfirmation = useCallback(
    (dialog: ConfirmationByTypingValueDialogProps) => {
      return new Promise<void>((resolve, reject) => {
        const primaryActionCallback = async () => {
          const onDialogSuccess = () => {
            resolve();
            popDialog();
          };

          if (dialog.affirmativeActionButtonCallback) {
            // Only resolve if Primary Action Succeeds
            await dialog
              .affirmativeActionButtonCallback()
              .then(onDialogSuccess);
          } else {
            // Always resolve
            onDialogSuccess();
          }
        };

        const secondaryActionCallback = async () => {
          reject();
          popDialog();
        };

        const Content = renderDialogTextContentWithInput({
          paragraphs: dialog.body,
          inputLabel: dialog.inputLabel,
          expectedValue: dialog.expectedValue,
          affirmativeActionButtonLabel:
            dialog.affirmativeActionButtonLabel ?? 'Confirm',
          dismissiveActionButtonLabel:
            dialog.dismissiveActionButtonLabel ?? 'Cancel',
          onClickAffirmativeActionCallback: primaryActionCallback,
          onClickDismissiveActionCallback: secondaryActionCallback,
        });

        setDialogStack([
          ...dialogStack,
          {
            ...dialog,
            show: true,
            id: uuidv4(),
            isAnimatingOut: false,
            dialogContent: <Content />,
            title: dialog.title,
            hideDefaultActions: true,
            shouldCloseEscapePress: true,
            hasCloseButton: true,
            primaryActionCallback,
            secondaryActionCallback,
          },
        ]);
      });
    },
    [dialogStack, popDialog]
  );

  const openAlert = useCallback(
    (dialog: AlertDialogProps) => {
      const Content = renderDialogTextContent(dialog.body);
      return openDialog<void>({
        dialogContent: <Content />,
        title: dialog.title,
        affirmativeActionButtonLabel:
          dialog.affirmativeActionButtonLabel ?? 'Ok',
        shouldCloseEscapePress: false,
      });
    },
    [openDialog]
  );

  const service = useMemo(
    () => ({
      openDialog,
      openConfirmation,
      openAlert,
      openTypeToConfirmation,
      popDialog,
      openFormDialog,
    }),
    [
      openDialog,
      openConfirmation,
      openAlert,
      openTypeToConfirmation,
      popDialog,
      openFormDialog,
    ]
  );

  return (
    <DialogServiceContext.Provider value={service}>
      {children}
      {dialogStack.map((d, i) => (
        <DialogWrapper
          show={d.show}
          key={d.id}
          stackIndex={i}
          dialogContent={d?.dialogContent ?? <></>}
          title={d.title}
          shouldCloseEscapePress={d.shouldCloseEscapePress ?? true}
          affirmativeActionButtonLabel={d?.affirmativeActionButtonLabel}
          dismissiveActionButtonLabel={d?.dismissiveActionButtonLabel}
          affirmativeActionButtonCallback={d.primaryActionCallback}
          dismissiveActionButtonCallback={d.secondaryActionCallback}
          fullScreen={d.fullScreen}
          lightbox={d.lightbox}
          hideDefaultActions={d.hideDefaultActions}
          hideTitle={d.hideTitle}
          hasCloseButton={d.hasCloseButton}
        />
      ))}
    </DialogServiceContext.Provider>
  );
};

export const DialogServiceConsumer = DialogServiceContext.Consumer;

export const useDialogService = () => useContext(DialogServiceContext);
