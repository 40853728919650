import { cva, cx } from 'class-variance-authority';
import { useEffect } from 'react';
import { ObAvatar } from '../../../avatars';
import { ObButton } from '../../../components/elements/ob-button/ob-button';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { useDialogService } from '../../../components/services/dialog-service-provider/dialog-service-provider';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { AgencyBrandingProps } from '../../../white-label.type';

export type SetupIntegrationStage =
  | 'configuring'
  | 'success'
  | 'error'
  | 'check-mail';

const lineLeftStyles = cva(
  ' h-[2px] flex flex-1 rounded-md transition-colors duration-300',
  {
    variants: {
      stage: {
        configuring: ['bg-dark/contextual/positive animate-pulse'],
        success: ['bg-dark/contextual/positive'],
        error: ['bg-dark/contextual/negative'],
        'check-mail': ['bg-dark/contextual/positive'],
      },
    },
  }
);

const lineRightStyles = cva(
  ' h-[2px] flex flex-1 rounded-md transition-colors duration-300',
  {
    variants: {
      stage: {
        configuring: ['bg-dark/content/tertiary animate-pulse'],
        success: ['bg-dark/contextual/positive'],
        error: ['bg-dark/contextual/negative'],
        'check-mail': ['bg-dark/contextual/warning animate-pulse'],
      },
    },
  }
);

const configuringIconPosition = cva(
  'absolute left-[14px] transition-all duration-300  ease-in-out',
  {
    variants: {
      stage: {
        configuring: ['top-[14px] opacity-100'],
        success: ['top-[50px]  opacity-0'],
        error: ['top-[50px]  opacity-0'],
        'check-mail': ['top-[50px]  opacity-0'],
      },
    },
  }
);

const successIconPosition = cva(
  ' absolute left-[14px] transition-all duration-300 ease-in-out',
  {
    variants: {
      stage: {
        //Indicate where the success icon should be placed during each stage
        configuring: ['top-[-8px] opacity-0'],
        success: ['top-[14px]  opacity-100'],
        error: ['top-[50px]  opacity-0'],
        'check-mail': ['top-[-8px]  opacity-0'],
      },
    },
  }
);

const errorIconPosition = cva(
  ' absolute left-[14px] transition-all duration-300 ease-in-out',
  {
    variants: {
      stage: {
        configuring: ['top-[50px] opacity-0'],
        success: ['top-[50px] opacity-0'],
        error: ['top-[14px]  opacity-100'],
        'check-mail': ['top-[50px]  opacity-0'],
      },
    },
  }
);

const checkMailIconStyles = cva(
  ' absolute left-[14px] transition-all duration-300 ease-in-out',
  {
    variants: {
      stage: {
        configuring: ['top-[-8px] opacity-0'],
        'check-mail': ['top-[14px]  opacity-100'],
        success: ['top-[-8px] opacity-0'],
        error: ['top-[50px]  opacity-0'],
      },
    },
  }
);

export interface SetupIntegrationUserFlowProps extends AgencyBrandingProps {
  header: string;
  integrationLogoUrl: string;
  integrationName: string;
  stage: SetupIntegrationStage;
  instructions: string;
  actionLabel?: string;
  onActionCallback?: () => void;
}

export const SetupIntegrationFlow = ({
  header,
  instructions,
  integrationLogoUrl,
  integrationName,
  agencyIconUrl,
  agencyName,
  stage,
  actionLabel,
  onActionCallback,
}: SetupIntegrationUserFlowProps) => {
  const { popDialog } = useDialogService();

  useEffect(() => {
    if (stage === 'success') {
      setTimeout(() => {
        popDialog('affirmative');
      }, 1000);
    }
  }, [popDialog, stage]);

  const size = 'x-large';
  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='pb-4 flex flex-col gap-2'>
        <ObTypography
          variant='h2'
          className='text-center'
        >
          {header}
        </ObTypography>
        <ObTypography
          variant='body1'
          color='tertiary'
          className='text-center'
        >
          This may take a few minutes
        </ObTypography>
      </div>
      <div className='flex flex-row gap-4 items-center justify-between self-stretch'>
        <div className='flex flex-col items-center justify-center min-w-[124px] gap-1'>
          <ObAvatar
            size={size}
            shape='rounded'
            profileImageUrl={agencyIconUrl}
          />
          <ObTypography
            color='secondary'
            variant='body2'
            className='text-center line-clamp-2 min-h-[44px]'
          >
            {agencyName}
          </ObTypography>
        </div>
        <div className='flex flex-row gap-1 flex-1 justify-between items-center pb-[44px]'>
          <div className={lineLeftStyles({ stage })} />
          <div className='relative w-[48px] h-[48px]'>
            <div className={cx(successIconPosition({ stage }))}>
              <ObIcon
                icon={'check'}
                size='medium'
              />
            </div>
            <div className={cx(errorIconPosition({ stage }))}>
              <ObIcon
                icon={'errorMessage'}
                size='medium'
                color='negative'
              />
            </div>
            <div className={cx(configuringIconPosition({ stage }))}>
              <ObIcon
                size='small'
                icon='RefreshCw05'
                color='content'
                classNames='animate-spin'
              />
            </div>
            <div className={cx(checkMailIconStyles({ stage }))}>
              <ObIcon
                size='small'
                icon='email'
                color='content'
                classNames=''
              />
            </div>
          </div>

          <div className={lineRightStyles({ stage })} />
        </div>
        <div className='flex flex-col items-center justify-center min-w-[124px] gap-1'>
          <ObAvatar
            size={size}
            shape='rounded'
            profileImageUrl={integrationLogoUrl}
          />
          <ObTypography
            color='secondary'
            variant='body2'
            className='text-center line-clamp-2 min-h-[44px]'
          >
            {integrationName}
          </ObTypography>
        </div>
      </div>
      <ObTypography
        variant='body2'
        color={stage === 'error' ? 'negative' : 'tertiary'}
        className='text-center pb-4'
      >
        {instructions}
      </ObTypography>

      <div
        className={cx('w-full', onActionCallback ? 'opacity-100' : 'opacity-0')}
      >
        <ObButton
          fullWidth='mobile'
          variant='primary'
          size='large'
          disabled={onActionCallback == null}
          onClick={onActionCallback}
          label={actionLabel}
        />
      </div>
    </div>
  );
};
