export const DragIcon = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 5C14 3.89543 14.8954 3 16 3C17.1046 3 18 3.89543 18 5C18 6.10457 17.1046 7 16 7C14.8954 7 14 6.10457 14 5Z'
        fill='#787F85'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 12C14 10.8954 14.8954 10 16 10C17.1046 10 18 10.8954 18 12C18 13.1046 17.1046 14 16 14C14.8954 14 14 13.1046 14 12Z'
        fill='#787F85'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 19C14 17.8954 14.8954 17 16 17C17.1046 17 18 17.8954 18 19C18 20.1046 17.1046 21 16 21C14.8954 21 14 20.1046 14 19Z'
        fill='#787F85'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 5C7 3.89543 7.89543 3 9 3C10.1046 3 11 3.89543 11 5C11 6.10457 10.1046 7 9 7C7.89543 7 7 6.10457 7 5Z'
        fill='#787F85'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12C11 13.1046 10.1046 14 9 14C7.89543 14 7 13.1046 7 12Z'
        fill='#787F85'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19Z'
        fill='#787F85'
      />
    </svg>
  );
};
