export const MagicWand = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.34017 1.33073C9.34017 0.962539 9.63865 0.664062 10.0068 0.664062C10.375 0.664062 10.6735 0.962539 10.6735 1.33073V2.33073C10.6735 2.69892 10.375 2.9974 10.0068 2.9974C9.63865 2.9974 9.34017 2.69892 9.34017 2.33073V1.33073Z'
        fill='currentColor'
      />
      <path
        d='M12.8688 2.19266C13.1291 1.93231 13.5512 1.93231 13.8116 2.19266C14.0719 2.45301 14.0719 2.87512 13.8116 3.13547L13.1045 3.84257C12.8441 4.10292 12.422 4.10292 12.1617 3.84257C11.9013 3.58222 11.9013 3.16011 12.1617 2.89976L12.8688 2.19266Z'
        fill='currentColor'
      />
      <path
        d='M12.1617 8.19266C11.9013 8.45301 11.9013 8.87512 12.1617 9.13547L12.8688 9.84257C13.1291 10.1029 13.5512 10.1029 13.8116 9.84257C14.0719 9.58222 14.0719 9.16011 13.8116 8.89976L13.1045 8.19266C12.8441 7.93231 12.422 7.93231 12.1617 8.19266Z'
        fill='currentColor'
      />
      <path
        d='M6.16166 3.13547C5.90131 2.87512 5.90131 2.45301 6.16166 2.19266C6.42201 1.93231 6.84412 1.93231 7.10447 2.19266L7.81157 2.89976C8.07192 3.16011 8.07192 3.58222 7.81157 3.84257C7.55122 4.10292 7.12911 4.10292 6.86876 3.84257L6.16166 3.13547Z'
        fill='currentColor'
      />
      <path
        d='M14.6735 5.33073H13.6735C13.3053 5.33073 13.0068 5.62921 13.0068 5.9974C13.0068 6.36558 13.3053 6.66406 13.6735 6.66406H14.6735C15.0417 6.66406 15.3402 6.36559 15.3402 5.9974C15.3402 5.62921 15.0417 5.33073 14.6735 5.33073Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.58804 4.67203C8.85583 4.58502 9.14429 4.58502 9.41208 4.67203C9.60523 4.73478 9.75316 4.84117 9.86972 4.94012C9.97587 5.03023 10.0917 5.14611 10.2107 5.2651L10.7322 5.78667C10.8512 5.90563 10.9671 6.02149 11.0572 6.12764C11.1562 6.2442 11.2626 6.39213 11.3253 6.58527C11.4123 6.85306 11.4123 7.14153 11.3253 7.40932C11.2626 7.60246 11.1562 7.75039 11.0572 7.86695C10.9671 7.9731 10.8512 8.08895 10.7323 8.20791L4.54401 14.3962C4.42506 14.5151 4.30919 14.631 4.20305 14.7211C4.08649 14.8201 3.93856 14.9265 3.74542 14.9892C3.47763 15.0762 3.18916 15.0762 2.92137 14.9892C2.72823 14.9265 2.5803 14.8201 2.46374 14.7211C2.3576 14.631 2.24173 14.5151 2.12278 14.3962L1.60121 13.8746C1.48222 13.7556 1.36633 13.6398 1.27622 13.5336C1.17727 13.4171 1.07089 13.2691 1.00813 13.076C0.921118 12.8082 0.921118 12.5197 1.00813 12.2519C1.07089 12.0588 1.17727 11.9109 1.27622 11.7943C1.36633 11.6882 1.48221 11.5723 1.60119 11.4534L7.78944 5.2651C7.9084 5.14612 8.02426 5.03023 8.1304 4.94012C8.24697 4.84117 8.39489 4.73478 8.58804 4.67203ZM8.55969 8.22949C8.48351 8.20476 8.41744 8.13869 8.2853 8.00655L7.99081 7.71206C7.85866 7.57992 7.79259 7.51385 7.76787 7.43767C7.74612 7.37066 7.74617 7.29849 7.76799 7.23151C7.7928 7.15536 7.85895 7.08937 7.99124 6.95738L8.62294 6.32715C8.75496 6.19543 8.82097 6.12958 8.89704 6.10493C8.96396 6.08324 9.03601 6.08329 9.1029 6.10505C9.17895 6.12978 9.24488 6.19572 9.37674 6.32758L9.66977 6.62061C9.80164 6.75248 9.86757 6.81841 9.89231 6.89445C9.91407 6.96134 9.91411 7.0334 9.89243 7.10032C9.86778 7.17639 9.80192 7.2424 9.67021 7.37442L9.03998 8.00611C8.90799 8.13841 8.84199 8.20456 8.76585 8.22937C8.69886 8.25119 8.62669 8.25123 8.55969 8.22949Z'
        fill='currentColor'
      />
    </svg>
  );
};
