import { SettingCardRow } from '@outbound/design-system/src/components/organisms/ob-setting-card-row/setting-card-row';
import { FC } from 'react';

import { ObIcon } from '@outbound/design-system';

import { IconSystemKeys } from '@outbound/design-system/src/tokens/icons/icons';
import RequiresRole, { ROLE } from '../../utils/requires-role/requires-role';

interface SettingCardRowIconProps {
  /**
   * The name of the setting
   */
  title: string;
  /**
   * A text description of the value of the setting or the action of the action button
   */
  body: string;
  /**
   * Accessible label describing the button's functionality.
   * Such as 'Edit Workspace Name'
   */
  buttonAriaLabel?: string;
  /**
   * A callback function to be called when the button is clicked
   */
  handleButtonClickCallback: () => void;
  /**
   * Sets the component to a loading State.
   * Useful for when the data needed to populate the component is fetched asynchronously
   */
  loading?: boolean;

  /**
   * Sets the color of the card to a supported theme color.
   */
  color?: 'error';
  /**
   * An array of Application Roles who should be able to edit this setting.
   * In cases where the user does not have the role the edit button will not be displayed
   */
  rolesWhoCanEdit?: ROLE[];
  icon: IconSystemKeys;
}
export const SettingCardRowWithIcon: FC<SettingCardRowIconProps> = ({
  title,
  body,
  buttonAriaLabel,
  handleButtonClickCallback,
  loading = false,
  color,
  rolesWhoCanEdit = [ROLE.ANY],
  icon,
}) => {
  return (
    <>
      <div className='flex flex-row justify-start w-full'>
        <div className='flex flex-row'>
          <SettingCardRow
            title={title}
            body={body}
            loading={loading}
            color={color}
          />
          <div className='flex items-center self-start'>
            <RequiresRole roles={rolesWhoCanEdit}>
              <button
                disabled={loading}
                className='ml-2 p-[2px]'
                aria-label={buttonAriaLabel}
                onClick={handleButtonClickCallback}
              >
                <ObIcon
                  size='x-small'
                  icon={icon}
                  color='secondary'
                />
              </button>
            </RequiresRole>
          </div>
        </div>
      </div>
    </>
  );
};
