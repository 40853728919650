import { ServiceResource } from '@outbound/types';
import { FC, useEffect, useRef, useState } from 'react';
import { StateManagedByParentInput } from '../../../../base-component-props.type';
import { stringHasContent } from '../../../../utilities/string-utils';

import { BusinessConfigServiceSettings } from '../../business-config';
import { FieldDisplayModeProps } from '../../form-renderer-poc';
import { ObSelectedOptionGroup } from '../ob-selected-option-group/ob-selected-option-group';
import { ObSelectedOption } from '../ob-selected-option/ob-selected-option';
import { SectionLabel } from '../section-label/section-label';
import { WhatRadioGroup } from '../what-radio-group/what-radio-group';

export interface WhatSectionProps
  extends StateManagedByParentInput<string>,
    FieldDisplayModeProps,
    BusinessConfigServiceSettings {
  servicesFromPlaybook: Array<ServiceResource>;
  onAddNewServiceClickedCallback: () => void;
}

const somethingElseOptions = [
  {
    value: 'brand-awareness',
    label: 'Build Awareness of my Brand',
  },
];

const lookupLabelForOption = (
  value: string,
  services: Array<ServiceResource>
): string => {
  const [, whatId] = value.split('/');
  const somethingElse = somethingElseOptions.find(
    (option) => option.value === whatId
  )?.label;
  if (somethingElse) {
    return somethingElse;
  }

  const service = services?.find((service) => service.id === whatId);
  if (service) {
    return `Generate Leads for ${service.name}`;
  }

  return 'Unknown';
};

export const WhatSection: FC<WhatSectionProps> = ({
  value,
  onValueChangedCallback,
  displayMode = 'editable',
  onDisplayModeChangedCallback,
  servicesFromPlaybook = [],
  doesBusinessActivitySupportServices = true,
  onAddNewServiceClickedCallback,
}: WhatSectionProps) => {
  const firstTimeMode = useRef(!stringHasContent(value));
  const [whatLabel, setWhatLabel] = useState<string>(
    lookupLabelForOption(value, servicesFromPlaybook)
  );

  useEffect(() => {
    setWhatLabel(lookupLabelForOption(value, servicesFromPlaybook));
    if (stringHasContent(value) && firstTimeMode.current) {
      firstTimeMode.current = false;
      onDisplayModeChangedCallback('readonly');
    }
  }, [onDisplayModeChangedCallback, servicesFromPlaybook, value]);
  return (
    <div className='flex flex-col gap-3 self-stretch'>
      <SectionLabel
        label='Why Do You Want to Advertise?'
        id={'what'}
      />
      {displayMode === 'readonly' && (
        <ObSelectedOptionGroup
          onEditClickedCallback={() => onDisplayModeChangedCallback('editable')}
        >
          <ObSelectedOption
            id={'what'}
            value={whatLabel}
          />
        </ObSelectedOptionGroup>
      )}
      {displayMode === 'editable' && (
        <WhatRadioGroup
          id={'what'}
          doesBusinessActivitySupportServices={
            doesBusinessActivitySupportServices
          }
          hideConfirmButton={firstTimeMode.current}
          servicesFromPlaybook={servicesFromPlaybook}
          somethingElseOptions={somethingElseOptions}
          onConfirmWhatClickedCallback={() =>
            onDisplayModeChangedCallback('readonly')
          }
          onAddNewServiceClickedCallback={onAddNewServiceClickedCallback}
          value={value}
          onValueChangedCallback={onValueChangedCallback}
          isLoading={false}
        />
      )}
    </div>
  );
};
