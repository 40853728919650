import { SetupIntegrationStage } from '@outbound/design-system';
import { ServerDelta } from '@outbound/types';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from '../../../root-store';
import IntegrationConfiguration from '../integration-configuration';
import IntegrationConfigurationGoogleAdsValue from './integration-configuration-google-ads-value.type';

/**
 * The Integration Configuration for Google Ads extends the base Integration Configuration object.
 * It adds Google Ads Integration specific attributes to the configuration.
 *
 * The type of this configuration will always be 'GOOGLE_AD_MANAGER'.
 */
class IntegrationConfigGoogleAds extends IntegrationConfiguration {
  static readonly paths = {
    ...IntegrationConfiguration.paths,
    customerNumber: '/customerNumber',
    connectExistingAccount: '/connectExistingAccount',
    timeZone: '/timeZone',
    isGoogleAdsTestAccount: '/isGoogleAdsTestAccount',
    googleAdsAccountStatus: '/googleAdsAccountStatus',
    descriptiveName: '/descriptiveName',
    currencyCode: '/currencyCode',
  };

  /**
   * The customer number for the Google Ads account.
   * Undefined when the account is being created.
   */
  private _customerNumber?: string;

  private _connectExistingAccount?: boolean;

  private _timeZone?: string;
  private _isGoogleAdsTestAccount?: boolean;
  private _googleAdsAccountStatus?: string;
  private _descriptiveName?: string;
  private _currencyCode?: string;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    data: IntegrationConfigurationGoogleAdsValue
  ) {
    super(rootStore, id, obrn, { ...data, type: 'GOOGLE_AD_MANAGER' });
    this._customerNumber = data.customerId;
    this._connectExistingAccount = data.connectExistingAccount;
    this._timeZone = data.timeZone;
    this._isGoogleAdsTestAccount = data.isGoogleAdsTestAccount;
    this._googleAdsAccountStatus = data.googleAdsAccountStatus;
    this._descriptiveName = data.descriptiveName;
    this._currencyCode = data.currencyCode;
  }

  protected makeObservableInternal() {
    super.makeObservableInternal();
    makeObservable(this, {
      _customerNumber: observable,
      _connectExistingAccount: observable,
      _timeZone: observable,
      _isGoogleAdsTestAccount: observable,
      _googleAdsAccountStatus: observable,
      _descriptiveName: observable,
      _currencyCode: observable,
      calculateSetupFlowState: computed,
    } as any);
  }

  /**
   * Allow the model to update itself based on a patch from the server.
   */
  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      super.applyPatch(patch);

      for (const delta of patch) {
        switch (delta.path) {
          case IntegrationConfigGoogleAds.paths.customerNumber: {
            switch (delta.op) {
              case 'add':
                this._customerNumber = delta.value as string;
              case 'replace':
                console.error('Invalid Delta. Cannot replace customer id');
              case 'remove':
                console.error('Invalid Delta. Cannot remove customer id');
            }
            break;
          }
          case IntegrationConfigGoogleAds.paths.connectExistingAccount: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._connectExistingAccount = delta.value as boolean;
              case 'remove':
                console.error(
                  'Invalid Delta. Cannot remove connectExistingAccount'
                );
            }
            break;
          }
          case IntegrationConfigGoogleAds.paths.timeZone: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._timeZone = delta.value as string;
              case 'remove':
                console.error('Invalid Delta. Cannot remove timeZone');
            }
            break;
          }
          case IntegrationConfigGoogleAds.paths.isGoogleAdsTestAccount: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._isGoogleAdsTestAccount = delta.value as boolean;
              case 'remove':
                console.error(
                  'Invalid Delta. Cannot remove isGoogleAdsTestAccount'
                );
            }
            break;
          }
          case IntegrationConfigGoogleAds.paths.googleAdsAccountStatus: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._googleAdsAccountStatus = delta.value as string;
              case 'remove':
                console.error(
                  'Invalid Delta. Cannot remove googleAdsAccountStatus'
                );
            }
            break;
          }
          case IntegrationConfigGoogleAds.paths.descriptiveName: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._descriptiveName = delta.value as string;
              case 'remove':
                console.error('Invalid Delta. Cannot remove descriptiveName');
            }
            break;
          }
          case IntegrationConfigGoogleAds.paths.currencyCode: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._currencyCode = delta.value as string;
              case 'remove':
                console.error('Invalid Delta. Cannot remove currencyCode');
            }
            break;
          }
          default: {
            // NoOp We assume the base class handles the rest
          }
        }
      }
    });
  }

  toJson() {
    const baseJson = super.toJson();
    return {
      ...baseJson,
      [IntegrationConfigGoogleAds.paths.customerNumber]: this._customerNumber,
      [IntegrationConfigGoogleAds.paths.connectExistingAccount]:
        this._connectExistingAccount,
      [IntegrationConfigGoogleAds.paths.timeZone]: this._timeZone,
      [IntegrationConfigGoogleAds.paths.isGoogleAdsTestAccount]:
        this._isGoogleAdsTestAccount,
      [IntegrationConfigGoogleAds.paths.googleAdsAccountStatus]:
        this._googleAdsAccountStatus,
      [IntegrationConfigGoogleAds.paths.descriptiveName]: this._descriptiveName,
      [IntegrationConfigGoogleAds.paths.currencyCode]: this._currencyCode,
    };
  }

  /**
   * Frontend value which calculates where the integration is in the setup flow.
   */
  get calculateSetupFlowState(): SetupIntegrationStage {
    if (this.operationalStatus === 'NOMINAL') {
      return 'success';
    }
    if (this.operationalStatus === 'ERROR') {
      return 'error';
    }
    if (this.operationalStatus === 'NEEDS_ATTENTION') {
      //Check for specific health checks to see if we need to return check mail
      if (
        super.latestHealthCheckItemEvaluations.some(
          (item) =>
            item.healthCheck.id === 'ad8ec693-bfd9-41e8-b9fe-43aa3aaeca80' &&
            (item.outcome?.status === 'FAIL' ||
              item.outcome?.status === 'WARNING')
        )
      ) {
        return 'check-mail';
      } else {
        return 'success';
      }
    }
    return 'configuring';
  }

  get customerNumber() {
    return this._customerNumber;
  }

  get connectExistingAccount() {
    return this._connectExistingAccount;
  }

  get timeZone() {
    return this._timeZone;
  }

  get isGoogleAdsTestAccount() {
    return this._isGoogleAdsTestAccount;
  }

  get googleAdsAccountStatus() {
    return this._googleAdsAccountStatus;
  }

  get descriptiveName() {
    return this._descriptiveName;
  }

  get currencyCode() {
    return this._currencyCode;
  }
}

export default IntegrationConfigGoogleAds;
