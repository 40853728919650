import { CreativeResource, CreativeStatusType } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { ObAiGeneratingLoadingIndicator } from '../..';
import { ObIconAvatar } from '../../avatars';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObIcon } from '../../tokens/icons/ob-icon/ob-icon';
import { CardVariantDeprecatedProps } from '../card.type';
import { CreativeIssueBadge } from './components/creative-issue-badge/creative-issue-badge';

/**
 * Card props that represent calls to action that the card can take on the creative
 */
interface CreativeCardActionProps {
  /**
   * Callback called when the card wants to navigate to the creative it represents
   * @returns
   */
  onNavigateToPreviewCallback?: (id: string) => void;

  /**
   * Handle the Navigate to Editor CTA
   */
  onNavigateToEditorCallback?: (id: string) => void;

  /**
   * Callback called when the card wants to view a specific error
   * This CTA is contextual to when there are errors on the creative
   */
  onViewSpecificErrorCallback: (settingId: string) => void;
}

/**
 * Attributes of Creative that are featured on the Card
 */
interface CreativeCardAttributeProps
  extends Pick<CreativeResource, 'id' | 'name' | 'medium'> {
  template: Pick<CreativeResource['template'], 'id' | 'name'> & {
    renderComponent: string;
  };

  hideBorder?: boolean;

  lifecycleStatus: CreativeStatusType;
  /**
   * Indicates if the creative has been reviewed
   */
  isReviewed: boolean;
  /**
   * Indicates if the creative is valid and is currently publishable
   */
  isValid: boolean;
  /**
   * A list of validation errors that the creative has
   */
  validationErrors: Array<{
    settingId: string;
    settingName: string;
    errors: Array<string>;
  }>;
}

/**
 * Data we need to render regarding the creative itself.
 *  - This should be mobx state shape, not API shape.
 * A way to callback when we want to navigate to the creative
 * A way to determine the variant of the card we want to use
 */
export type CreativeCardProps = CardVariantDeprecatedProps &
  CreativeCardActionProps &
  CreativeCardAttributeProps;

export const CreativeCard = ({ variant, ...props }: CreativeCardProps) => {
  switch (variant) {
    case 'mini':
      return <MiniCard {...props} />;
    case 'micro':
    case 'grid':
    case 'list':
    case 'detail':
      return (
        <ObTypography color='negative'>
          Location <span>{variant}</span> Card Not Implemented
        </ObTypography>
      );
  }
};

/**
 * When slightly more space is available we will begin to expose more
 * details of the creative
 * @returns
 */
const MiniCard = ({
  id,
  name,

  template,
  isValid,
  isReviewed,
  validationErrors,
  onViewSpecificErrorCallback,
  lifecycleStatus,
  onNavigateToPreviewCallback,
  onNavigateToEditorCallback,
  hideBorder = false,
}: Omit<CreativeCardProps, 'variant'>) => {
  return (
    <div
      {...(onNavigateToPreviewCallback != null
        ? {
            tabIndex: 0,
            'aria-label': `View ${name}`,
            role: 'button',
            onClick: (event) => {
              if (event.metaKey) {
                onNavigateToEditorCallback?.(id);
              } else {
                onNavigateToPreviewCallback(id);
              }
            },
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                if (event.metaKey) {
                  onNavigateToEditorCallback?.(id);
                } else {
                  onNavigateToPreviewCallback(id);
                }
              }
            },
          }
        : {})}
      className={cx(
        'flex flex-row justify-between items-center gap-1  rounded p-1',
        'bg-dark/background/surface',
        hideBorder ? 'border-none' : 'border border-dark/border/default/normal',
        onNavigateToPreviewCallback != null
          ? 'hover:bg-dark/action/ghost/hover hover:dark:border-dark/action/primary-v2/hover focus-visible:shadow-interactive focus:outline-none cursor-pointer'
          : ''
      )}
    >
      <div className='flex flex-row justify-start items-center gap-2 '>
        {/* FUTURE WORK. Add an Icon for Each Medium */}
        <ObIconAvatar
          icon={'CreativeIcon'}
          color='purple'
          size='large'
        />

        <div className={cx('flex flex-col flex-start')}>
          <ObTypography
            className='line-clamp-1 text-left'
            variant='subtitle2'
            color='tertiary'
          >
            {template.name}
          </ObTypography>
          <ObTypography
            className='line-clamp-1 pb-[2px] text-left'
            variant='h4'
          >
            {name}
          </ObTypography>
        </div>
      </div>
      {lifecycleStatus === 'ACTIVE' && !isValid && (
        <CreativeIssueBadge
          variant='indicator'
          issues={validationErrors.map((error) => ({
            issueText: error.errors[0], //Take the first error as the most important
            settingLabel: error.settingName,
            onClickHandler: () => {
              onViewSpecificErrorCallback(error.settingId);
            },
          }))}
        />
      )}

      {lifecycleStatus === 'ACTIVE' && isValid && isReviewed && (
        <div className='border border-dark/border/default/normal  flex flex-row flex-nowrap  justify-start items-center gap-1 p-2 rounded-full'>
          <ObIcon
            icon='check'
            color='positive'
            size='x-small'
          />
        </div>
      )}

      {lifecycleStatus === 'PENDING_INITIALIZATION' && (
        <div className='flex items-center justify-center pr-1 '>
          <ObAiGeneratingLoadingIndicator
            variant={'spinner'}
            size={48}
          />
        </div>
      )}
    </div>
  );
};
