import { DisclosureButton } from '@headlessui/react';
import { ObButton, ObTypography } from '@outbound/design-system';
import { cx } from 'class-variance-authority';

export interface TemplateSettingsSectionHeaderProps {
  heading: string;
  onClickRemixSectionCallback: () => void;
  isDisclosureExpanded: boolean;
  onClickToggleDisclosureCallback?: () => void;
  /**
   * Indicates that the remix button should be disabled
   */
  remixIsDisabled: boolean;

  FF_enableRemixFeature?: boolean;
}

/**
 * This is a good candidate to potentially pull into the design system
 */
export const TemplateSettingsSectionHeader = ({
  heading,
  onClickRemixSectionCallback,
  isDisclosureExpanded,
  onClickToggleDisclosureCallback,
  remixIsDisabled,
  FF_enableRemixFeature,
}: TemplateSettingsSectionHeaderProps) => {
  return (
    <DisclosureButton
      as='div'
      role='button'
      tabIndex={-1}
      onClick={onClickToggleDisclosureCallback}
      className={
        'pt-4 pb-4 pl-4 pr-1 flex flex-row justify-between items-center w-full'
      }
    >
      <ObTypography
        variant='body1'
        className='active:text-contentSecondaryLight dark:active:text-contentSecondaryDark'
      >
        {heading}
      </ObTypography>

      <div className='flex flex-row items-center justify-between'>
        {/* 
        This div with a click handler is here because the entire header is a click handler for expanding and collapsing the section
        We need to catch clicks on the ObButton to prevent the section from expanding/collapsing
         */}
        {FF_enableRemixFeature ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {
              <ObButton
                variant='ghost'
                iconLeft='magicWand'
                disabled={remixIsDisabled}
                size='medium'
                onClick={() => {
                  if (onClickRemixSectionCallback) {
                    onClickRemixSectionCallback();
                  }
                }}
                label=' Remix'
              />
            }
          </div>
        ) : (
          <div></div>
        )}
        <DisclosureButton
          as={ObButton}
          iconLeft='chevronDown'
          buttonType='icon'
          variant='ghost'
          size='medium'
          label={
            isDisclosureExpanded
              ? `Hide ${heading} Section`
              : `Show ${heading} Section`
          }
          className={cx(
            'transition-transform duration-300 transform ease-in-out ',
            isDisclosureExpanded ? '' : '-rotate-90'
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (onClickToggleDisclosureCallback) {
              onClickToggleDisclosureCallback();
            }
          }}
        />
      </div>
    </DisclosureButton>
  );
};
