import { maskitoTransform } from '@maskito/core';
import { useMaskito } from '@maskito/react';
import { useEffect, useState } from 'react';
import {
  BaseExposedTextInputProps,
  StateManagedByParentInputValueOnly,
} from '../../../base-component-props.type';
import { ObInputControlled } from '../ob-input-controlled/ob-input-controlled';
import { moneyMask } from './mask-definition';

export interface ObInputMoneyProps
  extends StateManagedByParentInputValueOnly<number>,
    BaseExposedTextInputProps {}

/**
 * An OBInput used for Money Values
 *
 * Provides a mask so that the user can only enter in valid numbers and formats the value as a money value including a dollar sign, commas, and two decimal places.
 *
 * The value expected and returned by this input is a javascript number.
 *
 * This input is controlled by the parent component.
 * This component will notify the parent that the value has changed by calling the onValueChangedCallback function provided by the parent.
 *
 * @returns
 */
export const ObInputMoney = ({
  inputId,
  value,
  onValueChangedCallback,
  isLoading,
  isErrored,
  isDisabled,
  autoFocus,
  placeholder,
}: ObInputMoneyProps) => {
  /**
   * Handle the onFocus event for the input
   * From a UX perspective When the input is focused, we want to select all the text in the input so that the user can easily replace the value.
   * This is because with the mask if the cursor is to the left and there are two decimal places the user will have to delete the decimal places before they can enter a new value.
   *
   * This behavior is experimental and we should verify if this provides a better user experience.
   */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  };

  const inputRef = useMaskito({
    options: {
      ...moneyMask,
      overwriteMode: 'shift',
      preprocessors: [
        ({ elementState, data }) => {
          const { value, selection } = elementState;
          return {
            elementState: {
              selection,
              value: value,
            },
            data: data,
          };
        },
      ],
    },
  });

  /**
   * Side effect for when the parent updates the value
   * When the value changes, we need to update the internal state.
   * We need to transform the value into a string that can be displayed in the input and apply the mask.
   *
   * When no value is provided we will show an empty input (Internally the input has an empty string)
   */
  useEffect(() => {
    setInternalState(maskitoTransform(value?.toFixed(2) ?? '', moneyMask));
  }, [value]);
  const [internalState, setInternalState] = useState<string>(
    maskitoTransform(value?.toFixed(2) ?? '', moneyMask)
  );

  /**
   * Side effect of when the internal state changes, we need to notify the parent.
   * This should occur when the user types in the input.
   * If the value is different than the current value, we notify the parent of the change by providing the new value
   * formatted as a number.
   */
  useEffect(() => {
    const externalValue = parseFloat(internalState.replace(/[^0-9.]/g, ''));
    /**
     * Don't spam the parent with the same value.
     * Since we are managing an internal state and transforming values back and forth we need to be careful and
     * check if the external value is different from the internal state before notifying the parent.
     *
     * We don't want to trigger dirty form logic etc unnecessarily.
     */
    if (externalValue !== value) {
      onValueChangedCallback(isNaN(externalValue) ? 0 : externalValue);
    }
  }, [internalState, onValueChangedCallback, value]);

  return (
    <ObInputControlled
      inputId={inputId}
      isErrored={isErrored}
      isDisabled={isDisabled}
      autoFocus={autoFocus}
      placeholder={placeholder}
      ref={inputRef}
      value={internalState}
      onValueChangedCallback={setInternalState}
      isLoading={isLoading}
      onFocusCallback={handleFocus}
    />
  );
};
