import { StrategyResource } from '@outbound/types';
import { ReactNode, useState } from 'react';
import { StateDependsOnAsyncSourceProps } from '../../base-component-props.type';
import { ObCallout } from '../../components/elements/ob-callout/ob-callout';
import { ObInfoFlag } from '../../components/elements/ob-info-flag/ob-info-flag';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { IconSystemKeys } from '../../tokens/icons/icons';
import { formatCurrency } from '../../utilities/format-utilities';
import { ObBudgetSlider } from '../ob-budget-slider/ob-budget-slider';

const IndustryAvgBudgetLowFlag = ({
  industryBudgetLow,
  componentWidth,
  maxBudget,
}: {
  industryBudgetLow?: number;
  componentWidth: number;
  maxBudget: number;
}) => {
  return (
    <Flag
      value={industryBudgetLow}
      componentWidth={componentWidth}
      maxValue={maxBudget}
      icon='arrowDown'
      label='Industry Low'
    />
  );
};

const IndustryAvgBudgetHighFlag = ({
  industryBudgetHigh,
  componentWidth,
  maxBudget,
}: {
  industryBudgetHigh?: number;
  componentWidth: number;
  maxBudget: number;
}) => {
  return (
    <Flag
      value={industryBudgetHigh}
      componentWidth={componentWidth}
      maxValue={maxBudget}
      icon='arrowUp'
      label='Industry High'
    />
  );
};

const Flag = ({
  value,
  componentWidth,
  maxValue: maxValue,
  label,
  icon,
}: {
  value?: number;
  componentWidth: number;
  maxValue: number;
  label: string;
  icon: IconSystemKeys;
}) => {
  return (
    <div
      className='absolute transition-opacity duration-300 delay-100 opacity-0 top-[-72px]'
      style={{
        opacity: `${value ? 1 : 0}`,
        left: `${
          ((value ?? 0) / maxValue) * 100 - (27 / componentWidth) * 100
        }%`,
      }}
    >
      <ObInfoFlag
        icon={icon}
        heading={formatCurrency(value ?? 0, {
          roundToWholeNumber: true,
        })}
        body={label}
      />
    </div>
  );
};

const FlagRow = ({
  componentWidth,
  children,
}: {
  componentWidth: number;
  children: ReactNode;
}) => {
  return (
    <div
      style={{
        height: `${30}px`,
        width: `${componentWidth}px`,
      }}
    >
      <div className='flex flex-row relative w-full'>{children}</div>
    </div>
  );
};

const CurrentBudgetLabelMobile = ({ budgetValue }: { budgetValue: number }) => {
  return (
    <div className='mobile-budget-label sm:hidden'>
      <ObCallout>
        <ObTypography
          variant='h4'
          className='text-right select-none flex '
        >
          Daily Budget:{' '}
          {formatCurrency(budgetValue, {
            roundToWholeNumber: true,
          })}
        </ObTypography>
      </ObCallout>
    </div>
  );
};

const CurrentBudgetLabel = ({
  budgetValue,
  industryBudgetHigh,
  industryBudgetLow,
}: {
  budgetValue: number;
  industryBudgetHigh?: number;
  industryBudgetLow?: number;
}) => {
  return (
    <div className='label-content hidden sm:block '>
      <div className='h-[32px] flex flex-col justify-center items-end pb-2 -mt-8 min-w-[74px] mr-2'>
        <div
          className='opacity-0 transition-opacity duration-200'
          style={{
            opacity:
              industryBudgetHigh == null || industryBudgetLow == null ? 0 : 1,
          }}
        >
          <ObTypography
            variant='h2'
            className='text-right select-none'
          >
            {formatCurrency(budgetValue, {
              roundToWholeNumber: true,
            })}
          </ObTypography>
        </div>
        <div className='select-none'>
          <ObTypography variant='body3'>Daily Budget</ObTypography>
        </div>
      </div>
    </div>
  );
};

export interface ObBudgetGoalsSliderProps
  extends StateDependsOnAsyncSourceProps {
  /**
   * Indicates the high end of what the industry spend per day on advertising
   */
  industryBudgetHigh?: number;
  /**
   * Indicates the low end of what the industry spend per day on advertising
   */
  industryBudgetLow?: number;
  /**
   * Indicates how much money each goal unit costs
   * For example. On a conversion based goal, if a conversion is estimated to cost 5.23 than you should
   * pas 5.23 to this prop.
   */
  costPerGoalUnit: number;
  /**
   * Sets the value of 100% of the sliders.
   * For example if a max budget of 165 / day is set than when the slider is dragged to 100% than the daily
   * budget would be set to $165.
   */
  dailyMaxBudget: number;
  budgetValue: number;

  /**
   * Callback function to be called each time that the daily budget is updated
   */
  onBudgetUpdated?: (dailyBudget: number) => any;

  /**
   * The width of the slider in px
   */
  componentWidth: number;

  strategies: Array<StrategyResource>;
  activeStrategy?: StrategyResource;
}

export const ObBudgetGoalsSlider = ({
  industryBudgetHigh,
  industryBudgetLow,
  dailyMaxBudget,
  budgetValue,
  onBudgetUpdated,
  componentWidth,
  strategies,
  activeStrategy,
  isLoading,
}: ObBudgetGoalsSliderProps) => {
  const [maxBudget] = useState<number>(Math.round(dailyMaxBudget));

  const handleBudgetSliderUpdated = (updatedValue: number) => {
    if (updatedValue != budgetValue && onBudgetUpdated) {
      onBudgetUpdated(Math.round(updatedValue));
    }
  };

  const INDUSTRY_DATA_HEIGHT_PX = 80;
  const INDUSTRY_DATA_LINE_HEIGHT_PX = 8;

  return (
    /**
     * Due to the way we are absolutely positioning elements in this interactive slider
     * we are doing some manual margin and heights to maintain the layout
     * */
    <>
      <CurrentBudgetLabelMobile budgetValue={budgetValue} />
      <div
        style={{
          marginBottom: `${
            INDUSTRY_DATA_HEIGHT_PX - INDUSTRY_DATA_LINE_HEIGHT_PX
          }px`,
          marginTop: `${INDUSTRY_DATA_HEIGHT_PX}px`,
        }}
        className={`flex flex-col gap-40 px-[20px] lg:px-0`}
      >
        <section className='flex flex-row items-center sm:gap-4'>
          <CurrentBudgetLabel
            budgetValue={budgetValue}
            industryBudgetHigh={industryBudgetHigh}
            industryBudgetLow={industryBudgetLow}
          />
          <div className='slider-content relative top-[-30px] xl:left-[20px] w-full lg:w-auto'>
            <FlagRow componentWidth={componentWidth}>
              <IndustryAvgBudgetLowFlag
                industryBudgetLow={industryBudgetLow}
                componentWidth={componentWidth}
                maxBudget={maxBudget}
              />
              <IndustryAvgBudgetHighFlag
                industryBudgetHigh={industryBudgetHigh}
                componentWidth={componentWidth}
                maxBudget={maxBudget}
              />
            </FlagRow>
            <ObBudgetSlider
              componentWidth={componentWidth}
              value={budgetValue}
              initialMaxBudget={maxBudget}
              onChangeCallback={handleBudgetSliderUpdated}
              strategies={strategies}
              activeStrategy={activeStrategy}
              isLoading={isLoading}
            />
          </div>
        </section>
      </div>
    </>
  );
};
