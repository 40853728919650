import { useAuth0 } from '@auth0/auth0-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
export interface LaunchDarklyProviderProps {
  children: React.ReactNode;
}
/**
 * Launch Darkly is used to serve feature flags and what flags we want to serve is dependent on the user's identity
 * and their organization. This component will identify the user to Launch Darkly so that the correct flags are served.
 * @param param0
 * @returns
 */
export const LaunchDarklyIdentity = ({
  children,
}: LaunchDarklyProviderProps) => {
  const { isAuthenticated, user } = useAuth0();
  let ldClient = useLDClient();
  useEffect(() => {
    if (ldClient == null) return;

    /**
     * We are only targeting based on email address at the moment.
     * We will also want to setup targeting based on organization at some point
     */
    if (isAuthenticated && user) {
      ldClient.identify({
        key: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
      });
    } else {
      ldClient.identify({
        key: 'anonymous',
      });
    }
    //Fixed a typo, this is existing I don't have time to investigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, ldClient]);
  return <>{children}</>;
};
