import {
  findFirstEnabledIsTrueCustomerProfileSetting,
  getCustomerProfileIdFromCustomerProfileSettingId,
} from '@otbnd/utils';
import { CampaignResource } from '@outbound/types';
import { SyntheticCampaignCustomerProfileResource } from '../transformer/campaign-customer-profile-transformer';

export const createSyntheticCampaignCustomerProfileResourceFromCampaignResource =
  (
    campaignResource: CampaignResource
  ): SyntheticCampaignCustomerProfileResource | null => {
    const customerProfileSetting = findFirstEnabledIsTrueCustomerProfileSetting(
      campaignResource.campaignSettingValues
    );
    if (customerProfileSetting == null) {
      return null;
    }
    const customerProfileId = getCustomerProfileIdFromCustomerProfileSettingId(
      customerProfileSetting!.id
    );
    return {
      customerProfileId,
      workspaceId: campaignResource.workspaceId,
    };
  };
