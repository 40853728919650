import { makeObservable, observable } from 'mobx';
import { BaseModel } from '../../base-model';

import { ServerDelta } from '@outbound/types';
import { RootStore } from '../../root-store';

/**
 * Model represents a Business Context Customer Profile
 * Customer Profile is synonymous with a customer persona in the backend.
 * Business Context is Synonymous with the Playbook in the backend.
 */
class CustomerProfile extends BaseModel {
  private _role: string;
  private _description: string;

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    role: string,
    description: string
  ) {
    super(rootStore, 'customer-profile', '1', id, workspaceId);
    this._role = role;
    this._description = description;
  }

  get id(): string {
    return this._id;
  }

  get role(): string {
    return this._role;
  }

  get description(): string {
    return this._description;
  }

  protected makeObservableInternal() {
    makeObservable(this, {
      _role: observable,
      _description: observable,
    } as any);
  }

  applyPatch(_patch: ServerDelta[]): void {
    //Not Implemented
  }
  toJson(): Record<string, any> {
    return {
      id: this.id,
      role: this._role,
      description: this._description,
    };
  }
}

export default CustomerProfile;
