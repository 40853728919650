import { cva } from 'class-variance-authority';

const levelValues = [
  'DEFAULT',
  'INFO',
  'SUCCESS',
  'WARNING',
  'ERROR',
  'NEUTRAL',
] as const;

export type LevelType = (typeof levelValues)[number];

/**
 * Deprecated Use LevelType instead
 */
export enum Level {
  DEFAULT = 'DEFAULT',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export const ObColors = [
  'content',
  'primary',
  'primaryV2',
  'secondary',
  'tertiary',
  'positive',
  'informative',
  'negative',
  'warning',
  'lime',
  'cyan',
  'mint',
  'yellow',
  'teal',
  'cobalt',
  'inherit',
  'invertedPrimary',
] as const;

export type ObColorType = (typeof ObColors)[number];

/**
 * TextColors and BackgroundColors must be separate here and cannot be dynamically created
 * because Tailwind does not support JavaScript mixed with class names like `{$prefix}-light/content/primary`
 * where prefix would be 'text' or 'bg'
 */
export const TextColors = cva('textColors', {
  variants: {
    color: {
      content: [`dark:text-dark/content/primary`, `text-light/content/primary`],
      'text.secondary': [
        `dark:text-dark/text/content/secondary`,
        `text-dark/content/secondary`,
      ],
      primary: [
        `dark:text-dark/action/primary/normal`,
        `text-light/action/primary/normal`,
      ],
      invertedPrimary: [
        'dark:text-dark/content/inverted-primary',
        'text-light/content/inverted-primary',
      ],
      primaryV2: [`dark:text-dark/action/primary-v2/normal`],
      secondary: [
        `dark:text-dark/action/secondary/normal`,
        `text-light/action/secondary/normal`,
      ],
      tertiary: [
        `dark:text-dark/content/tertiary`,
        `text-light/content/tertiary`,
      ],
      positive: [
        `dark:text-dark/contextual/positive`,
        `text-light/contextual/positive`,
      ],
      informative: [
        `dark:text-dark/contextual/informative`,
        `text-light/contextual/informative`,
      ],
      negative: [
        `dark:text-dark/contextual/negative`,
        `text-light/contextual/negative`,
      ],
      warning: [
        `dark:text-dark/contextual/warning`,
        `text-light/contextual/warning`,
      ],
      lime: [
        `dark:text-dark/non-contextual/lime`,
        `text-light/non-contextual/lime`,
      ],
      cyan: [
        `dark:text-dark/non-contextual/cyan`,
        `text-light/non-contextual/cyan`,
      ],
      mint: [
        `dark:text-dark/non-contextual/mint`,
        `text-light/non-contextual/mint`,
      ],
      yellow: [
        `dark:text-dark/non-contextual/yellow`,
        `text-dark/non-contextual/yellow`,
      ],
      cobalt: [
        `dark:text-dark/non-contextual/cobalt`,
        `text-dark/non-contextual/cobalt`,
      ],
      teal: [`dark:text-dark/content/teal`, `text-light/content/teal`],
      inherit: [`text-inherit`],
    },
  },
});

export const BackgroundColors = cva('bgColors', {
  variants: {
    color: {
      content: [`dark:bg-dark/content/primary`, `bg-light/content/primary`],
      'text.secondary': [
        `dark:bg-dark/text/content/secondary`,
        `bg-dark/content/secondary`,
      ],
      primary: [
        `dark:bg-dark/action/primary/normal`,
        `bg-light/action/primary/normal`,
      ],
      invertedPrimary: [
        'dark:bg-dark/content/inverted-primary',
        'bg-light/content/inverted-primary',
      ],
      primaryV2: [`dark:bg-dark/action/primary-v2/normal`],
      secondary: [
        `dark:bg-dark/action/secondary/normal`,
        `bg-light/action/secondary/normal`,
      ],
      tertiary: [`dark:bg-dark/content/tertiary`, `bg-light/content/tertiary`],
      positive: [
        `dark:bg-dark/contextual/positive`,
        `bg-light/contextual/positive`,
      ],
      informative: [
        `dark:bg-dark/contextual/informative`,
        `bg-light/contextual/informative`,
      ],
      negative: [
        `dark:bg-dark/contextual/negative`,
        `bg-light/contextual/negative`,
      ],
      warning: [
        `dark:bg-dark/contextual/warning`,
        `bg-light/contextual/warning`,
      ],
      lime: [
        `dark:bg-dark/non-contextual/lime`,
        `bg-light/non-contextual/lime`,
      ],
      cyan: [
        `dark:bg-dark/non-contextual/cyan`,
        `bg-light/non-contextual/cyan`,
      ],
      mint: [
        `dark:bg-dark/non-contextual/mint`,
        `bg-light/non-contextual/mint`,
      ],
      yellow: [
        `dark:bg-dark/non-contextual/yellow`,
        `bg-dark/non-contextual/yellow`,
      ],
      cobalt: [
        `dark:bg-dark/non-contextual/cobalt`,
        `bg-dark/non-contextual/cobalt`,
      ],
      teal: [`dark:bg-dark/content/teal`, `bg-light/content/teal`],
      inherit: [`bg-inherit`],
    },
  },
});
