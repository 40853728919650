import { ObDivider, ObTypography } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../../state/mobx-experiment/use-root-store';

export const AccountProfile = observer(() => {
  const { authenticatedUser } = useRootStore();
  return (
    <div className='flex flex-col gap-2'>
      <ObTypography variant='h2'>User Profile</ObTypography>
      <ObTypography
        variant='body2'
        color='secondary'
      >
        Manage Your Outbound Profile
      </ObTypography>
      <div className='pt-6'></div>
      <div className='flex flex-col gap-4 border border-dark/border/default/normal rounded-lg p-4'>
        <div className='flex flex-row gap-2 justify-between items-center'>
          <ObTypography variant='body2'>Email</ObTypography>
          <ObTypography>{authenticatedUser?.email}</ObTypography>
        </div>
        <ObDivider />
        <div className='flex flex-row gap-2 justify-between items-center'>
          <ObTypography variant='body2'>Name</ObTypography>
          <ObTypography>{authenticatedUser?.fullName}</ObTypography>
        </div>
      </div>
    </div>
  );
});
