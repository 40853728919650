import { Fragment, ReactNode } from 'react';
import { LandingPageCardProps } from './landing-page-card';

export interface LandingPageListProps {
  landingPages: LandingPageCardProps[];
  cardRenderFunction: (cardProps: LandingPageCardProps) => ReactNode;
}

export const LandingPageList = ({
  landingPages,
  cardRenderFunction,
}: LandingPageListProps) => {
  return (
    <section className='h-full flex justify-center items-start w-full @container'>
      <div className='  grid grid-cols-1 @xl:grid-cols-2 @4xl:grid-cols-3 gap-8 w-full'>
        {landingPages.map((c) => {
          const props: LandingPageCardProps = {
            ...c,
            variant: 'grid',
          };
          return <Fragment key={c.obrn}>{cardRenderFunction(props)}</Fragment>;
        })}
      </div>
    </section>
  );
};
