import { RadioGroup } from '@headlessui/react';
import { PhysicalLocationResource, ServiceAreaResource } from '@outbound/types';
import { FC } from 'react';
import { StateManagedByParentInput } from '../../../../base-component-props.type';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';
import { ObCheckbox } from '../../../../components/elements/ob-checkbox/ob-checkbox';
import { BusinessConfigLocationSettings } from '../../business-config';
import { lookupDescriptionForServiceAreaType } from '../location-labels';
import { ObRadioOptionSubGroup } from '../ob-radio-option-subgroup/ob-radio-option-subgroup';

export interface WhereCheckboxGroupProps
  extends StateManagedByParentInput<Array<string>>,
    BusinessConfigLocationSettings {
  physicalLocationsFromPlaybook: Array<PhysicalLocationResource>;
  serviceAreasFromPlaybook: Array<ServiceAreaResource>;
  onConfirmWhatClickedCallback: () => void;
  onAddPhysicalLocationClickedCallback: () => void;
  onAddNewServiceAreaClickedCallback: () => void;
}

export const WhereCheckboxGroup: FC<WhereCheckboxGroupProps> = ({
  physicalLocationsFromPlaybook = [],
  serviceAreasFromPlaybook = [],
  onAddNewServiceAreaClickedCallback,
  onAddPhysicalLocationClickedCallback,
  onConfirmWhatClickedCallback,
  value,
  onValueChangedCallback,
  doesBusinessActivitySupportPhysicalLocations = true,
  doesBusinessActivitySupportServiceAreas = true,
}) => {
  return (
    <div className='flex flex-col gap-3'>
      <RadioGroup
        value={value}
        onChange={(value) => onValueChangedCallback(value, undefined)}
      >
        <div className='grid grid-cols-1 xs:grid-cols-2 justify-between gap-8'>
          {doesBusinessActivitySupportServiceAreas && (
            <ObRadioOptionSubGroup heading='Service Areas'>
              {serviceAreasFromPlaybook.map((serviceArea) => (
                <div
                  key={`service-area/${serviceArea.id}`}
                  className='rounded-lg dark:hover:bg-dark/action/neutral/hover dark:active:bg-dark/action/neutral/active px-2 py-1'
                >
                  <ObCheckbox
                    label={serviceArea.name}
                    description={lookupDescriptionForServiceAreaType(
                      serviceArea.serviceAreaType
                    )}
                    checked={
                      value.includes(`service-area/${serviceArea.id}`)
                        ? 'checked'
                        : 'unchecked'
                    }
                    onChange={(checked) => {
                      if (checked === 'checked') {
                        onValueChangedCallback(
                          [...value, `service-area/${serviceArea.id}`],
                          undefined
                        );
                      } else {
                        onValueChangedCallback(
                          value.filter(
                            (v) => v !== `service-area/${serviceArea.id}`
                          ),
                          undefined
                        );
                      }
                    }}
                  />
                </div>
              ))}
              <div className='pl-[2px]'>
                <ObButton
                  variant='ghost'
                  color='primary'
                  iconLeft='plus'
                  label='Other Service Area'
                  fullWidth='never'
                  onClick={onAddNewServiceAreaClickedCallback}
                />
              </div>
            </ObRadioOptionSubGroup>
          )}
          {doesBusinessActivitySupportPhysicalLocations && (
            <ObRadioOptionSubGroup heading='Physical Locations'>
              {physicalLocationsFromPlaybook.map((physicalLocation) => (
                <div
                  key={`physical-location/${physicalLocation.id}`}
                  className='rounded-lg dark:hover:bg-dark/action/neutral/hover dark:active:bg-dark/action/neutral/active px-2 py-1'
                >
                  <ObCheckbox
                    label={physicalLocation.name}
                    checked={
                      value.includes(`physical-location/${physicalLocation.id}`)
                        ? 'checked'
                        : 'unchecked'
                    }
                    description={physicalLocation.description}
                    onChange={(checked) => {
                      if (checked === 'checked') {
                        onValueChangedCallback(
                          [
                            ...value,
                            `physical-location/${physicalLocation.id}`,
                          ],
                          undefined
                        );
                      } else {
                        onValueChangedCallback(
                          value.filter(
                            (v) =>
                              v !== `physical-location/${physicalLocation.id}`
                          ),
                          undefined
                        );
                      }
                    }}
                  />
                </div>
              ))}
              <div className='pl-[2px]'>
                <ObButton
                  variant='ghost'
                  color='primary'
                  iconLeft='plus'
                  label='Other Location'
                  fullWidth='never'
                  onClick={onAddPhysicalLocationClickedCallback}
                />
              </div>
            </ObRadioOptionSubGroup>
          )}
        </div>
      </RadioGroup>
      <div className='pl-2'>
        <ObButton
          variant='outline'
          label='Confirm'
          size='medium'
          disabled={value.length === 0}
          onClick={onConfirmWhatClickedCallback}
        />
      </div>
    </div>
  );
};
