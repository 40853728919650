import { ListResponse, WorkspaceResource } from '@outbound/types';

export type MyTenantsQueryResultsData = {
  myTenants: {
    id: string;
    name: string;
    auth0OrganizationId: string;
  }[];
};

export const userIsNotAMemberOfAnyWorkspace = (
  myWorkspaces?: ListResponse<WorkspaceResource>
) => {
  return myWorkspaces?.items == null || myWorkspaces?.items?.length === 0;
};

export const userHasTenantMemberships = (
  myTenantQueryResultsData: MyTenantsQueryResultsData
) => {
  return (
    myTenantQueryResultsData?.myTenants != null &&
    myTenantQueryResultsData?.myTenants.length > 0
  );
};

export const userIsAMemberOfExactlyOneWorkspace = (
  myWorkspaces?: ListResponse<WorkspaceResource>
) => {
  return myWorkspaces?.items != null && myWorkspaces?.items.length === 1;
};
