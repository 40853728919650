import { Box, Typography } from '@mui/material';
import { LinkTermsOfService } from './link-customer-terms-of-service';
import { LinkPrivacyPolicy } from './link-privacy-policy';

export const LinkFooter = () => {
  return (
    <Box
      data-testid={'link-footer'}
      component={'ul'}
      sx={{
        textAlign: 'center',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        listStyle: 'none',
        whiteSpace: 'nowrap',
        flexWrap: 'wrap',
        fontSize: '.8em',
      }}
    >
      <Box
        component={'li'}
        sx={{ display: 'inline-block', marginRight: 0.5 }}
      >
        <LinkTermsOfService />
        <Typography
          fontSize={'inherit'}
          variant={'caption'}
        >
          {' |'}
        </Typography>
      </Box>
      <Box
        component={'li'}
        sx={{ display: 'inline-block', marginRight: 0.5 }}
      >
        <LinkPrivacyPolicy />
        <Typography
          fontSize={'inherit'}
          variant={'caption'}
        >
          {' | '}
        </Typography>
      </Box>
    </Box>
  );
};
