import { ObLoadingSpinner } from '@outbound/design-system';
import { useDev } from '../../hooks/use-dev';
import { BasePageLayout } from './base-page';

export const FullPageLoadingSpinner = ({ reason }: { reason?: string }) => {
  const { isDevEnvironment } = useDev();
  return (
    <BasePageLayout
      background='scrollable-gradient'
      contentPosition='center-center'
      fullScreen={true}
    >
      <div
        className='flex flex-col justify-center items-center'
        data-spin-reason={reason ?? 'unknown'}
      >
        <ObLoadingSpinner includeBrand={true} />
        {isDevEnvironment && (
          <div className='flex flex-col'>
            <h3 className='font-mono dark:text-dark/content/positive text-base'>
              DEV MODE
            </h3>
            <p className='font-mono dark:text-dark/content/positive text-sm'>
              SPINNER REASON: {reason ?? 'Not Provided'}
            </p>
          </div>
        )}
      </div>
    </BasePageLayout>
  );
};
