'use client';
import { getRefsById } from '../hooks/useCustomRefs';
import {
  CustomInputRefsProps,
  FormDefinition,
  FormFieldType,
} from '../ob-form-renderer.types';

interface VauleSetterFnProps {
  id: string;
  providedDefaultValues: any;
  customInputsRefs: CustomInputRefsProps;
}

const setLocalDateValues = ({
  providedDefaultValues,
  customInputsRefs,
  id,
}: VauleSetterFnProps) => {
  const { dateStartRef } = getRefsById(customInputsRefs, id);
  // Only auto-populate once
  if (!dateStartRef?.getValue()) {
    dateStartRef?.setValue(providedDefaultValues[id].value);
  }
};

const setLocalTimeValues = ({
  providedDefaultValues,
  customInputsRefs,
  id,
}: VauleSetterFnProps) => {
  const { timeStartRef } = getRefsById(customInputsRefs, id);
  // Only auto-populate once
  if (!timeStartRef?.getValue()) {
    timeStartRef?.setValue(providedDefaultValues[id].value);
  }
};

const setLocalTimestampValues = ({
  providedDefaultValues,
  customInputsRefs,
  id,
}: VauleSetterFnProps) => {
  const { dateStartRef, timeStartRef } = getRefsById(customInputsRefs, id);
  // Only auto-populate once
  if (!dateStartRef?.getValue() || !timeStartRef?.getValue()) {
    dateStartRef?.setValue(providedDefaultValues[id].datePart);
    timeStartRef?.setValue(providedDefaultValues[id].timePart);
  }
};

const setLocalDateRangeValues = ({
  providedDefaultValues,
  customInputsRefs,
  id,
}: VauleSetterFnProps) => {
  const { dateStartRef, dateEndRef } = getRefsById(customInputsRefs, id);
  // Only auto-populate once
  if (!dateStartRef?.getValue()) {
    dateStartRef?.setValue(providedDefaultValues[id].start?.value);
    dateEndRef?.setValue(providedDefaultValues[id].end?.value);
  }
};

const setLocalTimeRangeValues = ({
  providedDefaultValues,
  customInputsRefs,
  id,
}: VauleSetterFnProps) => {
  const { timeStartRef, timeEndRef } = getRefsById(customInputsRefs, id);
  // Only auto-populate once
  if (!timeStartRef?.getValue() || !timeEndRef?.getValue()) {
    timeStartRef?.setValue(providedDefaultValues[id].start?.value);
    timeEndRef?.setValue(providedDefaultValues[id].end?.value);
  }
};

const setTimestampRangeValues = ({
  providedDefaultValues,
  customInputsRefs,
  id,
}: VauleSetterFnProps) => {
  const { dateStartRef, dateEndRef, timeStartRef, timeEndRef } = getRefsById(
    customInputsRefs,
    id
  );

  // Only auto-populate once
  if (!dateStartRef?.getValue() || !dateEndRef?.getValue()) {
    dateStartRef?.setValue(providedDefaultValues[id].start?.datePart);
    dateEndRef?.setValue(providedDefaultValues[id].end?.datePart);
  }

  // Only auto-populate once
  if (!timeStartRef?.getValue() || !timeEndRef?.getValue()) {
    timeStartRef?.setValue(providedDefaultValues[id].start?.timePart);
    timeEndRef?.setValue(providedDefaultValues[id].end?.timePart);
  }
};

export const sanitizeInitialValues = (
  form: FormDefinition,
  providedDefaultValues: any,
  customInputsRefs: CustomInputRefsProps
) => {
  let sanitizedDefaultValues: any = {};
  let areCustomInputsRefsLoaded: number = Object.keys(customInputsRefs).length;

  form.sections.forEach((section) => {
    section.fields.forEach(({ id }) => {
      if (
        typeof providedDefaultValues[id] === 'object' &&
        areCustomInputsRefsLoaded
      ) {
        // Reset custom input field's parent value
        sanitizedDefaultValues[id] = '';

        // Populate custom input field values
        const vauleSetterFnArgs = {
          providedDefaultValues,
          customInputsRefs,
          id,
        };
        switch (providedDefaultValues[id].format) {
          case FormFieldType.LOCAL_DATE: {
            setLocalDateValues(vauleSetterFnArgs);
            return;
          }
          case FormFieldType.LOCAL_TIME: {
            setLocalTimeValues(vauleSetterFnArgs);
            return;
          }
          case FormFieldType.TIMESTAMP: {
            setLocalTimestampValues(vauleSetterFnArgs);
            return;
          }
          case FormFieldType.LOCAL_DATE_RANGE: {
            setLocalDateRangeValues(vauleSetterFnArgs);
            return;
          }
          case FormFieldType.LOCAL_TIME_RANGE: {
            setLocalTimeRangeValues(vauleSetterFnArgs);
            return;
          }
          case FormFieldType.TIMESTAMP_RANGE: {
            setTimestampRangeValues(vauleSetterFnArgs);
            return;
          }
          default: {
            sanitizedDefaultValues[id] = providedDefaultValues[id];
          }
        }
      } else {
        sanitizedDefaultValues[id] = providedDefaultValues[id];
      }
    });
  });
  return sanitizedDefaultValues;
};
