import { FC, useEffect, useState } from 'react';
import { ObProfile } from '@outbound/design-system';

interface CellUserProfileProps {
  /**
   * Individuals First Name; Provided when an individual's name is stored as separate strings for first and last name
   */
  givenName?: string;
  /**
   * Individuals Last Name; Provided when an individual's name is stored as separate strings for first and last name
   */
  familyName?: string;
  /**
   * May be provided in some cases where name is stored as a single string or when the profile represents an enity
   */
  name?: string;
  emailAddress?: string;
  profileImageUrl?: string;
}

const buildName = (
  givenName: string | undefined,
  familyName: string | undefined,
  name: string | undefined
) => {
  const givenNamePresent = givenName != null && givenName.trim().length > 0;
  const familyNamePresent = familyName != null && familyName.trim().length > 0;
  const namePresent = name != null && name.trim().length > 0;

  if (givenNamePresent && familyNamePresent) {
    return `${givenName.trim()} ${familyName.trim()}`;
  } else if (namePresent) {
    return name.trim();
  } else {
    return '';
  }
};

/**
 * Displays an ObProfile within the Grid System.
 * Useful for representing users in tables
 */
export const CellUserProfile: FC<CellUserProfileProps> = ({
  givenName,
  familyName,
  name,
  emailAddress,
  profileImageUrl,
}) => {
  const [computedName, setComputedName] = useState(
    buildName(givenName, familyName, name)
  );
  useEffect(() => {
    setComputedName(buildName(givenName, familyName, name));
  }, [givenName, familyName, name]);

  return (
    <div
      className='flex items-start justify-center flex-nowrap gap-1'
      data-testid='cell-user-profile'
    >
      <ObProfile
        variant='individual'
        avatarSize='small'
        title={computedName || emailAddress || 'Anonymous User'}
        avatarImageUrl={profileImageUrl}
        detail={
          computedName == null || computedName.trim() === ''
            ? 'Name Unknown'
            : emailAddress
        }
      />
    </div>
  );
};
