import {
  CreativeCard,
  CreativeCardProps,
  ObThinkingButton,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';

interface CreativeBuilderHeaderCallsToAction
  extends Pick<CreativeCardProps, 'onViewSpecificErrorCallback'> {
  /**
   * Navigate back to the previous page.
   * Since the Creative Builder is a Full Screen Experience we
   * want to provide a way for a user to go back to where they came from
   */
  onBackButtonCallback: () => any;
}

type CreativeBuilderHeaderAttributes = Pick<
  CreativeCardProps,
  | 'id'
  | 'lifecycleStatus'
  | 'isReviewed'
  | 'isValid'
  | 'name'
  | 'medium'
  | 'validationErrors'
  | 'template'
>;

export type CreativeBuilderHeaderProps = CreativeBuilderHeaderCallsToAction &
  CreativeBuilderHeaderAttributes;

export const CreativeBuilderHeader: React.FC<CreativeBuilderHeaderProps> =
  observer(
    ({
      id,
      lifecycleStatus,
      isReviewed,
      isValid,
      name,
      medium,
      validationErrors,
      template,
      onBackButtonCallback,
      onViewSpecificErrorCallback,
    }: CreativeBuilderHeaderProps) => {
      return (
        <header
          id='ob-creative-builder__header'
          className='absolute top-0 flex justify-between items-center w-full bg-bgSurfaceDark h-[64px] pl-3 pr-4 border-b border-borderDefaultNormalDark'
        >
          <div className='flex justify-center items-center gap-4'>
            <CreativeCard
              variant={'mini'}
              hideBorder={true}
              onViewSpecificErrorCallback={onViewSpecificErrorCallback}
              template={template}
              lifecycleStatus={lifecycleStatus}
              isReviewed={isReviewed}
              isValid={isValid}
              validationErrors={validationErrors.map((error) => ({
                settingName: error.settingName,
                settingId: error.settingId,
                errors: error.errors,
              }))}
              id={id}
              name={name}
              medium={medium}
            />
          </div>
          <div className='flex justify-center items-center gap-2'>
            <ObThinkingButton
              size='medium'
              label='Done'
              onClickCallback={onBackButtonCallback}
            />
          </div>
        </header>
      );
    }
  );
