import { datadogRum } from '@datadog/browser-rum';
import { FC, useState } from 'react';
import {
  OUTBOUND_API_BASE_URL,
  VITE_DATADOG_APPLICATION_ID,
  VITE_DATADOG_CLIENT_TOKEN,
  VITE_DATADOG_ENABLED,
  VITE_DATADOG_ENVIRONMENT,
  VITE_DATADOG_SERVICE,
  VITE_DATADOG_SITE,
} from '../environment';

export interface DataDogProps {
  children?: React.ReactNode;
}

export const DataDog: FC<DataDogProps> = ({ children }: DataDogProps) => {
  // Initialize the Datadog RUM client

  const [isDatadogRumReady, setDatadogRumReady] = useState<boolean>(false);

  datadogRum.onReady(() => {
    if (isDatadogRumReady) return;
    setDatadogRumReady(true);
  });

  if (VITE_DATADOG_ENABLED) {
    /**
     * Prevent multiple initializations of the Datadog RUM client
     */
    if (!isDatadogRumReady) {
      datadogRum.init({
        applicationId: VITE_DATADOG_APPLICATION_ID,
        clientToken: VITE_DATADOG_CLIENT_TOKEN,
        site: VITE_DATADOG_SITE,
        service: VITE_DATADOG_SERVICE,
        env: VITE_DATADOG_ENVIRONMENT,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [
          OUTBOUND_API_BASE_URL,
          (url) => url.startsWith(OUTBOUND_API_BASE_URL),
        ],
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['feature_flags'],
      });
    }
  }

  return <>{children}</>;
};
