'use client';
import { FC, useEffect, useMemo, useState } from 'react';
import { FileProps, ObInputFile, ObUploadFileList } from '../../..';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { formatBytes } from '../../../utilities/format-utilities';

type FileUploaded = {
  file: File;
  uploadProgress: number;
  errorMessage?: string;
  preSignedURL: string;
  id?: string;
};

export interface ObUploadFileProps {
  multipleFiles?: boolean;
  icon?: IconSystemKeys;
  description?: string;
  name: string;
  additionalDescription?: string;
  allowedExtensions?: string;
  maxFileSizeInKB?: number;
  disabled?: boolean;
  error?: boolean;
  getPreSignedURL: any;
  uploadContentUsingPreSignedURL: any;
  onChange: (files: FileUploaded[]) => void;
}

export const ObUploadFile: FC<ObUploadFileProps> = ({
  multipleFiles = true,
  icon,
  description,
  name,
  additionalDescription,
  allowedExtensions,
  maxFileSizeInKB,
  disabled,
  error,
  onChange,
  getPreSignedURL,
  uploadContentUsingPreSignedURL,
}) => {
  const [files, setFiles] = useState(new Array<File>());
  const [filesUploaded, setFilesUploaded] = useState(new Array<FileUploaded>());

  const fileList = useMemo(
    () =>
      filesUploaded.map(
        (file) =>
          ({
            name: file.file.name,
            size: formatBytes(file.file.size),
            state: file.errorMessage
              ? 'error'
              : file.uploadProgress < 100
              ? 'uploading'
              : 'complete',
            progress: file.uploadProgress,
            errorMessage: '',
          }) as const
      ),
    [filesUploaded]
  );

  const onChangeFiles = (updatedFiles: File[]) => {
    setFiles(updatedFiles);
  };

  useEffect(() => {
    const newFiles = files.filter(
      (x) => !filesUploaded.find((y) => y.file.name === x.name)
    );
    if (newFiles.length > 0) {
      Promise.all(newFiles.map((newFile) => uploadFile(newFile)));

      return;
    }

    const filesToRemove = filesUploaded.filter(
      (f) => !files.find((y) => y.name === f.file.name)
    );
    if (filesToRemove.length > 0) {
      const newFileList = filesUploaded.filter(
        (f) => !filesToRemove.includes(f)
      );
      setFilesUploaded(newFileList);
      onChange(newFileList);
    }
    //Existing Issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const onRemoveItem = (file: FileProps) => {
    const newFiles = files.filter((f) => f.name != file.name);
    setFiles(newFiles);
  };

  const uploadFile = async (file: File) => {
    const res = await getPreSignedURL(file.name);
    const presignedUrl = typeof res === 'object' ? res?.presignedUrl : res;
    const id = typeof res === 'object' && res?.id;

    if (presignedUrl)
      uploadContentUsingPreSignedURL(presignedUrl, file, onUploadProgress, id);
  };

  const onUploadProgress = (
    progressEvent: any,
    file: File,
    preSignedURL: string,
    id?: string
  ) => {
    const progress = Math.round(
      (100 * progressEvent.loaded) / progressEvent.total
    );
    setFilesUploaded((prev) => {
      const foundFileIndex = prev.findIndex((f) => f.file.name === file.name);
      if (foundFileIndex >= 0) {
        const newFilesUploaded = [...prev];
        newFilesUploaded[foundFileIndex] = {
          ...newFilesUploaded[foundFileIndex],
          uploadProgress: progress,
          id,
        };
        if (progress === 100) onChange(newFilesUploaded);
        return newFilesUploaded;
      }
      const fileUploaded = {
        file,
        uploadProgress: progress,
        preSignedURL,
        id,
      };
      onChange([...prev, fileUploaded]);
      return [...prev, fileUploaded];
    });
  };

  return (
    <div className='w-full max-w-full text-center relative'>
      <ObInputFile
        name={name}
        value={files}
        onChange={onChangeFiles}
        multipleFiles={multipleFiles}
        allowedExtensions={allowedExtensions}
        maxFileSizeInKB={maxFileSizeInKB}
        icon={icon}
        description={description}
        additionalDescription={additionalDescription}
        disabled={disabled}
        error={error}
      />

      <ObUploadFileList
        data-testid='file-upload-list'
        files={fileList}
        onRemoveItem={onRemoveItem}
      />
    </div>
  );
};
