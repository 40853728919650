import { ServerDelta } from '@outbound/types';
import { BaseModel } from '../../../base-model';
import SettingSubSection from './setting-sub-section';

class SettingSection extends BaseModel {
  private _name: string;
  private _subSections: Map<string, SettingSubSection> = new Map();

  constructor(
    rootStore: any,
    id: string,
    name: string,
    subSections: Array<SettingSubSection>
  ) {
    super(rootStore, 'creative-template/setting-section', '1', id, 'system');
    this._name = name;
    subSections.forEach((subSection) => {
      this._subSections.set(subSection.id, subSection);
    });
  }

  remix(): void {
    console.log('remix section');
  }

  toJson(): Record<string, any> {
    return {
      id: this.id,
      name: this._name,
    };
  }

  applyPatch(_patch: ServerDelta[]): void {
    //Read Only Model. No need to apply patch
  }

  protected makeObservableInternal(): void {
    //Read Only Model. No need to make observable
  }

  get name(): string {
    return this._name;
  }

  get subSections(): Array<SettingSubSection> {
    return Array.from(this._subSections.values());
  }
}

export default SettingSection;
