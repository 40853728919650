import { ObButton } from '@outbound/design-system';

export interface RemixFieldLockButtonProps {
  /**
   * Indicates if the field is currently locked for remixing purposes
   */
  isLocked: boolean;
  /**
   * Indicates if the button should be disabled
   */
  isDisabled: boolean;
  /**
   * Notify the parent the the user clicked the button.
   * The isLocked state should be modified by the parent in response to clicking this button.
   * Ths component expects to be controlled by the parent.
   */
  onClickLockRemixCallback: () => void;
}

/**
 * Small Lock Button shown next to fields in the Creative Builder Settings Panel
 */
export const RemixFieldLockButton = ({
  isLocked,
  isDisabled,
  onClickLockRemixCallback,
}: RemixFieldLockButtonProps) => {
  return (
    <ObButton
      className={
        isLocked
          ? 'dark:text-contentPrimaryDark'
          : 'dark:text-contentTertiaryDark'
      }
      variant='ghost'
      buttonType='icon'
      disabled={isDisabled}
      iconLeft={isLocked ? 'lock' : 'lockUnlocked'}
      size='small'
      onClick={onClickLockRemixCallback}
    />
  );
};
