type FormatType = 'integer' | 'percentage';

export const formatNumericValue = (value: number, format: FormatType) => {
  switch (format) {
    case 'integer':
      return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
    case 'percentage':
      return value.toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
      });
    default:
      return value;
  }
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Formats Numbers to Currency.
 * Defaults to United States Localization and US Dollars
 * but options can be used to format other locales or currencies.
 * @param amount
 * @param options
 * @returns
 */
export const formatCurrency = (
  amount: number,
  options?: { roundToWholeNumber?: boolean; locale?: string; currency?: string }
): string => {
  if (amount == null) {
    return '';
  }
  const roundToWholeNumber = options?.roundToWholeNumber ?? false;
  const currency = options?.currency ?? 'USD';
  const locale = options?.locale ?? 'en-US';
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: roundToWholeNumber ? 0 : 2,
    maximumFractionDigits: roundToWholeNumber ? 0 : 2,
  }).format(amount);
};
