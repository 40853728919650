'use client';
import { cva, cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObSkeleton } from '../../components/elements/ob-skeleton/ob-skelton';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { IconSize, ObIcon } from '../../tokens/icons/ob-icon/ob-icon';
import { ObAvatarShape, ObAvatarSize } from '../avatar.types';

export interface ObAvatarProps {
  /**
   * Fallback Text
   */
  fallbackText?: string;
  profileImageUrl?: string;
  /**
   * The size of the Avatar.
   * Small | Medium | Large
   */
  size: ObAvatarSize;
  /**
   * The shape to use. Currently, circles are for displaying users, squares are for all other use cases
   */
  shape?: ObAvatarShape;
  /**
   * Indicates that the component should display a loading state
   */
  loading?: boolean;
}

interface ObAvatarAttributes {
  height: number;
  width: number;
  typography:
    | 'subtitle2'
    | 'avatar1'
    | 'avatar2'
    | 'avatar3'
    | 'avatar4'
    | 'avatar5';
  iconSize: IconSize;
}

const getAttributesForAvatarSize = (size: ObAvatarSize): ObAvatarAttributes => {
  switch (size) {
    case 'xx-small':
      return {
        height: 20,
        width: 20,
        typography: 'avatar5',
        iconSize: 'xx-small',
      };
    case 'x-small':
      return {
        height: 24,
        width: 24,
        typography: 'subtitle2',
        iconSize: 'x-small',
      };
    case 'small':
      return {
        height: 32,
        width: 32,
        typography: 'avatar1',
        iconSize: 'small',
      };
    case 'medium':
      return {
        height: 40,
        width: 40,
        typography: 'avatar2',
        iconSize: 'small',
      };
    case 'large':
      return {
        height: 48,
        width: 48,
        typography: 'avatar3',
        iconSize: 'medium',
      };
    case 'x-large':
      return {
        height: 96,
        width: 96,
        typography: 'avatar4',
        iconSize: 'large',
      };
    case 'xx-large':
      return {
        height: 128,
        width: 128,
        typography: 'avatar5',
        iconSize: 'x-large',
      };
    default:
      return {
        height: 40,
        width: 40,
        typography: 'avatar2',
        iconSize: 'x-large',
      };
  }
};

const buildFallbackText = (name: string) => {
  if (name == null || name.trim().length === 0) {
    return undefined;
  }
  /*
  For Accessibility, we can default to one letter so font size does not need to be too small
   */
  // if (size === 'x-small') {
  //   return name.trim()[0].toUpperCase();
  // } else {
  const nameParts = name.trim().toUpperCase().split(' ');
  return nameParts.length > 1
    ? `${nameParts[0][0]}${nameParts[1][0]}`
    : nameParts[0][0];
  // }
};

export const ObAvatar: FC<ObAvatarProps> = ({
  profileImageUrl,
  size = 'medium',
  fallbackText = '',
  shape = 'circle',
  loading = false,
}: ObAvatarProps) => {
  const sizeStyles = {
    'xx-small': [`h-[20px]`, 'w-[20px]'],
    'x-small': [`h-[24px]`, 'w-[24px]'],
    small: [`h-[32px]`, 'w-[32px]'],
    medium: ['h-[40px]', 'w-[40px]'],
    large: ['h-[48px]', 'w-[48px]'],
    'x-large': ['h-[96px]', 'w-[96px]'],
    'xx-large': ['h-[128px]', 'w-[128px]'],
  };
  const avatarImageStyles = cva('', {
    variants: {
      size: sizeStyles,
    },
  });
  const avatarContainerStyles = cva(
    'ob-avatar bg-bgSurface2Dark text-contentPrimaryDark',
    {
      variants: {
        imageProvided: {
          true: ['bg-transparent'],
          false: ['bg-bgSurface2Dark'],
        },
        shape: {
          circle: [
            /* General Circle Styling  */
            'rounded-full',
          ],
          rounded: [
            /* General Rounded Styling  */
            'rounded',
          ],
        },
        size: sizeStyles,
      },
      defaultVariants: {
        shape: 'circle',
        size: 'small',
        imageProvided: false,
      },
    }
  );

  const attributes = getAttributesForAvatarSize(size);

  return loading ? (
    <ObSkeleton
      variant={shape === 'circle' ? 'circle' : 'rounded'}
      height={`${getAttributesForAvatarSize(size).height}px`}
      width={`${getAttributesForAvatarSize(size).width}px`}
    />
  ) : (
    <div
      className={cx(
        ' text-contentPrimaryDark flex flex-shrink-0 justify-center items-center overflow-hidden',
        avatarContainerStyles({
          shape: shape,
          size: size,
          imageProvided: !!profileImageUrl,
        })
      )}
      data-testid={'user-avatar'}
    >
      {/* Prefer Images over Text */}
      {profileImageUrl && (
        <img
          className={cx('object-cover', avatarImageStyles({ size: size }))}
          height={attributes.height}
          width={attributes.width}
          src={profileImageUrl}
          alt={fallbackText}
        ></img>
      )}
      {/* Fallback to Text When no Image is available but fallback text is provided */}
      {!profileImageUrl && fallbackText && (
        <ObTypography
          variant={attributes.typography}
          color='primary'
        >
          {buildFallbackText(fallbackText)}
        </ObTypography>
      )}
      {/* Fallback to Icon  When no Image is available and no fallback text is provided */}
      {!profileImageUrl && (!fallbackText || fallbackText.trim() === '') && (
        <ObIcon
          icon='profile'
          size={attributes.iconSize}
        />
      )}
    </div>
  );
};
