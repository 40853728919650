import { RootStore } from '../../root-store';
import LandingPage, { LandingPageConstructorData } from '../landing-page';

//FUTURE USE
// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
export interface LandingPageSelfHostedConstructorData
  extends Omit<LandingPageConstructorData, 'type'> {
  //FUTURE USE
}

/**
 * MobX Model Representing a Landing Page with the Self Hosted Type.
 * These are landing pages that are not hosted by Outbound and are
 * the customers responsibility to maintain and optimize.
 *
 * The webpage itself is readonly from an Outbound Perspective.
 * There may be limited metadata that Outbound may store that is user editable
 * such as "featured sections"
 */
class LandingPageSelfHosted extends LandingPage {
  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    data: LandingPageSelfHostedConstructorData
  ) {
    super(rootStore, id, obrn, {
      ...data,
      type: 'SELF_HOSTED',
    });
  }
}

export default LandingPageSelfHosted;
