import { AssetResource, RuleBuilderSchema } from '@outbound/types';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { IFileUploadHandlerFactory } from '../../../utilities/file-upload';
import { CheckboxGroupCardOption } from '../../molecules/ob-card-option-checkbox-group/ob-card-option-checkbox-group.types';
import { ObRadioOption } from '../../molecules/ob-radio-group/ob-radio-group';
import { ObColorFieldColor } from '../ob-colors-field/ob-colors-field';
import { ObImageFieldImageGroup } from '../ob-images-field/ob-images-field';

export type FormDefinition = {
  id: string;
  fullWidthSubmitButton?: boolean;
  submitButtonLabel?: string;
  heading: string;
  subHeading: string;
  sections: Array<FormSectionDefinition>;
};

export type FormSectionDefinition = {
  id: string;
  heading: string;
  subHeading: string;
  fields: Array<FormFieldDefinition>;
};

export type FormFieldDefinition = {
  id: string;
  label: string;
  helperText: string;
  type: FormFieldType;
  validationSchema: FormFieldValidation;
  hideLabel?: boolean;
  hideHelperText?: boolean;
  autofocus?: boolean;
  readOnly?: boolean;
  /**
   * Optional Default Value for the field.
   * If the no value is provided for the field in initial
   * values than this value will be used to populate the initial value.
   *
   * For fields using BaseFormFieldOption as options only the key of the option
   * needs to be provided.
   */
  defaultValue?: any;
  fieldTypeSettings: FormFieldTypeSettingsSchema<FormFieldType>;
  disablePasswordManagers?: boolean;
  size?: 'small' | 'medium' | 'large';
  /**
   * Conditionally hide the field based on the values of other fields
   * Will be passed the values of the form and should return a boolean.
   */
  conditionallyHideFieldFunction?: (values: any) => boolean;
};

/**
 * Basic version of a form field option.
 * These properties are the properties that are needed to basic state management and validation
 * that can be generic. Components that want to display more sophisticated UI with additional headings, icons or bodies
 * can extend this Base type
 */
export type BaseFormFieldOption = {
  /**
   * A unique identifier for the field
   */
  key: string | number;
  /**
   * The value to be returned by the field when the option is selected.
   * This is intentionally set to any so we can accept any object which is helpful
   * to not need to do key lookups on selected values
   */
  value: any;
  /**
   * The label that should be displayed to the user for the option in the UI
   */
  displayValue?: string;
  /**
   * Indicates if the option is disabled in the UI or not. Assumes false if not specified
   */
  disabled?: boolean;

  /**
   * Arbitrary data that can be attached to the option other than the value.
   * Typically used for additional data that is needed for the UI that is not the value.
   *
   * Example use case:
   * value: "assetIdUUID"
   * data: { assetId: "assetIdUUID", "publicUrl": "https://www.example.com/image.jpg" }: AssetResource
   *
   * From a state management perspective, the value is the unique identifier for the option
   * but we want to show the user an image downloaded from the publicUrl in the UI
   */
  data?: any;
};

/**
 * Useful when we know that the key of the option is always a string
 */
export type FormFieldOptionWithStringKey = Omit<BaseFormFieldOption, 'key'> & {
  key: string;
};

export type FormFieldValidation = {
  isRequired?: boolean;
  isRequiredMessage?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  in?: Array<any>;
  passwordMatchFieldID?: string;
};

export enum FormFieldType {
  PHONE = 'PHONE',
  ADDRESS = 'ADDRESS',
  COMBO_BOX = 'COMBO_BOX',
  RADIO = 'RADIO',
  CHIPS = 'CHIPS',
  TEXT = 'TEXT',
  CURRENCY = 'CURRENCY',
  EMAIL = 'EMAIL',
  NUMBER = 'NUMBER',
  TEXT_AREA = 'TEXT_AREA',
  TEXT_LIST_EMAIL = 'TEXT_LIST_EMAIL',
  TOGGLE_SWITCH = 'TOGGLE_SWITCH',
  SELECT = 'SELECT',
  PASSWORD = 'PASSWORD',
  IRONCLAD_CLICKWRAP = 'IRONCLAD_CLICKWRAP',
  FILE_UPLOAD = 'FILE_UPLOAD',
  OPTION_CARDS = 'OPTION_CARDS',
  BUTTON_GROUP_RADIO = 'BUTTON_GROUP_RADIO',
  // TIME AND DATE
  LOCAL_DATE = 'LOCAL_DATE',
  LOCAL_TIME = 'LOCAL_TIME',
  TIMESTAMP = 'TIMESTAMP',
  LOCAL_DATE_RANGE = 'LOCAL_DATE_RANGE',
  LOCAL_TIME_RANGE = 'LOCAL_TIME_RANGE',
  TIMESTAMP_RANGE = 'TIMESTAMP_RANGE',
  CHECKBOX_GROUP_CARDS = 'CHECKBOX_GROUP_CARDS',
  RULE_BUILDER = 'RULE_BUILDER',
  COLORS = 'COLORS',
  IMAGES = 'IMAGES',
  PHOTO_TILES = 'PHOTO_TILES',
}

type BaseFormFieldTypeSettingsSchema = {};

export type FormFieldTypeSettingsSchemaMap = {
  [FormFieldType.TEXT]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.CURRENCY]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.EMAIL]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.NUMBER]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.TEXT_AREA]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.TEXT_LIST_EMAIL]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.TOGGLE_SWITCH]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.PASSWORD]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.RADIO]: RadioFieldTypeSettingsSchema;
  // SPECIAL FIELDS
  [FormFieldType.SELECT]: SelectFieldTypeSettingsSchema;
  [FormFieldType.BUTTON_GROUP_RADIO]: ButtonGroupRadioFieldTypeSettingsSchema;
  [FormFieldType.FILE_UPLOAD]: FileUploadFormFieldTypeSettingsSchema;
  [FormFieldType.IRONCLAD_CLICKWRAP]: IronCladFormFieldTypeSettingsSchema;
  [FormFieldType.OPTION_CARDS]: IronCladFormFieldTypeSettingsSchema;
  [FormFieldType.CHECKBOX_GROUP_CARDS]: CheckboxGroupSettingsSchema;
  // TIME AND DATE
  [FormFieldType.LOCAL_DATE]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.LOCAL_TIME]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.TIMESTAMP]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.LOCAL_DATE_RANGE]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.LOCAL_TIME_RANGE]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.TIMESTAMP_RANGE]: BaseFormFieldTypeSettingsSchema;

  [FormFieldType.ADDRESS]: AddressFieldTypeSettingsSchema;
  [FormFieldType.PHONE]: PhoneFieldTypeSettingsSchema;
  [FormFieldType.COMBO_BOX]: ComboBoxFieldTypeSettingsSchema;
  [FormFieldType.CHIPS]: BaseFormFieldTypeSettingsSchema;
  [FormFieldType.RULE_BUILDER]: RuleBuilderTypeSettingSchema;
  [FormFieldType.COLORS]: ColorsFieldTypeSettingsSchema;
  [FormFieldType.IMAGES]: ImagesFieldTypeSettingsSchema;
  [FormFieldType.PHOTO_TILES]: PhotoTilesTypeSettingsSchema;
};

/**
 * Deprecated. This should not be used going forward.
 * Use BaseFormFieldOption and extend if needed for your specific field type
 */
export interface Option {
  title: string;
  value: any;
  description?: string;
  icon?: IconSystemKeys;
  image?: string;
  disabled?: boolean;
}

export type FormFieldTypeSettingsSchema<T extends FormFieldType> =
  BaseFormFieldTypeSettingsSchema & FormFieldTypeSettingsSchemaMap[T];

export interface FileUploadFormFieldTypeSettingsSchema
  extends BaseFormFieldTypeSettingsSchema {
  additionalDescription?: string;
  description?: string;
  icon?: IconSystemKeys;
  multipleFiles: boolean;
  allowedExtensions?: string;
  maxFileSizeInKB?: number;
  getPreSignedURL?: any;
  uploadContentUsingPreSignedURL?: any;
}

export interface IronCladFormFieldTypeSettingsSchema
  extends BaseFormFieldTypeSettingsSchema {
  text: string;
  href: string;
  hrefText: string;
}

export interface ButtonGroupRadioFieldTypeSettingsSchema
  extends BaseFormFieldTypeSettingsSchema {
  options: BaseFormFieldOption[];
}

export interface RadioFieldTypeSettingsSchema
  extends BaseFormFieldTypeSettingsSchema {
  options: Array<ObRadioOption>;
}

export interface CustomInputRefsProps {
  dateStartRef?: any;
  timeStartRef?: any;
  dateEndRef?: any;
  timeEndRef?: any;
  ruleBuilderRef?: any;
}

export interface CheckboxGroupSettingsSchema {
  options: Array<CheckboxGroupCardOption>;
}

export interface SelectFieldTypeSettingsSchema {
  options: BaseFormFieldOption[];
}

export interface AddressFieldTypeSettingsSchema {
  showCountry?: boolean;
}

export interface PhoneFieldTypeSettingsSchema {
  showCountryCode?: boolean;
}

export interface ComboBoxFieldTypeSettingsSchema {
  options: BaseFormFieldOption[];
  properCaseDisplayValues?: boolean;
  /**
   * Controls if the combobox allows for multiple selections or not
   * The default is true.
   */
  allowMultiple?: boolean;
}

export interface RuleBuilderTypeSettingSchema {
  ruleBuilderSchema: RuleBuilderSchema;
  allowAdvancedMode?: boolean;
}

export interface ColorsFieldTypeSettingsSchema {
  colorsToSelect: Array<ObColorFieldColor>;
}

export interface ImagesFieldTypeSettingsSchema {
  /**
   * Configures the image slots that are expected on the form
   */
  imagesToSelect: Array<ObImageFieldImageGroup>;
  /**
   * A factory to create a file upload handler for the images field.
   * This allows us to upload images to different locations based on the context of the form.
   */
  fileUploadHandlerFactory: IFileUploadHandlerFactory;
}

export interface PhotoTilesTypeSettingsSchema {
  onAssetDetailsClick: (
    assetId: string,
    onAssetUpdatedCallback?: (updatedAsset: Partial<AssetResource>) => void
  ) => Promise<any>;
  fileUploadHandlerFactory: IFileUploadHandlerFactory;
}
