import {
  CreatePersonaResourceRequest,
  PatchPersonaResourceRequest,
} from '@outbound/types';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import { playbookSettingsKeys } from './playbook-query-key';

/**
 * Creates a new service area
 * @returns
 */
export const useCreatePersona = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: CreatePersonaResourceRequest }
  >(
    ['playbook:settings:persona:post'],
    async ({ values }) => {
      const response = await auth0AxiosClient.post(
        '/playbook/settings/persona',
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const usePatchPersonaById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: PatchPersonaResourceRequest }
  >(
    ['playbook:settings:persona', id, 'patch'],
    async ({ values }) => {
      if (id == null) {
        throw new Error(
          'Cannot Update Persona. A Persona id must be provided.'
        );
      }
      const response = await auth0AxiosClient.patch(
        'playbook/settings/persona/' + id,
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useDeletePersonaById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation(
    ['playbook:settings:persona', id, 'delete'],
    async () => {
      if (id == null) {
        throw new Error(
          'Cannot delete persona. A persona id must be provided.'
        );
      }
      const response = await auth0AxiosClient.delete(
        'playbook/settings/persona/' + id
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};
