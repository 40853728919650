import { AssetResource, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../base-transformer';

import { RootStore } from '../root-store';
import { simulatePatch } from '../sync-framework/patch-simulator/patch-simulator';
import Asset from './asset';
import ImageAssetTransformer from './image-asset-transformer';

class AssetTransformer extends BaseTransformer<AssetResource, Asset> {
  private _imageAssetTransformer: ImageAssetTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this._imageAssetTransformer = new ImageAssetTransformer(rootStore);
  }

  fromApiResource(resource: AssetResource): Asset {
    switch (resource.classification) {
      case 'GENERAL':
      case 'LOGO':
        return this._imageAssetTransformer.fromApiResource(resource);
      case 'MAP':
        return this._imageAssetTransformer.fromApiResource(resource);
      default:
        throw new Error(
          `Unsupported asset classification: ${resource.classification}`
        );
    }
  }
  createPatchForCurrentModelAndIncomingResource(
    currentModel: Asset,
    resource: AssetResource
  ): ServerDelta[] {
    if (currentModel.id !== resource.id) {
      throw new Error('Model and Resource ID Mismatch');
    }

    //Convert Incoming Resource to Model
    const incomingModel = this.fromApiResource(resource);

    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(), //This is a simulated server timestamp
      this._rootStore.clientId //This makes it seem like the update is coming from this client which is not true. A quick fix to satisfy the contact. Need to review of the implications of this and if it matters for now.
    );
  }
}

export default AssetTransformer;
