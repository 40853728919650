import { InitializingSelfHosted } from '@outbound/design-system';
import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import obProfileIcon from '../../../assets/ob-profile-icon.png';
import { FullPageLoadingSpinner } from '../../../components/layouts/full-page-loading-spinner';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import LandingPage from '../../../state/mobx-experiment/landing-page/landing-page';
import { useRootStore } from '../../../state/mobx-experiment/use-root-store';
import Workspace from '../../../state/mobx-experiment/workspace/workspace';
import { ErrorFallback } from '../campaigns/components/error-fallback';
import { FullPageErrorMessage } from '../campaigns/components/full-page-error-message';
import { FullPageSecondaryText } from '../campaigns/components/full-page-secondary-text-message';
import { RouteParams } from './landing-page-route-param.type';

/**
 * Wrapper / Loading Route for Landing Page Detail.
 * Deals with Errors / Suspense / Loading States.
 * This is an intermediate route that is mapped to the "landingPageId"
 * of the path and will automatically redirect to the "details" route
 * and the corresponding component.
 * @returns
 */
export const LandingPageDetailPage = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense
        fallback={
          <FullPageLoadingSpinner reason='campaign-detail-suspense-boundary' />
        }
      >
        <LandingPageWorkspaceCheck />
      </Suspense>
    </ErrorBoundary>
  );
};

const LandingPageWorkspaceCheck = () => {
  const { workspaceSlug, landingPageId } = useParams<RouteParams>();

  const { landingPageStore, workspaceStore } = useRootStore();
  const { navigateToWorkspacePicker } = useAppNavigation();

  const workspace = workspaceStore.getById(workspaceSlug!);
  const campaign = landingPageStore.getById(landingPageId!);

  // Candidate to be refactored into a common component
  if (workspace == null) {
    return (
      <FullPageErrorMessage
        message={`We were unable to locate the workspace with the ID ${workspaceSlug}. Please check your URL`}
        heading={'Workspace Not Found'}
        actionLabel={'Select Workspace'}
        actionOnClick={() => {
          navigateToWorkspacePicker();
        }}
      />
    );
  }

  return (
    <LandingPageLifecycleStatusHandler
      landingPage={campaign}
      workspace={workspace}
    />
  );
};

/**
 * Handles the various lifecycle statuses of the Landing Page at a high level so lower level components
 * are free from this concern.
 * It is important to realize that this component will not show if the Landing Page is being lazy loaded.
 * This will default back to the Suspense Boundary above.
 * @returns
 */
const LandingPageLifecycleStatusHandler = observer(
  ({
    landingPage,
  }: {
    landingPage: LandingPage | null;
    workspace: Workspace;
  }) => {
    const navigate = useNavigate();
    if (landingPage == null) {
      return (
        <FullPageErrorMessage
          heading='Landing Page does not exist'
          actionLabel='Back'
          actionOnClick={() => window.history.back()}
          message='The Landing Page you are trying to access does not exist. Please check your link and ensure you are logged in with the correct account.'
        />
      );
    }

    /**
     * We may remove this in favor of showing the placeholder page I need to see what the actual UX feels like
     */
    if (landingPage.lifecycleStatus === 'INITIALIZING') {
      return (
        <>
          <ObLink
            variant={'primary'}
            label='All Landing Pages'
            iconLeft='chevronLeft'
            iconSize='small'
            onClick={() => navigate('../..')} //Go Up One Level
          />
          <InitializingSelfHosted
            lifecycleStatus={landingPage.lifecycleStatus}
            url={landingPage.url}
            agencyName={'Outbound'}
            agencyIconUrl={obProfileIcon}
          />
        </>
      );
    }

    if (landingPage.lifecycleStatus === 'INITIALIZATION_FAILED') {
      return (
        <FullPageErrorMessage
          heading='Failed to Create Landing Page'
          actionLabel='Back'
          actionOnClick={() => window.history.back()}
          message='Something when wrong when we were trying to setup this Landing Page. Our team has been notified and we apologize for the inconvenience. If the issue persists please contact us.'
        />
      );
    }

    if (landingPage.lifecycleStatus === 'ARCHIVED') {
      return <FullPageSecondaryText message='Landing Page has been Archived' />;
    }

    return (
      <div className=' w-full m-auto max-w-3xl pt-10 gap-10 px-4'>
        <ObLink
          variant={'primary'}
          label='All Landing Pages'
          iconLeft='chevronLeft'
          iconSize='small'
          onClick={() => navigate('../..')} //Go Up One Level
        />
        <Outlet />
      </div>
    );
  }
);
