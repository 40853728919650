import { ObNeedsAttentionBadge } from '../../../..';
import {
  CreativeIssue,
  CreativeIssuesPopover,
} from '../creative-issue-popover/creative-issue-popover';

interface CreativeIssueBadgeProps {
  variant: 'indicator' | 'indicator-with-label';
  issues: Array<CreativeIssue>;
}

/**
 * Shows a Indicator Badge when there are issues with a creative
 * that need to be resolved before the creative can be published.
 */
export const CreativeIssueBadge = ({
  issues = [],
  variant = 'indicator-with-label',
}: CreativeIssueBadgeProps) => {
  return (
    <ObNeedsAttentionBadge
      variant={variant}
      label='Needs Attention'
      indicatorDotColor='negative'
      showIndicatorDot={true}
      popoverContent={<CreativeIssuesPopover issues={issues} />}
      priority={'high'}
    />
  );
};
