import { RadioGroup } from '@headlessui/react';
import { ServiceResource } from '@outbound/types';
import { FC } from 'react';
import { StateManagedByParentInput } from '../../../../base-component-props.type';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';
import { ObRadioGroupItem } from '../../../../components/molecules/ob-radio-group/components/ob-radio-group-item';
import { BusinessConfigServiceSettings } from '../../business-config';
import { ObRadioOptionSubGroup } from '../ob-radio-option-subgroup/ob-radio-option-subgroup';

export interface WhatRadioGroupProps
  extends StateManagedByParentInput<string>,
    BusinessConfigServiceSettings {
  /**
   * Accessible ID for the radio group
   */
  id: string;
  servicesFromPlaybook: Array<ServiceResource>;
  somethingElseOptions: Array<{ value: string; label: string }>;
  onConfirmWhatClickedCallback: () => void;
  onAddNewServiceClickedCallback: () => void;
  /**
   * Whether the confirm button should be hidden
   */
  hideConfirmButton: boolean;
}

export const WhatRadioGroup: FC<WhatRadioGroupProps> = ({
  servicesFromPlaybook = [],
  onAddNewServiceClickedCallback,
  onConfirmWhatClickedCallback,
  value,
  onValueChangedCallback,
  hideConfirmButton = false,
  doesBusinessActivitySupportServices = true,
}) => {
  return (
    <div className='flex flex-col gap-3'>
      <RadioGroup
        value={value}
        onChange={(value) => onValueChangedCallback(value, undefined)}
      >
        <div className='grid grid-cols-1 xs:grid-cols-2 justify-between gap-8'>
          {doesBusinessActivitySupportServices && (
            <ObRadioOptionSubGroup heading='Generate Leads for a Service'>
              {servicesFromPlaybook?.map((service) => (
                <ObRadioGroupItem
                  key={service.id}
                  value={`LEAD_GEN_FOR_SERVICE/${service.id}`}
                  label={service.name}
                  description={''}
                  isDisabled={false}
                  size={'small'}
                  isLoading={false}
                />
              ))}
              <div className='pl-[2px]'>
                <ObButton
                  variant='ghost'
                  color='primary'
                  iconLeft='plus'
                  label='Other Service'
                  fullWidth='never'
                  onClick={onAddNewServiceClickedCallback}
                />
              </div>
            </ObRadioOptionSubGroup>
          )}
          {/* TEMPORARILY REMOVED FOR OUTBOUND FREE RELEASE. WILL BE ADDED BACK LATER */}
          {/* <ObRadioOptionSubGroup
            heading={
              doesBusinessActivitySupportServices ? 'Something Else:' : ''
            }
          >
            {somethingElseOptions.map((somethingElse) => (
              <ObRadioGroupItem
                key={somethingElse.value}
                value={`something-else/${somethingElse.value}`}
                label={somethingElse.label}
                description={''}
                isDisabled={false}
                size={'small'}
                isLoading={false}
              />
            ))}
          </ObRadioOptionSubGroup> */}
        </div>
      </RadioGroup>
      {!hideConfirmButton && (
        <div className='pl-2'>
          <ObButton
            variant='outline'
            label='Confirm'
            size='medium'
            onClick={onConfirmWhatClickedCallback}
          />
        </div>
      )}
    </div>
  );
};
