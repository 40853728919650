import { FormDefinition, FormFieldType } from '@outbound/design-system';

export const BRICK_AND_MORTAR_FORM_DEF: FormDefinition = {
  id: 'brick-and-mortar-form',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'name',
          label: 'Name',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'description',
          label: 'Description',
          helperText: '',
          type: FormFieldType.TEXT_AREA,
          fieldTypeSettings: {},
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'location-details',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'address',
          label: 'Physical Address',
          helperText:
            'Provide a physical address for this location. It will be used to target ads for this location.',
          fieldTypeSettings: {},
          type: FormFieldType.ADDRESS,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'website',
          label: 'Website',
          helperText:
            'Provide this locations website if it is different than your primary site.',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'phone',
          label: 'Phone Number',
          helperText: 'What is the phone number for this location?',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
  ],
  heading: '',
  subHeading: '',
};
