/**
 * Query Keys for Playbook Queries
 */
export const playbookSettingsKeys = {
  all: ['playbook/settings'] as const,
  resources: () => [...playbookSettingsKeys.all, 'detail'] as const,
  resource: (resource: string) =>
    [...playbookSettingsKeys.resources(), resource] as const,
};

/**
 * Query Keys for Service Queries
 */
export const serviceKeys = {
  all: playbookSettingsKeys.resource('service'),
  lists: () => [...serviceKeys.all, 'list'] as const,
  list: (params: any) => [...serviceKeys.lists(), { ...params }] as const,
  details: () => [...serviceKeys.all, 'detail'] as const,
  detail: (id: string) => [...serviceKeys.details(), id] as const,
};

/**
 * Query Keys for Sub-Service Queries
 * These are a child resource of Service and therefore descend from the Service keys
 */
export const subServiceKeys = {
  list: (serviceId: string) =>
    [...serviceKeys.detail(serviceId), 'sub-service'] as const,
  details: (serviceId: string) =>
    [...subServiceKeys.list(serviceId), 'detail'] as const,
  detail: (serviceId: string, id: string) =>
    [...subServiceKeys.details(serviceId), id] as const,
};
