import { TargetingCondition } from '@outbound/types';
import { useCallback, useEffect, useState } from 'react';
import { RuleBody, RuleBodyProps } from './rule-body';
import { RuleContentWrapper } from './rule-content-wrapper';
import { RuleControls } from './rule-controls';
import { RuleDragZone } from './rule-drag-zone';

export interface ObRuleProps extends Omit<RuleBodyProps, 'isInEditMode'> {
  isDragEnabled?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  /**
   * If set to true, the rule will be in edit mode even when it is complete on the first render (User can still lock it in);
   * This property is only evaluated when initially the state of the component
   */
  showEditModeForCompleteRule?: boolean;

  /**
   * A callback function to notify the parent that this rule should be deleted.
   * (Parent manages state so it is responsible for deleting the rule)
   * @param id
   * @returns
   */
  onDeletedCallback: (id: string) => void;
}

const isRuleIncomplete = (value: Partial<TargetingCondition>) => {
  return (
    !value.fieldKey ||
    !value.operator ||
    value.operationValue === undefined ||
    value.operationValue === null ||
    (Array.isArray(value.operationValue) && value.operationValue.length === 0)
  );
};

export const ObRule = ({
  hasError,
  value,
  schema,
  isDragEnabled = false,
  isDisabled = false,
  showEditModeForCompleteRule = false,
  onDeletedCallback,
  onValueUpdatedCallback,
}: ObRuleProps) => {
  const [isInEditMode, setIsInEditMode] = useState(showEditModeForCompleteRule);

  /**
   * Wrapper function that is used to enable edit mode for this rule.
   * This function is meant to deal with any checks that need to be done before setting the state.
   */
  const enableEditModeForRule = useCallback(() => {
    if (!isDisabled) {
      setIsInEditMode(true);
    }
  }, [isDisabled]);

  /**
   * Wrapper function that is used to disable edit mode for this rule.
   * This function is meant to deal with any checks that need to be done before setting the state.
   */
  const disableEditModeForRule = useCallback(() => {
    if (!isRuleIncomplete(value) && !isDisabled) {
      setIsInEditMode(false);
    } else {
      console.log('Rule is incomplete');
      //Highlight errors
    }
  }, [value, isDisabled]);

  /**
   * Handles the Edit Mode Control being clicked
   */
  const handleEditModeToggleClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault(); // Prevent the form from submitting if this is used inside a form
      if (isInEditMode) {
        disableEditModeForRule();
      } else {
        enableEditModeForRule();
      }
    },
    [disableEditModeForRule, enableEditModeForRule, isInEditMode]
  );

  /**
   * Force the rule to be in edit mode if it is incomplete
   */
  useEffect(() => {
    if (isRuleIncomplete(value)) {
      enableEditModeForRule();
    }
  }, [enableEditModeForRule, value]);

  return (
    <RuleContentWrapper hasError={!!hasError}>
      {isDragEnabled && <RuleDragZone />}
      <div className='w-full'>
        <RuleBody
          value={value}
          schema={schema}
          isInEditMode={isInEditMode}
          onValueUpdatedCallback={onValueUpdatedCallback}
        />
      </div>
      {!isDisabled && (
        <div className=''>
          <div className='w-[80px] rounded-r h-full dark:bg-dark/background/surface transition-all duration-300 ease-in-out'>
            <RuleControls
              isEditModeEnabled={isInEditMode}
              onToggleEditModeCallback={handleEditModeToggleClicked}
              onDeleteRuleButtonClickedCallback={() =>
                onDeletedCallback(value.nodeId)
              }
            />
          </div>
        </div>
      )}
    </RuleContentWrapper>
  );
};
