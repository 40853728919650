import {
  RemixFieldButton,
  RemixFieldButtonProps,
} from '../remix-field-button/remix-field-button';
import {
  RemixFieldLockButton,
  RemixFieldLockButtonProps,
} from '../remix-field-lock-button/remix-field-lock-button';

export interface RemixFieldInlineControlsProps
  extends Omit<RemixFieldLockButtonProps, 'isDisabled'>,
    Omit<RemixFieldButtonProps, 'isDisabled'> {
  isRemixLockButtonDisabled: boolean;
  isRemixButtonDisabled: boolean;
}

export const RemixFieldInlineControls = ({
  isRemixButtonDisabled,
  isRemixLockButtonDisabled,
  onClickRemixCallback,
  isLocked,
  onClickLockRemixCallback: onLockRemixClickCallback,
}: RemixFieldInlineControlsProps) => {
  return (
    <div className='flex justify-center items-center px-1 gap-1'>
      <RemixFieldLockButton
        isLocked={isLocked}
        isDisabled={isRemixLockButtonDisabled}
        onClickLockRemixCallback={onLockRemixClickCallback}
      />
      <RemixFieldButton
        isDisabled={isRemixButtonDisabled}
        onClickRemixCallback={onClickRemixCallback}
        variant={'icon'}
      />
    </div>
  );
};
