import { ObProgressBar } from '../../elements/ob-progress-bar/ob-progress-bar';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObMetricCardXBarProps {
  title: string;
  value: number;
  maxValue: number;
  color: 'primary' | 'success' | 'informative' | 'purple';
}

export const ObMetricCardXBar = ({
  title,
  value,
  maxValue,
  color,
}: ObMetricCardXBarProps) => {
  return (
    <div className='flex flex-col justify-start gap-2'>
      <div className='flex flex-row items-center justify-start gap-2'>
        <ObTypography variant='body3'>{value}</ObTypography>
        <ObTypography
          variant='body3'
          truncate={true}
          color='secondary'
        >
          {title}
        </ObTypography>
      </div>
      <ObProgressBar
        size='medium'
        color={color}
        percentage={(value / maxValue) * 100}
      />
    </div>
  );
};
