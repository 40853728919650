import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObDialogHeaderProps {
  /**
   * Primary Heading for the Dialog
   */
  title: string;
}

export const ObDialogHeader: React.FC<ObDialogHeaderProps> = ({
  title,
}: ObDialogHeaderProps) => {
  return (
    <header className='flex  justify-between '>
      <ObTypography
        className='m-0'
        variant='h3'
      >
        {title}
      </ObTypography>
    </header>
  );
};
