import { ThemeOptions } from '@mui/material';
import { defaultDarkPalette } from './mui-default-palette';
import { baseColorPalette } from './design-token-colors';
import { CustomTheme } from './theme-options-base';

export const themeOptionsDark: ThemeOptions & CustomTheme = {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: defaultDarkPalette.divider,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      defaultProps: {
        sx: {
          color: defaultDarkPalette.text.icon,
        },
      },
    },
  },
  palette: {
    action: { ...defaultDarkPalette.action, active: '#f5f5f5' },
    primary: {
      main: '#4A4AFA',
    },
    secondary: defaultDarkPalette.secondary,
    error: defaultDarkPalette.error,
    warning: defaultDarkPalette.warning,
    info: defaultDarkPalette.info,
    success: defaultDarkPalette.success,
    text: { ...defaultDarkPalette.text, primary: '#fafafa' },
    grey: defaultDarkPalette.grey,
    background: {
      default: baseColorPalette.blackInk.hex,
      paper: baseColorPalette.blackInk.hex,
      paperSubtle: defaultDarkPalette.common.black,
    },
    divider: defaultDarkPalette.divider,
    common: defaultDarkPalette.common,
  },
};
