import { LandingPageCard, LandingPageCardProps } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import LandingPage from '../../state/mobx-experiment/landing-page/landing-page';

export interface LandingPageCardObserverProps
  extends Pick<LandingPageCardProps, 'variant'> {
  landingPage: LandingPage;
  /**
   * Optional. If not provided the card will default to navigating to the landing page detail page.
   * @param obrn
   * @returns
   */
  onCardClickedCallback?: (obrn: string) => void;
}

export const LandingPageCardObserver = observer(
  ({
    variant,
    landingPage,
    onCardClickedCallback,
  }: LandingPageCardObserverProps) => {
    const { navigateToLandingPageWithObrn } = useAppNavigation();
    return (
      <LandingPageCard
        variant={variant}
        onPreviewCallback={
          //Open link in new tab
          () => window.open(landingPage.url, '_blank')
        }
        onCardClickedCallback={(obrn) => {
          if (onCardClickedCallback) {
            onCardClickedCallback(obrn);
          } else {
            navigateToLandingPageWithObrn(obrn);
          }
        }}
        obrn={landingPage.obrn}
        pageTitle={landingPage.pageTitle}
        url={landingPage.url}
        landingPageType={landingPage.type}
        landingPageScreenshotPublicUrl={landingPage.thumbnailUrl ?? undefined}
      />
    );
  }
);
