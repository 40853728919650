import { ObIcon, ObTypography } from '@outbound/design-system';

export interface PlaybookCardAddNewButtonProps {
  label: string;
}

export const PlaybookCardAddNewButton = ({
  label,
}: PlaybookCardAddNewButtonProps) => {
  return (
    <div className='bg-dark/action/neutral/normal hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active h-full w-full rounded-lg flex items-center justify-center border border-dark/border/default/normal'>
      <div className='flex flex-col justify-center items-center'>
        <ObIcon
          icon='add'
          size='large'
          color='secondary'
        />
        <ObTypography variant='h4'>{label}</ObTypography>
      </div>
    </div>
  );
};
