import { Disclosure, DisclosurePanel, Transition } from '@headlessui/react';
import { IntegrationConfigurationOperationalStatus } from '@outbound/types';

import { cx } from 'class-variance-authority';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { ObAvatar } from '../../avatars';
import { ObButton } from '../../components/elements/ob-button/ob-button';
import { ObLink } from '../../components/elements/ob-link/ob-link';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { SettingCardRowWithButton } from '../../components/organisms/ob-setting-card-with-button/setting-card-row-with-button';
import { ObBadge } from '../../indicators';
import { timeAgoFromIsoString } from '../../utilities/datetime-utilities';
import { OverLineField } from './components/overline-field';
import { SectionContainer } from './components/section-container';
import { IntegrationXConfigurationCardProps } from './integration-card';
import { lookupStatusDetailsFromOperationalAndLifecycleStatus } from './utils';

export interface IntegrationCtaProps {
  onEnableIntegrationCallback: () => void;
  onRemoveIntegrationCallback: () => void;
}

const getPrettyUrl = (url: string): string | null => {
  try {
    const { hostname } = new URL(url);
    const domainParts = hostname.split('.');

    if (domainParts.length < 2) {
      return null; // Invalid or unsupported domain structure
    }

    // Return subdomain + domain + TLD (omit www if present)
    return domainParts.includes('www')
      ? domainParts.slice(1).join('.')
      : hostname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

export interface IntegrationDetailProps
  extends Omit<
      IntegrationXConfigurationCardProps,
      'variant' | 'onNavigationCallback'
    >,
    IntegrationCtaProps {
  /**
   * Black Box Pass Through
   */
  integrationConfigNode?: ReactNode;
  /**
   * Iso 8601 Timestamp of when the integration was configured
   */
  enabledAtTimestamp?: string;
  /**
   * Black boxed User Object
   * The micro variant of the User Card for the User who enabled this integration
   */
  enabledByUserNode?: ReactNode;
  /**
   * Temporary Implementation of Overview Text till we can move this into a CMS such as Sanity.
   */
  overviewContent: ReactNode;
  /**
   * Link to website containing details about the integration
   */
  websiteUrl: string;
  /**
   * Link to documentation on the integration
   */
  docsUrl: string;

  /**
   * The timestamp when the last health check was completed.
   * If no health check has been completed, this will be undefined
   */
  lastHealthCheckCompletedAt?: string;

  /**
   * Indicates that a health check is currently in progress.
   * We care because we want to block the user from running another health check while one is in progress.
   */
  isHealthCheckInProgress: boolean;

  /**
   * A list of health check item cards with the table variant that should be displayed in the health check section.
   */
  healthCheckItemsNode?: ReactNode;

  /**
   *  Handle when a user clicks the check health button
   */
  onCheckHealthCallback?: () => void;
}

const HeaderSection = ({
  integrationIconUrl,
  name,
  shortDescription,
}: Pick<
  IntegrationDetailProps,
  'name' | 'shortDescription' | 'integrationIconUrl' | 'releaseStatus'
>) => {
  return (
    <div className='flex flex-row  justify-start items-between flex-1'>
      <div className=' flex flex-row flex-nowrap gap-3 justify-start items-start flex-1'>
        <div className='pt-[6px]'>
          <ObAvatar
            shape='rounded'
            size={'medium'}
            profileImageUrl={integrationIconUrl}
          />
        </div>

        <div className='flex flex-col  items-start'>
          <ObTypography variant='h3'>{name}</ObTypography>
          <ObTypography
            variant='body2'
            color='secondary'
          >
            {shortDescription}
          </ObTypography>
        </div>
      </div>
    </div>
  );
};

const IntegrationInfoSection = ({
  operationalStatus,
  overviewContent,
  websiteUrl,
  docsUrl,
  onEnableIntegrationCallback,
}: Pick<
  IntegrationDetailProps,
  | 'operationalStatus'
  | 'lifecycleStatus'
  | 'onEnableIntegrationCallback'
  | 'overviewContent'
  | 'websiteUrl'
  | 'docsUrl'
  | 'releaseStatus'
>) => {
  const previousOperationalStatusRef =
    useRef<IntegrationConfigurationOperationalStatus>();

  /**
   * Close the about section when the integration is first configured
   */
  useEffect(() => {
    const previousOperationalStatus = previousOperationalStatusRef.current;
    if (
      previousOperationalStatus === 'NOT_CONFIGURED' &&
      operationalStatus !== 'NOT_CONFIGURED'
    ) {
      setIsAboutOpen(false);
    }
    // Store the current status in the ref for the next render
    previousOperationalStatusRef.current = operationalStatus;
  }, [operationalStatus]);

  const [isAboutOpen, setIsAboutOpen] = useState<boolean>(
    operationalStatus === 'NOT_CONFIGURED'
  );
  return (
    <SectionContainer>
      <div className='flex flex-col gap-4 sm:gap-0 sm:flex-row p-3 justify-between items-center flex-1'>
        <div className='grid grid-cols-2 gap-3 sm:gap-1 sm:grid-cols-3 w-full sm:w-auto'>
          <OverLineField
            label={
              operationalStatus === 'NOT_CONFIGURED' ? 'Built By' : 'Support'
            }
            content={
              <ObTypography
                variant='body2'
                className=''
              >
                {/* Hardcoded for now. If we ever introduce third party integrations we can update this. */}
                Outbound
              </ObTypography>
            }
          />
          <OverLineField
            label={'Docs'}
            content={
              <ObLink
                variant={'primary'}
                iconLeft='document'
                iconSize='x-small'
                href={docsUrl}
                label='Docs'
                openInNewTab={true}
              />
            }
          />
          <OverLineField
            label={'Website'}
            content={
              <ObLink
                variant={'primary'}
                iconSize='x-small'
                iconLeft='googleGlobe'
                href={websiteUrl}
                label={getPrettyUrl(websiteUrl) ?? websiteUrl}
                openInNewTab={true}
              />
            }
          />
        </div>
        <div className='flex self-stretch sm:justify-end'>
          {operationalStatus === 'NOT_CONFIGURED' && (
            <ObButton
              size='medium'
              label='Enable'
              fullWidth='mobile'
              onClick={onEnableIntegrationCallback}
            />
          )}
          {operationalStatus !== 'NOT_CONFIGURED' && (
            <>
              <div className='hidden sm:block'>
                <ObButton
                  iconLeft='info'
                  size='medium'
                  fullWidth='mobile'
                  variant='ghost'
                  label='About'
                  onClick={() => {
                    setIsAboutOpen((value) => !value);
                  }}
                />
              </div>
              <div className='sm:hidden flex flex-1'>
                <ObButton
                  iconLeft='info'
                  size='medium'
                  fullWidth='mobile'
                  variant='secondary'
                  label='About'
                  onClick={() => {
                    setIsAboutOpen((value) => !value);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Disclosure>
        <Transition
          show={isAboutOpen}
          enter='transition transition-[max-height] duration-200 ease-in'
          enterFrom='transform max-h-0'
          enterTo='transform max-h-screen'
          leave='transition transition-[max-height] duration-400 ease-out'
          leaveFrom='transform max-h-screen'
          leaveTo='transform max-h-0'
        >
          <DisclosurePanel className={cx(' overflow-hidden')}>
            <Transition
              show={isAboutOpen}
              enter='ease-in duration-400'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='flex flex-col m-3'>
                <ObTypography
                  variant='h4'
                  color='secondary'
                >
                  Overview
                </ObTypography>
                {overviewContent}
              </div>
            </Transition>
          </DisclosurePanel>
        </Transition>
      </Disclosure>
    </SectionContainer>
  );
};

export const IntegrationConfigurationSection = ({
  enabledByUserNode,
  enabledAtTimestamp,
  operationalStatus,
  lifecycleStatus,
  healthCheckItemsNode,
  isHealthCheckInProgress,
  lastHealthCheckCompletedAt,
  onCheckHealthCallback,
}: Required<
  Pick<
    IntegrationDetailProps,
    | 'enabledByUserNode'
    | 'enabledAtTimestamp'
    | 'operationalStatus'
    | 'lifecycleStatus'
    | 'healthCheckItemsNode'
    | 'isHealthCheckInProgress'
    | 'onCheckHealthCallback'
  >
> &
  Pick<IntegrationDetailProps, 'lastHealthCheckCompletedAt'>) => {
  const { level, label: content } =
    lookupStatusDetailsFromOperationalAndLifecycleStatus(
      operationalStatus,
      lifecycleStatus
    );

  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState<boolean>(
    operationalStatus !== 'NOMINAL'
  );

  useEffect(() => {
    console.log('Health Check In Progress updated:', isHealthCheckInProgress);
  }, [isHealthCheckInProgress]);
  return (
    <>
      <SectionContainer>
        <div className='m-3 grid grid-cols-2 gap-4'>
          <OverLineField
            label='Enabled By'
            content={enabledByUserNode}
          />
          <OverLineField
            label='Enabled'
            content={
              <ObTypography
                variant='body2'
                className='capitalize'
              >
                {enabledAtTimestamp ? (
                  <span data-chromatic='ignore'>
                    {timeAgoFromIsoString(enabledAtTimestamp)}
                  </span>
                ) : (
                  '--'
                )}
              </ObTypography>
            }
          />
          <OverLineField
            label='Status'
            content={
              <div className='flex flex-row gap-2'>
                <ObBadge
                  level={level}
                  variant='rounded'
                  content={content}
                />
                <div className='pb-1'>
                  {isHealthCheckInProgress && (
                    <div className='h-[24px] pt-1'>
                      <ObTypography
                        variant='subtitle2'
                        color='tertiary'
                      >
                        Checking...
                      </ObTypography>
                    </div>
                  )}
                  {!isHealthCheckInProgress && (
                    <ObLink
                      onClick={() => {
                        if (isHealthCheckInProgress) {
                          return;
                        } else {
                          onCheckHealthCallback?.();
                        }
                      }}
                      label='Check Now'
                      variant='primary'
                    />
                  )}
                </div>
              </div>
            }
          />
          <OverLineField
            label='Last Health Check'
            content={
              <div className='flex flex-row gap-1'>
                <ObTypography
                  variant='body2'
                  className='capitalize'
                >
                  {lastHealthCheckCompletedAt ? (
                    <span data-chromatic='ignore'>
                      {timeAgoFromIsoString(lastHealthCheckCompletedAt)}
                    </span>
                  ) : (
                    '--'
                  )}
                </ObTypography>
              </div>
            }
          />
        </div>
        <Disclosure>
          <Transition
            show={isMoreDetailsOpen}
            enter='transition transition-[max-height] duration-200 ease-in'
            enterFrom='transform max-h-0'
            enterTo='transform max-h-screen'
            leave='transition transition-[max-height] duration-400 ease-out'
            leaveFrom='transform max-h-screen'
            leaveTo='transform max-h-0'
          >
            <DisclosurePanel className={cx(' overflow-hidden')}>
              <Transition
                show={isMoreDetailsOpen}
                enter='ease-in duration-400'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='m-3'>
                  <div className='flex flex-row justify-between'>
                    <ObTypography
                      variant='h5'
                      color='secondary'
                    >
                      Health Check Details
                    </ObTypography>
                  </div>
                  {lifecycleStatus === 'INITIALIZING' && (
                    <div className='flex justify-center items-center w-full h-[80px]'>
                      <ObTypography
                        color='tertiary'
                        variant='body2'
                      >
                        Initializing Integration
                      </ObTypography>
                    </div>
                  )}
                  {lifecycleStatus === 'FAILED' && (
                    <div className='flex justify-center items-center w-full h-[80px]'>
                      <ObTypography
                        color='negative'
                        variant='body2'
                      >
                        Integration Configuration Failed
                      </ObTypography>
                    </div>
                  )}
                  {lifecycleStatus === 'ACTIVE' && (
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <th className='text-left pr-4 py-3 '>
                            <ObTypography
                              variant='subtitle3'
                              color='secondary'
                              className='uppercase text-left'
                              as='span'
                            >
                              Status
                            </ObTypography>
                          </th>
                          <th className='text-left px-4 py-3 '>
                            <ObTypography
                              variant='subtitle3'
                              color='secondary'
                              className='uppercase text-left'
                              as='span'
                            >
                              Check
                            </ObTypography>
                          </th>
                          <th className='text-left pl-4 py-3 '>
                            <ObTypography
                              variant='subtitle3'
                              color='secondary'
                              className='uppercase text-left'
                              as='span'
                            >
                              Message
                            </ObTypography>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='border-t border-dark/border/default/normal'>
                        {healthCheckItemsNode}
                      </tbody>
                    </table>
                  )}
                </div>
              </Transition>
            </DisclosurePanel>
          </Transition>
          <div className='m-3'>
            <ObButton
              size='medium'
              fullWidth='always'
              variant='ghost'
              label={isMoreDetailsOpen ? 'Show Less' : 'Show More'}
              onClick={() => {
                setIsMoreDetailsOpen((value) => !value);
              }}
            />
          </div>
        </Disclosure>
      </SectionContainer>
    </>
  );
};

/**
 * Page Template for Integration Detail Pages
 * @returns
 */
export const IntegrationDetail = ({
  releaseStatus,
  integrationIconUrl,
  name,
  shortDescription,
  operationalStatus,
  lifecycleStatus,
  integrationConfigNode,
  enabledAtTimestamp,
  enabledByUserNode,
  onEnableIntegrationCallback,
  onRemoveIntegrationCallback,
  overviewContent,
  websiteUrl,
  docsUrl,
  lastHealthCheckCompletedAt,
  isHealthCheckInProgress,
  healthCheckItemsNode,
  onCheckHealthCallback,
}: IntegrationDetailProps) => {
  return (
    <div className='flex flex-col gap-6'>
      {/* MARKETING INFORMATION ABOUT THE INTEGRATION FOR ENABLED AND DISABLED INTEGRATIONS */}
      <HeaderSection
        releaseStatus={releaseStatus}
        integrationIconUrl={integrationIconUrl}
        name={name}
        shortDescription={shortDescription}
      />
      {/* SHOW INFO ABOUT THE CONFIGURATION STATUS FOR CONFIGURE INTEGRATIONS */}
      <IntegrationInfoSection
        lifecycleStatus={lifecycleStatus}
        operationalStatus={operationalStatus}
        websiteUrl={websiteUrl}
        releaseStatus={releaseStatus}
        docsUrl={docsUrl}
        overviewContent={overviewContent}
        onEnableIntegrationCallback={onEnableIntegrationCallback}
      />

      {operationalStatus !== 'NOT_CONFIGURED' && (
        <IntegrationConfigurationSection
          onCheckHealthCallback={onCheckHealthCallback!}
          lifecycleStatus={lifecycleStatus!}
          operationalStatus={operationalStatus}
          enabledByUserNode={enabledByUserNode!} //IF Configured This will be present
          enabledAtTimestamp={enabledAtTimestamp!} //IF Configured This will be present
          healthCheckItemsNode={healthCheckItemsNode!} //IF Configured This will be present
          isHealthCheckInProgress={isHealthCheckInProgress}
          lastHealthCheckCompletedAt={lastHealthCheckCompletedAt}
        />
      )}
      {/* SHOW INTEGRATION SPECIFIC CONFIG FOR ENABLED INTEGRATIONS */}
      {operationalStatus !== 'NOT_CONFIGURED' &&
        integrationConfigNode != null && (
          <div className='flex flex-col gap-1'>
            <ObTypography
              variant='h5'
              color='secondary'
            >
              Integration Details
            </ObTypography>
            {integrationConfigNode}
          </div>
        )}
      {operationalStatus !== 'NOT_CONFIGURED' && (
        <SettingCardRowWithButton
          color='error'
          buttonLabel='Remove Integration'
          title={'Remove Integration'}
          body={
            'Remove this integration from your Workspace. Any features that depend on this integration will cease to work. This action is permanent and cannot be undone. You will need to re-enable the integration to restore functionality.'
          }
          handleButtonClickCallback={onRemoveIntegrationCallback}
        />
      )}
    </div>
  );
};
