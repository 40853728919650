import { useDialogService } from '@outbound/design-system';
import { useCallback } from 'react';
import { SetupIntegrationFlowObservable } from '../../../../../object-card/integration-x-configuration/setup-integration-flow';
import Integration from '../../../../../state/mobx-experiment/workspace/integration/integration';
import { IntegrationXWorkspace } from '../../../../../state/mobx-experiment/workspace/types/integration-x-workspace.type';
import Workspace from '../../../../../state/mobx-experiment/workspace/workspace';
import useConfigureIntegration from './integration-specific-config/use-start-integration';

/**
 * Hook for encapsulating the logic for setting up an integration.
 * This can be used when we want to allow a user to directly setup an integration without requiring
 * them to navigate to the workspace settings.
 */
export const useSetupIntegrationDialog = () => {
  const { openDialog } = useDialogService();
  /**
   * Opens a Dialog to capture required user input for the given Integration Configuration Type.
   * If no user input is needed this will do nothing when called
   */
  const { collectRequiredUserInputForIntegration } = useConfigureIntegration();
  /**
   * Creates an Integration Configuration for the given Integration Type.
   * If the Integration Requires User Input, it will collect the required input from the user
   * before creating the configuration.
   * @param integrationConfigurationType
   */
  const createIntegrationConfigurationForIntegrationType = useCallback(
    async (integration: Integration) => {
      return collectRequiredUserInputForIntegration(
        integration.configurationType
      );
    },
    [collectRequiredUserInputForIntegration]
  );

  const showIntegrationConfigurationSetupProgress = useCallback(
    async (integrationConfiguration: IntegrationXWorkspace) => {
      return openDialog({
        hideDefaultActions: true,
        hasCloseButton: false,

        dialogContent: (
          <SetupIntegrationFlowObservable
            integrationXWorkspace={integrationConfiguration}
          />
        ),
        title: '',
      });
    },
    [openDialog]
  );

  const initiateConfigureIntegrationUserFlow = useCallback(
    async (
      integration: Integration,
      workspace: Workspace,
      blockUserWhileConfiguring = false
    ) => {
      const userInput = await createIntegrationConfigurationForIntegrationType(
        integration
      );

      workspace.configureIntegration(integration.configurationType, userInput);

      const integrationXWorkspace =
        workspace.getIntegrationWithConfigurationBySlug(integration.slug)!;

      if (blockUserWhileConfiguring) {
        await showIntegrationConfigurationSetupProgress(integrationXWorkspace);
      }
    },
    [
      createIntegrationConfigurationForIntegrationType,
      showIntegrationConfigurationSetupProgress,
    ]
  );

  return {
    createIntegrationConfigurationForIntegrationType,
    showIntegrationConfigurationSetupProgress,
    initiateConfigureIntegrationUserFlow,
  };
};
