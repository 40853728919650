'use client';
import { FaceFrownIcon } from '@heroicons/react/24/solid';
import { CircularProgress } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { cva, cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObColorType } from '../../colors/colors';
import { IconSystemKeys, iconSystemMap } from '../icons';

export type IconSize =
  | 'xx-large'
  | 'x-large'
  | 'large'
  | 'medium'
  | 'small'
  | 'x-small'
  | 'xx-small';
export interface ObIconProps {
  /**
   * The Icon from the System that should be displayed
   */
  icon: IconSystemKeys;
  /**
   * The size of the icon to be displayed
   */
  size: IconSize;
  /**
   * Replaces Icon with circular progress indicator that respects the icons size and color
   */
  loading?: boolean;
  classNames?: string;
  color?: ObColorType;
  disabled?: boolean;
}

/**
 * A Component to easily display an icon from the outbound icon system in the UI
 * @param icon
 * @param size
 * @param color
 * @param loading
 * @constructor
 */
export const ObIcon: FC<ObIconProps> = ({
  icon,
  size,
  loading = false,
  classNames,
  color = 'content',
  disabled = false,
  ...props
}: ObIconProps) => {
  const TargetIconComponent:
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string;
          titleId?: string;
        } & React.RefAttributes<SVGSVGElement>
      >
    | React.FC<React.SVGProps<SVGSVGElement>>
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }) =
    iconSystemMap[icon];

  const iconStyles = cva('icon', {
    variants: {
      /*
      We are in the process of migrating from MUI icons to Hero Icons
      Font Size is for MUI Icons while height and width are for Heroicons
      Once we migrate fully off of MUI Icons we can remove the text sizing
      */
      size: {
        'xx-large': [
          'text-[80px]',
          'h-[80px] w-[80px] min-h-[80px] min-w-[80px] ',
        ],
        'x-large': [
          'text-[60px]',
          'h-[60px] w-[60px] min-h-[60px] min-w-[60px] ',
        ],
        large: ['text-[36px]', 'h-[36px] w-[36px] min-h-[36px] min-w-[36px]'],
        medium: ['text-[24px]', 'h-[24px] w-[24px] min-h-[24px] min-w-[24px]'],
        small: ['text-[20px]', 'h-[20px] w-[20px] min-h-[20px] min-w-[20px]'],
        'x-small': [
          'text-[16px]',
          'h-[16px] w-[16px] min-h-[16px] min-w-[16px] max-h-[16px] max-w-[16px]',
        ],
        'xx-small': [
          'text-[10px]',
          'h-[10px] w-[10px] min-h-[10px] min-w-[10px] max-h-[10px] max-w-[10px]',
        ],
      },
      color: {
        /*
         * invertedPrimary is used when the content needs to be the opposite mode color that the page is in
         * Example: ObIconAvatar uses the dark mode background for the avatar, but the icon needs to be in light mode for contrast purposes
         * In this case, using 'content' would always set it to the opposite mode that we need because the color is being based off of the
         * page mode versus the background of the avatar
         */
        invertedPrimary: [
          'dark:text-dark/content/inverted-primary',
          'text-light/content/inverted-primary',
        ],
        content: ['dark:text-contentPrimaryDark', 'text-contentPrimaryLight'],
        primary: ['dark:text-actionPrimaryDark', 'text-actionPrimaryLight'],
        primaryV2: ['dark:text-actionPrimaryV2NormalDark'],
        secondary: [
          'dark:text-actionSecondaryDark',
          'text-actionSecondaryLight',
        ],
        tertiary: [
          'dark:text-contentTertiaryDark',
          'text-contentTertiaryLight',
        ],
        'text.secondary': [
          'dark:text-contentSecondaryDark',
          'text-contentSecondaryDark',
        ],
        positive: [
          'dark:text-contextualPositiveDark',
          'text-contextualPositiveLight',
        ],
        informative: [
          'dark:text-contextualInformativeDark',
          'text-contextualInformativeLight',
        ],
        negative: [
          'dark:text-dark/content/negative',
          'text-contextualNegativeLight',
        ],
        warning: [
          'dark:text-contextualWarningDark',
          'text-contextualWarningLight',
        ],
        lime: [
          'dark:text-nonContextualLimeDark',
          'text-nonContextualLimeLight',
        ],
        cyan: [
          'dark:text-nonContextualCyanDark',
          'text-nonContextualCyanLight',
        ],
        mint: [
          'dark:text-nonContextualMintDark',
          'text-nonContextualMintLight',
        ],
        teal: ['dark:text-light/content/teal', 'text-light/content/teal'],
        cobalt: [
          'dark:text-dark/non-contextual/cobalt',
          'text-dark/non-contextual/cobalt',
        ],
        inherit: ['text-inherit'],
        yellow: [
          'dark/non-contextual/yellow',
          'text-dark/non-contextual/yellow',
        ],
      },
      disabled: {
        true: 'pointer-events-none',
      },
    },
    compoundVariants: [
      {
        color: 'secondary',
        disabled: true,
        className: [
          'dark:text-actionSecondaryDisabledDark',
          'text-actionSecondaryDisabledDark',
        ],
      },
    ],
    defaultVariants: {
      size: 'x-small',
      color: 'content',
      disabled: false,
    },
  });

  const getFontSize = (size: IconSize) => {
    switch (size) {
      case 'xx-large':
        return '124px';
      case 'x-large':
        return '96px';
      case 'large':
        return '36px';
      case 'medium':
        return '24px';
      case 'small':
        return '20px';
      case 'x-small':
        return '16px';
    }
    throw new Error('Invalid Icon Size');
  };

  if (TargetIconComponent != null) {
    return (
      <div
        style={{ lineHeight: 0 }}
        data-icon-name={icon}
        data-testid={`ob-icon__${icon}`}
        className={cx(
          iconStyles({ size: size, color: color, disabled }),
          classNames,
          'leading-none'
        )}
      >
        {loading ? (
          <CircularProgress
            data-testid={'loading-icon'}
            color={'inherit'}
            size={getFontSize(size)}
          />
        ) : (
          <TargetIconComponent
            {...props}
            role={icon}
            fontSize={'inherit'}
            color={'inherit'}
          />
        )}
      </div>
    );
  } else {
    /*
    Icon was not found
     */
    return (
      <div
        className={cx(iconStyles({ size: size }), classNames, 'leading-none')}
      >
        <FaceFrownIcon
          style={{ lineHeight: 0 }}
          className={cx(
            iconStyles({ size: size, color: color }),
            classNames,
            'leading-none'
          )}
        />
      </div>
    );
  }
};
