'use client';
import { Dialog, DialogPanel } from '@headlessui/react';
import { cx } from 'class-variance-authority';
import { useState } from 'react';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

export type ObFullDialogProps = {
  children: React.ReactNode;
  className?: string;
  panelClassName?: string;
  open?: boolean;
  onHide?: () => void;
  scrollable?: boolean;
};

export const ObFullDialog = ({
  children,
  className,
  open,
  onHide,
  panelClassName,
  scrollable = false,
}: ObFullDialogProps) => {
  const onClose = () => {
    onHide?.();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={cx('relative z-50', className)}
    >
      <div
        className={cx(
          'bg-bgDefaultDark fixed inset-0',
          scrollable && 'overflow-y-auto'
        )}
      >
        <DialogPanel
          className={cx('w-full h-full sm:px-24 relative', panelClassName)}
        >
          <div className='absolute left-0 right-0 top-2/3 h-min w-full -z-10'>
            <div className='opacity-[.20] blur-[200px] bg-actionPrimaryLight h-48 w-full'></div>
          </div>

          {children}

          <div className='absolute right-4 sm:right-24 top-6 sm:top-10'>
            <button
              onClick={onClose}
              className='inline-flex p-1 border border-whiteAlpha-5 rounded-lg bg-actionNeutralNormalDark'
            >
              <ObIcon
                icon='close'
                size='x-small'
              />
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export type ObFullDialogWrapperProps = Omit<
  ObFullDialogProps,
  'open' | 'onHide'
> & {
  trigger: (args: { onOpen: () => void }) => React.ReactNode;
};

export const ObFullDialogWrapper = ({
  children,
  trigger,
  ...props
}: ObFullDialogWrapperProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ObFullDialog
        open={open}
        onHide={() => setOpen(false)}
        {...props}
      >
        {children}
      </ObFullDialog>
      {trigger({ onOpen: () => setOpen(true) })}
    </>
  );
};
