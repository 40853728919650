import { ObButton } from '../../elements/ob-button/ob-button';

export interface ObDialogFooterProps {
  affirmativeActionLabel?: string;
  dismissiveActionLabel?: string;
  affirmativeActionCallback: () => void;
  dismissiveActionCallback: () => void;
  isAffirmativeActionDestructive?: boolean;
}

export const ObDialogFooter: React.FC<ObDialogFooterProps> = ({
  affirmativeActionLabel,
  dismissiveActionLabel,
  affirmativeActionCallback,
  dismissiveActionCallback,
  isAffirmativeActionDestructive = false,
}: ObDialogFooterProps) => {
  /**
   * If the primary action is destructive, the secondary action should be emphasized.
   */
  const emphasisPrimaryAction = !isAffirmativeActionDestructive;
  const emphasisSecondaryAction = !emphasisPrimaryAction;
  return (
    <footer className='flex rounded-b-lg justify-end gap-4'>
      {dismissiveActionLabel && (
        <ObButton
          onClick={dismissiveActionCallback}
          size='large'
          variant={emphasisSecondaryAction ? 'primary' : 'secondary'}
          label={dismissiveActionLabel}
        ></ObButton>
      )}
      <ObButton
        onClick={affirmativeActionCallback}
        size='large'
        variant={emphasisPrimaryAction ? 'primary' : 'secondary'}
        label={affirmativeActionLabel ?? 'Submit'}
      ></ObButton>
    </footer>
  );
};
