import {
  CampaignChannelType,
  MARKETING_DAYS_PER_MONTH,
  PhaseGoal,
  StrategyResource,
} from '@outbound/types';
import { cx } from 'class-variance-authority';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ObChannelAvatar } from '../../avatars/ob-channel-avatar/ob-channel-avatar';
import { ObBadgeFlag } from '../../components/elements/ob-badge-flag/ob-badge-flag';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { Level } from '../../tokens/colors/colors';
import { IconSystemKeys } from '../../tokens/icons/icons';
import { ObIcon } from '../../tokens/icons/ob-icon/ob-icon';

export interface ObBudgetSliderProps {
  value: number;
  initialMaxBudget: number;
  componentWidth: number;
  onChangeCallback?: (value: number) => any;
  /**
   * The goals which we are trying to accomplish with the daily budget
   * LIMITED TO A SINGLE GOAL FOR LAUNCH. SUPPORT FOR MULTIPLE GOALS WILL BE ADDED IN THE FUTURE.
   */
  strategies: Array<StrategyResource>;

  activeStrategy?: StrategyResource;

  /**
   *
   * @returns The most up to date version of the goals
   */
  onGoalsUpdated?: (updatedGoals: Array<StrategyResource>) => void;
  isLoading: boolean;
}

const getStrategyDetails = (
  type: PhaseGoal
): { icon: IconSystemKeys; metric: string } => {
  switch (type) {
    case PhaseGoal.HIGH_QUALITY_LEADS:
      return {
        icon: 'goalLeadGeneration',
        metric: 'Leads',
      };
    case PhaseGoal.BRAND_AWARENESS:
      return {
        icon: 'goalBrandAwareness',
        metric: 'Impressions',
      };
    case PhaseGoal.RETARGETING:
      return {
        icon: 'target',
        metric: 'Click',
      };
    default:
      return {
        icon: 'award',
        metric: 'Goals',
      };
  }
};

export let activeGoalLabel = '';
const getActiveGoalLabel = (
  value: number,
  segment: StrategyResource
): string => {
  if (segment.phases && segment.phases.length > 0) {
    const phaseType = segment.phases[0].type;
    if (
      phaseType &&
      Math.round(value) >= segment.minBudget &&
      Math.round(value) <= segment.maxBudget
    ) {
      activeGoalLabel = getStrategyDetails(phaseType).metric;
      return activeGoalLabel;
    }
  }
  return 'Error';
};

const getStrategyIcon = (segment: StrategyResource): IconSystemKeys => {
  if (segment.phases && segment.phases.length > 0) {
    const phaseType = segment.phases[0]?.type;
    if (phaseType) {
      const strategyIcon = getStrategyDetails(phaseType).icon;
      return strategyIcon || 'award';
    }
  }
  return 'award';
};

export const ObBudgetSlider = ({
  onChangeCallback,
  value,
  initialMaxBudget,
  componentWidth,
  strategies = [],
  activeStrategy,
}: ObBudgetSliderProps) => {
  const [maxBudget] = useState(initialMaxBudget);

  const [sortedStrategies, setSortedStrategies] = useState<StrategyResource[]>(
    []
  );

  useEffect(() => {
    const sorted = [...strategies].sort((a, b) => a.minBudget - b.minBudget);
    setSortedStrategies(sorted);
  }, [strategies]);

  const [isInitialized, setIsInitialized] = useState(
    value != null && value != 0
  );

  const calculateGoalWidth = useCallback(
    (strategy: StrategyResource, index: number) => {
      /**
       * If the index is not 0, subtract one from the minBudget
       * This is to ensure when calculating the range span we account for the gap between
       * the previous strategy maxBudget and the current strategy minBudget
       */
      const subtractOne = index !== 0 ? 1 : 0;

      if (strategy) {
        let rangeSpan = 0;
        if (strategy.maxBudget >= initialMaxBudget) {
          rangeSpan = initialMaxBudget - (strategy.minBudget - subtractOne);
        }
        if (strategy.maxBudget < initialMaxBudget) {
          rangeSpan = strategy.maxBudget - (strategy.minBudget - subtractOne);
        }
        const segmentWidth = (rangeSpan * 100) / initialMaxBudget;
        return segmentWidth;
      } else {
        return 0;
      }
    },
    [initialMaxBudget]
  );

  const setupFlagNumberValue = useCallback(
    (strategy: StrategyResource, dailyBudget: number) => {
      switch (strategy?.phases?.[0]?.type) {
        case PhaseGoal.HIGH_QUALITY_LEADS: {
          /**
           * Cost Per Lead
           * We want to show the user the number of leads they can expect to get
           * with their daily budget based on the current CPL
           */

          return (
            ((dailyBudget * MARKETING_DAYS_PER_MONTH) /
              (strategy.successMetricUnitCost ?? 0)) *
            0.05
          ); //Expected conversion rate on clicks to leads (5%)
        }
        case PhaseGoal.BRAND_AWARENESS: {
          /**
           * CMP = Cost Per Thousand Impressions
           * We want to show the user the number of impressions they can expect to get
           * with their daily budget based on the current CPM
           */
          return (
            ((dailyBudget * MARKETING_DAYS_PER_MONTH) /
              (strategy.successMetricUnitCost ?? 0)) *
            1000
          );
        }
        case PhaseGoal.RETARGETING:
          return (
            (dailyBudget * MARKETING_DAYS_PER_MONTH) /
            (strategy.successMetricUnitCost ?? 0)
          );
        default:
          return 0;
      }
    },
    []
  );

  const isGoalSegmentActive = useCallback(
    (segment: StrategyResource, value: number) => {
      return (
        Math.round(value) >= Math.round(segment.minBudget) &&
        Math.round(value) <= Math.round(segment.maxBudget)
      );
    },
    []
  );

  const formatGoalType = useCallback((goalType: PhaseGoal): PhaseGoal => {
    return goalType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ') as PhaseGoal;
  }, []);

  const [isDragging, setIsDragging] = useState(false);
  const rangeRef = useRef<HTMLDivElement>(null);

  const updateValue = useCallback(
    (clientX: number) => {
      if (rangeRef.current) {
        const rangeRect = rangeRef.current.getBoundingClientRect();
        const newValue = Math.max(
          0,
          Math.min(
            maxBudget,
            ((clientX - rangeRect.left) / rangeRect.width) * maxBudget
          )
        );
        if (onChangeCallback) {
          onChangeCallback(newValue);
        }
      }
    },
    [maxBudget, onChangeCallback]
  );

  /**
   * Used to track the first load of the slider.
   * This timeout matches the transition duration of the slider bar background.
   * We want the bar to animate up to the value when it first loads but we don't want it to animate when the value changes.
   * This timeout allows the bar to animate up to the value when it first loads and then disables the transition.
   */
  useEffect(() => {
    if (value != null && value != 0 && !isInitialized) {
      setTimeout(() => {
        setIsInitialized(true);
      }, 500);
    }
  }, [value, isInitialized]);

  /**
   * Handle drag with a mouse
   */
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        updateValue(e.clientX);
      }
    },
    [isDragging, updateValue]
  );

  /**
   * Handle drag on touch devices
   */
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (isDragging && e.touches.length) {
        updateValue(e.touches[0].clientX);
      }
    },
    [isDragging, updateValue]
  );

  /**
   * Handle when a user stops dragging with a mouse
   */
  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  /**
   * Handle when a user stops dragging with their finger
   */
  const handleTouchEnd = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleMouseMove, handleMouseUp, handleTouchEnd, handleTouchMove]);

  useEffect(() => {
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  }, [value, onChangeCallback]);

  return (
    <div className='slider relative w-full lg:w-auto select-none'>
      <div
        data-testid='strategy-segment'
        className='absolute py-12 top-[-48px] flex'
        style={{
          width: '100%',
          height: '76px',
          zIndex: '0',
        }} // Goal segments looped through here and create background blocks behind budget slider
      >
        <div
          className='rounded-l-lg absolute top-[16px] left-[-20px]'
          style={{
            width: '20px',
            height: '64px',
            backgroundColor: `rgba(217, 217, 217, .15)`,
          }}
        ></div>
        {sortedStrategies.map((strategy, index) => {
          const segmentWidth = calculateGoalWidth(strategy, index);
          const opacity = 0.15 - index * 0.05;
          getActiveGoalLabel(value, strategy);
          const strategyIcon = getStrategyIcon(strategy);
          return (
            <div
              key={strategy.strategyId}
              data-testid='segments'
              className='relative top-[-32px]'
              style={{
                width: `${segmentWidth}%`,
                height: '64px',
                backgroundColor: `rgba(217, 217, 217, ${opacity})`,
              }}
            >
              <div
                className={cx(
                  'text-contentSecondaryDark',
                  isGoalSegmentActive(strategy, value)
                    ? 'opacity-100'
                    : 'opacity-30'
                )}
              >
                <div className='text-contentSecondaryDark flex flex-col items-center gap-2 relative top-[80px] my-0 mx-auto first:pr-[20px] last:pr-[20px]'>
                  <ObChannelAvatar
                    channel={
                      strategy?.phases?.[0]?.campaign?.channelType ??
                      CampaignChannelType.GOOGLE_SEARCH_ADS
                    }
                    size={'xx-small'}
                  />
                  <div className='flex gap-1 items-center relative max-w-28	lg:w-max'>
                    <div
                      className={cx(`flex flex-row items-center justify-center gap-1
                      ${isGoalSegmentActive(strategy, value) ? '' : 'hidden'}`)}
                    >
                      <ObIcon
                        color='inherit'
                        icon={strategyIcon}
                        size='xx-small'
                      />

                      <ObTypography
                        variant='body3'
                        color='inherit'
                        className={cx(
                          `${
                            !!strategy?.phases?.[0]?.campaign?.channelType
                              ? ''
                              : 'hidden'
                          }`
                        )}
                      >
                        {formatGoalType(
                          strategy?.phases?.[0]?.type ??
                            PhaseGoal.BRAND_AWARENESS
                        )}
                      </ObTypography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={cx(`rounded-r-lg absolute top-[-32px] left-[100%]`)}
        style={{
          width: '20px',
          height: '64px',
          backgroundColor: `rgba(217, 217, 217, ${
            0.15 - (strategies.length - 1) * 0.05
          })`,
        }}
      ></div>
      <div className='flex flex-row items-center gap-2'>
        <div
          ref={rangeRef}
          style={{ width: '100%', height: '8px', zIndex: '1' }}
          className='relative rounded bg-bgSurface2Dark'
        >
          <div
            className={cx(
              'bg-money-gradient h-[8px] w-[20px] relative rounded-l duration-500 ease-in-out',
              !isInitialized ? 'transition-all' : 'transition-opacity' //After the initial load, we want to disable the transition to prevent the bar from lagging behind the slider when the value changes
            )}
            style={{
              width: `${(value / maxBudget) * 100}%`,
              opacity: `${value != null && value != 0 ? 1 : 0}`,
            }}
          ></div>
          <div
            className='group text-bgSurface2Light cursor-ew-resize relative top-[-12px] left-[-12px]'
            style={{ left: `${(value / maxBudget) * 100 - 2}%`, zIndex: '1' }}
            onMouseDown={() => setIsDragging(true)}
            onTouchStart={() => setIsDragging(true)}
          >
            {/* GOAL SLIDERS */}
            {activeStrategy != null && (
              <ObBadgeFlag
                className={cx(
                  `transition-all ease-in-out duration-300 opacity-0 group-hover:opacity-100 ${
                    isDragging ? 'opacity-100' : ''
                  }`
                )}
                value={value}
                goalValue={setupFlagNumberValue(activeStrategy!, value)}
                componentWidth={componentWidth}
                content={activeGoalLabel}
                lineHeight={24}
                iconRight='check'
                level={Level.SUCCESS}
              />
            )}
            <ObIcon
              color='inherit'
              icon='circle'
              size='x-small'
            />
          </div>

          <div
            className={cx(
              isDragging
                ? 'duration-0 scale-110 origin-center'
                : 'duration-500',
              'h-[28px] w-[28px] m-auto origin-center bg-bgSurface2Light/[.3] absolute top-[-10px] left-[-6px] rounded-full cursor-ew-resize ease-in-out hover:scale-110'
            )}
            style={{ left: `${(value / maxBudget) * 100 - 3.25}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
