/**
 * The environment variables for the application.
 * These are typically set by our build process at build time
 * for the specific environment we are building for through string replacement
 *
 * DEVELOPMENT NOTES:
 * When running `pnpm run dev` these values will be set dynamically by SST based on
 * the values for your developer aws account and the stage you are running.
 *
 * Sometimes you want to work on the frontend without deploying a backend.
 * In this case you can run `pnpm run dev-no-sst` against the development SDLC environment.
 * There will be no dependency on sst and the fallback values defined in this file will be used.
 *
 * MUST NOT BE USED TO STORE SECRETS!
 */

/**
 * -----------------------------------------------------------------------------------------------------------------------
 * API CONFIGURATION
 * -----------------------------------------------------------------------------------------------------------------------
 */
/**
 * The Base URL of the Outbound.com API that the application will use
 * Defaults to development if not set
 */
export const OUTBOUND_API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL ?? 'https://api.dev.outbound.com';

console.log('Web Environment API BASE URL:', OUTBOUND_API_BASE_URL);

/**
 * URL of the Outbound GraphQL API Endpoint. It should be noted that this is deprecated and will be removed in the future.
 * We had originally planned to use a GraphQL API for the application, but have since decided to use a REST API instead.
 */
export const OUTBOUND_GRAPHQL_API_ENDPOINT = `${OUTBOUND_API_BASE_URL}/graphql`;

/**
 * -----------------------------------------------------------------------------------------------------------------------
 * LAUNCH DARKLY CONFIGURATION (FEATURE FLAGS)
 * -----------------------------------------------------------------------------------------------------------------------
 */
/**
 * Launch Darkly Client ID
 * ID used to connect to Launch Darkly. This is a client side ID that is not expected to be kept secret.
 * Defaults to the development ID if not set.
 *
 * (Generated by Launch Darkly when creating an environment in their service)
 */
export const LAUNCH_DARKLY_CLIENT_ID =
  import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID ?? '649f0736bc0db01355d02eac';

/**
 * -----------------------------------------------------------------------------------------------------------------------
 * AUTH0 CONFIGURATION (AUTHENTICATION)
 * -----------------------------------------------------------------------------------------------------------------------
 */

/**
 * Auth0 Requires a audience header to dispense a JWT token. This is the audience that will be used.
 */
export const AUTHORIZATION_TOKEN_AUDIENCE =
  import.meta.env.VITE_API_AUDIENCE ?? 'https://api.dev.outbound.com';

/**
 * The url of our Auth0 Tenant
 */
export const AUTHORIZATION_DOMAIN =
  import.meta.env.VITE_AUTH_0_DOMAIN ?? 'auth.dev.outbound.com';

export const AUTHORIZATION_DEFAULT_SCOPES = 'openid profile email';
/**
 * The client id that uniquely identifies this application to Auth0.
 * (Generated by Auth0 when creating a client application)
 */
export const AUTHORIZATION_CLIENT_ID =
  import.meta.env.VITE_AUTH_0_CLIENT_ID ?? 'VWKexZ6Qr3XTWfsMuI2gIpdcIenOaC38';

/**
 * -----------------------------------------------------------------------------------------------------------------------
 * IRONCLAD CONFIGURATION (AGREEMENT MANAGEMENT VENDOR)
 * -----------------------------------------------------------------------------------------------------------------------
 */

/**
 * ID used to connect to Ironclad. This is a client side ID that is not expected to be kept secret.
 * Defaults to our sandbox account if not set.
 *
 * (Generated by Ironclad)
 */
export const IRONCLAD_CLICK_WRAP_ACCESS_ID =
  import.meta.env.VITE_IRONCLAD_CLICKWRAPPER_ACCESS_ID ??
  '4d48bc1d-9eea-4ad7-8575-8ca9c2e96717';

/**
 * -----------------------------------------------------------------------------------------------------------------------
 * DATADOG CONFIGURATION (MONITORING, LOGGING, AND ANALYTICS)
 * -----------------------------------------------------------------------------------------------------------------------
 */

export const VITE_DATADOG_ENVIRONMENT =
  import.meta.env.VITE_DATADOG_ENVIRONMENT ?? '';
export const VITE_DATADOG_ENABLED = import.meta.env.VITE_DATADOG_ENABLED;
export const VITE_DATADOG_CLIENT_TOKEN =
  import.meta.env.VITE_DATADOG_CLIENT_TOKEN ?? '';
export const VITE_DATADOG_APPLICATION_ID =
  import.meta.env.VITE_DATADOG_APPLICATION_ID ?? '';
export const VITE_DATADOG_SITE = import.meta.env.VITE_DATADOG_SITE ?? '';
export const VITE_DATADOG_SERVICE = import.meta.env.VITE_DATADOG_SERVICE ?? '';
