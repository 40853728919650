import { CampaignSettingValue } from '@outbound/types';

/**
 * Setting id for the setting that indicates if a customer profile is enabled for a campaign
 * @param customerProfileId
 * @returns
 */
export const customerProfileEnabledSettingId = (customerProfileId: string) =>
  `customer-profile#${customerProfileId}#enabled`;

/**
 * Setting id for the setting that indicates if a physical location is enabled for a campaign
 * @param physicalLocationId
 * @returns
 */
export const physicalLocationEnabledSettingId = (physicalLocationId: string) =>
  `physical-location#${physicalLocationId}#enabled`;

/**
 * Setting id for the setting that indicates if a service area is enabled for a campaign
 * @param serviceAreaId
 * @returns
 */
export const serviceAreaEnabledSettingId = (serviceAreaId: string) =>
  `service-area#${serviceAreaId}#enabled`;

/**
 * Setting id for the setting that indicates if a service is enabled for a campaign
 * @param serviceId
 * @returns
 */
export const serviceEnabledSettingId = (
  campaignId: string,
  workspaceId: string,
  env: string,
  serviceId: string
) =>
  campaignHighlightEnabledSettingId(
    workspaceId,
    `${campaignId}/${serviceId}`, //Transitional ID. We will mint an ID for this in the future in the campaign service once the campaign highlight object is implemented in the backend
    env
  );

/**
 * Setting id for the setting that indicates if a sub-service is enabled for a campaign
 * @param serviceId
 * @param subServiceId
 * @returns
 */
export const subServiceEnabledSettingId = (
  campaignId: string,
  workspaceId: string,
  env: string,
  serviceId: string,
  subServiceId: string
) =>
  campaignHighlightEnabledSettingId(
    workspaceId,
    `${campaignId}/${serviceId}/${subServiceId}`, //Transitional ID. We will mint an ID for this in the future in the campaign service once the campaign highlight object is implemented in the backend
    env
  );

/**
 * This is a temporary function to construct our transitional setting ID for the campaign highlight enabled setting.
 * This will be removed once the backend is updated with the concept of campaign highlights
 * @param workspaceId
 * @param campaignHighlightId
 * @param env
 * @returns
 */
export const campaignHighlightEnabledSettingId = (
  workspaceId: string,
  campaignHighlightId: string,
  env: string
) => {
  return `"obrn:${env}:${workspaceId}:campaign:campaign/highlight:${campaignHighlightId}/enabled`;
};

/**
 * Checks the settings to see if the given customer profile is enabled for the campaign
 * @param customerProfileId The ID of the customer profile to check for
 * @param settings All Campaign Settings
 * @returns
 */
export const isCustomerProfileEnabledForCampaign = (
  customerProfileId: string,
  settings: Array<CampaignSettingValue>
): boolean =>
  settings.some(
    (setting) =>
      setting.id === customerProfileEnabledSettingId(customerProfileId) &&
      setting.value === true
  );

/**
 * Checks if the given physical location is enabled for the campaign
 * @param physicalLocationId The ID of the physical location to check for
 * @param settings All Campaign Settings
 * @returns
 */
export const isPhysicalLocationEnabledForCampaign = (
  physicalLocationId: string,
  settings: Array<CampaignSettingValue>
): boolean =>
  settings.some(
    (setting) =>
      setting.id === physicalLocationEnabledSettingId(physicalLocationId) &&
      setting.value === true
  );

/**
 * Checks if the given service area is enabled for the campaign
 * @param serviceAreaId The ID of the service area to check for
 * @param settings All Campaign Settings
 * @returns
 */
export const isServiceAreaEnabledForCampaign = (
  serviceAreaId: string,
  settings: Array<CampaignSettingValue>
): boolean =>
  settings.some(
    (setting) =>
      setting.id === serviceAreaEnabledSettingId(serviceAreaId) &&
      setting.value === true
  );

export const findAllServiceEnabledSettings = (
  settings: Array<CampaignSettingValue>
) => {
  //Ends with Enabled?
  //Than Split on /enabled
  //The Remaining portion is an obrn for the object the setting is for
  //Is For a campaign/highlight object
  //getObjectFromObrn(); <- Implement this function in the utils package
  //if the object is a campaign/highlight object than return the setting

  return settings.filter(
    (setting) =>
      setting.id.startsWith('service#') && setting.id.endsWith('#enabled')
  );
};

export const findAllEnabledIsTrueServiceSettings = (
  settings: Array<CampaignSettingValue>
) => {
  return findAllServiceEnabledSettings(settings).filter(
    (setting) => setting.value === true
  );
};

export const findAllCustomerProfileEnabledSettings = (
  settings: Array<CampaignSettingValue>
) => {
  return settings.filter(
    (setting) =>
      setting.id.startsWith('customer-profile#') &&
      setting.id.endsWith('#enabled')
  );
};

export const findAllPhysicalLocationEnabledSettings = (
  settings: Array<CampaignSettingValue>
) => {
  return settings.filter(
    (setting) =>
      setting.id.startsWith('physical-location#') &&
      setting.id.endsWith('#enabled')
  );
};

export const findAllServiceAreaEnabledSettings = (
  settings: Array<CampaignSettingValue>
) => {
  return settings.filter(
    (setting) =>
      setting.id.startsWith('service-area#') && setting.id.endsWith('#enabled')
  );
};

/**
 * Returns all sub-service 'Enabled' settings for a given service (True and False values)
 * @param serviceId
 * @param settings
 * @returns
 */
export const findAllEnabledServiceOfferingSettingsForService = (
  serviceId: string,
  settings: Array<CampaignSettingValue>
) => {
  return settings.filter(
    (setting) =>
      setting.id.startsWith(`sub-service-of-service#${serviceId}`) &&
      setting.id.endsWith('#enabled')
  );
};

/**
 * Returns all sub-service 'Enabled' settings for a given service where the value is true
 * @param serviceId
 * @param settings
 * @returns
 */
export const findAllEnabledIsTrueServiceOfferingSettingsForService = (
  serviceId: string,
  settings: Array<CampaignSettingValue>
) => {
  return findAllEnabledServiceOfferingSettingsForService(
    serviceId,
    settings
  ).filter((setting) => setting.value === true);
};

/**
 * Find the first enabled service setting. Useful for campaign types that expect only one service to be enabled
 * @param settings
 * @returns
 */
export const findFirstEnabledIsTrueServiceSetting = (
  settings: Array<CampaignSettingValue>
): CampaignSettingValue | undefined => {
  return findAllServiceEnabledSettings(settings).find(
    (setting) => setting.value === true
  );
};

export const findFirstEnabledIsTrueCustomerProfileSetting = (
  settings: Array<CampaignSettingValue>
): CampaignSettingValue | undefined => {
  return findAllCustomerProfileEnabledSettings(settings).find(
    (setting) => setting.value === true
  );
};

export const getServiceIdFromServiceSettingId = (settingEnabledId: string) => {
  if (settingEnabledId.startsWith('service#')) {
    return settingEnabledId.split('#')[1];
  } else {
    throw new Error('Setting ID is not a service setting');
  }
};

export const getPhysicalLocationIdFromPhysicalLocationSettingId = (
  physicalLocationId: string
) => {
  if (physicalLocationId.startsWith('physical-location#')) {
    return physicalLocationId.split('#')[1];
  } else {
    throw new Error('Setting ID is not a physical location setting');
  }
};

export const getServiceAreaIdFromServiceAreaSettingId = (
  settingEnabledId: string
) => {
  if (settingEnabledId.startsWith('service-area#')) {
    return settingEnabledId.split('#')[1];
  } else {
    throw new Error(
      `Setting ID "${settingEnabledId}" is not a service area setting`
    );
  }
};

export const getSubServiceAndServiceIdFromServiceSettingId = (
  settingEnabledId: string
) => {
  if (settingEnabledId.startsWith('service#')) {
    return settingEnabledId.split('#')[1];
  } else {
    throw new Error('Setting ID is not a service setting');
  }
};

export const getCustomerProfileIdFromCustomerProfileSettingId = (
  settingId: string
) => {
  if (settingId.startsWith('customer-profile#')) {
    return settingId.split('#')[1];
  } else {
    throw new Error('Setting ID is not a customer profile setting');
  }
};

export const extractServiceAndSubServiceIdsFromSubServiceSettingString = (
  input: string
): { serviceId: string; subServiceId: string } => {
  // Define a regular expression to match the required parts of the input string
  const regex = /sub-service-of-service#(.*?)#sub-service#(.*?)#/;

  // Use the regular expression to match the input string
  const matches = input.match(regex);

  // Check if the matches array is valid and contains at least 3 elements
  // matches[0] is the entire matched string
  // matches[1] is the first capture group (serviceId)
  // matches[2] is the second capture group (subServiceId)
  if (!matches || matches.length < 3) {
    throw new Error('Invalid input format');
  }

  return {
    serviceId: matches[1],
    subServiceId: matches[2],
  };
};
