import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useWindowDimensions } from '../../hooks/use-window-dimensions';

interface OverflowScrollableFullHeightContainerProps {
  /**
   * The amount of px to subtract from the current page height (Account for nav bar height, header height, etc...)
   */
  heightOffset?: number;
  children: ReactNode;
}

/**
 * Creates a Scrollable Container that takes up the height of the page
 * @param heightOffset
 * @param children
 * @constructor
 */
export const OverflowScrollableFullHeightContainer: FC<
  OverflowScrollableFullHeightContainerProps
> = ({ heightOffset = 416, children }) => {
  const dimensions = useWindowDimensions(0, heightOffset);

  return (
    <Box sx={{ height: dimensions.height, width: '100%' }}>{children}</Box>
  );
};
