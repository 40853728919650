import { Deferred } from '@otbnd/utils';
import { AssetDetailsDrawer, useDrawerService } from '@outbound/design-system';
import { AssetResource } from '@outbound/types';
import {
  useFetchAssetById,
  usePatchAssetByIdMutation,
} from '../../../query/asset-manager/use-asset-manager-endpoints';

/**
 * Exposes functionality to open an details drawer for the given asset
 * @returns
 */
export const useAssetDetailDrawerHook = () => {
  const drawerService = useDrawerService();

  const openAssetDetailDrawer = (
    assetId: string,
    /**Optional Callback function that is called if the resource is updated */
    onUpdatedCallback?: (updatedResource: Partial<AssetResource>) => void
  ) => {
    return drawerService.pushDrawer({
      title: 'Photo Details',
      description: 'View and edit photo details',
      drawerContent: (
        <AssetDetailsDrawerWrapper
          assetId={assetId}
          onUpdatedCallback={onUpdatedCallback}
        />
      ),
    });
  };

  return {
    openAssetDetailDrawer,
  };
};

export const AssetDetailsDrawerWrapper = ({
  assetId,
  onUpdatedCallback,
}: {
  assetId: string;
  onUpdatedCallback?: (updatedResource: Partial<AssetResource>) => void;
}) => {
  const { mutateAsync: patchAsset } = usePatchAssetByIdMutation(assetId);
  const { data: assetResource } = useFetchAssetById(assetId);

  return (
    <AssetDetailsDrawer
      assetResource={assetResource}
      onAssetDetailsFormSubmit={(data) => {
        const deferred = new Deferred<void>();

        patchAsset({
          values: {
            description: data.description,
          },
        }).then(
          () => {
            if (onUpdatedCallback) {
              onUpdatedCallback({
                description: data.description,
              });
              deferred.resolve();
            }
          },
          () => {
            deferred.reject();
          }
        );

        return deferred.promise;
      }}
    />
  );
};
