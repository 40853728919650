import { ServiceAreaType } from '@outbound/types';

export const physicalLocationLabel: string = 'Physical Location';

export const lookupDescriptionForServiceAreaType = (
  type: ServiceAreaType
): string => {
  switch (type) {
    case 'DRIVE_TIME':
      return 'By Drive Time';
    case 'POSTAL_CODE':
      return 'By Zip Code';
    case 'STATE':
      return 'By State';
    case 'COUNTY':
      return 'By County';
    case 'COUNTRY':
      return 'By Country';
    case 'DRIVE_DISTANCE':
      return 'By Drive Distance';
    default:
      return 'Unknown';
  }
};
