import {
  AddressResource,
  CreateServiceAreaResourceRequest,
} from '@outbound/types';
import ServiceAreaLocation from '../../../../../../state/mobx-experiment/business-context/location/service-area/service-area';

export interface ServiceAreaFormValues {
  name: string;
  description: string;
  serviceAreaType:
    | 'POSTAL_CODE'
    | 'COUNTY'
    | 'STATE'
    | 'COUNTRY'
    | 'DRIVE_DISTANCE'
    | 'DRIVE_TIME';
  /**
   * The countries that this service area covers.
   * United States, Canada, etc.
   *
   * Only Populated if the service area type is COUNTRY
   */
  countries: Array<string>;
  /**
   * The states  that this service area covers.
   *
   * Only Populated if the service area type is STATE
   */
  states: Array<string>;
  /**
   * The counties that this service area covers.
   *
   * Only Populated if the service area type is COUNTY
   */
  counties: Array<string>;
  /**
   * The zip codes that this service area covers.
   *
   * Only Populated if the service area type is ZIP_CODE
   */
  zipCodes: Array<string>;
  driveDistance: number;
  driveTime: number;
  address: AddressResource;
}

export const mapFormValuesToApiResource = (
  values: ServiceAreaFormValues
): CreateServiceAreaResourceRequest => {
  console.log('values', values);
  return {
    name: values.name,
    description: values.description,
    serviceAreaType: values.serviceAreaType,
    countries: values?.countries ?? [],
    states: values?.states ?? [],
    postalCodes: values?.zipCodes ?? [],
    counties: values?.counties ?? [],
    driveDistanceMiles: values?.driveDistance,
    driveTimeMinutes: values?.driveTime,
    address: values.address,
    type: 'SERVICE_AREA',
  };
};

/**
 * Converts a ServiceAreaResource to a shape that can be used to populate the create / edit service area form
 * @param resource
 * @returns
 */
export const mapApiResponseToFormValues = (
  resource: ServiceAreaLocation
): ServiceAreaFormValues => {
  return {
    name: resource.name,
    description: resource.description ?? '',
    serviceAreaType: resource.serviceAreaType,
    countries: resource?.countries ?? [],
    zipCodes: resource?.postalCodes ?? [],
    states: resource?.states ?? [],
    counties: resource?.counties ?? [],
    // Min value for drive distance is 1
    driveDistance: resource?.driveDistanceMiles ?? 1,
    // Min value for drive time is 1
    driveTime: resource?.driveTimeMinutes ?? 1,
    address: {
      city: resource.city ?? '',
      state: resource.state ?? '',
      street1: resource.addressLine1 ?? '',
      street2: resource.addressLine2 ?? '',
      country: resource.country ?? '',
      postalCode: resource.postalCode ?? '',
    },
  };
};
