import { useState, useEffect } from 'react';
import { useFetchOnboardingByIdAsAuthenticatedUser } from '../../query/onboarding/use-onboarding-endpoints';
import { useParams, Navigate } from 'react-router-dom';
import {
  BrandOnboardingResource,
  BrandMarketingGoalsType,
} from '@outbound/types';
import { FullPageLoadingSpinner } from '../../components/layouts/full-page-loading-spinner';

type ResumeOnboardingRouteParams = {
  onboardingId: string;
};

/**
 * When the user is going through the onboarding process they choose what they want to do first,
 */
type OnboardingFirstAction = {
  //What the user choose to setup first
  type: 'CAMPAIGN' | 'PLAYBOOK'; //We will add SALES_ASSET once we have created that
  //The id of object created during onboarding that we will redirect to
  id: string;
};

//Push this back up into the backend as other areas of the onboarding process will need this decision as well.
export const getFirstActionFromOnboarding = (
  onboarding: BrandOnboardingResource
): OnboardingFirstAction => {
  const campaignGoals: string[] = Object.values(BrandMarketingGoalsType);
  const selectedGoal = onboarding?.steps?.goalSelection?.goals[0];
  if (selectedGoal != null && campaignGoals.includes(selectedGoal.goal)) {
    return {
      type: 'CAMPAIGN',
      id: selectedGoal.goalId!,
    };
  } else {
    return {
      type: 'PLAYBOOK',
      id: 'playbook',
    };
  }
};

/**
 * Intermediary redirect that will pull the onboarding from the API by it's ID and
 * determine what type of onboarding is being performed and where the user should be redirected to
 * @returns
 */
export const ResumeOnboardingRedirect = () => {
  const { onboardingId } = useParams<ResumeOnboardingRouteParams>();
  const [firstAction, setFirstAction] = useState<OnboardingFirstAction>();

  const { data, isSuccess, isLoading } =
    useFetchOnboardingByIdAsAuthenticatedUser(onboardingId);

  useEffect(() => {
    if (isSuccess && data) {
      const firstAction = getFirstActionFromOnboarding(data);
      setFirstAction(firstAction);
    }
  }, [data, isSuccess]);

  if (isLoading || !firstAction) {
    return <FullPageLoadingSpinner />;
  }
  if (firstAction?.type === 'CAMPAIGN') {
    const redirectToCampaignWizardPath = `/campaign-wizard/${firstAction?.id}`;
    return <Navigate to={redirectToCampaignWizardPath} />;
  }
  if (firstAction?.type === 'PLAYBOOK') {
    const playbookPath = `/dashboard/playbook`;
    return <Navigate to={playbookPath} />;
  }
  return <Navigate to={'/'} />;
};
