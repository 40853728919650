import { Grid } from '@mui/material';
import { NoContentCta } from '../no-content-cta/no-content-cta';

interface GridNoRowsOverlayProps {
  heading: string;
  subHeading: string;
  ctaPrimaryButtonLabel?: string;
  ctaPrimaryButtonCallback?: () => void;
  ctaSecondaryButtonLabel?: string;
  ctaSecondaryButtonCallback?: () => void;
}

/**
 * Wrapper Component to use the No Content CTA as a No Rows Overlay in MUI.
 * Using this component vs using the NoContentCta directly provides the following benefits.
 * - Provides opportunity to standardize the No Rows experience across all data-grids
 * - Contains a fix for a zIndex issue on data-grid virtual scroll (See Comment on sx prop).

 * @param heading
 * @param subHeading
 * @param ctaPrimaryButtonLabel
 * @param ctaPrimaryButtonCallback
 * @param ctaSecondaryButtonLabel
 * @param ctaSecondaryButtonCallback
 * @constructor
 */
export const ObDataGridOverlayNoRows = ({
  heading,
  subHeading,
  ctaPrimaryButtonLabel,
  ctaPrimaryButtonCallback,
  ctaSecondaryButtonLabel,
  ctaSecondaryButtonCallback,
}: GridNoRowsOverlayProps) => {
  return (
    <Grid
      data-testid={'ob-data-grid-overlay-no-rows'}
      sx={{
        /*Fill the parent's height to center this grid's content vertically in the parent*/
        height: '100%',
        /* [Start] Workaround for https://github.com/mui/mui-x/issues/5725
         *    This should be fixed in MUI V6/
         *    When we upgrade we can retest and potentially remove this */
        position: 'relative',
        zIndex: 1,
        /* [End] Workaround for https://github.com/mui/mui-x/issues/5725*/
      }}
    >
      <NoContentCta
        heading={heading}
        subHeading={subHeading}
        /* Standard icon for no data so we have consistency across data-grids*/
        icon={'noData'}
        ctaPrimaryButtonLabel={ctaPrimaryButtonLabel}
        ctaPrimaryButtonCallback={ctaPrimaryButtonCallback}
        ctaSecondaryButtonLabel={ctaSecondaryButtonLabel}
        ctaSecondaryButtonCallback={ctaSecondaryButtonCallback}
      />
    </Grid>
  );
};
