import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { AvatarComponent } from '../../../../avatars/avatar.types';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { ObIcon } from '../../../../tokens/icons/ob-icon/ob-icon';

export interface ObSelectedOptionProps {
  /**
   * The id of the label that describes the value selected option
   */
  id: string;
  /**
   * What should be displayed as the selected option to the user
   */
  value: string;

  /**
   * Secondary information about the selected option that will appear to the right of the value container
   */
  description?: string;

  /**
   * Avatar like Content that shows between the check icon and the label.
   * Can be any of Avatar, Channel Avatar or Icon Avatar
   */
  avatarContent?: AvatarComponent;
}

export const ObSelectedOption: FC<ObSelectedOptionProps> = ({
  id,
  value,
  description,
  avatarContent,
}) => {
  return (
    <div className='flex flex-row items-center gap-2'>
      <div
        className={cx(
          'flex flex-row flex-nowrap items-center justify-start gap-2',
          'pl-2 pr-3 py-1 h-[30px]',
          'dark:bg-dark/action/primary/on-subtle',
          'rounded-2xl',
          'border dark:border-dark/border/default/normal',
          ''
        )}
      >
        <ObIcon
          icon='nominal'
          color='primary'
          size='small'
        />
        {avatarContent && <div className='pl-1'>{avatarContent}</div>}
        <ObTypography
          aria-labelledby={id}
          aria-readonly='true'
          className='mb-[2px] line-clamp-1'
          variant='body2'
          as='span'
        >
          {value}
        </ObTypography>
      </div>
      {description && (
        <ObTypography
          aria-labelledby={id}
          aria-readonly='true'
          className='mb-[2px] line-clamp-1'
          variant='body2'
          color='secondary'
          as='span'
        >
          {description}
        </ObTypography>
      )}
    </div>
  );
};
