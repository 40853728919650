import { useCallback, useMemo } from 'react';
import { OnboardingQuestion } from '../questionnaire.type';

import { CheckboxGroupCardOption } from '@outbound/design-system/src/components/molecules/ob-card-option-checkbox-group/ob-card-option-checkbox-group.types';
import {
  useFetchBusinessDetails,
  usePatchBusinessDetails,
} from '../../../../query/playbook/use-playbook-settings-business-details-endpoints';

export const useProductsActivitiesQuestion = (
  questions: Array<OnboardingQuestion>
) => {
  const QUESTION_ID = 'product-location';

  //Grab the Industry Question from the Question Data

  const question = questions.find((q) => q.id === QUESTION_ID);

  const { refetch: refetchBusinessDetails } = useFetchBusinessDetails();

  const { mutateAsync: patchBusinessDetails } = usePatchBusinessDetails();

  /**
   * Function that will persist the business name question
   * @param answers
   */
  const updateProductAndServiceBooleans = useCallback(
    async (answers: { [key: string]: any }) => {
      const productActivityAnswer: Array<CheckboxGroupCardOption> =
        answers[`${QUESTION_ID}`]; //Lookup our answers by question id

      if (productActivityAnswer == undefined) {
        throw new Error('Answers cannot be defined');
      }
      try {
        const retailBusinessActivities = {
          isRetailInStore: productActivityAnswer.some(
            (i) => i.value === 'IN_STORE_RETAIL'
          ),
          isRetailOnline: productActivityAnswer.some(
            (i) => i.value === 'ONLINE_RETAIL'
          ),
        };

        const businessActivities = {
          ...retailBusinessActivities, //Set our retail business activities to the latest values
        };

        /**
         * Spread data from current call so we do not overwrite other data
         */
        const content = {
          businessActivities: businessActivities,
        };
        return patchBusinessDetails({ values: content });
      } catch (error) {
        console.error(error);
      }
    },
    [QUESTION_ID, patchBusinessDetails]
  );

  useMemo(() => {
    question!.fetchServerState = () => {
      return new Promise((resolve) => {
        refetchBusinessDetails().then((success: any) => {
          const businessActivities = success.data.businessActivities || '';

          const selectedOptions: Array<CheckboxGroupCardOption> = [];

          /**
           * Check for the in retail store option
           */
          if (businessActivities.isRetailInStore) {
            selectedOptions.push({
              key: 'IN_STORE_RETAIL',
              value: 'IN_STORE_RETAIL',
              title: 'In Physical Stores',
              displayValue: 'In Physical Stores',
            });
          }

          /**
           * Check for the in retail online option
           */
          if (businessActivities.isRetailOnline) {
            selectedOptions.push({
              key: 'ONLINE_RETAIL',
              value: 'ONLINE_RETAIL',
              displayValue: 'Online',
              title: 'Online',
            });
          }

          resolve(selectedOptions);
        });
      });
    };

    question!.submitServerState = updateProductAndServiceBooleans;
  }, [question, refetchBusinessDetails, updateProductAndServiceBooleans]);

  return {};
};
