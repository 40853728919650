import {
  ObDeleteCard,
  ObFormRenderer,
  useDrawerService,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { usePatchPhysicalLocationById } from '../../../../../../query/playbook/use-playbook-settings-location-endpoints';
import PhysicalLocation from '../../../../../../state/mobx-experiment/business-context/location/physical-location/physical-location';
import { useRootStore } from '../../../../../../state/mobx-experiment/use-root-store';
import { PlaybookDrawerContent } from '../../_common/playbook-drawer-content';
import { useDrawerEditForm } from '../../_common/use-drawer-edit-form';
import {
  mapApiResourceToFormValues,
  mapFormValuesToApiResource,
} from '../forms/brick-and-mortar-form-utils';
import { BRICK_AND_MORTAR_FORM_DEF } from '../forms/brick-and-mortar-form.def';

export interface BrickAndMortarEditDrawerProps {
  /**
   * The ID of the Physical location to load in the drawer
   */
  physicalLocationId: string;
}
export const BrickAndMortarEditDrawer = observer(
  ({ physicalLocationId }: BrickAndMortarEditDrawerProps) => {
    const {
      getRegisteredFormById,
      initialValues,
      setInitialValues,
      onFormDirtyChange,
      isInitialValuesFetched,
      setIsInitialValuesFetched,
      areChangesUnsaved,
      notificationService,
      drawerService,
    } = useDrawerEditForm();

    const { locationStore } = useRootStore();
    const location = locationStore.getById(physicalLocationId);

    const { mutateAsync: patchPhysicalLocation } =
      usePatchPhysicalLocationById(physicalLocationId);

    /**
     * Side Effect run to initialize the form
     */
    useEffect(() => {
      if (location) {
        setInitialValues(
          mapApiResourceToFormValues(location as PhysicalLocation)
        );
        setIsInitialValuesFetched(true);
      } else {
        throw new Error('Physical Location not found');
      }
      /**
       * When deleting a physical location, it triggers a re-render of the component
       * and we need to make sure that we don't re-fetch the initial values
       */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setInitialValues, setIsInitialValuesFetched]);

    /**
     * Will need to convert patching to the Location Mobx store
     */
    const handleUpdateServiceArea = useCallback(
      async (values: any) => {
        const result = await patchPhysicalLocation({
          values: mapFormValuesToApiResource(values),
        });

        return result;
      },
      [patchPhysicalLocation]
    );

    const handleDeleteBrickAndMortarLocation = useCallback(async () => {
      if (location == null) {
        return;
      }
      try {
        location.delete();
        /**
         * After we delete the physical location we want to close the drawer.
         */
        drawerService.popDrawer();

        notificationService.pushNotification({
          title: 'Brick and Mortar Location Deleted',
          body: 'The location has been deleted successfully.',
        });
      } catch (error) {}
      /**
       * When deleting a physical location, it triggers a re-render of the component
       * and we need to make sure that we don't re-fetch the initial values
       */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerService, notificationService]);

    return (
      <PlaybookDrawerContent
        primaryActionLabel={'Save'}
        primaryActionCallback={async () => {
          return getRegisteredFormById(
            BRICK_AND_MORTAR_FORM_DEF.id
          )?.submitFunction();
        }}
        secondaryActionLabel={'Discard'}
        secondaryActionCallback={async () => {
          return getRegisteredFormById(
            BRICK_AND_MORTAR_FORM_DEF.id
          )?.discardChangesFunction();
        }}
        showFooter={areChangesUnsaved}
      >
        {isInitialValuesFetched && (
          <div className='flex flex-col justify-between flex-1'>
            <ObFormRenderer
              formDefinition={BRICK_AND_MORTAR_FORM_DEF}
              hideSubmitButton={true}
              defaultValues={initialValues}
              onSubmitCallback={handleUpdateServiceArea}
              onFormDirtyChangeCallback={onFormDirtyChange}
            />
            <ObDeleteCard
              typeToConfirmValue={initialValues.name}
              isLoading={false}
              cardTitle={'Delete Location'}
              cardBody={
                "Deleting a brick & mortar location will delete all of it's associated data and you will no longer be able to use it to target campaigns."
              }
              cardButtonLabel={'Delete'}
              confirmationDialogTitle={'Delete Brick & Mortar Location'}
              confirmationDialogBody={
                'This action is permanent and cannot be undone.'
              }
              typeToConfirmInstructions={
                'Type the name of the brick and mortar location to confirm'
              }
              onDeleteConfirmedCallback={handleDeleteBrickAndMortarLocation}
            />
          </div>
        )}
      </PlaybookDrawerContent>
    );
  }
);

export const useBrickAndMortarEditDrawer = () => {
  const drawerService = useDrawerService();
  /**
   * Opens the drawer that allows the user to view, edit or delete a service area
   * @param serviceAreaId
   */
  const openEditBrickAndMortarLocationDrawer = (physicalLocationId: string) => {
    drawerService.pushDrawer({
      title: 'Edit Brick and Mortar Location',
      description: 'Edit the information below to update a location.',
      drawerContent: (
        <BrickAndMortarEditDrawer physicalLocationId={physicalLocationId} />
      ),
      size: 'medium',
    });
  };

  return {
    openEditBrickAndMortarLocationDrawer,
  };
};
