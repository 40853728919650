import { ObLoadingSpinner, ObTypography } from '@outbound/design-system';
import { ScreenshotResource } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { FC, ReactNode } from 'react';
import './website-scanner-step.css';
interface GradientScannerProps {
  children: ReactNode;
}

const GradientScanner: FC<GradientScannerProps> = ({ children }) => {
  return (
    <div
      className=''
      data-testid='scanner-line'
    >
      {children}
      <div className='scanner-line'></div>
    </div>
  );
};

interface WebsiteScannerStepProps {
  screenshot?: ScreenshotResource;
}

export const WebsiteScannerStep = ({ screenshot }: WebsiteScannerStepProps) => {
  return (
    <div
      data-testid='website-scanner-step'
      className='flex flex-col gap-8 items-center justify-center h-full p-8'
    >
      <ObTypography variant='h1'>Scanning your site</ObTypography>
      <ObTypography
        variant='h3'
        color='secondary'
        className='text-center'
      >
        You are moments away from launching your marketing
      </ObTypography>
      <div
        className='mx-auto w-full'
        style={{ maxWidth: '800px' }}
      >
        <div
          style={{ paddingBottom: 'calc(100% / (16 / 9))' }}
          className={cx(
            'relative rounded-2xl aspect-auto outline-[12px] sm:outline-[16px] outline-[rgba(50,50,50,0.7)] outline shadow-[0_25px_120px_-15px_rgba(0,0,0,0.1)] shadow-actionPrimaryLight overflow-clip mt-4'
          )}
        >
          {/* 
            We are setting a max height and width to the image to prevent it from overflowing the container.
             */}
          <div
            className={cx(
              'transition-opacity duration-700 delay-500',
              screenshot?.publicUrl ? 'opacity-100' : 'opacity-0'
            )}
          >
            {screenshot?.publicUrl && (
              <GradientScanner>
                <img
                  className={cx('absolute object-contain')}
                  data-testid='site-screenshot'
                  src={screenshot?.publicUrl}
                  alt='site screenshot'
                />
              </GradientScanner>
            )}
          </div>
          {
            <div
              className={cx(
                'w-full h-full absolute flex items-center justify-center duration-400 delay-300 transition-opacity',
                screenshot?.publicUrl ? 'opacity-0' : 'opacity-100'
              )}
            >
              <ObLoadingSpinner includeBrand={true} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
