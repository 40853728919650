export const isInvitePresentInURLSearchParams = (
  queryParams: URLSearchParams
): boolean => {
  const { organization, invitation } = getInviteSearchParams(queryParams);
  return (
    invitation != null &&
    invitation.trim().length > 0 &&
    organization != null &&
    organization.trim().length > 0
  );
};

type Auth0InviteParameters = {
  organization: string;
  invitation: string;
};
export const getInviteSearchParams = (
  queryParams: URLSearchParams
): Auth0InviteParameters => {
  const org = queryParams.get('organization') || '';
  const invite = queryParams.get('invitation') || '';
  return {
    organization: org,
    invitation: invite,
  };
};
