import {
  ObFormRenderer,
  FormDefinition,
  FormFieldType,
  FormSectionDefinition,
} from '@outbound/design-system';
import { Box } from '@mui/material';
import gql from 'graphql-tag';
import { useMutation } from 'urql';

const COMPLETE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfile($givenName: String, $familyName: String) {
    updateUserProfile(familyName: $familyName, givenName: $givenName) {
      ... on User {
        __typename
        familyName
        givenName
      }
    }
  }
`;

const COMPLETE_USER_PROFILE_FORM_DEFINITION: FormDefinition = {
  id: 'company-name',
  heading: 'What is your name?',
  subHeading: '',
  sections: [
    {
      id: 'section1',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'givenName',
          label: 'First Name',
          helperText: '',
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
            minLength: 1,
            maxLength: 150,
          },
          hideLabel: false,
          hideHelperText: false,
          autofocus: true,
        },
        {
          id: 'familyName',
          label: 'Last Name',
          helperText: '',
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
            minLength: 1,
            maxLength: 150,
          },
          hideLabel: false,
          hideHelperText: true,
          autofocus: false,
        },
      ],
    } as FormSectionDefinition,
  ],
};

const INITIAL_FORM_STATE = { givenName: '', familyName: '' };

const CompleteUserProfile = () => {
  const [, executeCompleteUserProfileMutation] = useMutation(
    COMPLETE_USER_PROFILE_MUTATION
  );

  const onSubmit = async (data: any): Promise<any> => {
    return await executeCompleteUserProfileMutation({
      givenName: data.givenName,
      familyName: data.familyName,
    });
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ObFormRenderer
          formDefinition={COMPLETE_USER_PROFILE_FORM_DEFINITION}
          defaultValues={INITIAL_FORM_STATE}
          onSubmitCallback={onSubmit}
          submitButtonLabel={'Next Step'}
        />
      </Box>
    </>
  );
};

export default CompleteUserProfile;
