import { createContext, useCallback, useContext, useMemo, useRef } from 'react';

/**
 * Describes the form object that is registered with the form context
 */
interface ObFormRendererForm {
  /**
   * A unique identifier for the form
   */
  formId: string;
  /**
   * A function that a consumer can call to submit the form
   */
  submitFunction: () => void;
  /**
   * A function that a consumer can call to discard changes in the form
   */
  discardChangesFunction: () => void;
}

/**
 * Describes the context value that is exposed to consumers
 */
interface ObFormRendererContextValue {
  /**
   * Only the form renderer should call this function.
   * It is used to register a form with the global form context.
   * @param formId
   * @param submitForm
   * @param resetFormToDefaultFunction
   * @returns
   */
  registerForm: (
    formId: string,
    submitForm: () => void,
    discardChangesFunction: () => void
  ) => void;
  unregisterForm: (formId: string) => void;
  getRegisteredFormById: (formId: string) => ObFormRendererForm | undefined;
}

/**
 * Allows the Form Renderer to register and unregister forms
 * with the global form context. We use this to expose some basic
 * form functionality to other components.
 *
 * Before modifying this context keep in mind that it is managing state in a way that
 * doesn't not trigger re-renders in the components that are using it. This works for
 * our use-case based on the way the fact that nothing exposed by this context is used
 * in a way that would need to trigger UI updates. If you have a need to trigger UI updates
 * it would be best to implement a callback function on the form renderer itself vs using this context.
 * (See the `onFormDirtyChangeCallback` callback in the form renderer for an example)
 *
 * Alternatively, we considered using imperativeHandle and forwardRef
 * to expose the form functionality to the parent component, but went with this approach
 * for maximum flexibility and to avoid the noise of imperativeHandle and forwardRef in the
 * form renderer component.
 *
 */
const ObFormRendererContext = createContext<
  ObFormRendererContextValue | undefined
>(undefined);

interface ObFormRendererProviderProps {
  children: React.ReactNode;
}

type RegisteredFormsStorage = { [key: string]: ObFormRendererForm };

export const ObFormRendererProvider: React.FC<ObFormRendererProviderProps> = ({
  children,
}: ObFormRendererProviderProps) => {
  /**
   * Initialize the registered forms state in a ref;
   * We will use this ref to avoid re-renders when updating the state
   */
  const registeredFormsRef = useRef<RegisteredFormsStorage>({});

  /**
   * Registers a form with the global form context
   */
  const registerForm = useCallback(
    (
      formId: string,
      submitFunction: () => void,
      discardChangesFunction: () => void
    ) => {
      if (!registeredFormsRef.current[formId]) {
        registeredFormsRef.current[formId] = {
          formId,
          submitFunction,
          discardChangesFunction,
        };
      }
    },
    []
  );

  /**
   * Removes a form from the global form context
   */
  const unregisterForm = useCallback((formId: string) => {
    if (registeredFormsRef.current[formId]) {
      delete registeredFormsRef.current[formId];
    }
  }, []);

  /**
   * Retrieves a registered form by its formId
   */
  const getRegisteredFormById = useCallback((formId: string) => {
    return registeredFormsRef.current[formId];
  }, []);

  const value = useMemo(
    () => ({
      registerForm,
      unregisterForm,
      getRegisteredFormById,
    }),
    [registerForm, unregisterForm, getRegisteredFormById]
  );

  return (
    <ObFormRendererContext.Provider value={value}>
      {children}
    </ObFormRendererContext.Provider>
  );
};

export const useObFormRendererContext = () => {
  const context = useContext(ObFormRendererContext);
  if (!context) {
    throw new Error(
      'useObFormRendererContext must be used within a ObFormRendererContextProvider'
    );
  }
  return context;
};
