import { ObAvatar } from '../../avatars';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantProps } from '../card.type';

export interface UserCardProps extends CardVariantProps {
  name: string;
  emailAddress: string;
  profileImageUrl?: string;
  affiliation?: string;
  affiliationImageUrl?: string;
  onNavigateCallback?: () => void;
}

type UserCardImplementationProps = Omit<UserCardProps, 'variant'>;

export const UserCard = ({ variant, ...props }: UserCardProps) => {
  switch (variant) {
    case 'micro':
      return <MicroCard {...props} />;
    case 'mini':
      return <MiniCard {...props} />;
    case 'grid':
    case 'table':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};

const MiniCard = ({
  name,
  emailAddress,
  profileImageUrl,
  affiliation,
  affiliationImageUrl,
}: UserCardImplementationProps) => {
  return (
    <div className='flex gap-4 items-center'>
      <ObAvatar
        size={'medium'}
        shape={'circle'}
        profileImageUrl={profileImageUrl}
        fallbackText={`${name} profile image`}
      />
      <div className='flex flex-1 flex-col'>
        <ObTypography
          variant='subtitle1'
          className='text-left'
        >
          {name}
        </ObTypography>

        <div className='flex gap-2'>
          {affiliationImageUrl && (
            <ObAvatar
              size='small'
              shape='rounded'
              profileImageUrl={affiliationImageUrl}
              fallbackText={affiliation}
            />
          )}
          {emailAddress && (
            <ObTypography
              className='dark:text-dark/content/secondary text-light/content/secondary'
              variant='subtitle3'
            >
              {emailAddress}
            </ObTypography>
          )}
        </div>
      </div>
    </div>
  );
};

const MicroCard = ({ name, profileImageUrl }: UserCardImplementationProps) => {
  return (
    <div className='flex flex-row flex-nowrap gap-1'>
      <ObAvatar
        size='xx-small'
        fallbackText={`${name} profile image`}
        profileImageUrl={profileImageUrl}
      />
      <ObTypography
        variant='subtitle2'
        className='text-left'
      >
        {name}
      </ObTypography>
    </div>
  );
};
