'use client';
import { Fragment, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ObRule } from './components/ob-rule';
import { RuleAndConnector } from './components/rule-add-connector';
import { AddNodeButton } from './components/rule-add-new-button';

import {
  RuleBuilderLogicalOperator,
  RuleBuilderSchema,
  TargetingGroup,
  TargetingNode,
} from '@outbound/types';
import { StateManagedByParentInputValueOnly } from '../../../base-component-props.type';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import {
  addChildNodeToExistingNode,
  removeNodeFromTree,
  updateNodeInTree,
} from './ob-rule-builder-utils';
import { TargetingConditionForFrontend } from './ob-rule-builder.types';

export interface ObRuleBuilderProps
  extends StateManagedByParentInputValueOnly<TargetingGroup> {
  isAdvancedModeEnabled: boolean;
  isDisabled?: boolean;
  /**
   * Describes the fields that are available for targeting by
   * this rule builder
   */
  schema: RuleBuilderSchema;
}

export const ObRuleBuilder = ({
  isAdvancedModeEnabled,
  schema,
  value,
  onValueChangedCallback: onValueUpdatedCallback,
  isDisabled = false,
}: ObRuleBuilderProps): any => {
  /**
   * Used to communicate to the parent that a node has been updated
   */
  const onNodeUpdatedCallback = useCallback(
    (node: TargetingNode | TargetingConditionForFrontend) => {
      const nextNode = updateNodeInTree(value, node);
      if (nextNode) {
        onValueUpdatedCallback(nextNode);
      }
    },
    [onValueUpdatedCallback, value]
  );

  /**
   * Used to delete a node and all of it's child nodes
   */
  const onNodeDeletedCallback = useCallback(
    (nodeId: string) => {
      const nextNode = removeNodeFromTree(value, nodeId);
      if (nextNode) {
        onValueUpdatedCallback(nextNode);
      }
    },
    [onValueUpdatedCallback, value]
  );

  /**
   * Used when we are adding a new Rule to an existing group (Including the root group)
   */
  const onRuleNodeAdded = useCallback(
    (nodeId: string) => {
      const newRule: TargetingConditionForFrontend = {
        nodeId: uuidv4(),
        fieldKey: undefined,
        operator: undefined,
        operationValue: undefined,
      };
      const nextNode = addChildNodeToExistingNode(value, nodeId, newRule);
      if (nextNode) {
        onValueUpdatedCallback(nextNode);
      }
    },
    [onValueUpdatedCallback, value]
  );

  /**
   * Future Use for adding nested AND / OR nodes
   */
  const onGroupNodeAdded = useCallback(
    (nodeId: string) => {
      const newGroup: TargetingGroup = {
        nodeId: uuidv4(),
        operator: RuleBuilderLogicalOperator.AND,
        children: [],
      };
      const nextNode = addChildNodeToExistingNode(value, nodeId, newGroup);
      if (nextNode) {
        onValueUpdatedCallback(nextNode);
      }
    },
    [onValueUpdatedCallback, value]
  );

  return (
    <NodeGroup
      schema={schema}
      nodeGroup={value}
      isAdvancedModeEnabled={isAdvancedModeEnabled}
      isDisabled={isDisabled}
      onNodeDeletedCallback={onNodeDeletedCallback}
      onNodeUpdatedCallback={onNodeUpdatedCallback}
      onGroupNodeAdded={onGroupNodeAdded}
      onRuleNodeAdded={onRuleNodeAdded}
    />
  );
};

interface NodeGroupProps {
  isAdvancedModeEnabled: boolean;
  isDisabled?: boolean;
  /**
   * Describes the fields that are available for targeting by
   * this rule builder
   */
  schema: RuleBuilderSchema;
  /**
   * The specific Node Group to Render. May render children Node Groups or Rules
   */
  nodeGroup: TargetingGroup;
  onNodeDeletedCallback: (id: string) => void;
  onNodeUpdatedCallback: (
    node: TargetingNode | TargetingConditionForFrontend
  ) => void;
  onGroupNodeAdded: (nodeId: string) => void;
  onRuleNodeAdded: (nodeId: string) => void;
}

const NodeGroup = ({
  isAdvancedModeEnabled,
  isDisabled = false,
  schema,
  nodeGroup,
  onNodeDeletedCallback,
  onNodeUpdatedCallback,
  onGroupNodeAdded,
  onRuleNodeAdded,
}: NodeGroupProps) => {
  return (
    <div className='flex flex-col justify-center items-center p-2 w-full gap-0.5'>
      {nodeGroup.children.length === 0 && (
        <ObTypography
          variant='subtitle3'
          color={isDisabled ? 'secondary' : 'primary'}
        >
          {isDisabled
            ? 'No demographics provided' //Currently the only use-case for this component is for demographics. If this changes, this should be made a prop.
            : 'Add a Rule to Get Started'}
        </ObTypography>
      )}
      {nodeGroup.children.map((node: TargetingNode, i: number) => {
        switch (node.operator) {
          case 'AND':
          case 'OR': {
            return (
              <NodeGroup
                key={node.nodeId}
                isAdvancedModeEnabled={isAdvancedModeEnabled}
                nodeGroup={node}
                schema={schema}
                isDisabled={isDisabled}
                onNodeDeletedCallback={onNodeDeletedCallback}
                onNodeUpdatedCallback={onNodeUpdatedCallback}
                onGroupNodeAdded={onGroupNodeAdded}
                onRuleNodeAdded={onRuleNodeAdded}
              />
            );
          }
          default: {
            /** All Other node types have children and are rendered as rules */
            //FUTURE: HANDLE NOT NODES WHEN SOMEONE COMPLAINS ABOUT NEGATION
            const nodeWithChildren = node as TargetingConditionForFrontend;
            return (
              <Fragment key={node.nodeId}>
                {i > 0 && (
                  <div className='relative'>
                    <RuleAndConnector />
                  </div>
                )}
                <ObRule
                  schema={schema}
                  value={nodeWithChildren}
                  onDeletedCallback={onNodeDeletedCallback}
                  onValueUpdatedCallback={onNodeUpdatedCallback}
                  isDisabled={isDisabled}
                />
              </Fragment>
            );
          }
        }
      })}

      {!isDisabled && (
        <div className='flex flex-row justify-center items-center gap-2'>
          <AddNodeButton
            nodeType='rule'
            onAdd={() => onRuleNodeAdded(nodeGroup.nodeId)}
          />
          {isAdvancedModeEnabled && (
            <AddNodeButton
              nodeType='group'
              onAdd={() => onGroupNodeAdded(nodeGroup.nodeId)}
            />
          )}
        </div>
      )}
    </div>
  );
};
