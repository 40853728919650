import { useCallback, useState } from 'react';

/**
 * Simple hook to handle switch state
 */
export const useSwitch = (initialState: boolean): [boolean, () => void] => {
  const [isOn, setSwitch] = useState(initialState);

  const toggleSwitch = useCallback((): void => {
    setSwitch(!isOn);
  }, [isOn]);

  return [isOn, toggleSwitch];
};
