import { FullPageErrorMessage } from './full-page-error-message';

export const ErrorFallback: React.FC<{
  error: Error;
  resetErrorBoundary: () => void;
}> = () => {
  return (
    <FullPageErrorMessage
      heading={'An Error Occurred'}
      message={
        'Something went wrong when loading the page. We apologize for the inconvenience and our team has been notified. If the issue persists please contact us. In order to continue please Reload the Page.'
      }
      actionLabel='Reload Page'
      actionOnClick={() => window.location.reload()}
    />
  );
};
