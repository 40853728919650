import {
  IntegrationConfigurationType,
  Plan,
  ServerDelta,
} from '@outbound/types';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../base-model';
import LandingPage from '../landing-page/landing-page';
import { RootStore } from '../root-store';
import Membership from './membership/membership';
import { IntegrationXWorkspace } from './types/integration-x-workspace.type';

/**
 * The root object for a customers outbound experience.
 * A business should have one workspace.
 *
 * An agency can be linked to multiple workspaces which they manage on behalf of their clients.
 * Outbound operates as an agency for our customers.
 *
 *
 */
class Workspace extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    name: '/name',
  };

  private _name: string;
  private _plan: Plan;
  private readonly _auth0OrganizationId: string;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    name: string,
    auth0OrganizationId: string
  ) {
    super(rootStore, 'workspace', '1', id, id, obrn);
    this._name = name;
    this._auth0OrganizationId = auth0OrganizationId;
    this._plan = 'FREE'; //TEMPORARILY HARD CODED TILL WE LAUNCH PRO / ENTERPRISE. This will come from the API
  }

  /**
   * Apply Updates to the Model from the Server
   * @param patch
   */
  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      for (const operation of patch) {
        switch (operation.path) {
          case Workspace.paths.name: {
            switch (operation.op) {
              case 'replace': {
                this._name = operation.value;
              }
            }
            break;
          }
        }
      }
    });
  }
  toJson(): Record<string, any> {
    return {
      id: this.id,
      obrn: this.obrn,
      name: this._name,
      auth0OrganizationId: this._auth0OrganizationId,
    };
  }
  protected makeObservableInternal(): void {
    makeObservable(this, {
      _name: observable,
      getIntegrationsWithConfiguration: observable,
    } as any);
  }

  get name(): string {
    return this._name;
  }

  get auth0OrganizationId(): string {
    return this._auth0OrganizationId;
  }

  /**
   * Return a list of memberships for this workspace
   */
  get memberships(): Array<Membership> {
    return this._rootStore.membershipStore.list().filter((membership) => {
      return membership.workspaceId === this.id;
    });
  }

  /**
   * Enable the given integration for the workspace
   * @param integrationConfigType
   * @param userInput
   */
  configureIntegration(
    integrationConfigType: IntegrationConfigurationType,
    userInput: any //Can we type this better?
  ): void {
    runInAction(() => {
      console.log(
        'Creating integration configuration',
        integrationConfigType,
        userInput
      );
      this._rootStore.integrationConfigurationStore.create(
        integrationConfigType,
        userInput,
        this.workspaceId
      );
    });

    //Push a new integration configuration to the store for the integration type
  }

  /**
   * Get the integration along with its configuration (if any) by the integration slug
   * If Integration is not configured, the configuration will be null but the integration will still be returned.
   * If the integration does not exist, the function will return null.
   * @param slug
   * @returns
   */
  getIntegrationWithConfigurationBySlug(
    slug: string
  ): IntegrationXWorkspace | null {
    /**
     * Find all available configurations for the integrations
     */
    const allAvailableIntegrations = this._rootStore.integrationStore.list();
    const targetIntegration = allAvailableIntegrations.find(
      (integration) => integration.slug == slug
    );

    //Integration Does not exist
    if (targetIntegration == null) {
      return null;
    }

    targetIntegration.makeObservable();

    return {
      integration: targetIntegration,
      configuration:
        this._rootStore.integrationConfigurationStore
          .list()
          .find(
            (config) => config.id === targetIntegration.configurationType
          ) ?? null,
    };
  }

  get plan(): Plan {
    return this._plan;
  }

  /**
   * Get all available integrations along with their configuration (if any)
   */
  getIntegrationsWithConfiguration(): Array<IntegrationXWorkspace> {
    /**
     * We want to show all integrations regardless of if they are configured for the workspace or not
     */
    const allAvailableIntegrations = this._rootStore.integrationStore.list(
      (a, b) => a.name.localeCompare(b.name)
    );

    allAvailableIntegrations.forEach((integration) => {
      integration.makeObservable();
    });

    /**
     * Find all available configurations for the integrations
     */
    const allIntegrationConfigurations =
      this._rootStore.integrationConfigurationStore.listWithDefaultSort;

    console.log(
      'All Available Integration Configurations',
      allIntegrationConfigurations
    );

    allIntegrationConfigurations.forEach((config) => {
      config.makeObservable();
    });

    return allAvailableIntegrations.map((integration) => {
      console.log('Looking for configuration for integration', integration.id);
      return {
        integration,
        configuration:
          allIntegrationConfigurations.find(
            (config) => config.id === integration.configurationType
          ) ?? null,
      };
    });
  }

  /**
   * Create a new landing page for the workspace
   * @param url
   */
  createLandingPage(url: string): LandingPage {
    let landingPage: LandingPage;
    landingPage = runInAction(() => {
      return this._rootStore.landingPageStore.createSelfHosted(url, this.id);
    });
    return landingPage;
  }

  /**
   * Get all landing pages for the workspace
   */
  get landingPages() {
    return this._rootStore.landingPageStore.list().filter((landingPage) => {
      return landingPage.workspaceId === this.id;
    });
  }
}

export default Workspace;
