import { useNotificationService } from '@outbound/design-system/src/components/services/notification-service-provider/notification-service-provider';
import React, { useEffect, useMemo } from 'react';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import { RootStore, createRootStore } from './root-store';
import { RootStoreContext } from './root-store-context';

let rootStoreInstance: RootStore | undefined;

export const RootStoreProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { axiosInstance, authenticatedUserId, isAuth0Loading } =
    useAuth0Axios();

  const { pushNotification } = useNotificationService();

  // Ensure that rootStore is created only once per app initialization
  const rootStore = useMemo(() => {
    if (!rootStoreInstance && !isAuth0Loading) {
      rootStoreInstance = createRootStore(
        axiosInstance,
        authenticatedUserId ?? null,
        pushNotification
      );
    }
    return rootStoreInstance;
  }, [isAuth0Loading, axiosInstance, authenticatedUserId, pushNotification]);

  useEffect(() => {
    console.log('authenticatedUserId', authenticatedUserId);
  }, [authenticatedUserId]);

  useEffect(() => {
    console.log('Root Store Provider Mounted');
    return () => {
      console.log('Root Store Provider Unmounted');
      if (rootStoreInstance?.destroy) {
        console.log('Destroying Root Store');
        rootStoreInstance.destroy();
        rootStoreInstance = undefined; // Allow reinitialization if needed
      }
    };
  }, []);

  if (!rootStore) {
    return <></>;
  }

  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  );
};
