import { cx } from 'class-variance-authority';
import { ObDrawerHeader } from '../../molecules/ob-drawer-header/ob-drawer-header';
import { ObDrawerTopBar } from '../../molecules/ob-drawer-top-bar/ob-drawer-top-bar';

export interface ObDrawerProps {
  title: string;
  position?: 'full-height' | 'under-header';
  description: string;
  children?: React.ReactNode;
  onCloseCallback?: () => Promise<void>;
  onBackCallback?: () => Promise<void>;
}

export const ObDrawer: React.FC<ObDrawerProps> = ({
  children,
  title,
  description,
  position = 'full-height',
  onBackCallback,
}: ObDrawerProps) => {
  return (
    <div
      className={cx(
        'flex flex-col flex-grow dark:bg-bgSurfaceDark border border-borderDefaultNormalDark box-border',
        position === 'full-height'
          ? 'min-h-screen h-screen'
          : 'min-h-[calc(100vh-63px)] h-[calc(100vh-63px)] mt-[63px]'
      )}
    >
      <ObDrawerTopBar onBackCallback={onBackCallback} />
      {title != null && title.trim().length > 0 && (
        <ObDrawerHeader
          title={title}
          description={description}
        />
      )}
      <main className='flex flex-grow flex-col h-full overflow-scroll'>
        {children}
      </main>
    </div>
  );
};
