import React from 'react';
import { ObProgressBar } from '../../elements/ob-progress-bar/ob-progress-bar';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObBrand } from '../../elements/ob-brand/ob-brand';

export interface ObProgressHeaderProps {
  percentage: number;
  onCloseCallback?: () => void;
}

export const ObProgressHeader: React.FC<ObProgressHeaderProps> = ({
  percentage,
  onCloseCallback,
}) => {
  return (
    <div className='flex items-center justify-between py-6 h-[80px] min-h-[80px]'>
      <div>
        <div className='p-6 hidden sm:flex'>
          <ObBrand
            variant='icon'
            size='medium'
          />
        </div>
      </div>
      <div className='mx-2 w-full max-w-[380px]'>
        <ObProgressBar percentage={percentage} />
      </div>
      <div className='sm:min-w-[54px]'>
        {onCloseCallback && (
          <div>
            <ObButton
              aria-label='Close'
              label='Close'
              variant='outline'
              buttonType='icon'
              iconLeft='close'
              onClick={onCloseCallback}
            ></ObButton>
          </div>
        )}
      </div>
    </div>
  );
};
