export const getFilesAsyncFromFolder = async (
  dataTransfer: DataTransfer
): Promise<File[]> => {
  const files: File[] = [];

  for (let i = 0; i < dataTransfer.items.length; i++) {
    const item = dataTransfer.items[i];
    if (item.kind === 'file') {
      if (typeof item.webkitGetAsEntry === 'function') {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          const entryContent = await readEntryContentAsync(entry);
          files.push(...entryContent);
        }
      } else {
        const file = item.getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
  }

  return files;
};

export const readEntryContentAsync = async (
  entry: FileSystemEntry
): Promise<File[]> => {
  const contents: File[] = [];

  if (isFile(entry)) {
    contents.push(await new Promise<File>((resolve) => entry.file(resolve)));
  } else if (isDirectory(entry)) {
    const reader = entry.createReader();
    let entries: FileSystemEntry[] = [];

    while (
      (entries = await new Promise((resolve) => reader.readEntries(resolve)))
        .length > 0
    ) {
      for (const entry of entries) {
        const entryContent = await readEntryContentAsync(entry);
        contents.push(...entryContent);
      }
    }
  }

  return contents;
};

export const isDirectory = (
  entry: FileSystemEntry
): entry is FileSystemDirectoryEntry => {
  return entry.isDirectory;
};

export const isFile = (
  entry: FileSystemEntry
): entry is FileSystemFileEntry => {
  return entry.isFile;
};
