import { FC } from 'react';
import { BackgroundColors, ObColorType } from '../../tokens/colors/colors';

export interface ObIndicatorDotProps {
  color: ObColorType;
  priority: 'low' | 'high';
}

/**
 * Pulsing dot that indicates to the user that their action is required
 * @param param0
 * @returns
 */
export const ObIndicatorDot: FC<ObIndicatorDotProps> = ({
  color,
  priority = 'high',
}: ObIndicatorDotProps) => {
  const colorClass = BackgroundColors({ color });

  return (
    <div
      data-testid='ob-indicator-dot'
      className='relative flex items-center justify-center'
      style={{ height: '20px', width: '20px' }}
    >
      <div
        className={`rounded-full ${colorClass}`}
        style={{
          height: '10px',
          width: '10px',
        }}
      ></div>
      {priority === 'high' && (
        <div
          className={`absolute rounded-full animate-pulse opacity-30 ${colorClass}`}
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      )}
    </div>
  );
};
