import { AxiosInstance } from 'axios';

import { Deferred } from '@otbnd/utils';
import { ClientDelta } from '@outbound/types';
import { Transport } from './transport';

export abstract class BaseTransport<T> {
  /**
   * Tracks if a resource transport has been bootstrapped.
   * If not we can't do things like list() since that method assumes
   * that all resources are loaded in the store.
   * This is an initial implementation that can be expanded or replaced in the future.
   */
  private _deferredBootstrap: Deferred<void>;

  private resourcePollStatusState = new Map<string, boolean>();
  protected _transport: Transport;
  protected _axiosInstance: AxiosInstance;
  protected notifyStoreOfServiceUpdateCallback:
    | ((id: string, update: T | null) => void)
    | null = null;

  /**
   * A queue of updates that need to be sent to the server
   * Initial implementation. We may want to move this to indexedDB or some other form of local storage
   * in the future to ensure that we don't lose updates if the user navigates away from the page or refreshes
   */
  protected deltaQueue: Array<ClientDelta> = [];
  protected deltaQueueFlushDelay: number = 500;
  protected deltaQueueFlushTimeoutId: number | null = null;

  constructor(_transport: Transport, _axiosInstance: AxiosInstance) {
    this._transport = _transport;
    this._axiosInstance = _axiosInstance;
    this._deferredBootstrap = new Deferred<void>();
  }

  /**
   * This method should be overwritten by a base class to perform any initialization
   * @returns
   */
  protected internalBootstrap(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Transitional Implementation
   *
   * Public method to load the resources from the server on app init.
   * This is a very basic implementation and assumes that we are doing a full
   * bootstrap of all resources on app init.
   *
   * In the future this may be moved to the global transport level and use a bootstrap endpoint to load all resources
   * and would coordinate with any resources that are currently in indexedDB or similar.
   */
  public bootstrap(): void {
    this.internalBootstrap().then(() => {
      console.log('Bootstrap Complete');
      this._deferredBootstrap.resolve();
    });
  }

  /**
   * Allows interested parties to check if the transport has been bootstrapped or not.
   * This is useful for stores since they do not want to allow people to call list()
   * unless that know that they have all the resources loaded from the server.
   *
   * The promise returned by this method will resolve when the transport has been bootstrapped.
   *
   * It should be thrown to trigger suspense in the UI
   * @returns
   */
  public bootstrapStatus(): Pick<Deferred<void>, 'promise' | 'status'> {
    return {
      promise: this._deferredBootstrap.promise,
      status: this._deferredBootstrap.status,
    };
  }

  /**
   * This allows the Store to register a callback that will be called whenever there is a server side update
   * to a resource. Null will be passed if the resource does not exist on the server
   * @param callback
   */
  public registerServerUpdateCallbackHandler(
    callback: (id: string, update: T | null) => void
  ): void {
    this.notifyStoreOfServiceUpdateCallback = callback;
  }

  protected abstract fetchById(id: string): Promise<T | null>;

  /**
   * Public method that allows the caller to request a specific resource by id.
   * The Transport implementation will fetch the resource from the server and notify the store once it has been updated.
   * @param id
   */
  public async requestResourceById(id: string): Promise<void> {
    const resource = await this.fetchById(id);
    if (resource != null) {
      this.onResourceFetchedFromServer(id, resource);
    }
    this.notifyStoreOfServiceUpdateCallback?.(id, resource);
    //Allow the implementation to have a chance to run side effects after the resource is fetched
  }

  /**
   * Overridable method that allows the implementation to run side effects after the resource is fetched
   * @param _id
   * @param _resource
   */
  protected onResourceFetchedFromServer(_id: string, _resource: T): void {
    //Default Implementation does nothing
  }

  /**
   * Provides the ability for Other Transport to share resources with this transport
   * that they find embedded in their resources. This may be less useful in the future
   * as we migrate towards bootstrapping the store with all resources up front. This tends
   * to work well with the "Include" query parameter in the API.
   * @param resource
   */
  public abstract acceptEmbeddedResource(resource: T): void;

  /**
   * Push a Change into the queue to be sent to the server
   * This queue is processed after a short delay to batch updates to the server
   * @param delta
   */
  public enqueue(delta: ClientDelta): void {
    this.deltaQueue.push(delta);

    /**
     * Restart the flush queue timer on every enqueue.
     * Future enhancements could include a max queue size or a max delay
     * so we don't wait too long to send updates to the server
     */
    if (this.deltaQueueFlushTimeoutId !== null) {
      clearTimeout(this.deltaQueueFlushTimeoutId);
    }

    /**
     * Set a timeout to flush the queue after the delay
     */
    this.deltaQueueFlushTimeoutId = window.setTimeout(() => {
      this.flushQueue();
      this.deltaQueueFlushTimeoutId = null;
    }, this.deltaQueueFlushDelay);
  }

  /**
   * Called with a deduped list of deltas to flush to the server after a short timeout
   *
   * In the future we hope to have this processed by a sync endpoint but for now
   * each transport will need to implement this method to send the deltas to the server
   * in a format that the server can understand
   */
  protected async processDeltaQueueFlush(
    _updatesToFlush: Array<ClientDelta>
  ): Promise<void> {
    throw new Error('Delta Updates Not Implemented');
  }

  /**
   * Flush the queue of updates to the server
   */
  private async flushQueue() {
    const updatesToFlush: Array<ClientDelta> = [...this.deltaQueue];
    this.deltaQueue = [];

    /**
     * Batch Settings by Campaign Id
     * For each change in the queue we will discard all but the most recent update.
     * This makes the assumption that each change uses a "Last Write Wins" conflict resolution.
     */
    const dedupeDeltaQueue: Record<string, ClientDelta> = {};

    /**
     * Run through all the updates to flush and dedupe them by the object path before sending them to the server
     */
    updatesToFlush.forEach((delta) => {
      const deltaObjectPath = `${delta}:${delta.obrn}:${delta.path}`;
      /**
       * Check to see if we have already queued an update for this object path
       * if we have not then we will add it to the dedupe queue
       */
      const lastWriteDelta = dedupeDeltaQueue[deltaObjectPath];
      if (!lastWriteDelta) {
        dedupeDeltaQueue[deltaObjectPath] = delta;
      } else {
        if (lastWriteDelta.clientTimestamp < delta.clientTimestamp) {
          dedupeDeltaQueue[deltaObjectPath] = delta;
        }
      }
    });

    console.log(
      `Merged ${updatesToFlush.length} updates to ${dedupeDeltaQueue.length} request(s) and flushed to server`
    );

    await this.processDeltaQueueFlush(Object.values(dedupeDeltaQueue));
  }

  /**
   * Provides a way to poll the server for the base transport to check if a resource is in a target state.
   * The is useful for checking for initialization of status
   *
   * Possible Future Enhancements:
   * - Add a timeout to stop polling after a certain amount of time
   * - Exponential Backoff for retries
   * - Jitter for retries
   * - Add way to cancel polling for a specific resource or all resources?
   *
   * @param id
   * @param checkStatus
   * @param checkForFailure
   * @param delay
   */
  protected async pollForResourceInTargetState(
    id: string,
    checkStatus: (body: T) => boolean,
    checkForFailure: (body: T) => boolean,
    checkName: string,
    delay: number = 2000
  ): Promise<void> {
    const pollState =
      this.resourcePollStatusState.get(`${id}:${checkName}`) ?? false;

    if (!pollState) {
      this.resourcePollStatusState.set(`${id}:${checkName}`, true);
      let isResourceInTargetState = false;
      let errorCount = 0;
      let pollCount = 0;
      while (!isResourceInTargetState && errorCount < 3 && pollCount < 50) {
        try {
          const response = await this.fetchById(id);
          if (response != null && checkStatus(response)) {
            isResourceInTargetState = true;
            this.resourcePollStatusState.delete(`${id}:${checkName}`);
            this.notifyStoreOfServiceUpdateCallback?.(id, response);
          }
          if (response != null && checkForFailure(response)) {
            this.resourcePollStatusState.delete(`${id}:${checkName}`);
            throw new Error('Failed');
          }
          console.log(
            `Will check again in ${delay}ms. Retry Count: ${pollCount} of 50`
          );
          pollCount++;
        } catch (error) {
          errorCount++;
          console.error('Error Polling for Target Status', error);
        }
        await new Promise((resolve) => setTimeout(resolve, delay));

        if (errorCount >= 3) {
          this.resourcePollStatusState.delete(`${id}:${checkName}`);
          console.error('Polling Stopped after 3 errors');
        }

        if (pollCount >= 50) {
          this.resourcePollStatusState.delete(`${id}:${checkName}`);
          console.error('Polling Stopped after 50 attempts');
        }
      }
    } else {
      console.log('Polling Already In Progress');
    }
  }
}
