import { Switch } from '@headlessui/react';
import { cva, cx } from 'class-variance-authority';
import { useCallback } from 'react';
import { ObBadge, ObGradientIcon, ObTypography } from '../../../';
import { Level } from '../../../tokens/colors/colors';
import { IconSize, ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { getType } from '../../../utilities/object-utilities';
import { CheckboxGroupCardOption } from './ob-card-option-checkbox-group.types';

export interface ObCardOptionCheckboxGroupProps {
  /**
   * The available options for the component
   */
  options: CheckboxGroupCardOption[];
  /**
   * The state for this component.
   * The parent should ensure that this array is a recreated (A new array)
   * each time the array is updated. Otherwise react will not know to rerender.
   */
  value?: CheckboxGroupCardOption[];

  /**
   * Disables the entire checkbox group so no values can be updated
   */
  isDisabled?: boolean;
  /**
   * Callback to notify the parent component that the values have been changed.
   * Calls the specified function with the current values. It is expected that the parent would
   * update the value prop with the value argument from this function in order to have the changes
   * reflect on the UI
   * @param value
   * @returns
   */
  onValueUpdatedCallback: (value: CheckboxGroupCardOption[]) => any | void;

  gradientIconSize?: IconSize;
}

const CheckedMark = ({ checked }: { checked: boolean }) => (
  <div className='checkbox__border rounded-full flex justify-center items-center h-5 w-5 border-actionPrimaryDark border-2 transition-opacity'>
    <div
      className={cx(
        'checkbox__icon-background rounded-full flex justify-center items-center h-5 w-5  transition-opacity',
        checked
          ? 'bg-actionPrimaryDark border-borderDefaultNormalDark opacity-100'
          : 'opacity-0'
      )}
    >
      <ObIcon
        aria-hidden='true'
        classNames={checked ? 'opacity-100' : 'opacity-0'}
        icon='check'
        size='x-small'
      />
    </div>
  </div>
);

export const ObCardOptionCheckboxGroup = ({
  options = [],
  value: selected = [],
  gradientIconSize = 'small',
  isDisabled = false,
  onValueUpdatedCallback: onValueChangedCb,
}: ObCardOptionCheckboxGroupProps) => {
  if (options == null || !Array.isArray(options)) {
    throw new Error(
      `Options are Required for the Checkbox Group. Expected an Array but got ${getType(
        options
      )}`
    );
  }
  if (selected == null || !Array.isArray(selected)) {
    throw new Error(
      `Value is required and must be an Array for the Checkbox Group. Expected an Array but got ${getType(
        selected
      )}`
    );
  }

  const getNewSelectedValues = useCallback(
    ({
      option,
      currentSelected,
    }: {
      option: CheckboxGroupCardOption;
      currentSelected: CheckboxGroupCardOption[];
    }) => {
      let newSelection: CheckboxGroupCardOption[];

      // Allow to un-select items
      if (
        currentSelected.find(
          (selectedOption) => selectedOption.value === option.value
        )
      ) {
        // Omit value
        newSelection = currentSelected.filter(
          (selectedOption) => selectedOption.value !== option.value
        );
      } else {
        // Add new value
        newSelection = [...currentSelected, option];
        // Dedup values
        newSelection = newSelection.filter(
          (item, index) => newSelection.indexOf(item) === index
        );
      }

      return newSelection;
    },
    []
  );

  const handleChange = useCallback(
    (selection: CheckboxGroupCardOption) => {
      if (selection.disabled) {
        return;
      }

      const newSelected = getNewSelectedValues({
        option: selection,
        currentSelected: selected,
      });

      if (onValueChangedCb) {
        onValueChangedCb(newSelected);
      }
    },
    [getNewSelectedValues, selected, onValueChangedCb]
  );

  const cardClasses = cva(
    '  rounded-lg border bg-bgSurfaceLight dark:bg-bgSurfaceDark p-4 shadow-sm  transform ',
    {
      variants: {
        disabled: {
          true: [
            'opacity-50 cursor-default dark:border-borderDefaultNormalDark ',
          ],
          false: [
            'cursor-pointer hover:translate-y-[-2px] transition transition-all ease-in duration-300 hover:ease-out hover:duration-1000 hover:shadow-[0_1px_120px_-30px_rgba(0,0,0,0.2)] hover:shadow-actionPrimaryLight ',
          ],
        },
        checked: {
          false: ['dark:border-borderDefaultNormalDark '],
          true: ['border-actionPrimaryDark ring-2 ring-actionPrimaryDark'],
        },
      },
      defaultVariants: {
        disabled: false,
      },
    }
  );

  const buildGrid = (length: number) => {
    switch (length) {
      case 1:
        return 'grid gap-4 grid-cols-1 ';
      case 2:
        return 'grid gap-4 grid-cols-1 md:grid-cols-2';
      case 3:
        return 'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
      default:
        return 'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';
    }
  };

  return (
    <div className={cx([buildGrid(options.length)])}>
      {options.map((option: CheckboxGroupCardOption) => {
        const isChecked =
          selected?.find(
            (selectedOption) => selectedOption.value === option.value
          ) != null;

        return (
          <Switch
            key={option.key || option.value}
            disabled={isDisabled || option.disabled}
            value={option.value}
            checked={!!isChecked}
            className={cx(
              cardClasses({
                checked: isChecked,
                disabled: isDisabled || option.disabled,
              })
            )}
            onChange={() => handleChange(option)}
          >
            {({ checked }) => (
              <article className='flex flex-col justify-between h-full  gap-3 '>
                <header className='flex flex-row gap-2 items-start justify-between  text-left'>
                  {/* We will place the overline here if it is present otherwise we will place the title here */}
                  <ObTypography
                    as='span'
                    variant={option.overline != null ? 'subtitle1' : 'h4'}
                    style={{ lineHeight: '20px' }}
                  >
                    {option.overline ?? option.title}
                  </ObTypography>

                  <CheckedMark checked={checked} />
                </header>
                <section className='flex flex-col text-left gap-2'>
                  {/* When no overline is present we will set the title as the overline so this is not needed */}
                  {option.overline && (
                    <ObTypography
                      variant={'h4'}
                      style={{ lineHeight: '20px' }}
                    >
                      {option.title}
                    </ObTypography>
                  )}
                  {option.icon && (
                    <div className='w-[56px] h-[56px] flex items-center justify-center'>
                      <ObGradientIcon
                        icon={option.icon}
                        size='large'
                        iconSize={gradientIconSize}
                        iconRadialColorClassName={option.gradientColor}
                      />
                    </div>
                  )}

                  <ObTypography
                    as='span'
                    variant='h6'
                    color='secondary'
                  >
                    {option.description}
                  </ObTypography>

                  <ObTypography
                    as='span'
                    variant='body2'
                    color='secondary'
                  >
                    {option.descriptionLine2}
                  </ObTypography>
                </section>
                <footer className='flex flex-col w-full'>
                  {option.badgeDescription && (
                    <div className='max-w-full flex'>
                      <ObBadge
                        level={option.badgeLevel ?? Level.DEFAULT}
                        content={option.badgeDescription}
                        iconLeft={option.badgeIconLeft}
                        iconRight={option.badgeIconRight}
                      />
                    </div>
                  )}
                </footer>
              </article>
            )}
          </Switch>
        );
      })}
    </div>
  );
};
