import { ObButton } from '../../../../index';

export interface RuleControlsProps {
  isEditModeEnabled: boolean;
  onToggleEditModeCallback: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDeleteRuleButtonClickedCallback: (
    e: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

/**
 * # Rule Builder / Rule / Rule Controls
 * Simple component that renders the edit and delete buttons for a rule.
 * The intent of this component is be used within the Rule component.
 * Its job is to render the buttons and the left border that separates the rule from the rest of the UI.
 * Any animations, state management, or other logic should be handled by the parent component.
 */
export const RuleControls = ({
  isEditModeEnabled,
  onToggleEditModeCallback,
  onDeleteRuleButtonClickedCallback,
}: RuleControlsProps) => {
  return (
    <div className='min-h-[60px] flex items-center justify-center border-l border-light/border/default/normal dark:border-actionSecondaryHoverBorderDark/[.16] h-full'>
      <ObButton
        size='medium'
        variant='ghost'
        buttonType='icon'
        label={isEditModeEnabled ? 'Finish Editing Rule' : 'Edit Rule'}
        onClick={onToggleEditModeCallback}
        iconLeft={isEditModeEnabled ? 'check' : 'pencil01'}
      />
      <ObButton
        size='medium'
        variant='ghost'
        buttonType='icon'
        label='Delete Rule'
        onClick={onDeleteRuleButtonClickedCallback}
        iconLeft='delete'
      />
    </div>
  );
};
