import {
  CreatePhysicalLocationResourceRequest,
  CreateServiceAreaResourceRequest,
  PhysicalLocationPatchRequest,
  ServiceAreaPatchRequest,
} from '@outbound/types';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import { playbookSettingsKeys } from './playbook-query-key';

/**
 * Creates a new service area
 * @returns
 */
export const useCreateServiceArea = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: CreateServiceAreaResourceRequest }
  >(
    ['locations:post'],
    async ({ values }) => {
      const response = await auth0AxiosClient.post('/locations', values);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const usePatchServiceAreaById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: Partial<ServiceAreaPatchRequest> }
  >(
    ['locations', id, 'patch'],
    async ({ values }) => {
      if (id == null) {
        throw new Error(
          'Cannot Update Playbook. A Playbook page id must be provided.'
        );
      }
      const response = await auth0AxiosClient.patch('locations/' + id, values);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useDeleteServiceAreaById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation(
    ['locations', id, 'delete'],
    async () => {
      if (id == null) {
        throw new Error(
          'Cannot delete service area. A service area id must be provided.'
        );
      }
      const response = await auth0AxiosClient.delete('locations/' + id);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

/**
 * Creates a new physical location
 * @returns
 */
export const useCreatePhysicalLocation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: CreatePhysicalLocationResourceRequest }
  >(
    ['locations:post'],
    async ({ values }) => {
      const response = await auth0AxiosClient.post('/locations', values);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const usePatchPhysicalLocationById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: Partial<PhysicalLocationPatchRequest> }
  >(
    ['locations', id, 'patch'],
    async ({ values }) => {
      if (id == null) {
        throw new Error(
          'Cannot Update Physical Location. A physical location id must be provided.'
        );
      }
      const response = await auth0AxiosClient.patch('locations/' + id, values);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useDeletePhysicalLocationById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation(
    ['locations', id, 'delete'],
    async () => {
      if (id == null) {
        throw new Error(
          'Cannot delete physical location. A physical location id must be provided.'
        );
      }
      const response = await auth0AxiosClient.delete('locations/' + id);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};
