import { cx } from 'class-variance-authority';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObTypography } from '../ob-typography/ob-typography';

export interface ObUploadStatusProps {
  isSuccess: boolean;
  isError: boolean;
  errorMessage?: string;
}

export const ObUploadStatus = ({
  isSuccess,
  isError,
  errorMessage,
}: ObUploadStatusProps) => {
  return (
    <>
      {isSuccess && (
        <div
          className={cx(
            'absolute flex justify-center items-center transition-all duration-300',
            isSuccess ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
          )}
        >
          <ObIcon
            color='positive'
            icon='check'
            size='small'
          />
        </div>
      )}
      {isError && (
        <div
          className={cx(
            'absolute inset-0 flex flex-col justify-center items-center text-center transition-all duration-300',
            isError ? 'opacity-100' : 'translate-y-20 opacity-0'
          )}
        >
          <ObIcon
            color='negative'
            icon='errorMessage'
            size='small'
          />
          {errorMessage && (
            <ObTypography
              color='negative'
              variant='body3'
            >
              {errorMessage}
            </ObTypography>
          )}
        </div>
      )}
    </>
  );
};
