import { AxiosInstance } from 'axios';
import PropTypes from 'prop-types';
import React, { ReactNode, createContext, useContext } from 'react';
import { useAuth0AxiosInstance } from './auth0Axios';

interface Auth0AxiosContextProps {
  axiosInstance: AxiosInstance;
  isAuthenticated: boolean;
  isAuthenticatedWithWorkspace: boolean;
  authenticatedWorkspaceId?: string;
  authenticatedUserId?: string;
  isAuth0Loading: boolean;
}

const defaultContextValues: Auth0AxiosContextProps = {
  axiosInstance: undefined as unknown as AxiosInstance,
  isAuthenticated: undefined as unknown as boolean,
  isAuthenticatedWithWorkspace: undefined as unknown as boolean,
  authenticatedWorkspaceId: undefined,
  authenticatedUserId: undefined,
  isAuth0Loading: undefined as unknown as boolean,
};

const Auth0AxiosContext =
  createContext<Auth0AxiosContextProps>(defaultContextValues);

interface Auth0AxiosProviderProps {
  children?: ReactNode;
}

export const Auth0AxiosProvider: React.FC<Auth0AxiosProviderProps> = ({
  children,
}) => {
  const {
    axiosInstance,
    isAuthenticated,
    isAuthenticatedWithWorkspace,
    authenticatedWorkspaceId,
    authenticatedUserId,
    isAuth0Loading,
  } = useAuth0AxiosInstance();

  return (
    <Auth0AxiosContext.Provider
      value={{
        axiosInstance,
        isAuthenticated,
        isAuthenticatedWithWorkspace,
        authenticatedWorkspaceId,
        authenticatedUserId,
        isAuth0Loading,
      }}
    >
      {children}
    </Auth0AxiosContext.Provider>
  );
};

Auth0AxiosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth0Axios = (): Auth0AxiosContextProps => {
  const context = useContext(Auth0AxiosContext);
  if (!context) {
    throw new Error(
      'useAuth0Axios must be used within a component that is wrapped with Auth0AxiosProvider'
    );
  }
  return context;
};
