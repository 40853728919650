import { OutboundCampaignGoal } from '@outbound/types';
import { RootStore } from '../../root-store';
import CampaignOutboundGoalContext from './base-ob-goal-context';

class CampaignOutboundGoalLeadsForServiceContext extends CampaignOutboundGoalContext {
  private _serviceId: string;
  private _serviceVersion: string;
  constructor(
    _rootStore: RootStore,
    type: OutboundCampaignGoal,
    serviceId: string,
    serviceVersion: string
  ) {
    super(_rootStore, type);

    this._serviceId = serviceId;
    this._serviceVersion = serviceVersion;
  }

  get serviceId(): string {
    return this._serviceId;
  }

  get serviceVersion(): string {
    return this._serviceVersion;
  }

  get service() {
    return this._rootStore.serviceStore.getById(this._serviceId);
  }
}

export default CampaignOutboundGoalLeadsForServiceContext;
