import {
  findAllPhysicalLocationEnabledSettings,
  findAllServiceAreaEnabledSettings,
  getPhysicalLocationIdFromPhysicalLocationSettingId,
  getServiceAreaIdFromServiceAreaSettingId,
} from '@otbnd/utils';
import { CampaignResource, LocationType } from '@outbound/types';
import { SyntheticCampaignLocationResource } from './campaign-location-transformer';

/**
 * A Helper function to build a list of our synthetic Campaign Location Resources from a Campaign Resource.
 * These can be passed to the transformers fromApiResource method to create MobX Models for the locations
 * @param resource
 * @returns
 */
export const buildCampaignLocationResourcesFromCampaign = (
  resource: CampaignResource
): Array<SyntheticCampaignLocationResource> => {
  const serviceAreaSettings = findAllServiceAreaEnabledSettings(
    resource.campaignSettingValues
  );

  const serviceAreaCampaignLocations = serviceAreaSettings.map((sas) => {
    const serviceAreaId = getServiceAreaIdFromServiceAreaSettingId(sas.id);
    return {
      campaignId: resource.id,
      workspaceId: resource.workspaceId,
      locationId: serviceAreaId,
      locationType: 'SERVICE_AREA' as LocationType,
      isEnabled: sas.value as boolean,
    };
  });

  const physicalLocationSettings = findAllPhysicalLocationEnabledSettings(
    resource.campaignSettingValues
  );

  const physicalLocationCampaignLocations = physicalLocationSettings.map(
    (pls) => {
      const physicalLocationId =
        getPhysicalLocationIdFromPhysicalLocationSettingId(pls.id);
      return {
        campaignId: resource.id,
        workspaceId: resource.workspaceId,
        locationId: physicalLocationId,
        locationType: 'PHYSICAL' as LocationType,
        isEnabled: pls.value as boolean,
      };
    }
  );

  return [
    ...serviceAreaCampaignLocations,
    ...physicalLocationCampaignLocations,
  ];
};
