import { ObTypography } from '../../../..';

/**
 * Heading for each section on the campaign details page.
 * @returns
 */
export const SectionHeader = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) => {
  return (
    <div className='flex flex-col  pb-4'>
      <ObTypography variant='h2'>{heading}</ObTypography>
      <ObTypography
        variant='subtitle1'
        color='secondary'
      >
        {subheading}
      </ObTypography>
    </div>
  );
};
