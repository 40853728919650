/* solid/general/help-circle */
export const HelpCircle = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99935 0.666626C3.94926 0.666626 0.666016 3.94987 0.666016 7.99996C0.666016 12.05 3.94926 15.3333 7.99935 15.3333C12.0494 15.3333 15.3327 12.05 15.3327 7.99996C15.3327 3.94987 12.0494 0.666626 7.99935 0.666626ZM7.27044 5.51411C7.5419 5.35457 7.86108 5.29625 8.17142 5.34948C8.48177 5.40271 8.76326 5.56406 8.96605 5.80495C9.16883 6.04584 9.27982 6.35073 9.27935 6.66561L9.27935 6.6666C9.27935 6.97936 9.03595 7.30541 8.57621 7.6119C8.36663 7.75162 8.15231 7.85926 7.98775 7.93239C7.90642 7.96854 7.83957 7.99523 7.79462 8.01236C7.77467 8.01996 7.75464 8.02731 7.7345 8.03438C7.38565 8.15108 7.1972 8.52836 7.31356 8.87742C7.42999 9.22671 7.80753 9.41549 8.15683 9.29906L8.26928 9.25834C8.3337 9.2338 8.4231 9.19799 8.52927 9.15081C8.73971 9.05728 9.02539 8.91491 9.31581 8.7213C9.85597 8.36119 10.6124 7.68745 10.6127 6.66721C10.6135 6.03759 10.3916 5.42797 9.98608 4.94629C9.58052 4.4645 9.01753 4.1418 8.39683 4.03534C7.77614 3.92887 7.1378 4.04551 6.59486 4.3646C6.05192 4.68369 5.63944 5.18463 5.43046 5.7787C5.30827 6.12603 5.49079 6.50664 5.83812 6.62882C6.18544 6.751 6.56605 6.56849 6.68824 6.22116C6.79273 5.92413 6.99897 5.67365 7.27044 5.51411ZM7.99935 10.6666C7.63116 10.6666 7.33268 10.9651 7.33268 11.3333C7.33268 11.7015 7.63116 11.9999 7.99935 11.9999H8.00601C8.3742 11.9999 8.67268 11.7015 8.67268 11.3333C8.67268 10.9651 8.3742 10.6666 8.00601 10.6666H7.99935Z'
        fill='currentColor'
      />
    </svg>
  );
};
