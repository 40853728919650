import { PersonaResource } from '@outbound/types';
import { FC, useEffect, useRef, useState } from 'react';
import { StateManagedByParentInput } from '../../../../base-component-props.type';
import { stringHasContent } from '../../../../utilities/string-utils';

import { FieldDisplayModeProps } from '../../form-renderer-poc';
import { ObSelectedOptionGroup } from '../ob-selected-option-group/ob-selected-option-group';
import { ObSelectedOption } from '../ob-selected-option/ob-selected-option';
import { SectionLabel } from '../section-label/section-label';
import { WhoRadioGroup } from '../who-radio-group/who-radio-group';

export interface WhoSectionProps
  extends StateManagedByParentInput<string>,
    FieldDisplayModeProps {
  personasFromPlaybook: Array<PersonaResource>;
  onAddNewCustomerProfileClickedCallback: () => void;
}

const lookupLabelForOption = (
  value: string,
  personas: Array<PersonaResource> = []
): string => {
  const persona = personas.find((service) => service.id === value);
  if (persona) {
    return `${persona.role}`;
  }

  return 'Unknown';
};

export const WhoSection: FC<WhoSectionProps> = ({
  value,
  onValueChangedCallback,
  displayMode = 'editable',
  onDisplayModeChangedCallback,
  personasFromPlaybook = [],
  onAddNewCustomerProfileClickedCallback,
}: WhoSectionProps) => {
  const firstTimeMode = useRef(!stringHasContent(value));
  const [whoLabel, setWhoLabel] = useState<string>(
    lookupLabelForOption(value, personasFromPlaybook)
  );

  useEffect(() => {
    setWhoLabel(lookupLabelForOption(value, personasFromPlaybook));
    if (stringHasContent(value) && firstTimeMode.current) {
      firstTimeMode.current = false;
      onDisplayModeChangedCallback('readonly');
    }
  }, [onDisplayModeChangedCallback, personasFromPlaybook, value]);
  return (
    <div className='flex flex-col gap-3 self-stretch'>
      <SectionLabel
        label='Who Do You Want to Advertise To?'
        id={'who'}
      />
      {displayMode === 'readonly' && (
        <ObSelectedOptionGroup
          onEditClickedCallback={() => onDisplayModeChangedCallback('editable')}
        >
          <ObSelectedOption
            id={'who'}
            value={whoLabel}
          />
        </ObSelectedOptionGroup>
      )}
      {displayMode === 'editable' && (
        <WhoRadioGroup
          hideConfirmButton={firstTimeMode.current}
          personasFromPlaybook={personasFromPlaybook}
          onConfirmWhatClickedCallback={() =>
            onDisplayModeChangedCallback('readonly')
          }
          onAddNewPersonaClickedCallback={
            onAddNewCustomerProfileClickedCallback
          }
          value={value}
          onValueChangedCallback={onValueChangedCallback}
          isLoading={false}
        />
      )}
    </div>
  );
};
