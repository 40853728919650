import { AssetResource } from '@outbound/types';
import { StateManagedByParentInputValueOnly } from '../../../base-component-props.type';
import { IFileUploadHandlerFactory } from '../../../utilities/file-upload';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import { ObUploadButton } from '../../elements/ob-upload-button/ob-upload-button';
import { ObUploadDropzone } from '../../elements/ob-upload-dropzone/ob-upload-dropzone';
import { ObPhotoTile, ObPhotoTileProps } from '../ob-photo-tile/ob-photo-tile';

/**
 * Photo Tile Grid component properties.
 */
export interface ObPhotoTileGridProps
  extends StateManagedByParentInputValueOnly<Array<PhotoTileStateProps>> {
  fileUploadHandlerFactory: IFileUploadHandlerFactory;
  onAssetDetailsClicked?: (
    assetId: string,
    onAssetUpdatedCallback?: (updatedAsset: Partial<AssetResource>) => void
  ) => Promise<any>;
}

type PhotoTileStateProps = Omit<
  ObPhotoTileProps,
  'onKeyPhotoButtonClicked' | 'actions'
>;

/**
 * The events that can trigger a state change in the photo tile grid.
 */
type StateChangeEvent =
  | 'KEY_PHOTO_SET'
  | 'PHOTO_REMOVED'
  | 'PHOTO_ADDED'
  /**
   * The description is technically a part of the asset resource (Alt Text).
   * This update only applies to the front end and does not update the asset resource.
   *
   * It is needed since updates to the asset resource won't be applied to the UI if this component
   * is used in a form renderer and the field is dirty.
   */
  | 'DESCRIPTION_UPDATED';

/**
 * Handles state changes for the photo tile grid.
 */
export const handleStateChangeEvent = (
  currentState: Array<PhotoTileStateProps>,
  event: StateChangeEvent,
  eventData: any
) => {
  if (currentState == null) {
    currentState = [];
  }
  switch (event) {
    case 'KEY_PHOTO_SET':
      return currentState.map((photo) => {
        if (photo.assetId === eventData.assetId) {
          return { ...photo, isKeyPhoto: true };
        }
        return { ...photo, isKeyPhoto: false };
      });
    case 'PHOTO_REMOVED': {
      let isRemovedPhotoTheKeyPhoto = false;
      // If the photo being removed is the key photo, and there are more photos, set the next photo as the key photo
      const remainingPhotos = currentState.filter((photo) => {
        const isPhotoToRemove = photo.assetId !== eventData.assetId;
        if (isPhotoToRemove && eventData.isKeyPhoto) {
          console.log('Photo');
          isRemovedPhotoTheKeyPhoto = true;
        }
        return isPhotoToRemove;
      });
      if (remainingPhotos.length > 0 && isRemovedPhotoTheKeyPhoto) {
        remainingPhotos[0].isKeyPhoto = true;
      }
      return remainingPhotos;
    }
    case 'PHOTO_ADDED': {
      const newPhoto: Omit<
        ObPhotoTileProps,
        'onKeyPhotoButtonClicked' | 'actions'
      > = {
        assetId: eventData.data.id,
        assetAltText: eventData.data.description,
        isKeyPhoto: currentState.length === 0, // If this is the first photo, make it the key photo
        assetUrl: eventData.data.publicUrl,
      };
      return [...currentState, newPhoto];
    }
    case 'DESCRIPTION_UPDATED':
      return currentState.map((photo) => {
        if (photo.assetId === eventData.assetId) {
          return { ...photo, assetAltText: eventData.description };
        }
        return photo;
      });
    default:
      return currentState;
  }
};

/**
 * Renders the Photo Tile Grid Component.
 * A UI for displaying a grid of value.
 */
export const ObPhotoTileGrid: React.FC<ObPhotoTileGridProps> = ({
  value,
  onValueChangedCallback,
  fileUploadHandlerFactory,
  onAssetDetailsClicked,
}: ObPhotoTileGridProps) => {
  const handleEvent = (event: StateChangeEvent, eventData: any) => {
    const nextValue = handleStateChangeEvent(value, event, eventData);
    if (nextValue != null) {
      onValueChangedCallback(nextValue);
    }
  };

  if (fileUploadHandlerFactory == null) {
    console.error(
      'You must pass a fileUploadHandlerFactor in order for this component to work'
    );
    return (
      <div className='hidden'>
        <ObTypography color='negative'>
          DEVELOPER ERROR. You must pass a fileUploadHandlerFactor in order for
          this component to work
        </ObTypography>
      </div>
    );
  }

  /**
   * Handle the case where there are no photos yet. We will provide a large dropzone for the user to upload photos.
   */
  if (value == null || value.length === 0) {
    return (
      <div className='flex flex-1 min-h-[188px]'>
        <ObUploadDropzone
          acceptedFileTypes={['image']}
          fileUploadHandlerFactory={fileUploadHandlerFactory}
          onFileUploadComplete={(file) => {
            handleEvent('PHOTO_ADDED', file);
          }}
          disabled={false}
        />
      </div>
    );
  }

  /**
   * When there is at least one photo, we will display the value in a grid.
   */
  return (
    <div className='grid gap-4 grid-cols-1 sm:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4'>
      <div className='flex flex-1 min-h-[188px]'>
        <ObUploadButton
          shape='responsive'
          ariaLabel='Upload a Photo'
          size='responsive'
          onFileUploadComplete={(file) => {
            handleEvent('PHOTO_ADDED', file);
          }}
          disabled={false}
          fileUploadHandlerFactory={fileUploadHandlerFactory}
          acceptedFileTypes={['image']}
        />
      </div>
      {value.map((photo, i) => (
        <ObPhotoTile
          key={photo.assetId}
          {...photo}
          actions={[
            {
              title: 'Edit Details',
              ariaLabel: `Edit Photo ${i + 1} Details from Subservice`,
              icon: 'pencil01',
              isDisabled: photo.assetId == null,
              onClickCallback: () => {
                onAssetDetailsClicked?.(
                  photo.assetId,
                  (updatedAsset: Partial<AssetResource>) => {
                    if (updatedAsset.description != null) {
                      handleEvent(
                        'DESCRIPTION_UPDATED',

                        { ...photo, description: updatedAsset.description }
                      );
                    }
                  }
                );
                //I could make a deterministic update here.
              },
            },
            {
              title: 'Unlink Photo',
              ariaLabel: `Unlink Photo ${i + 1} from Subservice`,
              icon: 'close',
              onClickCallback: () => handleEvent('PHOTO_REMOVED', { ...photo }),
            },
          ]}
          onKeyPhotoButtonClicked={() =>
            handleEvent('KEY_PHOTO_SET', {
              assetId: photo.assetId,
            })
          }
        />
      ))}
    </div>
  );
};
