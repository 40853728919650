import { FieldValidationResult } from '@otbnd/utils';
import { TemplateSettingFieldType } from '@outbound/types';
import { action, computed, makeObservable, observable } from 'mobx';
import { BaseModel } from '../../base-model';
import { RootStore } from '../../root-store';

abstract class BaseSetting extends BaseModel {
  protected _name: string;
  protected _type: TemplateSettingFieldType;
  protected _isRemixLocked: boolean;

  protected _isRequired: boolean = false;
  protected _validationErrors: string[] = [];

  protected _fieldValidationResult: FieldValidationResult | null = null;

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    obrn: string,
    name: string,
    type: TemplateSettingFieldType,
    isRemixLocked: boolean
  ) {
    super(rootStore, 'creative/setting', '1', id, workspaceId, obrn);
    this._name = name;
    this._type = type;
    this._isRemixLocked = isRemixLocked;
  }

  makeObservableInternal() {
    makeObservable(this, {
      _name: observable,
      _isRemixLocked: observable,
      _fieldValidationResult: observable,
      _validationErrors: observable,
      validationErrors: computed,
      isValid: computed,
      isRemixLocked: computed,
      toggleRemixLock: action,
    } as any);
  }

  toJson(): Record<string, any> {
    throw new Error('Method not implemented.');
  }

  get name(): string {
    return this._name;
  }

  get type(): TemplateSettingFieldType {
    return this._type;
  }

  get validationErrors(): string[] {
    return this._fieldValidationResult?.errors ?? [];
  }

  get isRemixLocked(): boolean {
    return this._isRemixLocked;
  }

  get isValid(): boolean {
    return this._fieldValidationResult?.isValid ?? false;
  }

  get value(): any {
    return null;
  }

  get isRequired(): boolean {
    return this._isRequired;
  }

  toggleRemixLock(): void {
    this._isRemixLocked = !this._isRemixLocked;
  }
}

export default BaseSetting;
