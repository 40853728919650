import { ObTag } from '../../../../index';
import { RuleType } from '../ob-rule-builder.types';

interface RuleBodyBadgesArrayProps {
  values: RuleType['values'];
}

export const RuleBodyBadgesArray = ({
  values = [],
}: RuleBodyBadgesArrayProps) => {
  return (
    <div className='w-full flex flex-row flex-wrap gap-2 '>
      {values.map((ruleValue) => (
        <ObTag
          key={ruleValue.key}
          content={ruleValue.displayValue ?? ruleValue.value.toString()}
          selectable={false}
          removable={false}
        />
      ))}
    </div>
  );
};
