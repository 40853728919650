import {
  ServerDelta,
  SubServiceResource,
  SubServiceResourceIncludeAssets,
} from '@outbound/types';
import { BaseTransformer } from '../../../base-transformer';

import { RootStore } from '../../../root-store';
import ServiceOffering from './service-offering';

export class ServiceOfferingTransformer extends BaseTransformer<
  {
    serviceId: string;
    subService: SubServiceResourceIncludeAssets;
    workspaceId: string;
  },
  ServiceOffering
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }
  fromApiResource(resource: {
    serviceId: string;
    subService: SubServiceResource;
    workspaceId: string;
  }): ServiceOffering {
    return new ServiceOffering(
      this._rootStore,
      resource.subService.id,
      resource.workspaceId,
      resource.subService.name,
      resource.subService.description ?? '',
      resource.serviceId,
      resource.subService.keyPhotoAssetId,
      resource.subService.additionalPhotoAssetIds ?? []
    );
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: ServiceOffering,
    _resource: {
      serviceId: string;
      subService: SubServiceResourceIncludeAssets;
    }
  ): ServerDelta[] {
    return [];
  }
}
