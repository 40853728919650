export const GoogleDisplayAdSizeConfig = {
  square: { height: '250px', width: '250px' },
  smallSquare: { height: '200px', width: '200px' },
  banner: { height: '60px', width: '468px' },
  leaderboard: { height: '90px', width: '728px' },
  mediumRectangle: { height: '250px', width: '300px' },
  largeRectangle: { height: '280px', width: '336px' },
  skyscraper: { height: '600px', width: '120px' },
  wideSkyscraper: { height: '600px', width: '160px' },
  largeSkyscraper: { height: '600px', width: '300px' },
  largeMobileBanner: { height: '100px', width: '320px' },
};
