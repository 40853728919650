import { FormDefinition, ObFormRenderer } from '@outbound/design-system';
import { SettingCardRow } from '@outbound/design-system/src/components/organisms/ob-setting-card-row/setting-card-row';
import { SettingCardRowWithButton } from '@outbound/design-system/src/components/organisms/ob-setting-card-with-button/setting-card-row-with-button';
import { FC, useState } from 'react';
import { SettingCardRowWithIcon } from './setting-card-row-with-icon';

import { IconSystemKeys } from '@outbound/design-system/src/tokens/icons/icons';
import { ROLE } from '../../utils/requires-role/requires-role';

interface SettingCardRowWithInlineFormProps {
  /**
   * The name of the setting
   */
  title: string;
  /**
   * A text description of the value of the setting or the action of the action button
   */
  body: string;
  /**
   * Sets the component to a loading State.
   * Useful for when the data needed to populate the component is fetched asynchronously
   */
  loading: boolean;
  /**
   * The text that will display on the Button that will switch the setting row from read mode to edit mode
   */
  editButtonLabel?: string;

  /**
   * Accessible label describing the edit button's functionality.
   * Such as 'Edit Workspace Name'
   */
  editButtonAriaLabel?: string;
  /**
   * A callback function to be called when the form is submitted
   * The form values will be passed to this function as an argument.
   * This is where you should do the work calling an API or whatever
   * action you want to take on form submission.
   */
  handleFormSubmitCallback: (data: any) => Promise<any>;
  /**
   * The JSON definition of the form that should be displayed
   */
  formDefinition: FormDefinition;
  defaultValues: any;
  /**
   * An array of Application Roles who should be able to edit this setting.
   * In cases where the user does not have the role the edit button will not be displayed
   */
  rolesWhoCanEdit?: ROLE[];
  /**
   * Sets the color of the card to a supported theme color.
   */
  color?: 'error';
  editButtonType?: 'with-button' | 'title-icon';
  icon?: IconSystemKeys;
}
export const SettingCardRowWithInlineForm: FC<
  SettingCardRowWithInlineFormProps
> = ({
  title,
  body,
  formDefinition,
  defaultValues = {},
  loading,
  rolesWhoCanEdit = [ROLE.ANY],
  color,
  editButtonLabel = 'Edit',
  editButtonAriaLabel = 'Edit',
  editButtonType = 'with-button',
  icon = 'pencilLine',
  handleFormSubmitCallback,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleInlineEditFormSubmission = (values: any): Promise<any> => {
    const onSaveCallbackPromise = handleFormSubmitCallback(values);
    onSaveCallbackPromise.then(
      () => {
        //Delay so that the "Success" animation on the thinking button is visible to the user
        setTimeout(() => {
          setIsEditing(false);
        }, 1000);
      },
      () => {
        //If the parent rejects the Save we will keep the inline setting open;
      }
    );
    return onSaveCallbackPromise;
  };

  const getCardRow = () => {
    switch (editButtonType) {
      case 'with-button':
        return (
          <SettingCardRowWithButton
            title={title}
            body={body}
            color={color}
            loading={loading}
            buttonLabel={editButtonLabel}
            buttonAriaLabel={editButtonAriaLabel}
            handleButtonClickCallback={() => {
              setIsEditing(true);
            }}
          />
        );
      case 'title-icon':
        return (
          <SettingCardRowWithIcon
            title={title}
            body={body}
            color={color}
            loading={loading}
            icon={icon}
            buttonAriaLabel={editButtonAriaLabel}
            rolesWhoCanEdit={rolesWhoCanEdit}
            handleButtonClickCallback={() => {
              setIsEditing(true);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {!isEditing && getCardRow()}
      {isEditing && (
        <>
          <SettingCardRow
            title={title}
            body={''}
            loading={false}
          />
          <ObFormRenderer
            formDefinition={formDefinition}
            defaultValues={defaultValues}
            submitButtonLabel='Save'
            onSubmitCallback={handleInlineEditFormSubmission}
            cancelButtonLabel='Cancel'
            onCancelCallback={() => {
              setIsEditing(false);
              return Promise.resolve();
            }}
          />
        </>
      )}
    </>
  );
};
