import { cx } from 'class-variance-authority';
import { useCallback } from 'react';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { IFileUploadHandlerFactory } from '../../../utilities/file-upload';
import { ObThumbnail } from '../../elements/ob-thumbnail/ob-thumbnail';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import { ObUploadButton } from '../../elements/ob-upload-button/ob-upload-button';
import { ObUploadDropzone } from '../../elements/ob-upload-dropzone/ob-upload-dropzone';
import { ObField } from '../../molecules/ob-field/ob-field';
import { BaseFormFieldOption } from '../ob-form-renderer/ob-form-renderer.types';

/**
 * Base props for input field components that are used in the ObFormRenderer
 *
 * Point is to keep a consistent API for all input fields when possible
 */
export interface BaseInputFieldProps<T> {
  inputId: string;
  value: T;
  onValueChangedCallback: (value: T) => void;
}

export interface BaseLoadableProps {
  isLoading?: boolean;
}

export interface ObImageFieldImageGroup {
  label: string;
  exclusiveRow?: boolean;
  size: 'small' | 'medium' | 'x-large';
  aspect: 'square' | 'rectangle';
  images: Array<ObImageFieldImage>;
}

export interface ObImageFieldImage {
  label: string;
  key: string;
}

export interface ObImagesFieldProps
  extends BaseInputFieldProps<Array<BaseFormFieldOption>>,
    BaseLoadableProps {
  imagesToSelect: Array<ObImageFieldImageGroup>;
  fileUploadHandlerFactory: IFileUploadHandlerFactory;
}

export const ObImagesField = ({
  imagesToSelect = [],
  inputId,
  value,
  fileUploadHandlerFactory,
  onValueChangedCallback,
}: ObImagesFieldProps) => {
  const handleInputChange = useCallback(
    (
      key: string,
      newValue: string,
      existingValues: Array<BaseFormFieldOption>,
      data?: any
    ) => {
      let updatedValue;

      if (existingValues.find((image) => image.key === key)) {
        updatedValue = existingValues?.map((image) => {
          if (image.key === key) {
            return {
              ...image,
              displayValue: undefined,
              value: newValue ?? '',
              data,
            };
          }
          return image;
        });
      } else {
        updatedValue = [
          ...existingValues,
          {
            key,
            displayValue: undefined,
            value: newValue?.toUpperCase() ?? '',
          },
        ];
      }
      onValueChangedCallback?.(updatedValue);
    },
    [onValueChangedCallback]
  );
  return (
    <div className='flex flex-wrap  sm:flex-row gap-x-8  '>
      {imagesToSelect.map((group) => {
        return (
          <div
            key={group.label}
            className={cx(
              'flex flex-col',
              group.exclusiveRow ? 'w-full' : 'flex-wrap'
            )}
          >
            <ObTypography
              variant='body1'
              color='primary'
            >
              {group.label}
            </ObTypography>
            <div className={cx('flex flex-row gap-x-4 flex-wrap')}>
              {group.images.map((image) => {
                const id = `${inputId}__${image.key}`;
                const ariaLabel = `${group.label} ${image.label}`;
                const selectedImage = value?.find((c) => c.key === image.key);
                return (
                  <div
                    key={id}
                    className=''
                  >
                    <ObField
                      label={image.label}
                      inputId={id}
                      variant={'field-in-group'}
                    >
                      {!selectedImage?.value && (
                        <>
                          {group.size === 'x-large' && (
                            <ObUploadDropzone
                              disabled={false}
                              size='medium'
                              enableAssetLibrary={false}
                              onFileUploadComplete={({ fileId, data }) => {
                                handleInputChange(
                                  image.key,
                                  fileId,
                                  value,
                                  data
                                );
                              }}
                              fileUploadHandlerFactory={
                                fileUploadHandlerFactory
                              }
                            />
                          )}
                          {group.size !== 'x-large' && (
                            <ObUploadButton
                              size={group.size}
                              shape={group.aspect}
                              onFileUploadComplete={({ fileId, data }) => {
                                handleInputChange(
                                  image.key,
                                  fileId,
                                  value,
                                  data
                                );
                              }}
                              fileUploadHandlerFactory={
                                fileUploadHandlerFactory
                              }
                              disabled={false}
                            />
                          )}
                        </>
                      )}
                      {selectedImage?.value && (
                        <div className='relative group'>
                          <ObThumbnail
                            onClickHandler={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            imageFit={'contain'}
                            preferredBackground={
                              selectedImage.data?.recommendedBackground ===
                              'LIGHT'
                                ? 'light'
                                : 'dark'
                            }
                            shape={group.aspect}
                            size={group.size}
                            publicUrl={selectedImage.data?.publicUrl}
                          />
                          <div className='absolute -top-3 -right-3 opacity-0 group-hover:opacity-100 transition-opacity'>
                            <button
                              className='origin-center hover:h-7 hover:w-7 transition-all duration-300 rounded-full h-6 w-6 flex justify-center items-center bg-contextualNegativeDark border border-borderNegativeNormalDark'
                              type='button'
                              aria-label={`Remove ${ariaLabel} image`}
                              onClick={() =>
                                handleInputChange(image.key, '', value)
                              }
                            >
                              <ObIcon
                                icon='close'
                                color='content'
                                size={'x-small'}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </ObField>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
