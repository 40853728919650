import { CampaignHighlightObjectType } from '@outbound/types';
import { ReactNode } from 'react';
import { ObBadge, ObButton } from '../..';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObCardHeaderWithMenu } from '../../cards/components/ob-card-header-menu/ob-card-header-menu';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantDeprecatedProps } from '../card.type';

interface CampaignHighlightAttributeProps {
  /**
   * Indicates if the highlight is currently enabled for the campaign
   */
  isEnabled: boolean;

  /**
   * The type of "Business Context" object that this highlight is highlighting.
   */
  highlightType: CampaignHighlightObjectType;

  /**
   * Unique ID of the object being highlighted. May not strictly be needed here?
   */
  highlightedObjectId: string;
}

interface CampaignHighlightCtaProps {
  onIsEnabledChanged: (isEnabled: boolean) => void;
  onRemoveHighlightFromCampaignClicked: () => void;
  /**
   * Callback to notify the parent that the user has clicked to add a landing page to the highlight
   * We expect the parent to start the Add Landing Page Workflow in response to this callback.
   * @returns
   */
  addLandingPageToHighlightClicked: () => void;
}

/**
 * React Nodes for each "Slot" of Black Boxed Embedded Content in the Campaign Highlights Card
 */
interface CampaignHighlightsBlackBoxedEmbeddedContentProps {
  /**
   * React Node that renders the highlighted object
   */
  highlightedObjectNode?: ReactNode;

  /**
   * Creative is Black Boxed Embedded Content. We expect the parent to provide the creative in a Black Boxed Card
   */
  associatedCreativesSlot?: ReactNode;

  /**
   * Landing Page is Black Boxed Embedded Content. We expect the parent to provide the landing page in a Black Boxed Card
   * If no landing page is provided we will render a call to action to add a landing page
   */
  associatedLandingPagesSlot?: ReactNode;
}

export interface CampaignHighlightCardProps
  extends CardVariantDeprecatedProps,
    CampaignHighlightCtaProps,
    CampaignHighlightAttributeProps,
    CampaignHighlightsBlackBoxedEmbeddedContentProps {}

/**
 * Interface passed to different variants of this card
 */
export type CampaignHighlightProps = Omit<
  CampaignHighlightCardProps,
  'variant'
>;

export const CampaignHighlightCard = ({
  variant,
  highlightType,
  highlightedObjectId: highlightId,
  ...props
}: CampaignHighlightCardProps) => {
  /**
   * Unique ID for the campaign highlight that can be used as a base for other identifiers such as labels.
   */
  switch (variant) {
    case 'grid':
      return (
        <GridCard
          highlightedObjectId={highlightId}
          highlightType={highlightType}
          {...props}
        />
      );
    case 'micro':
    case 'mini':
    case 'list':
    case 'detail':
      return <ObTypography color='negative'>Not Implemented</ObTypography>;
  }
};

const highLightTypeLabel = (highlightType: CampaignHighlightObjectType) => {
  switch (highlightType) {
    case 'service-offering':
      return 'Service Offering';
    case 'service':
      return 'Service';
    default:
      return 'Unknown';
  }
};

//TEMP REMOVED UNTIL WE CAN FULLY IMPLEMENT
// const menuItemDisableHighlight = (
//   onIsEnabledChanged: (isEnabled: boolean) => any
// ): ObActionMenuItemProps => ({
//   title: 'Disable Highlight',
//   icon: 'minus',
//   onClickCallback: () => onIsEnabledChanged(false),
//   ariaLabel: '',
// });

// const menuItemEnableHighlight = (
//   onIsEnabledChanged: (isEnabled: boolean) => any
// ): ObActionMenuItemProps => ({
//   title: 'Enable Highlight',
//   icon: 'add',
//   onClickCallback: () => onIsEnabledChanged(true),
//   ariaLabel: '',
// });

const GridCard = ({
  highlightType,
  highlightedObjectNode: highlightNode,
  isEnabled,
  associatedCreativesSlot,
  associatedLandingPagesSlot,
  addLandingPageToHighlightClicked,
}: CampaignHighlightProps) => {
  return (
    <ObCardSurface>
      <div className='p-3 flex flex-col gap-3 '>
        <ObCardHeaderWithMenu
          heading={'Campaign Highlight'}
          icon={'megaphone'}
          menuItems={
            [
              // TEMP REMOVED UNTIL WE CAN FULLY IMPLEMENT
              // ...(isEnabled
              //   ? [menuItemDisableHighlight(onIsEnabledChanged)]
              //   : []),
              // ...(!isEnabled
              //   ? [menuItemEnableHighlight(onIsEnabledChanged)]
              //   : []),
              // {
              //   title: 'Delete Highlight',
              //   icon: 'delete',
              //   onClickCallback: () => onIsEnabledChanged(false),
              //   ariaLabel: '',
              // },
            ]
          }
          rightContent={
            <div className=''>
              <ObBadge
                variant='rounded'
                content={isEnabled ? 'Enabled'.toLocaleUpperCase() : 'DISABLED'}
                level={isEnabled ? 'SUCCESS' : 'NEUTRAL'}
              />
            </div>
          }
        />
        <div>
          <ObBadge
            variant='rounded'
            content={highLightTypeLabel(highlightType).toLocaleUpperCase()}
            level='DEFAULT'
          />
        </div>
        <div>{highlightNode}</div>

        <ObTypography
          variant='body3'
          color='tertiary'
        >
          Ad Creative
        </ObTypography>
        {associatedCreativesSlot == null && <MissingAdCreative />}
        {/* Black Boxed Mini Creative Card for Each Associated Creative */}
        {associatedCreativesSlot != null && (
          <div className='flex flex-col gap-3'>{associatedCreativesSlot}</div>
        )}
        <ObTypography
          variant='body3'
          color='tertiary'
        >
          Landing Page
        </ObTypography>
        {associatedLandingPagesSlot == null ? (
          <div className='rounded border border-dotted border-dark/border/default/normal p-2 h-[64px] flex items-center justify-center'>
            <ObButton
              iconLeft='add'
              label='Add Landing Page'
              variant='ghost'
              size='medium'
              onClick={(e) => {
                e.stopPropagation(); //Don't bubble up to the card
                addLandingPageToHighlightClicked?.();
              }}
            />
          </div>
        ) : (
          associatedLandingPagesSlot
        )}
      </div>
    </ObCardSurface>
  );
};

const MissingAdCreative = () => {
  return (
    <div className='rounded border border-dotted border-dark/border/default/normal p-2 h-[64px] flex items-center justify-center'>
      <ObTypography
        variant='body3'
        color='tertiary'
      >
        Ad Creative Not Provided
      </ObTypography>
    </div>
  );
};
