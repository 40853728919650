import {
  DialogServiceProvider,
  DrawerServiceProvider,
  NotificationServiceProvider,
  ObFormRendererProvider,
} from '@outbound/design-system';
import {
  RenderHookResult,
  RenderOptions,
  act,
  fireEvent,
  render,
  renderHook,
} from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MemoryRouter } from 'react-router-dom';
import { Client, Provider } from 'urql';
import { VitestUtils } from 'vitest';

//Custom Setup for React Testing Library Adapted from:
//https://testing-library.com/docs/react-testing-library/setup/

const MOCK_URL = 'mock-api.dev.outbound.com';

/**
 * Wraps the children in the Design System Providers
 * @param children
 * @returns
 */
export const DesignSystemProviders = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <ObFormRendererProvider>
      <NotificationServiceProvider>
        <DialogServiceProvider>
          <DrawerServiceProvider>{children}</DrawerServiceProvider>
        </DialogServiceProvider>
      </NotificationServiceProvider>
    </ObFormRendererProvider>
  );
};

export const getDOMRect = (width: number, height: number) => ({
  width,
  height,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  x: 0,
  y: 0,
  toJSON: () => {
    //Intentionally left blank
  },
});

/**
 *
 * Called in the beforeEach function of the test file to mock the getBoundingClientRect function of the Element prototype.
 */
export const beforeEachVirtualized = (vi: VitestUtils) => {
  Element.prototype.getBoundingClientRect = vi.fn(function (this: HTMLElement) {
    if (this.getAttribute('data-test-virtual') != null) {
      return getDOMRect(500, 500);
    }
    return getDOMRect(0, 0);
  });
};

/**
 * Utility function to simulate a file drop event including a data transfer object
 * and data transfer items for the files.
 *
 * Our JSDOM version does not support the DataTransfer or DataTransferItemList interface, so we have to mock it.
 * @param files
 * @returns
 */
const createMockFilesDropEvent = (files: File[]) => {
  return {
    dataTransfer: {
      items: files.map((f: File) => {
        return { type: f.type, kind: 'file', getAsFile: () => f };
      }),
      files,
    },
  };
};

const dropFilesOnElement = (element: HTMLElement, files: Array<File>) => {
  act(() => {
    fireEvent.drop(element, createMockFilesDropEvent(files));
  });
};

const pressEscapeKey = async () => {
  await userEvent.keyboard('{Escape}');
};

const pressEnterKey = async () => {
  await userEvent.keyboard('{Enter}');
};

const renderWithUserEvent = (
  jsx: ReactElement,
  renderOptions?: RenderOptions
) => {
  return {
    user: {
      ...userEvent.setup(),
      dropFilesOnElement,
      pressEscapeKey,
      pressEnterKey,
    },
    ...render(jsx, renderOptions),
  };
};

type RenderWithRouteOptions = { route: string } & RenderOptions;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ turns retries off
      retry: false,
    },
  },
});
const wrapper = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export function renderHookWithReactQueryClient<T, P extends any[]>(
  hook: (...args: P) => T,
  ...args: P
): RenderHookResult<T, P> {
  return renderHook(() => hook(...args), {
    wrapper,
  });
}

export const renderWithRoute = (
  ui: ReactElement,
  { route, ...options }: RenderWithRouteOptions
) =>
  renderWithUserEvent(
    <MemoryRouter initialEntries={[route]}>{ui}</MemoryRouter>,
    options
  );

export type ProviderValueMock = Partial<{
  executeQuery: () => any;
  executeMutation: () => any;
  executeSubscription: () => any;
}>;

export type RenderWithProviderOptions = {
  mock?: Client | ProviderValueMock;
} & RenderWithRouteOptions;

export const renderWithProvider = (
  ui: ReactElement,
  {
    mock = new Client({ url: MOCK_URL }),
    ...options
  }: RenderWithProviderOptions
) =>
  //@ts-ignore
  renderWithRoute(<Provider value={mock}>{ui}</Provider>, options);

export * from '@testing-library/react';

/**
 * Override the built in render function from testing-library/react
 * with our extended render function that includes an initialized user object
 */
export { renderWithUserEvent as render };
