export const Performance = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.10763 1.33334H11.8924C12.2438 1.33333 12.547 1.33332 12.7967 1.35372C13.0602 1.37526 13.3224 1.4228 13.5746 1.55133C13.951 1.74308 14.2569 2.04904 14.4487 2.42536C14.5772 2.67762 14.6247 2.93976 14.6463 3.20333C14.6667 3.45304 14.6667 3.7562 14.6667 4.10762V4.44516C14.6667 4.5459 14.6667 4.59626 14.6532 4.64226C14.6412 4.683 14.6215 4.72107 14.5953 4.75444C14.5656 4.79212 14.5246 4.82132 14.4425 4.87972L9.40608 8.46335L7.44734 6.50461C7.39938 6.45661 7.3406 6.39779 7.28425 6.34935C7.21773 6.29218 7.12003 6.21823 6.98646 6.1673C6.8104 6.10018 6.61897 6.08416 6.43421 6.12109C6.29402 6.14911 6.18539 6.20579 6.1103 6.25111C6.04668 6.28951 5.97895 6.33774 5.92368 6.3771L2.17586 9.04382C1.90354 9.23759 1.76738 9.33448 1.65389 9.33012C1.55507 9.32633 1.46305 9.27887 1.40267 9.20055C1.33333 9.11061 1.33333 8.94349 1.33333 8.60927V4.10763C1.33332 3.75621 1.33331 3.45304 1.35371 3.20333C1.37524 2.93976 1.42279 2.67762 1.55132 2.42536C1.74306 2.04904 2.04902 1.74308 2.42535 1.55133C2.6776 1.4228 2.93975 1.37526 3.20331 1.35372C3.45302 1.33332 3.75621 1.33333 4.10763 1.33334Z'
        fill='currentColor'
      />
      <path
        d='M1.33333 11.5548C1.33333 11.4541 1.33333 11.4037 1.34684 11.3577C1.35881 11.317 1.37844 11.2789 1.4047 11.2455C1.43435 11.2079 1.47538 11.1787 1.55746 11.1203L6.59381 7.5367L8.55254 9.49544C8.6005 9.54343 8.65928 9.60226 8.71564 9.6507C8.78215 9.70787 8.87985 9.78182 9.01343 9.83274C9.18948 9.89987 9.38092 9.91589 9.56568 9.87896C9.70586 9.85094 9.8145 9.79425 9.88959 9.74893C9.9532 9.71054 10.0209 9.66231 10.0762 9.62296L13.8241 6.95615C14.0965 6.76238 14.2326 6.66549 14.3461 6.66985C14.4449 6.67364 14.5369 6.7211 14.5973 6.79942C14.6667 6.88936 14.6667 7.05648 14.6667 7.39071V11.8924C14.6667 12.2438 14.6667 12.547 14.6463 12.7967C14.6247 13.0603 14.5772 13.3224 14.4487 13.5747C14.2569 13.951 13.951 14.2569 13.5746 14.4487C13.3224 14.5772 13.0602 14.6248 12.7967 14.6463C12.547 14.6667 12.2438 14.6667 11.8924 14.6667H4.10762C3.75619 14.6667 3.45302 14.6667 3.20331 14.6463C2.93975 14.6248 2.6776 14.5772 2.42535 14.4487C2.04902 14.2569 1.74306 13.951 1.55132 13.5747C1.42279 13.3224 1.37524 13.0603 1.35371 12.7967C1.33331 12.547 1.33332 12.2438 1.33333 11.8924V11.5548Z'
        fill='currentColor'
      />
    </svg>
  );
};
