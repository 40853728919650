import { Fragment, ReactNode, useEffect, useState } from 'react';
import { SubscriptionPlanProps } from '../../../../base-component-props.type';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';
import { ObDivider } from '../../../../components/elements/ob-divider/ob-divider';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { ObFormRenderer } from '../../../../components/organisms/ob-form-renderer/ob-form-renderer';
import { FormFieldType } from '../../../../components/organisms/ob-form-renderer/ob-form-renderer.types';
import {
  LandingPageTypeCard,
  LandingPageTypeCardProps,
} from './components/landing-page-type-card';

export interface AddLandingPageWorkflowProps extends SubscriptionPlanProps {
  /**
   * Callback function called when a user selects an existing landing page
   */
  onExistingLandingPageSelected: (obrn: string) => void;
  /**
   * Callback function when a user has provided a self-hosted landing page URL
   */
  onSelfHostedLandingPageUrlProvided: (uri: string) => void;
  onCanceled: () => void;
  /**
   * A list of previously used landing pages that the user could select from
   * for this highlight.
   */
  availableLandingPageListNode?: ReactNode;

  /**
   * The current number of available landing pages that the user can use.
   * (Used to make decisions about the UX such as no content states)
   */
  availableLandingPageCount: number;

  /**
   * Existing Selected Landing Page OBRN to Confirm
   * This should be set when a user selects a current landing page
   *
   * TODO CHECK IF WE CAN SOMEHOW PASS THIS?
   */
  existingSelectedLandingPageObrn?: string;

  availableLandingPages: Array<string>;
  mobxRenderFunction: (
    obrn: string,
    /**
     * The Callback function we want the parent to assign to the rendered card
     */
    onClickCallback: () => void
  ) => ReactNode;

  onUpgradePlanClicked: () => void;

  /**
   * Indicates how the workflow is being used
   * ADD_OR_SELECT: The landing page will be created and added to the given highlight immediately
   * ADD_ONLY: The landing page will be created but not added to the highlight.
   *
   * There is no difference between the two modes when selecting an Outbound AI Landing Page since those require a highlight.
   * For Self Hosted Landing Pages this gives an opertunity to create the landing page without needing to assign it right away.
   */
  mode: 'ADD_OR_SELECT' | 'ADD_ONLY';
}

interface StepperData {
  selectedLandingPageType?: 'SELF_HOSTED' | 'OUTBOUND_AI';
  selectedLandingPageObrn?: string;
  newExternalLandingPageStage:
    | 'not-applicable'
    | 'configuring'
    | 'success'
    | 'error';
  newExternalLandingPageUri: '';
}

const SelfHostedLandingPageTypeCard = ({
  ctaOnClickCallback,
  currentSubscriptionPlan,
}: Pick<
  LandingPageTypeCardProps,
  'ctaOnClickCallback' | 'currentSubscriptionPlan'
>) => {
  return (
    <LandingPageTypeCard
      title={'Self Hosted Page'}
      userIntentCopy={
        'I already have a landing page or website that I would like to manage and optimize.'
      }
      planFeatures={[
        'You Host the Page',
        'No Ad ↔ Landing Page Optimization',
        'No Lead Tracking',
        'Ads may not perform as well',
        'Less efficient Ad Spend',
      ]}
      requiresStarterPlanOrHigher={false}
      ctaLabel={'Self Host Landing Page'}
      ctaOnClickCallback={ctaOnClickCallback}
      currentSubscriptionPlan={currentSubscriptionPlan}
    />
  );
};

const OutboundAiLandingPageTypeCard = ({
  ctaOnClickCallback,
  currentSubscriptionPlan,
}: Pick<
  LandingPageTypeCardProps,
  'ctaOnClickCallback' | 'currentSubscriptionPlan'
>) => {
  return (
    <LandingPageTypeCard
      title={'Outbound AI Page'}
      userIntentCopy={
        'I want Outbound to automatically setup, host and optimize my landing page.'
      }
      planFeatures={[
        'Page Hosting Included',
        'Optimization Included',
        'Lead Tracking Included',
        'Uses your Branding',
        'More efficient Ad Spend',
      ]}
      requiresStarterPlanOrHigher={true}
      ctaLabel={'Use Outbound AI Page'}
      ctaOnClickCallback={ctaOnClickCallback}
      currentSubscriptionPlan={currentSubscriptionPlan}
    />
  );
};

/**
 * User Flow of Adding a Landing Page to a Highlight
 * @param param0
 * @returns
 */
export const AddLandingPageWorkflow = ({
  onExistingLandingPageSelected,
  onSelfHostedLandingPageUrlProvided,

  onUpgradePlanClicked,
  availableLandingPages,
  mobxRenderFunction,
  currentSubscriptionPlan,
  mode = 'ADD_OR_SELECT',
}: AddLandingPageWorkflowProps) => {
  const [stepperData, setStepperData] = useState<StepperData>({
    newExternalLandingPageStage: 'not-applicable',
    newExternalLandingPageUri: '',
  });

  const [step, setStep] = useState<
    'TYPE_PICKER' | 'SELF_HOSTED_URI_ENTRY' | 'CONFIRM_PREVIOUS_USED_PAGE'
  >('TYPE_PICKER');

  useEffect(() => {
    console.log('Stepper Data Updated', JSON.stringify(stepperData));
    //TEMP DEV CODE
    if (stepperData.newExternalLandingPageStage === 'configuring') {
      setTimeout(() => {
        setStepperData((prev) => {
          return {
            ...prev,
            newExternalLandingPageStage: 'success',
          };
        });
      }, 3000);
    }
  }, [stepperData]);

  /**
   * Render the Type Picker Step
   */
  if (step == 'TYPE_PICKER') {
    return (
      <div className='flex flex-col gap-10 justify-center items-center max-w-2xl m-auto'>
        <div className='flex flex-col justify-center items-center flex-1 gap-1'>
          <ObTypography
            variant='h2'
            className='text-center'
          >
            Self Hosted or Outbound AI?
          </ObTypography>
          <ObTypography
            variant='h4'
            color='secondary'
            className='text-center'
          >
            Choose where users should be sent to when they click on your Ads
          </ObTypography>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
          <SelfHostedLandingPageTypeCard
            ctaOnClickCallback={() => {
              setStepperData((prev) => {
                return {
                  ...prev,
                  selectedLandingPageType: 'SELF_HOSTED',
                };
              });
              setStep('SELF_HOSTED_URI_ENTRY');
            }}
            currentSubscriptionPlan={currentSubscriptionPlan}
          />
          <OutboundAiLandingPageTypeCard
            ctaOnClickCallback={() => {
              if (currentSubscriptionPlan === 'FREE') {
                onUpgradePlanClicked();
                return;
              }
            }}
            currentSubscriptionPlan={currentSubscriptionPlan}
          />
        </div>
      </div>
    );
  }

  /**
   * Render the Self Hosted URI Entry Step
   */
  if (step == 'SELF_HOSTED_URI_ENTRY') {
    return (
      <div className='flex flex-col gap-10 justify-center items-centermax-w-2xl m-auto'>
        <div className='flex flex-col justify-center items-center flex-1'>
          <ObTypography
            variant='h2'
            className='text-center'
          >
            Self Hosted Page
          </ObTypography>
          <ObTypography
            variant='h4'
            color='secondary'
            className='text-center'
          >
            I already have a landing page setup that I want to use.
          </ObTypography>
        </div>

        <ObFormRenderer
          className='flex flex-col flex-1 self-stretch'
          formDefinition={{
            id: 'self-hosted-uri-entry',
            heading: '',
            subHeading: '',
            sections: [
              {
                id: 'self-hosted-uri-entry',

                fields: [
                  {
                    id: 'uri',
                    label: 'Landing Page URL',
                    autofocus: true,

                    type: FormFieldType.TEXT,
                    validationSchema: {
                      isRequired: true,
                    },
                    helperText:
                      'Enter the URL of the landing page you want to use.',

                    fieldTypeSettings: {},
                  },
                ],
                heading: '',
                subHeading: '',
              },
            ],
          }}
          submitButtonFullWidth='always'
          submitButtonLabel='Add Landing Page'
          onSubmitCallback={(formData) => {
            if (formData.uri) {
              setStepperData((prev) => {
                return {
                  ...prev,
                  newExternalLandingPageUri: formData.uri,
                  newExternalLandingPageStage: 'configuring',
                };
              });

              onSelfHostedLandingPageUrlProvided(formData.uri);
            }

            return Promise.resolve();
          }}
        />

        {mode === 'ADD_OR_SELECT' && (
          <>
            <div className='flex flex-row flex-nowrap gap-3 flex-1 self-stretch justify-center items-center'>
              <ObDivider variant='default' />

              <ObTypography
                variant='body3'
                color='secondary'
                className='text-center'
              >
                or
              </ObTypography>
              <ObDivider />
            </div>
            <ObTypography variant='h3'>
              Previously Used Landing Pages
            </ObTypography>
            {/* RENDER MOBX OBSERVED MODELS HERE */}
            {availableLandingPages != null &&
              availableLandingPages.length > 0 && (
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
                  {availableLandingPages.map((landingPageObrn) => {
                    return (
                      <Fragment key={landingPageObrn}>
                        {mobxRenderFunction(landingPageObrn, () => {
                          setStepperData((prev) => {
                            return {
                              ...prev,
                              selectedLandingPageObrn: landingPageObrn,
                            };
                          });
                          setStep('CONFIRM_PREVIOUS_USED_PAGE');
                        })}
                      </Fragment>
                    );
                  })}
                </div>
              )}
            {(availableLandingPages == null ||
              availableLandingPages.length === 0) && (
              <ObTypography
                variant='body3'
                color='tertiary'
              >
                Once you add a self hosted landing page it will be available
                here for future use.
              </ObTypography>
            )}
          </>
        )}
      </div>
    );
  }

  if (step == 'CONFIRM_PREVIOUS_USED_PAGE') {
    return (
      <div className='flex flex-col gap-10 justify-center items-center max-w-2xl m-auto'>
        <div className='flex flex-col justify-center items-center flex-1'>
          <ObTypography
            variant='h2'
            className='text-center'
          >
            Confirm Landing Page
          </ObTypography>
          <ObTypography
            variant='h4'
            color='secondary'
            className='text-center'
          >
            Click Add to Confirm your Selection
          </ObTypography>
        </div>

        <div className='flex flex-col gap-10 justify-center items-center flex-1 self-stretch'>
          {availableLandingPages != null &&
            availableLandingPages.length > 0 && (
              <div>
                {availableLandingPages
                  .filter((lp) => lp === stepperData.selectedLandingPageObrn)
                  .map((landingPageObrn) => {
                    return (
                      <Fragment key={landingPageObrn}>
                        {mobxRenderFunction(landingPageObrn, () => {
                          setStepperData((prev) => {
                            return {
                              ...prev,
                              selectedLandingPageObrn: landingPageObrn,
                            };
                          });
                          setStep('CONFIRM_PREVIOUS_USED_PAGE');
                        })}
                      </Fragment>
                    );
                  })}
              </div>
            )}

          <div className='flex flex-col gap-2 flex-1 self-stretch'>
            <ObButton
              variant='primary'
              className=''
              label='Add Landing Page'
              size={'large'}
              fullWidth='always'
              disabled={stepperData.selectedLandingPageObrn == null}
              onClick={() => {
                onExistingLandingPageSelected?.(
                  stepperData.selectedLandingPageObrn!
                );
              }}
            />
            <ObButton
              variant='ghost'
              size='large'
              label={'Back'}
              fullWidth='always'
              onClick={() => {
                setStep('SELF_HOSTED_URI_ENTRY');
                setStepperData((prev) => {
                  return {
                    ...prev,
                    selectedLandingPageObrn: undefined,
                  };
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};
