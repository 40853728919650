export enum AssetBackgroundType {
  'LIGHT' = 'LIGHT',
  'DARK' = 'DARK',
}

export interface AssetDetails {
  height: number;
  width: number;
  aspectRatio: number;
  hasAlphaChannel: boolean;
  brightness: number;
  colorPalette: string[];
  dominantColor: string;
  recommendedBackground: AssetBackgroundType;
}
