import {
  ObDeleteCard,
  ObFormRenderer,
  useDrawerService,
} from '@outbound/design-system';
import { useCallback, useEffect } from 'react';
import { useFetchPlaybookSettings } from '../../../../../../query/playbook/use-playbook-endpoints';
import {
  useDeletePersonaById,
  usePatchPersonaById,
} from '../../../../../../query/playbook/use-playbook-settings-persona-endpoints';
import { PlaybookDrawerContent } from '../../_common/playbook-drawer-content';
import { useDrawerEditForm } from '../../_common/use-drawer-edit-form';
import {
  mapApiResourceToFormValues,
  mapFormValuesToApiResource,
} from '../forms/customer-persona-form-utils';
import { CUSTOMER_PERSONA_FORM_DEF } from '../forms/customer-persona-form.def';

export interface CustomerPersonaEditDrawerProps {
  personaId: string;
}
export const CustomerPersonaEditDrawer = ({
  personaId,
}: CustomerPersonaEditDrawerProps) => {
  const {
    getRegisteredFormById,
    initialValues,
    setInitialValues,
    onFormDirtyChange,
    isInitialValuesFetched,
    setIsInitialValuesFetched,
    areChangesUnsaved,
    notificationService,
    drawerService,
  } = useDrawerEditForm();

  /**
   * Currently the only way to fetch persona data is to fetch the entire playbook settings
   * We may add a get endpoint to cut down on the data being fetched
   */
  const { data: playbookSettings, isFetched: isPlaybookSettingsFetched } =
    useFetchPlaybookSettings();

  const { mutateAsync: patchPhysicalLocation } = usePatchPersonaById(personaId);

  const { mutateAsync: deletePersona } = useDeletePersonaById(personaId);

  /**
   * Side Effect run to initialize the form
   */
  useEffect(() => {
    if (isPlaybookSettingsFetched && !isInitialValuesFetched) {
      const serviceAreaContent = playbookSettings?.personas?.find(
        (persona) => persona.id === personaId
      );
      if (serviceAreaContent == null) {
        throw new Error('Persona not found');
      } else {
        setInitialValues(mapApiResourceToFormValues(serviceAreaContent));
        setIsInitialValuesFetched(true);
      }
    }
  }, [
    isInitialValuesFetched,
    isPlaybookSettingsFetched,
    personaId,
    playbookSettings?.personas,
    setInitialValues,
    setIsInitialValuesFetched,
  ]);

  const handleUpdatePersona = useCallback(
    async (values: any) => {
      const result = await patchPhysicalLocation({
        values: mapFormValuesToApiResource(values),
      });

      return result;
    },
    [patchPhysicalLocation]
  );

  const handleDeletePersona = useCallback(async () => {
    try {
      await deletePersona();
      /**
       * After we delete the persona we want to close the drawer.
       */
      drawerService.popDrawer();

      notificationService.pushNotification({
        title: 'Customer Profile Deleted',
        body: 'The customer profile has been deleted successfully.',
      });
    } catch (error) {}
  }, [deletePersona, drawerService, notificationService]);

  return (
    <PlaybookDrawerContent
      primaryActionLabel={'Save'}
      primaryActionCallback={async () => {
        return getRegisteredFormById(
          CUSTOMER_PERSONA_FORM_DEF.id
        )?.submitFunction();
      }}
      secondaryActionLabel={'Discard'}
      secondaryActionCallback={async () => {
        return getRegisteredFormById(
          CUSTOMER_PERSONA_FORM_DEF.id
        )?.discardChangesFunction();
      }}
      showFooter={areChangesUnsaved}
    >
      {isInitialValuesFetched && (
        <div className='flex flex-col justify-between flex-1'>
          <ObFormRenderer
            formDefinition={CUSTOMER_PERSONA_FORM_DEF}
            hideSubmitButton={true}
            defaultValues={initialValues}
            onSubmitCallback={handleUpdatePersona}
            onFormDirtyChangeCallback={onFormDirtyChange}
          />
          <ObDeleteCard
            typeToConfirmValue={initialValues.role}
            isLoading={false}
            cardTitle={'Delete Customer Profile'}
            cardBody={
              "Deleting a customer profile will delete all of it's associated data and you will no longer be able to use it to target campaigns."
            }
            cardButtonLabel={'Delete'}
            confirmationDialogTitle={'Delete Customer Profile'}
            confirmationDialogBody={
              'This action is permanent and cannot be undone.'
            }
            typeToConfirmInstructions={
              'Type the name of the customer profile to confirm'
            }
            onDeleteConfirmedCallback={handleDeletePersona}
          />
        </div>
      )}
    </PlaybookDrawerContent>
  );
};

export const useCustomerPersonaEditDrawer = () => {
  const drawerService = useDrawerService();

  const openEditCustomerPersonaDrawer = (personaId: string) => {
    drawerService.pushDrawer({
      title: 'Edit Customer Profile',
      description: 'View or update customer profile.',
      drawerContent: <CustomerPersonaEditDrawer personaId={personaId} />,
      size: 'medium',
    });
  };
  return { openEditCustomerPersonaDrawer };
};
