import { BaseModel } from '../../base-model';
import ModelDifference from './model-difference.type';

/**
 * Compares two models using their JSON representations and provides a diff.
 * This is used by the frontend to create a patch.
 * @param current
 * @param incoming
 */
export const diffModels = (
  current: BaseModel,
  incoming: BaseModel
): ModelDifference => {
  if (current.id !== incoming.id) {
    throw new Error('Models must be of the same id');
  }
  return compareJsonObjects(current.toJson(), incoming.toJson());
};

/**
 * Identifies if a value is an object that is not an array or a map.
 * @param value
 * @returns
 */
const isObject = (value: any): boolean => {
  return (
    value &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    !(value instanceof Map)
  );
};

const isMap = (value: any): boolean => {
  return value instanceof Map;
};

const arraysAreEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
};

const addKeyWithPath = (
  key: string,
  obj: Record<string, any>,
  value: any,
  parentPath: string
) => {
  let finalKey;

  if (key.startsWith('obrn:')) {
    console.log('Key starts with obrn:', key);
    finalKey = '/' + encodeURIComponent(key);
  } else {
    finalKey = key;
  }

  const fullPath = parentPath ? `${parentPath}${finalKey}` : finalKey;

  obj[fullPath] = value;
};

const compare = (
  key: string,
  value1: any,
  value2: any,
  currentPath: string,
  created: Record<string, any>,
  updated: Record<string, { oldValue: any; newValue: any }>,
  removed: Record<string, any>
) => {
  let finalKey;

  if (key.startsWith('obrn:')) {
    finalKey = '/' + encodeURIComponent(key);
  } else {
    finalKey = key;
  }

  const fullPath = currentPath ? `${currentPath}${finalKey}` : finalKey;

  if (value1 === undefined && value2 !== undefined) {
    addKeyWithPath(key, created, value2, currentPath);
  } else if (value1 !== undefined && value2 === undefined) {
    addKeyWithPath(key, removed, value1, currentPath);
  } else if (isObject(value1) && isObject(value2)) {
    const nestedDiff = compareJsonObjects(
      value1,
      value2,
      fullPath,
      created,
      updated,
      removed
    );
    if (nestedDiff.added) Object.assign(created, nestedDiff.added);
    if (nestedDiff.updated) Object.assign(updated, nestedDiff.updated);
    if (nestedDiff.removed) Object.assign(removed, nestedDiff.removed);
  } else if (isMap(value1) && isMap(value2)) {
    const nestedDiff = compareJsonObjects(
      value1,
      value2,
      fullPath,
      created,
      updated,
      removed
    );
    if (nestedDiff.added) Object.assign(created, nestedDiff.added);
    if (nestedDiff.updated) Object.assign(updated, nestedDiff.updated);
    if (nestedDiff.removed) Object.assign(removed, nestedDiff.removed);
  } else if (Array.isArray(value1) && Array.isArray(value2)) {
    if (!arraysAreEqual(value1, value2)) {
      addKeyWithPath(
        key,
        updated,
        { oldValue: value1, newValue: value2 },
        currentPath
      );
    }
  } else if (value1 !== value2) {
    addKeyWithPath(
      key,
      updated,
      { oldValue: value1, newValue: value2 },
      currentPath
    );
  }
};

export const compareJsonObjects = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  parentPath = '',
  created: Record<string, any> = {},
  updated: Record<string, { oldValue: any; newValue: any }> = {},
  removed: Record<string, any> = {}
): ModelDifference => {
  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  allKeys.forEach((key) => {
    compare(key, obj1[key], obj2[key], parentPath, created, updated, removed);
  });

  const result = {
    added: Object.keys(created).length ? created : undefined,
    updated: Object.keys(updated).length ? updated : undefined,
    removed: Object.keys(removed).length ? removed : undefined,
  };
  return result;
};
