import {
  FilesUploaderProgress,
  IFileUploadHandler,
} from '@outbound/design-system/src/utilities/file-upload';
import {
  AssetResource,
  AssetStatusType,
  CreateAssetResponseResource,
  S3UploadProgressEvent,
  UploadAssetWithPreSignedUrlRequestResource,
} from '@outbound/types';
import { Observable, Subject, throwError } from '@reactivex/rxjs/dist/package';
import { useCallback } from 'react';
import {
  useCreateAssetMutationForFile,
  useUploadAssetMutation,
} from '../query/asset-manager/use-asset-manager-endpoints';
import { useAuth0Axios } from '../services/auth0-axios-provider';
import { getMimeTypeFromFileName } from '../utilties/mimetype-utilities';
import { queryClient } from '../utilties/test-utilities';

/**
 * Implements the File Upload Handler Interface for the Asset Manager.
 * Used by file upload components in our design system to upload files to S3 and AWS.
 */
export const useAssetManagerUploadHandler = (): IFileUploadHandler => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  const { mutateAsync: createAssetManagerAssetReservationForFile } =
    useCreateAssetMutationForFile();
  const { mutateAsync: uploadAssetToPresignedUrl } = useUploadAssetMutation();

  const subject = new Subject<FilesUploaderProgress>();

  const handleUploadContentUsingPreSignedURL = useCallback(
    async (
      assetReservation: CreateAssetResponseResource,
      file: File,
      uploadProgressCallbackFunction: (event: S3UploadProgressEvent) => void
    ) => {
      const uploadData: UploadAssetWithPreSignedUrlRequestResource = {
        presignedPostUrl: assetReservation.presignedUrl,
        fileContents: file,
        fileMimeType: getMimeTypeFromFileName(file.name),
        uploadProgress: uploadProgressCallbackFunction,
        id: assetReservation.id,
      };
      return uploadAssetToPresignedUrl(uploadData);
    },
    [uploadAssetToPresignedUrl]
  );

  /**
   * Does the actual work of processing the files and uploading them to the asset manager.
   * @param files
   */
  const handleFileUploads = async (files: Array<File>) => {
    /**
     * We will need to get a presigned URL for each file we want to upload.
     */
    for (const file of files) {
      const assetReservation: CreateAssetResponseResource =
        await createAssetManagerAssetReservationForFile({ file });

      subject.next({
        files: [
          {
            key: file.name,
            progress: 10,
            status: 'uploading',
            file,
          },
        ],
      });

      await handleUploadContentUsingPreSignedURL(
        assetReservation,
        file,
        (event) => {
          subject.next({
            files: [
              {
                key: file.name,
                progress: Math.max(
                  10,
                  Math.min((event.loaded / event.total) * 100, 80)
                ), //Start at 10% and save 20% for the final processing
                status: 'uploading',
                file,
              },
            ],
          });
        }
      );

      let progress = 80;
      const checkForActiveStatusProgress = () => {
        progress += 4;
        subject.next({
          files: [
            {
              key: file.name,
              progress,
              status: 'uploading',
              file,
            },
          ],
        });
      };

      const asset = await waitForActiveStatusOnAsset(
        assetReservation.id,
        checkForActiveStatusProgress
      );
      console.log('Asset Upload Complete', asset);
      subject.next({
        files: [
          {
            key: file.name,
            progress: 100,
            status: 'complete',
            file,
            uploadedFileId: asset.id,
            data: asset,
          },
        ],
      });
      //Now that the asset is active we can invalidate the cache
      queryClient.invalidateQueries(['asset-manager/assets:get']);
      //Finish the animation
      setTimeout(() => {
        subject.complete();
      }, 300);
    }
  };

  const waitForActiveStatusOnAsset = (
    id: string,
    progress: () => void
  ): Promise<AssetResource> => {
    return new Promise((resolve, reject) => {
      const activePollInterval = setInterval(async () => {
        try {
          const asset = await getAssetResourceById(id);
          if (asset.data.status === AssetStatusType.ACTIVE) {
            clearInterval(activePollInterval);
            resolve(asset.data);
          } else {
            console.log('Asset not active yet, polling again in 1 second');
            progress();
          }
        } catch (error) {
          clearInterval(activePollInterval);
          reject(error);
        }
      }, 1000);
    });
  };

  /**
   *
   * @param id
   * @returns
   */

  const getAssetResourceById = async (id: string) => {
    return auth0AxiosClient.get<AssetResource>(`asset-manager/assets/${id}`);
  };

  /**
   * External interface used by our design system components to initiate and report progress of file uploads.
   * @param files
   * @returns
   */
  const uploadFiles = (
    files: Array<File>
  ): Observable<FilesUploaderProgress> => {
    /**
     * We expect that this function will be called with at least one file to upload.
     */
    if (files == null || files.length === 0) {
      return throwError('Please Supply at least one file to upload');
    }

    if (files.length > 1) {
      /**
       * This file upload handler is only setup to handle one file at a time
       * at the moment. The plan is to add support for multiple file uploads in the future.
       * once we have a use-case that warrants it. In that case the design system components
       * will also need to be updated to expect multiple files and their keys and to be able to
       * deal with them accordingly.
       *
       * We may also want to add a new method to the IFileUploadHandler interface
       * that indicates if the handler supports multiple file uploads or not.
       */
      throw new Error('Multiple Uploads Not Yet Implemented');
    }

    handleFileUploads(files);
    return subject.asObservable();
  };

  /**
   * Tells the design system components if this file upload handler supports progress reporting. If it does not
   * then the design system components will need to show an indeterminate progress bar.
   * @returns
   */
  const supportsProgressReporting = (): boolean => {
    return true;
  };

  /**
   * Public Interface (Must Implement IFileUploadHandler)
   */
  return {
    supportsProgressReporting,
    uploadFiles,
  };
};
