import { cva } from 'class-variance-authority';
import { ObTagGroup } from '../../../indicators/ob-tag-group/ob-tag-group';
import { stringHasContent } from '../../../utilities/string-utils';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObThumbnail } from '../../elements/ob-thumbnail/ob-thumbnail';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObSubItemProps {
  /**
   * A unique identifier for the sub item.
   */
  id: string;
  /**
   * The header of the sub item. Will be displayed prominently.
   */
  header: string;
  /**
   * The description of the sub item. Will be displayed below the header and cut off after 2 lines on desktop
   * and 5 lines on mobile.
   */
  description: string;
  /**
   * An array of tags that will be displayed below the description.
   */
  tags?: Array<string>;

  /**
   * The image URL of the sub item. Will be displayed on the left side of the sub item.
   */
  keyPhotoUrl?: string;

  /**
   * The image alt text of the sub item. Used for accessibility purposes.
   */
  keyPhotoAlt?: string;

  /**
   * An optional callback that will be called when the user clicks on the "View Details" button.
   * If not provided, the button will not be displayed.
   */
  onClickViewDetailsCallback?: () => void;
}

const containerStyles = cva(
  'flex flex-row gap-4 hover:dark:bg-dark/background/surface+1 dark:bg-dark/background/surface p-3 rounded-lg transition duration-200 ease-in-out  min-h-[124px]',
  {
    variants: {},
  }
);

/**
 * Renders the Sub Item Component.
 * A UI for sub-items that are children of a primary item.
 * Original use case is for displaying sub-services or benefits of services.
 */
export const ObSubItem: React.FC<ObSubItemProps> = ({
  id,
  header,
  description,
  tags,
  keyPhotoUrl,
  keyPhotoAlt,
  onClickViewDetailsCallback,
}: ObSubItemProps) => {
  return (
    <article
      data-sub-item-id={id}
      className={containerStyles()}
    >
      {/* First Column */}
      {/* Conditionally Render the Thumbnail if a public url is provided */}
      {keyPhotoUrl && (
        <ObThumbnail
          label={`View more details for ${header}`}
          altText={keyPhotoAlt}
          publicUrl={keyPhotoUrl}
          imageFit={'cover'}
          size='large'
          preferredBackground={'dark'}
          onClickHandler={onClickViewDetailsCallback}
        />
      )}

      {/* Center Column */}
      <div className='flex flex-col justify-between flex-grow'>
        {/* Text Container */}
        <div className='flex flex-col gap-2'>
          <ObTypography
            variant='subtitle1'
            as='h4'
            className='line-clamp-1'
          >
            {header}
          </ObTypography>
          {stringHasContent(description) && (
            <ObTypography
              variant='body3'
              className={
                tags && tags.length > 0 ? 'line-clamp-2' : 'line-clamp-4'
              }
            >
              {description}
            </ObTypography>
          )}
          {!stringHasContent(description) && (
            <ObTypography
              color='tertiary'
              variant='body3'
              className='line-clamp-1 italic'
            >
              No Description Provided. If you add a description, it will show
              here.
            </ObTypography>
          )}
        </div>

        {/* Conditionally render the tag container if tags are present */}
        {tags && tags.length > 0 && (
          <ObTagGroup
            overflowBehavior='truncate'
            size='small'
            selectable={false}
            removable={false}
            value={tags.map((t) => ({ content: t }))}
          />
        )}
      </div>
      {/* End Column */}
      <div className='flex flex-col items-center justify-center min-w-[34px] flex-none'>
        {/* Button Desktop */}
        <ObButton
          iconLeft='chevronRight'
          size='medium'
          label={`View details for ${header}`}
          variant='outline'
          buttonType='icon'
          onClick={onClickViewDetailsCallback}
        />
      </div>
    </article>
  );
};
