import { ObButton } from '@outbound/design-system';
import { useAuth0Extended } from '../../auth/use-auth0-extended';

export const Auth0Playground = () => {
  const { loginWithRedirect } = useAuth0Extended();
  return (
    <div>
      <ObButton
        label='Signup User'
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: 'signup',
              redirect_uri: `${window.location.origin}/?signup=123`,
              login_hint: 'pat@outbound.com',
              hello: 'world',
            },
          })
        }
      />
      <ObButton
        label='Login User'
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: 'login',
              redirect_uri: `${window.location.origin}/?login=123`,
              login_hint: 'pat@outbound.com',
              hello: 'world',
            },
          })
        }
      />
    </div>
  );
};
