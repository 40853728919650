import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ObBrand } from '@outbound/design-system';
import { useWindowDimensions } from '../../../../hooks/use-window-dimensions';
import { DrawerItem } from '../drawer-item/drawer-item';
import { ToggleExpandButtonDesktop } from '../toggle-expand-button-desktop/toggle-expand-button-desktop';
import { DrawerExpansionProps } from '../types';

export const DrawerBottom = ({
  isDrawerExpanded,
  toggleDrawerExpansion,
}: DrawerExpansionProps) => {
  const flags = useFlags();
  const isNavFeedbackEnabled = useMemo(
    () => flags.navFeedback,
    [flags.navFeedback]
  );
  const isNavSupportEnabled = useMemo(
    () => flags.navSupport,
    [flags.navSupport]
  );

  const { isSmallOrLargerViewport: isDesktopView } = useWindowDimensions();

  return isDesktopView ? (
    <div>
      <ul>
        {isNavFeedbackEnabled ? (
          <li className='mb-1'>
            <DrawerItem
              expanded={isDrawerExpanded}
              label='Feedback'
              icon='chatLeft'
            />
          </li>
        ) : null}

        {isNavSupportEnabled ? (
          <li>
            <DrawerItem
              expanded={isDrawerExpanded}
              label='Support'
              icon='helpCircle'
            />
          </li>
        ) : null}
      </ul>
      <ToggleExpandButtonDesktop
        isDrawerExpanded={isDrawerExpanded}
        toggleDrawerExpansion={toggleDrawerExpansion}
      />
    </div>
  ) : (
    <div className='py-4 px-5'>
      <ObBrand
        variant='icon'
        size='small'
        color='full-color'
      />
    </div>
  );
};
