/** solid/communication/phone */
export const Phone = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7042 14.587C8.5754 13.9828 6.56678 12.8425 4.89235 11.1681C3.21793 9.49368 2.0777 7.48505 1.47347 5.35624C1.46989 5.34362 1.46634 5.33111 1.46281 5.3187C1.36447 4.97269 1.28821 4.70438 1.28712 4.33645C1.28587 3.91593 1.42287 3.38911 1.62882 3.02248C1.98301 2.39196 2.74352 1.58393 3.4004 1.25168C3.96796 0.964609 4.63823 0.964609 5.20579 1.25168C5.76728 1.53568 6.39261 2.17139 6.74122 2.70756C7.17221 3.37045 7.17221 4.22503 6.74122 4.88792C6.62573 5.06556 6.4611 5.2299 6.27 5.42068C6.21049 5.48009 6.14499 5.52301 6.18849 5.61361C6.62037 6.51313 7.20936 7.35685 7.95648 8.10398C8.7036 8.8511 9.54733 9.44009 10.4469 9.87197C10.5406 9.917 10.5776 9.85273 10.6398 9.79046C10.8306 9.59935 10.9949 9.43473 11.1725 9.31924C11.8354 8.88825 12.69 8.88825 13.3529 9.31924C13.8747 9.65852 14.527 10.2976 14.8088 10.8547C15.0958 11.4222 15.0958 12.0925 14.8088 12.6601C14.4765 13.3169 13.6685 14.0774 13.038 14.4316C12.6714 14.6376 12.1445 14.7746 11.724 14.7733C11.3561 14.7722 11.0878 14.696 10.7418 14.5976C10.7294 14.5941 10.7168 14.5906 10.7042 14.587Z'
        fill='currentColor'
      />
    </svg>
  );
};
