import {
  CampaignChannel,
  CampaignChannelType,
  PhaseGoal,
  StrategyResource,
} from '@outbound/types';

// OD-794 - Optimize and clean up the timeline generation code

const getFriendlyChannelType = (
  channelType?: CampaignChannelType | CampaignChannel
): string => {
  if (!channelType) {
    return '';
  }
  switch (channelType) {
    case CampaignChannelType.GOOGLE_DISPLAY_ADS:
      return 'Google Display Ads';
    case CampaignChannelType.FACEBOOK_ADS:
      return 'Facebook Ads';
    case CampaignChannelType.GOOGLE_SEARCH_ADS:
      return 'Google Search Ads';
    default:
      return channelType;
  }
};

const futureTimelineTextEventsForPresentCampaign = [
  {
    eventType: 'text' as const,
    status: 'future' as const,
    isLastEvent: false,
    isFutureEventNext: true,
    cardTitle: 'Ad Review',
    cardBody:
      'Your ad will undergo a review process by the ad network before it goes live. This process is done by the Ad Network such as Google or Facebook to ensure the ad meets their standards. If there are any issues Outbound will take care of them for you. The whole process typically takes 24-48 hours.',
    timeFrame: '24-48 Hours',
  },
  {
    eventType: 'text' as const,
    status: 'future' as const,
    isLastEvent: false,
    isFutureEventNext: true,
    cardTitle: 'Post Launch Optimization Period',
    cardBody:
      'After launch Outbound will being to optimization your ad. You should expect to see results from your ad within the first 30 days!',
    timeFrame: '2-4 Weeks',
  },
];

export const generateTimelineFromStrategy = (strategy?: StrategyResource) => {
  let timelinePhases: Record<string, any>[] = [];

  strategy?.phases?.forEach((phase) => {
    if (phase.type === PhaseGoal.BRAND_AWARENESS) {
      [
        {
          eventType: 'campaign' as const,
          status: 'present' as const,
          isLastEvent: false,
          isFutureEventNext: true,
          cardTitle: 'Create Brand Awareness',
          cardBody: `Based on your budget we recommend starting with a brand awareness campaign using ${getFriendlyChannelType(
            phase.campaign?.channelType
          )}.`,
          channel: phase.campaign?.channelType,
          goal: 'Brand Awareness',
          goalMetrics: '',
          goalIcon: 'goalBrandAwareness' as const,
          campaign: phase.campaign,
        },
        ...futureTimelineTextEventsForPresentCampaign,
      ].forEach((phase) => {
        timelinePhases.push(phase);
      });
    } else if (phase.type === PhaseGoal.HIGH_QUALITY_LEADS) {
      [
        {
          eventType: 'campaign' as const,
          status: 'present' as const,
          isLastEvent: false,
          isFutureEventNext: true,
          cardTitle: `Use ${getFriendlyChannelType(
            phase.campaign?.channelType
          )} to Generate Leads`,
          cardBody: `Your campaign will launch on ${getFriendlyChannelType(
            phase.campaign?.channelType
          )}, where we will start acquiring and analyzing data to reach your target audience.`,
          channel: phase.campaign?.channelType,
          goal: 'Generate Quality Leads',
          goalMetrics: '',
          goalIcon: 'goalLeadGeneration' as const,
          campaign: phase.campaign,
        },
        ...futureTimelineTextEventsForPresentCampaign,
      ].forEach((phase) => {
        timelinePhases.push(phase);
      });
    } else if (phase.type === PhaseGoal.RETARGETING) {
      [
        {
          eventType: 'campaign' as const,
          status: 'future' as const,
          isLastEvent: true,
          isFutureEventNext: false,
          cardTitle: `Use ${getFriendlyChannelType(
            phase.campaign?.channelType
          )} to Increase Conversions`,
          cardBody: `Use Timeline Ads to re-engage people who clicked on your ${getFriendlyChannelType(
            phase.campaign?.channelType
          )} but did not call or submit a form.`,
          channel: phase.campaign?.channelType,
          goal: 'Re-Engage Previous Visitors',
          goalMetrics: '',
          goalIcon: 'goalLeadGeneration' as const,
          timeFrame: '24-48 hours',
          campaign: phase.campaign,
        },
      ].forEach((phase) => {
        timelinePhases.push(phase);
      });
    }
  });
  return {
    ...strategy,
    phases: timelinePhases,
  };
};
