import * as Yup from 'yup';
/**
 * Code in this file is things that should be implemented in the future in the form renderer when we have time.
 *
 * The main addition is the concept of "Display Mode" which is a way to show the form in a read only state or an editable state.
 *
 * We also introduced readOnly interactions into conditional field visibility.
 */

export type FieldHiddenState = { [key: string]: boolean };

export type CreateCampaignFormData = {
  what: string;
  who: string;
  where: Array<string>;
  channel: string;
  name: string;
};

/**
 * EXPERIMENT
 * Properties for FieldDisplayMode
 * The idea is to provide different display modes for the fields in the form renderer
 * of read-only and editable.
 * This is potentially a new feature that can be incorporated into the form renderer.
 *
 */
export type FieldDisplayMode = 'editable' | 'readonly';

export interface FieldDisplayModeProps {
  /**
   * The display mode of the field
   */
  displayMode: FieldDisplayMode;

  /**
   * Callback to switch the display mode
   */
  onDisplayModeChangedCallback: (displayMode: FieldDisplayMode) => void;
}

/**
 * Defines a Map of Field Ids to their display mode.
 * This state is used to determine how a field should be rendered
 */
export type FieldDisplayModeState = {
  [key: string]: {
    mode: FieldDisplayMode;
    /**
     * Incremented each time the field is requested to be in read-only mode
     */
    childRequestsForReadOnlyMode: number;
    /**
     * Incremented each time the field is requested to be in editable mode
     */
    childRequestsForEditableMode: number;
  };
};

export type CampaignField = {
  id: string;
  conditionalVisibilityFunction?: (
    values: CreateCampaignFormData,
    fieldDisplayModes: FieldDisplayModeState
  ) => boolean;
  valueType: 'string' | 'string-array';
  validation?: {
    isRequired: boolean;
    isRequiredMessage: string;
    maxLength?: number;
    maxLengthMessage?: string;
  };
  displayModes?: Array<FieldDisplayMode>;
};

/**
 * A Field Schema for this Form that demonstrates how we may incorporate conditional visibility and editable/read-only modes into the form rendered.
 */
export const fields: Array<CampaignField> = [
  {
    id: 'what',
    conditionalVisibilityFunction: () => true, //First field is always visible
    valueType: 'string',
    validation: {
      isRequired: true,
      isRequiredMessage: 'Please select why you would like to advertise.',
    },
    displayModes: ['editable', 'readonly'],
  },
  {
    id: 'who',
    valueType: 'string',
    conditionalVisibilityFunction: (values: CreateCampaignFormData) => {
      return !!values.what;
    },
    validation: {
      isRequired: true,
      isRequiredMessage: 'Please select a customer profile to advertise to.',
    },
    displayModes: ['editable', 'readonly'],
  },
  {
    id: 'where',
    valueType: 'string-array',
    conditionalVisibilityFunction: (values: CreateCampaignFormData) =>
      !!values.who,
    validation: {
      isRequired: true,
      isRequiredMessage:
        'Please select at least one location to advertise for.',
    },
    displayModes: ['editable', 'readonly'],
  },
  {
    id: 'channel',
    valueType: 'string',
    conditionalVisibilityFunction: (values, displayModes) => {
      return (
        !!values.where &&
        values.where.length > 0 &&
        displayModes!['where']?.childRequestsForReadOnlyMode > 0
      );
    },
    validation: {
      isRequired: true,
      isRequiredMessage: 'Please select a channel to advertise on.',
    },
    displayModes: ['editable', 'readonly'],
  },
  {
    id: 'name',
    valueType: 'string',
    conditionalVisibilityFunction: (values: CreateCampaignFormData) =>
      !!values.channel,
    validation: {
      isRequired: true,
      isRequiredMessage: 'Please provide a name for your campaign.',
      maxLength: 100,
      maxLengthMessage: 'Campaign name must be less than 100 characters.',
    },
    displayModes: ['editable'],
  },
];

/**
 * This function builds a validation schema for the form based on the fields and the visible field map
 * This is very similar to what the form renderer does.
 *
 * This was duplicated here due to the read-only mode requirement. In the future when we have time to
 * integrate the read-only mode into the form renderer this logic should be removed since the form renderer
 * would handle this.
 * @param fields
 * @param visibleFieldMap
 * @returns
 */
export const buildValidationSchemaForForm = (
  fields: Array<CampaignField>,
  visibleFieldMap: FieldHiddenState
) => {
  const schema: { [key: string]: any } = {};

  fields.forEach((field) => {
    if (!visibleFieldMap[field.id]) {
      return;
    } else {
      if (field.valueType === 'string') {
        let yupField = Yup.string();
        if (field.validation?.isRequired) {
          yupField = yupField.required(
            `${field.validation?.isRequiredMessage ?? 'This field is required'}`
          );
        }
        if (field.validation?.maxLength) {
          yupField = yupField.max(
            field.validation.maxLength,
            `${field.validation?.maxLengthMessage ?? 'This field is too long'}`
          );
        }
        schema[field.id] = yupField;
      } else if (field.valueType === 'string-array') {
        let yupField = Yup.array().of(Yup.string());
        if (field.validation?.isRequired) {
          yupField = yupField.min(
            1,
            `${field.validation?.isRequiredMessage ?? 'This field is required'}`
          );
          schema[field.id] = yupField;
        }
      } else {
        throw new Error('Unsupported field type');
      }
    }
  });

  return Yup.object(schema);
};
