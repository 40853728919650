import { cx } from 'class-variance-authority';
import { FC, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Level } from '../../../tokens/colors/colors';
import { IconSystemKeys } from '../../../tokens/icons/icons';

import { levelStyles } from '../../../indicators/ob-badge/ob-badge';
import { ObPin } from '../ob-pin/ob-pin';
import { ObTypography } from '../ob-typography/ob-typography';

export interface ObBadgeFlagProps {
  /**
   * The text label of the Badge Flag
   */
  content: string;
  /**
   * What icon we should display
   */
  iconRight?: IconSystemKeys;
  /**
   * Indicates the styling of the badge
   */
  level?: Level;
  /**
   * Indicates the line height to grow to
   */
  lineHeight: number;

  value: number;

  goalValue: number;

  componentWidth: number;

  // goalUnitQty: number;

  style?: any;
  className?: string;
}

/**
 * The width of the Badge container
 * (Must include the fully extended (hovered) width to keep expansion animation centered)
 * NOTE: If the values to be displayed become more dynamic we could calculate this using a ref
 * Can't be 100% due to absolute positioning logic
 */
const BADGE_WIDTH = 275;

/**
 * Initial Use Case:
 * Used to represent goals on a marketing plan. Sits above a budge slider.
 */
export const ObBadgeFlag: FC<ObBadgeFlagProps> = ({
  goalValue,
  content,
  level = Level.SUCCESS,
  lineHeight = 28,
  componentWidth,
  style,
  className,
}: ObBadgeFlagProps) => {
  const [isDragging] = useState(false);

  /**
   * Ref for the bounds (size) of the slider. Needed to calculate value
   * based on where the slider is relative to it's container
   */
  const rangeRef = useRef<HTMLDivElement>(null);

  /**
   * Ref for the bounds (size) of the drag handle. These can be variable width
   * due to the text labels. This allows to determine the center point of the drag
   * handle so we can perform the value math that we need to.
   */
  const handleRef = useRef<HTMLDivElement>(null);

  /**
   * React Spring animation used to animate the pin SVG height during drag.
   * The line-height is variable to account for more than one badge-flag on the row.
   * In the future the default line height of 24 would need to be variable as well in
   * the case where we have two badge-flags that overlap we need to stack them
   */
  const animatedLineHeight = useSpring({
    value: isDragging ? lineHeight : 18,
    from: { value: 24 },
    config: {
      duration: 150,
      tension: 170,
      friction: 26,
    },
  });

  return (
    <div
      ref={rangeRef}
      style={{ width: componentWidth }}
      className='flex flex-col justify-center items-center relative'
    >
      <div
        id='flag-container'
        ref={handleRef}
        style={{
          left: '-138px',
          width: `${BADGE_WIDTH}px`,
          ...style,
        }}
        className={cx(`group absolute bottom-[4px] ${className}`)}
      >
        <div className='flex flex-col'>
          <div
            id='flag'
            style={{ margin: 'auto', left: '8px' }}
            className={cx(
              levelStyles({ level }),
              ' relative transition-all duration-500 ease-in-out rounded-full cursor-ew-resize select-none '
            )}
          >
            <div
              className={cx(
                'opacity-0 absolute left-0 max-w-[24px] transition-opacity duration-500 ease-in-out transform translate-x-0',
                isDragging ? 'opacity-100' : ''
              )}
            ></div>
            <div
              className={cx(
                'w-0 transform duration-500',
                isDragging ? 'w-4' : ''
              )}
            ></div>
            <div className=' inset-0  m-auto flex  items-center justify-center gap-2'>
              <ObTypography
                variant={'body3'}
                className='whitespace-nowrap text-ellipsis overflow-hidden transition-colors duration-500'
              >
                {Math.round(goalValue)} {content}
              </ObTypography>
            </div>
            <div
              className={cx(
                'w-0 transform duration-500',
                isDragging ? 'w-4' : ''
              )}
            ></div>
            <div
              className={cx(
                'opacity-0 absolute right-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out transform -translate-x-0 ',
                isDragging ? 'opacity-100' : ''
              )}
            ></div>
          </div>
        </div>
        <animated.div
          className=' w-[7px] mt-2 mx-auto left-[8px] relative'
          style={{ height: animatedLineHeight.value }}
        >
          <ObPin lineHeight={animatedLineHeight}></ObPin>
        </animated.div>
      </div>
    </div>
  );
};
