import { cva, cx } from 'class-variance-authority';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { IconSize, ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import {
  ObTypography,
  ObTypographyVariant,
} from '../ob-typography/ob-typography';

export interface ObLinkProps {
  onClick?: () => void;
  href?: string;
  label?: string;
  iconLeft?: IconSystemKeys;
  iconRight?: IconSystemKeys;
  iconSize?: IconSize;
  variant: 'primary' | 'secondary';
  className?: string;
  openInNewTab?: boolean;
  typographyVariant?: ObTypographyVariant;
}

export const ObLink: React.FC<ObLinkProps> = ({
  onClick,
  href,
  label,
  iconLeft,
  iconRight,
  iconSize = 'medium',
  variant = 'primary',
  className,
  openInNewTab,
  typographyVariant,
}: ObLinkProps) => {
  const styles = cva(
    'inline-flex items-center cursor-pointer hover:underline focus:outline-none focus-visible:shadow-interactive rounded-md max-h-[22px]',
    {
      variants: {
        variant: {
          primary: [
            'dark:text-dark/action/primary-v2/normal dark:hover:dark/action/primary-v2/hover dark:active:dark/action/primary-v2/active ',
          ],
          secondary: [
            'text-actionSecondaryLight dark:text-contentPrimaryDark dark:hover:text-contentPrimaryHoverDark',
          ],
        },
      },
    }
  );
  return (
    <a
      tabIndex={0}
      className={cx(styles({ variant: variant }), className)}
      {...(onClick && { onClick: onClick })}
      {...(href && { href: href, target: openInNewTab ? '_blank' : '_self' })}
    >
      {iconLeft && (
        <ObIcon
          icon={iconLeft}
          size={iconSize}
          color='inherit'
        />
      )}
      <span
        className={cx({
          'pb-[4px] pl-1 pr-1':
            (iconLeft ?? iconRight) && iconSize !== 'x-small',
          'pb-[3px] pl-[2px]':
            (iconLeft ?? iconRight) && iconSize === 'x-small',
        })}
      >
        <ObTypography
          variant={typographyVariant ?? 'subtitle2'}
          as={'span'}
          color='inherit'
        >
          {label ?? href ?? ''}
        </ObTypography>
      </span>
      {iconRight && (
        <ObIcon
          icon={iconRight}
          size={iconSize}
          color='inherit'
        />
      )}
    </a>
  );
};
