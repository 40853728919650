import { toObrn } from '@otbnd/utils';
import { ServerDelta, WorkspaceResource } from '@outbound/types';
import { BaseTransformer } from '../base-transformer';
import { RootStore } from '../root-store';
import Workspace from './workspace';

class WorkspaceTransformer extends BaseTransformer<
  WorkspaceResource,
  Workspace
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }
  fromApiResource(resource: WorkspaceResource): Workspace {
    return new Workspace(
      this._rootStore,
      resource.id,
      toObrn({
        workspaceId: 'system',
        objectType: 'workspace',
        localPathId: resource.id,
      }),
      resource.name,
      resource.auth0OrganizationId
    );
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: Workspace,
    _resource: WorkspaceResource
  ): ServerDelta[] {
    return []; // Not Implemented
  }
}

export default WorkspaceTransformer;
