/** solid/general/zap */
export const Zap = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3981 1.08275C13.8053 1.25952 14.0472 1.68359 13.9921 2.12409L13.1326 9.00006L19.3276 9.00005C19.5686 9.00001 19.8127 8.99996 20.0121 9.01802C20.2008 9.03509 20.5619 9.08027 20.8751 9.33338C21.2337 9.62314 21.4391 10.0617 21.4322 10.5227C21.4261 10.9253 21.2296 11.2317 21.122 11.3875C21.0082 11.5523 20.8519 11.7398 20.6975 11.9249L11.7681 22.6402C11.4839 22.9813 11.0089 23.0941 10.6017 22.9174C10.1945 22.7406 9.95252 22.3165 10.0076 21.876L10.8671 15.0001L4.67209 15.0001C4.4311 15.0001 4.187 15.0002 3.98758 14.9821C3.79897 14.965 3.4378 14.9198 3.12459 14.6667C2.76601 14.377 2.5606 13.9384 2.56755 13.4774C2.57363 13.0748 2.77013 12.7684 2.87776 12.6126C2.99156 12.4478 3.14786 12.2603 3.30218 12.0752C3.30982 12.066 3.31747 12.0569 3.3251 12.0477L12.2316 1.35987C12.5158 1.01884 12.9908 0.905986 13.3981 1.08275Z'
        fill='currentColor'
      />
    </svg>
  );
};
