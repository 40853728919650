/**
 * Takes a Creative Resource and renders a preview of it
 */
import {
  CreativeInitializingLoader,
  ObLoadingSpinner,
} from '@outbound/design-system';
import { BaseTemplateValueResource, CreativeResource } from '@outbound/types';
import { cx } from 'class-variance-authority';
import React, { FC, useEffect, useState } from 'react';

import { buildTemplateValueMapFromArray } from '@otbnd/utils';
import { templateComponentMapping } from '../../template-component-mapping';

export interface ObCreativePlayerProps {
  creative: CreativeResource | undefined;
  mobileView?: boolean;
  isLoading: boolean;
  estimatedPercentComplete?: number;
}

export const ObCreativePlayer: FC<ObCreativePlayerProps> = ({
  creative,
  isLoading,
  mobileView = false,
  estimatedPercentComplete = 100,
}: ObCreativePlayerProps) => {
  const [showCreative, setShowCreative] = useState<boolean>(false);
  /**
   * Null means we have not yet initialized the values. An empty array would mean no values in the template (which shouldn't happen).
   */
  const [templateValues, setTemplateValues] = useState<Map<
    string,
    BaseTemplateValueResource
  > | null>(null);

  useEffect(() => {
    if (creative?.templateSettingFieldValues) {
      setTemplateValues(
        buildTemplateValueMapFromArray(creative.templateSettingFieldValues)
      );
    }
  }, [creative]);

  useEffect(() => {
    if (!isLoading && creative != null) {
      setTimeout(() => {
        setShowCreative(true);
      }, 100);
    }
  }, [isLoading, creative]);

  return (
    <div
      className='cursor-pointer'
      data-testid='ob-creative-player'
      data-ob-component='ob-creative-player'
    >
      {/* The creative resource is present but not initialized */}
      {!isLoading && creative != null && estimatedPercentComplete < 100 && (
        <div className='p-2'>
          <CreativeInitializingLoader
            estimatedPercentComplete={estimatedPercentComplete}
          />
        </div>
      )}
      {/* The creative resource is NOT present and we don't know if it's initialized */}
      {(isLoading || creative == null) && (
        <ObLoadingSpinner
          includeBrand={true}
          fadeIn={false}
        />
      )}

      <div
        className={cx(
          'opacity-0 transition-opacity duration-500 ease-in-out',
          showCreative ? 'opacity-100' : ''
        )}
      >
        {/* The creative is available and is done initializing */}
        {!isLoading &&
          creative != null &&
          templateValues &&
          estimatedPercentComplete === 100 &&
          creative?.template?.renderComponent &&
          templateComponentMapping.hasOwnProperty(
            creative.template.renderComponent
          ) &&
          React.createElement(
            templateComponentMapping[creative.template.renderComponent],
            {
              templateId: creative.templateId,
              templateName: creative.template.name,
              templateVersion: creative.templateVersion,
              values: templateValues,
              playbackControls: {
                animate: true,
                playback: 'play',
                loop: true,
              },
              size: mobileView ? 'mobile' : 'desktop',
            }
          )}
      </div>
    </div>
  );
};
