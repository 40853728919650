import { parseObrn } from '@otbnd/utils';
import { ServerDelta, ServiceAreaResource } from '@outbound/types';
import { BaseTransformer } from '../../../base-transformer';
import { RootStore } from '../../../root-store';
import ServiceAreaLocation from './service-area';

class ServiceAreaLocationTransformer extends BaseTransformer<
  ServiceAreaResource,
  ServiceAreaLocation
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  fromApiResource(resource: ServiceAreaResource): ServiceAreaLocation {
    const { workspaceId } = parseObrn(resource.obrn);

    return new ServiceAreaLocation(
      this._rootStore,
      resource.id,
      workspaceId,
      resource.name,
      resource.description ?? '',
      resource.driveTimeMinutes,
      resource.driveDistanceMiles,
      resource.serviceAreaType,
      resource.address?.street1,
      resource.address?.street2,
      resource.address?.city,
      resource.address?.state,
      resource.address?.country,
      resource.address?.postalCode,
      resource.locationImageStatus,
      resource.locationImageForDarkBackgroundAssetId,
      resource.locationImageForLightBackgroundAssetId,
      resource.postalCodes,
      resource.counties,
      resource.states,
      resource.countries
    );
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: ServiceAreaLocation,
    _resource: ServiceAreaResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }
}

export default ServiceAreaLocationTransformer;
