import { CampaignChannelType } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { useEffect, useRef, useState } from 'react';
import { ObChannelAvatar } from '../../../avatars/ob-channel-avatar/ob-channel-avatar';
import { StateDependsOnAsyncSourceProps } from '../../../base-component-props.type';
import { Level } from '../../../tokens/colors/colors';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

import { ObBadge } from '../../../indicators';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObSkeleton } from '../../elements/ob-skeleton/ob-skelton';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObTimelineCardProps extends StateDependsOnAsyncSourceProps {
  timelineHeight?: number;
  totalEvents?: number;
  eventType: 'default' | 'campaign' | 'text';
  status: 'past' | 'present' | 'future';
  isLastEvent: boolean; // If true, event is the final event in timeline and signals that event does not need a connector line
  isFutureEventNext: boolean; // If true, proceeding events are future events and signals a dotted connector line
  cardTitle: string;
  cardBody: string;
  channel?: CampaignChannelType;
  goal?: string; // Goal for campaign (ex. Generate Quality Leads)
  goalMetrics?: string; // Specific data for goal (ex. 20 New Leads)
  goalIcon?: IconSystemKeys; // Icon that correlates with goal
  timeFrame?: string; // Amount of time estimated for event completion (ex. 24-48 hours)
}

export const ObTimelineCard = ({
  timelineHeight,
  eventType,
  status,
  isLastEvent,
  isFutureEventNext,
  cardTitle,
  cardBody,
  channel,
  goal,
  goalMetrics,
  goalIcon,
  timeFrame,
  isLoading,
  totalEvents,
}: ObTimelineCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardHeight, setCardHeight] = useState(0);
  const cardId = `div-${eventType}-${status}`;
  const connectorId = `connector-${eventType}-${status}-${timeFrame}`;

  const [hasCards, setHasCards] = useState<boolean | null>(null);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setHasCards(totalEvents != null && totalEvents > 0 && hasCards);
  }, [totalEvents, isLoading, hasCards]);

  useEffect(() => {
    const cardElement = cardRef.current;
    const connectorElement = document.getElementById(connectorId);

    if (cardElement && connectorElement) {
      const height = cardElement.clientHeight;
      if (height !== cardHeight) {
        // Check if height changed and update card and connector height
        const connectorHeight =
          window.innerWidth >= 768 ? height + 16 : height + 50;
        connectorElement.style.height = `${connectorHeight}px`;
        setCardHeight(height);
      }
    }
  }, [cardRef, connectorId, cardHeight, timelineHeight]);

  return (
    <>
      {isLoading ? (
        <ObSkeleton variant='text'>
          <ObTypography variant='body2'></ObTypography>
        </ObSkeleton>
      ) : eventType === 'default' ? (
        <div
          id={cardId}
          ref={cardRef}
          data-testid='card-component'
          className='min-w-[200px] flex flex-col rounded-lg border border-borderDefaultNormalDark bg-bgSurfaceDark relative'
        >
          <div className='flex items-center gap-2 px-3 py-3 bg-[#1B1D2C] border-b rounded-t-lg border-borderDefaultNormalDark'>
            <ObIcon
              icon='checkCircle'
              size='small'
              color='positive'
            />
            <ObTypography
              variant='subtitle1'
              color='primary'
            >
              {cardTitle}
            </ObTypography>
          </div>
          <div className='p-3'>
            <div className='pb-3 border-b border-borderDefaultNormalDark'>
              <ObTypography
                variant='body2'
                color='primary'
              >
                {cardBody}
              </ObTypography>
            </div>
            <div className='flex flex-col md:flex-row gap-2 pt-3'>
              <ObButton
                variant='outline'
                buttonType='text'
                size='large'
                iconRight='arrowRight'
                label='View Brand Guide'
                aria-label='View Brand Guide'
              />
              <ObButton
                variant='outline'
                buttonType='text'
                size='large'
                iconRight='arrowRight'
                label='View Playbook'
                aria-label='View Playbook'
              />
            </div>
          </div>
          {!isLastEvent && (
            <hr
              id={connectorId}
              className={`absolute left-[6px]	top-[30px] border-[.5px] ${
                status === 'future' || !!isFutureEventNext
                  ? 'border-dashed border-transparent'
                  : 'border-borderDefaultNormalDark'
              }`}
              style={{ zIndex: '-1' }}
            />
          )}
        </div>
      ) : eventType === 'campaign' ? (
        <div
          id={cardId}
          ref={cardRef}
          data-testid='card-component'
          className=' min-w-[200px] flex flex-col rounded-lg border border-borderDefaultNormalDark bg-bgSurfaceDark relative'
        >
          <div className='flex items-center gap-2 px-3 py-3 bg-[#1B1D2C] border-b rounded-t-lg border-borderDefaultNormalDark'>
            {channel && (
              <ObChannelAvatar
                size='xx-small'
                channel={channel}
              />
            )}
            <ObTypography
              variant='subtitle1'
              color='primary'
            >
              {cardTitle}
            </ObTypography>
          </div>
          <div className='p-3'>
            <div className='pb-3 flex flex-col gap-2 border-b border-borderDefaultNormalDark'>
              <div className='flex items-center gap-1'>
                {goalIcon && (
                  <ObIcon
                    icon={goalIcon}
                    size='x-small'
                    color='content'
                  />
                )}
                <ObTypography
                  variant='subtitle2'
                  color='primary'
                >
                  {goal}
                </ObTypography>
              </div>
              <div className='max-w-full flex'>
                {goalMetrics && (
                  <ObBadge
                    level={Level.DEFAULT}
                    content={goalMetrics}
                  />
                )}
              </div>
            </div>
            <div className='flex flex-col gap-1 pt-3'>
              <ObTypography
                variant='body2'
                color='secondary'
              >
                {cardBody}
              </ObTypography>
              <div className='flex flex-col md:flex-row gap-2 pt-3'>
                <ObButton
                  variant='primary'
                  buttonType='text'
                  size='large'
                  iconRight='rocket01'
                  label='Launch'
                  aria-label='Launch'
                  className={cx(status !== 'present' ? 'hidden' : '')}
                />
                <ObButton
                  variant='outline'
                  buttonType='text'
                  size='large'
                  iconRight='pencil02'
                  label='Edit'
                  aria-label='Edit'
                  className={cx(status !== 'present' ? 'hidden' : '')}
                />
              </div>
            </div>
          </div>
          {!isLastEvent && (
            <hr
              id={connectorId}
              className={`absolute left-[6px] border-[.5px] ${
                status === 'future' || !!isFutureEventNext
                  ? 'border-dashed border-transparent'
                  : 'border-borderDefaultNormalDark'
              }`}
              style={{
                zIndex: '-1',
                backgroundImage:
                  'linear-gradient(rgb(68, 68, 68) 50%, rgba(255, 255, 255, 0) 0%)',
                backgroundSize: '1px 6px',
                height: `${cardHeight}px`,
                top: `${cardHeight / 2}px`,
              }}
            />
          )}
        </div>
      ) : eventType === 'text' ? (
        <div
          id={cardId}
          ref={cardRef}
          data-testid='card-component'
          className=' min-w-[200px] flex items-center gap-3 relative'
        >
          <ObIcon
            icon='circleDot'
            size='x-small'
            color='tertiary'
          />
          <div className='w-[616px] flex flex-col gap-1 md:gap-0'>
            <div className='flex flex-col md:flex-row md:justify-between'>
              <ObTypography
                variant='body2'
                color='primary'
              >
                {cardTitle}
              </ObTypography>
              <div className='flex items-center gap-1'>
                <ObTypography
                  variant='body2'
                  color='primary'
                >
                  {timeFrame}
                </ObTypography>
                <ObIcon
                  icon='clock'
                  size='x-small'
                  color='secondary'
                />
              </div>
            </div>
            <ObTypography
              variant='body2'
              color='secondary'
            >
              {cardBody}
            </ObTypography>
          </div>
          {!isLastEvent && (
            <hr
              id={connectorId}
              className={`absolute left-[7px] border-[.5px] ${
                status === 'future' || !!isFutureEventNext
                  ? 'border-dashed border-transparent'
                  : 'border-borderDefaultNormalDark'
              }`}
              style={{
                zIndex: '-1',
                backgroundImage:
                  'linear-gradient(rgb(68, 68, 68) 50%, rgba(255, 255, 255, 0) 0%)',
                backgroundSize: '1px 6px',
                height: `${cardHeight}px`,
                top: `${cardHeight / 2}px`,
              }}
            />
          )}
        </div>
      ) : null}
    </>
  );
};
