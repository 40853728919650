import {
  ObFormRenderer,
  ObLoadingSpinner,
  ObTypography,
} from '@outbound/design-system';
import { FC, useMemo, useState } from 'react';
import { useAuth0Extended } from '../../../auth/use-auth0-extended';
import { createWorkspaceForm } from '../../../components/forms/workspace-creation-form';

export interface WorkspaceDetailsCaptureProps {
  onFormSubmitCallback: (data: any) => Promise<any>;
}

export const WorkspaceDetailsCapture: FC<WorkspaceDetailsCaptureProps> = ({
  onFormSubmitCallback,
}: WorkspaceDetailsCaptureProps) => {
  const { isLoading, isAuthenticated } = useAuth0Extended();

  const [userTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York'
  );

  const workspaceForm = useMemo(
    () => createWorkspaceForm(isAuthenticated),
    [isAuthenticated]
  );

  const handleFormSubmission = (data: any): Promise<any> => {
    return onFormSubmitCallback({
      ...data,
      timezone: userTimezone,
    });
  };

  if (isLoading) {
    return (
      <div className='h-screen flex items-center w-full justify-center'>
        <ObLoadingSpinner includeBrand={true} />
      </div>
    );
  }

  return (
    <section className=' flex items-center justify-center flex-col'>
      <ObTypography variant='h1'>Create Your Workspace</ObTypography>
      <ObTypography
        variant='h4'
        color='secondary'
      >
        Your workspace is where you will manage your marketing campaigns.
      </ObTypography>
      <div className='min-w-[400px]'>
        <ObFormRenderer
          formDefinition={workspaceForm}
          onSubmitCallback={handleFormSubmission}
        />
      </div>
    </section>
  );
};
