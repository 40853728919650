import { NavItem } from '../../../components/navigation/dashboard-route.type';
import { myAccountSettingsRoutes } from './my-account/routes';
import { SettingsPageOutlet } from './settings-page';
import { workspaceSettingsRoutes } from './workspace/routes';

export const settingRoutes: NavItem = {
  title: 'Settings',
  route: 'settings',
  component: SettingsPageOutlet,
  showInSideNav: false,
  showInUserMenu: false,
  childRoutes: [
    { ...workspaceSettingsRoutes, index: true },
    myAccountSettingsRoutes,
  ],
};
