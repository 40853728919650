import { AnimatePresence, motion } from 'motion/react';
import { ObAiGeneratingLoadingIndicator, ObTypography } from '../../../..';

export interface InitializingGoogleSearchProps {
  message: string;
}
export const InitializingGoogleSearch = ({
  message,
}: InitializingGoogleSearchProps) => {
  return (
    <div className='max-w-screen-xl m-auto min-h-screen flex items-center justify-center'>
      <motion.div
        className='flex flex-col gap-4 justify-center items-center'
        initial='hidden'
        animate='visible'
        variants={{
          visible: {
            opacity: 1,
            transition: {
              delay: 0.5,
              staggerChildren: 0.4, // Restores staggered effect
              ease: 'easeIn',
            },
          },
          hidden: { opacity: 0 },
        }}
      >
        {/* Spinner with Fade and Scale */}
        <motion.div
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0.8 },
          }}
          transition={{
            delay: 0.5,
            duration: 0.5, // Animation duration
            ease: 'easeOut',
          }}
        >
          <ObAiGeneratingLoadingIndicator
            variant='spinner'
            size={120}
          />
        </motion.div>

        {/* Text Animation with AnimatePresence */}
        <AnimatePresence mode='wait'>
          <motion.div
            key={message} // Ensures re-render for new message
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 10 },
            }}
            initial='hidden'
            animate='visible'
            exit='hidden'
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <ObTypography
              variant='body2'
              color='secondary'
            >
              {message}
            </ObTypography>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </div>
  );
};
