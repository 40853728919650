import { FC } from 'react';
import { ObDivider } from '../../../../components/elements/ob-divider/ob-divider';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';

export interface SectionLabelProps {
  /**
   * Value shown to the user
   */
  label: string;
  /**
   * Id for accessibility
   * Should be the same as the id that this label is describing
   */
  id: string;
}
export const SectionLabel: FC<SectionLabelProps> = ({ label, id }) => {
  return (
    <label
      id={id}
      className='flex flex-col gap-3'
    >
      <ObTypography
        variant='h5'
        as='span'
      >
        {label}
      </ObTypography>
      <ObDivider />
    </label>
  );
};
