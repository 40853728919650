import { cx } from 'class-variance-authority';
import { ObButton } from '../../..';
import {
  ObCampaignBudgetSlider,
  ObCampaignBudgetSliderProps,
} from '../../../sliders/ob-campaign-budget-slider/ob-campaign-budget-slider';
import { BaseSection } from './components/base-section';

export interface BudgetSectionProps {
  isBudgetDirty: boolean;
  onSaveBudgetClickedCallback: () => void;
  onDiscardBudgetChangesClickedCallback: () => void;
  budgetSlider: ObCampaignBudgetSliderProps;
}

export const BudgetSection = ({
  budgetSlider,
  isBudgetDirty,
  onSaveBudgetClickedCallback,
  onDiscardBudgetChangesClickedCallback,
}: BudgetSectionProps) => {
  return (
    <BaseSection
      heading='Budget and Impact'
      subheading='Use the Budget Slider to Estimate the Impact of Your Campaign'
    >
      <ObCampaignBudgetSlider {...budgetSlider} />
      <div className='flex flex-col self-stretch gap-2 '>
        <ObButton
          label='Save Budget'
          fullWidth='always'
          size='large'
          disabled={!isBudgetDirty}
          onClick={onSaveBudgetClickedCallback}
        />
        <div
          className={cx(
            'opacity-0 duration-300 transition-opacity',
            isBudgetDirty ? 'opacity-100' : '' //Show the discard changes button only if the budget has been changed
          )}
        >
          <ObButton
            label='Discard Budget Changes'
            fullWidth='always'
            size='large'
            variant='ghost'
            onClick={onDiscardBudgetChangesClickedCallback}
          />
        </div>
      </div>
    </BaseSection>
  );
};
