'use client';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { isValidHex } from 'react-color/lib/helpers/color';
import { ObInputColorIndicator } from '../../..';
import { ObInput } from '../ob-input/ob-input';
export interface ObInputColorProps {
  inputId?: string;
  value: string | undefined;
  /**
   * Human readable label for the input.
   * Passed to the color picker open button to read:
   * "Open Color Picker for {label}"
   */
  label?: string;
  optionalColors?: string[];

  /**
   * Indicates that the color picker has been closed
   */
  onPickerClosedCallback?: () => any;

  /**
   * Indicates that the color picker has been opened
   */
  onPickerOpenedCallback?: () => any;

  onChangeCallback?: (value: string) => any;
  isLoading?: boolean;
}

/**
 * Controlled Color Input Component used to select colors
 * @returns
 */
export const ObInputColor: FC<ObInputColorProps> = ({
  inputId,
  label,
  value,
  optionalColors,
  onPickerClosedCallback,
  onPickerOpenedCallback,
  onChangeCallback,
  isLoading,
}: ObInputColorProps) => {
  const inputRef = useRef<any>();

  const [colorValue, setColorValue] = useState<string | undefined>(value);

  const updateInputValue = useCallback((value: string) => {
    const inputElement = inputRef?.current;
    inputElement.value = value;
  }, []);

  const handleInternalColorChange = useCallback(
    (nextValue: string) => {
      //Only communicate updates when the value is a valid hex code (Need to think through through how we would handle validation)
      if (isValidHex(nextValue) || nextValue === '') {
        const internalValueDifferentFromParentValue =
          value?.toUpperCase() !== nextValue.toUpperCase();

        setColorValue(nextValue);
        /**
         * We only want to communicate changes to the parent if the value has changed from what they have.
         * This is important since we have a single direction data flow from parent to child we want to make sure the child does not spam the parent.
         * This is also important since we want to make sure we do not trigger any side effects in the parent if the value has not changed.
         */
        if (internalValueDifferentFromParentValue) {
          onChangeCallback?.(nextValue);
        }
      }
    },
    [onChangeCallback, value]
  );

  const handleInputBlur = useCallback(
    (inputValue: string) => {
      // Reset input value to the last valid value entered
      if (colorValue && inputValue !== colorValue) {
        updateInputValue(colorValue);
        handleInternalColorChange(colorValue);
      }
    },
    [colorValue, handleInternalColorChange, updateInputValue]
  );

  useEffect(() => {
    if (value != null && value !== colorValue) {
      handleInternalColorChange(value);
    }
  }, [colorValue, handleInternalColorChange, value]);

  return (
    <ObInput
      inputId={inputId}
      ref={inputRef}
      value={value?.toUpperCase()}
      inputClassName='font-mono focus:ring-0 focus-within:ring-0' //Focus Ring is applied to the parent div to wrap the Color Indicator as well.
      onChangeCallback={handleInternalColorChange}
      onBlurCallback={handleInputBlur}
      isLoading={isLoading}
      leftContent={
        <ObInputColorIndicator
          fillType='solid'
          label={label}
          value={colorValue}
          optionalColors={optionalColors}
          onPickerOpenedCallback={onPickerOpenedCallback}
          onPickerClosedCallback={onPickerClosedCallback}
          onChangeCallback={({ hex }: { hex: string }) => {
            handleInternalColorChange(hex?.toUpperCase());
          }}
        />
      }
    />
  );
};
