import { ServiceResource } from '@outbound/types';
import { AxiosInstance, isAxiosError } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';

const SERVICE_RESOURCE_PATH = '/playbook/settings/service';
class ServiceTransport extends BaseTransport<ServiceResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  public async fetchById(id: string): Promise<ServiceResource | null> {
    try {
      return this._axiosInstance.get(`${SERVICE_RESOURCE_PATH}/${id}`, {
        params: {
          include: 'assets',
        },
      });
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null;
        }
      }
      throw error;
    }
  }

  public acceptEmbeddedResource(resource: ServiceResource): void {
    this.notifyStoreOfServiceUpdateCallback?.(resource.id, resource);
  }
}

export default ServiceTransport;
