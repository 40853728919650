import { parseObrn } from '@otbnd/utils';
import { PersonaResource, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import CustomerProfile from './customer-profile';

class CustomerProfileTransformer extends BaseTransformer<
  PersonaResource,
  CustomerProfile
> {
  fromApiResource(resource: PersonaResource): CustomerProfile {
    const { scope: workspaceId } = parseObrn(resource.obrn);
    return new CustomerProfile(
      this._rootStore,
      resource.id,
      workspaceId,
      resource.role,
      resource.description ?? ''
    );
  }
  createPatchForCurrentModelAndIncomingResource(
    _model: CustomerProfile,
    _resource: PersonaResource
  ): ServerDelta[] {
    return [];
  }
  constructor(rootStore: RootStore) {
    super(rootStore);
  }
}

export default CustomerProfileTransformer;
