import { CampaignChannelType } from '../campaign/CampaignChannelType';

interface Option {
  title: string;
  description?: string;
  icon?: any;
  image?: string;
  disabled?: boolean;
  value: string;
  channelType: CampaignChannelType;
}

export const channelTypeSelectorOptions: Option[] = [
  {
    value: CampaignChannelType.GOOGLE_DISPLAY_ADS,
    title: 'Display Ads',
    channelType: CampaignChannelType.GOOGLE_DISPLAY_ADS,
    description: 'Advertise on over 90% of the internet',
  },
  {
    value: CampaignChannelType.GOOGLE_SEARCH_ADS,
    title: 'Search Ads',
    channelType: CampaignChannelType.GOOGLE_SEARCH_ADS,
    description:
      'Make sure your brand is the first thing people see when they search',
  },
  {
    value: CampaignChannelType.FACEBOOK_ADS,
    title: 'Facebook Ads',
    channelType: CampaignChannelType.FACEBOOK_ADS,
    description: 'Meet your customers where they are',
    disabled: true,
  },
  {
    value: CampaignChannelType.INSTAGRAM_ADS,
    title: 'Instagram Ads',
    channelType: CampaignChannelType.INSTAGRAM_ADS,
    description: 'Meet your customers where they are',
    disabled: true,
  },
  {
    value: CampaignChannelType.BILLBOARD_ADS,
    title: 'Billboards',
    channelType: CampaignChannelType.BILLBOARD_ADS,
    description: 'Meet your customers where they are',
    disabled: true,
  },
  {
    value: CampaignChannelType.LINKEDIN_ADS,
    title: 'LinkedIn Ads',
    channelType: CampaignChannelType.LINKEDIN_ADS,
    description: 'Meet your customers where they are',
    disabled: true,
  },
  {
    value: CampaignChannelType.PODCAST_ADS,
    title: 'Podcast Ads',
    channelType: CampaignChannelType.PODCAST_ADS,
    description: 'Meet your customers where they are',
    disabled: true,
  },
  {
    value: CampaignChannelType.MAILER_ADS,
    title: 'Mailers',
    channelType: CampaignChannelType.MAILER_ADS,
    description: 'Meet your customers where they are',
    disabled: true,
  },
  {
    value: CampaignChannelType.STREAMING_TV_ADS,
    title: 'Streaming TV Ads',
    channelType: CampaignChannelType.STREAMING_TV_ADS,
    description: 'Meet your customers where they are',
    disabled: true,
  },
];
