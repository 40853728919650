import { cva, cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { Level, LevelType } from '../../tokens/colors/colors';
import { IconSystemKeys } from '../../tokens/icons/icons';
import { ObIcon } from '../../tokens/icons/ob-icon/ob-icon';

export interface Badge {
  content: string;
}
export interface ObBadgeProps {
  /**
   * The text content of the badge
   */
  content: string;
  /**
   * Defaults to Pill
   */
  variant?: 'pill' | 'rounded';
  iconLeft?: IconSystemKeys;
  iconRight?: IconSystemKeys;
  /**
   * Use LevelType over Level as Level is deprecated
   */
  level?: Level | LevelType;
  /**
   * Deprecated; Should not be used
   */
  className?: string;
}

export const levelStyles = cva(
  'inline-flex flex-row  items-center justify-between  gap-2  border box-border max-w-full w-auto flex-nowrap transition-color duration-300 ease-in-out',
  {
    variants: {
      variant: {
        ['pill']: 'rounded-3xl  px-2 py-1 min-w-[26px]',
        ['rounded']: 'rounded-sm uppercase px-1',
      },
      level: {
        ['SUCCESS']:
          '[&_*]:text-emerald-600 [&_*]:dark:text-emerald-500 dark:bg-emerald-500/10 bg-emerald-500/10 dark:border-emerald-600/40 border-emerald-600/80',
        ['WARNING']:
          '[&_*]:text-amber-600 [&_*]:dark:text-amber-500 dark:bg-amber-500/10 bg-amber-500/10 dark:border-amber-600/40 border-amber-600/80',
        ['ERROR']:
          '[&_*]:text-red-600 [&_*]:dark:text-dark/content/on-subtle-negative dark:bg-dark/background/subtle-negative bg-red-500/10 dark:border-dark/border/subtle-negative border-red-600/80',
        ['INFO']:
          '[&_*]:text-cyan-600 [&_*]:dark:text-cyan-500 dark:bg-cyan-500/10 bg-cyan-500/10 dark:border-cyan-600/40 border-cyan-600/80',
        ['DEFAULT']:
          'dark:bg-bgSurfaceDark dark:border-whiteAlpha-2 [&_*]:dark:text-contentPrimaryDark [&_*]:text-contentPrimaryLight bg-light/background/default',
        ['NEUTRAL']:
          'dark:bg-dark/background/surface+1 dark:border-whiteAlpha-2 [&_*]:dark:text-dark/content/secondary [&_*]:text-contentPrimaryLight bg-light/background/default',
      },
    },
  }
);

/**
 * Implementation of Badge from the Outbound Design System
 * https://www.figma.com/file/YoUto3huNM5NTvo0jX4pjJ/Outbound-playbooks-DS-(Copy)?type=design&node-id=3052-11546&t=bGcFXKDUHoroN1ks-4
 *
 * @param heading
 * @constructor
 */
export const ObBadge: FC<ObBadgeProps> = ({
  content,
  iconLeft,
  iconRight,
  level = 'DEFAULT',
  className,
  variant = 'pill',
}: ObBadgeProps) => {
  return (
    <div className={cx(levelStyles({ level, variant }), className)}>
      {iconLeft && (
        <div className='dark:text-dark/content/primary  text-light/content/primary flex-shrink-0'>
          <ObIcon
            icon={iconLeft}
            size={'x-small'}
            color='inherit'
          />
        </div>
      )}
      <ObTypography
        variant={'body3'}
        as='span'
        className='text-ellipsis overflow-hidden whitespace-nowrap  max-w-[100%] w-auto text-center m-auto'
      >
        {content}
      </ObTypography>
      {iconRight && (
        <div className='flex-shrink-0'>
          <ObIcon
            icon={iconRight}
            size={'x-small'}
          />
        </div>
      )}
    </div>
  );
};
