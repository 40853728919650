import { Auth0ContextInterface } from '@auth0/auth0-react';
import { createContext } from 'react';

export interface Auth0ExtendedContextValue extends Auth0ContextInterface {
  switchOrganization: (
    auth0OrgId: string,
    workspaceId: string
  ) => Promise<void>;
  switchToUserOnlyAuthentication: (redirectToPath?: string) => Promise<void>;
  isSwitchToUserOnlyAuthenticationInProgress: boolean;
  isAuthenticatedWithWorkspaceCheckInProgress: boolean;
  isAuthenticatedWithWorkspace: boolean;
  authenticatedWorkspaceId: string | undefined;
  permissions: string[];
}

export const Auth0ExtendedContext =
  createContext<Auth0ExtendedContextValue | null>(null);
