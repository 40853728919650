import {
  RuleBuilderLogicalOperator,
  TargetingGroup,
  TargetingNode,
} from '@outbound/types';
import { deepClone } from '../../../utilities/object-utilities';
import { TargetingConditionForFrontend } from './ob-rule-builder.types';

export const findNodeInTree = (
  nodeId: string,
  nodeTree: TargetingNode
): TargetingNode | undefined => {
  if (nodeTree.nodeId === nodeId) {
    return nodeTree; //The Base Node is the Node
  } else if (nodeTree.operator === 'AND' || nodeTree.operator === 'OR') {
    const nodeWithChildren = nodeTree as TargetingGroup;
    for (const child of nodeWithChildren.children) {
      const foundNode = findNodeInTree(nodeId, child);
      if (foundNode) {
        return foundNode;
      }
    }
  }
};

export const updateNodeInTree = (
  tree: TargetingGroup,
  node: TargetingNode | TargetingConditionForFrontend
): TargetingGroup => {
  const clonedTree = deepClone(tree);
  let nodeToUpdate = findNodeInTree(node.nodeId, clonedTree);

  if (nodeToUpdate) {
    Object.assign(nodeToUpdate, node);
    nodeToUpdate.nodeId = node.nodeId; // Ensure the nodeId is not changed
  }
  return clonedTree;
};

export const removeNodeFromTree = (
  tree: TargetingGroup,
  nodeIdToRemove: string
): TargetingGroup => {
  // Clone the tree to avoid mutating the original tree
  const clonedTree = deepClone(tree);

  const removeNode = (
    currentNode: TargetingNode,
    parentId: string | null,
    parent?: TargetingGroup
  ): boolean => {
    if (currentNode.nodeId === nodeIdToRemove) {
      if (parent) {
        // Remove the currentNode from its parent
        parent.children = parent.children!.filter(
          (child) => child.nodeId !== nodeIdToRemove
        );
      } else if (parentId === null) {
        console.warn('Cannot remove the root node without replacement.');
      }
      return true; // Node found and removed
    }

    if (
      currentNode.operator === RuleBuilderLogicalOperator.AND ||
      currentNode.operator === RuleBuilderLogicalOperator.OR
    ) {
      for (let child of currentNode.children) {
        const found = removeNode(child, currentNode.nodeId, currentNode);
        if (found) return true; // Stop searching once the node is found and removed
      }
    }

    return false; // Node not found in this branch
  };

  removeNode(clonedTree, null); // Start the search from the root node (no parent or parentId

  return clonedTree; // Return the modified tree
};

export const addChildNodeToExistingNode = (
  tree: TargetingGroup,
  nodeId: string,
  childNode: Partial<TargetingNode>
) => {
  if (nodeId == null || childNode == null) {
    return;
  }
  const nextTree = deepClone(tree);
  const nodeToAddChildTo = findNodeInTree(nodeId, nextTree);
  if (
    nodeToAddChildTo &&
    (nodeToAddChildTo.operator === RuleBuilderLogicalOperator.AND ||
      nodeToAddChildTo.operator === RuleBuilderLogicalOperator.OR)
  ) {
    nodeToAddChildTo.children.push(childNode as any);
    return nextTree;
  }
};
