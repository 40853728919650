import {
  IntegrationXConfigurationCard,
  ObTypography,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRootStore } from '../../../../../state/mobx-experiment/use-root-store';

const IntegrationList: FC<{}> = observer(() => {
  const navigate = useNavigate();
  const { workspaceSlug } = useParams();
  const { workspaceStore } = useRootStore();

  const workspace = workspaceStore.getById(workspaceSlug!);

  if (workspace == null) {
    return <></>;
  }

  const workspaceIntegrationConfigurations =
    workspace.getIntegrationsWithConfiguration();

  return (
    <>
      <div className='flex flex-col gap-4'>
        <ObTypography variant='h4'>Ad Channels</ObTypography>
        <div className='grid grid-cols-1 md:grid-cols-2  gap-6 w-full'>
          {workspaceIntegrationConfigurations.map(
            ({ configuration, integration: i }) => (
              <IntegrationXConfigurationCard
                key={i.id}
                integrationIconUrl={i.iconImagePublicUrl}
                name={i.name}
                shortDescription={i.shortDescription}
                lifecycleStatus={configuration?.lifecycleStatus}
                operationalStatus={
                  configuration == null
                    ? 'NOT_CONFIGURED'
                    : configuration.operationalStatus
                }
                category={'AD_CHANNEL'}
                variant={'grid'}
                onNavigateCallback={() => navigate(i.slug)}
              />
            )
          )}
        </div>
      </div>
    </>
  );
});

export const IntegrationsListPage = () => {
  return (
    <div className='flex flex-col gap-2'>
      <ObTypography variant='h2'>Integrations</ObTypography>
      <ObTypography
        variant='body2'
        color='secondary'
      >
        Enhance your Outbound Experience with add-ons and integrations
      </ObTypography>
      <div className='h-6'></div>
      <IntegrationList />
    </div>
  );
};
