import useDebounce from '@outbound/design-system/src/hooks/use-debounce';
import { CampaignEstimateROIResponseResource } from '@outbound/types';
import { useCallback, useEffect, useState } from 'react';
import { useCalculateROIMetricsForCampaign } from '../../../../query/campaigns/use-campaigns-endpoints';

export const useCampaignRoiEstimate = (campaignId: string | undefined) => {
  /**
   * API Call used to calculate the ROI metrics for the campaign
   */
  const { mutateAsync: calculateRoi } =
    useCalculateROIMetricsForCampaign(campaignId);

  /**
   * State to track the current value of the budget slider on the frontend.
   * This value will be different from what is in the server if the user has
   * made changes to the budget slider but not saved it yet.
   */
  const [workingBudget, setWorkingBudget] = useState<number>();

  /**
   * State to store the estimated ROI metrics for the campaign.
   * This is used to show the user the estimated leads per month based on the budget they have set.
   */
  const [estimatedRoi, setEstimatedRoi] =
    useState<CampaignEstimateROIResponseResource | null>(null);

  /**
   * Used to track the "Loading" state of the ROI Estimate metrics.
   * Since we are debouncing the API call to calculate the ROI metrics there is a short
   * delay before we fire off the API call so the metrics don't immediately enter a loading
   * state visually if rely on the isLoading state of the API call. To resolve this as soon as
   * the user starts moving the slider we set this state to true and then set it to false once
   * the API call returns with the up to date estimated metrics.
   *
   * The effect of this is that the metrics will show as loading as soon as the user starts moving
   * the slider and then update to the correct values once the API call returns.
   */
  const [isPendingRoiUpdate, setIsPendingRoiUpdate] = useState<boolean>(false);

  /**
   * We want to prevent the slider from spamming the API with requests on every increment so we
   * wait for the user to stop moving the slider for 100ms before making the API call.
   */
  const debouncedWorkingBudget = useDebounce(workingBudget, 100);

  /**
   * As soon as the user starts moving the slider we want to set the ROI metrics to loading
   */
  useEffect(() => {
    setIsPendingRoiUpdate(true);
  }, [workingBudget]);

  /**
   * When the debounced working budget changes we want to make an API call to calculate the ROI metrics
   * and update the estimated ROI state for the user.
   */
  useEffect(() => {
    const fetchRoi = async () => {
      const nextEstimatedRoi = await calculateRoi({
        budget: debouncedWorkingBudget,
      });
      if (
        estimatedRoi == null ||
        estimatedRoi.calculatedAtTimestamp <
          nextEstimatedRoi.calculatedAtTimestamp
      ) {
        console.log('Setting estimated ROI', nextEstimatedRoi);
        setEstimatedRoi(nextEstimatedRoi);
        setIsPendingRoiUpdate(false);
      }
    };
    if (
      debouncedWorkingBudget != null &&
      estimatedRoi?.estimatedROIs[0].dailyBudget != debouncedWorkingBudget
    ) {
      fetchRoi();
    }
  }, [debouncedWorkingBudget, calculateRoi, estimatedRoi]);

  const getLatestRoiMetricByMethodology = useCallback(
    (methodology: string) => {
      return estimatedRoi?.estimatedROIs?.find(
        (metric) => metric.methodology === methodology
      );
    },
    [estimatedRoi]
  );

  return {
    getLatestRoiMetricByMethodology,
    setWorkingBudget,
    workingBudget,
    isPendingRoiUpdate,
  };
};
