import {
  BaseTemplateValueResource,
  SettingDefinitionResource,
  TextSettingDefinitionResource,
} from '@outbound/types';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export interface FieldValidationResult {
  //The value that was validated
  value: any;

  //Whether the value is invalid
  isValid: boolean;

  //The errors that occurred during validation
  errors: string[];
  //The id of the field
  id: string;
}

export interface ValidationResult {
  results: { [key: string]: any };
  isValid: boolean;
}

const buildYupValidationForTextFieldResource = (
  field: TextSettingDefinitionResource
) => {
  return buildYupValidationForTextField(
    field.label,
    field.publishingValidation.isRequired,
    field.publishingValidation.minLength,
    field.publishingValidation.maxLength
  );
};

export const buildYupValidationForTextField = (
  label: string,
  isRequired: boolean,
  minLength?: number,
  maxLength?: number
): Yup.StringSchema<string | undefined, AnyObject, string | undefined> => {
  let yupField = Yup.string();
  //Add Validation For Required Fields
  if (isRequired) {
    yupField = yupField.required(`${fieldLabel(label)} is required.`);
  }
  //Add Validation For Fields with a Max Length
  if (maxLength) {
    yupField = yupField.max(
      maxLength,
      `${fieldLabel(label)} can not be more than ${maxLength} characters.`
    );
  }
  //Add Validation For Fields with a Min Length
  if (minLength) {
    yupField = yupField.min(
      minLength,
      `${fieldLabel(label)} can not be less than ${minLength} characters.`
    );
  }

  return yupField;
};

export const validateTextSetting = (
  id: string,
  value: string,
  validationRules: Yup.StringSchema
): FieldValidationResult => {
  const validationResult: FieldValidationResult = {
    id: id,
    value: value,
    isValid: true,
    errors: [],
  };
  //Build the validation schema

  try {
    validationRules.validateSync(value, { abortEarly: false });
  } catch (err) {
    //If Error is a Yup error
    if (err instanceof Yup.ValidationError) {
      //Populate the errors in the validation results
      err.inner.forEach((error: any) => {
        validationResult.errors.push(error.message);
        validationResult.isValid = false;
      });
    } else {
      throw err;
    }
  }

  return validationResult;
};

export const buildValidationSchemaForTemplateSettings = (
  fields: Array<SettingDefinitionResource>
) => {
  const schema: { [key: string]: any } = {};

  fields.forEach((field) => {
    switch (field.type) {
      case 'text': {
        let yupField = Yup.string();
        if (isTextSettingFieldResource(field)) {
          yupField = buildYupValidationForTextFieldResource(field);
        }
        schema[field.id] = yupField;
        break;
      }
      case 'color': {
        //Future implementation
        break;
      }
      case 'image': {
        //Future implementation
        break;
      }
      default: {
        throw new Error('Unsupported field type');
      }
    }
  });

  return Yup.object(schema);
};

/**
 * Helper function to validate the given template settings.
 * @param template
 * @param templateSettingValues
 */
export const validateTemplateSettings = async (
  templateSettingFields: Array<SettingDefinitionResource>,
  templateSettingValues: Array<BaseTemplateValueResource>
): Promise<ValidationResult> => {
  //Build map from values array of id to value
  const valuesMap = templateSettingValues.reduce(
    (acc, value) => {
      //Initial handling for this can be made more robust for other types and cases as the need arises
      if (value.type === 'text' && value.value) {
        acc[value.id] = value.value.text;
      } else {
        acc[value.id] = value.value;
      }

      return acc;
    },
    {} as { [key: string]: any }
  );

  //Build the validation schema
  const schema = buildValidationSchemaForTemplateSettings(
    templateSettingFields ?? []
  );

  //Prepare the return map of id -> ValidationResult
  const validationResults: { [key: string]: FieldValidationResult } =
    templateSettingFields.reduce(
      (acc, field) => {
        acc[field.id] = {
          value: valuesMap[field.id],
          errors: [],
          isValid: true,
          id: field.id,
        };
        return acc;
      },
      {} as { [key: string]: FieldValidationResult }
    );

  try {
    await schema.validate(valuesMap, { abortEarly: false });
  } catch (err) {
    //If Error is a Yup error
    if (err instanceof Yup.ValidationError) {
      //Populate the errors in the validation results
      err.inner.forEach((error: any) => {
        validationResults[error.path].errors.push(error.message);
        validationResults[error.path].isValid = false;
      });
    } else {
      throw err;
    }
  }

  //Check if all fields are valid
  const isValid = Object.values(validationResults).every(
    (result) => result.isValid
  );

  return { results: validationResults, isValid };
};

const fieldLabel = (label: string) => {
  return label ?? 'Field';
};

/**
 * Type guard for checking if a field is a text field
 * @param field
 * @returns
 */
const isTextSettingFieldResource = (
  field: SettingDefinitionResource
): field is TextSettingDefinitionResource => {
  return field.type === 'text';
};
