import { useCallback, useEffect, useRef, useState } from 'react';

import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObInput, ObInputProps } from '../../elements/ob-input/ob-input';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObChipInputProps extends ObInputProps {
  /**
   * The current value of the input
   */
  values: Array<ObChipValue>;

  /**
   * Callback to call with the new set of values
   */
  setValues: (values: Array<ObChipValue>) => void;

  /**
   * Indicates that the component is in an error state
   */
  hasError: boolean;
}

export interface ObChipValue {
  data?: any;
  value: string;
}

export const ObChipInput = ({
  hasError,
  values = [],
  setValues,
  isDisabled,
  inputId,
}: ObChipInputProps) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (values == null) {
      setValues([]);
    }
  });

  const clearInput = useCallback(() => {
    if (inputRef?.current) {
      const input: any = inputRef.current;
      input.value = '';
    }
  }, []);

  const addChipsToValues = useCallback(
    (keywords: string[], values: Array<ObChipValue>) => {
      const newValues = keywords.reduce(
        (acc, keyword) => {
          if (
            keyword.trim() !== '' &&
            !values.some((k) => k.value === keyword.trim())
          ) {
            acc.push({ value: keyword.trim() });
          }
          return acc;
        },
        [...values]
      );
      setValues(newValues);
    },
    [setValues]
  );

  const removeChipFromValues = (keyword: string) => {
    setValues(values.filter((k) => k.value !== keyword));
    focusInput();
  };

  const focusInput = useCallback(() => {
    if (inputRef?.current) {
      const input: any = inputRef.current;
      input.focus();
    }
  }, []);

  const addKeyword = useCallback(() => {
    if (inputValue && inputValue.trim() !== '') {
      addChipsToValues([inputValue], values);
      clearInput();
      setInputValue('');
    }
  }, [inputValue, addChipsToValues, values, clearInput]);

  const handleOnChange = useCallback(
    (value: any) => {
      if (value.includes(',') || value.includes(';')) {
        const keywords = value
          .split(/[,;]+/)
          .map((v: string) => v.trim())
          .filter(Boolean);
        addChipsToValues(keywords, values);
        setInputValue('');
        clearInput();
      } else {
        setInputValue(value);
      }
    },
    [addChipsToValues, clearInput, values]
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault(); //Don't submit the form if chip input is inside a form
        e.stopPropagation(); //Don't trigger the parent's click event
        if (inputValue) {
          addChipsToValues([inputValue], values);
          setInputValue('');
          clearInput();
        }
      } else if (e.key === 'Escape') {
        clearInput();
        setInputValue('');
      }
    },
    [addChipsToValues, clearInput, inputValue, values]
  );

  const handlePaste = useCallback(
    (e: React.ClipboardEvent) => {
      e.preventDefault();
      const paste = e.clipboardData.getData('text');
      const keywords = paste
        .split(/[,;]+/)
        .map((v) => v.trim())
        .filter(Boolean);
      addChipsToValues(keywords, values);
    },
    [addChipsToValues, values]
  );

  return (
    <div
      role='menuitem'
      className='cursor-pointer focus:ring-0 focus:outline-none'
      tabIndex={-1}
      onClick={focusInput}
      onKeyDown={focusInput}
    >
      <ObInput
        ref={inputRef}
        inputClassName='focus:ring-0 focus:outline-none'
        inputId={inputId}
        topContent={
          values &&
          values.length > 0 && (
            <div className='w-full h-auto flex flex-row px-4 py-3 flex-wrap'>
              {values.map((k) => (
                <div
                  key={k.value}
                  tabIndex={-1}
                  className='flex flex-row justify-center items-center px-3 py-1 mr-3 mb-2 rounded-[16px] border border-solid border-borderDefaultNormalLight dark:border-borderDefaultNormalDark'
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <ObTypography
                    as='span'
                    color='secondary'
                  >
                    {k.value}
                  </ObTypography>

                  <div
                    tabIndex={0}
                    role='button'
                    aria-label={`Remove ${k.value}`}
                    className='w-5 h-5 ml-3 flex cursor-pointer'
                    onClick={() => removeChipFromValues(k.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); //Don't submit the form if chip input is inside a form
                        e.stopPropagation(); //Don't trigger the parent's click event
                        removeChipFromValues(k.value);
                      }
                    }}
                  >
                    <ObIcon
                      icon='close'
                      size='small'
                      color='tertiary'
                    />
                  </div>
                </div>
              ))}
            </div>
          )
        }
        placeholder='Type and press enter to add a value'
        iconRight='add'
        size={'medium'}
        error={hasError}
        onKeyDownCallback={handleOnKeyDown}
        preventDefaultOnEnter={true} //We may want to revisit this from a UX / Accessibility perspective to allow form submission when the input is empty
        onChangeCallback={handleOnChange}
        onPaste={handlePaste}
        onIconRightClickLabel='Add Value'
        onClick={addKeyword}
        isDisabled={isDisabled}
      />
    </div>
  );
};
