import {
  ObBrand,
  ObDivider,
  ObIcon,
  ObTypography,
} from '@outbound/design-system';
import { cva, cx } from 'class-variance-authority';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useAppNavigation } from '../../../../hooks/use-app-navigation';
import { useWindowDimensions } from '../../../../hooks/use-window-dimensions';
import {
  filterOutUnreleasedNavItems,
  getRoutesForNavMenuLocation,
} from '../../../../navigation/navigation-utils';
import { dashboardRoute } from '../../../../pages/dashboard/routes';
import { DrawerItemList } from '../drawer-item-list/drawer-item-list';
import { ToggleExpandButtonDesktop } from '../toggle-expand-button-desktop/toggle-expand-button-desktop';
import { ToggleExpandButtonMobile } from '../toggle-expand-button-mobile/toggle-expand-button-mobile';
import { DrawerProps } from '../types';

interface ListHeadingProps {
  className?: string;
  heading: string;
}

const ListHeading = ({ className, heading }: ListHeadingProps) => (
  <ObTypography
    variant='body3'
    className={cx('p-1 mt-3 mx-3', className)}
  >
    {heading}
  </ObTypography>
);

interface SubDrawerProps extends DrawerProps {
  isSubDrawerShown?: boolean;
  isCampaignDetailsPage?: boolean;
}

/**
 * The Slide Over Nav Used for the Workspace Settings
 */
export const SubDrawer = ({
  isDrawerExpanded,
  toggleDrawerExpansion,
}: SubDrawerProps) => {
  const flags = useFlags();
  const { navigateToWorkspaceHome } = useAppNavigation();
  const { isSmallOrLargerViewport: isDesktopView } = useWindowDimensions();

  const styles = cva('', {
    variants: {
      isDesktopView: {
        // DESKTOP DRAWER COLLAPSED WIDTH: 64px;
        // DESKTOP DRAWER EXPANDED WIDTH = 260px;
        // MOBILE DRAWER COLLAPSED HEIGHT: 65px;
        true: cx(
          'h-screen transition-width duration-300 ease-in-out',
          isDrawerExpanded
            ? 'w-[260px] min-w-[260px] max-w-[260px]'
            : 'w-[64px] min-w-[64px] max-w-[64px]'
        ),
        false: cx(
          'w-screen',
          isDrawerExpanded
            ? 'h-screen min-h-screen max-h-screen'
            : 'h-[65px] min-h-[65px] max-h-[65px]'
        ),
      },
    },
  });

  const handleDrawerItemClick = useCallback(() => {
    if (isDesktopView) return;

    // On mobile, always toggle expansion on click
    toggleDrawerExpansion();
  }, [isDesktopView, toggleDrawerExpansion]);

  /**
   * Find all the Nav Items for the My Account Setting Section
   */
  const myAccountSettings = getRoutesForNavMenuLocation(
    dashboardRoute,
    'my-account-settings-nav'
  );

  /**
   * Find all the Nav Items for the Workspace Setting Section
   */
  const workspaceSettings = getRoutesForNavMenuLocation(
    dashboardRoute,
    'workspace-settings-nav'
  );

  return (
    <div
      data-testid='sub-navigation-drawer'
      className={cx(
        'flex flex-1 flex-col justify-between inset-y-0 content-center bg-bgSurfaceLight dark:bg-bgDefaultDark border-r border-borderDefaultNormalLight border-opacity-[0.16] overflow-hidden',
        styles({ isDesktopView })
      )}
    >
      <div>
        <div className='flex items-center h-[64px] justify-between'>
          <div className='flex flex-1 items-center    gap-x-3'>
            <button
              data-testid='sub-navigation-back'
              type='button'
              onClick={() => navigateToWorkspaceHome()}
              className='flex flex-1 mx-3 pl-1 pr-3 py-2 flex-row rounded items-center gap-2 hover:dark:bg-dark/action/primary/on-subtle dark:active:bg-dark/action/neutral/active'
            >
              <ObIcon
                size='small'
                icon='chevronLeft'
              />
              <ObTypography
                className={cx(
                  'transition-all duration-300 ease-in-out',
                  !isDrawerExpanded &&
                    isDesktopView &&
                    'translate-x-full opacity-0'
                )}
                truncate
              >
                Settings
              </ObTypography>
            </button>
          </div>
          {!isDesktopView && (
            <ToggleExpandButtonMobile
              isDrawerExpanded={isDrawerExpanded}
              toggleDrawerExpansion={toggleDrawerExpansion}
            />
          )}
        </div>

        <ObDivider />

        <div className='[&>*]:transition-all [&>*]:duration-300 [&>*]:ease-in-out'>
          <ListHeading
            heading={'Account'}
            className={cx(isDrawerExpanded ? '' : 'hidden')}
          />
          <DrawerItemList
            onItemClick={handleDrawerItemClick}
            isDrawerExpanded={isDrawerExpanded}
            navigationItems={filterOutUnreleasedNavItems(
              myAccountSettings,
              flags
            )}
          />
          <ObDivider className={cx(isDrawerExpanded ? 'hidden' : '')} />
          <ListHeading
            heading={'Workspace'}
            className={cx(isDrawerExpanded ? '' : 'hidden')}
          />
          <DrawerItemList
            onItemClick={handleDrawerItemClick}
            isDrawerExpanded={isDrawerExpanded}
            navigationItems={filterOutUnreleasedNavItems(
              workspaceSettings,
              flags
            )}
          />
        </div>
      </div>
      {isDesktopView ? (
        <ToggleExpandButtonDesktop
          isDrawerExpanded={isDrawerExpanded}
          toggleDrawerExpansion={toggleDrawerExpansion}
        />
      ) : (
        <div className='py-4 px-5'>
          <ObBrand
            variant='icon'
            size='small'
            color='full-color'
          />
        </div>
      )}
    </div>
  );
};
