'use client';
import { cx } from 'class-variance-authority';
import {
  getMonth,
  getYear,
  isSameDay,
  isSameHour,
  isSameMinute,
} from 'date-fns';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ObButton } from '../../../index';
import './styles.css';

const HEADER_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export interface ObDateTimePickerProps {
  datepickerRef?: any;
  initialDate?: any;
  initialTime?: any;
  dateFormat?: string;
  timeFormat?: string;
  placeholderText?: string;
  children: ReactNode;
  type?: 'date' | 'time';
  onChangeCb?: (selection: any) => void;
  onCalendarOpenCb?: () => void;
  onCalendarCloseCb?: () => void;
}

const CalendarContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      data-testid='ob-date-time-picker'
      className='dark:bg-actionNeutralNormalDark border-[0] rounded-[8px] flex font-sans'
    >
      {children}
    </div>
  );
};

export const ObDateTimePicker = ({
  datepickerRef = null,
  initialDate = null,
  initialTime = null,
  placeholderText = '',
  dateFormat,
  timeFormat,
  children,
  type = 'date',
  onChangeCb,
  onCalendarOpenCb,
  onCalendarCloseCb,
}: ObDateTimePickerProps) => {
  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(initialTime);

  const handleDateChange = useCallback(
    (value: any) => {
      if (type === 'date') {
        setDate(value);
      } else {
        setTime(value);
      }

      if (onChangeCb) {
        onChangeCb(value);
      }
    },
    [onChangeCb, type]
  );

  const renderSelectedTimeClassName = useCallback(
    (toRenderTime: any) => {
      const isSelectedHour = isSameHour(toRenderTime, time);
      const isSelectedMinute = isSameMinute(toRenderTime, time);
      const isSelectedClasses =
        isSelectedHour && isSelectedMinute
          ? '!text-actionPrimaryV2NormalDark dark:!text-actionPrimaryV2NormalDark '
          : '';

      return (
        isSelectedClasses +
        'hover!:bg-actionPrimaryHoverLight hover:dark:!bg-actionPrimaryHoverDark'
      );
    },
    [time]
  );

  const renderDay = useCallback(
    (toRenderDay: any, toRenderDate: any) => {
      const isSelectedDay = isSameDay(toRenderDate, date);
      const isToday = isSameDay(toRenderDate, new Date());

      return (
        <>
          <span
            data-testid={isToday && 'ob-date-picker-today'}
            className={cx(
              'w-full flex rounded justify-center hover:bg-actionPrimaryHoverLight hover:dark:bg-actionPrimaryHoverDark',
              !isSelectedDay &&
                'text-contentPrimaryLight dark:text-contentPrimaryDark'
            )}
          >
            {toRenderDay}
          </span>
          {isSelectedDay ? (
            <div
              className={
                'w-1 h-1 bg-actionPrimaryV2NormalDark dark:bg-actionPrimaryV2NormalDark rounded mx-[auto]'
              }
            />
          ) : null}
        </>
      );
    },
    [date]
  );

  const selectedDayStyles: any = useMemo(
    () => [
      {
        'text-actionPrimaryV2NormalDark dark:text-actionPrimaryV2NormalDark': [
          new Date(date),
        ],
      },
    ],
    [date]
  );

  return (
    <DatePicker
      ref={datepickerRef}
      customInput={children}
      selected={type === 'date' ? date : time}
      showPopperArrow={false}
      popperPlacement='bottom-end'
      onChange={handleDateChange}
      renderDayContents={renderDay}
      timeClassName={renderSelectedTimeClassName}
      highlightDates={selectedDayStyles}
      calendarContainer={CalendarContainer}
      placeholderText={placeholderText}
      onCalendarOpen={onCalendarOpenCb}
      onCalendarClose={onCalendarCloseCb}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        return type === 'time' ? null : (
          <div className='m-0 p-2 flex justify-between items-center border-b-[1px] dark:border-borderDefaultNormalDark'>
            <ObButton
              variant='ghost'
              buttonType='icon'
              size='small'
              iconLeft='left'
              label='Previous Month'
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className='rounded-[8px!important] min-w-[24px!important] px-[0!important] h-[24px!important]'
              aria-label='Previous Month'
            />

            <p className='text-[14px] text-contentPrimaryLight dark:text-contentPrimaryDark'>
              {HEADER_MONTHS[getMonth(date)]} {getYear(date)}
            </p>

            <ObButton
              variant='ghost'
              buttonType='icon'
              size='small'
              iconLeft='right'
              label='Next Month'
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className='rounded-[8px!important] min-w-[24px!important] px-[0!important] h-[24px!important]'
              aria-label='Next Month'
            />
          </div>
        );
      }}
      {...(type === 'time'
        ? {
            showTimeSelect: true,
            showTimeSelectOnly: true,
            dateFormat: timeFormat,
            timeFormat: timeFormat,
            timeIntervals: 15,
          }
        : {
            dateFormat: dateFormat,
          })}
    />
  );
};
