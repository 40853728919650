/** solid/maps/marker-pin-06 */
export const MarkerPin06 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M12 2C9.79086 2 8 3.79086 8 6C8 7.86384 9.27477 9.42994 11 9.87398V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V9.87398C14.7252 9.42994 16 7.86384 16 6C16 3.79086 14.2091 2 12 2Z'
        fill='currentColor'
      />
      <path
        d='M8.19292 14.3554C8.73483 14.2489 9.08776 13.7232 8.98121 13.1813C8.87467 12.6394 8.34899 12.2865 7.80708 12.393C5.95688 12.7568 4.31925 13.3651 3.11641 14.1764C1.93566 14.9727 1 16.0947 1 17.4998C1 19.381 2.64633 20.7512 4.51857 21.5937C6.48468 22.4784 9.13066 22.9998 12 22.9998C14.8693 22.9998 17.5153 22.4784 19.4814 21.5937C21.3537 20.7512 23 19.381 23 17.4998C23 16.0947 22.0643 14.9727 20.8836 14.1764C19.6807 13.3651 18.0431 12.7568 16.1929 12.393C15.651 12.2865 15.1253 12.6394 15.0188 13.1813C14.9122 13.7232 15.2652 14.2489 15.8071 14.3554C17.4887 14.6861 18.8511 15.2179 19.7653 15.8345C20.7016 16.466 21 17.0596 21 17.4998C21 18.1038 20.4077 18.9837 18.6607 19.7698C17.0075 20.5138 14.6535 20.9998 12 20.9998C9.34649 20.9998 6.99248 20.5138 5.3393 19.7698C3.59225 18.9837 3 18.1038 3 17.4998C3 17.0596 3.29844 16.466 4.23474 15.8345C5.14894 15.2179 6.51131 14.6861 8.19292 14.3554Z'
        fill='currentColor'
      />
    </svg>
  );
};
