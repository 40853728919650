'use client';
import { cva, cx } from 'class-variance-authority';
import { FC, useState } from 'react';
import { ObIcon, ObTypography } from '../..';

export interface Badge {
  content: string;
}

/**
 * Props that control the visual appearance of the tag.
 * These are props that are typically shared by all tags in a group.
 */
export interface ObTagVariantProps {
  /**
   * The size of the tag
   */
  size?: ObTagSize;
  /**
   * Controls if the tag is selectable (Acts as a checkbox)
   */
  selectable?: boolean;
  /**
   * Controls if the x button is shown
   */
  removable?: boolean;
}

export interface ObTagStateProps {
  /**
   * Controls if the tag is selected
   */
  selected?: boolean;
  /**
   * Controls if the tag is disabled
   */
  disabled?: boolean;
  /**
   * The text content of the tag
   */
  content: string;
  /**
   * Callback that should be called when the tag is removed
   */
  onRemoveCallback?: (e: any) => void;
}

export interface ObTagProps extends ObTagVariantProps, ObTagStateProps {}

export type ObTagSize = 'default' | 'small' | 'medium';

/**
 *
 * @param heading
 * @constructor
 */
export const ObTag: FC<ObTagProps> = ({
  content,
  size = 'small',
  selectable = true,
  selected,
  disabled = false,
  removable = false,
  onRemoveCallback,
}: ObTagProps) => {
  /**
   * This should be updated to be controlled by the parent.
   */
  const [isSelected, setSelected] = useState(false);

  const getTextVariantForSize = (size: ObTagSize) => {
    switch (size) {
      case 'default':
      case 'small':
        return 'body3';
      case 'medium':
        return 'body2';
    }
  };

  const handleClick = () => {
    if (selected == null && selectable) {
      setSelected((prev) => !prev);
    }
  };

  const tagContainerStyles = cva(
    [
      'border dark:border-dark/border/default/normal',
      'dark:bg-dark/action/neutral/normal bg-light/action/neutral/normal',
      'data-[disabled=true]:opacity-50',
      'text-light/content/primary dark:text-dark/content/primary',
    ],
    {
      variants: {
        size: {
          default: 'px-[10px] py-0 h-[24px]',
          small: 'px-[10px] py-0 h-[24px]',
          medium: 'px-4 py-2 h-[40px]',
        },
        removable: {
          true: '',
          false: '',
        },
        selectable: {
          true: [
            'data-[disabled=false]:cursor-pointer ',
            'focus:outline-none  data-[disabled=false]:focus:shadow-interactive',
            'data-[disabled=false]:hover:dark:bg-dark/action/neutral/hover',
            'dark:data-[selected=true]:bg-dark/action/primary/normal data-[disabled=false]:hover:dark:data-[selected=true]:bg-dark/action/primary/hover',
            'data-[selected=true]:bg-light/action/primary/subtle-normal data-[disabled=false]:hover:dark:data-[selected=true]:bg-dark/action/primary/hover',
            'data-[disabled=false]:hover:dark:border-dark/border/default/hover data-[selected=true]:text-light/action/primary/on-subtle ',
            '',
          ],
          false: '',
        },
      },
    }
  );

  return (
    <span
      // When selectable is true, the tag should behave like a checkbox, otherwise it shouldn't be interactive
      {...(selectable && {
        role: 'checkbox',
        tabIndex: 0,
        'aria-checked': selected ?? isSelected,
        onClick: handleClick,
      })}
      data-selected={selected ?? isSelected}
      data-disabled={disabled}
      aria-disabled={disabled}
      data-testid='ob-tag'
      className={cx(
        ' inline-flex justify-center items-center gap-1 transition box-border rounded-3xl',
        tagContainerStyles({ size, removable, selectable })
      )}
    >
      <ObTypography
        variant={getTextVariantForSize(size)}
        as='span'
        color='inherit' //Let CSS Style this typography
        className='select-none whitespace-nowrap text-ellipsis overflow-hidden '
      >
        {content}
      </ObTypography>
      {removable && (
        <div className='flex justify-center items-center dark:text-dark/content/tertiary text-light/content/tertiary hover:bg-light/action/neutral/hover hover:dark:bg-dark/action/neutral/hover rounded-full p-[1px]'>
          <button
            disabled={disabled}
            onClick={onRemoveCallback}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (onRemoveCallback) {
                  onRemoveCallback(e);
                }
              }
            }}
          >
            <ObIcon
              icon={'close'}
              color='inherit' //Let Css style this icon
              size='x-small'
            />
          </button>
        </div>
      )}
    </span>
  );
};
