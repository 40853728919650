import { ObCustomerStatus } from '@outbound/types';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObMetricStatusContext {
  status: ObCustomerStatus;
  title: string;
}

export interface ObMetricCardHeaderProps {
  title: string;
  status: ObCustomerStatus;
  context: ObMetricStatusContext;
}

export const ObMetricCardHeader = ({ title }: ObMetricCardHeaderProps) => {
  return (
    <div className='flex flex-row items-center justify-between mb-4'>
      <div className='flex flex-row gap-2 justify-start items-center'>
        <ObTypography variant='h4'>{title}</ObTypography>
        <button>
          <ObIcon
            icon='context'
            size='x-small'
            color='secondary'
          />
        </button>
      </div>
      <button>
        <ObIcon
          icon='nominal'
          color='positive'
          size='medium'
        />
      </button>
    </div>
  );
};
