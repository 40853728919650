import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0Extended } from '../../auth/use-auth0-extended';
import { useFetchMyWorkspacesOnDemand } from '../../query/my-workspaces/use-my-workspaces-endpoints';
import { FullPageLoadingSpinner } from '../layouts/full-page-loading-spinner';

/**
 * Wraps a React Router Route to Ensure that a User is Authenticated WITH A WORKSPACE
 * (Application Tenant / Auth0 Organization) before Accessing the Route.
 *
 * If a user attempts to access this route they will be redirected to either:
 *
 * The login page if they are not authenticated
 *
 * --- or ---
 *
 * The page logged into their only workspace (IF THEY ONLY HAVE ONE WORKSPACE)
 *
 * --- or ---
 *
 * The Workspace Launcher if they are authenticated as a user but don't have application tenant
 * details in their Access Token
 *
 * @constructor
 */
const ProtectedWorkspaceRoute = ({
  component,
  ...args
}: ProtectedRouteProps) => {
  const {
    isAuthenticated,
    getIdTokenClaims,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticatedWithWorkspaceCheckInProgress,
    isAuthenticatedWithWorkspace,
    switchOrganization,
  } = useAuth0Extended();

  const { refetch: getMyWorkspaces } = useFetchMyWorkspacesOnDemand();

  const [
    hasCheckedThatThereIsMoreThanOneWorkspace,
    setHasCheckedThatThereIsMoreThanOneWorkspace,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAuthenticated) {
      console.log(
        'Cannot access Workspace Route without Authentication. Redirecting',
        isAuthenticated,
        isLoading
      );
      /**
       * We previously use useLocation hook to get the current path but it was causing
       * unnecessary re-renders in the application on navigation. Since we are going to
       * redirect here out of the app anyway we can just use window.location.pathname.
       *
       * The side-effect here is that we will not trigger this logic anymore on navigation
       */
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  /**
   * Handle the case where we are not authenticated with a workspace but only have one.
   * We will just automatically log them into that workspace instead of redirecting them and making them pick
   */
  useEffect(() => {
    console.log('Running Authentication Checks');
    const checkForSingleWorkspace = async () => {
      getMyWorkspaces().then(async (response) => {
        const myWorkspaces = response.data;
        if (myWorkspaces?.items.length === 1) {
          //LOGIN TO WORKSPACE
          await switchOrganization(
            myWorkspaces.items[0].auth0OrganizationId,
            myWorkspaces.items[0].id
          );
        } else {
          console.log('Going to Redirect to Workspace Picker');
          /**
           * Since there is more than one workspace for this user we will need to redirect them to the workspace picker
           * to choose which workspace they want to continue to this page as
           */
          setHasCheckedThatThereIsMoreThanOneWorkspace(true);
        }
      });
    };
    /**
     * In order to perform this side effect the following need to be true:
     * 1. We should be authenticated

     * 3. We should only have a single workspace
     **/
    if (
      isAuthenticated &&
      !isAuthenticatedWithWorkspaceCheckInProgress &&
      !isAuthenticatedWithWorkspace
    ) {
      console.log(
        'We are authenticated but not with a workspace yet. Checking for single workspace.'
      );
      checkForSingleWorkspace();
    }
  }, [
    getAccessTokenSilently,
    getIdTokenClaims,
    getMyWorkspaces,
    isAuthenticated,
    isAuthenticatedWithWorkspace,
    isAuthenticatedWithWorkspaceCheckInProgress,
    switchOrganization,
  ]);

  //@ts-ignore
  const Component = withAuthenticationRequired(component, args);
  if (isLoading) {
    return <></>;
  } else if (isAuthenticatedWithWorkspace) {
    console.log(
      'Protected Workspace Route: Authenticated with Workspace. Rendering Protected Route'
    );
    return <Component />;
  } else if (hasCheckedThatThereIsMoreThanOneWorkspace) {
    console.log('Protected Workspace Route: Redirecting to Workspace Picker');
    return <Navigate to={'/my-workspaces'} />;
  } else {
    return (
      <FullPageLoadingSpinner reason='protected-workspace-route-loading-workspaces' />
    );
  }
};

type ProtectedRouteProps = {
  component: ComponentType;
  args?: any;
};

export default ProtectedWorkspaceRoute;
