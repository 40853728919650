export enum OutboundMembershipPlanType {
  OUTBOUND_STARTER_PLAN = 'OUTBOUND_STARTER_PLAN',
  OUTBOUND_GROWTH_PLAN = 'OUTBOUND_GROWTH_PLAN',
  OUTBOUND_PROFESSIONAL_PLAN = 'OUTBOUND_PROFESSIONAL_PLAN',
}

export interface PostPaymentCheckoutSessionRequestResource {
  planType: OutboundMembershipPlanType;
}

export interface PostPaymentBillingPortalSessionRequestResource {
  customerId: string;
}
