import { useEffect, useMemo, useState } from 'react';
import {
  IFileUploadHandler,
  IFileUploadHandlerFactory,
} from '../../../utilities/file-upload';

export const useObUpload = (
  fileUploadHandlerFactory?: IFileUploadHandlerFactory
) => {
  /**
   * Track the progress of the file upload
   */
  const [progress, setProgress] = useState<number>(0);
  /**
   * Track if the file is currently being uploaded
   */
  const [isUploading, setIsUploading] = useState<boolean>(false);
  /**
   * Track if the file upload was successful
   */
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  /**
   * Track if there was an error during the file upload
   */
  const [isError, setIsError] = useState<boolean>(false);

  /**
   * Tracks if the file upload handler supports progress reporting
   */
  const [isProgressSupported, setIsProgressSupported] =
    useState<boolean>(false);

  const fileUploadHandler: IFileUploadHandler | undefined = useMemo(() => {
    return fileUploadHandlerFactory?.createFileUploadHandler();
  }, [fileUploadHandlerFactory]);

  useEffect(() => {
    setIsProgressSupported(
      fileUploadHandler?.supportsProgressReporting() ?? false
    );
  }, [fileUploadHandler]);

  return {
    isProgressSupported,
    progress,
    isUploading,
    isSuccess,
    isError,
    setProgress,
    setIsUploading,
    setIsSuccess,
    setIsError,
    uploadFiles:
      fileUploadHandler?.uploadFiles != null
        ? fileUploadHandler?.uploadFiles.bind(fileUploadHandler)
        : undefined, //Bind the uploadFiles method to the fileUploadHandler so we don't loose the context
  };
};
