import { LocationMapGenerationStatus, ServerDelta } from '@outbound/types';
import { computed, makeObservable, observable } from 'mobx';
import { RootStore } from '../../../root-store';
import Location from '../location';

class PhysicalLocation extends Location {
  private _addressLine1: string;
  private _addressLine2: string;
  private _city: string;
  private _state: string;
  private _country: string;
  private _postalCode: string;
  private _website?: string;
  private _phone?: string;

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    name: string,
    description: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    mapImageGenerationStatus: LocationMapGenerationStatus,
    mapImageForDarkModeAssetId?: string,
    mapImageForLightModeAssetId?: string,
    website?: string,
    phone?: string
  ) {
    super(
      rootStore,
      id,
      workspaceId,
      'PHYSICAL',
      name,
      description,
      mapImageGenerationStatus,
      mapImageForDarkModeAssetId,
      mapImageForLightModeAssetId
    );
    this._addressLine1 = addressLine1;
    this._addressLine2 = addressLine2;
    this._city = city;
    this._state = state;
    this._country = country;
    this._postalCode = postalCode;
    this._website = website;
    this._phone = phone;
  }

  makeObservableInternal(): void {
    super.makeObservableInternal();
    makeObservable(this, {
      _addressLine1: observable,
      _addressLine2: observable,
      _city: observable,
      _state: observable,
      _country: observable,
      _postalCode: observable,
      _website: observable,
      _phone: observable,
      geographicDescription: computed,
    } as any);
  }

  applyPatch(patch: ServerDelta[]): void {
    super.applyPatch(patch);
  }

  toJson(): Record<string, any> {
    const baseJson = super.toJson();
    return {
      ...baseJson,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      country: this.country,
      postalCode: this.postalCode,
      website: this.website,
      phone: this.phone,
    };
  }

  get addressLine1(): string {
    return this._addressLine1;
  }

  get addressLine2(): string {
    return this._addressLine2;
  }

  get city(): string {
    return this._city;
  }

  get state(): string {
    return this._state;
  }

  get country(): string {
    return this._country;
  }

  get postalCode(): string {
    return this._postalCode;
  }

  get website(): string | undefined {
    return this._website;
  }

  get phone(): string | undefined {
    return this._phone;
  }

  get geographicDescription(): string {
    return `${this.addressLine1}, ${this.city}, ${this.state}. ${this.postalCode}`;
  }
}

export default PhysicalLocation;
