import { useMemo } from 'react';

export const useDev = () => {
  // Check if we are running on localhost
  const isLocalhost = useMemo(() => {
    return ['localhost', '127.0.0.1', '::1'].includes(window.location.hostname);
  }, []);

  // Check if we are in development mode
  const isDevelopment = useMemo(() => {
    return process.env.NODE_ENV === 'development';
  }, []);

  // Combine both checks to determine if we're in a dev environment running on localhost
  const isDevEnvironment = useMemo(() => {
    return isLocalhost && isDevelopment;
  }, [isLocalhost, isDevelopment]);

  return {
    isLocalhost,
    isDevelopment,
    isDevEnvironment,
  };
};
