import {
  CreateWorkspaceForNewUserRequestResource,
  DeleteWorkspaceRequestResource,
  PatchWorkspaceRequestResource,
  WorkspaceResource,
} from '@outbound/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

export const useCreateWorkspaceForUserMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    WorkspaceResource,
    undefined,
    CreateWorkspaceForNewUserRequestResource
  >(
    ['workspace:post'],
    async (data: CreateWorkspaceForNewUserRequestResource) => {
      const response = await auth0AxiosClient.post('/workspace', data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workspace:get']);
        queryClient.invalidateQueries(['my-workspaces:get']);
      },
    }
  );
};

export const useFetchWorkspaceDetails = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, WorkspaceResource>(
    ['workspace:get'],
    async () => {
      const response = await auth0AxiosClient.get('/workspace');
      return response.data;
    },
    {
      // React Query configuration (staleTime, cacheTime, etc.)
      onSuccess: () => {
        // Additional actions on query success
      },
    }
  );
};

export const usePatchWorkspaceMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<undefined, undefined, PatchWorkspaceRequestResource>(
    ['workspace:patch'],
    async (data: PatchWorkspaceRequestResource) => {
      const response = await auth0AxiosClient.patch('/workspace', data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workspace:get']);
        queryClient.invalidateQueries(['my-workspaces:get']);
      },
    }
  );
};

export const useDeleteWorkspaceMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<undefined, undefined, DeleteWorkspaceRequestResource>(
    ['workspace:delete'],
    async (data: DeleteWorkspaceRequestResource) => {
      const response = await auth0AxiosClient.delete('/workspace', { data });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workspace:get']);
        queryClient.invalidateQueries(['my-workspaces:get']);
      },
    }
  );
};
