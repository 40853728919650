import {
  CampaignChannelType,
  channelTypeSelectorOptions,
} from '@outbound/types';
import { ObChannelAvatar } from '../../../../avatars';
import { StateManagedByParentInput } from '../../../../base-component-props.type';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';
import { ObRadioGroup } from '../../../../components/molecules/ob-radio-group/ob-radio-group';

export interface ChannelRadioGroupProps
  extends StateManagedByParentInput<string> {
  onConfirmWhatClickedCallback: () => void;
  hideConfirmButton?: boolean;
  availableChannels: Array<CampaignChannelType>;
}

export const ChannelRadioGroup = ({
  value,
  hideConfirmButton = false,
  onValueChangedCallback,
  onConfirmWhatClickedCallback,
  availableChannels = [],
}: ChannelRadioGroupProps) => {
  return (
    <div className='flex flex-col gap-3'>
      <ObRadioGroup
        value={value}
        size='small'
        onValueChangedCallback={onValueChangedCallback}
        options={channelTypeSelectorOptions
          .filter((c) =>
            availableChannels.includes(c.value as CampaignChannelType)
          )
          .map((option) => ({
            avatarContent: (
              <ObChannelAvatar
                size='xx-small'
                channel={option.channelType}
              />
            ),
            value: option.value,
            label: option.title,
            description: '',
          }))}
        isFieldDisabled={false}
        isLoading={false}
      />
      {!hideConfirmButton && (
        <div className='pl-2'>
          <ObButton
            variant='outline'
            label='Confirm'
            size='medium'
            onClick={onConfirmWhatClickedCallback}
          />
        </div>
      )}
    </div>
  );
};
