import {
  FormDefinition,
  FormFieldType,
  FormFieldTypeSettingsSchema,
} from '@outbound/design-system';
import { MockFileUploadHandler } from '@outbound/design-system/src/utilities/file-upload';
import { useAssetManagerUploadHandler } from '../../../../../../hooks/use-asset-manager-upload-handler';

export const BRAND_GUIDE_FORM_DEF: FormDefinition = {
  id: 'brand-guide-form',
  sections: [
    {
      id: 'colors',
      heading: 'Colors',
      subHeading: '',
      fields: [
        {
          id: 'colors',
          label: 'Brand Colors',
          helperText: 'Primary and secondary colors that represent the brand.',
          fieldTypeSettings: {
            colorsToSelect: [
              { label: 'Primary', key: 'primaryColor', isRequired: true },
              { label: 'Secondary', key: 'secondaryColor' },
            ],
          },
          type: FormFieldType.COLORS,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'logos',
      heading: 'Logos',
      subHeading: '',
      fields: [
        {
          id: 'logos',
          label: 'Logos',
          helperText: 'Primary and secondary logos that represent the brand.',
          type: FormFieldType.IMAGES,
          validationSchema: {
            isRequired: false,
          },
          fieldTypeSettings: {
            fileUploadHandlerFactory: {
              createFileUploadHandler: () => {
                return new MockFileUploadHandler();
              },
            },
            imagesToSelect: [
              {
                label: 'Full Color Logos',
                aspect: 'rectangle',
                exclusiveRow: true,
                size: 'large',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'logoFullColorForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'logoFullColorForLightBackgroundAssetId',
                  },
                ],
              },
              {
                label: 'Brand Color Logos',
                aspect: 'rectangle',
                size: 'medium',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'logoSingleColorBrandForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'logoSingleColorBrandForLightBackgroundAssetId',
                  },
                ],
              },
              {
                label: 'One-Color Logos',
                aspect: 'rectangle',
                size: 'medium',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'logoSingleColorWhiteForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'logoSingleColorBlackForLightBackgroundAssetId',
                  },
                ],
              },
              {
                label: 'Greyscale Logos',
                aspect: 'rectangle',
                size: 'medium',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'logoGreyScaleForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'logoGreyScaleForLightBackgroundAssetId',
                  },
                ],
              },
            ],
          },
        },
      ],
    },

    {
      id: 'icons',
      heading: 'Square Aspect Ratio Logos',
      subHeading: '',
      fields: [
        {
          id: 'icons',
          label: 'Icons',
          helperText:
            'Your company logo that can be used inside of a small square or circle, often a single letter or symbol that represents your brand.',
          type: FormFieldType.IMAGES,
          validationSchema: {
            isRequired: false,
          },
          fieldTypeSettings: {
            fileUploadHandlerFactory: {
              createFileUploadHandler: () => {
                return new MockFileUploadHandler();
              },
            },
            imagesToSelect: [
              {
                label: 'Full Color Icons',
                aspect: 'square',
                exclusiveRow: true,
                size: 'large',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'iconFullColorForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'iconFullColorForLightBackgroundAssetId',
                  },
                ],
              },
              {
                label: 'Brand Color Icons',
                aspect: 'square',
                size: 'medium',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'iconSingleColorBrandForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'iconSingleColorBrandForLightBackgroundAssetId',
                  },
                ],
              },
              {
                label: 'One-Color Icons',
                aspect: 'square',
                size: 'medium',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'iconSingleColorWhiteForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'iconSingleColorBlackForLightBackgroundAssetId',
                  },
                ],
              },
              {
                label: 'Greyscale Icons',
                aspect: 'square',
                size: 'medium',
                images: [
                  {
                    label: 'Dark Background',
                    key: 'iconGreyScaleForDarkBackgroundAssetId',
                  },
                  {
                    label: 'Light Background',
                    key: 'iconGreyScaleForLightBackgroundAssetId',
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    // TEMPORARILY REMOVED UNTIL WE HAVE A USE CASE - Pat
    // {
    //   id: 'tagLine',
    //   heading: 'Tag Line',
    //   subHeading: '',
    //   fields: [
    //     {
    //       id: 'tagLine',
    //       label: 'Tag Line',
    //       helperText: '',
    //       fieldTypeSettings: {},
    //       type: FormFieldType.TEXT,
    //       validationSchema: {
    //         isRequired: false,
    //       },
    //     },
    //   ],
    // },
  ],
  heading: '',
  subHeading: '',
};

export const useBrandGuideForm = (): { formDefinition: FormDefinition } => {
  const assetManagerFileUploader = useAssetManagerUploadHandler();
  /**
   * Dynamically set the file upload handler for the images fields
   */
  for (const section of BRAND_GUIDE_FORM_DEF.sections) {
    for (const field of section.fields) {
      if (field.type === FormFieldType.IMAGES) {
        const fieldSettings =
          field.fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.IMAGES>;
        fieldSettings.fileUploadHandlerFactory = {
          createFileUploadHandler: () => {
            return assetManagerFileUploader;
          },
        };
      }
    }
  }
  return {
    formDefinition: BRAND_GUIDE_FORM_DEF,
  };
};
