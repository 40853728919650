export enum RuleBuilderLogicalOperator {
  AND = 'AND',
  NOT = 'NOT',
  OR = 'OR',
}

export enum RuleBuilderComparisonOperator {
  ANY_OF_ENUM = 'ANY_OF',
  EQUALS = 'EQUALS',
  EQUALS_STRING = 'EQUALS_STRING',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  BETWEEN = 'BETWEEN',
}

/**
 * The Operators that a rule builder node can have
 */
export type RuleBuilderOperator =
  | RuleBuilderComparisonOperator
  | RuleBuilderLogicalOperator;

export const operatorToLabelMap: Record<RuleBuilderOperator, string> = {
  [RuleBuilderComparisonOperator.ANY_OF_ENUM]: 'is any of',
  [RuleBuilderComparisonOperator.EQUALS]: 'is equal to',
  [RuleBuilderComparisonOperator.EQUALS_STRING]: 'is equal to',
  [RuleBuilderComparisonOperator.LESS_THAN]: 'is less than',
  [RuleBuilderComparisonOperator.GREATER_THAN]: 'is greater than',
  [RuleBuilderComparisonOperator.BETWEEN]: 'is between',
  [RuleBuilderLogicalOperator.AND]: '',
  [RuleBuilderLogicalOperator.NOT]: '',
  [RuleBuilderLogicalOperator.OR]: '',
};

export enum ComparisonTypes {
  IS_ANY_OF = 'is any of',
  IS_EQUAL_TO = 'is equal to',
  IS_LESS_THAN = 'is less than',
  IS_GREATER_THAN = 'is greater than',
  IS_BETWEEN = 'is between',
}

/**
 * Types of data that a Rule Builder Field can operate on
 */
export enum RuleBuilderFieldDataType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM',
  TIMESTAMP = 'TIMESTAMP',
}

/**
 * Describes a field that the rule builder can operate on
 */
export interface RuleBuilderFieldBaseSchema<T> {
  /**
   * Unique identifier for the field
   */
  key: string;
  /**
   * Customer facing name of the field.
   * Suitable for use in a UI.
   * Example Age, Last Updated Timestamp, etc.
   */
  label: string;

  dataType: T;
}

export interface EnumRuleBuilderFieldSchema
  extends RuleBuilderFieldBaseSchema<RuleBuilderFieldDataType.ENUM> {
  dataType: RuleBuilderFieldDataType.ENUM;
  dataTypeConfig: {
    options: EnumOption[];
  };
}

export interface NumberRuleBuilderFieldSchema
  extends RuleBuilderFieldBaseSchema<RuleBuilderFieldDataType.NUMBER> {
  dataType: RuleBuilderFieldDataType.NUMBER;
}

export interface StringRuleBuilderFieldSchema
  extends RuleBuilderFieldBaseSchema<RuleBuilderFieldDataType.STRING> {
  dataType: RuleBuilderFieldDataType.STRING;
}

/**
 * Defines a single option for an Enum Rule Builder Field
 */
export interface EnumOption {
  value: string;
  label: string;
}

/**
 * Documents the Operators that the Enum Rule Builder Field Data Type Supports
 */
export const ENUM_SUPPORTED_OPERATORS = [
  RuleBuilderComparisonOperator.ANY_OF_ENUM,
];

export const NUMBER_SUPPORTED_OPERATORS = [
  RuleBuilderComparisonOperator.GREATER_THAN,
  RuleBuilderComparisonOperator.LESS_THAN,
  RuleBuilderComparisonOperator.EQUALS,
  RuleBuilderComparisonOperator.BETWEEN,
];

export const STRING_SUPPORTED_OPERATORS = [
  RuleBuilderComparisonOperator.EQUALS,
  RuleBuilderComparisonOperator.ANY_OF_ENUM,
];

export type AnyFieldSchema =
  | EnumRuleBuilderFieldSchema
  | StringRuleBuilderFieldSchema
  | NumberRuleBuilderFieldSchema;

/**
 * Describes the fields that are available to the rule builder
 * for targeting
 */
export interface RuleBuilderSchema {
  fields: AnyFieldSchema[];
  //Future State - Add field Sections here if we want to categorize fields into sections for UX reasons (See Creative Builder for Example)
}

/*****************************************
 *  VALUE TYPES
 *****************************************/

/**
 * The base type for all nodes in the Rule Builder Tree
 * The operator field is used to determine the type of the node
 * and how it should be rendered / processed
 */
interface BaseTargetingNode<T extends RuleBuilderOperator> {
  /**
   * Unique Identifier for the Node
   * Used to find a node in the tree and preform tree updates
   */
  nodeId: string;
  operator: T;
}

/**
 * Node that acts a parent to 1 or more child nodes.
 * This node adds a children field that contains the children nodes
 *
 * For a single child node, use TargetingNot
 */
export interface TargetingGroup
  extends BaseTargetingNode<
    RuleBuilderLogicalOperator.AND | RuleBuilderLogicalOperator.OR
  > {
  children: TargetingNode[];
}

export type TargetingConditionNodeResource = TargetingCondition & {
  fieldKeyLabel?: string;
  operationValueLabels?: string[];
  operationValueAsText?: string;
  operatorLabel: string;
  formattedString?: string;
  // combination of the operatorLabel and operationValue as a short-hand readable string
  displayValue?: string;
};
/**
 * Common fields present in all Targeting Condition Nodes
 */
interface BaseTargetingConditionNode {
  fieldKey: string;
}

export interface StringTargetingCondition
  extends BaseTargetingConditionNode,
    BaseTargetingNode<RuleBuilderComparisonOperator.EQUALS_STRING> {
  operationValue: string;
}

export interface NumberTargetingCondition
  extends BaseTargetingConditionNode,
    BaseTargetingNode<
      | RuleBuilderComparisonOperator.GREATER_THAN
      | RuleBuilderComparisonOperator.LESS_THAN
      | RuleBuilderComparisonOperator.EQUALS
    > {
  operationValue: number;
}

export interface NumberRangeTargetingCondition
  extends BaseTargetingConditionNode,
    BaseTargetingNode<RuleBuilderComparisonOperator.BETWEEN> {
  operationValue: { from: number; to: number };
}

export interface StringArrayTargetingCondition
  extends BaseTargetingConditionNode,
    BaseTargetingNode<RuleBuilderComparisonOperator.ANY_OF_ENUM> {
  operationValue: Array<string>;
}

/**
 * Describes a Rule in a Rule Tree
 * Should be read
 *
 * FIELD_KEY OPERATOR OPERATION_VALUE
 * Example:
 *
 * | field     | operator          | values         |
 * |-----------|-------------------|----------------|
 * | age       | GREATER_THAN      | 18             |
 * | first_name| EQUALS_STRING     | "Patrick"      |
 *
 */
export type TargetingCondition =
  | StringTargetingCondition
  | NumberTargetingCondition
  | NumberRangeTargetingCondition
  | StringArrayTargetingCondition;

/**
 * Node that acts a parent to a single node
 * This node adds a child field that contains the child node
 */
export interface TargetingNot
  extends BaseTargetingNode<RuleBuilderLogicalOperator.NOT> {
  child: TargetingNode;
}

export type TargetingNode = TargetingGroup | TargetingCondition | TargetingNot;
export type TargetingNodeWithChildren =
  | TargetingGroup
  | TargetingCondition
  | TargetingNot;
