import { SettingDefinitionResource } from './creative-manager.api.type';

/**
 * Base field names for a generic "Ad" template.
 * This assumes a basic ad with a single scene
 */
export const BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1 = {
  HEADING_SCENE_1: 'heading_scene_1',
  DESCRIPTION_SCENE_1: 'description_scene_1',
  CALL_TO_ACTION_SCENE_1: 'call_to_action_scene_1',
  HERO_IMAGE_SCENE_1: 'hero_image_scene_1',
};

/**
 * Base field names for a generic "Ad" template.
 * This assumes a basic ad with a single scene
 */
export const BASE_AD_SETTINGS_FIELD_NAMES_SCENE_2 = {
  HEADING_SCENE_2: 'heading_scene_2',
  DESCRIPTION_SCENE_2: 'description_scene_2',
  CALL_TO_ACTION_SCENE_2: 'call_to_action_scene_2',
  HERO_IMAGE_SCENE_2: 'hero_image_scene_2',
};

/**
 * Base field names for a generic "Ad" template.
 * This assumes a basic ad with a single scene
 */
export const BASE_AD_SETTINGS_FIELD_NAMES_SCENE_3 = {
  HEADING_SCENE_3: 'heading_scene_3',
  DESCRIPTION_SCENE_3: 'description_scene_3',
  CALL_TO_ACTION_SCENE_3: 'call_to_action_scene_3',
  HERO_IMAGE_SCENE_3: 'hero_image_scene_3',
};

/**
 * We maintain a list of common fields that are used across different mediums or across different templates within the same medium.
 * This allows us to swap out templates and mediums and keep the values the same as it makes sense that the headings
 * for one template may be valid for another type.
 */
export const BASE_AD_SETTING_FIELDS_SCENE_1: {
  [keyof: string]: Omit<SettingDefinitionResource, 'defaultValue'>;
} = {
  [`${BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.HEADING_SCENE_1}`]: {
    id: BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.HEADING_SCENE_1,
    label: 'Heading',
    type: 'text',
    publishingValidation: {
      isRequired: true,
    },
  },
  [BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.DESCRIPTION_SCENE_1]: {
    id: BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.DESCRIPTION_SCENE_1,
    label: 'Description',
    type: 'text',
    publishingValidation: {
      isRequired: true,
    },
  },
  [BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.CALL_TO_ACTION_SCENE_1]: {
    id: BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.CALL_TO_ACTION_SCENE_1,
    label: 'Call to Action',
    type: 'text',
    publishingValidation: {
      isRequired: true,
    },
  },
};
