import { toObrn } from '@otbnd/utils';
import { LocationType, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import CampaignLocation from './campaign-location';

/**
 * We do not have a single Campaign Location Resource Type defined in the API
 * so we manually define a synthetic type here to represent the API Resource
 * to the Transformer
 */
export type SyntheticCampaignLocationResource = {
  workspaceId: string;
  locationId: string;
  locationType: LocationType;
  isEnabled: boolean;
  campaignId: string;
};

class CampaignLocationTransformer extends BaseTransformer<
  SyntheticCampaignLocationResource,
  CampaignLocation
> {
  createPatchForCurrentModelAndIncomingResource(
    _model: CampaignLocation,
    _resource: SyntheticCampaignLocationResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  /**
   * Converts a Campaign Location from the Server into a MobX Model
   * @param resource
   * @returns
   */
  public fromApiResource(
    resource: SyntheticCampaignLocationResource
  ): CampaignLocation {
    return new CampaignLocation(
      this._rootStore,
      resource.locationId,
      toObrn({
        scope: resource.workspaceId,
        objectType: 'campaign/location',
        localPathId: `${resource.campaignId}/${resource.locationType}/${resource.locationId}`,
      }),
      {
        type: resource.locationType,
        isEnabled: resource.isEnabled,
      }
    );
  }
}

export default CampaignLocationTransformer;
