/** line/general/x-close */
export const XClose = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      data-testid='CloseIcon' //Old close icon had a test id of CloseIcon. This is to maintain backwards compatibility with tests
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M18 6L6 18M6 6L18 18'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
