import { ReactNode } from 'react';
import { Hue, Saturation } from 'react-color/lib/components/common';
import ColorWrap from 'react-color/lib/components/common/ColorWrap';

import { CustomColorPickerInputs } from './custom-color-picker-inputs';
import {
  CustomColorPickerPointer,
  CustomColorPickerPointerHue,
} from './custom-color-picker-pointer';
import { CustomColorPickerPresetColors } from './custom-color-picker-preset-colors';
import type { Color, OnColorChangeProps } from './custom-color-picker.types';

interface PickerProps {
  optionalColors: string[];
  rgb: Color;
  hex: Color['hex'];
  hsl: Color['hsl'];
  hsv: Color['hsv'];
  onChange: OnColorChangeProps;
}

export const Picker = ({
  optionalColors,
  rgb,
  hex,
  hsv,
  hsl,
  onChange,
}: PickerProps) => {
  const saturationProps = { hsl, hsv, radius: 8 };
  const hueProps = { hsl, hsv, radius: 24 };

  return (
    <>
      <div className='w-[319px] h-[238px] relative overflow-hidden'>
        <Saturation
          {...saturationProps}
          pointer={CustomColorPickerPointer as unknown as ReactNode}
          onChange={onChange}
        />
      </div>

      <div className='w-[319px] h-[12px] mt-4 relative'>
        <Hue
          {...hueProps}
          direction={'horizontal'}
          onChange={onChange}
          pointer={CustomColorPickerPointerHue as unknown as ReactNode}
        />
      </div>

      <div className='w-[319px] mt-4 mb-4 relative'>
        <CustomColorPickerInputs
          rgb={rgb}
          hex={hex}
          onChange={onChange}
          hasOptionalColors={optionalColors?.length > 0}
        />
      </div>

      <div className='w-[319px] mt-4'>
        <CustomColorPickerPresetColors
          colors={optionalColors}
          onClick={onChange}
        />
      </div>
    </>
  );
};

export const CustomColorPicker = ColorWrap(Picker);
