import { DeleteMyUserRequestResource } from '@outbound/types';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

export const useDeleteMyUserMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useMutation<undefined, undefined, DeleteMyUserRequestResource>(
    ['my-user:delete'],
    async (data: DeleteMyUserRequestResource) => {
      const response = await auth0AxiosClient.delete('/my-user', { data });
      return response.data;
    },
    {
      onSuccess: () => {
        //Since we are deleting the user we can safely clear the cache
        queryClient.clear();
      },
    }
  );
};
