import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
  FormDefinition,
  FormFieldDefinition,
  FormFieldType,
  FormFieldValidation,
  ObFormRenderer,
} from '@outbound/design-system';
import { FC, useCallback } from 'react';
import { useSendInvites } from '../../../../../query/workspace/use-workspace-invites-endpoints';

interface WorkspaceInvitesModalProps {
  show: boolean;
  handleHideModal: () => void;
}
const WorkspaceInvitesDialog: FC<WorkspaceInvitesModalProps> = ({
  show,
  handleHideModal,
}) => {
  const { mutateAsync: inviteToWorkspace } = useSendInvites();

  const submitInviteUsers = useCallback(
    (data: any) => {
      return new Promise((resolve, reject) => {
        inviteToWorkspace({
          invitations: data.inviteByEmail.map((email: string) => ({ email })),
        })
          .then(() => {
            handleHideModal();
          })
          .catch((err: any) => {
            console.log('submitInviteUsers: ', { err });
            reject(err);
          })
          .finally(() => {
            // This finishes the loading button animation
            resolve('completed');
          });
      });
    },
    [handleHideModal, inviteToWorkspace]
  );

  const inviteMembersForm: FormDefinition = {
    id: 'invite-member-form',
    heading: '',
    sections: [
      {
        id: 'inviteMembersSection',
        heading: '',
        subHeading: '',
        fields: [
          {
            id: 'inviteByEmail',
            label: 'Invite By Email',
            helperText: 'Enter up to five emails separated by a comma',
            type: FormFieldType.TEXT_LIST_EMAIL,
            validationSchema: {
              isRequired: true,
              min: 1,
              max: 5,
            } as FormFieldValidation,
            fieldTypeSettings: {},
          } as FormFieldDefinition,
        ],
      },
    ],
    subHeading: '',
  };

  return (
    <Dialog
      open={show}
      fullWidth={true}
      onClose={handleHideModal}
      aria-labelledby='invite-member-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='invite-member-dialog-title'>Invite Members</DialogTitle>
      <DialogContent>
        <ObFormRenderer
          formDefinition={inviteMembersForm}
          defaultValues={{ inviteByEmail: [] }}
          onSubmitCallback={submitInviteUsers}
          submitButtonLabel='Send Invites'
        />
      </DialogContent>
    </Dialog>
  );
};

export default WorkspaceInvitesDialog;
