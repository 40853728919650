import React, { FC, useCallback, useEffect, useState } from 'react';
import { ObButton } from '@outbound/design-system';
export interface CreativeBuilderZoomableContentProps {
  /**
   * The total px on the x axis of the zoomable content area being completely covered by panels
   * (Should not include the header panel )
   */
  viewableAreaCoveredWithPanelsX: number;
  /**
   * The total px on the y axis of the zoomable content area being completely covered by panels
   * (Should not include the side panels )
   */
  viewableAreaCoveredWithPanelsY: number;
  children?: React.ReactNode;
  leftControlsSlot?: React.ReactNode;
  floatingControlsSlot?: React.ReactNode;
}

export const CreativeBuilderZoomableContent: FC<
  CreativeBuilderZoomableContentProps
> = ({
  children,
  viewableAreaCoveredWithPanelsX: openPanelWidth,
  viewableAreaCoveredWithPanelsY: openPanelHeight,
  leftControlsSlot,
  floatingControlsSlot,
}: CreativeBuilderZoomableContentProps) => {
  const [scale, setScale] = useState<number>(1);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [transformOrigin, setTransformOrigin] = useState<string>('50% 50%');
  const [initialPositionComplete, setInitialPositionComplete] =
    useState<boolean>(false);
  const [contentReady, setContentReady] = useState<boolean>(false);

  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const refCallback = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  const handleScale = useCallback(
    (newScale: number, setPositionAndScale?: boolean, maxWidth?: number) => {
      if (element) {
        const contentRect = element.getBoundingClientRect();
        const areaRect = element.parentElement!.getBoundingClientRect();

        if (contentRect && areaRect) {
          let scaledContentWidth = (contentRect.width / scale) * newScale;
          let scaledContentHeight = (contentRect.height / scale) * newScale;

          // Constrain width to maxWidth while maintaining aspect ratio
          if (maxWidth && scaledContentWidth > maxWidth) {
            const aspectRatio = contentRect.height / contentRect.width;
            newScale = (maxWidth * scale) / contentRect.width;
            scaledContentWidth = maxWidth; // Update width to be the maximum width
            scaledContentHeight = maxWidth * aspectRatio; // Adjust height based on aspect ratio
          }

          const newPosX =
            (areaRect.width + openPanelWidth - scaledContentWidth) / 2;
          const newPosY = (areaRect.height - scaledContentHeight) / 2;
          setScale(newScale);
          if (setPositionAndScale) {
            setPosition({ x: newPosX, y: newPosY });
          }
        }
      }
    },
    [element, openPanelWidth, scale]
  );

  const setOriginTransformToViewportCenter = useCallback(() => {
    if (!element) {
      return;
    }
    /**
     * Grab the creative canvas area (Should be full width and height of screen (it goes under the panels))
     */
    const areaRect = element.parentElement!.getBoundingClientRect();
    /**
     * When calculating the transform origin we need to take into account any open panels
     * since the creative canvas stretches the full width of the screen. However from a UX
     * perspective we want the transform origin to be the center of the visible area.
     */
    const xTransformOrigin =
      (areaRect.width + openPanelWidth) / areaRect.width / 2;
    const yTransformOrigin =
      (areaRect.height + openPanelHeight) / areaRect.height / 2;
    setTransformOrigin(`${xTransformOrigin}% ${yTransformOrigin}%`);
  }, [element, openPanelHeight, openPanelWidth]);

  /**
   * Zooms in on the content
   */
  const zoomIn = () => {
    setOriginTransformToViewportCenter();
    handleScale(Math.min(scale + 0.1, 3), true);
  };

  /**
   * Zooms out of the content
   */
  const zoomOut = () => {
    setOriginTransformToViewportCenter();
    handleScale(Math.max(scale - 0.1, 0.5), true);
  };

  /**
   * Sets the content to its natural size without scaling.
   */
  const setContentToNaturalSize = useCallback(() => {
    setOriginTransformToViewportCenter();
    // Assuming `element` is the content element you're working with
    if (element) {
      // Reset scale to 1 to ensure content is at 100% of its natural size
      const naturalScale = 1;

      handleScale(naturalScale, true, 1300);
    }
  }, [setOriginTransformToViewportCenter, element, handleScale]);

  /**
   * Fits the content to the screen with a small amount of padding
   */
  const fitFrameToScreen = useCallback(() => {
    /**
     * We don't want the content to go full bleed (To the edge of the viewport)
     * We set padding of 16px on each side of the content for the "fit"
     */
    const FIT_PADDING_IN_PX = 32; //16px on each side
    setOriginTransformToViewportCenter();
    if (element) {
      const contentRect = element.getBoundingClientRect();
      const areaRect = element.parentElement!.getBoundingClientRect();
      if (contentRect && areaRect) {
        const widthScale =
          ((areaRect.width - openPanelWidth) /
            (contentRect.width + FIT_PADDING_IN_PX)) *
          scale;
        const heightScale =
          ((areaRect.height - openPanelHeight) /
            (contentRect.height + FIT_PADDING_IN_PX)) *
          scale;

        /**
         * We will use the smaller of the two scales to fit the content to the screen
         */
        const fitScale = Math.min(widthScale, heightScale);
        handleScale(fitScale, true, 1300);
      }
    }
  }, [
    element,
    handleScale,
    openPanelHeight,
    openPanelWidth,
    scale,
    setOriginTransformToViewportCenter,
  ]);

  /**
   * Positions the content in the center of the viewable screen on initial render
   * This hook is only meant to be run a single time when the element is rendered to the dom
   * and when we have a reference to the element. Once we call this hook it should not be called again.
   */
  useEffect(() => {
    if (element && !initialPositionComplete) {
      /**
       * Tracking initial position complete so that we can prevent the initial position from being
       * run again. If not the content will jump on the user anytime any of the dependencies in the deps array change.
       */
      setInitialPositionComplete(true);
      setContentToNaturalSize();
      setTimeout(() => {
        /**
         * We are using a timeout here due to our use of transitions on the page.
         * An better approach would be to disable all animations until the initial render is complete.
         */
        setContentReady(true);
      }, 400);
    }
  }, [element, setContentToNaturalSize, initialPositionComplete]);

  /**
   * Handle 'Command + =' to zoom into the content
   * @param event
   */
  const handleZoomInKeyCommand = (event: KeyboardEvent) => {
    if (event.key === '=' && event.metaKey) {
      event.stopPropagation();
      event.preventDefault(); // Override the browsers zoom controls
      zoomIn();
    }
  };

  /**
   * Handle 'Command + -' to zoom out of the content
   * @param event
   */
  const handleZoomOutKeyCommand = (event: KeyboardEvent) => {
    if (event.key === '-' && event.metaKey) {
      event.preventDefault(); // Override the browsers zoom controls
      zoomOut();
    }
  };

  /**
   * Handle 'Command + -' to zoom out of the content
   * @param event
   */
  const fitToScreenKeyCommand = (event: KeyboardEvent) => {
    if (event.key === '1' && event.metaKey && event.shiftKey) {
      event.preventDefault(); // Override the browsers zoom controls
      fitFrameToScreen();
    }
  };

  const registerAllKeyboardShortcuts = (event: KeyboardEvent) => {
    handleZoomInKeyCommand(event);
    handleZoomOutKeyCommand(event);
    fitToScreenKeyCommand(event);
  };
  /**
   * Register Keyboard Shortcuts
   */
  useEffect(() => {
    document.addEventListener('keydown', registerAllKeyboardShortcuts);
    return () => {
      document.removeEventListener('keydown', registerAllKeyboardShortcuts);
    };
  });

  return (
    <main
      id='ob-creative-builder__zoomable-content'
      className='content-area overflow-hidden w-full relative h-[calc(100vh)]'
    >
      <section
        className='content absolute cursor-grab transition-all duration-300 '
        ref={refCallback}
        style={{
          transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
          opacity: contentReady ? '1' : '0',
          transformOrigin: transformOrigin,
        }}
      >
        {children}
        <div
          className='relative  transition-all duration-300'
          style={{
            transform: `scale(${1 / scale})`,
            marginTop: `${(1 / scale) * 8}px`,
            opacity: contentReady ? '1' : '0',
            transformOrigin: transformOrigin,
          }}
        >
          {floatingControlsSlot}
        </div>
      </section>

      <div
        style={{
          position: 'absolute',
          width: `calc(100% - ${openPanelWidth}px)`,
          transform: `translateX(${openPanelWidth}px)`,
        }}
        className='flex transition-all justify-between items-center gap-2 absolute bottom-4 px-4 flex-grow '
      >
        <div className='flex justify-start items-center gap-2'>
          {leftControlsSlot}
        </div>
      </div>
      <div
        style={{ position: 'absolute', bottom: '0', right: '0' }}
        className='flex justify-end items-center gap-2 p-4'
      >
        <ObButton
          variant='outline'
          label='Actual Size'
          size='medium'
          onClick={() => {
            setContentToNaturalSize();
          }}
        />
        <ObButton
          variant='outline'
          label='Fit Screen'
          size='medium'
          onClick={() => {
            fitFrameToScreen();
          }}
        />
        <ObButton
          iconLeft='plus'
          variant='outline'
          size='medium'
          buttonType='icon'
          onClick={() => {
            zoomIn();
          }}
        />
        <ObButton
          iconLeft='minus'
          variant='outline'
          size='medium'
          buttonType='icon'
          onClick={() => {
            zoomOut();
          }}
        />
      </div>
    </main>
  );
};
