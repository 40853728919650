import {
  CreateServiceResourceRequest,
  CreateSubServiceResourceRequest,
  CreateSubServiceResourceResponse,
  PatchPlaybookSubServiceRequest,
  PatchServiceResourceRequest,
  ServiceResource,
  SubServiceResourceIncludeAssets,
} from '@outbound/types';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import {
  playbookSettingsKeys,
  serviceKeys,
  subServiceKeys,
} from './playbook-query-key';

const SERVICE_RESOURCE_PATH = '/playbook/settings/service';
const SUB_SERVICE_RESOURCE_PATH = 'sub-services';

const RESOURCE_NAME = 'service';
const SUB_SERVICE_RESOURCE_NAME = 'sub-service';

/**
 * Creates a new service area
 * @returns
 */
export const useCreateService = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: CreateServiceResourceRequest }
  >(
    [`playbook:settings:${RESOURCE_NAME}:post`],
    async ({ values }) => {
      const response = await auth0AxiosClient.post(
        SERVICE_RESOURCE_PATH,
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useFetchServiceById = (id: string | undefined) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, ServiceResource>(
    serviceKeys.detail(id!),
    async () => {
      const response = await auth0AxiosClient.get(
        `${SERVICE_RESOURCE_PATH}/${id}`,
        {
          params: {
            include: 'assets',
          },
        }
      );
      return response.data;
    },
    {
      enabled: !!id,
      onSuccess: () => {
        //
      },
    }
  );
};

export const usePatchServiceById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: PatchServiceResourceRequest }
  >(
    [`services`, id, 'patch'],
    async ({ values }) => {
      if (id == null) {
        throw new Error(
          `Cannot Update ${RESOURCE_NAME}. A ${RESOURCE_NAME} id must be provided.`
        );
      }
      const response = await auth0AxiosClient.patch(
        `${SERVICE_RESOURCE_PATH}/${id}`,
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useDeleteServiceById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation(
    [`playbook:settings:${RESOURCE_NAME}`, id, 'delete'],
    async () => {
      if (id == null) {
        throw new Error(
          `Cannot delete ${RESOURCE_NAME}. A ${RESOURCE_NAME} id must be provided.`
        );
      }
      const response = await auth0AxiosClient.delete(
        `${SERVICE_RESOURCE_PATH}/${id}`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useCreateSubServiceForService = (serviceId: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    CreateSubServiceResourceResponse,
    undefined,
    CreateSubServiceResourceRequest
  >(
    [
      `playbook:settings:${RESOURCE_NAME}:${serviceId}:${SUB_SERVICE_RESOURCE_NAME}:post`,
    ],
    async (requestBody: CreateSubServiceResourceRequest) => {
      const response = await auth0AxiosClient.post<
        CreateSubServiceResourceRequest,
        AxiosResponse<CreateSubServiceResourceResponse>
      >(
        `${SERVICE_RESOURCE_PATH}/${serviceId}/${SUB_SERVICE_RESOURCE_PATH}`,
        requestBody
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useFetchSubServiceById = (
  serviceId: string | undefined,
  subServiceId: string | undefined
) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, SubServiceResourceIncludeAssets>(
    subServiceKeys.detail(serviceId!, subServiceId!),
    async () => {
      const response = await auth0AxiosClient.get(
        `${SERVICE_RESOURCE_PATH}/${serviceId}/${SUB_SERVICE_RESOURCE_PATH}/${subServiceId}`,
        {
          params: {
            include: 'assets',
          },
        }
      );
      return response.data;
    },
    {
      enabled: !!serviceId,
      onSuccess: () => {
        //
      },
    }
  );
};

export const usePatchSubServiceById = (
  serviceId: string | null,
  subServiceId: string | null
) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<undefined, undefined, PatchPlaybookSubServiceRequest>(
    [`services`, serviceId, `sub-services`, subServiceId, 'patch'],
    async (requestBody) => {
      if (serviceId == null) {
        throw new Error(
          `Cannot Update ${RESOURCE_NAME}. A ${RESOURCE_NAME} id must be provided.`
        );
      }
      const response = await auth0AxiosClient.patch(
        `${SERVICE_RESOURCE_PATH}/${serviceId}/${SUB_SERVICE_RESOURCE_PATH}/${subServiceId}`,
        requestBody
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

/**
 * Archives a sub-service by it's id from a given service
 * Makes an API call to POST /service/:serviceId/sub-service/:subServiceId/archive
 * @param serviceId
 * @param subServiceId
 * @returns
 */
export const useArchiveSubServiceById = (
  serviceId: string | null,
  subServiceId: string | null
) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation(
    [
      `playbook:settings:${SUB_SERVICE_RESOURCE_NAME}`,
      serviceId,
      `sub-services`,
      subServiceId,
      'archive',
    ],
    async () => {
      if (serviceId == null || subServiceId == null) {
        throw new Error(
          `Cannot delete ${SUB_SERVICE_RESOURCE_NAME}. 
           A ${RESOURCE_NAME} id and ${SUB_SERVICE_RESOURCE_NAME} id must be provided.`
        );
      }
      const response = await auth0AxiosClient.post(
        `${SERVICE_RESOURCE_PATH}/${serviceId}/${SUB_SERVICE_RESOURCE_PATH}/${subServiceId}/archive`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};
