import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { cva, cx } from 'class-variance-authority';
import {
  BaseExposedTextInputProps,
  InputSizeProps,
  StateManagedByParentInput,
} from '../../../../base-component-props.type';
import { ObIcon } from '../../../../tokens/icons/ob-icon/ob-icon';
import { getHeightForInputSizeInPx } from '../../../../tokens/input-size/input-size';
import { ObSkeleton } from '../../../elements/ob-skeleton/ob-skelton';
import { ObTypography } from '../../../elements/ob-typography/ob-typography';

/**
 * The data structure of the options that are passed to the ObInputListbox.
 * The ObInputListbox will render a dropdown list of options based on the options passed to it.
 */
export interface ObInputListboxOption {
  value: string;
  label: string;
  description?: string;
  data?: any;
}

/**
 * Props for the ObInputListbox component.
 */
export interface ObInputListboxProps
  extends StateManagedByParentInput<string>,
    BaseExposedTextInputProps,
    InputSizeProps {
  options: Array<ObInputListboxOption>;
}

/**
 * A listbox input component that allows the user to select an option from a list of options.
 * This is the input only and does not include labels or any other form elements.
 *
 * This component expects the parent to manage the state of the value and will notify the parent when the value changes via the onValueChangedCallback.
 */
export const ObInputListbox = ({
  inputId,
  isDisabled,
  autoFocus,
  placeholder = 'Select an option...',
  options = [],
  isLoading,
  isErrored,
  size = 'medium',
  value,

  onValueChangedCallback,
}: ObInputListboxProps) => {
  const itemStyles = cva(
    'rounded flex flex-row flex-nowrap justify-between w-full px-3 py-2 data-[focus]:border-dark/border/default/normal',
    {
      variants: {
        focused: {
          true: 'bg-dark/action/primary/on-subtle',
          false: '',
        },
        isDisabled: {
          true: 'cursor-not-allowed opacity-50',
          false: 'cursor-pointer',
        },
      },
      compoundVariants: [
        {
          isDisabled: false,
          focused: true,
          className: '',
        },
        {
          isDisabled: false,
          focused: false,
          className: ' hover:bg-dark/action/neutral/hover',
        },
      ],
    }
  );

  const listBoxButtonStyles = cva(
    [
      'flex flex-row box-border flex-nowrap justify-between items-center w-full border  border-transparent dark:bg-dark/background/surface  rounded focus:outline-none focus:shadow-interactive gap-2 text-nowrap whitespace-nowrap',
    ],
    {
      variants: {
        /**
         * Expected sizes from the design system are: small: 34px, medium: 42px, large: 50px (Including border + padding)
         * https://www.figma.com/design/l1PsB4JNyhbHbnXedIkHJ4/Outbound-DS?node-id=335-13001&m=dev
         */
        size: {
          small: ['px-3 py-[5px] '],
          medium: ['px-4 py-[9px]'],
          large: ['px-4 py-[13px]'],
        },
        isDisabled: {
          true: 'opacity-50',
          false: '',
        },
        isErrored: {
          true: 'dark:border-dark/border/negative/normal',
          false:
            'dark:border-dark/border/default/normal bg-light/background/default dark:hover:border-dark/border/default/hover',
        },
      },
      defaultVariants: {
        size: 'medium',
        isErrored: false,
      },
    }
  );

  if (isLoading) {
    return (
      <ObSkeleton
        variant='rounded'
        height={`${getHeightForInputSizeInPx(size, false)}px`}
        width='100%'
      />
    );
  }
  return (
    <Listbox
      disabled={isDisabled}
      value={value ?? ''}
      onChange={(selectedValue: string) => {
        onValueChangedCallback(selectedValue, undefined);
      }}
    >
      <ListboxButton
        id={inputId}
        autoFocus={autoFocus}
        disabled={isDisabled}
        value={value ?? ''}
        className={listBoxButtonStyles({
          isErrored,
          isDisabled,

          size,
        })}
      >
        {value != null && value != '' && (
          <ObTypography
            variant='body2'
            as='span'
            color='primary'
            truncate={true}
          >
            {options.find((option) => option.value === value)?.label}
          </ObTypography>
        )}
        {/* PLACE HOLDER TEXT */}
        {(value == null || value == '') && (
          <div className='text-dark/content/placeholder'>
            <ObTypography
              variant='body2'
              as='span'
              color='inherit'
            >
              {placeholder}
            </ObTypography>
          </div>
        )}
        <ObIcon
          icon='chevronDown'
          size='small'
          color='tertiary'
        />
      </ListboxButton>
      <ListboxOptions
        anchor='bottom start'
        className={
          'dark:bg-dark/background/surface bg-light/background/default px-2 py-2 rounded border border-dark/border/default/normal [--anchor-gap:4px] [--anchor-max-height:260px] shadow-md focus:outline-none focus:shadow-interactive cursor-pointer'
        }
      >
        {options.map((option) => (
          <ListboxOption
            key={option.value}
            value={option.value}
            className={cx(
              itemStyles({ isDisabled }),

              'data-[focus]:bg-dark/action/primary/on-subtle'
            )}
          >
            {({ selected }) => (
              <ObTypography
                variant='body2'
                color={selected ? 'primaryV2' : 'primary'}
              >
                {option.label}
              </ObTypography>
            )}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
