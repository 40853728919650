import { useCallback, useMemo } from 'react';

import { OnboardingQuestion } from '../questionnaire.type';
import { PRODUCT_OR_SERVICE_QUESTION } from '../questions';
import { CheckboxGroupCardOption } from '@outbound/design-system/src/components/molecules/ob-card-option-checkbox-group/ob-card-option-checkbox-group.types';
import {
  useFetchBusinessDetails,
  usePatchBusinessDetails,
} from '../../../../query/playbook/use-playbook-settings-business-details-endpoints';

export const useProductsOrServicesQuestion = (
  questions: Array<OnboardingQuestion>
) => {
  const QUESTION_ID = PRODUCT_OR_SERVICE_QUESTION;

  //Grab the Industry Question from the Question Data
  const question = questions.find((q) => q.id === QUESTION_ID);

  const { refetch: refetchBusinessDetails } = useFetchBusinessDetails();

  const { mutateAsync: patchBusinessDetails } = usePatchBusinessDetails();

  /**
   * Function that will persist the business name question
   * @param answers
   */
  const updateProductAndServiceBooleans = useCallback(
    async (answers: { [key: string]: any }) => {
      const productOrServiceAnswer: Array<CheckboxGroupCardOption> =
        answers[QUESTION_ID]; //Lookup our answers by question id

      const isServicesBusiness = productOrServiceAnswer.some(
        (i) => i.value === 'SERVICES'
      );
      const isRetailBusiness = productOrServiceAnswer.some(
        (i) => i.value === 'PRODUCTS'
      );

      const falseServiceBusinessActivities = {
        isServiceInBusinessLocation: false,
        isServiceInCustomerLocation: false,
        isServiceOnline: false,
      };

      const falseRetailBusinessActivities = {
        isRetailInStore: false,
        isRetailOnline: false,
      };

      const businessActivities = {
        ...(isServicesBusiness ? {} : falseServiceBusinessActivities), //When the business is a service business we will reuse existing values, otherwise set values to false
        ...(isRetailBusiness ? {} : falseRetailBusinessActivities), //When the business is a retail business we will reuse existing values, otherwise we will set values to false
      };

      /**
       * Spread data from current call so we do not overwrite other data
       */
      const content = {
        businessActivities: businessActivities,
      };
      return patchBusinessDetails({ values: content });
    },
    [QUESTION_ID, patchBusinessDetails]
  );

  useMemo(() => {
    //Add the Refetch Function
    question!.fetchServerState = () => {
      return new Promise((resolve) => {
        refetchBusinessDetails().then((success: any) => {
          const businessActivities = success.data?.businessActivities || '';

          const selectedOptions: Array<CheckboxGroupCardOption> = [];

          if (
            businessActivities.isServiceInBusinessLocation ||
            businessActivities.isServiceInCustomerLocation ||
            businessActivities.isServiceOnline
          ) {
            selectedOptions.push({
              value: 'SERVICES',
              title: 'Services',
              key: 'SERVICES',
              displayValue: 'Services',
            });
          }

          if (
            businessActivities.isRetailInStore ||
            businessActivities.isRetailOnline
          ) {
            selectedOptions.push({
              value: 'PRODUCTS',
              title: 'Products',
              key: 'PRODUCTS',
              displayValue: 'Products',
            });
          }

          resolve(selectedOptions);
        });
      });
    };

    question!.submitServerState = updateProductAndServiceBooleans;
  }, [question, refetchBusinessDetails, updateProductAndServiceBooleans]);

  return {};
};
