import { Box } from '@mui/material';
import { FC } from 'react';

/**
 * This should be renamed to something more generic so it can be reused across the app as a true element
 * @constructor
 */
export const CardDivider: FC = () => {
  return (
    <Box
      data-testid='setting-card-divider'
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        marginTop: '20px',
        marginBottom: '16px',
      }}
    />
  );
};
