export const BarLineChart = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='solid/charts/bar-line-chart'>
        <g id='Solid'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.82149 1.8215C7.47236 1.17063 8.52764 1.17063 9.17851 1.8215C9.48159 2.12457 9.64354 2.51533 9.66436 2.91212L12.1372 3.83942L12.1548 3.8215C12.8057 3.17063 13.861 3.17063 14.5118 3.8215C15.1627 4.47237 15.1627 5.52765 14.5118 6.17852C13.861 6.8294 12.8057 6.8294 12.1548 6.17852C11.8517 5.87543 11.6898 5.48465 11.669 5.08784L9.19621 4.16056C9.19036 4.16658 9.18447 4.17257 9.17851 4.17852C8.63633 4.7207 7.81353 4.81124 7.17763 4.45014L4.28889 6.6167C4.41671 7.15995 4.26881 7.75489 3.84518 8.17852C3.1943 8.8294 2.13903 8.8294 1.48816 8.17852C0.837282 7.52765 0.837282 6.47237 1.48816 5.8215C2.03035 5.27931 2.85316 5.18877 3.48907 5.5499L6.37778 3.38336C6.24995 2.8401 6.39785 2.24514 6.82149 1.8215Z'
            fill='currentColor'
          />
          <path
            d='M13.3333 8.00001C13.7015 8.00001 14 8.29849 14 8.66668V13.3333C14 13.7015 13.7015 14 13.3333 14C12.9651 14 12.6667 13.7015 12.6667 13.3333V8.66668C12.6667 8.29849 12.9651 8.00001 13.3333 8.00001Z'
            fill='currentColor'
          />
          <path
            d='M8.66667 6.66668C8.66667 6.29849 8.36819 6.00001 8 6.00001C7.63181 6.00001 7.33333 6.29849 7.33333 6.66668L7.33333 13.3333C7.33333 13.7015 7.63181 14 8 14C8.36819 14 8.66667 13.7015 8.66667 13.3333L8.66667 6.66668Z'
            fill='currentColor'
          />
          <path
            d='M2.66667 10C3.03486 10 3.33333 10.2985 3.33333 10.6667V13.3333C3.33333 13.7015 3.03486 14 2.66667 14C2.29848 14 2 13.7015 2 13.3333L2 10.6667C2 10.2985 2.29848 10 2.66667 10Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
