import { PlaybookPageContent } from '../_common/playbook-page-content';
import { ServiceSection } from './sections/service-section';

export const ProductsAndServices = () => {
  return (
    <article className='flex flex-col gap-8 h-full flex-grow '>
      <PlaybookPageContent>
        <ServiceSection />
      </PlaybookPageContent>
    </article>
  );
};
