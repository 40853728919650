import { ObButton } from '../../elements/ob-button/ob-button';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import {
  ObSubItem,
  ObSubItemProps,
} from '../../molecules/ob-sub-item/ob-sub-item';

/**
 * The properties for the ObSubItemList component.
 */
export interface ObSubItemListProps {
  /**
   * An array of sub items to be displayed.
   */
  subItems: Array<ObSubItemProps>;

  /**
   * The header that will be shown when there are no sub items.
   */
  noSubItemsCallToActionHeader: string;

  /**
   * The description that will be shown when there are no sub items.
   */
  noSubItemsCallToActionDescription: string;

  /**
   * The label for the "Add Sub Item" button.
   */
  addSubItemButtonLabel: string;

  /**
   * An optional callback that will be called when the user clicks on the "Add Sub Item" button.
   * If not provided, the button will not be displayed.
   */
  onClickAddSubItemCallback?: () => void;

  onClickViewSubItemDetailsCallback?: (subItem: ObSubItemProps) => void;
}

/**
 * Renders the Sub Item List Component.
 * A UI for displaying a list of sub-items.
 * Original use case is for displaying sub-services or benefits of services.
 */
export const ObSubItemList: React.FC<ObSubItemListProps> = ({
  subItems,
  addSubItemButtonLabel,
  onClickAddSubItemCallback,
  noSubItemsCallToActionHeader,
  noSubItemsCallToActionDescription,
  onClickViewSubItemDetailsCallback,
}: ObSubItemListProps) => {
  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col gap-2'>
        {(subItems == null || subItems.length === 0) && (
          <div className='flex flex-col gap-3'>
            <ObTypography variant='h5'>
              {noSubItemsCallToActionHeader}
            </ObTypography>
            <ObTypography variant='body3'>
              {noSubItemsCallToActionDescription}
            </ObTypography>
          </div>
        )}
        {subItems != null &&
          subItems.length > 0 &&
          subItems.map((subItem) => (
            <ObSubItem
              {...subItem}
              onClickViewDetailsCallback={() =>
                onClickViewSubItemDetailsCallback?.(subItem)
              }
              key={subItem.id}
            />
          ))}
      </div>
      <div>
        {onClickAddSubItemCallback && (
          <ObButton
            size='large'
            variant='outline'
            fullWidth='always'
            onClick={onClickAddSubItemCallback}
          >
            {addSubItemButtonLabel}
          </ObButton>
        )}
      </div>
    </div>
  );
};
