import { Outlet } from 'react-router-dom';

import { NavItem } from '../../../../components/navigation/dashboard-route.type';
import { billingRoute } from './billing/routes';
import { generalSettingsRoute } from './general/routes';
import { integrationsPageRoute } from './integration/routes';
import { inviteSettingsRoute } from './invites/routes';
import { memberSettingsRoute } from './members/routes';
import { planRoute } from './plan/routes';

export const workspaceSettingsRoutes: NavItem = {
  title: 'Workspace',
  route: 'workspace',
  component: () => <Outlet />,
  showInNavMenu: ['workspace-menu-nav'],
  childRoutes: [
    generalSettingsRoute,
    memberSettingsRoute,
    inviteSettingsRoute,
    planRoute,
    billingRoute,
    integrationsPageRoute,
  ],
};
