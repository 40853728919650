import axios, { AxiosInstance } from 'axios';
import { useEffect } from 'react';
import { useAuth0Extended } from '../auth/use-auth0-extended';
import { OUTBOUND_API_BASE_URL } from '../environment';

export const useAuth0AxiosInstance = (): {
  axiosInstance: AxiosInstance;
  isAuthenticated: boolean;
  isAuthenticatedWithWorkspace: boolean;
  authenticatedWorkspaceId: string | undefined;
  authenticatedUserId: string | undefined;
  isAuth0Loading: boolean;
} => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isAuthenticatedWithWorkspace,
    authenticatedWorkspaceId,
    user,
    isLoading,
  } = useAuth0Extended();

  useEffect(() => {
    console.log('Authenticated Workspace ID Updated', authenticatedWorkspaceId);
  }, [authenticatedWorkspaceId]);

  const auth0Axios = axios.create({
    baseURL: OUTBOUND_API_BASE_URL,
  });

  auth0Axios.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    axiosInstance: auth0Axios,
    isAuthenticated,
    isAuthenticatedWithWorkspace,
    authenticatedWorkspaceId,
    authenticatedUserId: user?.sub,
    isAuth0Loading: isLoading,
  };
};
