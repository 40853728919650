import { ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import { CampaignCustomerProfile } from '../campaign-customer-profile/campaign-customer-profile';

export type SyntheticCampaignCustomerProfileResource = {
  customerProfileId: string;
  workspaceId: string;
};

class CampaignCustomerProfileTransformer extends BaseTransformer<
  SyntheticCampaignCustomerProfileResource | null,
  CampaignCustomerProfile | null
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: CampaignCustomerProfile,
    _resource: SyntheticCampaignCustomerProfileResource
  ): ServerDelta[] {
    return []; //Not Supported at the moment
  }

  public fromApiResource(
    resource: SyntheticCampaignCustomerProfileResource | null
  ): CampaignCustomerProfile | null {
    if (resource == null) {
      return null;
    }
    return new CampaignCustomerProfile(
      this._rootStore,
      resource.customerProfileId,
      resource.workspaceId
    );
  }
}

export default CampaignCustomerProfileTransformer;
