import { FC, ReactNode } from 'react';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';

export interface ObRadioOptionSubGroupProps {
  /**
   * The heading of the sub group
   */
  heading: string;
  /**
   * The ob-radio-option-items that belong to this sub group
   */
  children?: ReactNode;
}

/**
 * Groups a set of ob-radio-option-items together under a heading
 */
export const ObRadioOptionSubGroup: FC<ObRadioOptionSubGroupProps> = ({
  children,
  heading,
}) => {
  return (
    <div className='flex flex-col gap-2'>
      <ObTypography
        color='secondary'
        variant='subtitle1'
        className='text-left'
      >
        {heading}
      </ObTypography>
      <div className='flex flex-col gap-2 '>{children}</div>
    </div>
  );
};
