import { FC } from 'react';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface FileProps {
  name: string;
  size: string;
  state: 'uploading' | 'complete' | 'error';
  progress: number;
  errorMessage: string;
}

export interface ObUploadFileListProps {
  files: FileProps[];
  onRemoveItem: (item: FileProps) => void;
}

export const ObUploadFileList: FC<ObUploadFileListProps> = ({
  files,
  onRemoveItem,
}) => {
  return (
    <div>
      {files.map((value, index) => {
        return (
          <FileListItem
            key={index}
            name={value.name}
            size={value.size}
            state={value.state}
            progress={value.progress}
            errorMessage={value.errorMessage}
            onClickDelete={() => {
              onRemoveItem(value);
            }}
          />
        );
      })}
    </div>
  );
};

interface ExtendFileProps extends FileProps {
  onClickDelete: () => void;
}

const FileListItem: FC<ExtendFileProps> = ({
  name,
  size,
  state,
  progress,
  errorMessage,
  onClickDelete,
}) => {
  const classComplete = `${
    state === 'complete'
      ? 'dark:bg-contextualPositiveDark/[.12] dark:border-contextualPositiveDark'
      : ''
  }`;
  const classError = `${
    state === 'error'
      ? 'dark:bg-contextualNegativeDark/[.12] dark:border-contextualNegativeDark'
      : ''
  }`;

  return (
    <div
      data-testid='file-list-item'
      className={`w-full border rounded border-solid my-2 px-3 py-2 ${classComplete} ${classError}`}
    >
      <div className='flex flex-wrap'>
        <div className='flex items-center'>
          <ObIcon
            icon={'document'}
            size={'small'}
            classNames='dark:text-contentSecondaryDark'
          />
          <ObTypography
            variant='body3'
            className='dark:text-contentPrimaryDark mx-1.5'
          >
            {name}
          </ObTypography>
          <ObTypography
            variant='body3'
            className='dark:text-contentSecondaryDark'
          >
            {size}
          </ObTypography>
        </div>
        <div className='flex flex-row flex-1 justify-end items-center'>
          {state === 'uploading' && (
            <div className='flex flex-row'>
              <ObTypography
                variant='body3'
                className='dark:text-contentPrimaryDark'
              >
                Uploading
              </ObTypography>
              <div className='w-28 dark:bg-actionPrimaryDark/50 rounded-full h-1.5 self-center mx-4 my-0'>
                <div
                  className='dark:bg-actionPrimaryDark h-1.5 rounded-full'
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          )}
          {state === 'complete' && (
            <div className='flex items-center'>
              {' '}
              <ObIcon
                icon={'complete'}
                size={'x-small'}
                classNames='dark:text-contextualPositiveDark mr-2'
              />{' '}
              <ObTypography
                variant='body3'
                className='dark:text-contextualPositiveDark mr-4'
              >
                Upload complete
              </ObTypography>
            </div>
          )}

          <div
            data-testid='delete-button'
            className='hover:cursor-pointer'
            onClick={() => {
              onClickDelete?.();
            }}
          >
            <ObIcon
              icon={'delete'}
              size={'medium'}
              classNames='dark:text-contentSecondaryDark'
            />
          </div>
        </div>
      </div>
      {state === 'error' && (
        <div className='flex flex-row mt-2'>
          <ObIcon
            icon={'problem'}
            size={'medium'}
            classNames='dark:text-contextualNegativeDark'
          />{' '}
          <ObTypography className='dark:text-contextualNegativeDark ml-2'>
            {errorMessage}
          </ObTypography>
        </div>
      )}
    </div>
  );
};
