import { FC, ReactNode } from 'react';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';

export interface ObReadOnlyTextProps {
  /**
   * Label of the text to be displayed
   */
  label: string;
  /**
   * Span of text to be displayed
   */
  children: ReactNode;
}

export const ObReadOnlyText: FC<ObReadOnlyTextProps> = ({
  label,
  children,
}: ObReadOnlyTextProps) => {
  const labelId = `${label?.replaceAll(' ', '-')}-id`;
  return (
    <div className='flex flex-col'>
      <label id={labelId}>
        <ObTypography
          color={'secondary'}
          as='span'
          variant='subtitle2'
        >
          {label}
        </ObTypography>
      </label>
      <div
        aria-readonly='true'
        aria-labelledby={labelId}
      >
        <ObTypography
          variant='body1'
          as='span'
          color='tertiary'
        >
          {children}
        </ObTypography>
      </div>
    </div>
  );
};
