import { ObrnDomain, ObrnObject, ObrnObjectValues } from '@outbound/types';

export interface ReadOnlyObrnAttributes {
  /**
   * A hard-coded prefix of "obrn" short for "Outbound Resource Name"
   */
  prefix: 'obrn';
  /**
   * Future Use (We can adjust this later for lower environments if we want. For now we will hard-code ob)
   * Thought here is provide a way to visually distinguish between different environments in the future
   * with just the id. For now we will hard-code this to "ob" for "Outbound". If this is never used that is okay
   * just wanted to include it for future use.
   */
  environment: 'ob';
}

export interface ObrnAttributesArguments {
  /**
   * The workspace in which the resource exists. Use scope instead
   * @deprecated
   */
  workspaceId?: string;

  /**
   * The scope of the resource. system or the workspaceId
   */
  scope?: 'system' | string;
  /**
   * The domain or service of the resource
   * Future Use, We can centralize this based on the object type
   */
  // domain: ObrnDomain;
  /**
   * The type of the resource
   */
  objectType: ObrnObject;
  /**
   * The local identifier of the resource
   */
  localPathId: string;
}

export interface ObrnAttributes
  extends ReadOnlyObrnAttributes,
    ObrnAttributesArguments {}

export const lookupDomainForObject = (object: ObrnObject): ObrnDomain => {
  switch (object) {
    case 'campaign':
    case 'campaign/highlight':
    case 'campaign/location':
    case 'campaign/deployment':
    case 'campaign/campaign-customer-profile':
      return 'campaign';
    case 'creative':
    case 'creative/setting':
    case 'creative-template/setting-definition':
    case 'creative-template/setting-section':
    case 'creative-template/setting-section/sub-section':
    case 'creative-template':
      return 'creative';
    case 'integration-configuration':
    case 'integration-configuration/health-check-item-execution':
    case 'integration/health-check-item':
    case 'integration/health-check-item/outcome':
      return 'workspace';
    default:
      return 'playbook'; //FIX THIS
  }
};

/**
 * Converts a set of attributes to an outbound unique identifier
 * @param attributes
 */
export const toObrn = ({
  workspaceId,
  scope,
  objectType,
  localPathId,
}: ObrnAttributesArguments): string => {
  return `obrn:ob:${scope ?? workspaceId}:${objectType}:${localPathId}`;
};

/**
 * Accepts a obrn and returns the attributes parsed from the individual segments
 * @param obrn
 * @returns
 */
export const parseObrn = (obrn: string): Required<ObrnAttributes> => {
  /**
   * Parse the first 5 segments into individual attributes
   * Everything after the 5th segment is considered part of the localId
   */
  const [prefix, environment, scope, objectType, localPathId] = obrn.split(
    ':',
    5
  );
  if (prefix !== 'obrn') {
    throw new Error('Invalid UID prefix');
  }

  if (!ObrnObjectValues.includes(objectType as ObrnObject)) {
    throw new Error(`Invalid object type: ${objectType}`);
  }

  const objectTypeValue: ObrnObject = objectType as ObrnObject;

  return {
    prefix,
    environment: environment as 'ob',
    workspaceId: scope,
    scope: scope,
    objectType: objectTypeValue,
    localPathId,
  };
};
