import { DisplayAdAnimated0001 } from './template-library/display-ad/dynamic/display-ad-animated-0001/display-ad-animated-0001';
import { DisplayAdAnimated2 } from './template-library/display-ad/dynamic/display-ad-animated-2/display-ad-animated-2';
import { GoogleDisplayAdStatic } from './template-library/display-ad/static/google-display-ad-static/google-display-ad-static';
import { GoogleSearch01 } from './template-library/google-search/google-search/google-search-1';
import { AnimatedTemplateProps } from './template-library/template-component-props.type';

// Define a mapping from string to component
export const templateComponentMapping: {
  [key: string]: React.FC<AnimatedTemplateProps>;
} = {
  GoogleSearch01: GoogleSearch01,
  GoogleDisplayAdStatic: GoogleDisplayAdStatic,
  //@ts-ignore
  DisplayAdAnimated0001: DisplayAdAnimated0001,
  DisplayAdAnimated2: DisplayAdAnimated2,
};
