import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import Membership from './membership';

class MembershipStore extends BaseStore<Membership> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'workspace/membership');
  }
  protected requestLoadModelFromServer(_id: string): void {
    throw new Error('Method not implemented.');
  }
}

export default MembershipStore;
