import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import { AccountProfile } from './account-profile';

export const profileSettingsRoute: NavItem = {
  index: true,
  title: 'Profile',
  route: 'profile',
  component: () => <AccountProfile />,
  showInNavMenu: ['my-account-settings-nav'],
};
