'use client';
import React, { useState } from 'react';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObBrand } from '../../elements/ob-brand/ob-brand';
import { ObLink, ObLinkProps } from '../../elements/ob-link/ob-link';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import { ObFormRenderer } from '../../organisms/ob-form-renderer/ob-form-renderer';
import { FormDefinition } from '../../organisms/ob-form-renderer/ob-form-renderer.types';

export interface FullPageActionProps {
  heading: string;
  subHeading?: string;
  form: FormDefinition;
  onSubmitCallback: (data: any) => Promise<any>;
  successHeading: string;
  successSubHeading?: string;
  errorHeading: string;
  errorSubHeading?: string;
  footerLinks: Array<ObLinkProps>;
}

export type FullPageActionState = 'ready' | 'success' | 'error';

export const FullPageActionHeader: React.FC<{
  heading: string;
  subHeading?: string;
}> = ({ heading, subHeading }: { heading: string; subHeading?: string }) => {
  return (
    <div className=''>
      {heading && (
        <ObTypography
          className='text-center'
          variant='h2'
        >
          {heading}
        </ObTypography>
      )}
      {subHeading && (
        <ObTypography
          className='text-center pt-4'
          variant='body1'
          color='secondary'
        >
          {subHeading}
        </ObTypography>
      )}
    </div>
  );
};

export const FullPageAction: React.FC<FullPageActionProps> = ({
  heading,
  subHeading,
  form,
  onSubmitCallback,
  footerLinks,
  successHeading,
  successSubHeading,
  errorHeading,
  errorSubHeading,
}) => {
  const [pageState, setPageState] = useState<FullPageActionState>('ready');

  const handleFormSubmit = async (data: any) => {
    return onSubmitCallback(data).then(
      () => {
        setPageState('success');
      },
      () => {
        setPageState('error');
      }
    );
  };

  return (
    <div className='flex flex-col h-screen justify-between dark:bg-black'>
      <header className='flex justify-center items-center pt-8'>
        <ObBrand
          variant='icon'
          size='medium'
        />
      </header>

      <main className='flex flex-col justify-center items-center flex-grow w-full'>
        {pageState === 'ready' && (
          <>
            <FullPageActionHeader
              heading={heading}
              subHeading={subHeading}
            />
            {form && (
              <ObFormRenderer
                submitButtonLabel='Continue'
                submitButtonFullWidth={'always'}
                submitButtonSize={'xlarge'}
                className='w-full max-w-[320px]'
                formDefinition={form}
                defaultValues={null}
                onSubmitCallback={handleFormSubmit}
              />
            )}
          </>
        )}
        {pageState === 'success' && (
          <>
            <ObIcon
              icon='complete'
              size='x-large'
            />
            <FullPageActionHeader
              heading={successHeading}
              subHeading={successSubHeading}
            />
          </>
        )}
        {pageState === 'error' && (
          <>
            <div className='mb-2 rounded-full dark:bg-contentNegativeDark dark:bg-opacity-20 bg-contentNegativeLight bg-opacity-20 flex h-[44px] w-[44px]'>
              <div className='m-auto'>
                <ObIcon
                  icon='close'
                  size='large'
                  color='negative'
                />
              </div>
            </div>
            <FullPageActionHeader
              heading={errorHeading}
              subHeading={errorSubHeading}
            />
          </>
        )}
      </main>

      <footer className='flex justify-center items-center h-12'>
        {footerLinks.map((link, index) => (
          <React.Fragment key={link.label}>
            <ObLink {...link} />
            {index < footerLinks.length - 1 && (
              <span className='px-4 dark:text-contentTertiaryDark text-contentTertiaryLight'>
                |
              </span>
            )}
          </React.Fragment>
        ))}
      </footer>
    </div>
  );
};

export default FullPageAction;
