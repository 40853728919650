import { CampaignDetail, CampaignDetailProps } from '../../business-objects';
import { ObDivider } from '../../components/elements/ob-divider/ob-divider';
import { BudgetSection, BudgetSectionProps } from './sections/budget';
import {
  CampaignHighlightSection,
  CampaignHighlightSectionProps,
} from './sections/campaign-highlights';
import {
  CustomerProfileSection,
  CustomerProfileSectionProps,
} from './sections/customer-profile';
import { LeadsSection } from './sections/leads';
import { LocationSection, LocationSectionProps } from './sections/location';

export interface CampaignDetailPageProps {
  campaignCardDetailHeader: CampaignDetailProps;
  campaignHighlightsSection: CampaignHighlightSectionProps;
  campaignLocationsSection: LocationSectionProps;
  customerProfileSection: CustomerProfileSectionProps;
  budgetSectionProps: BudgetSectionProps;
  children?: React.ReactNode;
}

export const CampaignDetailLandingPage = ({
  campaignCardDetailHeader,
  campaignHighlightsSection,
  campaignLocationsSection,
  customerProfileSection,
  budgetSectionProps,
  children,
}: CampaignDetailPageProps) => {
  return (
    <div>
      <div className='max-w-screen-lg m-auto gap-[96px] flex flex-col p-6 '>
        <CampaignDetail {...campaignCardDetailHeader} />
        {campaignCardDetailHeader.goal === 'LEAD_GEN_FOR_SERVICE' && (
          <LeadsSection
            isAttributionAvailableForCampaign={false}
            currentWorkspacePlan={'free'}
          />
        )}
        <ObDivider />
        <CampaignHighlightSection {...campaignHighlightsSection} />
        <ObDivider />
        <LocationSection {...campaignLocationsSection} />
        <ObDivider />
        <CustomerProfileSection {...customerProfileSection} />
        <ObDivider />
        <BudgetSection {...budgetSectionProps} />
        {children}
      </div>
    </div>
  );
};
