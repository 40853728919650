import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';

export const MarketingPlanCard = ({
  children,
  gap = 'gap-3',
  className,
}: {
  children: ReactNode;
  gap?: 'gap-3' | 'gap-6';
  className?: string;
}) => {
  return (
    <section
      className={cx(
        'rounded-lg dark:bg-bgSurfaceDark p-5 flex flex-col w-full',
        gap,
        className
      )}
    >
      {children}
    </section>
  );
};
