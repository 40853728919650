import { ObInputCaption, ObInputLabel } from '@outbound/design-system';
import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';
import { RemixFieldInlineControls } from '../../controls/remix-field-inline-controls/remix-field-inline-controls';

export interface TemplateSettingBaseInputFieldProps {
  id: string;
  label: string;
  /**
   * Enables the Remix Feature
   */
  FF_enableRemixFeature?: boolean;
  /**
   * Indicates that this field should not be remixed
   */
  isLockedForRemixValue: boolean;
  onClickIsLockedForRemixCallback: (fieldId: string) => void;
  /**
   * Callback to be called wen the field is to be remixed
   */
  onClickRemixFieldButtonCallback: (fieldId: string) => void;
  /**
   * The input to display for the field
   */
  children: ReactNode;
  /**
   * Indicates that the creative field should be disabled
   */
  isDisabled: boolean;

  /**
   * Indicates that the field is required
   */
  isRequired: boolean;

  /**
   * Errors that will prevent publishing for this creative
   */
  publishingValidationErrors: string[];
}
export const TemplateSettingBaseInputField = ({
  id,
  label,
  children,
  isLockedForRemixValue,
  onClickIsLockedForRemixCallback,
  onClickRemixFieldButtonCallback,
  isDisabled,
  isRequired,
  publishingValidationErrors,
  FF_enableRemixFeature = false,
}: TemplateSettingBaseInputFieldProps) => {
  return (
    <div data-ob-component='template-setting-base-input-field'>
      <ObInputLabel
        isRequired={isRequired}
        label={label}
        id={id}
        className='mb-1'
      />
      <div className={cx('flex flow-row items-center justify-between')}>
        {children}
        {FF_enableRemixFeature && (
          <RemixFieldInlineControls
            variant={'icon'}
            isLocked={isLockedForRemixValue}
            onClickLockRemixCallback={() => onClickIsLockedForRemixCallback(id)}
            isRemixLockButtonDisabled={isDisabled} //Disable the Lock Button when the field is disabled
            isRemixButtonDisabled={isLockedForRemixValue || isDisabled} //Disable the Remix Button when the field is locked
            onClickRemixCallback={() => onClickRemixFieldButtonCallback(id)}
          />
        )}
      </div>
      <div
        className={cx(
          'pt-1',
          'opacity-0 transition-opacity ease-in-out duration-200 min-h-[20px]',
          {
            'opacity-100': publishingValidationErrors?.length > 0,
          }
        )}
      >
        <ObInputCaption
          type={'error'}
          hideIcon={true}
          message={publishingValidationErrors?.[0] ?? ''} //We assume that validation errors are ordered by priority
        />
      </div>
    </div>
  );
};
