import {
  ObFormRenderer,
  useDrawerService,
  useObFormRendererContext,
} from '@outbound/design-system';
import { useCallback } from 'react';
import { useCreatePersona } from '../../../../../../query/playbook/use-playbook-settings-persona-endpoints';
import { PlaybookDrawerContent } from '../../_common/playbook-drawer-content';
import { mapFormValuesToApiResource } from '../forms/customer-persona-form-utils';
import { CUSTOMER_PERSONA_FORM_DEF } from '../forms/customer-persona-form.def';

export const CustomerPersonaCreateDrawer = () => {
  const { getRegisteredFormById } = useObFormRendererContext();
  const drawerService = useDrawerService();

  const { mutateAsync: createPersona } = useCreatePersona();

  /**
   * Maps the Form Values to the API Resource and calls the createPhysicalLocation mutation
   */
  const handleCreatePersona = useCallback(
    async (values: any) => {
      const result = createPersona({
        values: mapFormValuesToApiResource(values),
      });
      result.then(() => {
        drawerService.popDrawer();
      });
      return result;
    },
    [createPersona, drawerService]
  );

  return (
    <PlaybookDrawerContent
      primaryActionLabel={'Create'}
      primaryActionCallback={async () => {
        return getRegisteredFormById(
          CUSTOMER_PERSONA_FORM_DEF.id
        )?.submitFunction();
      }}
      secondaryActionLabel={'Cancel'}
      secondaryActionCallback={async () => {
        return getRegisteredFormById(
          CUSTOMER_PERSONA_FORM_DEF.id
        )?.discardChangesFunction();
      }}
    >
      <ObFormRenderer
        formDefinition={CUSTOMER_PERSONA_FORM_DEF}
        hideSubmitButton={true}
        onSubmitCallback={handleCreatePersona}
      />
    </PlaybookDrawerContent>
  );
};

export const useCustomerPersonaCreateDrawer = () => {
  const drawerService = useDrawerService();

  const openNewCustomerPersonaDrawer = () => {
    drawerService.pushDrawer({
      title: 'Add New Customer Profile',
      description:
        'Add and complete rules below to create a new customer profile.',
      drawerContent: <CustomerPersonaCreateDrawer />,
      size: 'medium',
    });
  };
  return { openNewCustomerPersonaDrawer };
};
