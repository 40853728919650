import { CampaignGoal, OutboundCampaignGoal } from '@outbound/types';
import { ObIcon, ObTypography } from '../../../..';
import { IconSystemKeys } from '../../../../tokens/icons/icons';

export interface ObGoalAvatarProps {
  goal: CampaignGoal | OutboundCampaignGoal;
  size?: 'small' | 'medium';
}

const getDetailsForGoal = (
  goal: CampaignGoal | OutboundCampaignGoal
): { label: string; icon: IconSystemKeys } => {
  switch (goal) {
    case 'BRAND_AWARENESS':
    case CampaignGoal.AWARENESS:
      return {
        icon: 'goalBrandAwareness',
        label: 'Brand Awareness',
      };
    case 'LEAD_GEN_FOR_SERVICE':
    case CampaignGoal.LEAD_GEN:
      return {
        icon: 'goalLeadGeneration',
        label: 'Lead Generation',
      };
    case CampaignGoal.PURCHASE:
      return {
        icon: 'goalPurchases',
        label: 'Purchases',
      };
  }
};

export const ObGoalAvatar = ({ goal, size = 'small' }: ObGoalAvatarProps) => {
  if (!goal) return <></>;

  const { icon, label } = getDetailsForGoal(goal);

  return (
    <div className='flex flex-row gap-1 items-center  justify-start'>
      <ObIcon
        icon={icon}
        size={size === 'small' ? 'xx-small' : 'x-small'}
        color='secondary'
      />
      <ObTypography
        variant={size === 'small' ? 'body3' : 'body2'}
        color='secondary'
        className='text-left'
      >
        {label}
      </ObTypography>
    </div>
  );
};
