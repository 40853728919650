import { cva } from 'class-variance-authority';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { stringHasContent } from '../../../utilities/string-utils';
import { ObActionMenu } from '../../elements/ob-action-menu/ob-action-menu';
import {
  ObActionMenuItem,
  ObActionMenuItemProps,
} from '../../elements/ob-action-menu/ob-action-menu-item';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObThumbnail } from '../../elements/ob-thumbnail/ob-thumbnail';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

/**
 * Props for the ObPhotoTile component that explicitly define the value of the tile.
 * These properties are broken out so it is easier for consumers to load / store state
 * for this component.
 */
export interface ObPhotoTileStateProps {
  /**
   * The unique identifier for the asset being displayed.
   */
  assetId: string;
  /**
   * The public URL of the asset being displayed.
   */
  assetUrl: string;
  /**
   * The alt text for the asset being displayed.
   */
  assetAltText: string;
  /**
   * Indicates that the photo title is the key photo.
   */
  isKeyPhoto: boolean;
}

/**
 * The properties for the ObPhotoTile component.
 *
 * This component is a more hard-coded version of the ObPhotoTile component if we have needs in the future
 * for this component to be used in a more flexible way we can refactor it.
 *
 * Example the isKeyPhoto prop is pretty specific to the current (only) use case of this component but may be
 * made more generic in the future if the need arises.
 */
export interface ObPhotoTileProps extends ObPhotoTileStateProps {
  /**
   * Callback for when the key photo button is clicked.
   */
  onKeyPhotoButtonClicked: () => void;

  /**
   * Actions to be displayed on the asset in the settings menu.
   */
  actions: Array<ObActionMenuItemProps>;
}

const photoTileContainerStyles = cva(
  'border border-dark/border/default/normal rounded-lg overflow-clip flex flex-col min-h-[188px]'
);

export const ObPhotoTile: React.FC<ObPhotoTileProps> = ({
  assetId,
  assetUrl,
  assetAltText,
  onKeyPhotoButtonClicked,
  isKeyPhoto,
  actions = [],
}: ObPhotoTileProps) => {
  return (
    <article
      className={photoTileContainerStyles()}
      data-asset-id={assetId}
    >
      <ObThumbnail
        preferredBackground={'light'}
        publicUrl={assetUrl}
        altText={assetAltText}
        roundedOnBottom={false}
        includeBorder={false}
        size='responsive'
      />
      <div className=' flex-1 py-2 px-3 border-t min-h-[65px] dark:border-dark/border/default/normal border-light/border/default/normal'>
        <ObTypography
          variant='body3'
          color='secondary'
          className='line-clamp-3'
        >
          {stringHasContent(assetAltText)
            ? assetAltText
            : 'Alt Text Not Provided'}
        </ObTypography>
      </div>
      <div className='flex flex-row items-center justify-between  border-t border-dark/border/default/normal flex-1'>
        <div className='ml-1 py-2 flex flex-row items-center flex-1 '>
          {!isKeyPhoto && (
            <ObButton
              variant='ghost'
              onClick={onKeyPhotoButtonClicked}
              label='Set Key Photo'
            />
          )}
          {isKeyPhoto && (
            <div className=' flex flex-row items-center gap-2 px-2'>
              <div className='text-dark/non-contextual/yellow'>
                <ObIcon
                  color='inherit'
                  size='small'
                  icon='star01'
                />
              </div>
              <ObTypography
                variant='body3'
                className='text-nowrap text-ellipsis'
              >
                Key Photo
              </ObTypography>
            </div>
          )}
        </div>
        <div className=' py-2 flex justify-center items-center px-2 border-l border-dark/border/default/normal h-full'>
          <ObActionMenu
            menuOpenButton={
              <ObButton
                buttonType='icon'
                variant='ghost'
                iconLeft='settings01'
                size='small'
                label='More Photo Actions'
              />
            }
          >
            {actions.map((action) => (
              <ObActionMenuItem
                key={action.ariaLabel}
                {...action}
              />
            ))}
          </ObActionMenu>
        </div>
      </div>
    </article>
  );
};
