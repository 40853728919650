export const CheckVerified03 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_5240_6026)'>
        <path
          d='M7.50008 9.99984L9.16675 11.6665L12.9167 7.9165M7.66833 17.1672C7.94146 17.1311 8.21736 17.2052 8.43493 17.3728L9.43763 18.1422C9.76908 18.3968 10.2302 18.3968 10.5607 18.1422L11.6013 17.3432C11.7958 17.1941 12.0411 17.1283 12.2837 17.1608L13.5854 17.332C13.9993 17.3867 14.3983 17.1561 14.5585 16.77L15.0594 15.5589C15.1529 15.3321 15.3325 15.1525 15.5593 15.059L16.7704 14.558C17.1564 14.3988 17.387 13.9988 17.3323 13.5849L17.1675 12.3303C17.1314 12.0572 17.2055 11.7813 17.3731 11.5637L18.1425 10.5609C18.3971 10.2295 18.3971 9.76836 18.1425 9.43782L17.3435 8.3971C17.1944 8.20267 17.1287 7.9573 17.1611 7.71472L17.3323 6.4129C17.387 5.99902 17.1564 5.59996 16.7704 5.43978L15.5593 4.93887C15.3325 4.84535 15.1529 4.66573 15.0594 4.43888L14.5585 3.2278C14.3993 2.8417 13.9993 2.61115 13.5854 2.66578L12.2837 2.83707C12.0411 2.87041 11.7958 2.80467 11.6023 2.65652L10.5616 1.85747C10.2302 1.60285 9.76908 1.60285 9.43855 1.85747L8.3979 2.65652C8.20347 2.80467 7.95812 2.87041 7.71555 2.83892L6.41381 2.66763C5.99995 2.61301 5.60091 2.84355 5.44074 3.22966L4.94078 4.44073C4.84634 4.66665 4.66673 4.84628 4.44082 4.94072L3.22981 5.44071C2.84373 5.60089 2.6132 5.99995 2.66782 6.41383L2.8391 7.71564C2.87058 7.95823 2.80485 8.20359 2.65671 8.3971L1.8577 9.43782C1.6031 9.76929 1.6031 10.2304 1.8577 10.5609L2.65671 11.6016C2.80577 11.7961 2.87151 12.0414 2.8391 12.284L2.66782 13.5859C2.6132 13.9997 2.84373 14.3988 3.22981 14.559L4.44082 15.0599C4.66766 15.1534 4.84727 15.333 4.94078 15.5599L5.44167 16.7709C5.60091 17.157 6.00088 17.3876 6.41473 17.333L7.66833 17.1672Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5240_6026'>
          <rect
            width='20'
            height='20'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
