import { KeyboardEvent, useEffect, useRef } from 'react';
import { EditableInput } from 'react-color/lib/components/common';
import { isValidHex } from 'react-color/lib/helpers/color';
import type { Color, OnColorChangeProps } from './custom-color-picker.types';
import { useKeyboardNavigation } from './hooks/use-keyboard-navigation';

const inputStyles: any = {
  wrap: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  input: {
    width: '64px',
    padding: '12px 8px',
    fontSize: '14px',
    background: '#1B1D2C',
    borderRadius: '4px',
    border: '1px solid rgba(255, 255, 255, 0.16)',
  },
  label: {
    color: '#ECEDEE',
    display: 'block',
    textAlign: 'start',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 800,
    paddingLeft: '4px',
    paddingBottom: '2px',
    textTransform: 'capitalize',
  },
};
interface CustomColorPickerInputsprops {
  rgb: Color;
  hex: string;
  hasOptionalColors: boolean;
  onChange: OnColorChangeProps;
}

export const CustomColorPickerInputs = ({
  rgb,
  hex,
  hasOptionalColors,
  onChange,
}: CustomColorPickerInputsprops) => {
  const hexValueInputRef = useRef<any>();
  const { focusFirstPickerInput } = useKeyboardNavigation();

  const handleChange = (data: Color, e: any) => {
    if (data.hex) {
      // Validate color fisrt
      const isAValidHex = isValidHex(data.hex);
      if (isAValidHex) {
        onChange({ hex: data.hex, source: 'hex' }, e);
      }
    } else if (data.r ?? data.g ?? data.b) {
      onChange(
        {
          r: data.r ?? rgb.r,
          g: data.g ?? rgb.g,
          b: data.b ?? rgb.b,
          a: rgb.a,
          source: 'rgb',
        },
        e
      );
    }
  };

  useEffect(() => {
    const hexValueInput = hexValueInputRef?.current;
    hexValueInput.input.focus({ preventScroll: true });
  }, []);

  return (
    <div
      style={{ display: 'flex', paddingTop: '4px' }}
      className='flexbox-fix text-contentPrimaryLight dark:text-contentPrimaryDark'
    >
      <div style={{ flex: '1' }}>
        <EditableInput
          ref={hexValueInputRef}
          style={{
            ...inputStyles,
            input: { ...inputStyles.input, width: '104px' },
          }}
          label='hex'
          value={hex}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: '1', paddingLeft: '6px' }}>
        <EditableInput
          style={{ ...inputStyles }}
          label='r'
          value={rgb.r}
          onChange={handleChange}
          dragLabel='true'
          dragMax='255'
        />
      </div>
      <div style={{ flex: '1', paddingLeft: '6px' }}>
        <EditableInput
          style={{ ...inputStyles }}
          label='g'
          value={rgb.g}
          onChange={handleChange}
          dragLabel='true'
          dragMax='255'
        />
      </div>
      <div
        style={{ flex: '1', paddingLeft: '6px' }}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) =>
          !hasOptionalColors && focusFirstPickerInput(event)
        }
      >
        <EditableInput
          style={{ ...inputStyles }}
          label='b'
          value={rgb.b}
          onChange={handleChange}
          dragLabel='true'
          dragMax='255'
        />
      </div>
    </div>
  );
};
