export const useBrowserSupport = () => {
  /**
   * Uses a codec and mimeType to determine if the browser supports
   * playback of videos of the file type that have been encoded with the codec.
   *
   * We are primarily using this to check for support of codecs that include support an alpha channel.
   * (Only some codecs include support for alpha channels (alpha channel is where transparency is stored))
   *
   * @param videoCodec
   * @param mimeType
   * @returns
   */
  const isVideoCodecSupportedByBrowser = (
    videoCodec: string,
    mimeType: string
  ): boolean => {
    if (typeof MediaSource !== 'undefined') {
      const codecString = `${mimeType}; codecs="${videoCodec}"`;
      return MediaSource.isTypeSupported(codecString);
    } else {
      console.warn('MediaSource is not supported in this browser');
      return false;
    }
  };

  return {
    isVideoPlayableWithAlpha: isVideoCodecSupportedByBrowser,
  };
};
