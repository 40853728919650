import { BrandMarketingGoalsType } from './BrandMarketingGoals.type';

interface Option {
  value: string;
  title: string;
  description: string;
}

export const marketingGoalSelectorOptions: Option[] = [
  {
    value: BrandMarketingGoalsType.MAKE_ME_A_RECOMMENDATION,
    title: 'Make Me A Recommendation',
    description:
      'I want to advertise but not sure where to start. Make me a reccomendation based on my business.',
  },
  {
    value: BrandMarketingGoalsType.GENERATE_NEW_LEADS,
    title: 'Generate New Leads',
    description:
      'Aim to gather information from potential customers interested in your products or services Outbound Choice for Lawn Care Maintenance',
  },
  {
    value: BrandMarketingGoalsType.INCREASE_BRAND_AWARENESS,
    title: 'Increase Brand Awareness',
    description:
      "Enhance your business's visibility and recognition. A well-known brand can attract more customers, even if they're just passing by.",
  },
  {
    value: BrandMarketingGoalsType.DRIVE_E_COMMERCE_SALES,
    title: 'Drive E-Commerce Sales',
    description:
      'Directly drive customers to purchase when the path to purchase is shorter and the customer is often ready to buy.',
  },
];
