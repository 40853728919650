import { ObDivider, ObBrand, ObIcon } from '@outbound/design-system';
import { cx } from 'class-variance-authority';
import { DrawerExpansionProps } from '../types';

export const ToggleExpandButtonDesktop = ({
  isDrawerExpanded,
  toggleDrawerExpansion,
}: DrawerExpansionProps) => {
  return (
    <div>
      <ObDivider className='mt-4' />
      <button
        type='button'
        className='w-full'
        onClick={toggleDrawerExpansion}
      >
        <div
          className={cx(
            isDrawerExpanded ? 'h-[48px]' : 'h-[84px]',
            'block transition-all duration-300 ease-in-out relative'
          )}
        >
          <div
            className={cx(
              'transition-all duration-300 ease-in-out block mx-3 p-2 absolute top-1'
            )}
          >
            <ObBrand
              variant='icon'
              size='small'
              color='full-color'
            />
          </div>
          <div className='transition-all duration-300 ease-in-out block transform absolute mx-3 p-2 bottom-1 right-[1px]'>
            <ObIcon
              classNames={cx(
                'transition-all duration-200 ease-in-out',
                isDrawerExpanded ? '' : 'rotate-180'
              )}
              size='small'
              icon='doubleChevronLeft'
            />
          </div>
        </div>
      </button>
    </div>
  );
};
