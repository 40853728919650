import { FormDefinition } from '@outbound/design-system';

export const QUESTION_LEVEL_DELIMITER = '#';

export interface OnboardingQuestion {
  id: string;
  fullPath?: string; //Set internally by the question manager
  label: string;
  helperText: string;
  /**
   * Alternative Label to use if there is not a currently populated value
   */
  noValueLabel?: string;
  /**
   * Alternative Helper text to use if there is not a currently populated value
   */
  noValueHelperText?: string;
  useFormRenderer: boolean;
  playBookPageId?: string;
  formDefinition?: FormDefinition;
  subQuestions?: Array<OnboardingQuestion>;
  isQuestionInScope?: (answers: any) => boolean;
  /**
   * Optional: Function that returns state from a server that is will be used
   * to populate the answer to the question.
   * @param params
   * @returns
   */
  fetchServerState?: (...args: any) => Promise<any>;
  submitServerState?: (...args: any) => Promise<any>;
}
