import { ObProgressHeader, ObTypography } from '@outbound/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BrandColorSelectorPage } from './brand-color-selector/brand-color-selector';
import { GoalSelector } from './goal-selector/goal-selector';
import { WebsiteAssetResults } from './website-asset-results/website-asset-results';
import { WebsiteScannerStep } from './website-scanner-step/website-scanner-step';

import { useAuth0 } from '@auth0/auth0-react';
import {
  AssetScanResource,
  AssetsCategorized,
  ColorScanResource,
  CreateSelectedColorsRequestResource,
  ScreenshotResource,
  SelectedAssetResource,
  SelectedColorResource,
  SiteScanResource,
} from '@outbound/types';
import { BehaviorSubject, Subscription, zip } from 'rxjs';
import {
  useFetchBrandOnboardingById,
  useSetAssetsMutation,
  useSetColorsMutation,
  useSetWorkspaceDetailsMutation,
} from '../../query/onboarding/use-onboarding-endpoints';
import { BaseJwtToken, getJwtTokenPayload } from '../../utilties/jwt-utilities';
import { WorkspaceDetailsCapture } from './workspace-details-capture/workspace-details-capture';
import { WorkspaceRedirect } from './workspace-redirect/workspace-redirect';

const COLOR_STEP = 'color-selection';
const ASSET_STEP = 'asset-management';
const SITE_SCAN_STEP = 'site-scan';
const GOAL_SELECTION_STEP = 'goal-selection';
const WORKSPACE_DETAILS_CAPTURE_STEP = 'workspace-creation';
const WORKSPACE_CHECK_EMAIL = 'workspace-check-email';

const userSteps = [
  {
    title: GOAL_SELECTION_STEP,
    type: 'GOAL_SELECTION',
  },
  {
    title: SITE_SCAN_STEP,
    type: 'SITE_SCAN',
  },
  {
    title: COLOR_STEP,
    type: 'COLOR_SELECTION',
  },
  {
    title: ASSET_STEP,
    type: 'ASSET_SELECTION',
  },
  {
    title: WORKSPACE_DETAILS_CAPTURE_STEP,
    type: 'WORKSPACE_CREATION',
  },
  {
    title: WORKSPACE_CHECK_EMAIL,
    type: 'WORKSPACE_CHECK_EMAIL',
  },
];

export const BrandOnboardingWizardPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAuthenticated } = useAuth0();
  const flags = useFlags();
  const isUrlBoxScreenshotEnabled = useMemo(
    () => flags.urlBoxScreenshot,
    [flags.urlBoxScreenshot]
  );

  const [rawToken] = useState<string | null>(searchParams.get('token'));

  const updateSearchPrams = useCallback(
    (stepIndex: number, rawToken: string) => {
      setSearchParams(
        { step: userSteps[stepIndex].title, token: rawToken ?? '' },
        { replace: false }
      );
    },
    [setSearchParams]
  );

  /**
   * Temporary function to move to the next step.
   * Real logic will need to take into account what has been completed etc.
   */
  const goToNextStep = useCallback(() => {
    const currentStep = searchParams.get('step');
    const currentIndex = userSteps.findIndex(
      (step) => step.title === currentStep
    );
    if (currentIndex < userSteps.length - 1) {
      updateSearchPrams(currentIndex + 1, rawToken ?? '');
    }
  }, [rawToken, searchParams, updateSearchPrams]);

  useEffect(() => {
    let selectTimeout: NodeJS.Timeout;
    if (searchParams.get('step') === GOAL_SELECTION_STEP) {
      selectTimeout = setTimeout(
        () => goToNextStep(),
        15000 // 15 seconds delay before
      );
    }
    return () => clearTimeout(selectTimeout);
  }, [goToNextStep, rawToken, searchParams]);

  const getBrandOnboardingIdFromToken = (token: string | null) => {
    if (token == null) {
      return null;
    }
    const tokenPayload = getJwtTokenPayload<BaseJwtToken>(token);
    const brandOnboardingId = tokenPayload.sub.split('|')[1];
    return brandOnboardingId;
  };

  const [brandOnboardingId, setBrandOnboardingId] = useState<string | null>(
    getBrandOnboardingIdFromToken(rawToken)
  );

  useEffect(() => {
    if (rawToken) {
      setBrandOnboardingId(getBrandOnboardingIdFromToken(rawToken));
    }
  }, [rawToken]);

  const { data } = useFetchBrandOnboardingById(brandOnboardingId, rawToken);

  const { mutateAsync: selectColors } = useSetColorsMutation(
    brandOnboardingId,
    rawToken
  );
  const { mutateAsync: selectAssets } = useSetAssetsMutation(
    brandOnboardingId,
    rawToken
  );
  const { mutateAsync: setWorkspaceDetails } = useSetWorkspaceDetailsMutation(
    brandOnboardingId,
    rawToken
  );

  /**
   * Tracks if we are waiting for the scan animation to complete its minimum time already.
   * Used to prevent the call to goToColorSelectionStep from being called multiple times.
   */
  const [waitingForScanAnimation, setWaitingForScanAnimation] =
    useState<boolean>(false);

  const [, setLoadedGoals] = useState<Array<string>>([]);
  const colorsAvailableSubject = useMemo(
    () => new BehaviorSubject<boolean>(false),
    []
  );

  const assetsAvailableSubject = useMemo(
    () => new BehaviorSubject<boolean>(false),
    []
  );

  /**
   * Tracks if the screenshot is available in the brand onboarding scan
   * (And is the screenshot)
   */
  const [screenShot, setScreenShot] = useState<ScreenshotResource | undefined>(
    undefined
  );
  /**
   * Tracks if colors are available in the brand onboarding scan
   */
  const [colorsAvailable, setColorsAvailable] = useState<boolean>(false);
  const [loadedPrimaryColor, setLoadedPrimaryColor] = useState<
    SelectedColorResource | undefined
  >();
  const [loadedSecondaryColor, setLoadedSecondaryColor] = useState<
    SelectedColorResource | undefined
  >();

  /**
   * Tracks the colors discovered in the brand onboarding scan
   */
  const [discoveredColors, setDiscoveredColors] =
    useState<ColorScanResource[]>();

  const [assetsAvailable, setAssetsAvailable] = useState<boolean>(false);

  const [loadedAssets, setLoadedAssets] = useState<SelectedAssetResource[]>([]);
  const [discoveredAssets, setDiscoveredAssets] = useState<AssetScanResource[]>(
    []
  );

  const goToColorSelectionStep = useCallback(() => {
    if (searchParams.get('step') !== COLOR_STEP) {
      updateSearchPrams(
        userSteps.findIndex((step) => step.title === COLOR_STEP),
        rawToken ?? ''
      );
    }
  }, [rawToken, searchParams, updateSearchPrams]);

  /**
   * Update the URL with the current step.
   */
  useEffect(() => {
    const currentStepSearchParam = searchParams.get('step');
    /**
     * When there is not a step search param we will set the current step to the first step.
     */
    if (
      currentStepSearchParam == null ||
      currentStepSearchParam.trim() === ''
    ) {
      setSearchParams(
        { step: userSteps[0].title, token: rawToken ?? '' },
        { replace: true }
      );
    }
  }, [rawToken, searchParams, setSearchParams]);

  const isScreenshotAvailable = (siteScan: SiteScanResource) => {
    return siteScan?.screenshotStatus === 'COMPLETE';
  };

  const isColorsAvailable = (siteScan: SiteScanResource) => {
    return siteScan.colorStatus === 'COMPLETE';
  };

  const isAssetsAvailable = (siteScan: SiteScanResource) => {
    return siteScan.assetsStatus === 'COMPLETE';
  };

  useEffect(() => {
    const currentStepIsSiteScan = searchParams.get('step') === SITE_SCAN_STEP;
    let siteScanStepsAvailable$: Subscription;
    if (
      !waitingForScanAnimation &&
      screenShot != null &&
      currentStepIsSiteScan
    ) {
      setWaitingForScanAnimation(true);
      /**
       * Now that the screenshot is available we will wait 2 seconds and then move to the color selection step.
       * This is to give the user a chance to see the screenshot before moving on.
       * If the colors are not available yet, we will wait until they are.
       */
      console.log(
        'Kicking off Delay',
        JSON.stringify({ screenShot, colorsAvailable })
      );

      /**
       * We want to show users the screenshot for at least 2 seconds.
       * - Avoid flicker
       * - Buy time for the site-scan to complete
       * - Gets the user intrigued about what we are doing with their site
       */
      const minimumTimeToShowScreenshot = 5500;
      let screenShotDisplayedForMinimumTime = new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log('Minimum Time Reached');
          resolve();
        }, minimumTimeToShowScreenshot);
      });

      /**
       * Once the screenshot has been displayed for the minimum amount of time
       * we will start checking to see if the data from the site scan is available
       * so that we can route to the next screen
       */
      screenShotDisplayedForMinimumTime.then(() => {
        siteScanStepsAvailable$ = zip(
          colorsAvailableSubject.asObservable(),
          assetsAvailableSubject.asObservable()
        ).subscribe(([colorsAvailable, assetsAvailable]) => {
          /**
           * We may update the routing logic to go to either the assets page or the colors available page based on what is available.
           * after the screenshot has been displayed for the minimum time. Currently we are just going to the colors page.
           */
          console.log('colorsAvailable', colorsAvailable);
          console.log('assetsAvailable', assetsAvailable);
          if (colorsAvailable) {
            goToNextStep();
          }
        });
      });
    }
    return () => {
      /**
       * Clean up the subscription on component unmount.
       */
      if (siteScanStepsAvailable$) {
        siteScanStepsAvailable$.unsubscribe();
      }
    };
  }, [
    colorsAvailable,
    searchParams,
    goToColorSelectionStep,
    screenShot,
    waitingForScanAnimation,
    goToNextStep,
    colorsAvailableSubject,
    assetsAvailableSubject,
  ]);

  useEffect(() => {
    if (isUrlBoxScreenshotEnabled) {
      if (data?.screenshotUrl && screenShot == null) {
        setScreenShot({
          publicUrl: data?.screenshotUrl,
          dimensions: { height: 998, width: 1440 },
        });
      }
    }
    if (data?.siteScan) {
      /**
       * If the screenshot is available, we will set it in our components state.
       */
      if (isScreenshotAvailable(data.siteScan) && screenShot == null) {
        console.log('Setting Screenshot', data?.siteScan?.screenshot);
        setScreenShot(data?.siteScan?.screenshot);
      }

      if (data?.steps?.goalSelection?.goals) {
        setLoadedGoals(data.steps.goalSelection.goals?.map((g) => g.goal));
      }

      if (
        data?.steps?.colorSelection?.primaryColor &&
        data.steps.colorSelection.secondaryColor
      ) {
        setLoadedPrimaryColor(data.steps.colorSelection.primaryColor);
        setLoadedSecondaryColor(data.steps.colorSelection.secondaryColor);
      }

      if (data?.steps?.assetSelection?.length) {
        setLoadedAssets(data.steps.assetSelection);
      }

      /**
       * We need to track if the colors and are assets are available
       * so we can know it is safe to move to the next step.
       */
      const colorsAvailableInRequest = isColorsAvailable(data.siteScan);
      if (
        colorsAvailableInRequest &&
        colorsAvailableInRequest !== colorsAvailable
      ) {
        setDiscoveredColors(data.siteScan.colors);
        setColorsAvailable(colorsAvailableInRequest);
        colorsAvailableSubject.next(colorsAvailableInRequest);
      }

      const assetsAvailableInRequest = isAssetsAvailable(data.siteScan);
      if (
        assetsAvailableInRequest &&
        assetsAvailableInRequest !== assetsAvailable
      ) {
        setDiscoveredAssets(data.siteScan.assets);
        setAssetsAvailable(assetsAvailableInRequest);
        assetsAvailableSubject.next(assetsAvailableInRequest);
      }
    }
  }, [
    assetsAvailable,
    assetsAvailableSubject,
    colorsAvailable,
    colorsAvailableSubject,
    data,
    screenShot,
    isUrlBoxScreenshotEnabled,
  ]);

  const handleColorPageNext = async (
    primaryColor: SelectedColorResource | undefined,
    secondaryColor: SelectedColorResource | undefined
  ) => {
    if (primaryColor == null) {
      return Promise.reject(new Error('A primary color is required'));
    }

    const payload: CreateSelectedColorsRequestResource = {
      primaryColor,
      ...(secondaryColor ? { secondaryColor } : undefined),
    };

    const selectBrandColorsPromise = selectColors(payload);
    selectBrandColorsPromise.then(() => {
      setLoadedPrimaryColor(primaryColor);
      setLoadedSecondaryColor(secondaryColor);
      goToNextStep();
    });
    return selectBrandColorsPromise;
  };

  const handleAssetPageNext = async (selectedImages: AssetsCategorized[]) => {
    const formattedAssets = selectedImages.map(({ id, category }) => ({
      id,
      classification: category || ('CONTENT' as any),
    }));

    const selectAssetsPromise = selectAssets({ assets: formattedAssets });
    selectAssetsPromise.then(() => {
      setLoadedAssets(formattedAssets);
      goToNextStep();
    });
    return selectAssetsPromise;
  };

  const handleWorkspacePageNext = async (data: any) => {
    console.log('Workspace Data', data);
    const populateWorkspaceDetailsPromise = setWorkspaceDetails(data);
    populateWorkspaceDetailsPromise.then(() => {
      goToNextStep();
      console.log('Workspace Details Saved');
    });
    return populateWorkspaceDetailsPromise;
  };

  return (
    <div className='flex flex-col min-h-screen background-dark overflow-scroll justify-between '>
      <ObProgressHeader
        percentage={
          ((userSteps.findIndex(
            (step) => step.title === searchParams.get('step')
          ) +
            1) /
            userSteps.length) *
          100
        }
      />
      {searchParams.get('step') === GOAL_SELECTION_STEP && (
        <GoalSelector domain={data?.domain ?? 'website'} />
      )}
      {searchParams.get('step') === SITE_SCAN_STEP && (
        <WebsiteScannerStep screenshot={screenShot} />
      )}
      {searchParams.get('step') === COLOR_STEP && (
        <BrandColorSelectorPage
          hasColors={!!discoveredColors?.length}
          availableColors={discoveredColors?.filter(
            (value) => value.categorization === 'COLOR'
          )}
          onSubmitCallback={handleColorPageNext}
          initialPrimaryColor={loadedPrimaryColor}
          initialSecondaryColor={loadedSecondaryColor}
        />
      )}
      {searchParams.get('step') === ASSET_STEP && (
        <WebsiteAssetResults
          availableAssets={discoveredAssets}
          onSubmitCallback={handleAssetPageNext}
          initialAssets={loadedAssets}
        />
      )}
      {searchParams.get('step') === WORKSPACE_DETAILS_CAPTURE_STEP && (
        <WorkspaceDetailsCapture
          onFormSubmitCallback={handleWorkspacePageNext}
        />
      )}
      {searchParams.get('step') === WORKSPACE_CHECK_EMAIL &&
        !isAuthenticated && (
          <section className='flex items-center justify-center flex-col'>
            <ObTypography variant='h1'>Check Your Email</ObTypography>
            <ObTypography variant='h2'>
              Confirm Your Email Address to Continue
            </ObTypography>
          </section>
        )}
      {searchParams.get('step') === WORKSPACE_CHECK_EMAIL &&
        isAuthenticated && (
          <WorkspaceRedirect
            brandOnboardingId={brandOnboardingId ?? ''}
            token={rawToken ?? ''}
          />
        )}
      <footer className='flex justify-center items-center h-16'></footer>
    </div>
  );
};
