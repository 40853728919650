import {
  CampaignLocationCard,
  CampaignLocationCardProps,
} from '../../../business-objects/campaign-location/campaign-location-card';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { BaseSection } from './components/base-section';

export interface LocationSectionProps {
  campaignLocations: Array<Omit<CampaignLocationCardProps, 'variant'>>;
}

export const LocationSection = ({
  campaignLocations = [],
}: LocationSectionProps) => {
  return (
    <BaseSection
      heading='Locations'
      subheading='Locations this campaign should advertise for'
    >
      {campaignLocations == null || campaignLocations.length === 0 ? (
        <>
          <div className='flex justify-center items-center w-full h-48'>
            <p className='text-center text-gray-400'>
              No locations have been added to this campaign yet.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className='grid grid-col-1 gap-8 w-full '>
            {campaignLocations.map((location) => {
              return (
                <CampaignLocationCard
                  key={`${location.locationId}`}
                  {...location}
                  variant={'grid'}
                />
              );
            })}
          </div>

          <ObTypography
            className='pt-4'
            variant='body3'
            color='secondary'
          >
            We will handle the exact targeting for you. You tell us where your
            business operates and we will expertly place your ads to maximize
            your return on investment!
          </ObTypography>
        </>
      )}
    </BaseSection>
  );
};
