import { KeyboardEvent } from 'react';
import Swatch from 'react-color/lib/components/common/Swatch';
import { ObTypography } from '../../../../index';
import { useKeyboardNavigation } from './hooks/use-keyboard-navigation';

interface CustomColorPickerPresetColorsProps {
  colors: string[];
  onClick: (args: any, e: any) => void;
}

export const CustomColorPickerPresetColors = ({
  colors,
  onClick,
}: CustomColorPickerPresetColorsProps) => {
  const { focusFirstPickerInput } = useKeyboardNavigation();

  const handleClick = (hex: string, e: any) => {
    if (onClick) {
      onClick({ hex, source: 'hex' }, e);
    }
  };

  if (!colors?.length) {
    return null;
  }

  return (
    <div className='flex flex-col px-0 mt-4'>
      <ObTypography variant='body1'>Optional Colors Palette</ObTypography>

      <div className='flex wrap flexbox-fix px-0 pt-4 my-0'>
        {colors.map((color: any, index: number) => (
          <div
            key={`${color}_color`}
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '15px',
              margin: '0 10px 10px 0',
            }}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) =>
              index == colors.length - 1 && focusFirstPickerInput(event)
            }
          >
            <Swatch
              color={color}
              style={{
                borderRadius: '15px',
                border: '1px solid rgba(255, 255, 255, 0.16)',
              }}
              onClick={handleClick}
              focusStyle={{
                border: '1px solid white',
                boxShadow: `1px solid rgba(255, 255, 255, 1)`,
                outline: 'none',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
