import { ObLinkProps } from '@outbound/design-system/src/components/elements/ob-link/ob-link';

export const legalLinks: ObLinkProps[] = [
  {
    variant: 'primary',
    label: 'Terms of Service',
    openInNewTab: true,
    href: 'https://legal.outbound.com/terms-of-service',
  },
  {
    variant: 'primary',
    label: 'Privacy Policy',
    openInNewTab: true,
    href: 'https://legal.outbound.com/privacy-policy',
  },
];
