/** solid/editor/cursor-click-02 */
export const CursorClick02 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M6.00033 0.666748C6.36852 0.666748 6.66699 0.965225 6.66699 1.33341V2.33341C6.66699 2.7016 6.36852 3.00008 6.00033 3.00008C5.63214 3.00008 5.33366 2.7016 5.33366 2.33341V1.33341C5.33366 0.965225 5.63214 0.666748 6.00033 0.666748Z'
        fill='currentColor'
      />
      <path
        d='M2.19559 2.19534C2.45594 1.93499 2.87805 1.93499 3.1384 2.19534L3.8455 2.90245C4.10585 3.1628 4.10585 3.58491 3.8455 3.84526C3.58515 4.10561 3.16304 4.10561 2.90269 3.84526L2.19559 3.13815C1.93524 2.8778 1.93524 2.45569 2.19559 2.19534Z'
        fill='currentColor'
      />
      <path
        d='M3.8455 9.13815C4.10585 8.8778 4.10585 8.45569 3.8455 8.19534C3.58515 7.93499 3.16304 7.93499 2.90269 8.19534L2.19559 8.90245C1.93524 9.1628 1.93524 9.58491 2.19559 9.84526C2.45594 10.1056 2.87805 10.1056 3.1384 9.84526L3.8455 9.13815Z'
        fill='currentColor'
      />
      <path
        d='M9.8455 2.19534C10.1059 2.45569 10.1059 2.8778 9.8455 3.13815L9.1384 3.84526C8.87805 4.10561 8.45594 4.10561 8.19559 3.84526C7.93524 3.58491 7.93524 3.1628 8.19559 2.90245L8.90269 2.19534C9.16304 1.93499 9.58515 1.93499 9.8455 2.19534Z'
        fill='currentColor'
      />
      <path
        d='M1.33366 5.33342C0.965469 5.33342 0.666992 5.63189 0.666992 6.00008C0.666992 6.36827 0.965469 6.66675 1.33366 6.66675H2.33366C2.70185 6.66675 3.00033 6.36827 3.00033 6.00008C3.00033 5.63189 2.70185 5.33342 2.33366 5.33342H1.33366Z'
        fill='currentColor'
      />
      <path
        d='M5.87119 5.03214C5.63321 4.95557 5.37238 5.01858 5.19561 5.19536C5.01883 5.37214 4.95581 5.63296 5.03238 5.87095L7.77312 14.3895C7.84427 14.6106 8.02539 14.7788 8.25119 14.8333C8.47698 14.8879 8.7149 14.8209 8.87916 14.6567L10.3337 13.2022L12.2697 15.1382C12.3947 15.2632 12.5643 15.3334 12.7411 15.3334C12.9179 15.3334 13.0875 15.2632 13.2125 15.1382L15.1384 13.2122C15.2634 13.0872 15.3337 12.9177 15.3337 12.7408C15.3337 12.564 15.2634 12.3945 15.1384 12.2694L13.2024 10.3334L14.6569 8.87891C14.8212 8.71465 14.8881 8.47674 14.8335 8.25094C14.779 8.02515 14.6108 7.84402 14.3897 7.77288L5.87119 5.03214Z'
        fill='currentColor'
      />
    </svg>
  );
};
