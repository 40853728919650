import { FC } from 'react';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObTypography } from '../ob-typography/ob-typography';

export type ObInputCaptionProps = {
  type: 'error' | 'info';
  message: string;
  hideIcon?: boolean;
};

/**
 * Shows text captions under input fields along with a corresponding icon
 * @param type
 * @param message
 * @returns
 */
export const ObInputCaption: FC<ObInputCaptionProps> = ({
  type,
  message,
  hideIcon = false,
}: ObInputCaptionProps) => {
  return (
    <div className='flex gap-2'>
      {!hideIcon && (
        <ObIcon
          size='x-small'
          icon={type === 'error' ? 'errorMessage' : 'info'}
          color={type === 'error' ? 'negative' : 'content'}
        />
      )}
      <ObTypography
        color={type === 'error' ? 'negative' : 'secondary'}
        variant='body3'
      >
        {message}
      </ObTypography>
    </div>
  );
};
