import { stringHasContent } from '@otbnd/utils';
import { ObTypography } from '../../../../../components/elements/ob-typography/ob-typography';
import { OverLineField } from '../../../components/overline-field';
import { SectionContainer } from '../../../components/section-container';

export interface GoogleAdManagerIntegrationConfigurationProps {
  customerId?: string;
  connectExistingAccount?: boolean;
  timeZone?: string;
  isGoogleAdsTestAccount?: boolean;
  googleAdsAccountStatus?: string;
  descriptiveName?: string;
  currencyCode?: string;
}

export const GoogleAdManagerIntegrationConfiguration = ({
  customerId,
  connectExistingAccount,
  timeZone,
  isGoogleAdsTestAccount,
  googleAdsAccountStatus,
  descriptiveName,
  currencyCode,
}: GoogleAdManagerIntegrationConfigurationProps) => {
  return (
    <SectionContainer>
      <div className='m-3 grid grid-cols-2 gap-4'>
        <OverLineField
          label='Google Ads Account ID'
          content={
            <ObTypography>{formatGoogleAdsAccountId(customerId)}</ObTypography>
          }
        />
        <OverLineField
          label='Account Origin'
          content={formatIsConfiguredByOutbound(connectExistingAccount)}
        />
        <OverLineField
          label='Name'
          content={<ObTypography>{descriptiveName ?? '---'}</ObTypography>}
        />
        <OverLineField
          label='Currency'
          content={<ObTypography>{currencyCode ?? '---'}</ObTypography>}
        />
        <OverLineField
          label='Timezone'
          content={<ObTypography>{timeZone ?? '---'}</ObTypography>}
        />
        <OverLineField
          label='Account Status'
          content={
            <ObTypography>{googleAdsAccountStatus ?? '---'}</ObTypography>
          }
        />
      </div>
      {isGoogleAdsTestAccount && (
        <div className='p-3'>
          <ObTypography
            color='negative'
            className='text-center'
            variant='h6'
          >
            GOOGLE ADS SANDBOX ACCOUNT
          </ObTypography>
        </div>
      )}
    </SectionContainer>
  );
};

const formatIsConfiguredByOutbound = (connectExistingAccount?: boolean) => {
  if (connectExistingAccount == null) {
    return <ObTypography color='secondary'>---</ObTypography>;
  } else {
    return (
      <ObTypography>
        {connectExistingAccount
          ? 'Existing Google Ads Account'
          : 'Created By Outbound'}
      </ObTypography>
    );
  }
};

const formatGoogleAdsAccountId = (customerId?: string) => {
  if (stringHasContent(customerId)) {
    let formattedCustomerId = customerId!.trim();
    if (formattedCustomerId.length === 10) {
      formattedCustomerId = `${formattedCustomerId.slice(
        0,
        3
      )}-${formattedCustomerId.slice(3, 6)}-${formattedCustomerId.slice(
        6,
        10
      )}`;
    }
    return formattedCustomerId;
  } else {
    return '---';
  }
};
