import { NotificationContent } from '@outbound/design-system';
import { AxiosInstance } from 'axios';
import { AssetStore } from './assets/asset-store';
import CustomerProfileStore from './business-context/customer-profile/customer-profile-store';
import LocationStore from './business-context/location/location-store';
import ServiceStore from './business-context/service/service-store';
import CampaignStore from './campaign/campaign-store';
import CreativeStore from './creative/creative-store';
import { TemplateStore } from './creative/template/template-store';
import LandingPageStore from './landing-page/landing-page-store';
import { Transport } from './transport';
import User from './user/user';
import UserStore from './user/user-store';
import IntegrationConfigurationStore from './workspace/integration-configuration/integration-configuration-store';
import IntegrationStore from './workspace/integration/integration-store';
import MembershipStore from './workspace/membership/membership-store';
import WorkspaceStore from './workspace/workspace-store';

/**
 * The primary client side state stor for the application
 */
export class RootStore {
  private rootStoreId: string;
  private authenticatedUserId: string | null;
  readonly pushNotification: (
    notification: NotificationContent
  ) => Promise<void>;
  private environment: string;
  private readonly INIT_DELAY: number = 1000;
  private initTimeoutId: ReturnType<typeof setTimeout> | null = null;
  private _clientId: string = 'abc-123';
  private _transport: Transport;
  private _campaignStore: CampaignStore;
  private _creativeStore: CreativeStore;
  private _serviceStore: ServiceStore;
  private _locationStore: LocationStore;
  private _customerProfileStore: CustomerProfileStore;
  private _templateStore: TemplateStore;
  private _assetStore: AssetStore;
  private _integrationStore: IntegrationStore;
  private _integrationConfigurationStore: IntegrationConfigurationStore;
  private _workspaceStore: WorkspaceStore;
  private _userStore: UserStore;
  private _membershipStore: MembershipStore;
  private _landingPageStore: LandingPageStore;

  /**
   *
   * @param transport
   * @param authenticatedUserId Null if there is no authenticated user
   */
  constructor(
    transport: Transport,
    authenticatedUserId: string | null,
    pushNotification: (notification: NotificationContent) => Promise<void>
  ) {
    this.authenticatedUserId = authenticatedUserId;

    this.pushNotification = pushNotification;

    // Generate a short random ID for the root store (Used for debugging)
    this.rootStoreId = Math.random().toString(36).substring(7);
    console.log('Creating Root Store', this.rootStoreId);

    console.log('Root Store Authenticated User ID', this.authenticatedUserId);

    this._transport = transport;
    this._workspaceStore = new WorkspaceStore(this);
    this._userStore = new UserStore(this);
    this._membershipStore = new MembershipStore(this);
    this._campaignStore = new CampaignStore(this);
    this._creativeStore = new CreativeStore(this);
    this._serviceStore = new ServiceStore(this);
    this._locationStore = new LocationStore(this);
    this._customerProfileStore = new CustomerProfileStore(this);
    this._templateStore = new TemplateStore(this);
    this._assetStore = new AssetStore(this);
    this._integrationStore = new IntegrationStore(this);
    this._integrationConfigurationStore = new IntegrationConfigurationStore(
      this
    );
    this._landingPageStore = new LandingPageStore(this);
    this.environment = 'dev';

    this.initTimeoutId = setTimeout(() => {
      this.initialize();
      this.initTimeoutId = null; // Clear the timeout ID after bootstrapping
    }, this.INIT_DELAY);
  }

  public async initialize() {
    console.log('Initializing Root Store ', this.rootStoreId);

    /**
     * Ideally at some point we will store data locally and not need to do a full bootstrap
     * on each app init. This is something we would like to do as a future optimization.
     *
     * If you neglect to call this method and you call list() on a store, you will get an a promise thrown that will trigger suspense in the UI and never resolve.
     *
     * We may want to consider a more sophisticated way to handle this in the future
     * such as a register method or something instead of needing to create a transport for every model / resource
     */
    this._transport.initializeBusinessContext();
    this._transport.assetTransport.bootstrap(); //Load all assets from server
    this._transport.integrationTransport.bootstrap();
    this._transport.workspaceTransport.bootstrap();
    this._transport.membershipTransport.bootstrap();
    this._transport.userTransport.bootstrap();
    this._transport.integrationConfigurationTransport.bootstrap();
    this._transport.customerProfileTransport.bootstrap();
    this._transport.locationTransport.bootstrap();
    this._transport.serviceTransport.bootstrap();
    this._transport.creativeTransport.bootstrap();
    this._transport.campaignTransport.bootstrap();
    this._transport.landingPageTransport.bootstrap();
  }

  public destroy(): void {
    /**
     * Temporary fix to prevent heavy initialization from being called during the React
     * app initialization. I noticed during development that the store is being created and torn down
     * several times on app bootstrap. This wasn't an issue until I started to make a bunch of API calls
     * to bootstrap all the stores.
     * Ideally we should solve the root cause of the issue, but for now this is a quick fix.
     */
    if (this.initTimeoutId) {
      console.log('Canceling Root Store Initialization', this.rootStoreId);
      clearTimeout(this.initTimeoutId);
      this.initTimeoutId = null; // Clear the timeout ID
    }
    console.log('Destroying Root Store', this.rootStoreId);
    this._transport?.destroy();
  }
  get clientId(): string {
    return this._clientId;
  }

  get transport(): Transport {
    return this._transport;
  }

  get creativeStore(): CreativeStore {
    return this._creativeStore;
  }

  get campaignStore(): CampaignStore {
    return this._campaignStore;
  }

  get serviceStore(): ServiceStore {
    return this._serviceStore;
  }

  get locationStore(): LocationStore {
    return this._locationStore;
  }

  get customerProfileStore(): CustomerProfileStore {
    return this._customerProfileStore;
  }

  get templateStore(): TemplateStore {
    return this._templateStore;
  }

  get assetStore(): AssetStore {
    return this._assetStore;
  }

  get integrationStore(): IntegrationStore {
    return this._integrationStore;
  }

  get workspaceStore(): WorkspaceStore {
    return this._workspaceStore;
  }

  get membershipStore(): MembershipStore {
    return this._membershipStore;
  }

  get integrationConfigurationStore(): IntegrationConfigurationStore {
    return this._integrationConfigurationStore;
  }

  get userStore(): UserStore {
    return this._userStore;
  }

  get landingPageStore(): LandingPageStore {
    return this._landingPageStore;
  }

  get env(): string {
    return this.environment;
  }

  get authenticatedUser(): User | null {
    if (this.authenticatedUserId == null) {
      return null;
    }
    return this.userStore.getById(this.authenticatedUserId)!;
  }
}

/**
 * Called by the Root Store React Context to Setup the Root Store for the Application
 * @param axiosInstance
 * @returns
 */
export const createRootStore = (
  axiosInstance: AxiosInstance,
  authenticatedUserId: string | null,
  pushNotification: (notification: NotificationContent) => Promise<void>
): RootStore => {
  console.log('Create Root Store', authenticatedUserId);
  const transport = new Transport(axiosInstance);
  return new RootStore(transport, authenticatedUserId, pushNotification);
};
