export const Budget = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.33334 2.66667C2.96515 2.66667 2.66667 2.96514 2.66667 3.33333C2.66667 3.70152 2.96515 4 3.33334 4L12.6667 4C13.7712 4 14.6667 4.89543 14.6667 6V12.6667C14.6667 13.7712 13.7712 14.6667 12.6667 14.6667H3.33334C2.22877 14.6667 1.33334 13.7712 1.33334 12.6667V3.33333C1.33334 2.22876 2.22877 1.33333 3.33334 1.33333H11.3333C11.7015 1.33333 12 1.63181 12 2C12 2.36819 11.7015 2.66667 11.3333 2.66667H3.33334ZM11 8.33333C10.4477 8.33333 10 8.78105 10 9.33333C10 9.88562 10.4477 10.3333 11 10.3333C11.5523 10.3333 12 9.88562 12 9.33333C12 8.78105 11.5523 8.33333 11 8.33333Z'
        fill='currentColor'
      />
    </svg>
  );
};
