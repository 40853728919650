import { Fragment, ReactNode } from 'react';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariant } from '../card.type';

export interface CampaignListNestedProps {
  campaignObrns: Array<string>;
  campaignRenderFunction: (
    campaignObrn: string,
    variant: CardVariant
  ) => ReactNode;
}

export const CampaignListNested = ({
  campaignObrns: campaigns,
  campaignRenderFunction,
}: CampaignListNestedProps) => {
  return (
    <section className='h-full flex justify-center items-start w-full @container'>
      <div className=' @2xl:hidden grid @xl:grid-cols-2 grid-cols-1 gap-8 w-full'>
        {campaigns.map((c) => {
          return (
            <Fragment key={c}>{campaignRenderFunction(c, 'grid')}</Fragment>
          );
        })}
      </div>
      {/* Negative Margin Added to Deal with Border Spacing applying to the table as well. Better Fixes Welcome. */}
      <table className='hidden @2xl:block border-separate border-spacing-y-4 w-full mt-[-16px]'>
        <thead className='w-full '>
          <tr className='w-full'>
            <th className='text-left px-4 w-1/3 '>
              <ObTypography
                variant='subtitle3'
                color='secondary'
                className='uppercase text-left'
                as='span'
              >
                Campaign
              </ObTypography>
            </th>
            <th className='text-left px-4 '>
              <ObTypography
                variant='subtitle3'
                color='secondary'
                className='uppercase text-left'
                as='span'
              >
                Status
              </ObTypography>
            </th>
            <th className='text-left px-4 '>
              <ObTypography
                variant='subtitle3'
                color='secondary'
                className='uppercase text-left'
                as='span'
              >
                Integration
              </ObTypography>
            </th>
            <th className='text-left px-4 '>
              <ObTypography
                variant='subtitle3'
                color='secondary'
                className='uppercase text-left'
                as='span'
              >
                Health
              </ObTypography>
            </th>
          </tr>
        </thead>
        <tbody className='w-full'>
          {campaigns.map((c) => {
            return (
              <Fragment key={c}>{campaignRenderFunction(c, 'table')}</Fragment>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
