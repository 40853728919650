/**
 * Icon Name: link-external-02
 *
 * {@link https://www.figma.com/file/l1PsB4JNyhbHbnXedIkHJ4/Outbound-DS?type=design&node-id=3491-18241&mode=design&t=rociGP1iVjtjtIjV-4 | View In Figma}
 */
export const LinkExternal02 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.17214 0.8125H5.66634C6.03453 0.8125 6.33301 1.11098 6.33301 1.47917C6.33301 1.84736 6.03453 2.14583 5.66634 2.14583H4.19968C3.62862 2.14583 3.24043 2.14635 2.94037 2.17087C2.64809 2.19475 2.49862 2.23803 2.39435 2.29116C2.14347 2.41899 1.9395 2.62296 1.81167 2.87385C1.75854 2.97811 1.71526 3.12758 1.69138 3.41986C1.66686 3.71992 1.66634 4.10811 1.66634 4.67917V10.2792C1.66634 10.8502 1.66686 11.2384 1.69138 11.5385C1.71526 11.8308 1.75854 11.9802 1.81167 12.0845C1.9395 12.3354 2.14347 12.5393 2.39435 12.6672C2.49862 12.7203 2.64809 12.7636 2.94037 12.7875C3.24043 12.812 3.62862 12.8125 4.19968 12.8125H9.79968C10.3707 12.8125 10.7589 12.812 11.059 12.7875C11.3513 12.7636 11.5007 12.7203 11.605 12.6672C11.8559 12.5393 12.0599 12.3354 12.1877 12.0845C12.2408 11.9802 12.2841 11.8308 12.308 11.5385C12.3325 11.2384 12.333 10.8502 12.333 10.2792V8.8125C12.333 8.44431 12.6315 8.14583 12.9997 8.14583C13.3679 8.14583 13.6663 8.44431 13.6663 8.8125V10.3067C13.6664 10.8434 13.6664 11.2863 13.6369 11.6471C13.6063 12.0218 13.5406 12.3663 13.3757 12.6898C13.12 13.1916 12.7121 13.5995 12.2103 13.8552C11.8868 14.02 11.5423 14.0858 11.1676 14.1164C10.8068 14.1458 10.3639 14.1458 9.82723 14.1458H4.17213C3.63549 14.1458 3.19258 14.1458 2.83179 14.1164C2.45706 14.0858 2.11259 14.02 1.78903 13.8552C1.28727 13.5995 0.87932 13.1916 0.623658 12.6898C0.458797 12.3663 0.393088 12.0218 0.362471 11.6471C0.332993 11.2863 0.333 10.8434 0.333008 10.3067V4.65163C0.333 4.11499 0.332993 3.67208 0.362471 3.31128C0.393088 2.93655 0.458797 2.59208 0.623658 2.26853C0.87932 1.76676 1.28727 1.35881 1.78903 1.10315C2.11259 0.93829 2.45706 0.87258 2.83179 0.841963C3.19259 0.812485 3.6355 0.812492 4.17214 0.8125Z'
        fill='currentColor'
      />
      <path
        d='M8.33301 1.47917C8.33301 1.11098 8.63148 0.8125 8.99967 0.8125H12.9997C13.3679 0.8125 13.6663 1.11098 13.6663 1.47917L13.6663 5.47917C13.6663 5.84736 13.3679 6.14583 12.9997 6.14583C12.6315 6.14583 12.333 5.84736 12.333 5.47917L12.333 3.08865L7.47108 7.95057C7.21073 8.21092 6.78862 8.21092 6.52827 7.95057C6.26792 7.69022 6.26792 7.26811 6.52827 7.00776L11.3902 2.14583H8.99967C8.63148 2.14583 8.33301 1.84736 8.33301 1.47917Z'
        fill='currentColor'
      />
    </svg>
  );
};
