/**
 * Named colors picked by Typeless for the Typeless Design System.
 * When we onboard a designer they can define colors here and then
 * use them throughout the system
 */
export const baseColorPalette: any = {
  typelessLakeBlue: {
    hex: '#258bd7',
    name: 'Typeless Lake Blue',
  },
  typelessOceanBlue: {
    hex: '#1b346e',
    name: 'Typeless Ocean Blue',
  },
  oceanDepths: {
    hex: '#19234F',
    name: 'Ocean Depth',
  },
  oceanNight: {
    hex: '#0D1229',
    name: 'Jet',
  },
  blackInk: {
    hex: '#1B1D2C',
    name: 'Black Ink',
  },
  brandGradiantDarkStart: { hex: '#01030C', name: 'Gradiant Start Dark' },
  brandGradiantDarkEnd: { hex: '#060B26', name: 'Gradiant End Dark' },
};

export const findNamedColorByCssValue = (cssValue: string): any => {
  return Object.keys(baseColorPalette).find(
    (colorName: string) => baseColorPalette[colorName]?.hex === cssValue
  );
};
