import { AssetMimeType } from '@outbound/types';

export const getMimeTypeFromFileName = (filename: string): string => {
  let ext = '';
  if (filename) {
    ext = filename.split('.').pop() || '';
  }
  const mimeTypes: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    pdf: 'application/pdf',
    // Add more mappings as needed
  };
  return mimeTypes[ext] ?? 'application/octet-stream'; // Default to binary stream if unknown
};

export const getAssetMimeType = (mimeType: string) => {
  if (mimeType.toUpperCase() === 'SVG') {
    return AssetMimeType.SVG;
  }
  if (mimeType.toUpperCase() === 'JPG') {
    return AssetMimeType.JPEG;
  }
  return `image/${mimeType}`.toUpperCase() as AssetMimeType;
};
