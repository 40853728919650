import { useCallback } from 'react';
import { useDialogService } from '../../services/dialog-service-provider/dialog-service-provider';
import { SettingCardRowWithButton } from '../ob-setting-card-with-button/setting-card-row-with-button';

export interface ObDeleteCardProps {
  /**
   * The value that the user will need to type in order to confirm the delete
   */
  typeToConfirmValue: string;
  /**
   * Indicates that the card should show a skeleton loading state
   */
  isLoading: boolean;

  /**
   * The title of the card.
   * Example: 'Delete Workspace'
   */
  cardTitle: string;
  /**
   * The body of the card.
   * Should explain the consequences of the delete action
   * Example: Deleting a Workspace will remove all your data from Outbound.
   *           This action is permanent and cannot be undone.
   */
  cardBody: string;

  /**
   * The labe of the button that will open a type to confirm dialog.
   * Example: 'Delete Workspace'
   */
  cardButtonLabel: string;

  /**
   * The title of the confirmation dialog
   * Example: 'Confirm Workspace Deletion'
   */
  confirmationDialogTitle: string;
  /**
   * The body of the confirmation dialog
   * Should explain the consequences of the delete action
   */
  confirmationDialogBody: string | string[];

  /**
   * Explain what the user needs to type in order to confirm the delete.
   * (The actual value will be shown to them in the dialog so do not include it here)
   * Example: 'Type the name of the workspace to confirm'
   *    (In this example we are showing them the typeToConfirmValue value which would be their workspace name)
   */
  typeToConfirmInstructions: string;
  /**
   * A callback function to be called when the delete button is clicked
   * This function should call the delete endpoint and return a promise
   * once the delete is complete
   */
  onDeleteConfirmedCallback: () => Promise<any>;
}

/**
 * The complete resource delete experience including a delete card
 * and a type to confirm dialog. Consumers of this component are only
 * responsible for calling the delete endpoint and handling the response
 * by passing a function to the onDeleteConfirmedCallback.
 */
export const ObDeleteCard = ({
  typeToConfirmValue,
  isLoading,
  cardTitle,
  cardBody,
  cardButtonLabel,
  confirmationDialogTitle,
  confirmationDialogBody,
  typeToConfirmInstructions,
  onDeleteConfirmedCallback,
}: ObDeleteCardProps) => {
  const { openTypeToConfirmation } = useDialogService();
  const openTypeToConfirmDialog = useCallback(() => {
    openTypeToConfirmation({
      body: confirmationDialogBody,
      title: confirmationDialogTitle,
      expectedValue: typeToConfirmValue,
      inputLabel: typeToConfirmInstructions,
      affirmativeActionButtonLabel: 'Delete',
      affirmativeActionButtonCallback: onDeleteConfirmedCallback,
    });
  }, [
    confirmationDialogBody,
    confirmationDialogTitle,
    onDeleteConfirmedCallback,
    openTypeToConfirmation,
    typeToConfirmInstructions,
    typeToConfirmValue,
  ]);

  return (
    <SettingCardRowWithButton
      title={cardTitle}
      body={cardBody}
      buttonLabel={cardButtonLabel}
      handleButtonClickCallback={openTypeToConfirmDialog}
      color={'error'}
      loading={isLoading}
    />
  );
};
