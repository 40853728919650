import { ServerDelta } from '@outbound/types';
import { BaseModel } from '../../base-model';
import { diffModels } from '../model-differ/model-differ';
import ModelDifference from '../model-differ/model-difference.type';

export const simulatePatch = (
  current: BaseModel,
  incoming: BaseModel,
  updatedAtTimestamp: string,
  clientId: string
): Array<ServerDelta> => {
  const patch: Array<ServerDelta> = [];

  const diff: ModelDifference = diffModels(current, incoming);

  for (const path in diff.added) {
    patch.push({
      id: current.id,
      objectDomain: current.objectDomain,
      obrn: current.obrn,
      op: 'add',
      value: diff.added[path],
      objectSchemaVersion: current.objectVersion,
      object: current.object,
      serverTimestamp: updatedAtTimestamp,
      path,
      clientId,
      clientUpdateId: '',
      clientTimestamp: '',
    });
  }

  /**
   * For each update we need to create a Delta that
   * will be able to be applied to the model to create the
   * desired state.
   *
   * This should handle both simple attribute updates as well
   * as nested maps. We purposely do not handle nested arrays due
   * to the added complexity of having to determine the position.
   * For now we just ensure all of our nested collections are modeled
   * as maps and convert to lists for access.
   *
   * For Nested Map Structure we expect the path to be the path to the nested object in the following format:
   * '/${attributeNameOfMap}/${encodeURIComponent(mapKey)}/${attributeNameOfNestedObject}...'
   * For example:
   * Saw we have a nested object in the 'latestHealthCheckItemEvaluations' map with the key 'obrn:ob:workspace-1:integration-configuration/health-check-item-execution:health-check-item-1'
   * and we want to update the 'name' attribute of that object. The path would be:
   * '/latestHealthCheckItemEvaluations/obrn%3Aob%3Aworkspace-1%3Aintegration-configuration%2Fhealth-check-item-execution%3Ahealth-check-item-0/name'
   * 

   */
  for (const path in diff.updated) {
    patch.push({
      id: current.id,
      objectDomain: current.objectDomain,
      obrn: current.obrn,
      op: 'replace',
      value: diff.updated[path].newValue,
      objectSchemaVersion: current.objectVersion,
      object: current.object,
      serverTimestamp: updatedAtTimestamp,
      path,
      clientId,
      clientUpdateId: '',
      clientTimestamp: '',
    });
  }

  for (const path in diff.removed) {
    patch.push({
      id: current.id,
      objectDomain: current.objectDomain,
      obrn: current.obrn,
      op: 'remove',
      value: undefined,
      objectSchemaVersion: current.objectVersion,
      object: current.object,
      serverTimestamp: updatedAtTimestamp,
      path,
      clientId,
      clientUpdateId: '',
      clientTimestamp: '',
    });
  }

  return patch;
};
