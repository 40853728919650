/**
 * This component provides a button that when clicked shows a processing or "thinking" indicator.
 * It should be used for interactions that kick off some asynchronous process that should block the
 * user interface. The button also communicates success and error states based on the resolution or
 * rejection of a promise.
 */

'use client';
import React, { FC, useState } from 'react';
import { ObButton, ObButtonProps, ObIcon } from '../../../';

export type ObThinkingButtonProps = Omit<
  ObButtonProps,
  'onClick' | 'children'
> & {
  /**
   *  A function to call when the button is clicked
   */
  onClickCallback?: () => Promise<any>;
  /**
   *  For development and documentation purposes. Forces the button to a specific state.
   */
  forcedState?: ObThinkingStatus; //Intended for development and documentation purposes
};

export enum ObThinkingStatus {
  READY = 'READY',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  ERROR = 'ERROR',
}

const ObThinkingButton: FC<ObThinkingButtonProps> = ({
  label,
  variant: style = 'primary',

  onClickCallback,
  forcedState,
  size = 'large',
  fullWidth,
  disabled,
  ...props
}: ObThinkingButtonProps) => {
  const [currentStatusState, setCurrentStatusState] = useState(
    ObThinkingStatus.READY
  );

  const currentStatus = forcedState ?? currentStatusState;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClickCallback != null) {
      e.preventDefault(); //<-- WILL PREVENT FORMS FROM SUBMITTING!
      setCurrentStatusState(ObThinkingStatus.PENDING);
      onClickCallback().then(
        () => {
          setCurrentStatusState(ObThinkingStatus.RESOLVED);
          resetButton();
        },
        (error) => {
          if (error != null) {
            console.error(error);
          }
          setCurrentStatusState(ObThinkingStatus.ERROR);
          resetButton();
        }
      );
    }
  };

  const resetButton = () => {
    setTimeout(() => {
      setCurrentStatusState(ObThinkingStatus.READY);
    }, 2000);
  };

  return (
    <ObButton
      {...(onClickCallback !== null ? { onClick: handleClick } : {})}
      variant={style}
      size={size}
      fullWidth={fullWidth}
      iconLeftClassName={
        currentStatus == ObThinkingStatus.READY ? '' : 'opacity-0'
      }
      disabled={currentStatus !== ObThinkingStatus.READY || disabled}
      {...props}
    >
      {
        <div
          className={currentStatus == ObThinkingStatus.READY ? '' : 'opacity-0'}
        >
          {label}
        </div>
      }
      {currentStatus == ObThinkingStatus.PENDING && (
        <div
          role='progressbar'
          className='flex justify-center items-center m-auto w-full min-w-[48px] absolute'
        >
          <ObIcon
            icon='loadingSpinner'
            size='small'
            classNames='animate-spin text-contentPrimaryDark'
          />
        </div>
      )}
      {currentStatus == ObThinkingStatus.RESOLVED && (
        <div
          className='flex justify-center items-center m-auto w-full min-w-[48px] absolute  text-contentPrimaryDark'
          data-testid='success'
        >
          <ObIcon
            icon='complete'
            size='small'
            color='inherit'
          ></ObIcon>
        </div>
      )}
      {currentStatus == ObThinkingStatus.ERROR && (
        <div
          className='flex justify-center items-center m-auto w-full min-w-[48px] absolute  text-contentPrimaryDark'
          data-testid='error'
        >
          <ObIcon
            icon='close'
            size='small'
            color='inherit'
          ></ObIcon>
        </div>
      )}
    </ObButton>
  );
};

export { ObThinkingButton };
