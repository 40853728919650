'use client';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObDialogFooter } from '../../molecules/ob-dialog-footer/ob-dialog-footer';
import { ObDialogHeader } from '../../molecules/ob-dialog-header/ob-dialog-header';

export interface ObDialogProps {
  children?: React.ReactNode;
  title: string;
  primaryFooterActionLabel?: string;
  secondaryFooterActionLabel?: string;
  onPrimaryActionCallback: () => void;
  onSecondaryActionCallback: () => void;
  hideDefaultActions?: boolean;
  hasCloseButton?: boolean;
  hideTitle?: boolean;
}

export const ObDialog: React.FC<ObDialogProps> = ({
  children,
  title,
  hideTitle = false,
  primaryFooterActionLabel,
  secondaryFooterActionLabel,
  onPrimaryActionCallback,
  onSecondaryActionCallback,
  hideDefaultActions,
  hasCloseButton,
}: ObDialogProps) => {
  return (
    <div className='m-auto max-w-[640px]  md:max-w-[720px] dark:bg-dark/background/surface border dark:border-dark/border/default/normal rounded-lg p-4 sm:p-5 relative'>
      {!hideTitle && <ObDialogHeader title={title} />}

      {hasCloseButton && (
        <div className='absolute top-4 right-4'>
          <ObButton
            variant='outline'
            buttonType='icon'
            iconLeft='close'
            size='small'
            onClick={onSecondaryActionCallback}
          />
        </div>
      )}

      <div className=''>{children}</div>

      {!hideDefaultActions && (
        <ObDialogFooter
          affirmativeActionLabel={primaryFooterActionLabel}
          dismissiveActionLabel={secondaryFooterActionLabel}
          affirmativeActionCallback={onPrimaryActionCallback}
          dismissiveActionCallback={onSecondaryActionCallback}
        />
      )}
    </div>
  );
};
