export interface ObMetricCardSurfaceProps {
  children?: React.ReactNode;
}
export const ObMetricCardSurface = ({ children }: ObMetricCardSurfaceProps) => {
  return (
    <div className='rounded-lg bg-bgSurfaceDark border border-borderDefaultNormalDark p-4 sm:p-6 flex flex-col w-full'>
      {children}
    </div>
  );
};
