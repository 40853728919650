import { BaseApiResource } from '../core/api';
import { TargetingNode } from '../core/rule-builder/rule-builder.type';
import { CreativeResource } from '../creative-manager/creative-manager.api.type';
import {
  PhysicalLocationResource,
  ServiceAreaResource,
} from '../playbook/location/location.type';
import {
  PersonaResource,
  ServiceResource,
} from '../playbook/playbook.api.type';
import { CampaignChannel, CampaignChannelType } from './CampaignChannelType';
import { CampaignCreativeStatus } from './CampaignCreativeStatus';
import { CampaignDeploymentStatusType } from './CampaignDeploymentStatusType';
import { CampaignDestinationType } from './CampaignDestinationType';
import { CampaignFrequencyType } from './CampaignFrequencyType';
import { CampaignScheduledSettingsType } from './CampaignScheduledSettingsType';
import { CampaignLifecycleStatus } from './CampaignStatusType';
import { CampaignHighlightObjectType } from './campaign-highlight-type';
import { CampaignPausedByUserState } from './campaign-paused-by-user-state.type';
import { CampaignServingStateReason } from './campaign-serving-state-reason.type';
import { CampaignServingState } from './campaign-serving-state.type';

export type CreateCampaignRequestResource = Omit<
  CampaignResource,
  | 'id'
  | 'status'
  | 'workspaceId'
  | 'createdAtTimestamp'
  | 'updatedAtTimestamp'
  | 'budget'
  | 'highlights'
  | 'obrn'
  | 'targeting'
  | 'creative'
  | 'scheduledSettings'
  | 'metrics'
  | 'campaignSettingValues'
  | 'latestSubmittedCampaignSettingsHash'
  | 'initialPlaybookContext'
  | 'estimatedROI'
  | 'servingState'
  | 'servingStateReason'
  | 'pausedByUserState'
  | 'latestDeployment'
  | 'lastSyncedFromAdChannelTimestamp'
  | 'provisionedCampaignChannelId'
> & {
  personaIds: string[];
  serviceIds: string[];
  physicalLocationIds: string[];
  serviceAreaIds: string[];
};

/**
 * This is the request body for the POST /campaign-recommendations endpoint
 */
export type PostRecommendationRequestResource = {
  personaIds: string[];
  serviceIds: string[];
  physicalLocationIds?: string[];
  serviceAreaIds?: string[];

  numberOfRecommendations?: number;
  /**
   * A user provided value that indicates the daily budget they have in mind.
   * We should return this value in the response so we can preset the value of the slider in the UI.
   */
  selectedDailyBudget?: number;
  /**
   * Possible that the frontend should not provide this and we should pull this from internal data
   */
  minBudget: number;
  maxBudget: number;
};

export type CreateStrategyResource = Omit<
  StrategyResource,
  'strategyId' | 'phases'
> & {
  phases: { campaignId: string; type: PhaseGoal }[];
  workspaceId: string;
  recommendationId: string;
};

/**
 
 * CPC: Cost per click <- What each click costs
 * CPA: Cost per acquisition <- What each conversion costs
 * CPM: Cost per thousand impressions <- What each 1000 impressions costs
 * CPL: Cost per lead <- What each lead costs. (CPC * conversion rate of forms or calls = CPL)
 */
export type SuccessMetricType = 'CPC' | 'CPA' | 'CPM' | 'CPL';

export interface RecommendationResource {
  id: string;
  selectedDailyBudget: number;
  minBudget: number;
  maxBudget: number;
  strategies: Array<StrategyResource>;
}

export type StrategyResource = {
  /**
   * An UUID that uniquely identifies the strategy
   */
  strategyId: string;

  /**
   * The name of the strategy that is suitable for display to the user
   */
  name: string;

  /**
   * The phases that make up the strategy
   * Each phase should have a campaign associated with it.
   * Phases can run concurrently or sequentially.
   */
  phases: PhaseResource[];

  /**
   * The minimum budget that this strategy can run on
   */
  minBudget: number;
  /**
   * The maximum budget that this strategy is recommended to run on
   */
  maxBudget: number;

  /**
   * The primary success metric for the strategy
   */
  successMetricType: SuccessMetricType;
  /**
   * The unit cost for the success metric.
   * This value should be able to be used to estimate the performance of the strategy given a budget.
   *
   * For example, if the success metric is CPA and the successMetricUnitCost is 10.00 than we can estimate that
   * a budget of 100.00 would result in 10 conversions.
   *
   * This value is based on market data and assumptions and should be presented as an estimate, not a guarantee.
   */
  successMetricUnitCost: number;
};

export type PhaseResource = {
  campaign?: CampaignResource;
  type?: PhaseGoal;
};

export enum PhaseGoal {
  HIGH_QUALITY_LEADS = 'HIGH_QUALITY_LEADS',
  RETARGETING = 'RETARGETING',
  BRAND_AWARENESS = 'BRAND_AWARENESS',
}

/**
 * Temp function to get the display name of a phase goal.
 * This will need to be replaced with a more robust solution in the future that
 * deals with localization and internationalization.
 * @param goal
 * @returns
 */
export const getPhaseGoalDisplayName = (goal: PhaseGoal): string => {
  switch (goal) {
    case PhaseGoal.HIGH_QUALITY_LEADS:
      return 'Lead Generation';
    case PhaseGoal.RETARGETING:
      return 'Re-Targeting';
    case PhaseGoal.BRAND_AWARENESS:
      return 'Brand Awareness';
  }
};

export interface CampaignHighlightResource {
  /**
   * Unique identifier for the highlight
   */
  id: string;
  /**
   * The workspace id that the highlight belongs to
   */
  workspaceId: string;

  /**
   * The Outbound Resource Name of the highlight
   */
  obrn: string;

  /**
   * The type of the highlight
   */
  highlightType: CampaignHighlightObjectType;

  /**
   * The Outbound Resource Name of the object that the highlight is associated with
   */
  highlightObjectObrn: string;

  /**
   * The creative id that is associated with the highlight
   */
  creativeId: string;

  /**
   * Optional property that is used when multiple slots are assigned to the same creative.
   * This provides the consumer an ability to directly or "deep" link to a specific view in the creative.
   *
   * An example of this would be a carousel ad where the deep link would be the id of the carousel item
   */
  creativeDeepLink?: string;

  /**
   * The unique identifier of the landing page that this highlight will link to.
   * This is optional as some campaign types may not have a landing pages.
   */
  landingPageId?: string;

  /**
   * Identifies if the highlight is currently enabled or not.
   */
  isEnabled: boolean;
}

export interface CampaignHighlightWithCreativeResource
  extends CampaignHighlightResource {
  creative: CreativeResource;
}

export interface CampaignResource extends BaseApiResource {
  workspaceId: string;
  budget: CampaignBudgetResource;
  targeting?: TargetingResource;
  channelType: CampaignChannelType | CampaignChannel;
  outboundCampaignGoal: OutboundCampaignGoal;
  description?: string;
  name: string;
  highlights: Array<CampaignHighlightResource>;
  scheduledSettings?: CampaignScheduledSettingsResource;
  /**
   * lifecycle status of the campaign
   * used internally to track if the campaign is initialized, active or archived.
   */
  status: CampaignLifecycleStatus;

  /**
   * Indicates if the campaign is currently running Ads or not.
   */
  servingState: CampaignServingState;
  /**
   * Indicates the reason that Ads are not running.
   */
  servingStateReason: CampaignServingStateReason;

  /**
   * Indicates if a user want this campaign to serve ads or not.
   * This value is synced with the channel and can be set from Outbound or from the Ad Channel.
   */
  pausedByUserState: CampaignPausedByUserState;

  /**
   * Updated each time the Ad Channel ETL process is run.
   * Undefined means that the process has never been run.
   */
  lastSyncedFromAdChannelTimestamp?: string;

  /**
   * The Unique Identifier of this Campaign withing the Ad Channel
   */
  provisionedCampaignChannelId?: string;

  metrics?: CampaignMetricsResource;
  latestSubmittedCampaignSettingsHash?: string;
  recommended?: boolean;
  createdAtTimestamp: string;
  updatedAtTimestamp: string;
  campaignSettingValues: Array<CampaignSettingValueResource>;
  initialPlaybookContext?: {
    personas: Array<PersonaResource>;
    services: Array<ServiceResource>;
    serviceAreas: Array<ServiceAreaResource>;
    physicalLocations: Array<PhysicalLocationResource>;
  };
  latestDeployment?: CampaignDeploymentResource;
}

export interface CampaignResourceWithCreative extends CampaignResource {
  highlights: Array<CampaignHighlightWithCreativeResource>;
}

export interface TargetingResource {
  isComplete: boolean;
  rules?: TargetingNode;
  recommendedKeywords?: KeywordResource[];
}

export interface KeywordResource {
  value: string;
  avgMonthlySearches?: number;
  lowBidRange?: number;
  highBidRange?: number;
}

export interface PatchCampaignRequestResource {
  name?: string;
  budget: Omit<CampaignBudgetResource, 'isComplete'>;
  targeting: Omit<TargetingResource, 'isComplete'>;
  scheduledSettings: {
    startDate: string;
    endDate?: string;
    startTime?: string;
    endTime?: string;
  };
  pausedByUserState: CampaignPausedByUserState;
}

export interface PatchCampaignSettingsRequestResource {
  /**
   * A partial set of the campaign settings. You can / should include only those that we want to update
   */
  campaignSettingValues: Array<CampaignSettingValueResource>;
}

export interface CampaignBudgetResource {
  frequency: CampaignFrequencyType;
  amount: number;
}

export interface CampaignScheduledSettingsResource {
  startDate: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  selectTimeOfDay: boolean;
  type: CampaignScheduledSettingsType;
}

export interface CampaignCreativeResource {
  id: string;
  isComplete: boolean;
  status: CampaignCreativeStatus;
}

export interface CampaignMetricsResource {
  impressions: number;
  clicks: number;
  conversions: number;
}

export interface CampaignDestinationResource {
  type: CampaignDestinationType;
  landingPageConfiguration: {};
  externalUrlConfiguration: {
    externalUrl?: string;
  };
}

/**
 * API resource the describes the VALUE of a campaign setting field.
 
 *
 * Example:
 */
export type CampaignSettingValueResource = {
  id: string;

  type: 'string' | 'number' | 'boolean';

  /**
   * The current value of the field.
   */
  value: string | number | boolean;

  /**
   * Indicates the last time this value was updated.
   * This value will be used in last update wins conflict resolution
   */
  updatedAtTimestamp: string;
};

export const CampaignCreativeSlotTypeValues = [
  //Indicates that the creative is for the default campaign landing page
  'LANDING_PAGE_PRIMARY',
  //What Page Top Section for Service Lead Gen Ads
  'AD_SERVICE',
  // Specific creatives for the sub services section of the what page
  'ADS_SUB_SERVICES',
] as const;
export type CampaignCreativeSlotType =
  (typeof CampaignCreativeSlotTypeValues)[number];

/**
 * Outbound Campaign Goal Types
 * These are the possible things we are trying to achieve with a campaign in the Outbound System
 * These are more business user facing and less pure marketing terms
 */
export const OutboundCampaignGoalValues = [
  /**
   * Indicates that the campaigns primary goal is Lead Generation for a service
   */
  'LEAD_GEN_FOR_SERVICE',
  /**
   * Indicates that the campaigns primary goal is General Brand Awareness
   */
  'BRAND_AWARENESS',
] as const;
export type OutboundCampaignGoal = (typeof OutboundCampaignGoalValues)[number];

export const EstimateROISuccessMetricTypeValues = [
  'LEADS',
  'IMPRESSIONS',
] as const;
export type EstimateROISuccessMetricType =
  (typeof EstimateROISuccessMetricTypeValues)[number];

export const EstimateROISuccessMetricPeriodValues = ['MONTHLY'] as const;
export type EstimateROISuccessMetricPeriod =
  (typeof EstimateROISuccessMetricPeriodValues)[number];

export interface PostCampaignEstimateROIRequestResource {
  budget: number;
}

export interface PostLatestChannelDataRequestResource {
  channelType: CampaignChannel;
}

export interface CampaignEstimateROIResource {
  dailyBudget: number; // submitted budget in dollars
  minRecommendedDailyBudget: number; // Minimum recommended budget in dollars based on formula for channel type
  maxRecommendedDailyBudget: number; // Maximum recommended budget in dollars based on formula for channel type
  primarySuccessMetric: number; // Quantity of primary success metric
  primarySuccessMetricType: EstimateROISuccessMetricType; // Type of primary success metric
  primarySuccessMetricPeriod: EstimateROISuccessMetricPeriod; // Period of primary success metric
  methodology: string; // Type of ROI calculation methodology
}

/**
 * Response Body Type for the POST /campaigns/:campaignId/estimate-roi endpoint
 */
export interface CampaignEstimateROIResponseResource {
  /**
   * Server Timestamp of when the ROI was calculated
   */
  calculatedAtTimestamp: string;
  /**
   * The estimated ROI metrics for the campaign
   */
  estimatedROIs: Array<CampaignEstimateROIResource>;
}

export interface CampaignDeploymentResource extends BaseApiResource {
  status: CampaignDeploymentStatusType;
  campaignId: string;
  versionTimestamp: string;
  rejectionReasons?: string[];
  createdAtTimestamp: string;
  updatedAtTimestamp: string;
}
