import {
  ObButton,
  ObLoadingSpinner,
  ObNoContentCta,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import { LocationModelCard } from '../../../../../../object-card/location/Location';
import { useRootStore } from '../../../../../../state/mobx-experiment/use-root-store';
import { PlaybookCardAddNewButton } from '../../_common/playbook-card-add-new-button';
import { PlaybookCardButtonWrapper } from '../../_common/playbook-card-button-wrapper';
import { PlaybookCardGrid } from '../../_common/playbook-card-grid';
import { PlaybookPageSectionHeading } from '../../_common/playbook-page-section-heading';
import { useServiceAreaCreateDrawer } from '../drawer/service-area-create-drawer';
import { useServiceAreaEditDrawer } from '../drawer/service-area-edit-drawer';
import { LOCATIONS_PAGE_DEF } from '../locations.def';

export interface ServiceAreaSectionProps {
  hideHeader?: boolean;
}

export const ServiceAreaSection = observer(
  ({ hideHeader = false }: ServiceAreaSectionProps) => {
    const { locationStore } = useRootStore();
    const serviceAreas = locationStore.getServiceAreas();

    const { openEditLocationDrawer } = useServiceAreaEditDrawer();
    const { openNewLocationDrawer } = useServiceAreaCreateDrawer();

    const atLeastOneServiceAreaExists = (serviceAreas?.length ?? 0) > 0;

    const noServiceAreasExist = serviceAreas?.length === 0;

    return (
      <section className='flex flex-col gap-4 w-full'>
        {!hideHeader && (
          <PlaybookPageSectionHeading
            title={'Service Areas'}
            controls={
              <ObButton
                size='medium'
                variant='outline'
                iconLeft='plus'
                buttonType='icon'
                label='New Service Area'
                onClick={openNewLocationDrawer}
              />
            }
          />
        )}
        {atLeastOneServiceAreaExists && (
          <PlaybookCardGrid>
            {serviceAreas.map((serviceArea) => (
              <PlaybookCardButtonWrapper
                key={serviceArea.id}
                label={`View ${serviceArea.name} details`}
                onClickCallback={() => openEditLocationDrawer(serviceArea.id)}
              >
                <Suspense
                  fallback={
                    <div className='flex justify-center items-center flex-1'>
                      <ObLoadingSpinner includeBrand={true} />
                    </div>
                  }
                >
                  <LocationModelCard
                    variant='grid'
                    location={serviceArea}
                  />
                </Suspense>
              </PlaybookCardButtonWrapper>
            ))}
            <PlaybookCardButtonWrapper
              className='h-[200px]'
              onClickCallback={openNewLocationDrawer}
              label={'Create New Service Area'}
            >
              <PlaybookCardAddNewButton label='Create New Service Area' />
            </PlaybookCardButtonWrapper>
          </PlaybookCardGrid>
        )}
        {noServiceAreasExist && (
          <ObNoContentCta
            includeGradient={true}
            title={'Setup Your First Service Area'}
            description={
              'Service areas define where your services are available.'
            }
            buttonText={'Create Service Area'}
            iconName={LOCATIONS_PAGE_DEF.icon}
            onButtonClick={openNewLocationDrawer}
          />
        )}
      </section>
    );
  }
);
