import { ISourceOptions } from 'tsparticles-engine';

export const generateParticlesConfigForButton = (
  color: string = '#00E9FC'
): ISourceOptions => {
  return {
    fullScreen: false,
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: 'push',
        },
        onHover: {
          enable: true,
          mode: 'attract',
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 8,
        },
        repulse: {
          distance: 50,
          duration: 0.4,
        },
      },
    },
    particles: {
      opacity: {
        value: {
          min: 0.25,
          max: 0.75,
        },
      },
      color: {
        value: color,
      },
      move: {
        direction: 'none',
        enable: true,
        out_mode: 'out',

        random: true,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 200,
        },
        value: 70,
      },
      shape: {
        type: 'circle',
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };
};
