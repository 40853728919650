import { useDialogService } from '@outbound/design-system';
import { useCreateService } from '../../../../../../query/playbook/use-playbook-settings-service-endpoints';
import { CREATE_SERVICE_MINI_FORM_DEF } from '../forms/service-form.def';

export const useCreateServiceDialog = () => {
  const { openFormDialog } = useDialogService();
  const { mutateAsync: createService } = useCreateService();

  /**
   * Opens a Dialog to Create a new Service and persists the data to the backend
   */
  const openCreateServiceDialog = (): Promise<{ id: string }> => {
    return openFormDialog({
      formDefinition: CREATE_SERVICE_MINI_FORM_DEF,
      title: 'Create Service',
      onFormSubmitCallback: async (values: { name: string }) => {
        const result = await createService({
          values: values,
        });
        console.log(result);
        return result;
      },
    });
  };
  return { openCreateServiceDialog };
};
