import { RadioGroup } from '@headlessui/react';
import { cva } from 'class-variance-authority';
import { AvatarComponent } from '../../../avatars/avatar.types';
import { StateManagedByParentInput } from '../../../base-component-props.type';
import { ObRadioGroupItem } from './components/ob-radio-group-item';

export interface ObRadioOption {
  value: string;
  label: string;
  description: string;
  avatarContent?: AvatarComponent;
}

export interface ObRadioGroupProps extends StateManagedByParentInput<string> {
  options: Array<ObRadioOption>;
  isFieldDisabled: boolean;
  size?: 'small' | 'medium';
}

const itemContainerStyles = cva('cursor-pointer  flex flex-col', {
  variants: {
    size: {
      medium: 'gap-2',
      small: 'gap-1',
    },
  },
});

export const ObRadioGroup = ({
  options = [],
  isFieldDisabled,
  isLoading,
  value,
  size = 'medium',
  onValueChangedCallback,
}: ObRadioGroupProps) => {
  // Loading State
  if (isLoading) {
    return (
      // Show an indeterminate loading state
      <div
        className='w-full'
        role='progressbar'
      >
        {options.map((option) => (
          <ObRadioGroupItem
            key={option.value}
            isLoading={true}
            value={option.value}
            label=''
            description={''}
            isDisabled={false}
            size={size}
          />
        ))}
      </div>
    );
  }

  return (
    <RadioGroup
      value={value}
      disabled={isFieldDisabled}
      onChange={(value) => onValueChangedCallback(value, undefined)}
      className={itemContainerStyles({ size })}
    >
      {options.map((option) => (
        <ObRadioGroupItem
          key={option.value}
          value={option.value}
          label={option.label}
          description={option.description}
          isDisabled={isFieldDisabled}
          size={size}
          avatarContent={option.avatarContent}
          isLoading={false}
        />
      ))}
    </RadioGroup>
  );
};
