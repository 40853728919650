import {
  FormDefinition,
  FormFieldType,
  FormFieldTypeSettingsSchema,
} from '@outbound/design-system';
import { MockFileUploadHandler } from '@outbound/design-system/src/utilities/file-upload';
import { AssetResource } from '@outbound/types';
import { useAssetManagerUploadHandler } from '../../../../../../../../hooks/use-asset-manager-upload-handler';
import { useAssetDetailDrawerHook } from '../../../../../../../asset-manager/asset-detail-drawer/use-asset-detail-drawer-hook';

export const SUB_SERVICE_FORM_DEF: FormDefinition = {
  id: 'sub-service-form',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'name',
          label: 'Name',
          helperText: '',
          fieldTypeSettings: {},
          autofocus: true,
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'description',
          label: 'Short Description',
          helperText: '',
          type: FormFieldType.TEXT_AREA,
          fieldTypeSettings: {},
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'keywords',
      heading: 'Keywords',
      subHeading: '',
      fields: [
        {
          id: 'relatedKeywords',
          label: 'Keywords',
          helperText:
            'What are some words or phrases a customer might search for to find this service?',
          fieldTypeSettings: {},
          type: FormFieldType.CHIPS,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'photos',
      heading: 'Related Photos',
      subHeading: '',
      fields: [
        {
          id: 'photos',
          label: 'Photos',
          helperText:
            'Upload photos related to this subservice. Outbound uses these images as a starting point to generate creative for your marketing materials.',
          fieldTypeSettings: {
            fileUploadHandlerFactory: {
              createFileUploadHandler: () => {
                return new MockFileUploadHandler();
              },
            },
          },
          type: FormFieldType.PHOTO_TILES,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
  ],
  heading: '',
  subHeading: '',
};

/**
 * This hook is used to add the asset manager file uploader to the form definition
 * @param formDefinition
 */
export const useFormWithAssetManagerFileUploader = (
  formDefinition: FormDefinition
): { formDefinitionWithAssetManager: FormDefinition } => {
  if (!formDefinition) {
    throw new Error('Form definition is required');
  }
  const duplicatedFormDefinition = JSON.parse(JSON.stringify(formDefinition));
  const assetManagerFileUploader = useAssetManagerUploadHandler();
  const { openAssetDetailDrawer } = useAssetDetailDrawerHook();
  /**
   * Dynamically set the file upload handler for the images fields
   */
  for (const section of duplicatedFormDefinition.sections) {
    for (const field of section.fields) {
      if (field.type === FormFieldType.IMAGES) {
        const fieldSettings =
          field.fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.IMAGES>;
        fieldSettings.fileUploadHandlerFactory = {
          createFileUploadHandler: () => {
            return assetManagerFileUploader;
          },
        };
      }
      if (field.type === FormFieldType.PHOTO_TILES) {
        const fieldSettings =
          field.fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.PHOTO_TILES>;
        fieldSettings.onAssetDetailsClick = (
          assetId: string,
          onAssetUpdated?: (updatedAsset: Partial<AssetResource>) => void
        ) => {
          return openAssetDetailDrawer(assetId, onAssetUpdated);
        };
        fieldSettings.fileUploadHandlerFactory = {
          createFileUploadHandler: () => {
            return assetManagerFileUploader;
          },
        };
      }
    }
  }
  return {
    formDefinitionWithAssetManager: duplicatedFormDefinition,
  };
};
