import { ObIcon, ObTypography } from '@outbound/design-system';
import { formatCurrency } from '@outbound/design-system/src/utilities/format-utilities';
import { AdvertisingBudgetDetails } from './advertising-budget-details';
import { OutboundMembershipPlanType } from '@outbound/types';

interface PricingMembershipProps {
  membership: string;
  adBudget: number;
}

interface MembershipDetail {
  id: string;
  description: string;
}

interface Membership {
  id: string;
  price: number;
  details: MembershipDetail[];
}

export const OUTBOUND_MEMBERSHIP_DETAILS: Membership[] = [
  {
    id: OutboundMembershipPlanType.OUTBOUND_STARTER_PLAN,
    price: 99,
    details: [
      {
        id: 'detail-1',
        description: 'Up to $1000 of Ad Spend Per Month',
      },
      {
        id: 'detail-2',
        description: 'Setup and Management of Ad Accounts for All Channels',
      },
      {
        id: 'detail-3',
        description: 'Consolidated Marketing billing',
      },
      {
        id: 'detail-4',
        description: 'Automated Creation and Publishing of Ads to all Channels',
      },
      {
        id: 'detail-5',
        description: 'Unlimited Customized Landing Pages',
      },
      {
        id: 'detail-6',
        description: 'Lead Tracking and Ranking',
      },
      {
        id: 'detail-7',
        description: 'Ongoing Ad Optimization & Experimentation',
      },
    ],
  },
  {
    id: OutboundMembershipPlanType.OUTBOUND_GROWTH_PLAN,
    price: 299,
    details: [
      {
        id: 'detail-1',
        description: 'Up to $3000 of Ad Spend Per Month',
      },
      {
        id: 'detail-2',
        description: 'Setup and Management of Ad Accounts for All Channels',
      },
      {
        id: 'detail-3',
        description: 'Consolidated Marketing billing',
      },
      {
        id: 'detail-4',
        description: 'Automated Creation and Publishing of Ads to all Channels',
      },
      {
        id: 'detail-5',
        description: 'Unlimited Customized Landing Pages',
      },
      {
        id: 'detail-6',
        description: 'Lead Tracking and Ranking',
      },
      {
        id: 'detail-7',
        description: 'Ongoing Ad Optimization & Experimentation',
      },
    ],
  },
  {
    id: OutboundMembershipPlanType.OUTBOUND_PROFESSIONAL_PLAN,
    price: 499,
    details: [
      {
        id: 'detail-1',
        description: 'Up to $5000 of Ad Spend Per Month',
      },
      {
        id: 'detail-2',
        description: 'Setup and Management of Ad Accounts for All Channels',
      },
      {
        id: 'detail-3',
        description: 'Consolidated Marketing billing',
      },
      {
        id: 'detail-4',
        description: 'Automated Creation and Publishing of Ads to all Channels',
      },
      {
        id: 'detail-5',
        // Add missing id attribute here
        description: 'Unlimited Customized Landing Pages',
      },
      {
        id: 'detail-6',
        // Add missing id attribute here
        description: 'Lead Tracking and Ranking',
      },
      {
        id: 'detail-7',
        // Add missing id attribute here
        description: 'Ongoing Ad Optimization & Experimentation',
      },
    ],
  },
];

export const PricingMembership = ({
  membership,
  adBudget,
}: PricingMembershipProps) => {
  const membershipDetails = OUTBOUND_MEMBERSHIP_DETAILS.find(
    (membershipDetail) => membershipDetail.id === membership
  );

  return (
    <div className='flex flex-col p-6 h-full'>
      <section className='dark:border-whiteAlpha-2 rounded-lg dark:bg-bgSurfaceDark'>
        <div className='flex flex-col gap-3'>
          <div className='flex flex-row justify-between items-center'>
            <ObTypography
              variant='h4'
              color='primaryV2'
            >
              Outbound.com Membership
            </ObTypography>
            <ObTypography
              variant='body1'
              color='primary'
            >
              {formatCurrency(membershipDetails?.price as number)}
            </ObTypography>
          </div>

          {membershipDetails?.details.map((detail) => {
            return (
              <div
                key={detail.id}
                className='flex flex-row justify-between items-center ml-4'
              >
                <div className='flex flex-row items-center'>
                  <ObIcon
                    icon='check'
                    size='small'
                    color='positive'
                  />
                  <div className='text-sm text-whiteAlpha-8 pl-2'>
                    <ObTypography>{detail.description}</ObTypography>
                  </div>
                </div>
              </div>
            );
          })}
          <AdvertisingBudgetDetails adBudget={adBudget} />
          <div className='divider'></div>
        </div>
        <div className='flex flex-row justify-between items-center pt-3'>
          <ObTypography
            variant='h3'
            color='primary'
          >
            Todays Total
          </ObTypography>
          <ObTypography
            variant='body1'
            color='positive'
          >
            {formatCurrency(membershipDetails?.price as number)}
          </ObTypography>
        </div>
      </section>
    </div>
  );
};
