import { stringHasContent } from '@otbnd/utils';
import {
  NavItem,
  NavItemWithAbsolutePath,
  WorkspaceNavigationMenuLocation,
} from '../components/navigation/dashboard-route.type';

export const filterOutUnreleasedNavItems = (
  navItems: NavItemWithAbsolutePath[],
  flags: Record<string, boolean>
) => {
  return navItems.filter((item) => {
    if (stringHasContent(item.featureFlag)) {
      return flags[item.featureFlag!]; //We know this is defined based on the has content check
    } else {
      return true;
    }
  });
};

export const flattenNavItemsToArray = (navItem: NavItem): NavItem[] => {
  const array = [navItem];
  if (navItem.childRoutes) {
    navItem.childRoutes.forEach((child) => {
      array.push(...flattenNavItemsToArray(child));
    });
  }
  return array;
};

export const flattenNavItemsWithAbsolutePathsToArray = (
  navItem: NavItemWithAbsolutePath
): NavItemWithAbsolutePath[] => {
  const array = [navItem];
  if (navItem.childRoutes) {
    navItem.childRoutes.forEach((child) => {
      array.push(...flattenNavItemsWithAbsolutePathsToArray(child));
    });
  }
  return array;
};

export const getRoutesForNavMenuLocation = (
  rootNavItem: NavItem,
  navMenuLocation: WorkspaceNavigationMenuLocation
) => {
  const absoluteRootNavItem = decorateNavTreeWithAbsoluteRoutes(rootNavItem);
  return flattenNavItemsWithAbsolutePathsToArray(absoluteRootNavItem).filter(
    (r) => r.showInNavMenu?.includes(navMenuLocation)
  );
};

export const decorateNavTreeWithAbsoluteRoutes = (
  rootNavItem: NavItem
): NavItemWithAbsolutePath => {
  const decorateNavItem = (
    navItem: NavItem,
    parentAbsolutePath: string
  ): NavItemWithAbsolutePath => {
    let absolutePath = '';
    if (navItem.route.startsWith(':')) {
      if (navItem.route === ':workspaceSlug') {
        absolutePath = '';
      }
    } else {
      absolutePath = `${parentAbsolutePath}/${navItem.route}`;
    }

    //Strip Leading Slash from absolute path
    absolutePath = absolutePath.replace(/^\//, '');

    const decoratedNavItem: NavItemWithAbsolutePath = {
      ...navItem,
      absolutePath,
      childRoutes: navItem.childRoutes?.map((child) =>
        decorateNavItem(child, absolutePath)
      ),
    };

    return decoratedNavItem;
  };

  return decorateNavItem(rootNavItem, '');
};

/**
 * Traverse a NavItem tree to find a route by its absolute path.
 * Start reading the path from the root of the tree.
 * if you match the first segment of the path, look for the next segment in the childRoutes.
 */
export const findAppRouteByAbsolutePath = (
  absolutePath: string,
  rootNavItem: NavItem
): NavItem | null => {
  const pathSegments = absolutePath.split('/').filter(Boolean);

  let currentRoute = rootNavItem;
  let currentPathSegment = pathSegments.shift();

  if (currentRoute.route === currentPathSegment) {
    currentPathSegment = pathSegments.shift();
  }

  while (currentPathSegment) {
    const childRoute = currentRoute.childRoutes?.find(
      (r) => r.route === currentPathSegment
    );
    if (!childRoute) {
      return null;
    }

    currentRoute = childRoute;
    currentPathSegment = pathSegments.shift();
  }

  return currentRoute;
};
