import { ObLoadingSpinner, ObTypography } from '@outbound/design-system';
import { FC } from 'react';
import { useAuth0Extended } from '../../../auth/use-auth0-extended';
import { useFetchWorkspaceIdFromOnboardingById } from '../../../query/onboarding/use-onboarding-endpoints';
import { useWaitForWorkspace } from './use-wait-for-workspace';

export interface WorkspaceRedirectProps {
  brandOnboardingId: string;
  token: string;
}

export const WorkspaceRedirect: FC<WorkspaceRedirectProps> = ({
  brandOnboardingId,
  token,
}: WorkspaceRedirectProps) => {
  const { switchOrganization } = useAuth0Extended();

  const { data } = useFetchWorkspaceIdFromOnboardingById(
    brandOnboardingId,
    token
  );

  const { workspace } = useWaitForWorkspace(data?.workspaceId);

  if (workspace == null) {
    return (
      <section className='flex items-center justify-center flex-col'>
        <ObLoadingSpinner
          includeBrand={true}
          fadeIn={true}
        />
        <ObTypography variant='h3'>
          We are setting up your workspace
        </ObTypography>
      </section>
    );
  } else {
    /**
     * Here we are switching the workspace organization to the user's current tenant so when we fetch playbook data we get the correct workspace data
     * It is important to note that this logic will cause a redirect for authentication purposes.
     * The app should redirect the user back to /onboarding-wizard after the authentication process is complete and it will need to get the user back to the correct step in the wizard
     **/
    switchOrganization(workspace.auth0OrganizationId, workspace.id);

    return <></>;
  }
};
