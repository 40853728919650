export interface ObTableProps {
  children?: React.ReactNode;
}
export const ObTable = ({ children }: ObTableProps) => {
  return (
    <div className='-mx-4 sm:-mx-0'>
      <table className='min-w-full divide-y divide-borderDefaultNormalDark'>
        {children}
      </table>
    </div>
  );
};
