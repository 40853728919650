import { useCallback, useMemo } from 'react';
import {
  useFetchBusinessDetails,
  usePatchBusinessDetails,
} from '../../../../query/playbook/use-playbook-settings-business-details-endpoints';
import { OnboardingQuestion } from '../questionnaire.type';

export const useIndustryQuestion = (questions: Array<OnboardingQuestion>) => {
  const INDUSTRY_QUESTION_ID = 'industry';

  const { refetch: refetchCompanyDetails } = useFetchBusinessDetails();

  const { mutateAsync: patchBusinessDetails } = usePatchBusinessDetails();

  /**
   * Function that will persist the industry question
   * @param answers
   */
  const updatePlaybookIndustry = useCallback(
    async (answers: { [key: string]: any }) => {
      const industryAnswer = answers['industry']; //Lookup our answers by question id

      /**
       * Spread data from current call so we do not overwrite other data
       */
      const content = {
        industryId:
          industryAnswer != null && industryAnswer.length > 0
            ? industryAnswer[0]
            : undefined,
      };
      return patchBusinessDetails({ values: content });
    },
    [patchBusinessDetails]
  );

  useMemo(() => {
    //Grab the Industry Question from the Question Data
    const industryQuestion = questions.find(
      (q) => q.id === INDUSTRY_QUESTION_ID
    );
    //Add the Refetch Function
    industryQuestion!.fetchServerState = () => {
      return new Promise((resolve) => {
        refetchCompanyDetails().then((success: any) => {
          //Extract the Industry value from the playbook page so we can populate the form with it
          const industry =
            success.data?.industryId != null ? [success.data?.industryId] : [];
          resolve(industry);
        });
      });
    };
    industryQuestion!.submitServerState = updatePlaybookIndustry;
  }, [questions, refetchCompanyDetails, updatePlaybookIndustry]);

  return {};
};
