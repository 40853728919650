/**
 * Interface for standard JWT token claims
 */
export interface BaseJwtToken {
  iss: string;
  aud: string;
  sub: string;
  exp: number;
  iat: number;
}

export const getJwtTokenPayload = <T>(token: string): T => {
  if (token == null || token.trim().length === 0) {
    throw new Error('Token is required');
  }
  const tokenSegments = token.split('.');
  if (tokenSegments.length !== 3) {
    throw new Error(
      `Token is invalid found ${tokenSegments.length} segments, expected 3`
    );
  }
  const tokenPayload = tokenSegments[1];
  const paddedPayload = tokenPayload.padEnd(
    tokenPayload.length + ((4 - (tokenPayload.length % 4)) % 4),
    '='
  );

  const decodedTokenPayload = window.atob(paddedPayload);
  const parsedTokenPayload = JSON.parse(decodedTokenPayload);
  return parsedTokenPayload;
};
