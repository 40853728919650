import {
  ObCardOptionRadioGroup,
  ObCardOptionRadioGroupProps,
  ObThinkingButton,
  ObTypography,
} from '@outbound/design-system';
import { useCallback, useState } from 'react';
import { PricingMembership } from './components/pricing-membership';

import { Level } from '@outbound/design-system/src/tokens/colors/colors';
import { OutboundMembershipPlanType } from '@outbound/types';
import { useCreatePaymentCheckoutForUserMutation } from '../../../query/payment-manager/use-payment-manager-endpoints';

interface StepPricingProposalProps {
  adBudget: string;
}

type Option = ObCardOptionRadioGroupProps['value'];

export const OUTBOUND_PLAN_OPTIONS: Option[] = [
  {
    value: OutboundMembershipPlanType.OUTBOUND_STARTER_PLAN,
    title: '$99 per month',
    description: `Maximum Ad Spend:`,
    descriptionLine2: '$1000 per month',
    overline: 'Starter',
    badgeDescription: 'Great to Get Started',
  },
  {
    value: OutboundMembershipPlanType.OUTBOUND_GROWTH_PLAN,
    title: '$299 per month',
    description: 'Maximum Ad Spend:',
    descriptionLine2: '$3000 per month',
    overline: 'Growth',
    badgeDescription: 'Outbound Recommended',
    badgeIconLeft: 'checkBadge',
    badgeLevel: Level.SUCCESS,
  },
  {
    value: OutboundMembershipPlanType.OUTBOUND_PROFESSIONAL_PLAN,
    title: '$499 per month',
    overline: 'Professional',
    description: 'Maximum Ad Spend:',
    descriptionLine2: '$5000 per month',
    badgeDescription: 'Scaling Businesses',
  },
];

export const SubscriptionSelectionPage = ({
  adBudget,
}: StepPricingProposalProps) => {
  const { mutateAsync } = useCreatePaymentCheckoutForUserMutation();
  const toStripeCheckoutPage = async () => {
    const sessionUrl = await mutateAsync({
      planType: currentPlan?.value as OutboundMembershipPlanType,
    });
    if (sessionUrl) {
      window.location.href = sessionUrl;
    }
  };

  const findRecommendedPlan = (adBudget: number): Option => {
    if (adBudget > 3000) {
      return OUTBOUND_PLAN_OPTIONS[2];
    } else if (adBudget > 1000) {
      return OUTBOUND_PLAN_OPTIONS[1];
    } else {
      return OUTBOUND_PLAN_OPTIONS[0];
    }
  };
  const getSelectedPlanOption = (selection?: string): Option => {
    const selectedOption = OUTBOUND_PLAN_OPTIONS.find(
      (option: Option) => option?.value === selection
    );

    return selectedOption;
  };

  const [currentPlan, setCurrentPlan] = useState<Option>(
    findRecommendedPlan(Number(adBudget))
  );
  const handleValueChange = useCallback((option: Option) => {
    setCurrentPlan(getSelectedPlanOption(option?.value));
  }, []);

  return (
    <>
      <div className='w-full'>
        <div className='flex flex-col py-6 h-full gap-4'>
          <div className='flex flex-col lg:flex-row w-full gap-4'>
            <div className='flex flex-col w-full  gap-4'>
              <ObCardOptionRadioGroup
                gradientIconSize='medium'
                options={OUTBOUND_PLAN_OPTIONS}
                value={currentPlan}
                onValueChangedCb={handleValueChange}
              />
              <section className='border dark:border-borderDefaultNormalDark border-borderDefaultNormalLight rounded-lg dark:bg-bgSurfaceDark bg-bgSurfaceLight'>
                <PricingMembership
                  membership={currentPlan?.value as string}
                  adBudget={Number(adBudget)}
                />
              </section>
              <div className='flex flex-col items-center'>
                <ObTypography
                  variant='body3'
                  color='tertiary'
                >
                  *Your first advertising budget charge will occur once your
                  first campaign is approved
                </ObTypography>
              </div>
              <div className='flex justify-end'>
                <ObThinkingButton
                  label={'Sign Up'}
                  size='medium'
                  fullWidth='mobile'
                  onClickCallback={toStripeCheckoutPage}
                />
              </div>
            </div>
            {/* <div className='flex flex-col lg:max-w-[284px] w-full gap-3'>
              <div className='mt-5 lg:hidden flex'>
                <ObTypography variant='h2'>Still Deciding?</ObTypography>
              </div>
              <section className='border dark:border-borderDefaultNormalDark rounded-lg dark:bg-bgSurfaceDark'>
                <SeeWhatYouCanSave
                  membershipPrice={
                    planPricesMap.get(
                      currentPlan?.value as OutboundMembershipPlanType
                    ) as number
                  }
                />
              </section>
              <div className='flex flex-col items-center '>
                <ObTypography
                  variant='body3'
                  color='tertiary'
                >
                  *Does not include your Ad Budget
                </ObTypography>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
