import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { FC, useEffect } from 'react';
import { getJwtTokenPayload } from '../utilties/jwt-utilities';

export interface DataDogIdentifyProps {
  children?: React.ReactNode;
}

export const DataDogIdentify: FC<DataDogIdentifyProps> = ({
  children,
}: DataDogIdentifyProps) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      getAccessTokenSilently().then((token) => {
        const decoded: any = getJwtTokenPayload(token);
        datadogRum.setUser({
          id: user.sub,
          email: user.email,
          name: user.given_name + ' ' + user.family_name,
          emailVerified: user.email_verified,
          authenticatedToWorkspace:
            decoded['https://outbound.com/tenant_id'] != null,
          workspaceId: decoded['https://outbound.com/tenant_id'] ?? undefined,
          auth0OrganizationId: decoded.org_id,
          roles: decoded['https://outbound.com/roles'],
          permissions: decoded['https://outbound.com/permissions'],
        });
      });
    } else {
      datadogRum.setUser({
        id: 'anonymous',
        email: 'anonymous',
        name: 'anonymous',
      });
    }
  }, [getAccessTokenSilently, isAuthenticated, user]);

  return <>{children}</>;
};
