import { VALID_TLD_LIST } from './tld-reference';

export const createURLWithDefaultScheme = (
  input: string,
  defaultScheme: string = 'http://'
): URL => {
  // Check if the input already has a scheme
  if (!input.match(/^[a-zA-Z]+:\/\//)) {
    // Prepend the default scheme if not
    input = defaultScheme + input;
    console.log('Prepended default scheme to URL', input);
  } else {
    console.log('URL already has a scheme');
  }
  return new URL(input);
};

/**
 * Validates if the TLD is known
 * @param url
 * @returns
 */
export const isValidTld = (url: URL) => {
  const tld = url.hostname.split('.').pop();
  return (
    tld !== undefined &&
    tld.length > 1 &&
    VALID_TLD_LIST.includes(tld.toUpperCase())
  );
};

/**
 * Adds a default scheme and makes the url lowercase. Will fail if the URL is invalid.
 * @param url
 * @returns
 */
export const normalizeWebsiteUrl = (url: string): URL => {
  try {
    const parsedUrl = createURLWithDefaultScheme(url.toLocaleLowerCase());
    if (
      !parsedUrl.hostname ||
      parsedUrl.hostname === '' ||
      parsedUrl.hostname.split('.').length < 2 ||
      parsedUrl.hostname.split('.')[0] === '' ||
      parsedUrl.hostname.split('.')[1] === '' ||
      !['http:', 'https:'].includes(parsedUrl.protocol)
    ) {
      throw new Error('Invalid URL');
    }
    return parsedUrl;
  } catch (error) {
    console.log('Invalid URL');
    throw error;
  }
};

export const urlIncludesFragment = (url: string | URL): boolean => {
  if (typeof url === 'string') {
    return url.includes('#');
  } else {
    return url.hash !== '';
  }
};

export const cleanUrlFromExtraSlashes = (url: string): string => {
  // Parse the URL using the URL constructor
  const parsedUrl = new URL(url);

  // Removes consecutive slashes if present
  const pathname = parsedUrl.pathname.replace(/\/+/g, '/');

  // Reconstruct the URL with normalized components
  const normalizedUrl = `${parsedUrl.protocol}//${parsedUrl.host}${pathname}${parsedUrl.search}${parsedUrl.hash}`;

  return normalizedUrl;
};

/**
 * Checks if the provided URL is valid. If a base URL is provided, it will also check if the URL is valid relative to the base URL.
 * @param url
 * @param baseUrl
 * @returns
 */
export const isValidAbsoluteUrlOrRelativeUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    // Check if the URL is a relative URL
    if (url.startsWith('/')) {
      return true;
    }
    return false;
  }
};
