import { useAuth0 } from '@auth0/auth0-react';
import {
  BrandOnboardingResource,
  CreateBrandOnboardingRequestResource,
  CreateBrandOnboardingResponse,
  CreateSelectedColorsRequestResource,
  CreateWorkspaceDetailsRequestResource,
  OkResponseResource,
  PopulateSelectedAssetsRequestResource,
  PopulateSelectedGoalsRequestResource,
} from '@outbound/types';
import axios, { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OUTBOUND_API_BASE_URL } from '../../environment';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

const unauthenticatedAxios = axios.create({
  baseURL: OUTBOUND_API_BASE_URL,
});

const ENDPOINT = '/onboarding/brand';

const assertTokenAndIdAreValid = (
  id: string | null,
  onboardingToken: string | null
) => {
  if (
    id == null ||
    id.trim() === '' ||
    onboardingToken == null ||
    onboardingToken.trim() === ''
  ) {
    throw new Error('Invalid ID or Token: Cannot perform mutation.');
  }
};

export const useWebsiteScanMutation = () => {
  return useMutation<
    CreateBrandOnboardingResponse,
    undefined,
    CreateBrandOnboardingRequestResource
  >(
    [ENDPOINT, 'post'],
    async ({ domain }: CreateBrandOnboardingRequestResource) => {
      const response = await unauthenticatedAxios.post<
        CreateBrandOnboardingRequestResource,
        AxiosResponse<CreateBrandOnboardingResponse>
      >(`${ENDPOINT}`, { domain });
      return response.data;
    }
  );
};

export const useFetchOnboardingByIdAsAuthenticatedUser = (
  id: string | undefined
) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useQuery<BrandOnboardingResource>(
    [ENDPOINT, id, 'get'],

    async () => {
      const response = await auth0AxiosClient.get<BrandOnboardingResource>(
        `/onboarding/${id}`
      );
      return response.data;
    },
    {
      enabled: !!id, // Only run the query if the id is defined
    }
  );
};

export const useFetchWorkspaceIdFromOnboardingById = (
  id: string | null,
  onboardingToken: string | null
) => {
  return useQuery<BrandOnboardingResource>(
    [ENDPOINT, id, 'get'],

    async () => {
      assertTokenAndIdAreValid(id, onboardingToken);
      const response = await unauthenticatedAxios.get<BrandOnboardingResource>(
        `${ENDPOINT}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${onboardingToken}`,
          },
        }
      );
      return response.data;
    },
    {
      enabled: !!id, // Only run the query if the id is defined
      refetchInterval: (data?: BrandOnboardingResource) => {
        const shouldPoll =
          data?.workspaceId == null || data.workspaceId === undefined;

        return shouldPoll ? 2000 : false;
      },
      // Poll even when the tab is in the background
      refetchIntervalInBackground: false,
    }
  );
};

export const useFetchBrandOnboardingById = (
  id: string | null,
  onboardingToken: string | null
) => {
  return useQuery<BrandOnboardingResource>(
    [ENDPOINT, id, 'get'],

    async () => {
      assertTokenAndIdAreValid(id, onboardingToken);
      const response = await unauthenticatedAxios.get<BrandOnboardingResource>(
        `${ENDPOINT}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${onboardingToken}`,
          },
        }
      );
      return response.data;
    },
    {
      enabled: !!id, // Only run the query if the id is defined
      refetchInterval: (data) =>
        // If the site-scan  status is IN_PROGRESS, then we want to refetch every 2 seconds
        data?.siteScan == null || data?.siteScan?.status === 'IN_PROGRESS'
          ? 2000
          : false,
      // Poll even when the tab is in the background
      refetchIntervalInBackground: false,
    }
  );
};

export const useSetGoalsMutation = (
  id: string | null,
  onboardingToken: string | null
) => {
  const queryClient = useQueryClient();

  return useMutation<
    OkResponseResource,
    undefined,
    PopulateSelectedGoalsRequestResource
  >(
    [ENDPOINT, id, 'select-goal'],

    async (requestData: PopulateSelectedGoalsRequestResource) => {
      assertTokenAndIdAreValid(id, onboardingToken);
      const response = await unauthenticatedAxios.post<
        OkResponseResource,
        AxiosResponse<OkResponseResource>,
        PopulateSelectedGoalsRequestResource
      >(`${ENDPOINT}/${id}/select-goal`, requestData, {
        headers: {
          Authorization: `Bearer ${onboardingToken}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINT, id, 'get']);
      },
    }
  );
};

export const useSetColorsMutation = (
  id: string | null,
  onboardingToken: string | null
) => {
  const queryClient = useQueryClient();
  return useMutation<
    OkResponseResource,
    undefined,
    CreateSelectedColorsRequestResource
  >(
    [ENDPOINT, id, 'select-colors'],
    async (requestData: CreateSelectedColorsRequestResource) => {
      assertTokenAndIdAreValid(id, onboardingToken);
      const response = await unauthenticatedAxios.post<
        OkResponseResource,
        AxiosResponse<OkResponseResource>,
        CreateSelectedColorsRequestResource
      >(`${ENDPOINT}/${id}/select-colors`, requestData, {
        headers: {
          Authorization: `Bearer ${onboardingToken}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINT, id, 'get']);
      },
    }
  );
};

export const useSetAssetsMutation = (
  id: string | null,
  onboardingToken: string | null
) => {
  const queryClient = useQueryClient();

  return useMutation<
    OkResponseResource,
    undefined,
    PopulateSelectedAssetsRequestResource
  >(
    [ENDPOINT, id, 'select-assets'],
    async (requestData: PopulateSelectedAssetsRequestResource) => {
      assertTokenAndIdAreValid(id, onboardingToken);
      const response = await unauthenticatedAxios.post<
        OkResponseResource,
        AxiosResponse<OkResponseResource>,
        PopulateSelectedAssetsRequestResource
      >(`${ENDPOINT}/${id}/select-assets`, requestData, {
        headers: {
          Authorization: `Bearer ${onboardingToken}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINT, id, 'get']);
      },
    }
  );
};

export const useSetWorkspaceDetailsMutation = (
  id: string | null,
  onboardingToken: string | null
) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const { isAuthenticated } = useAuth0();

  return useMutation<
    OkResponseResource,
    undefined,
    CreateWorkspaceDetailsRequestResource
  >(
    [ENDPOINT, id, 'workspace-details'],
    async (requestData: CreateWorkspaceDetailsRequestResource) => {
      assertTokenAndIdAreValid(id, onboardingToken);

      if (isAuthenticated) {
        const response = await auth0AxiosClient.post<OkResponseResource>(
          `${ENDPOINT}/${id}/workspace-details-with-user`,
          requestData
        );
        return response.data;
      }
      const response = await unauthenticatedAxios.post<
        OkResponseResource,
        AxiosResponse<OkResponseResource>,
        CreateWorkspaceDetailsRequestResource
      >(
        `${ENDPOINT}/${id}/workspace-details`,
        {
          ...requestData,
        },
        {
          headers: {
            Authorization: `Bearer ${onboardingToken}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINT, id, 'get']);
      },
    }
  );
};
