import { cx } from 'class-variance-authority';
import { FC, useEffect, useState } from 'react';
import { brandIconWhite } from '../../../brand';
import './ob-loading-spinner.css';

interface LoadingSpinnerProps {
  size?: number;
  strokeWidth?: number;
  logoPath?: string; // Path to your logo SVG
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 100,
  strokeWidth = 4,
  logoPath,
}: LoadingSpinnerProps) => (
  <div
    role='progressbar'
    data-testid='ob-loading-spinner'
    className='inline-block'
    style={{ width: size, height: size }}
  >
    <svg
      width={size}
      height={size}
      viewBox='0 0 100 100'
    >
      <circle
        cx='50'
        cy='50'
        r='48'
        fill='none'
        stroke='#D7DBDF'
        strokeWidth={strokeWidth}
      />
      <circle
        className='spinner-path'
        cx='50'
        cy='50'
        r='48'
        fill='none'
        stroke='#787F85'
        strokeWidth={strokeWidth}
        strokeDasharray='90, 240'
        strokeLinecap='round'
      />
      {logoPath && (
        <image
          href={logoPath}
          x='20'
          y='16'
          width='60'
          height='60'
        />
      )}
    </svg>
  </div>
);

export interface ObLoadingSpinnerProps {
  includeBrand: boolean;
  fadeIn?: boolean;
  size?: number;
  logoPath?: string;
}

export const ObLoadingSpinner: FC<ObLoadingSpinnerProps> = ({
  includeBrand,
  fadeIn = false,
  size = 50,
  logoPath,
}: ObLoadingSpinnerProps) => {
  const [visible, setVisible] = useState<boolean>(!fadeIn);

  useEffect(() => {
    setTimeout(
      () => {
        setVisible(true);
      },
      fadeIn ? 200 : 0
    ); //Prevent Flicker by not showing the spinner for 200ms ()
    //We only render on mount intentionally
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cx(
        'transition-opacity duration-500 ',
        visible ? 'opacity-100' : 'opacity-0'
      )}
      style={{ width: size, height: size }}
    >
      <LoadingSpinner
        size={size}
        strokeWidth={3}
        logoPath={includeBrand ? brandIconWhite : logoPath ?? undefined}
      />
    </div>
  );
};
