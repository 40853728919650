import {
  BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1,
  BASE_AD_SETTINGS_FIELD_NAMES_SCENE_2,
} from '../template-mediums';

export const MEDIUM_DISPLAY_ADS_ANIMATED = {
  SCENE_1_HEADING: BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.HEADING_SCENE_1,
  SCENE_1_CALL_TO_ACTION:
    BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.CALL_TO_ACTION_SCENE_1,
  SCENE_1_IMAGE: BASE_AD_SETTINGS_FIELD_NAMES_SCENE_1.HERO_IMAGE_SCENE_1,
  SCENE_2_HEADING: BASE_AD_SETTINGS_FIELD_NAMES_SCENE_2.HEADING_SCENE_2,
  SCENE_2_CALL_TO_ACTION:
    BASE_AD_SETTINGS_FIELD_NAMES_SCENE_2.CALL_TO_ACTION_SCENE_2,
  SCENE_2_IMAGE: BASE_AD_SETTINGS_FIELD_NAMES_SCENE_2.HERO_IMAGE_SCENE_2,
};
