'use client';
import { cva, cx } from 'class-variance-authority';
import { FC, useCallback } from 'react';
import {
  BaseFormFieldOption,
  ObButton,
  ObButtonProps,
  ObButtonSize,
} from '../../../index';
import { IconSystemKeys } from '../../../tokens/icons/icons';

/**
 * Extends the FOrm Field Option to include the icon properties for the Button Group
 */
export interface ObButtonGroupOption extends BaseFormFieldOption {
  /** Optional: Show an Icon to the left of the label */
  iconLeft?: IconSystemKeys;
  /** Optional: Show an Icon to the right of the label */
  iconRight?: IconSystemKeys;
}

export interface ObButtonGroupProps {
  id?: string;

  /**
   * Indicates the visual size of the button group
   */
  size?: 'medium' | 'large';

  /**
   * The options available in the button group
   */
  options: Array<ObButtonGroupOption>;

  /**
   * The current value of the button group.
   * Expected to be always updated by the parent
   */
  value?: ObButtonGroupOption;

  /**
   * Callback function used by the component to notify the parent that
   * the value should be updated.
   * @param option
   * @returns
   */
  onValueUpdatedCallback?: (option: ObButtonGroupOption) => void;
  /**
   * Indicates that the button group is disabled
   */
  isDisabled?: boolean;

  /**
   * Indicates that the button group should take up the full width of the parent container
   */
  isFullWidth?: boolean;
}

export const ObButtonGroup: FC<ObButtonGroupProps> = ({
  id,
  size = 'large',
  options,
  value,
  onValueUpdatedCallback,
  isDisabled,
  isFullWidth = true,
}: ObButtonGroupProps) => {
  const buttonStyles = cva('border dark:border-dark/border/default/normal', {
    variants: {
      isSelected: {
        true: 'selected',
        false: '',
      },
      isFirst: {
        true: 'rounded-l border-l-[1px]',
        false: '',
      },
      isLast: {
        true: 'rounded-r border-r-[1px]',
        false: '',
      },
      isMultipleOptions: {
        true: 'rounded-[0px] border-x-[0.5px]',
        false: '',
      },
      isFullWidth: {
        true: 'w-full',
        false: '',
      },
    },
  });

  const handleButtonClicked = useCallback(
    (
      e: any,
      key: string | number,
      currentValue: ObButtonGroupOption | undefined
    ) => {
      e.preventDefault();
      if (!isDisabled) {
        const selectedOption = options.find(
          (option: ObButtonProps) => option.key === key
        );

        if (selectedOption && selectedOption.key !== currentValue?.key) {
          onValueUpdatedCallback?.(selectedOption);
        }
      }
    },
    [isDisabled, onValueUpdatedCallback, options]
  );

  return (
    <div
      id={id}
      className='flex row'
      data-testid='ob-button-group'
      role='radiogroup'
    >
      {options.map((option: ObButtonGroupOption, index: number) => {
        const { key, displayValue, iconLeft, iconRight } = option;
        const isSelected = key === value?.key;
        const isFirst = options.length > 1 && index === 0;
        const isLast = options.length > 1 && index === options.length - 1;
        const isMultipleOptions = options.length > 1;
        return (
          <ObButton
            key={index}
            role='radio'
            aria-checked={isSelected}
            data-testid={'ob-button-group-option-' + (index + 1)}
            variant={isSelected ? 'primary' : 'secondary'}
            size={size as ObButtonSize}
            label={displayValue}
            iconLeft={iconLeft}
            disabled={isDisabled}
            iconRight={iconRight}
            textClassname='capitalize px-2'
            className={cx([
              buttonStyles({
                isSelected,
                isFullWidth,
                isMultipleOptions,
                isFirst,
                isLast,
              }),
            ])}
            onClick={(e) => handleButtonClicked(e, key, value)}
          />
        );
      })}
    </div>
  );
};
