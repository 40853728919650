'use client';
import { cva, cx } from 'class-variance-authority';
import { ReactNode, forwardRef, useCallback, useMemo, useState } from 'react';

import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObSkeleton } from '../ob-skeleton/ob-skelton';
import { sizeClasses } from './ob-input-styles';

export interface ObInputProps {
  inputId?: string;
  value?: any;
  readOnly?: boolean;
  size?: 'small' | 'medium' | 'large';
  iconLeft?: IconSystemKeys;
  iconRight?: IconSystemKeys;
  iconRightTabIndex?: number;
  containerClassName?: string;
  inputClassName?: string;
  showInput?: boolean;
  topContent?: ReactNode;
  leftContent?: ReactNode;
  name?: string;
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  preventDefaultOnEnter?: boolean;
  /**
   * Optional Label to use if the Icon Right Click Handler is provided.
   * Ignored if onIconRightClick is not provided.
   */
  onIconRightClickLabel?: string;
  /**
   * I (@pjcahill) attempted to update this to be more specific to onClickRightIcon.
   * But it breaks the obTimePicker component which passes ob-input as a prop called "customInput" on the React DatePicker component.
   * There is something "Magic" (TM) about this being called onClick in that component.
   */
  onClick?: () => any;
  onFocusCallback?: () => any;
  onBlurCallback?: (args: any) => any;
  onChangeCallback?: (args: any) => any;
  onKeyDownCallback?: (args: any) => any;
  onPaste?: (args: any) => any;
  isLoading?: boolean;
}

export const ObInput = forwardRef<HTMLInputElement, ObInputProps>(
  (
    {
      inputId,
      value,
      iconLeft = null,
      iconRight = null,
      topContent = null,
      leftContent = null,
      iconRightTabIndex = 0,
      containerClassName,
      inputClassName,
      size = 'medium',
      name = 'ob-input',
      placeholder = '',
      error = false,
      isDisabled = false,
      showInput = true,
      onClick,
      onIconRightClickLabel,
      onFocusCallback = () => null,
      onBlurCallback = () => null,
      onChangeCallback = () => null,
      onKeyDownCallback = () => null,
      preventDefaultOnEnter = false,
      isLoading,
      onPaste,
      ...props
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false);

    const inputContainerStyles = cva(
      'w-full flex flex-col items-center content-center text-center rounded-[4px] border border-solid appearance-none box-border dark:bg-bgSurfaceDark dark:placeholder:text-contentPlaceholderDark text-contentPrimaryLight dark:text-contentPrimaryDark outline-none focus-within:shadow-interactive ring-0',
      {
        variants: {
          state: {
            normal:
              'border-borderDefaultNormalLight dark:border-borderDefaultNormalDark',
            error: 'ring-1 ring-contextualNegativeDark border-transparent',
            focus: '',
            disabled:
              'dark:bg-bgSurfaceDark/[.84] dark:border-actionSecondaryHoverBorderDark/[.16] opacity-[0.7] pointer-events-none',
          },
          size: {
            small: topContent
              ? ['px-3 py-2']
              : ['px-3 py-2 h-[40px] max-h-[40px]'],
            medium: ['px-4 py-3'],
            large: ['px-4 py-4'],
          },
        },
        defaultVariants: {
          state: 'normal',
        },
      }
    );

    const handleOnKeyDown = useCallback(
      (args: any) => {
        const { key } = args;
        if (key === 'Enter') {
          onClick?.();
        } else if (key === 'Escape') {
          // delegates to parents escape handling to close modal
          onKeyDownCallback?.(args);
        }
      },
      [onClick, onKeyDownCallback]
    );

    const inputContainerState = useMemo(() => {
      if (isDisabled) {
        return 'disabled';
      } else if (focus) {
        return 'focus';
      }

      return error ? 'error' : 'normal';
    }, [error, focus, isDisabled]);

    if (isLoading) {
      return (
        <ObSkeleton
          variant='rounded'
          height={
            size === 'large' ? '53px' : size === 'medium' ? '45px' : '37px'
          }
        />
      );
    }

    return (
      <div
        className={cx([
          inputContainerStyles({ state: inputContainerState }),
          containerClassName,
        ])}
      >
        {topContent ? <>{topContent}</> : null}
        <div
          className={cx([
            'flex items-center content-center text-center',
            showInput && 'w-full',
          ])}
        >
          {iconLeft && (
            <div
              className={cx(
                'h-full flex flex-col items-end justify-center cursor-pointer pl-3'
              )}
            >
              <ObIcon
                icon={iconLeft}
                size='small'
                color='tertiary'
              />
            </div>
          )}
          {leftContent && (
            <div className='cursor-pointer flex justify-center items-center border-r border-borderDefaultNormalLight dark:border-borderDefaultNormalDark box-content w-[40px] min-w-[40px] min-h-[43px]'>
              {leftContent}
            </div>
          )}
          <input
            data-testid='ob-input'
            {...props}
            onPaste={onPaste}
            tabIndex={0}
            id={inputId}
            ref={ref}
            type='text'
            autoComplete='off'
            autoCorrect='off'
            className={cx(
              `h-full inset-0 outline-none ring-0  border-none rounded-[4px] cursor-pointer  bg-transparent text-[14px] placeholder:text-contentPlaceholderLight dark:placeholder:text-contentPlaceholderDark text-ellipsis`,
              sizeClasses({
                size,
                sideIcon: iconLeft != null || iconRight != null,
              }),
              inputClassName
            )}
            placeholder={placeholder}
            name={name}
            value={focus ? null : value}
            onChange={(e) => {
              if (onChangeCallback) {
                onChangeCallback(e.target.value);
              }
            }}
            onKeyDown={(e) => {
              /**
               * Added to prevent the default behavior of the enter key submitting the form
               * when we are using the input in complex components like the ob-chip-input.
               * In this case we want the enter key to add a chip and not submit the form.
               */
              if (preventDefaultOnEnter && e.key === 'Enter') {
                e.preventDefault();
              }
              onKeyDownCallback({
                key: e.key,
                shiftKey: e.shiftKey,
                value: (e.target as any)?.value,
                preventDefault: e.preventDefault,
                stopPropagation: e.stopPropagation,
              });
            }}
            onFocus={() => {
              setFocus(true);
              onFocusCallback();
            }}
            onBlur={(e) => {
              setFocus(false);
              onBlurCallback((e.target as any).value);
            }}
          />
          {iconRight && (
            <div
              tabIndex={iconRightTabIndex}
              role={onClick ? 'button' : undefined}
              aria-label={onIconRightClickLabel}
              data-testid={
                // eslint-disable-next-line react/prop-types
                (props as any)['icon-right-data-testid'] ||
                'ob-input-right-icon'
              }
              className={cx(
                'h-full flex flex-col items-end justify-center cursor-pointer'
              )}
              onClick={onClick}
              onKeyDown={handleOnKeyDown}
            >
              <ObIcon
                icon={iconRight}
                size='small'
                color='tertiary'
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
