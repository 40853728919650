import { CampaignSettingValue } from '@outbound/types';
import { AxiosInstance } from 'axios';

class CampaignSettingTransport {
  private axios: AxiosInstance;
  private timeoutId: number | null = null;
  private delay: number;
  private queue: Array<{ campaignId: string; value: CampaignSettingValue }> =
    [];

  constructor(axios: AxiosInstance) {
    this.delay = 2000;
    this.axios = axios;
  }

  /**
   * Add a campaign setting to the queue to be updated
   *
   * We will batch updates to the server to avoid making too many requests
   * @param campaignId
   * @param setting
   */
  enqueue(campaignId: string, setting: CampaignSettingValue) {
    this.queue.push({ campaignId, value: setting });

    /**
     * Restart the flush queue timer on every enqueue.
     * Future enhancements could include a max queue size or a max delay
     * so we don't wait too long to send updates to the server
     */
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }

    /**
     * Set a timeout to flush the queue after the delay
     */
    this.timeoutId = window.setTimeout(() => {
      this.flushQueue();
      this.timeoutId = null;
    }, this.delay);
  }

  /**
   * Flush the queue of updates to the server
   */
  private async flushQueue() {
    const updates = [...this.queue];
    this.queue = [];

    /**
     * Batch Settings by Campaign Id
     * For each setting in the queue we will discard all but the most recent update.
     * This makes the assumption that each setting is atomic and a "Last one Wins" approach.
     * If this is not the case we would need to implement a more complex merge strategy either here
     * or on the backend.
     */
    const settingsByCampaignId: Record<string, CampaignSettingValue[]> = {};
    updates.forEach(({ campaignId, value }) => {
      if (!settingsByCampaignId[campaignId]) {
        settingsByCampaignId[campaignId] = [];
      }
      const existingSettingIndex = settingsByCampaignId[campaignId].findIndex(
        (setting) => setting.id === value.id
      );
      if (existingSettingIndex !== -1) {
        settingsByCampaignId[campaignId][existingSettingIndex] = value;
      } else {
        settingsByCampaignId[campaignId].push(value);
      }
    });

    //Send Batched Settings
    const responses = await Promise.all(
      Object.entries(settingsByCampaignId).map(([campaignId, settings]) =>
        this.axios.patch(`/campaigns/${campaignId}/settings`, {
          campaignSettingValues: settings,
        })
      )
    );

    console.log(
      `Merged ${updates.length} updates to ${responses.length} request(s) and flushed to server`
    );
  }
}

export default CampaignSettingTransport;
