import { cva, cx } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSwitch } from '../../../hooks/use-switch';
import { useWindowDimensions } from '../../../hooks/use-window-dimensions';
import { SETTINGS_ROUTES } from '../../navigation/NavigationConstants';
import { MainDrawer } from './main-drawer/main-drawer';
import { SubDrawer } from './sub-drawer/sub-drawer';

const needsSubDrawerNavigation = (pathname: string) => {
  const isSettingsPage = SETTINGS_ROUTES.some((route) =>
    pathname.includes(route)
  );

  return isSettingsPage;
};

export const NavigationDrawer = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams(); // Hook to access query parameters

  const hideSideNav = searchParams.has('hide-side-nav'); // Check for the "hide-side-nav" parameter

  const [isSubDrawerShown, setIsSubDrawerShown] = useState(
    needsSubDrawerNavigation(pathname)
  );

  const { isSmallOrLargerViewport: isDesktopView } = useWindowDimensions();
  const [isDrawerExpanded, toggleIsDrawerExpanded] = useSwitch(isDesktopView);

  useEffect(() => {
    setIsSubDrawerShown(needsSubDrawerNavigation(pathname));
  }, [pathname]);

  const navPanelPositionStyles = cva(
    'transition-all duration-300 ease-in-out',
    {
      variants: {
        hideSideNav: {
          true: 'translate-x-[-200px] opacity-0', // Hide nav when the param is present
          false: '',
        },
        isDesktopView: { true: '', false: '' },
        isDrawerExpanded: { true: '', false: '' },
        isSubDrawerShown: {
          true: '',
          false: 'translate-x-[0]', // Default position when sub drawer is hidden
        },
      },
      compoundVariants: [
        {
          isDrawerExpanded: true,
          isSubDrawerShown: true,
          isDesktopView: true,
          className: 'translate-x-[-260px]',
        },
        {
          isDrawerExpanded: false,
          isSubDrawerShown: true,
          isDesktopView: true,
          className: 'translate-x-[-64px]',
        },
        {
          isSubDrawerShown: true,
          isDesktopView: false,
          className: '-translate-x-[50%]',
        },
        {
          isDrawerExpanded: false,
          isSubDrawerShown: false,
          className: '',
        },
      ],
    }
  );

  const styles = cva('transition-all duration-300 ease-in-out', {
    variants: {
      hideSideNav: {
        true: 'w-0 opacity-0', // Hide navigation completely
        false: 'opacity-100',
      },
      isDrawerExpanded: {
        true: '',
        false: '',
      },
      isSubDrawerShown: {
        true: '',
        false: '',
      },
      isDesktopView: {
        true: 'min-h-screen',
        false: 'min-w-screen',
      },
    },
    compoundVariants: [
      {
        hideSideNav: false,
        isDesktopView: true,
        isDrawerExpanded: true,
        className: 'w-[260px] min-w-[260px] max-w-[260px]',
      },
      {
        hideSideNav: false,
        isDesktopView: true,
        isDrawerExpanded: false,
        className: 'w-[64px] min-w-[64px] max-w-[64px]',
      },
      {
        hideSideNav: false,
        isDesktopView: false,
        isDrawerExpanded: true,
        className: 'h-screen min-h-screen max-h-screen',
      },
      {
        hideSideNav: false,
        isDesktopView: false,
        isDrawerExpanded: false,
        className: 'h-[65px] min-h-[65px] max-h-[65px]',
      },
    ],
    defaultVariants: {
      hideSideNav: false,
      isSubDrawerShown: false,
      isDrawerExpanded: true,
      isDesktopView: true,
    },
  });

  return (
    <>
      {/* Spacer to help with the fixed location */}
      {isDesktopView && (
        <div
          className={cx(
            'h-full',
            styles({
              hideSideNav,
              isDesktopView,
              isDrawerExpanded,
              isSubDrawerShown,
            })
          )}
        ></div>
      )}
      <div
        data-testid='navigation-drawer'
        data-desktop-view={isDesktopView}
        data-drawer-expanded={isDrawerExpanded}
        data-sub-drawer-shown={isSubDrawerShown}
        className={cx(
          'fixed flex overflow-hidden',
          styles({
            hideSideNav,
            isDesktopView,
            isDrawerExpanded,
            isSubDrawerShown,
          })
        )}
      >
        <div
          className={cx(
            'flex flex-row justify-start relative transition-transform duration-200',
            navPanelPositionStyles({
              hideSideNav,
              isDrawerExpanded,
              isSubDrawerShown,
              isDesktopView,
            })
          )}
        >
          <div
            data-visible={!isSubDrawerShown}
            className='data-[visible=false]:invisible data-[visible=false]:pointer-events-none'
          >
            <MainDrawer
              isDrawerExpanded={isDrawerExpanded}
              isSubDrawerShown={isSubDrawerShown}
              toggleDrawerExpansion={toggleIsDrawerExpanded}
            />
          </div>
          <div
            data-visible={isSubDrawerShown}
            className='data-[visible=false]:invisible data-[visible=false]:pointer-events-none'
          >
            <SubDrawer
              isCampaignDetailsPage={false}
              isDrawerExpanded={isDrawerExpanded}
              isSubDrawerShown={isSubDrawerShown}
              toggleDrawerExpansion={toggleIsDrawerExpanded}
            />
          </div>
        </div>
      </div>
    </>
  );
};
