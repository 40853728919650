import {
  ObDeleteCard,
  ObFormRenderer,
  useDrawerService,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { usePatchServiceAreaById } from '../../../../../../query/playbook/use-playbook-settings-location-endpoints';
import ServiceAreaLocation from '../../../../../../state/mobx-experiment/business-context/location/service-area/service-area';
import { useRootStore } from '../../../../../../state/mobx-experiment/use-root-store';
import { PlaybookDrawerContent } from '../../_common/playbook-drawer-content';
import { useDrawerEditForm } from '../../_common/use-drawer-edit-form';
import {
  mapApiResponseToFormValues,
  mapFormValuesToApiResource,
} from '../forms/service-area-form-utils';
import { SERVICE_AREA_FORM_DEF } from '../forms/service-area-form.def';

export interface ServiceAreaEditDrawerProps {
  /**
   * The ID of the Service Area to load in the drawer
   */
  serviceAreaId: string;
}
export const ServiceAreaEditDrawer = observer(
  ({ serviceAreaId }: ServiceAreaEditDrawerProps) => {
    const {
      getRegisteredFormById,
      initialValues,
      setInitialValues,
      onFormDirtyChange,
      isInitialValuesFetched,
      setIsInitialValuesFetched,
      areChangesUnsaved,
      notificationService,
      drawerService,
    } = useDrawerEditForm();

    const { locationStore } = useRootStore();
    const location = locationStore.getById(serviceAreaId);

    const { mutateAsync: patchServiceArea } =
      usePatchServiceAreaById(serviceAreaId);

    /**
     * Side Effect run to initialize the form
     */
    useEffect(() => {
      if (location == null) {
        throw new Error('Service Area not found');
      } else {
        setInitialValues(
          mapApiResponseToFormValues(location as ServiceAreaLocation)
        );
        setIsInitialValuesFetched(true);
      }
      /**
       * When deleting a service area, it triggers a re-render of the component
       * and we need to make sure that we don't re-fetch the initial values
       */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialValuesFetched, setInitialValues, setIsInitialValuesFetched]);

    /**
     * Will need to convert patching to the Location Mobx store
     */
    const handleUpdateServiceArea = useCallback(
      async (values: any) => {
        const result = await patchServiceArea({
          values: mapFormValuesToApiResource(values),
        });

        return result;
      },
      [patchServiceArea]
    );

    const handleDeleteServiceArea = useCallback(async () => {
      if (location == null) {
        return;
      }
      try {
        location.delete();
        /**
         * After we delete the service area we want to close the drawer.
         */
        drawerService.popDrawer();

        notificationService.pushNotification({
          title: 'Service Area Deleted',
          body: 'The service area has been deleted successfully.',
        });
      } catch (error) {}
    }, [drawerService, location, notificationService]);

    return (
      <PlaybookDrawerContent
        primaryActionLabel={'Save'}
        primaryActionCallback={async () => {
          return getRegisteredFormById('service-area-form')?.submitFunction();
        }}
        secondaryActionLabel={'Discard'}
        secondaryActionCallback={async () => {
          return getRegisteredFormById(
            'service-area-form'
          )?.discardChangesFunction();
        }}
        showFooter={areChangesUnsaved}
      >
        {isInitialValuesFetched && (
          <div className='flex flex-col justify-between flex-1'>
            <ObFormRenderer
              formDefinition={SERVICE_AREA_FORM_DEF}
              hideSubmitButton={true}
              defaultValues={initialValues}
              onSubmitCallback={handleUpdateServiceArea}
              onFormDirtyChangeCallback={onFormDirtyChange}
            />
            <ObDeleteCard
              typeToConfirmValue={initialValues.name}
              isLoading={false}
              cardTitle={'Delete Service Area'}
              cardBody={
                "Deleting a service area will delete all of it's associated data and remove it from any campaign related to this service area."
              }
              cardButtonLabel={'Delete'}
              confirmationDialogTitle={'Delete Service Area'}
              confirmationDialogBody={
                'This action is permanent and cannot be undone.'
              }
              typeToConfirmInstructions={
                'Type the name of the service area to confirm'
              }
              onDeleteConfirmedCallback={handleDeleteServiceArea}
            />
          </div>
        )}
      </PlaybookDrawerContent>
    );
  }
);

export const useServiceAreaEditDrawer = () => {
  const drawerService = useDrawerService();
  /**
   * Opens the drawer that allows the user to view, edit or delete a service area
   * @param serviceAreaId
   */
  const openEditLocationDrawer = (serviceAreaId: string) => {
    drawerService.pushDrawer({
      title: 'Edit Service Area',
      description: 'Edit the information below to update a service area.',
      drawerContent: <ServiceAreaEditDrawer serviceAreaId={serviceAreaId} />,
      size: 'medium',
    });
  };

  return { openEditLocationDrawer };
};
