/* solid/general/trash-04 */
export const SolidGeneralTrash04 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      {...props}
    >
      <path
        d='M4.33337 1.00004C4.33337 0.631851 4.63185 0.333374 5.00004 0.333374H9.00004C9.36823 0.333374 9.66671 0.631851 9.66671 1.00004C9.66671 1.36823 9.36823 1.66671 9.00004 1.66671H5.00004C4.63185 1.66671 4.33337 1.36823 4.33337 1.00004Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.333374 3.00004C0.333374 2.63185 0.631851 2.33337 1.00004 2.33337H13C13.3682 2.33337 13.6667 2.63185 13.6667 3.00004C13.6667 3.36823 13.3682 3.66671 13 3.66671H12.2904L11.8626 10.0837C11.8291 10.5873 11.8013 11.0041 11.7515 11.3433C11.6996 11.6965 11.6174 12.0193 11.446 12.3201C11.1792 12.7883 10.7769 13.1648 10.2919 13.3998C9.98038 13.5508 9.65286 13.6114 9.297 13.6396C8.9552 13.6667 8.53751 13.6667 8.03277 13.6667H5.96735C5.46261 13.6667 5.04492 13.6667 4.70312 13.6396C4.34726 13.6114 4.01974 13.5508 3.70821 13.3998C3.22325 13.1648 2.82088 12.7883 2.55412 12.3201C2.38276 12.0193 2.3005 11.6965 2.24864 11.3433C2.19883 11.0041 2.17106 10.5873 2.13749 10.0837L1.70969 3.66671H1.00004C0.631851 3.66671 0.333374 3.36823 0.333374 3.00004Z'
        fill='currentColor'
      />
    </svg>
  );
};
