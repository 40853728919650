import { useCallback, useEffect, useState } from 'react';
import {
  createAGrammaticallyCorrectListWithAnd,
  createAGrammaticallyCorrectListWithOr,
  determineArticleForWord,
} from '../../../utilities/string-utils';
import { AcceptedFileType } from '../ob-upload/ob-upload.type';

export const useObUploadAllowedFileTypes = (
  acceptedFileTypes: Array<AcceptedFileType>
) => {
  const buildFileTypeErrorMessage = (
    acceptedFileTypes: Array<AcceptedFileType>,
    rejectedExtensions: Array<string> = []
  ) => {
    const acceptedFileTypesString =
      createAGrammaticallyCorrectListWithOr(acceptedFileTypes);

    const article = determineArticleForWord(acceptedFileTypesString);
    return `${createAGrammaticallyCorrectListWithAnd(
      rejectedExtensions.map((ext) => ext.toUpperCase())
    )} files are not supported. Please upload ${article} ${acceptedFileTypesString}.`;
  };

  /**
   * Given an array of accepted file types, return a string of accepted mime types
   * @param acceptedFileTypes
   * @returns
   */
  const generateAcceptedExtensionsString = (
    acceptedFileTypes: Array<AcceptedFileType>
  ) => {
    const acceptedExtensions = acceptedFileTypes.map((type) => {
      switch (type) {
        case 'image':
          return 'image/apng,image/avif,image/gif,image/jpeg,image/png,image/svg+xml,image/webp';
        case 'pdf':
          return 'application/pdf';
        default:
          return '';
      }
    });

    return acceptedExtensions.join(',');
  };

  /**
   * Given an array of accepted file types, return an array of file extensions
   * @param acceptedFileTypes
   * @returns
   */
  const getFileExtensionsForAcceptedFileType = (
    acceptedFileTypes: Array<AcceptedFileType>
  ): Array<string> => {
    return acceptedFileTypes.flatMap((type) => {
      switch (type) {
        case 'image':
          return ['apng', 'avif', 'gif', 'jpeg', 'jpg', 'png', 'svg', 'webp'];
        case 'pdf':
          return ['pdf'];
        default:
          return [];
      }
    });
  };

  const findInvalidFileTypes = useCallback(
    (files: Array<File>): Array<string> => {
      if (acceptedFileTypes.length === 0) {
        return [];
      }

      const fileExtensions =
        getFileExtensionsForAcceptedFileType(acceptedFileTypes);

      return files
        .map((file) => {
          const fileExtension = file.name.split('.').pop();
          if (fileExtension) {
            if (
              !fileExtensions.some(
                (type) => type === fileExtension.toLowerCase()
              )
            ) {
              return fileExtension;
            }
          }
          return null;
        })
        .filter((fileType) => fileType != null) as Array<string>;
    },
    [acceptedFileTypes]
  );

  /**
   * Given an array of accepted file types, return an array of mime types
   * @param acceptedFileTypes
   * @returns
   */
  const getMimeTypesForAcceptedFileType = (
    acceptedFileTypes: Array<AcceptedFileType>
  ): Array<string> => {
    return acceptedFileTypes.flatMap((type) => {
      switch (type) {
        case 'image':
          return [
            'image/apng',
            'image/avif',
            'image/gif',
            'image/jpeg',
            'image/png',
            'image/svg+xml',
            'image/webp',
          ];
        case 'pdf':
          return ['application/pdf'];
        default:
          return [];
      }
    });
  };

  const buildUiAcceptedFileTypesString = (
    acceptedFileTypes: Array<AcceptedFileType>
  ) => {
    const acceptedFileTypesString =
      createAGrammaticallyCorrectListWithOr(acceptedFileTypes);

    const article = determineArticleForWord(acceptedFileTypesString);
    return `Upload ${article} ${acceptedFileTypesString} file.`;
  };

  const [acceptedExtensionsString, setAcceptedExtensionsString] = useState(
    generateAcceptedExtensionsString(acceptedFileTypes)
  );

  const [acceptedExtensionsUiString, setAcceptedExtensionsUiString] = useState(
    buildUiAcceptedFileTypesString(acceptedFileTypes)
  );

  useEffect(() => {
    setAcceptedExtensionsString(
      generateAcceptedExtensionsString(acceptedFileTypes)
    );
    setAcceptedExtensionsUiString(
      buildUiAcceptedFileTypesString(acceptedFileTypes)
    );
  }, [acceptedFileTypes]);

  useEffect(() => {
    setAcceptedExtensionsString(
      generateAcceptedExtensionsString(acceptedFileTypes)
    );
  }, [acceptedFileTypes]);

  return {
    acceptedExtensionsString,
    acceptedExtensionsUiString,
    buildFileTypeErrorMessage,
    findInvalidFileTypes,
    getMimeTypesForAcceptedFileType,
  };
};
