import {
  CampaignHighlightObjectType,
  ClientDelta,
  ServerDelta,
} from '@outbound/types';

import { parseObrn } from '@otbnd/utils';
import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { BaseModel } from '../../base-model';
import Creative from '../../creative/creative';
import LandingPage from '../../landing-page/landing-page';
import { RootStore } from '../../root-store';

type HighlightedServiceOfferingContext = {
  type: 'service-offering';
  serviceId: string;
  serviceOfferingId: string;
};

type HighlightedServiceContext = { type: 'service'; serviceId: string };
type HighlightedObjectContext =
  | HighlightedServiceOfferingContext
  | HighlightedServiceContext;

interface CampaignHighlightData {
  highlightType: CampaignHighlightObjectType;
  highlightedObjectObrn: string;
  highlightedObjectContext: HighlightedObjectContext;
  associatedCreativeIds: Array<string>;
  isEnabled: boolean;
  associatedLandingPageObrn?: string;
}

/**
 * From a User Perspective is a way to feature or "highlight" a specific object in the playbook prominently in a campaign.
 *
 * From a data model perspective a Campaign Highlight is a Junction Object tracking the relationship between a campaign and a highlighted object.
 * Additionally this junction object has a relationship to the creatives that are associated with the highlight.
 */
class CampaignHighlight extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    isEnabled: '/isEnabled',
    type: '/highlightType',
    associatedCreativeIds: '/associatedCreativeIds',
    associatedLandingPageObrn: '/associatedLandingPageObrn',
    highlightedObjectObrn: '/highlightedObjectObrn',
    context: '/highlightedObjectContext',
  };

  protected _isEnabled: boolean;
  protected _highlightType: CampaignHighlightObjectType;
  protected _highlightedObjectObrn: string;
  protected _highlightedObjectContext: HighlightedObjectContext;
  protected _associatedCreativeIds: Array<string>;
  protected _hasUnpublishedChanges = false;

  protected _associatedLandingPageObrn: string | null;

  /**
   * Indicates if the object that this highlight is highlighting is missing from
   * the source of truth (The Playbook). This means that the user has deleted the object
   * from the playbook, we still have the cached version of the object we can reference.
   */
  private _isHighlightedObjectDeletedFromSourceOfTruth: boolean;
  /**
   *
   * This is a flag that indicates if the object that this highlight is highlighting
   * is out of sync with the source of truth (The Playbook). This means that the user has
   * made changes to the object in the playbook and we have not yet updated our cached version
   * of the object to reflect those changes.
   *
   * This will expose a new Call to Action to the user to update the object in the campaign to
   * reflect what is in the playbook.
   */
  private _isHighlightedObjectOutOfSyncWithSourceOfTruth: boolean;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    data: CampaignHighlightData
  ) {
    const { scope } = parseObrn(obrn);
    super(rootStore, 'campaign/highlight', '1', id, scope, obrn);

    this._highlightType = data.highlightType;
    this._highlightedObjectContext = data.highlightedObjectContext;
    this._highlightedObjectObrn = data.highlightedObjectObrn;
    this._associatedCreativeIds = data.associatedCreativeIds;
    this._isEnabled = data.isEnabled;
    this._hasUnpublishedChanges = false;
    this._associatedLandingPageObrn = data.associatedLandingPageObrn ?? null;

    //Future Implementation (May move to @computed property)
    this._isHighlightedObjectDeletedFromSourceOfTruth = false;
    this._isHighlightedObjectOutOfSyncWithSourceOfTruth = false;
  }

  protected makeObservableInternal() {
    makeObservable(this, {
      _isEnabled: observable,
      _highlightType: observable,
      _associatedCreativeIds: observable,
      _associatedLandingPageObrn: observable,
      applyPatch: action,
      toJson: action,
    } as any);
    this.setupReactions();
  }

  private setupReactions() {
    reaction(
      () => this._isEnabled,
      () => {
        this._hasUnpublishedChanges = true;
        const clientDelta: ClientDelta = {
          id: this._id,
          obrn: this._obrn,
          op: 'replace',
          path: CampaignHighlight.paths.isEnabled,
          value: this._isEnabled,
          clientTimestamp: new Date().toISOString(),
          clientUpdateId: crypto.randomUUID(),
          clientId: this._rootStore.clientId,
          object: this.object,
          objectDomain: this.objectDomain,
          objectSchemaVersion: this.objectVersion,
        };

        this._clientDeltas.set(clientDelta.path, clientDelta); //Keep Reference to the last change sent to the server
        this._rootStore.transport.campaignTransport.enqueue(clientDelta);
      }
    );
    //When the associated landing page changes we need to update the server
    reaction(
      () => this._associatedLandingPageObrn,
      () => {
        this._hasUnpublishedChanges = true;
        const clientDelta: ClientDelta = {
          id: this._id,
          obrn: this._obrn,
          op: 'replace',
          path: CampaignHighlight.paths.associatedLandingPageObrn,
          value: this._associatedLandingPageObrn,
          clientTimestamp: new Date().toISOString(),
          clientUpdateId: crypto.randomUUID(),
          clientId: this._rootStore.clientId,
          object: this.object,
          objectDomain: this.objectDomain,
          objectSchemaVersion: this.objectVersion,
        };

        this._clientDeltas.set(clientDelta.path, clientDelta); //Keep Reference to the last change sent to the server
        this._rootStore.transport.campaignTransport.enqueue(clientDelta);
      }
    );
  }

  public addExistingLandingPageByObrn(landingPageObrn: string) {
    runInAction(() => {
      this._associatedLandingPageObrn = landingPageObrn;
    });
  }

  public addSelfHostedLandingPage(url: string) {
    runInAction(() => {
      const landingPage = this._rootStore.landingPageStore.createSelfHosted(
        url,
        this.workspaceId
      );
      this.addExistingLandingPageByObrn(landingPage.obrn);
    });
  }

  public removeLandingPage() {
    this._associatedLandingPageObrn = null;
  }

  public applyPatch(patch: Array<ServerDelta>) {
    runInAction(() => {
      for (const delta of patch) {
        if (delta.path === CampaignHighlight.paths.isEnabled) {
          const clientDelta = this._clientDeltas.get(
            CampaignHighlight.paths.isEnabled
          );
          const applyChange = this.shouldDeltaBeApplied(clientDelta, delta);
          if (applyChange) {
            switch (delta.op) {
              case 'replace': {
                this._isEnabled = delta.value as boolean;
                this._clientDeltas.set(delta.path, {
                  ...delta,
                  clientTimestamp: delta.serverTimestamp,
                });
                break;
              }
            }
          }
        }
      }
    });
  }

  public toJson(): Record<string, any> {
    const baseAttributes = super.toJson();
    return {
      ...baseAttributes,
      [CampaignHighlight.paths.type]: this._highlightType,
      [CampaignHighlight.paths.highlightedObjectObrn]:
        this._highlightedObjectObrn,
      [CampaignHighlight.paths.associatedCreativeIds]:
        this._associatedCreativeIds,
      [CampaignHighlight.paths.isEnabled]: this._isEnabled,
      [CampaignHighlight.paths.context]: this._highlightedObjectContext,
      [CampaignHighlight.paths.associatedLandingPageObrn]:
        this._associatedLandingPageObrn,
    };
  }

  get highlightedObjectData(): any {
    return {
      id: 'not-implemented',
      name: 'NOT IMPLEMENTED',
    };
  }

  /**
   * Returns the associated creative for this highlight.
   */
  get creatives(): Array<Creative> {
    const c = this._associatedCreativeIds
      .map((creativeId) => {
        return this._rootStore.creativeStore.getById(creativeId);
      })
      .filter((creative) => creative != null) as Array<Creative>;
    return c;
  }

  get highlightedObject(): any {
    switch (this._highlightType) {
      case 'service': {
        const serviceContext = this
          ._highlightedObjectContext as HighlightedServiceContext;
        return this._rootStore.serviceStore.getById(serviceContext.serviceId);
      }
      case 'service-offering': {
        const serviceOfferingContext = this
          ._highlightedObjectContext as HighlightedServiceOfferingContext;
        const service = this._rootStore.serviceStore.getById(
          serviceOfferingContext.serviceId
        );
        return service?.serviceOfferings.find((offering) => {
          return offering.id === serviceOfferingContext.serviceOfferingId;
        });
      }
      default:
        //TODO REACH INTO THE ROOT STORE AND PULL THE ASSOCIATED SERVICE OFFERING
        return {
          id: this._highlightedObjectObrn,
          name: 'NOT IMPLEMENTED',
        };
    }
  }

  /**
   * Indicates if the highlight is enabled or not for the campaign
   */
  get isEnabled(): boolean {
    return this._isEnabled;
  }

  set isEnabled(value: boolean) {
    this._isEnabled = value;
  }

  /**
   * Indicates the underlying type of object that we are highlighting
   */
  get highlightType(): CampaignHighlightObjectType {
    return this._highlightType;
  }

  get highlightedObjectId(): string {
    return this._highlightedObjectObrn;
  }

  get isHighlightedObjectDeletedFromSourceOfTruth(): boolean {
    return this._isHighlightedObjectDeletedFromSourceOfTruth;
  }

  get isHighlightedObjectOutOfSyncWithSourceOfTruth(): boolean {
    return this._isHighlightedObjectOutOfSyncWithSourceOfTruth;
  }

  get hasUnpublishedChanges(): boolean {
    return this._hasUnpublishedChanges;
  }

  get landingPage(): LandingPage | null {
    if (this._associatedLandingPageObrn == null) {
      return null;
    } else {
      const landingPageId = parseObrn(
        this._associatedLandingPageObrn
      ).localPathId;
      return this._rootStore.landingPageStore.getById(landingPageId);
    }
  }
}

export default CampaignHighlight;
