import { isBefore, isSameDay } from 'date-fns';
import * as Yup from 'yup';
import { getRefValues } from './hooks/useCustomRefs';
import {
  CustomInputRefsProps,
  FormDefinition,
  FormFieldDefinition,
  FormFieldType,
  FormFieldTypeSettingsSchema,
} from './ob-form-renderer.types';

interface BuildYupValidationSchemaForFormProps {
  formDefinition: FormDefinition;
  formValues: any;
  customInputsRefs: CustomInputRefsProps;
}

export const buildYupValidationSchemaForForm = ({
  formDefinition,
  formValues,
  customInputsRefs,
}: BuildYupValidationSchemaForFormProps) => {
  const yupShape: any = {};

  formDefinition.sections?.forEach((s) => {
    s?.fields?.forEach((field: FormFieldDefinition) => {
      // Skip hidden values from validation

      if (field?.conditionallyHideFieldFunction) {
        const isHidden = field.conditionallyHideFieldFunction(formValues);
        if (isHidden) return;
      }
      yupShape[field.id] = buildYupValidationForField(field, customInputsRefs);
    });
  });

  const errorsShape = Yup.object().shape(yupShape);
  return errorsShape;
};

export const buildYupValidationForRadioGroup: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.string();
  if (field.validationSchema != null) {
    if (field.validationSchema.isRequired) {
      yupField = yupField.required(`${field.label || 'Field'} is required`);
    }
  }
  return yupField;
};

export const buildYupStringValidationForField: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.string();
  yupField = applyRequiredValidationRules(yupField, field);
  if (field.validationSchema.minLength != null) {
    yupField = yupField.min(
      field.validationSchema.minLength,
      `${field.label || 'Field'} must have at least ${
        field.validationSchema.minLength
      } characters`
    );
  }
  if (field.validationSchema.maxLength != null) {
    yupField = yupField.max(
      field.validationSchema.maxLength,
      `${field.label || 'Field'} must be ${
        field.validationSchema.maxLength
      } or less characters.`
    );
  }
  if (
    field.validationSchema.in != null &&
    field.validationSchema.in.length > 0
  ) {
    let inMessage;
    /*
        Handle the case that this is being used for a type to confirm feature.
        I cannot currently think of any other use case where this would be used with only one value.
        If one comes up this logic can be modified.
         */
    if (field.validationSchema.in.length === 1) {
      inMessage = `Type ${field.validationSchema.in[0]} to confirm`;
    } else {
      inMessage = `Please enter one of the following values: ${field.validationSchema.in}`;
    }
    yupField = yupField.oneOf(field.validationSchema.in, inMessage);
  }
  return yupField;
};

export const buildFieldValidationForPassword: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.string();

  yupField = buildYupStringValidationForField(field);

  yupField = yupField.test(
    'sequential-repeated-chars',
    'Invalid password',
    (value) => {
      const isIncrementalPassword = isIncremental(value!);
      const isRepetitivePassword = isRepetitive(value!);

      // Return false if either condition is met (invalid password)
      return !(isIncrementalPassword || isRepetitivePassword);
    }
  );

  if (field?.validationSchema?.passwordMatchFieldID) {
    yupField = yupField.oneOf(
      [Yup.ref(field?.validationSchema?.passwordMatchFieldID), null],
      'Passwords must match'
    );
  }
  return yupField;
};

export const isIncremental = (password: string): boolean => {
  // Check if the password is an incremental sequence (e.g., "1234", "abcd", etc.)
  for (let i = 1; i < password.length; i++) {
    const prevCharCode = password.charCodeAt(i - 1);
    const currentCharCode = password.charCodeAt(i);
    if (prevCharCode + 1 !== currentCharCode) {
      return false;
    }
  }
  return true;
};

export const isRepetitive = (password: string): boolean => {
  // Check if the password contains repeated characters (e.g., "aaaa", "1111", etc.)
  const repeatedPattern = /(\w)\1{3,}/;
  return repeatedPattern.test(password);
};

export const applyRequiredValidationRules = (
  yupField: any,
  field: FormFieldDefinition
) => {
  if (field?.validationSchema?.isRequired) {
    yupField = yupField.required(
      field.validationSchema?.isRequiredMessage ||
        `${field.label || 'Field'} is required`
    );
  }
  return yupField;
};

export const buildFieldValidationForBoolean: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.boolean();
  if (field.validationSchema != null) {
    if (field.validationSchema.isRequired) {
      yupField = yupField.isTrue('Please check to continue');
    }
  }
  return yupField;
};

export const buildYupFieldValidationForSelect: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.string();
  if (field.validationSchema != null) {
    if (field.validationSchema.isRequired) {
      yupField = yupField.required(`${field.label || 'Field'} is required`);
    }
  }
  return yupField;
};

export const buildFieldValidationForNumber: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.number().typeError(
    `${field.label || 'Field'} must be a number`
  );
  if (field.validationSchema != null) {
    if (field.validationSchema.isRequired) {
      yupField = yupField.required(`${field.label || 'Field'} is required`);
    }
    if (field.validationSchema.min != null) {
      yupField = yupField.min(
        field.validationSchema.min,
        `${field.label || 'Value'} must be greater than ${
          field.validationSchema.min
        }`
      );
    }
  }
  if (field.validationSchema.max != null) {
    yupField = yupField.max(
      field.validationSchema.max,
      `${field.label || 'Value'} must be less than ${
        field.validationSchema.max
      }`
    );
  }
  return yupField;
};

export const buildEmailValidationSchema: any = (field: FormFieldDefinition) => {
  let yupField = Yup.string().email(() => 'Please enter a valid email');
  yupField = applyRequiredValidationRules(yupField, field);

  return yupField;
};

export const buildEmailListValidationSchema: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`));

  if (field.validationSchema.min != null) {
    yupField = yupField.min(
      field.validationSchema.min,
      `${field.label || 'Value'} must include at least ${
        field.validationSchema.min
      } email address${field.validationSchema.min === 1 ? '.' : 'es.'}`
    );
  }
  if (field.validationSchema.max != null) {
    yupField = yupField.max(
      field.validationSchema.max,
      `${field.label || 'Value'} must have less than ${
        field.validationSchema.max
      } email addresses.`
    );
  }
  return yupField;
};

export const buildIroncladClickWrapValidationSchema: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.boolean();

  if (field.validationSchema.isRequired) {
    yupField = yupField.required();
  }

  const fieldTypeSettingsAsIrocladType =
    field.fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.IRONCLAD_CLICKWRAP>;

  return yupField.oneOf(
    [true],
    `Please agree to the ${fieldTypeSettingsAsIrocladType.hrefText}`
  );
};
/**
 * Yup Validation Logic for the Field Validation for Chips Inputs
 * @param field
 * @returns
 */
export const buildFieldValidationForChipInput: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.array().of(Yup.string());

  if (field.validationSchema.isRequired) {
    yupField = yupField.required(
      `${
        field.label || 'Value'
      } is required. Please provide at least one value.`
    );
  }

  /** Handle Minimum Selected Options Validation */
  if (field.validationSchema.min != null) {
    yupField = yupField.min(
      field.validationSchema.min,
      `${field.label || 'Value'} requires at least ${
        field.validationSchema.min
      } value${field.validationSchema.min === 1 ? '' : 's'} to be provided.`
    );
    /** If Required there should be at least one value */
  } else if (
    field.validationSchema.isRequired &&
    (field.validationSchema.min == null || field.validationSchema.min === 0)
  ) {
    yupField = yupField.min(
      1,
      `${
        field.label || 'Value'
      } is required. Please provide at least one value.`
    );
  }

  /** Handle Maximum Selected Options Validation */
  if (field.validationSchema.max != null) {
    yupField = yupField.max(
      field.validationSchema.max,
      `${field.label || 'Value'} cannot have more than ${
        field.validationSchema.max
      } value${field.validationSchema.max === 1 ? '' : 's'}.`
    );
  }

  return yupField;
};

/**
 * Yup Validation Logic for the Field Validation Checkbox Group
 * @param field
 * @returns
 */
export const buildFieldValidationForCheckboxGroup: any = (
  field: FormFieldDefinition
) => {
  let yupField = Yup.array();

  /** Handle Minimum Selected Options Validation */
  if (field.validationSchema.min != null) {
    yupField = yupField.min(
      field.validationSchema.min,
      `${field.label || 'Value'} requires at least ${
        field.validationSchema.min
      } option${field.validationSchema.min === 1 ? '' : 's'} to be selected.`
    );
  }

  /** Handle Maximum Selected Options Validation */
  if (field.validationSchema.max != null) {
    yupField = yupField.max(
      field.validationSchema.max,
      `${field.label || 'Value'} cannot have more than ${
        field.validationSchema.max
      } option${field.validationSchema.max === 1 ? '' : 's'} selected.`
    );
  }
  return yupField;
};

export const buildFileUploadValidationSchema: any = () => {
  let yupField = Yup.mixed()
    .test('required', 'You need to provide at least one file', (files) => {
      if (files?.length > 0) {
        return true;
      }
      return false;
    })
    .test(
      'progress',
      'You need to wait for the upload to complete',
      (files) => {
        if (files?.length > 0) {
          for (let file of files) {
            if (file.uploadProgress < 100) {
              return false;
            }
          }
        }
        return true;
      }
    );
  return yupField;
};

export const buildYupStringValidationForTimestampField: any = (
  field: FormFieldDefinition,
  customInputsRefs: CustomInputRefsProps
) => {
  const { datePartValue, timePartValue } = getRefValues(
    customInputsRefs,
    field.id
  );

  const isRequiredAndNoDate =
    field.validationSchema.isRequired && !datePartValue;
  const isRequiredAndNoTime =
    field.validationSchema.isRequired && !timePartValue;

  let yupField = Yup.string();
  if (isRequiredAndNoDate || isRequiredAndNoTime) {
    yupField = yupField.required('Start Date and Start Time are Required');
  }

  return yupField;
};

export const buildYupStringValidationForLocalDateRangeField: any = (
  field: FormFieldDefinition,
  customInputsRefs: CustomInputRefsProps
) => {
  const { datePartValue, datePartEndValue } = getRefValues(
    customInputsRefs,
    field.id
  );

  let yupField = Yup.string();
  if (!field.validationSchema.isRequired) return yupField;

  if (!datePartValue || !datePartEndValue) {
    yupField = yupField.required('Start Date and End Date are Required');
  } else {
    const isEndDateBefore = isBefore(datePartEndValue, datePartValue);
    if (isEndDateBefore) {
      yupField = yupField.required('End Date cannot be before Start Date');
    }
  }

  return yupField;
};

export const buildYupStringValidationForLocalTimeRangeField: any = (
  field: FormFieldDefinition,
  customInputsRefs: CustomInputRefsProps
) => {
  const { timePartValue, timePartEndValue } = getRefValues(
    customInputsRefs,
    field.id
  );

  let yupField = Yup.string();
  if (!field.validationSchema.isRequired) return yupField;

  if (!timePartValue || !timePartEndValue) {
    yupField = yupField.required('Start Time and End Time are Required');
  } else {
    const isEndTimeBefore = isBefore(timePartEndValue, timePartValue);
    if (isEndTimeBefore) {
      yupField = yupField.required('End Time cannot be before Start Time');
    }
  }

  return yupField;
};

export const buildYupValidationForRuleBuilderField: any = (
  _field: FormFieldDefinition
) => {
  let yupField = Yup.object().nullable();

  return yupField;
};

export const buildYupValidationForColorField: any = (
  _field: FormFieldDefinition
) => {
  let yupField = Yup.array().nullable();

  return yupField;
};

export const buildYupValidationForImagesField: any = (
  _field: FormFieldDefinition
) => {
  let yupField = Yup.array().nullable();

  return yupField;
};

export const buildYupValidationForPhotoTilesField: any = (
  _field: FormFieldDefinition
) => {
  let yupField = Yup.array().nullable();

  return yupField;
};

export const buildYupValidationForAddressField: any = (
  field: FormFieldDefinition
) => {
  let yupField;
  if (field.validationSchema.isRequired) {
    yupField = Yup.object({
      street1: Yup.string().required('Address 1 is required'),
      street2: Yup.string().nullable(),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      postalCode: Yup.string()
        .required('Required')
        .matches(/^\d+$/, 'Invalid Zip Code') //Regex to match 1 or more digits (Can be refined)
        .min(5, 'Invalid Zip Code')
        .max(5, 'Invalid Zip Code'),
      country: Yup.string().required('Country is required'),
    });
  } else {
    yupField = Yup.object().shape({
      street1: Yup.string()
        .nullable()
        .test(
          'street1-conditional',
          'Street1 is required if any address field is filled out',
          function (value) {
            const { street2, city, state, postalCode, country } = this.parent;
            const isAnyOtherFieldFilled =
              street2 || city || state || postalCode || country;
            return isAnyOtherFieldFilled ? !!value : true;
          }
        ),
      street2: Yup.string().nullable(), // Street2 remains optional without additional validation
      city: Yup.string()
        .nullable()
        .test('city-conditional', 'Required', function (value) {
          const { street1, street2, state, postalCode, country } = this.parent;
          const isAnyOtherFieldFilled =
            street1 || street2 || state || postalCode || country;
          return isAnyOtherFieldFilled ? !!value : true;
        }),
      state: Yup.string()
        .nullable()
        .test('state-conditional', 'Required', function (value) {
          const { street1, street2, city, postalCode, country } = this.parent;
          const isAnyOtherFieldFilled =
            street1 || street2 || city || postalCode || country;
          return isAnyOtherFieldFilled ? !!value : true;
        }),
      postalCode: Yup.string()
        .nullable()
        .matches(/^\d*$/, 'Invalid Zip Code') //Regex to match 0 or more digits (Can be refined)
        .test(
          'len',
          'Invalid Zip Code',
          (val) =>
            val === undefined || val === null || val === '' || val.length === 5
        )
        .test('postalCode-conditional', 'Required', function (value) {
          const { street1, street2, city, state, country } = this.parent;
          const isAnyOtherFieldFilled =
            street1 || street2 || city || state || country;
          return isAnyOtherFieldFilled ? !!value && value.length === 5 : true;
        }),
      country: Yup.string()
        .nullable()
        .test(
          'country-conditional',
          'Country is required if any address field is filled out',
          function (value) {
            const { street1, street2, city, state, postalCode } = this.parent;
            const isAnyOtherFieldFilled =
              street1 || street2 || city || state || postalCode;
            return isAnyOtherFieldFilled ? !!value : true;
          }
        ),
    });
  }

  return yupField;
};

export const buildYupStringValidationForTimestampRangeField: any = (
  field: FormFieldDefinition,
  customInputsRefs: CustomInputRefsProps
) => {
  const { datePartValue, datePartEndValue, timePartValue, timePartEndValue } =
    getRefValues(customInputsRefs, field.id);

  let yupField = Yup.string();
  if (!field.validationSchema.isRequired) return yupField;

  if (
    !datePartValue ||
    !datePartEndValue ||
    !timePartValue ||
    !timePartEndValue
  ) {
    yupField = yupField.required(
      'Start Date, End Date, Start Time & End Time are Required'
    );
  } else {
    const isEndDateBefore = isBefore(datePartEndValue, datePartValue);
    const sameRangeDates = isSameDay(datePartValue, datePartEndValue);

    if (isEndDateBefore) {
      yupField = yupField.required('End Date cannot be before Start Date');
    } else if (sameRangeDates) {
      yupField = buildYupStringValidationForLocalTimeRangeField(
        field,
        customInputsRefs
      );
    }
  }

  return yupField;
};

export const buildYupValidationForField: any = (
  field: FormFieldDefinition,
  customInputsRefs: CustomInputRefsProps
) => {
  switch (field.type) {
    case FormFieldType.TEXT:
    case FormFieldType.TEXT_AREA:
    case FormFieldType.LOCAL_DATE:
    case FormFieldType.LOCAL_TIME:
      return buildYupStringValidationForField(field);
    case FormFieldType.TIMESTAMP:
      return buildYupStringValidationForTimestampField(field, customInputsRefs);
    case FormFieldType.LOCAL_DATE_RANGE:
      return buildYupStringValidationForLocalDateRangeField(
        field,
        customInputsRefs
      );
    case FormFieldType.LOCAL_TIME_RANGE:
      return buildYupStringValidationForLocalTimeRangeField(
        field,
        customInputsRefs
      );
    case FormFieldType.TIMESTAMP_RANGE:
      return buildYupStringValidationForTimestampRangeField(
        field,
        customInputsRefs
      );
    case FormFieldType.PASSWORD:
      return buildFieldValidationForPassword(field);
    case FormFieldType.EMAIL:
      return buildEmailValidationSchema(field);
    case FormFieldType.NUMBER:
      return buildFieldValidationForNumber(field);
    case FormFieldType.TOGGLE_SWITCH:
      return buildFieldValidationForBoolean(field);
    case FormFieldType.SELECT:
      return buildYupFieldValidationForSelect(field);
    case FormFieldType.TEXT_LIST_EMAIL:
      return buildEmailListValidationSchema(field);
    case FormFieldType.IRONCLAD_CLICKWRAP:
      return buildIroncladClickWrapValidationSchema(field);
    case FormFieldType.FILE_UPLOAD:
      return buildFileUploadValidationSchema(field);
    case FormFieldType.CHECKBOX_GROUP_CARDS:
      return buildFieldValidationForCheckboxGroup(field);
    case FormFieldType.ADDRESS:
      return buildYupValidationForAddressField(field);
    case FormFieldType.CHIPS:
      return buildFieldValidationForChipInput(field);
    case FormFieldType.COMBO_BOX:
      return buildFieldValidationForChipInput(field);
    case FormFieldType.RULE_BUILDER:
      return buildYupValidationForRuleBuilderField(field);
    case FormFieldType.COLORS:
      return buildYupValidationForColorField(field);
    case FormFieldType.IMAGES:
      return buildYupValidationForImagesField(field);
    case FormFieldType.RADIO:
      return buildYupValidationForRadioGroup(field);
    case FormFieldType.CURRENCY:
      return buildFieldValidationForNumber(field);
    case FormFieldType.PHOTO_TILES:
      return buildYupValidationForPhotoTilesField(field);
    default:
      return Yup.object();
  }
};
