'use client';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ObDateTimePicker, ObInput, ObInputProps } from '../../../index';
import { validateTimeInput } from './utils/validateTimeInput';

export interface ObInputTimePickerProps {
  inputId?: string;
  initialTime?: any;
  hasError?: boolean;
  isDisabled?: boolean;
  size?: ObInputProps['size'];
  onTimeChangeCb?: (selection: any) => void;
  onTimeInputChangeCb?: (selection: any) => void;
}

export const ObInputTimePicker = forwardRef<any, ObInputTimePickerProps>(
  (
    {
      inputId,
      initialTime = null,
      hasError = false,
      isDisabled = false,
      size = 'medium',
      onTimeChangeCb,
      onTimeInputChangeCb,
    },
    ref
  ) => {
    const datepickerRef = useRef(null);

    const [time, setTime] = useState(initialTime);
    const [error, setError] = useState(hasError);

    useEffect(() => {
      if (!time) setError(hasError);
    }, [hasError, time]);

    const handleTimeValidationAndSelect = useCallback((value: string) => {
      const validatedTime = validateTimeInput(value);
      const calendarRef: any = datepickerRef?.current;

      if (calendarRef?.isCalendarOpen()) {
        return;
      }

      if (validatedTime) {
        calendarRef?.handleTimeChange(validatedTime);
        setError(false);
      } else {
        setError(true);
      }

      calendarRef?.setOpen(false);
    }, []);

    const handleOnInputChange = useCallback(
      (value: string) => {
        if (onTimeInputChangeCb) {
          onTimeInputChangeCb(value);
        }
      },
      [onTimeInputChangeCb]
    );

    const handleOnBlur = useCallback(
      (value: string) => {
        setTimeout(() => {
          handleTimeValidationAndSelect(value);
        }, 200);
      },
      [handleTimeValidationAndSelect]
    );

    const handleOnKeyDown = useCallback(
      (args: any) => {
        const { key, value } = args;
        if (key === 'Enter') {
          handleTimeValidationAndSelect(value);
        } else if (key === 'Escape') {
          const calendarRef: any = datepickerRef?.current;
          calendarRef?.setOpen(false);
        }
      },
      [handleTimeValidationAndSelect]
    );

    const handleTimeChange = useCallback(
      (value: any) => {
        setTime(value);
        setError(false);

        if (onTimeChangeCb) {
          onTimeChangeCb(value);
        }
      },
      [onTimeChangeCb]
    );

    useImperativeHandle(
      ref,
      () => ({
        id: inputId,
        getValue: () => time,
        setValue: (value: any) => handleTimeValidationAndSelect(value),
        setError,
      }),
      [handleTimeValidationAndSelect, inputId, time]
    );

    return (
      <ObDateTimePicker
        datepickerRef={datepickerRef}
        initialTime={time}
        type='time'
        timeFormat='h:mm aa'
        onChangeCb={handleTimeChange}
        placeholderText='Type or Select a Time'
        onCalendarOpenCb={() => {
          const calendarRef: any = datepickerRef?.current;
          calendarRef.calendar.componentNode
            .querySelector('.react-datepicker__time-list-item[tabindex="0"]')
            .focus();
        }}
        onCalendarCloseCb={() => {
          // Fire a validation
          const calendarRef: any = datepickerRef?.current;
          calendarRef.input.focus();
          calendarRef.input.blur();
        }}
      >
        <ObInput
          inputId={inputId}
          data-testid='ob-time-picker-input'
          icon-right-data-testid='ob-time-picker-input-clock-icon'
          iconRight='clock'
          placeholder='Type or Select a Date'
          size={size}
          error={error}
          isDisabled={isDisabled}
          onBlurCallback={handleOnBlur}
          onChangeCallback={handleOnInputChange}
          onKeyDownCallback={handleOnKeyDown}
        />
      </ObDateTimePicker>
    );
  }
);
