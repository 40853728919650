import {
  LandingPageCard,
  LandingPageCardProps,
  LandingPageList,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import LandingPage from '../../state/mobx-experiment/landing-page/landing-page';

export interface LandingPageListObserverProps
  extends Pick<LandingPageCardProps, 'variant'> {
  landingPages: Array<LandingPage>;
  /**
   * Optional. If not provided the card will default to navigating to the landing page detail page.
   * @param obrn
   * @returns
   */
  onCardClickedCallback?: (obrn: string) => void;
}

export const LandingPageListObserver = observer(
  ({
    variant,
    landingPages,
    onCardClickedCallback,
  }: LandingPageListObserverProps) => {
    const { navigateToLandingPageWithObrn } = useAppNavigation();

    return (
      <LandingPageList
        landingPages={landingPages.map((landingPage) => {
          return {
            variant: variant,
            obrn: landingPage.obrn,
            pageTitle: landingPage.pageTitle,
            url: landingPage.url,
            landingPageType: landingPage.type,
            landingPageScreenshotPublicUrl:
              landingPage.thumbnailUrl ?? undefined,
            onPreviewCallback: () => {
              window.open(landingPage.url, '_blank');
            },
            onCardClickedCallback: () => {
              if (onCardClickedCallback) {
                onCardClickedCallback(landingPage.obrn);
              } else {
                navigateToLandingPageWithObrn(landingPage.obrn);
              }
            },
          };
        })}
        cardRenderFunction={function (
          cardProps: LandingPageCardProps
        ): ReactNode {
          return <LandingPageCard {...cardProps} />;
        }}
      />
    );
  }
);
