import Creative from '../../state/mobx-experiment/creative/creative';
import BaseSetting from '../../state/mobx-experiment/creative/setting/base-setting';
import { SettingSectionWithSettingDefinition } from '../../state/mobx-experiment/creative/template/setting-section.type';
import Template from '../../state/mobx-experiment/creative/template/template';
import { TemplateSettingsSectionDisplay } from './creative-builder.types';

/**
 * Formats Setting Values from a Creative into Sections and Sub-Sections
 * that are specified the given Creative Template. This is merging data
 * from two different sources and mapping them to a single shape that is
 * used by the Creative Builder to render the settings in the left panel
 */
export class TemplateSettingsDisplayBuilder {
  private formattedSections: Array<TemplateSettingsSectionDisplay>;

  /**
   * Takes Template Setting Schema from the API and maps it to a frontend
   * friendly shape that includes the fields. This makes rendering the config
   * sections, sub-sections and their child fields simple and consolidates all
   * of the mapping and field lookup logic to a single place and execution
   */
  constructor(templateSettingsSchema: Template, creative: Creative) {
    this.formattedSections = this.buildSectionsForDisplay(
      templateSettingsSchema.builderSections,
      creative.settings
    );
  }

  getOrganizedSections(): Array<TemplateSettingsSectionDisplay> {
    return this.formattedSections;
  }

  printOrganizeSection(): void {
    console.log(JSON.stringify(this.formattedSections, null, 2));
  }

  private buildSectionsForDisplay = (
    settingSections: Array<SettingSectionWithSettingDefinition>,
    settingsMap: Map<string, BaseSetting>
  ): Array<TemplateSettingsSectionDisplay> => {
    const organizedSections: Array<TemplateSettingsSectionDisplay> =
      settingSections.map((section) => {
        return {
          id: section.id,
          name: section.name,
          settings: section.settingDefinitions
            .map((definition) => {
              return settingsMap.get(definition.id);
            })
            .filter((setting) => setting != null) as Array<BaseSetting>,
          subSections:
            section.subSections?.map((subSection) => {
              return {
                id: subSection.id,
                name: subSection.name,
                settings: section.settingDefinitions
                  .map((definition) => {
                    return settingsMap.get(definition.id);
                  })
                  .filter((setting) => setting != null) as Array<BaseSetting>,
              };
            }) ?? [],
        };
      });

    return organizedSections;
  };
}
