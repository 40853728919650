import { createTheme as createMuiTheme, ThemeOptions } from '@mui/material';
import 'inter-ui/inter.css';

const { breakpoints } = createMuiTheme();

export interface CustomTheme {
  palette: {
    background: {
      paperSubtle?: string;
    };
  };
}

const headingFontFamilyOption1 = 'brandon-grotesque, sans-serif';
const bodyFontFamilyOption1 = 'sofia-pro, sans-serif';

const headingFontFamilyOption2 = 'Inter, sans-serif';
const bodyFontFamilyOption2 = 'Inter, sans-serif';

const headingFontFamily = headingFontFamilyOption2;
const bodyFontFamily = bodyFontFamilyOption2;
export const themeOptionsBase: ThemeOptions = {
  breakpoints,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTab: {},
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
        sizeLarge: {
          fontSize: 15,
        },
        sizeMedium: {
          fontSize: 14,
        },
        sizeSmall: {
          fontSize: 13,
        },
      },
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    h1: {
      fontSize: 4 * 8,
      fontFamily: headingFontFamily,
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: '-0.025em',
    },
    h2: {
      fontSize: 4 * 7,
      fontFamily: headingFontFamily,
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: 1.5,
      textTransform: 'none',
      letterSpacing: '-0.025em',
    },
    h3: {
      fontSize: 4 * 6,
      fontFamily: headingFontFamily,
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: 1.5,
      textTransform: 'none',
      letterSpacing: '-0.025em',
    },
    h4: {
      fontSize: 4 * 5,
      fontFamily: headingFontFamily,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 14,
      fontFamily: headingFontFamily,
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: 1.5,
      textTransform: 'none',
      letterSpacing: '-0.025em',
    },
    h6: {
      fontSize: 14,
      fontFamily: headingFontFamily,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 1.5,
      textTransform: 'none',
      letterSpacing: '-0.025em',
    },
    body1: {
      fontFamily: bodyFontFamily,
      fontSize: 4 * 3.5,
    },
    body2: {
      fontFamily: bodyFontFamily,
    },
    button: {
      fontWeight: 500,
      fontFamily: headingFontFamily,
      textTransform: 'none',
    },
  },
};
