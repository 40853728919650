import {
  CampaignHighlightCard,
  CampaignHighlightProps,
} from '../../../business-objects';
import { BaseSection } from './components/base-section';

export interface CampaignHighlightSectionProps {
  campaignHighlights: Array<Omit<CampaignHighlightProps, 'variant'>>;
}

/**
 * Responsible for rendering the campaign highlights section
 * on the campaign detail page.
 * @returns
 */
export const CampaignHighlightSection = ({
  campaignHighlights,
}: CampaignHighlightSectionProps) => {
  return (
    <BaseSection
      heading='Highlights'
      subheading='Each highlight will be featured prominently in the campaign'
    >
      <div className='grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 gap-3 w-full '>
        {campaignHighlights.map((highlight) => {
          return (
            <CampaignHighlightCard
              key={`${highlight.highlightType}#${highlight.highlightedObjectId}`}
              {...highlight}
              variant={'grid'}
            />
          );
        })}
      </div>
    </BaseSection>
  );
};
