import { cva, cx } from 'class-variance-authority';
import { ReactNode } from 'react';

export type PageBackground =
  | 'scrollable-gradient'
  | 'scrollable-gradient-fixed'
  | 'non-scrollable-gradient'
  | 'none';
export type PageContentPosition =
  | 'center-center'
  | 'center-top'
  | 'center-between';

export interface FullPageLayoutProps {
  /**
   * Changes position of content on the page using flex box
   */
  contentPosition?: PageContentPosition;
  background?: PageBackground;
  fullScreen?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
}

/**
 * Simple Helper Component to have a DRY way to specify full page layouts for our application
 * @param param0
 * @returns
 */
export const BasePageLayout = ({
  contentPosition,
  background,
  fullScreen,
  fullWidth = false,
  children,
}: FullPageLayoutProps) => {
  const pageClasses = cva('flex flex-col w-full', {
    variants: {
      contentPosition: {
        'center-center': ['justify-center', 'items-center', 'w-full'],
        'center-top': ['justify-start', 'items-center'],
        'center-between': ['justify-between', 'items-center'],
      },
      fullWidth: {
        true: ['w-full'],
        false: [],
      },
      fullScreen: {
        true: ['h-screen'],
        false: [],
      },
    },
    defaultVariants: {
      contentPosition: 'center-center',
      fullScreen: true,
    },
  });
  const backgroundClasses = cva('flex flex-col ', {
    variants: {
      contentPosition: {
        'center-center': ['justify-center', 'items-center', 'w-full'],
        'center-top': ['justify-start', 'items-center'],
        'center-between': ['justify-between', 'items-center'],
      },
      background: {
        'scrollable-gradient': ['dark:bg-bg-gradient-top-right-dark'],
        'scrollable-gradient-fixed': [''],
        'non-scrollable-gradient': [''],
        none: [],
      },
      fullScreen: {
        true: ['min-h-screen '],
        false: [],
      },
    },
    defaultVariants: {
      background: 'scrollable-gradient',
      contentPosition: 'center-center',
      fullScreen: true,
    },
  });
  return (
    <div
      className={cx(
        ' z-[0]',
        backgroundClasses({ contentPosition, background, fullScreen })
      )}
    >
      {background === 'scrollable-gradient-fixed' && (
        <div className='fixed top-0 left-0 w-full h-full bg-cover bg-no-repeat bg-fixed dark:bg-bg-gradient-top-right-dark pointer-events-none z-[-10]' />
      )}
      <div
        className={cx(
          '',
          pageClasses({ contentPosition, fullScreen, fullWidth })
        )}
      >
        {children}
      </div>
    </div>
  );
};
