import { useDialogService } from '@outbound/design-system';
import { SettingCardRowWithButton } from '@outbound/design-system/src/components/organisms/ob-setting-card-with-button/setting-card-row-with-button';
import { FC, useCallback } from 'react';
import { CardDivider } from '../../../../../components/elements/card-divider';

import { useAuth0Extended } from '../../../../../auth/use-auth0-extended';
import { useDeleteWorkspaceMutation } from '../../../../../query/workspace/use-workspace-endpoints';

interface WorkspaceDetailsDangerProps {
  workspaceName: string;
  loading: boolean;
}

const WorkspaceDetailsDanger: FC<WorkspaceDetailsDangerProps> = ({
  workspaceName,
  loading,
}) => {
  const { openTypeToConfirmation } = useDialogService();

  const { switchToUserOnlyAuthentication } = useAuth0Extended();

  const { mutateAsync: deleteWorkspace } = useDeleteWorkspaceMutation();

  const logoutOfOrganizationAndRedirectToWorkspaceCreationPage =
    useCallback(() => {
      switchToUserOnlyAuthentication('/my-workspaces');
    }, [switchToUserOnlyAuthentication]);

  const onDeleteWorkspace = useCallback(async (): Promise<void> => {
    try {
      await deleteWorkspace({
        name: workspaceName,
      });
      logoutOfOrganizationAndRedirectToWorkspaceCreationPage();
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }, [
    deleteWorkspace,
    logoutOfOrganizationAndRedirectToWorkspaceCreationPage,
    workspaceName,
  ]);

  const openDeleteWorkspaceConfirmation = useCallback(() => {
    openTypeToConfirmation({
      body: [
        "You are about to delete your company's entire Outbound account and all its data. This action is permanent and once complete cannot be undone!",
        'You and all other workspace members will be unable to access your data.',
      ],
      title: 'Confirm workspace deletion',
      expectedValue: workspaceName,
      inputLabel: 'Enter workspace name to confirm',
      affirmativeActionButtonLabel: 'Delete',
      affirmativeActionButtonCallback: onDeleteWorkspace,
    }).catch(() => {
      // Not sure why this is here
    });
  }, [onDeleteWorkspace, openTypeToConfirmation, workspaceName]);

  return (
    <div data-testid='danger-zone'>
      <CardDivider />

      <SettingCardRowWithButton
        title='Delete Workspace'
        body='Deleting a Workspace will remove all your data from Outbound. This action is permanent and cannot be
            undone.'
        buttonLabel='Delete Workspace'
        handleButtonClickCallback={openDeleteWorkspaceConfirmation}
        color={'error'}
        loading={loading}
      />
    </div>
  );
};

export default WorkspaceDetailsDanger;
