import { ObInputLabel, ObInputTimePicker } from '../../../../';
import type { CustomFieldComponentsProps } from './custom-field-components';

type TimeBlockProps = Omit<
  CustomFieldComponentsProps,
  'fieldType' | 'fieldTypeSettings'
> & {
  type: 'start' | 'end';
  timeStartRef: any;
  timeEndRef: any;
  updateFormValues: (arg: any) => void;
};

const TimeBlock = ({
  type,
  id,
  isRequired = false,
  hideLabel = false,
  size,
  isDisabled,
  errors,
  updateFormValues,
  field,
  timeStartRef,
  timeEndRef,
}: TimeBlockProps) => {
  const isStart = type === 'start';
  const inputId = `${id}${isStart ? '_timeStart' : '_timeEnd'}`;
  const label = isStart ? 'Start Time' : 'End Time';
  const ref = isStart ? timeStartRef : timeEndRef;

  return (
    <>
      <ObInputLabel
        id={inputId}
        label={label}
        isRequired={isRequired}
        visuallyHidden={hideLabel}
        className='-mt-1 mb-1'
      />
      <ObInputTimePicker
        inputId={inputId}
        size={size}
        isDisabled={isDisabled}
        hasError={!!errors[id]}
        ref={ref}
        onTimeChangeCb={(time) => {
          const value = isStart ? { timePart: time } : { timePartEnd: time };
          updateFormValues({ [id]: value });
          field.onChange('');
        }}
      />
    </>
  );
};

type TimeRangeCustomFieldProps = Omit<
  CustomFieldComponentsProps,
  'fieldType' | 'fieldTypeSettings'
> & {
  timeStartRef: any;
  timeEndRef: any;
  updateFormValues: (arg: any) => void;
};

export const TimeRangeCustomField = ({
  size,
  field,
  errors,
  id,
  isRequired = false,
  isDisabled = false,
  hideLabel = false,
  timeStartRef,
  timeEndRef,
  updateFormValues,
}: TimeRangeCustomFieldProps) => {
  const sharedProps = {
    id,
    isRequired,
    isDisabled,
    hideLabel,
    size,
    errors,
    updateFormValues,
    field,
    timeStartRef,
    timeEndRef,
  };

  return (
    <div className='sm:flex-row flex flex-col'>
      <div className='sm:pr-2 w-full pr-0'>
        <TimeBlock
          {...sharedProps}
          type='start'
        />
      </div>
      <div className='sm:pl-2 sm:mt-0 w-full pl-0 mt-4'>
        <TimeBlock
          {...sharedProps}
          type='end'
        />
      </div>
    </div>
  );
};
