import {
  AnimatedTemplateProps,
  DisplayAdAnimated0001,
  DisplayAdAnimated2,
  GoogleDisplayAdStatic,
  GoogleSearch01,
} from '@outbound/design-system';

// Define a mapping from string to component
export const templateComponentMapping: {
  [key: string]: React.FC<AnimatedTemplateProps>;
} = {
  GoogleSearch01: GoogleSearch01,
  GoogleDisplayAdStatic: GoogleDisplayAdStatic,
  //@ts-ignore
  DisplayAdAnimated0001: DisplayAdAnimated0001,
  DisplayAdAnimated2: DisplayAdAnimated2,
};
