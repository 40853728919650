import { ObLoadingSpinner } from '../../../../../components/elements/ob-loading-spinner/ob-loading-spinner';
import { TemplateComponentProps } from '../../../template-component-props.type';

export const GoogleDisplayAdStatic: React.FC<TemplateComponentProps> = ({
  values,
}: TemplateComponentProps) => {
  if (values == null) {
    return (
      <>
        <ObLoadingSpinner
          includeBrand={true}
          fadeIn={true}
        />
      </>
    );
  }

  return (
    <div
      style={{
        backgroundColor: '#202124',
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      GOOGLE DISPLAY AD TEMPLATE
    </div>
  );
};
