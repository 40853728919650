import {
  CreativeStatusType,
  ServerDelta,
  TemplateMediumType,
} from '@outbound/types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { BaseModel } from '../base-model';

import { patchNestedLocalModelMap } from '../framework/patch/nested-model-map-patch';
import { RootStore } from '../root-store';
import BaseSetting from './setting/base-setting';
import { SyntheticSettingResource } from './setting/setting-transformer';
import Template from './template/template';

class Creative extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    name: '/name',
    lifecycleStatus: '/lifecycleStatus',
    settings: '/settings',
  };

  /**
   * Indicates the latest version of the creative (The last time the creative was updated)
   */

  private _latestVersion: string;

  private _templateId: string;

  private _name: string;

  private _lifecycleStatus: CreativeStatusType;

  private _medium: TemplateMediumType;

  private _settings: Map<string, BaseSetting> = new Map();

  private _isReviewed: boolean; //Temporarily only on the frontend. No Server State for this

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    name: string,
    lifecycleStatus: CreativeStatusType,
    _latestVersion: string,
    medium: TemplateMediumType,
    templateId: string,
    settings: Map<string, BaseSetting>
  ) {
    super(rootStore, 'creative', '1', id, workspaceId);
    this._name = name;
    this._lifecycleStatus = lifecycleStatus;
    this._latestVersion = _latestVersion;
    this._medium = medium;
    this._templateId = templateId;
    this._settings = settings;
    this._isReviewed = false;
  }

  makeObservableInternal() {
    this._settings.forEach((s) => s.makeObservable());
    makeObservable(this, {
      _name: observable,
      _settings: observable,
      _isReviewed: observable,
      _lifecycleStatus: observable,
      _templateId: observable,
      applyPatch: action,
      toJson: action,
      template: computed,
      isValid: computed,
      validationErrors: computed,
    } as any);
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      for (const operation of patch) {
        if (operation.path === Creative.paths.name) {
          this.patchName(operation);
        }
        //These string paths are not the best dev ex. Curious as to how we can improve this.
        if (operation.path === Creative.paths.lifecycleStatus) {
          this.patchLifecycleStatus(operation);
        }

        if (operation.path.startsWith(Creative.paths.settings)) {
          this.patchSettings(operation);
        }
      }
    });
  }

  private patchName(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        this._name = operation.value as string;
        break;
      }
    }
  }

  private patchLifecycleStatus(operation: ServerDelta) {
    switch (operation.op) {
      case 'replace': {
        console.log('Updating Lifecycle Status', operation.value);
        this._lifecycleStatus = operation.value as CreativeStatusType;
        break;
      }
    }
  }

  /**
   * Patching Maps
   *  - We can add items to the map by id. We will use the transformer to create the new setting model.
   * Add Item
   * Remove Item
   *  - We can remove the item from the map by id
   * Update Item
   *  - For Updating the Item we will delegate the patch to the item itself.
   * @param operation
   */
  private patchSettings(operation: ServerDelta) {
    patchNestedLocalModelMap<BaseSetting, SyntheticSettingResource>(
      this._settings,
      operation,
      this._rootStore.creativeStore.creativeTransformer.settingTransformer
    );
  }

  toJson(): Record<string, any> {
    return {
      id: this.id,
      latestVersion: this._latestVersion,
      medium: this._medium,
      name: this._name,
      lifecycleStatus: this._lifecycleStatus,
    };
  }

  get name(): string {
    return this._name;
  }

  get medium(): TemplateMediumType {
    return this._medium;
  }

  get lifecycleStatus(): CreativeStatusType {
    return this._lifecycleStatus;
  }

  get template(): Template {
    return this._rootStore.templateStore.getById(this._templateId)!;
  }

  get isValid(): boolean {
    return (
      this.settings.size > 0 &&
      Array.from(this.settings.values()).every((s) => s.isValid)
    );
  }

  get validationErrors(): Array<{
    settingId: string;
    settingName: string;
    errors: string[];
  }> {
    return Array.from(this.settings.values())
      .filter((s) => !s.isValid)
      .map((s) => ({
        settingId: s.id,
        settingName: s.name,
        errors: s.validationErrors,
      }));
  }

  get settings(): Map<string, BaseSetting> {
    return this._settings;
  }

  get isReviewed(): boolean {
    return this._isReviewed;
  }

  set isReviewed(value: boolean) {
    this._isReviewed = value;
  }
}

export default Creative;
