export const LandingPageIcon = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='landing-page'>
        <g id='Icon'>
          <path
            d='M7 8C7 8.55228 6.55228 9 6 9C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7C6.55228 7 7 7.44772 7 8Z'
            fill='currentColor'
          />
          <path
            d='M10 8C10 8.55228 9.55228 9 9 9C8.44772 9 8 8.55228 8 8C8 7.44772 8.44772 7 9 7C9.55228 7 10 7.44772 10 8Z'
            fill='currentColor'
          />
          <path
            d='M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z'
            fill='currentColor'
          />
          <path
            d='M2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5V8C22 8.55228 21.5523 9 21 9C20.4477 9 20 8.55228 20 8V6H4V18H10C10.5523 18 11 18.4477 11 19C11 19.5523 10.5523 20 10 20H3C2.44772 20 2 19.5523 2 19V5Z'
            fill='currentColor'
          />
          <path
            d='M18 11C18.5523 11 19 11.4477 19 12C19 13.2668 19.2816 13.9508 19.6654 14.3346C20.0492 14.7184 20.7332 15 22 15C22.5523 15 23 15.4477 23 16C23 16.5523 22.5523 17 22 17C20.7332 17 20.0492 17.2816 19.6654 17.6654C19.2816 18.0492 19 18.7332 19 20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20C17 18.7332 16.7184 18.0492 16.3346 17.6654C15.9508 17.2816 15.2668 17 14 17C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15C15.2668 15 15.9508 14.7184 16.3346 14.3346C16.7184 13.9508 17 13.2668 17 12C17 11.4477 17.4477 11 18 11Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
