import { cx } from 'class-variance-authority';
export interface PlaybookCardButtonWrapperProps {
  children?: React.ReactNode;
  onClickCallback?: () => any;
  /**
   * Accessible Label for the button
   */
  label: string;
  className?: string;
}

export const PlaybookCardButtonWrapper = ({
  children,
  onClickCallback,
  label,
  className,
}: PlaybookCardButtonWrapperProps) => {
  return (
    <button
      aria-label={label}
      className={cx(
        `hover:translate-y-[-2px] transition-all ease-in duration-300 hover:ease-out hover:duration-1000 hover:shadow-[0_1px_120px_-30px_rgba(0,0,0,0.2)] hover:shadow-actionPrimaryLight `,
        className
      )}
      onClick={onClickCallback}
    >
      {children}
    </button>
  );
};
