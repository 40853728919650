export const Building = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='solid/general/building-05'>
        <path
          id='Solid'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.19944 1.66675H5.1352C4.69594 1.66674 4.31692 1.66672 4.0048 1.69223C3.67534 1.71914 3.34766 1.77857 3.03234 1.93923C2.56194 2.17892 2.17949 2.56137 1.9398 3.03177C1.77914 3.34709 1.71971 3.67477 1.69279 4.00423C1.66729 4.31637 1.6673 4.69534 1.66732 5.13463L1.66732 16.6667C1.20708 16.6667 0.833984 17.0398 0.833984 17.5001C0.833984 17.9603 1.20708 18.3334 1.66732 18.3334H18.334C18.7942 18.3334 19.1673 17.9603 19.1673 17.5001C19.1673 17.0398 18.7942 16.6667 18.334 16.6667V11.8013C18.334 11.362 18.334 10.983 18.3085 10.6709C18.2816 10.3414 18.2222 10.0138 18.0615 9.69844C17.8218 9.22804 17.4394 8.84558 16.969 8.6059C16.6536 8.44524 16.326 8.38581 15.9965 8.35889C15.6844 8.33339 15.3054 8.3334 14.8661 8.33342L11.6673 8.33342V5.13462C11.6673 4.69534 11.6673 4.31637 11.6418 4.00423C11.6149 3.67477 11.5555 3.34709 11.3948 3.03177C11.1552 2.56137 10.7727 2.17892 10.3023 1.93923C9.98698 1.77857 9.6593 1.71914 9.32984 1.69223C9.01771 1.66672 8.6387 1.66674 8.19944 1.66675ZM4.16732 5.83342C4.16732 5.37318 4.54041 5.00008 5.00065 5.00008H8.33398C8.79422 5.00008 9.16732 5.37318 9.16732 5.83342C9.16732 6.29365 8.79422 6.66675 8.33398 6.66675H5.00065C4.54041 6.66675 4.16732 6.29365 4.16732 5.83342ZM4.16732 9.16675C4.16732 8.70651 4.54041 8.33342 5.00065 8.33342H8.33398C8.79422 8.33342 9.16732 8.70651 9.16732 9.16675C9.16732 9.62699 8.79422 10.0001 8.33398 10.0001H5.00065C4.54041 10.0001 4.16732 9.62699 4.16732 9.16675ZM5.00065 11.6667C4.54041 11.6667 4.16732 12.0398 4.16732 12.5001C4.16732 12.9603 4.54041 13.3334 5.00065 13.3334H8.33398C8.79422 13.3334 9.16732 12.9603 9.16732 12.5001C9.16732 12.0398 8.79422 11.6667 8.33398 11.6667H5.00065Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
