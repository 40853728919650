import { ObLoadingSpinner } from '@outbound/design-system';

const LoadingIndicator = () => {
  return (
    <div
      data-testid={'loading-indicator'}
      className=''
    >
      <ObLoadingSpinner includeBrand />
    </div>
  );
};

export default LoadingIndicator;
