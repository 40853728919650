import { IntegrationXConfigurationCard } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import { IntegrationXWorkspace } from '../../state/mobx-experiment/workspace/types/integration-x-workspace.type';

export interface IntegrationXConfigurationModelCardProps {
  integrationXWorkspace: IntegrationXWorkspace;
}

export const IntegrationXConfigurationModelCard = observer(
  ({ integrationXWorkspace }: IntegrationXConfigurationModelCardProps) => {
    const { navigateToIntegrationConfigurationBySlug } = useAppNavigation();

    const { integration, configuration } = integrationXWorkspace;

    return (
      <IntegrationXConfigurationCard
        variant='micro'
        onNavigateCallback={(e) => {
          e?.stopPropagation();
          e?.preventDefault();
          navigateToIntegrationConfigurationBySlug(integration.slug);
        }}
        name={integration.name ?? 'Unknown'}
        integrationIconUrl={integration.iconImagePublicUrl ?? ''}
        shortDescription={integration.shortDescription ?? 'Unknown'}
        operationalStatus={configuration?.operationalStatus ?? 'NOT_CONFIGURED'}
        lifecycleStatus={configuration?.lifecycleStatus}
        category={integration.category}
      />
    );
  }
);
