import {
  WithAuthenticationRequiredOptions,
  withAuthenticationRequired,
} from '@auth0/auth0-react';
import { ComponentType } from 'react';

type ProtectedRouteProps = {
  component: ComponentType;
  options?: WithAuthenticationRequiredOptions;
};

/**
 * Wraps a React Router Route to Ensure that a User is Authenticated before accessing the Route.
 *
 * If the user is unauthenticated they will be redirected to the login page
 * @constructor
 */
const ProtectedRoute = ({ component }: ProtectedRouteProps) => {
  const ProtectedPage = withAuthenticationRequired(component);

  return <ProtectedPage />;
};

export default ProtectedRoute;
