import { ObTypography } from '@outbound/design-system';
import { ReactNode } from 'react';

export interface TemplateSettingSubSectionProps {
  header?: string;
  children?: ReactNode;
}
export const TemplateSettingSubSection = ({
  header,
  children,
}: TemplateSettingSubSectionProps) => {
  return (
    <>
      {header && (
        <ObTypography
          className=''
          color='secondary'
          variant='body1'
        >
          {header}
        </ObTypography>
      )}
      {children}
    </>
  );
};
