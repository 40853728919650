import {
  PostPaymentBillingPortalSessionRequestResource,
  PostPaymentCheckoutSessionRequestResource,
} from '@outbound/types';
import { useMutation } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

export const useCreatePaymentCheckoutForUserMutation = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    PostPaymentCheckoutSessionRequestResource
  >(['payment:post'], async (data: any) => {
    const response = await auth0AxiosClient.post(
      '/payment-manager/stripe/checkout-session',
      data
    );
    return response.data.sessionUrl;
  });
};

export const useCreatePaymentBillingPortalForUserMutation = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    PostPaymentBillingPortalSessionRequestResource
  >(['payment:post'], async (data: any) => {
    const response = await auth0AxiosClient.post(
      '/payment-manager/stripe/billing-portal',
      data
    );
    return response.data.sessionUrl;
  });
};
