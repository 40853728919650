import { ObInputControlled } from '@outbound/design-system/src/components/elements/ob-input-controlled/ob-input-controlled';
import { observer } from 'mobx-react-lite';
import {
  TemplateSettingBaseInputField,
  TemplateSettingBaseInputFieldProps,
} from '../template-setting-input-field/template-setting-base-input-field';

export interface TemplateSettingFieldTextProps
  extends Omit<TemplateSettingBaseInputFieldProps, 'children'> {
  value: string;
  autoFocus?: boolean;
  onValueUpdatedCallback: (value: string) => void;
  onSettingFocused: (settingId: string) => void;
}

/**
 * Renders Simple Text Fields for Template Settings
 */
export const TemplateSettingFieldText = observer(
  (props: TemplateSettingFieldTextProps) => {
    return (
      <TemplateSettingBaseInputField {...props}>
        <ObInputControlled
          isDisabled={props.isDisabled}
          autoFocus={props.autoFocus}
          placeholder={props.label}
          onValueChangedCallback={props.onValueUpdatedCallback}
          value={props.value}
          isLoading={false}
          inputId={props.id}
          isErrored={props.publishingValidationErrors.length > 0}
          onFocusCallback={() => {
            props.onSettingFocused?.(props.id);
          }}
        />
      </TemplateSettingBaseInputField>
    );
  }
);
