import { ObTypography } from '@outbound/design-system';
import { formatCurrency } from '@outbound/design-system/src/utilities/format-utilities';

interface Props {
  adBudget: number;
}

export const AdvertisingBudgetDetails = ({ adBudget }: Props) => {
  return (
    <>
      <div className='flex flex-row justify-between items-center'>
        <ObTypography
          variant='body1'
          color='primary'
        >
          {`Fund Your Advertising Budget of $${adBudget} Per Day`}
        </ObTypography>
        <ObTypography
          variant='body1'
          color='primary'
        >
          {formatCurrency(208)}
        </ObTypography>
      </div>

      <div className='flex flex-row justify-between items-center  pl-4 gap-2'>
        <div>
          <ObTypography
            variant='body2'
            color='secondary'
          >{`Advertising Budget Funding`}</ObTypography>
          <div className='flex flex-row justify-between items-center ml-4'>
            <ObTypography
              variant='body3'
              color='tertiary'
            >{`Billed throughout the month in $200 increments as needed up to ${formatCurrency(
              adBudget * 30.4
            )}*`}</ObTypography>
          </div>
        </div>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          {formatCurrency(200)}
        </ObTypography>
      </div>

      <div className='flex flex-row justify-between items-right ml-4'>
        <ObTypography
          variant='body2'
          color='secondary'
        >{`Advertising Budget Credit Card Fee (Waived for ACH Payments)`}</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          {formatCurrency(8)}
        </ObTypography>
      </div>
    </>
  );
};
