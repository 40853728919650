'use client';
import { cva, cx } from 'class-variance-authority';
import { ChangeEvent, FC, ReactNode, useEffect, useRef } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';
import { ObField } from '../../../';
import { CustomFieldComponents } from './components/custom-field-components';
import { FormFieldDefinition, FormFieldType } from './ob-form-renderer.types';

export type FieldType = 'TEXT' | 'TEXT_AREA' | 'NUMBER';

export type InputType = 'input' | 'textarea' | 'select';

const inputs = ['TEXT', 'EMAIL', 'NUMBER', 'PASSWORD'];
const textarea = ['TEXT_AREA', 'TEXT_LIST_EMAIL'];

const inlineInputs = ['TOGGLE_SWITCH'];

export type FormRendererFieldProps = {
  id: string;
  label: string;
  isRequired?: boolean;
  readOnly?: boolean;
  hideLabel?: boolean;
  helperText: string;
  errors: any;
  control: any;
  fieldType: FormFieldType;
  /**
   * Indicates that the input should be focused when the component is rendered.
   * Only one input at a time should be autofocused. Currently the last input in a form
   * with this prop set to true will be the one that is focused.
   */
  autofocusInput: boolean | undefined;
  size?: 'small' | 'medium' | 'large';
  /**
   * When a specific field type has additional settings or configuration values outside of the standard
   * field values they can be passed in here. This is a generic type that is specific to the field type
   * and provides typesafety for the input.
   */
  fieldTypeSettings: FormFieldDefinition['fieldTypeSettings'];
  /**
   * Allows the developer to disable the browser's password manager for the field
   * This should only be used for fields that are not actually passwords; Be aware that
   * users sometimes use password managers to store other sensitive information such as
   * social security numbers, credit card numbers, etc.
   *
   * Some may even store addresses etc in these fields as well so we should only use this
   * feature in very limited circumstances where the extension UI is impacting our UX
   */
  disablePasswordManagers?: boolean;
  setCustomInputsRefs?: any;
  disabled?: boolean;
  /**
   * Indicates that the field should be conditionally hidden.
   */
  conditionallyHide?: boolean;

  /**
   * Indicates that the data backing the field is still loading and the field should be in a loading state.
   *
   * Implementation of this is a work in progress. Not all fields support it yet
   */
  isLoading?: boolean;
};

const FormRendererField: FC<FormRendererFieldProps> = ({
  id,
  label,
  isRequired = false,
  readOnly = false,
  hideLabel = false,
  helperText,
  errors,
  control,
  fieldType,
  autofocusInput,
  size = 'medium',
  fieldTypeSettings,
  disablePasswordManagers = false,
  setCustomInputsRefs,
  disabled = false,
  conditionallyHide = false,
}) => {
  const formInputStyles = cva(
    'appearance-none box-border block w-full  border-solid rounded font-sans font-normal border border-borderDefaultNormalLight dark:bg-bgSurfaceDark dark:text-contentPrimaryDark placeholder:text-contentPlaceholderLight dark:placeholder:text-contentPlaceholderDark focus:outline-none focus:shadow-interactive     ',
    {
      variants: {
        state: {
          normal: [
            'text-contentPrimaryLight',
            'dark:text-contentPrimaryDark',
            'dark:border-borderDefaultNormalDark ',
          ],
          error: [
            'border-borderNegativeNormalDark',
            'focus:border-borderNegativeFocusDark',
            'focus-visible:border-borderNegativeFocusDark',
            'focus-visible:ring-borderNegativeFocusDark',
            'focus:interactive-error',
          ],
          disabled:
            'dark:bg-bgSurfaceDark/[.84] dark:border-actionSecondaryHoverBorderDark/[.16] opacity-[0.7] pointer-events-none',
        },
        size: {
          small: ['px-3 py-2'],
          medium: ['px-4 py-3'],
          large: ['px-4 py-4'],
        },
        defaultVariants: {
          state: 'normal',
          size: 'medium',
        },
      },
    }
  );

  const inputType = (fieldType: FormFieldType) => {
    if (inputs.includes(fieldType)) {
      switch (fieldType) {
        case FormFieldType.TEXT:
          return { type: 'text' };
        case FormFieldType.NUMBER:
          return { type: 'number' };
        case FormFieldType.PASSWORD:
          return { type: 'password' };
      }
    }
    return null;
  };

  const standardFieldProps = (field: any) => {
    const inputState = errors[id] ? 'error' : 'normal';
    return {
      className: cx(
        formInputStyles({
          state: disabled ? 'disabled' : inputState,
          size: size,
        })
      ),
      id: id,
      placeholder: label,
      ...(isRequired && { required: true }),
      ...(readOnly && { readOnly }),
      ...(errors[id] && { error: 'true' }),
      ...(autofocusInput && { autoFocus: true, 'data-autofocus': true }),
      ...(disablePasswordManagers && {
        'data-1p-ignore': true,
        'data-lpignore': true,
      }),
      ...(disabled && { disabled }),
      ...field,
      onChange: (event: any) => {
        field.onChange(event);
      },
    };
  };

  /**
   * Based on the field definition this field should be hidden
   */
  if (conditionallyHide) {
    return <></>;
  }

  return (
    <div
      className={`flex transition-opacity duration-500 opacity-100 flex-col`}
    >
      <Controller
        name={id}
        control={control}
        render={({ field }) => {
          let content: ReactNode = null;

          if (inputs.includes(fieldType)) {
            content = (
              <input
                {...standardFieldProps(field)}
                {...inputType(fieldType)}
              />
            );
          } else if (textarea.includes(fieldType)) {
            content = (
              <TextArea
                field={field}
                standardFieldProps={standardFieldProps}
              />
            );
          } else {
            //By Default we will defer to the Custom Field Components
            content = (
              <CustomFieldComponents
                size={size}
                field={field}
                fieldType={fieldType}
                fieldTypeSettings={fieldTypeSettings}
                errors={errors}
                id={id}
                label={label}
                isRequired={isRequired}
                isDisabled={disabled}
                hideLabel={hideLabel}
                setCustomInputsRefs={setCustomInputsRefs}
              />
            );
          }
          return (
            <ObField
              inputId={id}
              isRequired={isRequired}
              displayChildrenInline={inlineInputs.includes(fieldType)}
              label={label}
              helperText={helperText}
              errorText={errors[id]?.message}
              variant={'stand-alone-field'}
            >
              {content}
            </ObField>
          );
        }}
      />
    </div>
  );
};

const TextArea = ({
  field,
  standardFieldProps,
}: {
  field: ControllerRenderProps<FieldValues, string>;
  standardFieldProps: any;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    }
  };
  useEffect(() => {
    adjustHeight();
  }, []);
  return (
    <textarea
      {...standardFieldProps(field)}
      ref={textareaRef}
      onInput={adjustHeight}
      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
        field.onChange(event);
      }}
    ></textarea>
  );
};

export default FormRendererField;
