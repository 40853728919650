/** solid/finance/shopping-cart-01 */
export const ShoppingCart01 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      {...props}
    >
      <path
        d='M1 1.50001H1.56635L1.7905 3.06906L2.31861 6.95064C2.34994 7.18095 2.37717 7.38115 2.41259 7.54581C2.45022 7.72075 2.50403 7.89216 2.607 8.05426C2.762 8.2983 2.98423 8.49234 3.24694 8.61303C3.42144 8.69321 3.59854 8.72342 3.77695 8.73712C3.94489 8.75002 4.14693 8.75001 4.37936 8.75001H8.69306C8.91412 8.75001 9.10681 8.75002 9.2675 8.73802C9.43859 8.72524 9.60833 8.69707 9.77697 8.62282C10.0317 8.51067 10.2501 8.32988 10.408 8.10067C10.5125 7.9489 10.5719 7.78743 10.6165 7.62175C10.6583 7.46616 10.6944 7.27689 10.7357 7.05973L11.4032 3.55541C11.4165 3.48563 11.4319 3.40512 11.4391 3.33412C11.4472 3.25426 11.4519 3.13286 11.4051 3.00026C11.3459 2.83266 11.2291 2.69153 11.0756 2.60203C10.9541 2.53122 10.8339 2.51312 10.7539 2.50615C10.6829 2.49995 10.6009 2.49998 10.5299 2.5L2.71936 2.50001L2.54257 1.26247C2.53522 1.21088 2.52625 1.14787 2.51395 1.09244C2.49947 1.02716 2.47347 0.937015 2.41437 0.844924C2.33667 0.723843 2.22578 0.627667 2.09492 0.567866C1.9954 0.522383 1.90249 0.509395 1.83582 0.504291C1.7792 0.499958 1.71556 0.499983 1.66344 0.500003L1 0.500006C0.723858 0.500006 0.5 0.723863 0.5 1.00001C0.5 1.27615 0.723858 1.50001 1 1.50001Z'
        fill='currentColor'
      />
      <path
        d='M3.5 10.5C3.5 9.94771 3.94772 9.5 4.5 9.5C5.05229 9.5 5.5 9.94771 5.5 10.5C5.5 11.0523 5.05229 11.5 4.5 11.5C3.94772 11.5 3.5 11.0523 3.5 10.5Z'
        fill='currentColor'
      />
      <path
        d='M7.5 10.5C7.5 9.94771 7.94772 9.5 8.5 9.5C9.05229 9.5 9.5 9.94771 9.5 10.5C9.5 11.0523 9.05229 11.5 8.5 11.5C7.94772 11.5 7.5 11.0523 7.5 10.5Z'
        fill='currentColor'
      />
    </svg>
  );
};
