/**
 * Required MimeType and videoCodec support required to play
 * the HEVC video with alpha transparency
 * (Supported in Safari)
 */
export const HEVC_AI_ORB_VIDEO_METADATA = {
  videoCodec: 'hvc1',
  mimeType: 'video/mp4',
};

/**
 * Required MimeType and videoCodec support required to play
 * the WEBM video with alpha transparency
 * (Supported in Chromium)
 */
export const WEBM_AI_ORB_VIDEO_METADATA = {
  videoCodec: 'vp09.00.10.08',
  mimeType: 'video/webm',
};
