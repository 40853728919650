import { ReactNode } from 'react';
import { SectionHeader } from './section-header';

export interface BaseSectionProps {
  heading: string;
  subheading: string;
  children?: ReactNode;
  showPlaceholder?: boolean;
  placeholderNode?: ReactNode;
}
export const BaseSection = ({
  heading,
  subheading,
  children,
  showPlaceholder = false,
  placeholderNode,
}: BaseSectionProps) => {
  return (
    <section className='flex flex-col justify-center items-start flex-1 gap-1'>
      <SectionHeader
        heading={heading}
        subheading={subheading}
      />
      {showPlaceholder && (
        <div className='flex flex-1 self-stretch '>
          <div className=' flex flex-1 justify-center items-center border-2 border-dotted border-dark/border/default/hover rounded-lg p-20 sm:p-40 '>
            {placeholderNode}
          </div>
        </div>
      )}
      {!showPlaceholder && children}
    </section>
  );
};
