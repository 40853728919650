import { KeyboardEvent } from 'react';

export const useKeyboardNavigation = () => {
  // Find and focus the first color picker input
  const focusFirstPickerInput = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();

      const target = event.target as unknown as HTMLElement;
      const parentColorPicker = target?.closest('.ob-color-picker-panel');
      parentColorPicker?.getElementsByTagName('input')[0]?.focus();
    }
  };

  return { focusFirstPickerInput };
};
