export const ArrowNarrowRight = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.58893 0.577452C7.26349 0.252015 6.73586 0.252015 6.41042 0.577452C6.08498 0.902889 6.08498 1.43053 6.41042 1.75596L10.8212 6.16671H1.16634C0.706104 6.16671 0.333008 6.5398 0.333008 7.00004C0.333008 7.46028 0.706104 7.83337 1.16634 7.83337H10.8212L6.41042 12.2441C6.08498 12.5696 6.08498 13.0972 6.41042 13.4226C6.73586 13.7481 7.26349 13.7481 7.58893 13.4226L13.4223 7.5893C13.7477 7.26386 13.7477 6.73622 13.4223 6.41079L7.58893 0.577452Z'
        fill='currentColor'
      />
    </svg>
  );
};
