import { NavItem } from '../../../components/navigation/dashboard-route.type';
import { playbookPageSections } from './playbook-page-section.def';
import { PlaybookV2 } from './playbookv2';

export const playbookRoutes: NavItem = {
  title: 'My Business',
  icon: 'playbooks',
  route: 'playbook',
  component: () => <PlaybookV2 />,
  showInNavMenu: ['workspace-side-nav'],
  childRoutes: [
    /**
     * This should be cleaned up to use the new routing system once
     * we have migrated the locations, personas and services out of the playbook
     */
    ...playbookPageSections.flatMap((pageSection) => {
      return pageSection.pages.map((page) => {
        return {
          title: page.title,
          icon: page.icon,
          index: page.id === 'business-overview',
          route: `${page.id}`,
          component: page.component,
        };
      });
    }),
  ],
};
