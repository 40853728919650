import { addDays, isValid, parse } from 'date-fns';
import en from 'date-fns/locale/en-US';

const DATE_PATTERNS = [
  'MM/dd/yyyy',
  'MMMM d, yyyy',
  'yyyy-MM-dd',
  'MM-dd-yyyy',
  'MMM d yyyy',
];

export const validateDateInput = (inputDate: string): Date | undefined => {
  if (!inputDate) return undefined;
  let parsedDates: any = [];

  DATE_PATTERNS.forEach((formatString) => {
    let parsedDate: any;

    if (inputDate.toLowerCase() === 'today') {
      parsedDate = new Date();
    } else if (inputDate.toLowerCase() === 'tomorrow') {
      parsedDate = addDays(new Date(), 1);
    } else {
      parsedDate = parse(inputDate, formatString, new Date(), {
        locale: en,
      });
    }

    if (isValid(parsedDate)) {
      parsedDates.push(parsedDate);
    }
  });

  if (parsedDates.length) {
    return parsedDates[0] as Date;
  }
};
