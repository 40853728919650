import { ObIconAvatar } from '../../avatars';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantDeprecatedProps } from '../card.type';

interface CustomerProfileCtaProps {
  onNavigateCallback: (CustomerProfileObrn: string) => void;
}

interface CustomerProfileAttributeProps {
  /**
   * The Outbound Resource Name of the CustomerProfile
   */
  obrn: string;

  /*
   * The ID of the CustomerProfile. Unique within the context of the CustomerProfile domain
   */
  id: string;

  /**
   * The name of the CustomerProfile
   */
  name: string;

  /**
   * The description of the CustomerProfile
   */
  description: string;
}

/**
 * The public interface for the CustomerProfile Card
 */
export interface CustomerProfileCardProps
  extends CardVariantDeprecatedProps,
    CustomerProfileCtaProps,
    CustomerProfileAttributeProps {}

/**
 * Internal interface for the CustomerProfile Card used to implement the different variants
 */
export type CustomerProfileImplProps = Omit<
  CustomerProfileCardProps,
  'variant'
>;

export const CustomerProfileCard = ({
  variant,
  ...props
}: CustomerProfileCardProps) => {
  switch (variant) {
    case 'list':
      return <ListCard {...props} />;
    case 'grid':
    case 'micro':
    case 'mini':
    case 'detail':
      return (
        <ObTypography color='negative'>
          Customer Profile <span>{variant}</span> Card Not Implemented
        </ObTypography>
      );
  }
};

const ListCard = ({
  name,
  description,
  onNavigateCallback,
  obrn,
}: CustomerProfileImplProps) => {
  return (
    <div
      className='h-[112px]'
      role='button'
      onClick={() => onNavigateCallback?.(obrn)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNavigateCallback?.(obrn);
        }
      }}
    >
      <ObCardSurface isClickable={true}>
        <div className='flex flex-row gap-4 justify-start items-center p-2'>
          <ObIconAvatar
            icon='userCircle'
            size='x-large'
            shape='circle'
          />
          <div>
            <ObTypography
              variant='subtitle3'
              className='uppercase'
              color='tertiary'
            >
              Customer Profile
            </ObTypography>
            <ObTypography variant='h2'>{name}</ObTypography>
            <ObTypography
              variant='body1'
              color='secondary'
              className='line-clamp-2'
            >
              {description}
            </ObTypography>
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};
