import {
  CustomerProfileCard,
  CustomerProfileCardProps,
} from '../../../business-objects';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { BaseSection } from './components/base-section';

export interface CustomerProfileSectionProps {
  customerProfile: Omit<CustomerProfileCardProps, 'variant'>;
}

/**
 * Customer Profile Section for the Campaign Detail Landing Page
 * @returns
 */
export const CustomerProfileSection = ({
  customerProfile,
}: CustomerProfileSectionProps) => {
  return (
    <BaseSection
      heading='Customer Profile'
      subheading='The Customer Profile that this campaign should target'
    >
      {customerProfile == null ? (
        <>
          <div className='flex justify-center items-center w-full h-48'>
            <ObTypography
              className='text-center'
              variant='body2'
              color='secondary'
            >
              No Customer Profile has been added to this campaign yet.
            </ObTypography>
          </div>
        </>
      ) : (
        <div className='w-full'>
          <CustomerProfileCard
            {...customerProfile}
            variant={'list'}
          />
        </div>
      )}
    </BaseSection>
  );
};
