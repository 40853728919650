import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObTypography } from '../ob-typography/ob-typography';

export interface ObInfoFlagProps {
  heading: string;
  body: string;
  icon: IconSystemKeys;
}

/**
 * Used to indicate non-interactive high and low points on a slider.
 * Specific use case this was developed for is to show the industry high and
 * industry low points on the budget slider
 */
export const ObInfoFlag = ({ heading, body, icon }: ObInfoFlagProps) => {
  return (
    <div className='w-[48px] flex flex-col justify-center items-center'>
      <div className='bg-bgSurfaceDark p-1 rounded-lg'>
        <div className=''>
          <ObTypography
            variant='body3'
            className='text-center'
            color='tertiary'
          >
            {heading}
          </ObTypography>
        </div>
        <div className='flex flex-row justify-center items-center gap-1'>
          <ObIcon
            icon={icon}
            size='small'
            color='tertiary'
          />

          <ObTypography
            variant='body3'
            color='tertiary'
          >
            {body}
          </ObTypography>
        </div>
      </div>
      <div className='h-[44px] w-0 border-dashed border-l border-contentTertiaryDark'></div>
    </div>
  );
};
