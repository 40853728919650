import { useEffect } from 'react';
import { BasePageLayout } from '../../../../../../components/layouts/base-page';
import { MarketingPlanLoading } from '../../../../../onboarding/components/marketing-plan-loading/marketing-plan-loading';
import { useAppNavigation } from '../../../../../../hooks/use-app-navigation';

/**
 * Loading Page for Marketing Plan.
 * We stop at this page to give some weight to what we are doing on the marketing plan.
 * We want to communicate that we are doing a lot of work behind the scenes to build their CUSTOM marketing plan.
 * @returns
 */
export const MarketingPlanBuildingPage = () => {
  const { navigateToTheMarketingPlan } = useAppNavigation();
  useEffect(() => {
    setTimeout(() => {
      navigateToTheMarketingPlan({ showLoadingIndicator: false });
    }, 4000);
  }, [navigateToTheMarketingPlan]);
  return (
    <BasePageLayout
      fullScreen={true}
      contentPosition='center-center'
      background='scrollable-gradient'
    >
      <MarketingPlanLoading />
    </BasePageLayout>
  );
};
