import { CampaignDeploymentStatusType } from '@outbound/types';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

export interface CampaignDeploymentStageIconProps {
  stage: CampaignDeploymentStatusType;
}

export const CampaignDeploymentStatusIcon = ({
  stage,
}: CampaignDeploymentStageIconProps) => {
  switch (stage) {
    case 'FAILED':
      return (
        <ObIcon
          size='medium'
          icon='close'
          color='negative'
        />
      );

    case 'SUCCEEDED':
      return (
        <ObIcon
          size='small'
          icon='check'
          color='positive'
        />
      );
    default:
      return (
        <ObIcon
          size='small'
          icon='RefreshCw05'
          color='informative'
          classNames='animate-spin'
        />
      );
  }
};
