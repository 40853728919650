import { action, makeObservable, observable } from 'mobx';
import { BaseModel } from '../../base-model';

import { ServerDelta } from '@outbound/types';
import { RootStore } from '../../root-store';
import ServiceOffering from './service-offering/service-offering';

export class Service extends BaseModel {
  private _name: string;
  private _description: string;
  private _serviceOfferings: Map<string, ServiceOffering> = new Map<
    string,
    ServiceOffering
  >();

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    name: string,
    description: string,
    serviceOfferings: Map<string, ServiceOffering>
  ) {
    super(rootStore, 'service', '1', id, workspaceId);
    this._name = name;
    this._description = description;
    this._serviceOfferings = serviceOfferings;
  }

  protected makeObservableInternal(): void {
    this._isObservable = true;
    makeObservable(this, {
      _name: observable,
      _description: observable,
      _serviceOfferings: observable,

      applyPatch: action,
      toJson: action,
    } as any);
  }

  applyPatch(_patch: ServerDelta[]): void {
    throw new Error('Method not implemented.');
  }
  toJson(): Record<string, any> {
    throw new Error('Method not implemented.');
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get serviceOfferings(): Array<ServiceOffering> {
    const serviceOfferings = Array.from(this._serviceOfferings.values());
    serviceOfferings.sort((a, b) => a.name.localeCompare(b.name));
    return serviceOfferings;
  }
}
