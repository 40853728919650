import { SubServiceResource } from '@outbound/types';
import { FC } from 'react';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObViewMoreCard } from '../ob-view-more-card/ob-view-more-card';

export interface ObServiceCardProps {
  /**
   * Name of the X
   */
  name: string;
  /**
   * The description of the X
   */
  description?: string;
  /**
   * Human readable descriptions of the demographics of the persona
   */
  subServices: Array<SubServiceResource>;
}

export const ObServiceCard: FC<ObServiceCardProps> = ({
  name,
  description,
  subServices = [],
}: ObServiceCardProps) => {
  return (
    <ObViewMoreCard
      heading={name}
      color='cyan'
      icon={'Certificate02'}
      height='200px'
    >
      <div className='flex flex-col gap-4 text-left'>
        <ObTypography
          className='line-clamp-2'
          variant='body1'
        >
          {description}
        </ObTypography>
        {
          <div className='flex flex-col gap-1'>
            <ObTypography variant='h6'>Subservices</ObTypography>
            <div>
              {subServices.length === 0 && (
                <ObTypography variant='body3'>Not configured</ObTypography>
              )}
              {subServices.map((subService) => (
                <ObTypography
                  key={subService.id}
                  variant='body3'
                >
                  {subService.name}
                </ObTypography>
              ))}
            </div>
          </div>
        }
      </div>
    </ObViewMoreCard>
  );
};
