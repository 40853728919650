import { useEffect, useRef, useState } from 'react';
import { StateDependsOnAsyncSourceProps } from '../../../base-component-props.type';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import {
  ObTimelineCard,
  ObTimelineCardProps,
} from '../../molecules/ob-timeline-card/ob-timeline-card';

export interface ObTimelineProps extends StateDependsOnAsyncSourceProps {
  title: string;
  timelineCards: ObTimelineCardProps[];
}

export const ObTimeline = ({
  title,
  timelineCards,
  isLoading,
}: ObTimelineProps) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        if (entry.target === timelineRef.current) {
          const height = entry.contentRect.height;
          setHeight(height);
        }
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (timelineRef.current) {
      observer.observe(timelineRef.current);
    }

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  return (
    <div className='relative'>
      <div
        ref={timelineRef}
        className='bg-bgSurfaceDark flex flex-col justify-center min-w-[200px] p-5 gap-4 relative rounded-lg'
        style={{ zIndex: '0' }}
      >
        <ObTypography
          variant='h2'
          color='primary'
        >
          {title}
        </ObTypography>
        <ObTimelineCard
          timelineHeight={height}
          eventType='default'
          status='past'
          isLastEvent={false}
          isFutureEventNext={false}
          cardTitle='Create Playbook and Brand Guide'
          cardBody='Now we can launch optimized campaigns to reach your target audience in the right place using the right keywords and maximizing potential business opportunities.'
          isLoading={isLoading}
        />
        {timelineCards?.map((card, index) => (
          <ObTimelineCard
            timelineHeight={height}
            key={`${card.eventType}${card.status}${index}`}
            eventType={card.eventType}
            status={card.status}
            isLastEvent={index === timelineCards.length - 1}
            isFutureEventNext={card.isFutureEventNext}
            cardTitle={card.cardTitle}
            cardBody={card.cardBody}
            channel={card.channel}
            goal={card.goal}
            goalMetrics={card.goalMetrics}
            goalIcon={card.goalIcon}
            timeFrame={card.timeFrame}
            isLoading={isLoading}
          />
        ))}
      </div>
    </div>
  );
};
