import { AddressResource } from '@outbound/types';
import { ControllerRenderProps } from 'react-hook-form';
import {
  ObAddressField,
  ObButtonGroup,
  ObCardOptionCheckboxGroup,
  ObChipInput,
  ObColorsField,
  ObImagesField,
  ObInputDatePicker,
  ObInputLabel,
  ObInputTimePicker,
  ObPhotoTileGrid,
  ObRuleBuilder,
  ObUploadFile,
} from '../../../../';
import { ObInputCombobox } from '../../../atoms/inputs/ob-input-combobox/ob-input-combobox';
import { ObInputMoney } from '../../../elements/ob-input-money/ob-input-money';
import { ObRadioGroup } from '../../../molecules/ob-radio-group/ob-radio-group';
import { useCustomRefs } from '../hooks/useCustomRefs';
import {
  FormFieldDefinition,
  FormFieldType,
  FormFieldTypeSettingsSchema,
} from '../ob-form-renderer.types';
import { useFormService } from '../ob-form-service-provider';
import { DateRangeCustomField } from './date-range-custom-field';
import { SelectCustomField } from './select-custom-field';
import { TimeRangeCustomField } from './time-range-custom-field';
import { ToggleSwitchCustomField } from './toggle-switch-custom-field';

export type CustomFieldComponentsProps = {
  size?: 'small' | 'medium' | 'large';
  field: ControllerRenderProps;
  fieldType: FormFieldType;
  fieldTypeSettings: FormFieldDefinition['fieldTypeSettings'];
  errors: any;
  id: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  hideLabel?: boolean;
  setCustomInputsRefs?: any;
  /**
   * Indicates that the fields data is still loading and the field should be in a loading state.
   */
  isLoading?: boolean;
};

export const CustomFieldComponents = ({
  size = 'medium',
  field,
  fieldType,
  fieldTypeSettings,
  errors,
  id,
  label,
  isDisabled = false,
  isRequired = false,
  hideLabel = false,
  setCustomInputsRefs,
  isLoading = false,
}: CustomFieldComponentsProps) => {
  /**
   * I think we can refactor this to no use this and set the form
   * values directly using the onChange method of the field.
   *
   * We are doing this with AddressField and chips field and it works
   * without any issues.
   **/
  const { updateFormValues } = useFormService();

  const { dateStartRef, timeStartRef, dateEndRef, timeEndRef } = useCustomRefs({
    setCustomInputsRefs,
  });

  const sharedProps = {
    id,
    field,
    isRequired,
    isDisabled,
    size,
  };

  switch (fieldType) {
    case FormFieldType.CURRENCY: {
      return (
        <ObInputMoney
          inputId={id}
          value={field.value}
          onValueChangedCallback={field.onChange}
          isDisabled={isDisabled}
          isErrored={!!errors[id]}
          isLoading={isLoading}
        />
      );
    }

    case FormFieldType.RADIO: {
      const fieldSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.RADIO>;
      return (
        <ObRadioGroup
          isFieldDisabled={isDisabled}
          value={field.value}
          onValueChangedCallback={(value) => field.onChange(value)}
          isLoading={isLoading}
          options={fieldSettings.options}
        />
      );
    }
    case FormFieldType.PHOTO_TILES: {
      const fieldSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.PHOTO_TILES>;
      return (
        <div data-hello>
          <ObPhotoTileGrid
            value={field.value} //Update to Values
            onValueChangedCallback={field.onChange}
            onAssetDetailsClicked={fieldSettings.onAssetDetailsClick}
            isLoading={isLoading}
            fileUploadHandlerFactory={fieldSettings.fileUploadHandlerFactory}
          />
        </div>
      );
    }
    case FormFieldType.IMAGES: {
      const fieldSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.IMAGES>;
      return (
        <ObImagesField
          inputId={id}
          imagesToSelect={fieldSettings.imagesToSelect}
          value={field.value}
          onValueChangedCallback={(values) => {
            field.onChange(values);
          }}
          fileUploadHandlerFactory={fieldSettings.fileUploadHandlerFactory}
        />
      );
    }

    case FormFieldType.COLORS: {
      const fieldSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.COLORS>;

      return (
        <ObColorsField
          inputId={id}
          isLoading={isLoading}
          colorsToSelect={fieldSettings.colorsToSelect}
          value={field.value}
          onValueChangedCallback={(value) => {
            console.log('value', value);
            field.onChange(value);
          }}
        />
      );
    }
    case FormFieldType.RULE_BUILDER: {
      const fieldSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.RULE_BUILDER>;
      return (
        <ObRuleBuilder
          value={field.value}
          isLoading={isLoading}
          onValueChangedCallback={field.onChange}
          schema={fieldSettings.ruleBuilderSchema}
          isAdvancedModeEnabled={fieldSettings.allowAdvancedMode ?? false}
        />
      );
    }
    /**
     * Renders a ComoBox on the form
     * https://storybook.dev.outbound.com/?path=/docs/organisms-combobox--documentation
     */
    case FormFieldType.COMBO_BOX: {
      const fieldSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.COMBO_BOX>;

      return (
        <ObInputCombobox
          value={field.value}
          isDisabled={isDisabled}
          isErrored={!!errors[id]}
          onValueChangedCallback={field.onChange}
          isLoading={isLoading}
          inputId={field.name}
          allowMultiple={fieldSettings.allowMultiple ?? true} //Allow form definition to control if multiple selections are allowed (default is true)
          options={fieldSettings.options.map((option) => {
            return {
              ...option,
              id: option.key as string,
              label: option.displayValue ?? (option.key as string),
            };
          })}
        />
      );
    }
    case FormFieldType.CHIPS: {
      if (field.value === undefined || !Array.isArray(field.value)) {
        field.value = []; //Default Value
      }
      return (
        /**
         * The chip input has a wrapped object value.
         * Typically our APIs won't share this shape so we expose this value
         * as an Array<string> on the form render as that is the most common use case.
         */
        <ObChipInput
          inputId={field.name}
          values={field.value?.map((v: string) => ({ value: v })) ?? []}
          isLoading={isLoading}
          setValues={(value) => {
            const convertedValue = value.map((v) => v.value);
            field.onChange(convertedValue);
          }}
          hasError={false}
        />
      );
    }
    case FormFieldType.ADDRESS: {
      return (
        <ObAddressField
          isRequired={isRequired}
          isLoading={isLoading}
          value={field.value as AddressResource}
          onChange={field.onChange}
          isDisabled={isDisabled}
          errors={errors[id]}
          inputId={id}
        />
      );
    }
    case FormFieldType.TOGGLE_SWITCH: {
      return (
        <ToggleSwitchCustomField
          {...sharedProps}
          label={label}
          isLoading={isLoading}
          hideLabel={hideLabel}
          value={field.value}
          onChangeCallback={field.onChange}
        />
      );
    }
    case FormFieldType.SELECT: {
      const selectSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.SELECT>;
      return (
        <SelectCustomField
          {...sharedProps}
          label={label}
          isLoading={isLoading}
          hideLabel={hideLabel}
          size={size}
          errors={errors}
          options={selectSettings.options}
        />
      );
    }
    case FormFieldType.CHECKBOX_GROUP_CARDS: {
      //Get the Field Specific Settings in a TypeSafe Manor
      const checkboxGroupSettings =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.CHECKBOX_GROUP_CARDS>;
      return (
        <div className={hideLabel ? '' : 'my-3'}>
          <ObCardOptionCheckboxGroup
            isDisabled={isDisabled}
            options={checkboxGroupSettings.options}
            value={field.value}
            onValueUpdatedCallback={field.onChange}
          />
        </div>
      );
    }
    case FormFieldType.BUTTON_GROUP_RADIO: {
      const fieldTypeSettingsAsButtonGroupRadioType =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.BUTTON_GROUP_RADIO>;

      return (
        <div className='mt-3'>
          <ObButtonGroup
            id={id}
            isFullWidth={false}
            size='large' // We always want to use "Large"
            options={fieldTypeSettingsAsButtonGroupRadioType.options}
            isDisabled={isDisabled}
            value={field.value}
            onValueUpdatedCallback={field.onChange}
          />
        </div>
      );
    }
    case FormFieldType.FILE_UPLOAD: {
      const fieldTypeSettingsAsFileUploadType =
        fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.FILE_UPLOAD>;

      return (
        <ObUploadFile
          disabled={isDisabled}
          error={!!errors[id]}
          name={field.name}
          getPreSignedURL={fieldTypeSettingsAsFileUploadType.getPreSignedURL}
          uploadContentUsingPreSignedURL={
            fieldTypeSettingsAsFileUploadType.uploadContentUsingPreSignedURL
          }
          allowedExtensions={
            fieldTypeSettingsAsFileUploadType.allowedExtensions
          }
          maxFileSizeInKB={fieldTypeSettingsAsFileUploadType.maxFileSizeInKB}
          {...fieldTypeSettingsAsFileUploadType}
          onChange={field.onChange}
        />
      );
    }
    case FormFieldType.LOCAL_DATE: {
      return (
        <ObInputDatePicker
          size={size}
          inputId={id + '_dateStart'}
          isDisabled={isDisabled}
          hasError={!!errors[id]}
          ref={dateStartRef}
          onDateChangeCb={field.onChange}
        />
      );
    }
    case FormFieldType.LOCAL_TIME: {
      return (
        <ObInputTimePicker
          size={size}
          inputId={id + '_timeStart'}
          isDisabled={isDisabled}
          hasError={errors[id]}
          ref={timeStartRef}
          onTimeChangeCb={field.onChange}
        />
      );
    }
    case FormFieldType.TIMESTAMP: {
      const dateLabel = 'Date';
      const timeLabel = 'Time';

      return (
        <div className='sm:flex-row flex flex-col'>
          <div className='sm:pr-2 w-full pr-0'>
            <ObInputLabel
              id={id + '_dateStart'}
              isRequired={isRequired}
              label={dateLabel}
              visuallyHidden={hideLabel}
              className='-mt-1 mb-1'
            />
            <ObInputDatePicker
              size={size}
              inputId={id + '_dateStart'}
              isDisabled={isDisabled}
              hasError={!!errors[id]}
              ref={dateStartRef}
              onDateChangeCb={(date) => {
                updateFormValues({ [id]: { datePart: date } });
                field.onChange('');
              }}
            />
          </div>
          <div className='sm:pl-2 sm:mt-0 w-full pl-0 mt-4'>
            <ObInputLabel
              id={id + '_timeStart'}
              isRequired={isRequired}
              label={timeLabel}
              visuallyHidden={hideLabel}
              className='-mt-1 mb-1'
            />
            <ObInputTimePicker
              size={size}
              inputId={id + '_timeStart'}
              isDisabled={isDisabled}
              hasError={!!errors[id]}
              ref={timeStartRef}
              onTimeChangeCb={(time) => {
                updateFormValues({ [id]: { timePart: time } });
                field.onChange('');
              }}
            />
          </div>
        </div>
      );
    }
    case FormFieldType.LOCAL_DATE_RANGE: {
      return (
        <DateRangeCustomField
          {...sharedProps}
          errors={errors}
          hideLabel={hideLabel}
          dateStartRef={dateStartRef}
          dateEndRef={dateEndRef}
          updateFormValues={updateFormValues}
        />
      );
    }

    case FormFieldType.LOCAL_TIME_RANGE: {
      return (
        <TimeRangeCustomField
          {...sharedProps}
          errors={errors}
          hideLabel={hideLabel}
          timeStartRef={timeStartRef}
          timeEndRef={timeEndRef}
          updateFormValues={updateFormValues}
        />
      );
    }

    case FormFieldType.TIMESTAMP_RANGE: {
      return (
        <>
          <DateRangeCustomField
            {...sharedProps}
            errors={errors}
            hideLabel={hideLabel}
            dateStartRef={dateStartRef}
            dateEndRef={dateEndRef}
            updateFormValues={updateFormValues}
          />

          <div className='w-full h-6' />

          <TimeRangeCustomField
            {...sharedProps}
            errors={errors}
            hideLabel={hideLabel}
            timeStartRef={timeStartRef}
            timeEndRef={timeEndRef}
            updateFormValues={updateFormValues}
          />
        </>
      );
    }

    default:
      return null;
  }
};
