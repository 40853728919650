import { FormDefinition, FormFieldType } from '@outbound/design-system';
import { languages, referenceIndustries } from '@outbound/types';

export const BUSINESS_OVERVIEW_FORM_DEF: FormDefinition = {
  id: 'business-overview-form',
  fullWidthSubmitButton: true,
  submitButtonLabel: 'Save',
  heading: '',
  subHeading: '',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'legalBusinessName',
          label: 'The Legal Business Name',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'businessName',
          label: 'Business Name (The Name You Want to Advertise)',
          helperText:
            'This may be a shortened version of your legal business name or a registered DBA name.',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },

        {
          id: 'industry',
          label: 'Industry',
          helperText: '',
          fieldTypeSettings: {
            allowMultiple: false, // Treat this like a select field with a search
            options: referenceIndustries.map((industry) => {
              return {
                key: industry.id,
                value: industry.id,
                displayValue: industry.name,
              };
            }),
          },
          type: FormFieldType.COMBO_BOX,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'website',
          label: 'Website',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'summary',
          label: 'Business Summary',
          helperText:
            'Describe your business in a few sentences.  Used internally by Outbound to understand your business better. What do you do, who do you serve, and what makes you unique?',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT_AREA,
          validationSchema: {
            isRequired: false,
          },
        },

        {
          id: 'languages',
          label: 'Languages Spoken',
          helperText: 'What languages does your business support?',
          type: FormFieldType.COMBO_BOX,
          fieldTypeSettings: {
            options: languages.map((language) => {
              return {
                key: language.value,
                value: language.value,
                displayValue: language.description,
              };
            }),
          },
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'business-contact-information',
      heading: 'Business Contact Information',
      subHeading: '',
      fields: [
        {
          id: 'address',
          label: 'Customer Facing Address',
          helperText:
            'A mailing address for your business (required by some ad platforms). This address may be visible to customers.',
          fieldTypeSettings: {},
          type: FormFieldType.ADDRESS,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'isCustomerPhoneCallsAccepted',
          label: 'Accept Customer Calls',
          helperText:
            'Can customers call you? If so enable this setting to provide a phone number.',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'phoneNumber',
          label: 'Phone Number',
          helperText:
            'What is the phone number that customers can call to reach you?',
          fieldTypeSettings: {},
          conditionallyHideFieldFunction: (formValues) => {
            return !formValues.isCustomerPhoneCallsAccepted;
          },
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'keywords',
      heading: 'Finding Your Business Online',
      subHeading: '',
      fields: [
        {
          id: 'relatedKeywords',
          label: 'What do people search when looking for your business online?',
          helperText:
            'What are some words or phrases a customer might search for to find your business or services you offer?',
          fieldTypeSettings: {},
          type: FormFieldType.CHIPS,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'negativeKeywords',
          label: 'What do people think you offer, but you do not?',
          helperText:
            'Do you get calls asking for services you do not provide? Add those services here and we will do our best to filter out those types of leads.',
          fieldTypeSettings: {},
          type: FormFieldType.CHIPS,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'business-activities',
      heading:
        'Which business activities do you engage in? (Select all that apply)',
      subHeading: '',
      fields: [
        {
          id: 'isRetailOnline',
          label: 'E-Commerce',
          helperText:
            'Do you sell digital or physical products online? Clothing, Online Courses, etc.',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'isRetailInStore',
          label: 'Brick and Mortar Retail',
          helperText: 'Do you sell products in physical stores?',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },

        {
          id: 'isServiceInCustomerLocation',
          label:
            "Services where You Travel to Customer's Locations to Provide Services",
          helperText:
            'Do you travel to your customers home, business or other location to perform services? Examples include real estate, cleaning, repairs, installations, etc.',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'isServiceInBusinessLocation',
          label:
            'Services where Customers Travel to your brick and mortar location to receive services',
          helperText:
            "Do your customers travel to your business' location to receive services? Examples include restaurants, salons, spas, doctors, gyms, studios, etc.",
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'isServiceOnline',
          label: 'Online Services not tied to any specific location',
          helperText:
            'Examples of fully remote services include online consulting, coaching, etc.',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'isNonProfit',
          label: 'Non Profit Organization or Charity',
          helperText:
            'You are a non-profit organization or charity funded by donations, grants or other sources.',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'business-differentiators',
      heading: 'Credibility Indicators',
      subHeading:
        'These details help us to better position your business in against your competition.',
      fields: [
        // {
        //   id: 'uniqueSellingProposition',
        //   label: 'Unique Selling Proposition',
        //   helperText:
        //     'How do you stand out from your competition? What makes you unique? What do you do better than anyone else? What do you want to be known for? Avoid generic statements like "great customer service" or "high quality products".',
        //   fieldTypeSettings: {},
        //   type: FormFieldType.TEXT_AREA,
        //   validationSchema: {
        //     isRequired: false,
        //   },
        // },
        {
          id: 'yearEstablished',
          label: 'Year Established',
          helperText:
            'What year was your business established? If you are a new business, enter the current year.',
          fieldTypeSettings: {},
          type: FormFieldType.NUMBER,
          validationSchema: {
            isRequired: false,
            min: 0,
            max: new Date().getFullYear(),
          },
        },
        {
          id: 'isDifferentiatorVeteranOwned',
          label: 'Veteran Owned Business',
          helperText: 'Are you or is the owner of the business a veteran?',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'isDifferentiatorFamilyOwned',
          label: 'Family Owned Business',
          helperText: 'Is your business family owned?',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
        {
          id: 'isDifferentiatorMadeInUsa',
          label: 'Products Made in the USA',
          helperText:
            'Are the products you sell, provide or install made in the USA?',
          fieldTypeSettings: {},
          type: FormFieldType.TOGGLE_SWITCH,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
  ],
};
