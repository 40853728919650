import { useCallback, useEffect, useState } from 'react';
import { PlaybookPageContent } from '../_common/playbook-page-content';
import { PlaybookPageHeading } from '../_common/playbook-page-heading';
import { BUSINESS_OVERVIEW_PAGE_DEF } from './business-overview.def';

import {
  ObFormRenderer,
  ObLoadingSpinner,
  useObFormRendererContext,
} from '@outbound/design-system';
import {
  useFetchBusinessDetails,
  usePatchBusinessDetails,
} from '../../../../../query/playbook/use-playbook-settings-business-details-endpoints';
import { PlaybookEditFooter } from '../_common/playbook-edit-footer';
import {
  mapApiResourceToFormValues,
  mapFormValuesToApiResource,
} from './forms/business-overview-form-utils';
import { BUSINESS_OVERVIEW_FORM_DEF } from './forms/business-overview-form.def';

/**
 * Primary Component for the Playbook Business Overview Page
 */
export const BusinessOverview = () => {
  const { data: businessDetails, isLoading: isBusinessDetailsLoading } =
    useFetchBusinessDetails();

  const [initialValues, setInitialValues] = useState<any>(
    isBusinessDetailsLoading ? {} : mapApiResourceToFormValues(businessDetails!)
  );

  const [isFormDataReady, setIsForDataReady] = useState<boolean>(
    !isBusinessDetailsLoading
  );

  const { mutateAsync: patchBusinessDetails } = usePatchBusinessDetails();

  useEffect(() => {
    if (!isBusinessDetailsLoading && !isFormDataReady) {
      setInitialValues(mapApiResourceToFormValues(businessDetails!));
      setIsForDataReady(true);
    }
  }, [isFormDataReady, isBusinessDetailsLoading, businessDetails]);

  const submitForm = useCallback(
    async (data: any) => {
      return patchBusinessDetails({ values: mapFormValuesToApiResource(data) });
    },
    [patchBusinessDetails]
  );

  const [areChangesUnsaved, setAreChangesUnsaved] = useState<boolean>(false);

  const { getRegisteredFormById } = useObFormRendererContext();

  const onFormDirtyChange = useCallback((isDirty: boolean) => {
    setAreChangesUnsaved(isDirty);
  }, []);

  return (
    <>
      <article className='flex flex-col gap-8 flex-grow '>
        <PlaybookPageHeading title={BUSINESS_OVERVIEW_PAGE_DEF.title} />
        {!isFormDataReady && (
          <div className='w-full h-full flex justify-center items-center mt-[50%]'>
            <ObLoadingSpinner includeBrand={true} />
          </div>
        )}
        {isFormDataReady && (
          <PlaybookPageContent>
            <ObFormRenderer
              formDefinition={BUSINESS_OVERVIEW_FORM_DEF}
              onSubmitCallback={submitForm}
              defaultValues={initialValues}
              hideSubmitButton={true}
              onFormDirtyChangeCallback={onFormDirtyChange}
            />
          </PlaybookPageContent>
        )}
      </article>

      <PlaybookEditFooter
        areChangesUnsaved={areChangesUnsaved}
        onSaveClickedCallback={async () => {
          getRegisteredFormById(
            BUSINESS_OVERVIEW_FORM_DEF.id
          )?.submitFunction();
        }}
        onDiscardClickedCallback={() => {
          getRegisteredFormById(
            BUSINESS_OVERVIEW_FORM_DEF.id
          )?.discardChangesFunction();
        }}
      />
    </>
  );
};
