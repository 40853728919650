export const Tag01 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_8645_57769)'>
        <path
          d='M5.66602 5.3335H5.67268M1.66602 3.46683L1.66602 6.44984C1.66602 6.77596 1.66602 6.93902 1.70286 7.09247C1.73552 7.22852 1.78939 7.35858 1.8625 7.47787C1.94495 7.61243 2.06025 7.72773 2.29085 7.95833L7.40327 13.0708C8.19531 13.8628 8.59132 14.2588 9.04798 14.4072C9.44967 14.5377 9.88236 14.5377 10.284 14.4072C10.7407 14.2588 11.1367 13.8628 11.9288 13.0708L13.4033 11.5962C14.1953 10.8042 14.5913 10.4082 14.7397 9.95153C14.8702 9.54984 14.8702 9.11715 14.7397 8.71546C14.5913 8.2588 14.1953 7.86279 13.4033 7.07075L8.29085 1.95833C8.06025 1.72773 7.94495 1.61243 7.81039 1.52998C7.6911 1.45687 7.56104 1.403 7.42499 1.37034C7.27154 1.3335 7.10848 1.3335 6.78236 1.3335L3.79935 1.3335C3.05261 1.3335 2.67924 1.3335 2.39403 1.47882C2.14315 1.60665 1.93917 1.81063 1.81134 2.06151C1.66602 2.34672 1.66602 2.72009 1.66602 3.46683ZM5.99935 5.3335C5.99935 5.51759 5.85011 5.66683 5.66602 5.66683C5.48192 5.66683 5.33268 5.51759 5.33268 5.3335C5.33268 5.1494 5.48192 5.00016 5.66602 5.00016C5.85011 5.00016 5.99935 5.1494 5.99935 5.3335Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_8645_57769'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.333008)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
