export const Pencil01 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6625 1.33741C11.7904 0.46527 10.3764 0.465269 9.50422 1.33741L2.90288 7.93873C2.76666 8.07488 2.66361 8.17788 2.57331 8.29424C2.49349 8.3971 2.42243 8.50645 2.36085 8.62116C2.29119 8.75094 2.23892 8.88694 2.16982 9.06673L0.913899 12.3322C0.887712 12.4002 0.874991 12.4712 0.875 12.5415C0.87501 12.6935 0.934436 12.8426 1.04587 12.9541C1.20888 13.1171 1.45259 13.1688 1.66775 13.086L4.80594 11.8791C4.82376 11.8722 4.84193 11.8652 4.8602 11.8581L4.93318 11.8301C5.11294 11.761 5.24898 11.7087 5.37874 11.6391C5.49345 11.5775 5.60281 11.5064 5.70566 11.4266C5.82202 11.3363 5.92501 11.2333 6.06116 11.097L12.6625 4.4957C13.5346 3.62356 13.5346 2.20955 12.6625 1.33741ZM3.13891 9.79711L2.47396 11.5259L4.2028 10.861L3.13891 9.79711Z'
        fill='currentColor'
      />
    </svg>
  );
};
