import { cx } from 'class-variance-authority';
import { FC, ReactNode, useState } from 'react';
import { ObBadge, ObButton, ObTypography } from '../../../..';

export interface CreativeIssue {
  settingLabel: string;
  issueText: string;
  onClickHandler: () => void;
}

export interface CreativeIssuesPopoverProps {
  issues: Array<CreativeIssue>;
}

export const CreativeIssuesPopover: FC<CreativeIssuesPopoverProps> = ({
  issues,
}) => {
  const [showAll, setShowAll] = useState(false);

  const consolidatedIssues = issues.reduce(
    (acc, issue) => {
      const existingIssue = acc.find(
        (item) => item.settingLabel === issue.settingLabel
      );
      if (existingIssue) {
        existingIssue.count++;
        existingIssue.issueText = `Missing Content (${existingIssue.count}+)`;
      } else {
        acc.push({
          ...issue,
          count: 1,
        });
      }
      return acc;
    },
    [] as {
      settingLabel: string;
      issueText: string;
      onClickHandler: (e: any) => Promise<any> | void;
      count: number;
    }[]
  );

  const MAX_ISSUES_TO_SHOW = 4;
  const showLimited =
    consolidatedIssues.length > MAX_ISSUES_TO_SHOW && !showAll;
  const issueListLength = showLimited
    ? MAX_ISSUES_TO_SHOW
    : consolidatedIssues.length;

  return (
    <div className='relative flex flex-col border border-dark/border/default/normal  rounded dark:bg-dark/background/surface max-w-[480px]'>
      <div
        className={cx(
          'flex-grow overflow-auto',
          consolidatedIssues.length > MAX_ISSUES_TO_SHOW && showLimited
            ? 'pb-1'
            : 'pb-0'
        )}
      >
        <IssueList>
          {consolidatedIssues.slice(0, issueListLength).map((issue) => (
            <IssueRow key={`${issue.settingLabel}-${issue.issueText}`}>
              <SettingNameColumn>
                <ObTypography
                  color='secondary'
                  className=' text-ellipsis overflow-hidden line-clamp-1'
                  variant='subtitle3'
                >
                  {issue.settingLabel}
                </ObTypography>
              </SettingNameColumn>
              <ErrorBadgeColumn>
                <ObBadge
                  content={issue.issueText}
                  level={'ERROR'}
                  className='line-clamp-1'
                />
              </ErrorBadgeColumn>
              <ButtonColumn>
                <ObButton
                  variant='outline'
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    issue.onClickHandler?.(e);
                  }}
                  label='View'
                />
              </ButtonColumn>
            </IssueRow>
          ))}
        </IssueList>
      </div>
      {showLimited && consolidatedIssues.length > MAX_ISSUES_TO_SHOW && (
        <button
          className='absolute group bottom-0 left-0 right-0 bg-dark/background/surface bg-opacity-80 py-4 h-[50px] '
          onClick={() => setShowAll(true)}
        >
          <div className='inline-flex bg-dark/background/surface group-hover:bg-dark/background/surface+1 group-active:bg-dark/action/neutral/active p-1 rounded'>
            <ObTypography
              variant='body3'
              as={'span'}
              className='text-center cursor-pointer'
              color='primaryV2'
            >
              View More
            </ObTypography>
          </div>
        </button>
      )}
    </div>
  );
};

type ChildrenProps = { children: ReactNode };

const IssueList = ({ children }: ChildrenProps) => {
  return (
    <div className='grid grid-cols-[minmax(0,_1fr)_minmax(auto,_2fr)_auto] gap-3 divide-dark/border/default/normal p-2'>
      {children}
    </div>
  );
};

const IssueRow = ({ children }: ChildrenProps) => {
  return <>{children}</>;
};

const SettingNameColumn = ({ children }: ChildrenProps) => {
  return <div className='max-w-[200px] self-center'>{children}</div>;
};

const ErrorBadgeColumn = ({ children }: ChildrenProps) => {
  return <div className='min-w-0 self-center'>{children}</div>;
};

const ButtonColumn = ({ children }: ChildrenProps) => {
  return <div className='self-center'>{children}</div>;
};
