export const Users = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='solid/users/users-01'>
        <g id='Solid'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.0211 9.91785C12.1128 9.56128 12.4763 9.34662 12.8329 9.4384C14.2704 9.8084 15.3334 11.1126 15.3334 12.6667V14C15.3334 14.3682 15.0349 14.6667 14.6667 14.6667C14.2985 14.6667 14 14.3682 14 14V12.6667C14 11.7357 13.3633 10.9517 12.5005 10.7296C12.1439 10.6379 11.9293 10.2744 12.0211 9.91785Z'
            fill='currentColor'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.7154 1.94371C9.85355 1.60242 10.2422 1.43774 10.5835 1.57589C11.8039 2.06988 12.6667 3.26672 12.6667 4.66668C12.6667 6.06663 11.8039 7.26347 10.5835 7.75746C10.2422 7.89561 9.85355 7.73094 9.7154 7.38965C9.57725 7.04836 9.74193 6.6597 10.0832 6.52155C10.8174 6.22436 11.3334 5.50497 11.3334 4.66668C11.3334 3.82838 10.8174 3.109 10.0832 2.81181C9.74193 2.67366 9.57725 2.285 9.7154 1.94371Z'
            fill='currentColor'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.78598 9.33332C5.81757 9.33366 6.84915 9.33366 7.88073 9.33332C8.60781 9.33308 9.10395 9.33292 9.52942 9.44692C10.6797 9.75515 11.5782 10.6536 11.8864 11.8039C12.0399 12.3768 11.9955 12.9891 12.0001 13.5761C12.0007 13.6473 12.0019 13.7915 11.966 13.9255C11.8735 14.2706 11.6039 14.5401 11.2588 14.6326C11.1248 14.6685 10.9807 14.6674 10.9094 14.6668C7.85941 14.6424 4.80731 14.6424 1.7573 14.6668C1.68602 14.6674 1.54188 14.6685 1.40787 14.6326C1.06278 14.5401 0.793233 14.2706 0.700765 13.9255C0.664858 13.7915 0.666007 13.6473 0.666575 13.5761C0.671243 12.9905 0.627014 12.3759 0.780272 11.8039C1.0885 10.6536 1.98699 9.75515 3.13729 9.44692C3.56277 9.33292 4.05891 9.33308 4.78598 9.33332Z'
            fill='currentColor'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.00002 4.66668C3.00002 2.82573 4.49241 1.33334 6.33336 1.33334C8.17431 1.33334 9.66669 2.82573 9.66669 4.66668C9.66669 6.50763 8.17431 8.00001 6.33336 8.00001C4.49241 8.00001 3.00002 6.50763 3.00002 4.66668Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
