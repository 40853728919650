import {
  ObButton,
  ObThinkingButton,
  ObTypography,
} from '@outbound/design-system';

export interface PlaybookEditFooterProps {
  areChangesUnsaved: boolean;
  onDiscardClickedCallback?: () => void | Promise<any>;
  onSaveClickedCallback?: () => Promise<any>;
}

export const PlaybookEditFooter = ({
  areChangesUnsaved,
  onDiscardClickedCallback,
  onSaveClickedCallback,
}: PlaybookEditFooterProps) => {
  return (
    <>
      {/* 
          Adds additional space to the bottom of the page to account for the sticky footer 
          so that the footer never covers content.
        */}

      <div className='h-[90.5px]'></div>
      {/* Floating Footer that will appear only when unsaved changes = true */}
      <footer
        style={{
          bottom: areChangesUnsaved ? '0' : '-100px',
          opacity: areChangesUnsaved ? '1' : '0',
        }}
        className='w-full h-[90.5px] flex flex-row justify-end items-center border-t-borderDefaultNormalDark border-t p-5 fixed inset-x-0 z-10 bg-bgDefaultDark gap-4 opacity-0 transition-all bottom-[-100px] duration-300 ease-in-out'
      >
        <div>
          <ObTypography>You have unsaved changes.</ObTypography>
        </div>
        <div className='flex flex-row gap-2'>
          <ObButton
            size='large'
            variant='outline'
            label='Discard'
            onClick={onDiscardClickedCallback}
          />
          <ObThinkingButton
            size='large'
            variant='primary'
            label='Save'
            onClickCallback={onSaveClickedCallback}
          />
        </div>
      </footer>
    </>
  );
};
