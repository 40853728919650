export const CheckBoxIntermediateIcon = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='checkbox'>
        <rect
          id='box'
          width='24'
          height='24'
          rx='6'
          fill='currentColor'
        />
        <rect
          id='Checkbox'
          x='6'
          y='10.8'
          width='12'
          height='2.4'
          rx='1.2'
          fill='white'
        />
      </g>
    </svg>
  );
};
