import {
  CreateLandingPageSelfHostedResource,
  LandingPageResource,
} from '@outbound/types';
import { AxiosInstance, isAxiosError } from 'axios';
import { BaseTransport } from '../base-transport';
import { Transport } from '../transport';

/**
 * Handles communicating with the API for Landing Pages for MobX Store
 */
class LandingPageTransport extends BaseTransport<LandingPageResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }
  protected async fetchById(id: string): Promise<LandingPageResource | null> {
    try {
      const response = await this._axiosInstance.get<LandingPageResource>(
        `/landing-pages/${id}`
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null;
        }
      }
      throw error;
    }
  }
  public acceptEmbeddedResource(resource: LandingPageResource): void {
    this.notifyStoreOfServiceUpdateCallback?.(resource.id, resource);
  }

  protected async internalBootstrap(): Promise<void> {
    const response = await this._axiosInstance.get('/landing-pages');
    if (response.data?.items) {
      response.data.items.forEach((result: LandingPageResource) => {
        this.onResourceFetchedFromServer(result.id, result);
        this.notifyStoreOfServiceUpdateCallback?.(result.id, result);
      });
    }
  }

  public async createSelfHosted(request: CreateLandingPageSelfHostedResource) {
    const response = await this._axiosInstance.post<{ id: string }>(
      '/landing-pages',
      request
    );
    /**
     * The polling for the lifecycle status and thumbnail generation status is started here,
     * wanted to ensure the landing page is created before polling for the status.
     */
    this.pollForActiveLifecycleStatus(response.data.id);
    this.pollForActiveThumbnailGenerationStatus(response.data.id);
    return response.data.id;
  }

  public async delete(id: string): Promise<void> {
    await this._axiosInstance.delete(`/landing-pages/${id}`);
  }

  public async pollForActiveLifecycleStatus(
    landingPageId: string
  ): Promise<void> {
    /**
     * The Landing Page takes some time to initialize.
     * We will poll the server to check if the landing page is in the INITIALIZING state.
     * In the future this would be replaced with a websocket event on the sync endpoint.
     */
    this.pollForResourceInTargetState(
      landingPageId,
      (resource: LandingPageResource) => {
        return resource.lifecycleStatus !== 'INITIALIZING';
      },
      (resource: LandingPageResource) => {
        return resource.lifecycleStatus === 'INITIALIZATION_FAILED';
      },
      'landing-page-lifecycle-status'
    );
  }

  public async pollForActiveThumbnailGenerationStatus(
    landingPageId: string
  ): Promise<void> {
    /**
     * The Landing Page thumbnail Image takes some time to initialize.
     * We will poll the server to check if the landing page thumbnail generation status is in the WAITING_FOR_THUMBNAIL_IMAGE_GENERATION state.
     * In the future this would be replaced with a websocket event on the sync endpoint.
     */
    this.pollForResourceInTargetState(
      landingPageId,
      (resource: LandingPageResource) => {
        return (
          resource.thumbnailGenerationStatus !==
          'WAITING_FOR_THUMBNAIL_IMAGE_GENERATION'
        );
      },
      (resource: LandingPageResource) => {
        return resource.thumbnailGenerationStatus === 'FAILED';
      },
      'landing-page-thumb-generation-status'
    );
  }
}

export default LandingPageTransport;
