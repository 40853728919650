export const CampaignIcon = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='layoutalt'>
        <g id='Solid'>
          <path
            d='M17.8385 2H6.16146C5.63433 1.99998 5.17954 1.99997 4.80497 2.03057C4.40963 2.06287 4.01641 2.13419 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2.13419 4.01641 2.06287 4.40963 2.03057 4.80497C1.99997 5.17954 1.99998 5.63429 2 6.16142V6.4C2 6.96005 2 7.24008 2.10899 7.45399C2.20487 7.64215 2.35785 7.79513 2.54601 7.89101C2.75992 8 3.03995 8 3.6 8H20.4C20.9601 8 21.2401 8 21.454 7.89101C21.6422 7.79513 21.7951 7.64215 21.891 7.45399C22 7.24008 22 6.96005 22 6.4V6.16144C22 5.6343 22 5.17954 21.9694 4.80497C21.9371 4.40963 21.8658 4.01641 21.673 3.63803C21.3854 3.07354 20.9265 2.6146 20.362 2.32698C19.9836 2.13419 19.5904 2.06287 19.195 2.03057C18.8205 1.99997 18.3657 1.99998 17.8385 2Z'
            fill='currentColor'
          />
          <path
            d='M22 11.6C22 11.0399 22 10.7599 21.891 10.546C21.7951 10.3578 21.6422 10.2049 21.454 10.109C21.2401 10 20.9601 10 20.4 10H11.6C11.0399 10 10.7599 10 10.546 10.109C10.3578 10.2049 10.2049 10.3578 10.109 10.546C10 10.7599 10 11.0399 10 11.6L10 20.4C10 20.9601 10 21.2401 10.109 21.454C10.2049 21.6422 10.3578 21.7951 10.546 21.891C10.7599 22 11.0399 22 11.6 22H17.8386C18.3657 22 18.8205 22 19.195 21.9694C19.5904 21.9371 19.9836 21.8658 20.362 21.673C20.9265 21.3854 21.3854 20.9265 21.673 20.362C21.8658 19.9836 21.9371 19.5904 21.9694 19.195C22 18.8205 22 18.3657 22 17.8386V11.6Z'
            fill='currentColor'
          />
          <path
            d='M6.4 22C6.96005 22 7.24008 22 7.45399 21.891C7.64215 21.7951 7.79513 21.6422 7.89101 21.454C8 21.2401 8 20.9601 8 20.4L8 11.6C8 11.0399 8 10.7599 7.89101 10.546C7.79513 10.3578 7.64215 10.2049 7.45399 10.109C7.24008 10 6.96005 10 6.4 10H3.6C3.03995 10 2.75992 10 2.54601 10.109C2.35785 10.2049 2.20487 10.3578 2.10899 10.546C2 10.7599 2 11.0399 2 11.6V17.8385C1.99998 18.3657 1.99997 18.8205 2.03057 19.195C2.06287 19.5904 2.13419 19.9836 2.32698 20.362C2.6146 20.9265 3.07354 21.3854 3.63803 21.673C4.01641 21.8658 4.40963 21.9371 4.80497 21.9694C5.17954 22 5.6343 22 6.16144 22H6.4Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
