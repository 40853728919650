import { ObIcon } from '@outbound/design-system';
import { DrawerExpansionProps } from '../types';

export const ToggleExpandButtonMobile = ({
  isDrawerExpanded,
  toggleDrawerExpansion,
}: DrawerExpansionProps) => {
  return (
    <button
      data-testid='toggle-expand-mobile'
      type='button'
      className='flex justify-center items-center rounded-full dark:hover:bg-dark/action/neutral/hover dark:active:bg-dark/action/neutral/active focus:outline-none focus:shadow-interactive w-[36px] h-[36px]'
      onClick={toggleDrawerExpansion}
    >
      <ObIcon
        icon={isDrawerExpanded ? 'close' : 'hamburgerMenu'}
        size='medium'
      />
    </button>
  );
};
