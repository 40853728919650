import {
  LocationMapGenerationStatus,
  ServerDelta,
  ServiceAreaType,
} from '@outbound/types';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from '../../../root-store';
import Location from '../location';

/**
 * Model for a Service Area Location.
 * This is missing additional details for service areas like service area type and other supporting data
 *
 * I do believe we should refactor this to be a base class that can be extended
 * for Drive Time Service Area and Drive Distance Service Area etc.
 *
 * This would make the typing align with the actual business rules.
 *
 * The other area of concern here is that the the geographicDescription is dependent on reference data.
 * In some cases the reference data can be quite large. We should consider if the reference data should be
 * added as a mobx type and lazy loaded. We could than reference the data via a computed property.
 */
class ServiceAreaLocation extends Location {
  static readonly paths = {
    ...Location.paths,
    driveDistanceMiles: '/driveDistanceMiles',
  };
  private _serviceAreaType: ServiceAreaType;

  //Drive Time is required when the service area type is DRIVE_TIME
  private _driveTimeMinutes: number | undefined;

  //Drive Distance is required when the service area type is DRIVE_DISTANCE
  private _driveDistanceMiles: number | undefined;

  //Address properties are required when the service area type is DRIVE_DISTANCE or DRIVE_TIME
  private _addressLine1: string | undefined;
  private _addressLine2: string | undefined;
  private _city: string | undefined;
  private _state: string | undefined;
  private _country: string | undefined;
  private _postalCode: string | undefined;

  //Postal Codes are required when the service area type is POSTAL_CODE
  private _postalCodes: string[] = [];
  //Counties are required when the service area type is COUNTY
  private _counties: string[] = [];
  //States are required when the service area type is STATE
  private _states: string[] = [];
  //Countries are required when the service area type is COUNTRY
  private _countries: string[] = [];

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    name: string,
    description: string,
    driveTimeMinutes: number | undefined,
    driveDistanceMiles: number | undefined,
    serviceAreaType: ServiceAreaType,
    addressLine1: string | undefined,
    addressLine2: string | undefined,
    city: string | undefined,
    state: string | undefined,
    country: string | undefined,
    postalCode: string | undefined,
    mapImageGenerationStatus: LocationMapGenerationStatus,
    mapImageForDarkModeAssetId?: string,
    mapImageForLightModeAssetId?: string,
    postalCodes: string[] = [],
    counties: string[] = [],
    states: string[] = [],
    countries: string[] = []
  ) {
    super(
      rootStore,
      id,
      workspaceId,
      'SERVICE_AREA',
      name,
      description,
      mapImageGenerationStatus,
      mapImageForDarkModeAssetId,
      mapImageForLightModeAssetId
    );
    this._driveTimeMinutes = driveTimeMinutes;
    this._driveDistanceMiles = driveDistanceMiles;
    this._serviceAreaType = serviceAreaType;
    this._addressLine1 = addressLine1;
    this._addressLine2 = addressLine2;
    this._city = city;
    this._state = state;
    this._country = country;
    this._postalCode = postalCode;
    this._postalCodes = postalCodes;
    this._counties = counties;
    this._states = states;
    this._countries = countries;
  }

  makeObservableInternal(): void {
    super.makeObservableInternal();
    makeObservable(this, {
      _addressLine1: observable,
      _addressLine2: observable,
      _city: observable,
      _state: observable,
      _country: observable,
      _postalCode: observable,
      geographicDescription: computed,
    } as any);
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      super.applyPatch(patch);
      for (const delta of patch) {
        switch (delta.path) {
          case ServiceAreaLocation.paths.driveDistanceMiles: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._driveDistanceMiles = delta.value as number;
                break;
              case 'remove':
                console.error('Invalid Delta. Cannot remove customer id');
            }
            break;
          }
          default: {
            // NoOp We assume the base class handles the rest
          }
        }
      }
    });
  }

  toJson(): Record<string, any> {
    const baseJson = super.toJson();
    return {
      ...baseJson,
      driveTimeMinutes: this.driveTimeMinutes,
      driveDistanceMiles: this.driveDistanceMiles,
      serviceAreaType: this.serviceAreaType,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      country: this.country,
      postalCode: this.postalCode,
      postalCodes: this.postalCodes,
      counties: this.counties,
      states: this.states,
      countries: this.countries,
    };
  }

  /**
   * Get a calculated description of the location based on the location type and the location data.
   */
  get geographicDescription(): string {
    switch (this.serviceAreaType) {
      case 'DRIVE_DISTANCE':
        return `${this._driveDistanceMiles} mile drive from ${this._addressLine1}`;
      case 'DRIVE_TIME':
        return `${this._driveTimeMinutes} minute drive from ${this._addressLine1}`;
      case 'POSTAL_CODE':
        return `Zip Codes...`;
      case 'COUNTRY':
        return `Country...`;
      case 'COUNTY':
        return `Counties...`;
      case 'STATE':
        return `States...`;
      default:
        return 'Unknown Service Area Type';
    }
  }

  get serviceAreaType(): ServiceAreaType {
    return this._serviceAreaType;
  }

  get driveTimeMinutes(): number | undefined {
    return this._driveTimeMinutes;
  }

  get driveDistanceMiles(): number | undefined {
    return this._driveDistanceMiles;
  }

  get addressLine1(): string | undefined {
    return this._addressLine1;
  }

  get addressLine2(): string | undefined {
    return this._addressLine2;
  }

  get city(): string | undefined {
    return this._city;
  }

  get state(): string | undefined {
    return this._state;
  }

  get country(): string | undefined {
    return this._country;
  }

  get postalCode(): string | undefined {
    return this._postalCode;
  }

  get postalCodes(): string[] {
    return this._postalCodes;
  }

  get counties(): string[] {
    return this._counties;
  }

  get states(): string[] {
    return this._states;
  }

  get countries(): string[] {
    return this._countries;
  }
}

export default ServiceAreaLocation;
