import { useCallback, useMemo } from 'react';
import { OnboardingQuestion } from '../questionnaire.type';
import {
  useFetchBusinessDetails,
  usePatchBusinessDetails,
} from '../../../../query/playbook/use-playbook-settings-business-details-endpoints';

export const useBusinessNameQuestion = (
  questions: Array<OnboardingQuestion>
) => {
  const QUESTION_ID = 'business-name';

  //Grab the Industry Question from the Question Data
  const question = questions.find((q) => q.id === QUESTION_ID);

  const { refetch: refetchCompanyDetails } = useFetchBusinessDetails();

  const { mutateAsync: patchBusinessDetails } = usePatchBusinessDetails();

  /**
   * Function that will persist the business name question
   * @param answers
   */
  const updatePlaybookBusinessName = useCallback(
    async (answers: { [key: string]: any }) => {
      const businessNameAnswer = answers['business-name']; //Lookup our answers by question id

      /**
       * Spread data from current call so we do not overwrite other data
       */
      const content = {
        doingBusinessAsName: businessNameAnswer,
      };
      return patchBusinessDetails({ values: content });
    },
    [patchBusinessDetails]
  );

  useMemo(() => {
    //Add the Refetch Function
    question!.fetchServerState = () => {
      return new Promise((resolve) => {
        refetchCompanyDetails().then((success: any) => {
          const businessName = success.data?.doingBusinessAsName ?? '';
          resolve(businessName);
        });
      });
    };

    question!.submitServerState = updatePlaybookBusinessName;
  }, [question, refetchCompanyDetails, updatePlaybookBusinessName]);

  return {};
};
