import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';

export const LinkPrivacyPolicy = () => {
  return (
    <ObLink
      href='https://legal.outbound.com/privacy-policy'
      openInNewTab={true}
      label='Privacy Policy'
      variant={'primary'}
    />
  );
};
