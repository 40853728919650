import {
  CampaignEstimateROIResponseResource,
  CampaignResource,
  CampaignResourceWithCreative,
  CreateCampaignRequestResource,
  PatchCampaignRequestResource,
  PatchCampaignSettingsRequestResource,
  PostCampaignEstimateROIRequestResource,
  PostRecommendationRequestResource,
  RecommendationResource,
} from '@outbound/types';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

export const useFetchCampaigns = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, CampaignResource[]>(
    ['campaigns:get'],
    async () => {
      const response = await auth0AxiosClient.get('/campaigns');
      return response.data.items;
    },
    {
      onSuccess: () => {
        //
      },
    }
  );
};

export const useCreateCampaignMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<string, undefined, CreateCampaignRequestResource>(
    ['campaigns:post'],
    async (data: CreateCampaignRequestResource) => {
      const response = await auth0AxiosClient.post('/campaigns', data);
      return response.data.id;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns:get']);
      },
    }
  );
};

export const useFetchRecommendationById = (recommendationId: string) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, RecommendationResource>(
    ['campaigns:recommendations:get'],
    async () => {
      const response = await auth0AxiosClient.get(
        `/campaign-recommendations/${recommendationId}`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        //
      },
    }
  );
};

export const useCreateRecommendationMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<string, undefined, PostRecommendationRequestResource>(
    ['campaigns:recommendation:post'],
    async (data: PostRecommendationRequestResource) => {
      const response = await auth0AxiosClient.post(
        '/campaign-recommendations',
        {
          personaIds: data.personaIds,
          serviceIds: data.serviceIds,
          physicalLocationIds: data.physicalLocationIds,
          serviceAreaIds: data.serviceAreaIds,
          selectedDailyBudget: data.selectedDailyBudget,
          minBudget: data.minBudget,
          maxBudget: data.maxBudget,
        }
      );
      return response.data.id;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns:get']);
      },
    }
  );
};

export const useDeleteCampaignByIdMutation = (id: string) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation(
    ['campaigns', id, 'delete'],
    async () => {
      const response = await auth0AxiosClient.delete('/campaigns/' + id);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns:get']);
      },
    }
  );
};

export const useFetchCampaignById = (id: string | undefined) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, CampaignResource>(
    ['campaigns', id, 'get'],
    async () => {
      const response = await auth0AxiosClient.get('/campaigns/' + id);
      return response.data;
    },
    {
      enabled: !!id,
      onSuccess: () => {
        //
      },
    }
  );
};

export const useFetchCampaignByIdWithCreative = (id: string | undefined) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, CampaignResourceWithCreative>(
    ['campaigns', id, 'get', 'creative'],
    async () => {
      const response = await auth0AxiosClient.get(`/campaigns/${id}`, {
        params: {
          include: 'creative',
        },
      });
      return response.data;
    },
    {
      enabled: !!id,
      onSuccess: () => {
        //
      },
    }
  );
};

export const useFetchCampaignByIdAndPollForActive = (
  id: string | undefined
) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, CampaignResource>(
    ['campaigns', id, 'get'],
    async () => {
      const response = await auth0AxiosClient.get('/campaigns/' + id);
      return response.data;
    },
    {
      enabled: !!id,
      refetchInterval: (data?: CampaignResource) => {
        const shouldPoll =
          data?.status == null || data.status === 'INITIALIZING';

        return shouldPoll ? 2000 : false;
      },
      refetchIntervalInBackground: true,
      onSuccess: () => {
        //
      },
    }
  );
};

export const useCalculateROIMetricsForCampaign = (
  id: string | null | undefined
) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useMutation<
    CampaignEstimateROIResponseResource,
    undefined,
    PostCampaignEstimateROIRequestResource
  >(
    ['campaigns', id, 'estimate-roi'],
    async (budget) => {
      if (id == null) {
        throw new Error('Campaign ID is required to calculate ROI metrics.');
      }
      const response = await auth0AxiosClient.post<
        CampaignEstimateROIResponseResource,
        AxiosResponse<CampaignEstimateROIResponseResource>,
        PostCampaignEstimateROIRequestResource
      >(`/campaigns/${id}/estimate-roi`, budget);

      console.log('response', response.data);
      return response.data;
    },
    {}
  );
};

export const usePatchCampaignByIdMutation = (id: string | null | undefined) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: Partial<PatchCampaignRequestResource> }
  >(
    ['campaigns', id, 'patch'],
    async ({ values }) => {
      if (id == null) {
        throw new Error(
          'Cannot Update Campaign. A campaign id must be provided.'
        );
      }
      const response = await auth0AxiosClient.patch('/campaigns/' + id, values);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns', id, 'get']);
        queryClient.invalidateQueries(['campaigns:get']);
      },
    }
  );
};

export const usePatchCampaignSettingsByIdMutation = (
  id: string | null | undefined
) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: Partial<PatchCampaignSettingsRequestResource> }
  >(
    ['campaign/setting', id, 'patch'],
    async ({ values }) => {
      if (id == null) {
        throw new Error(
          'Cannot Update Campaign. A campaign id must be provided.'
        );
      }
      const response = await auth0AxiosClient.patch(
        `/campaigns/${id}/settings`,
        {
          ...values,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['campaigns', id, 'get']);
        queryClient.invalidateQueries(['campaigns:get']);
      },
    }
  );
};
