import { Button, Grid, Typography } from '@mui/material';
import { ObIcon } from '@outbound/design-system';
import { IconSystemKeys } from '@outbound/design-system/src/tokens/icons/icons';
import { FC } from 'react';

interface NoContentCtaProps {
  heading: string;
  subHeading: string;
  icon: IconSystemKeys;
  ctaPrimaryButtonLabel?: string;
  ctaPrimaryButtonCallback?: () => void;
  ctaSecondaryButtonLabel?: string;
  ctaSecondaryButtonCallback?: () => void;
}

export const NoContentCta: FC<NoContentCtaProps> = ({
  heading,
  subHeading,
  icon,
  ctaPrimaryButtonLabel,
  ctaPrimaryButtonCallback,
  ctaSecondaryButtonLabel,
  ctaSecondaryButtonCallback,
}) => {
  return (
    <>
      <Grid
        data-testid={'no-content-cta'}
        container
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          /*Fill the parent's height to center this grid's content vertically in the parent*/
          height: '100%',
        }}
      >
        <ObIcon
          icon={icon}
          size={'x-large'}
          color={'secondary'}
        />
        <Typography
          color={'text.secondary'}
          variant={'h3'}
        >
          {heading}
        </Typography>
        <Typography
          color={'text.secondary'}
          variant={'body2'}
        >
          {subHeading}
        </Typography>
        {ctaPrimaryButtonLabel && (
          <Grid
            container
            columnGap={1}
            alignContent={'center'}
            justifyContent={'center'}
            sx={{
              mt: 1 /* Ideally this could be handled by the parent's grid spacing but
                   the vertical rhythm is being thrown off by the typography line heights.
                */,
            }}
          >
            {ctaSecondaryButtonLabel && (
              <Button
                onClick={ctaSecondaryButtonCallback}
                variant={'outlined'}
                color={'secondary'}
                aria-label={ctaSecondaryButtonLabel}
              >
                {ctaSecondaryButtonLabel}
              </Button>
            )}
            <Button
              onClick={ctaPrimaryButtonCallback}
              variant={'outlined'}
              aria-label={ctaPrimaryButtonLabel}
            >
              {ctaPrimaryButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
