import { PhysicalLocationResource, ServiceAreaResource } from '@outbound/types';
import { FC } from 'react';
import { StateManagedByParentInput } from '../../../../base-component-props.type';

import { BusinessConfigLocationSettings } from '../../business-config';
import { FieldDisplayModeProps } from '../../form-renderer-poc';
import {
  lookupDescriptionForServiceAreaType,
  physicalLocationLabel,
} from '../location-labels';
import { ObSelectedOptionGroup } from '../ob-selected-option-group/ob-selected-option-group';
import { ObSelectedOption } from '../ob-selected-option/ob-selected-option';
import { SectionLabel } from '../section-label/section-label';
import { WhereCheckboxGroup } from '../where-checkbox-group/where-checkbox-group';

export interface WhereSectionState {
  selectedPhysicalLocations: Array<string>;
  selectedServiceAreas: Array<string>;
}

export interface WhereSectionProps
  extends StateManagedByParentInput<Array<string>>,
    FieldDisplayModeProps,
    BusinessConfigLocationSettings {
  physicalLocationsFromPlaybook: Array<PhysicalLocationResource>;
  serviceAreasFromPlaybook: Array<ServiceAreaResource>;
  onAddNewPhysicalLocationClickedCallback: () => void;
  onAddNewServiceAreaClickedCallback: () => void;
}

export const WhereSection: FC<WhereSectionProps> = ({
  value,
  onValueChangedCallback,
  physicalLocationsFromPlaybook,
  serviceAreasFromPlaybook,
  displayMode = 'editable',
  doesBusinessActivitySupportPhysicalLocations = true,
  doesBusinessActivitySupportServiceAreas = true,
  onDisplayModeChangedCallback,
  onAddNewPhysicalLocationClickedCallback,
  onAddNewServiceAreaClickedCallback,
}: WhereSectionProps) => {
  return (
    <div className='flex flex-col gap-3 self-stretch'>
      <SectionLabel
        label='Where Do You Want to Advertise?'
        id={'where'}
      />
      {displayMode === 'readonly' && (
        <ObSelectedOptionGroup
          onEditClickedCallback={() => onDisplayModeChangedCallback('editable')}
        >
          {value.map((loc) => {
            const [type, id] = loc.split('/');
            let label: string = '';
            let description: string = '';
            if (type === 'service-area') {
              const serviceArea = serviceAreasFromPlaybook.find(
                (sa) => sa.id === id
              );
              label = serviceArea?.name || 'Unknown';
              description =
                serviceArea != null
                  ? lookupDescriptionForServiceAreaType(
                      serviceArea.serviceAreaType
                    )
                  : 'Unknown';
            }
            if (type === 'physical-location') {
              const physicalLocation = physicalLocationsFromPlaybook.find(
                (pl) => pl.id === id
              );
              label = physicalLocation?.name || 'Unknown';
              description = physicalLocationLabel;
            }
            return (
              <ObSelectedOption
                key={loc}
                id={'where'}
                value={label}
                description={description}
              />
            );
          })}
        </ObSelectedOptionGroup>
      )}
      {displayMode === 'editable' && (
        <WhereCheckboxGroup
          doesBusinessActivitySupportPhysicalLocations={
            doesBusinessActivitySupportPhysicalLocations
          }
          doesBusinessActivitySupportServiceAreas={
            doesBusinessActivitySupportServiceAreas
          }
          physicalLocationsFromPlaybook={physicalLocationsFromPlaybook}
          serviceAreasFromPlaybook={serviceAreasFromPlaybook}
          onConfirmWhatClickedCallback={() => {
            onDisplayModeChangedCallback('readonly');
          }}
          onAddPhysicalLocationClickedCallback={
            onAddNewPhysicalLocationClickedCallback
          }
          onAddNewServiceAreaClickedCallback={
            onAddNewServiceAreaClickedCallback
          }
          value={value}
          onValueChangedCallback={onValueChangedCallback}
          isLoading={false}
        />
      )}
    </div>
  );
};
