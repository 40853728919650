/**
 * line/alerts/thumbs-up
 */
export const ThumbsUp = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      {...props}
    >
      <path
        d='M7.5 22V11M2.5 13V20C2.5 21.1046 3.39543 22 4.5 22H17.9262C19.407 22 20.6662 20.9197 20.8914 19.4562L21.9683 12.4562C22.2479 10.6389 20.8418 9 19.0032 9H15.5C14.9477 9 14.5 8.55228 14.5 8V4.46584C14.5 3.10399 13.396 2 12.0342 2C11.7093 2 11.415 2.1913 11.2831 2.48812L7.76394 10.4061C7.60344 10.7673 7.24532 11 6.85013 11H4.5C3.39543 11 2.5 11.8954 2.5 13Z'
        stroke='currentColor'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
