import { useContext } from 'react';
import { RootStore } from './root-store';
import { RootStoreContext } from './root-store-context';

export const useRootStore = (): RootStore => {
  const context = useContext(RootStoreContext);
  if (!context) {
    throw new Error('useRootStore must be used within a RootStoreProvider');
  }
  return context;
};
