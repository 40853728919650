export const FileSearch = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 18C20.6863 18 18 20.6863 18 24C18 27.3137 20.6863 30 24 30C25.1741 30 26.2694 29.6628 27.1943 29.08L28.3905 30.2762C28.9112 30.7969 29.7554 30.7969 30.2761 30.2762C30.7968 29.7555 30.7968 28.9113 30.2761 28.3906L29.0799 27.1944C29.6628 26.2694 30 25.1741 30 24C30 20.6863 27.3137 18 24 18ZM20.6667 24C20.6667 22.1591 22.159 20.6667 24 20.6667C25.841 20.6667 27.3333 22.1591 27.3333 24C27.3333 25.841 25.841 27.3334 24 27.3334C22.159 27.3334 20.6667 25.841 20.6667 24Z'
        fill='#787F85'
      />
      <path
        d='M11.6783 1.33337H20.3217C21.395 1.33336 22.2808 1.33334 23.0024 1.3923C23.7519 1.45353 24.4408 1.58495 25.0879 1.91467C26.0915 2.426 26.9074 3.2419 27.4187 4.24543C27.7484 4.89254 27.8798 5.58148 27.9411 6.33094C28 7.05253 28 7.93833 28 9.01161V14.5509C28 15.1377 28 15.4311 27.9002 15.5923C27.8029 15.7496 27.6941 15.8319 27.5162 15.8826C27.3339 15.9346 27.0121 15.8434 26.3685 15.661C25.6157 15.4476 24.8212 15.3334 24 15.3334C19.2135 15.3334 15.3333 19.2136 15.3333 24C15.3333 25.6209 15.7783 27.1378 16.5526 28.4353C17.1139 29.3756 17.3945 29.8458 17.3739 30.063C17.3529 30.284 17.2834 30.4064 17.1044 30.5377C16.9284 30.6667 16.456 30.6667 15.5111 30.6667H11.6782C10.605 30.6667 9.71915 30.6667 8.99757 30.6078C8.2481 30.5466 7.55917 30.4151 6.91205 30.0854C5.90852 29.5741 5.09262 28.7582 4.5813 27.7547C4.25158 27.1075 4.12016 26.4186 4.05893 25.6691C3.99997 24.9476 3.99998 24.0617 4 22.9885V9.01164C3.99998 7.93836 3.99997 7.05253 4.05893 6.33094C4.12016 5.58148 4.25158 4.89254 4.5813 4.24543C5.09262 3.2419 5.90852 2.426 6.91205 1.91467C7.55917 1.58495 8.2481 1.45353 8.99757 1.3923C9.71916 1.33334 10.605 1.33336 11.6783 1.33337Z'
        fill='#787F85'
      />
    </svg>
  );
};
