import { parseObrn } from '@otbnd/utils';
import { LandingPageDetail } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { CampaignCardObserver } from '../../../object-card/campaign/campaign-card-observer';
import LandingPage from '../../../state/mobx-experiment/landing-page/landing-page';
import { useRootStore } from '../../../state/mobx-experiment/use-root-store';
import { RouteParams } from './landing-page-route-param.type';

export const LandingPageDetailMobxWrapper = observer(() => {
  const { landingPageId } = useParams<RouteParams>();
  const { landingPageStore, campaignStore } = useRootStore();
  const navigate = useNavigate();

  const landingPage: LandingPage | null = landingPageStore.getById(
    landingPageId!
  );

  // Here to keep Typescript Happy. The wrapper component that renders this handles this case.
  if (landingPage == null) {
    return <></>;
  }

  return (
    <LandingPageDetail
      onPreviewCallback={
        //Open link in new tab
        () => window.open(landingPage.url, '_blank')
      }
      obrn={landingPage.obrn}
      pageTitle={landingPage.pageTitle}
      url={landingPage.url}
      landingPageType={landingPage.type}
      landingPageScreenshotPublicUrl={landingPage.thumbnailUrl ?? undefined}
      onHealthCheckLandingPage={function (): void {
        throw new Error('Function not implemented.');
      }}
      googleTagManagerInstalled={false}
      googleAnalyticsInstalled={false}
      metaPixelInstalled={false}
      sslInstalled={landingPage.httpsEnabled}
      lastHealthCheckResult={'UP'}
      lastHealthCheckRanAtTimestamp={''}
      campaignsUsingLandingPage={landingPage
        .getCampaignsUsingLandingPage()
        .map((campaign) => campaign.obrn)}
      campaignRenderFunction={(obrn: string, variant) => {
        const campaignId = parseObrn(obrn).localPathId;
        const campaign = campaignStore.getById(campaignId);

        //This means the campaign was deleted. This shouldn't happen but it is included for type safety.
        if (campaign == null) {
          return null;
        }

        return (
          <CampaignCardObserver
            campaign={campaign}
            variant={variant}
          />
        );
      }}
      onDeleteLandingPage={() => {
        landingPage.delete();
        navigate('../..');
      }}
      ffLandingPageGoogleTagManager={false}
      ffLandingPageGoogleAnalytics={false}
      ffLandingPageMetaPixel={false}
      ffLandingPageAnalytics={false}
      ffLandingPageSslInstalled={false}
    />
  );
});
