export const Paint = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.4714 0.862025C7.21105 0.601676 6.78894 0.601676 6.52859 0.862025C6.26824 1.12238 6.26824 1.54448 6.52859 1.80483L6.72382 2.00006L2.77189 5.95199C2.39241 6.33145 2.07923 6.64462 1.84495 6.92059C1.60163 7.20721 1.40451 7.49725 1.2923 7.84262C1.11828 8.3782 1.11828 8.95513 1.2923 9.49071C1.40451 9.83607 1.60162 10.1261 1.84495 10.4127C2.07923 10.6887 2.39243 11.0019 2.77191 11.3814L4.95202 13.5615C5.33145 13.9409 5.64467 14.2541 5.92061 14.4884C6.20724 14.7317 6.49728 14.9288 6.84264 15.0411C7.37822 15.2151 7.95515 15.2151 8.49073 15.0411C8.8361 14.9288 9.12613 14.7317 9.41276 14.4884C9.68871 14.2541 10.0019 13.941 10.3813 13.5615L14.0655 9.87731C14.1845 9.75834 14.3004 9.64247 14.3905 9.53632C14.4895 9.41976 14.5959 9.27183 14.6586 9.07869C14.7456 8.81089 14.7456 8.52243 14.6586 8.25464C14.5959 8.0615 14.4895 7.91357 14.3905 7.797C14.3004 7.69086 14.1845 7.575 14.0655 7.45603L8.13809 1.52859C8.13551 1.52601 8.13291 1.52345 8.1303 1.52092L7.4714 0.862025ZM7.66669 2.94281L3.73417 6.87533C3.33037 7.27912 3.05624 7.55398 2.8614 7.78349C2.79079 7.86667 2.73606 7.93777 2.6933 8H12.7239L7.66669 2.94281Z'
        fill='currentColor'
      />
    </svg>
  );
};
