/** solid/shapes/circle-dot */
export const CircleDot = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        stroke='currentColor'
        fill='#121422'
      />
      <path
        d='M12 5.58331C8.45615 5.58331 5.58331 8.45615 5.58331 12C5.58331 15.5438 8.45615 18.4166 12 18.4166C15.5438 18.4166 18.4166 15.5438 18.4166 12C18.4166 8.45615 15.5438 5.58331 12 5.58331Z'
        fill='currentColor'
      />
    </svg>
  );
};
