import { yupResolver } from '@hookform/resolvers/yup';
import { Deferred } from '@otbnd/utils';
import {
  CampaignChannelType,
  OutboundCampaignGoal,
  PersonaResource,
  PhysicalLocationResource,
  ServiceAreaResource,
  ServiceResource,
  channelTypeSelectorOptions,
} from '@outbound/types';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ObInputCaption } from '../../components/elements/ob-input-caption/ob-input-caption';
import { ObDrawerFooter } from '../../components/molecules/ob-drawer-footer/ob-drawer-footer';
import FormRendererField from '../../components/organisms/ob-form-renderer/ob-form-renderer-field';
import { FormFieldType } from '../../components/organisms/ob-form-renderer/ob-form-renderer.types';

import { Transition } from '@headlessui/react';
import { ObThinkingButton } from '../../components/elements/ob-thinking-button/ob-thinking-button';
import { useDrawerService } from '../../components/services/drawer-service-provider/drawer-service-provider';
import {
  BusinessConfigLocationSettings,
  BusinessConfigServiceSettings,
} from './business-config';
import { ChannelSection } from './components/channel-section/channel-section';
import { SectionLabel } from './components/section-label/section-label';
import { WhatSection } from './components/what-section/what-section';
import { WhereSection } from './components/where-section/where-section';
import { WhoSection } from './components/who-section/who-section';
import {
  CampaignField,
  CreateCampaignFormData,
  FieldDisplayMode,
  FieldDisplayModeState,
  FieldHiddenState,
  buildValidationSchemaForForm,
  fields,
} from './form-renderer-poc';

const initialFormState: CreateCampaignFormData = {
  what: '',
  who: '',
  where: [],
  channel: '',
  name: '',
};

type FormSubmission = {
  campaignName: string;
  channel: string;
  personaId: string;
  physicalLocationIds: string[];
  serviceAreaIds: string[];
  serviceId: string | undefined;
  campaignGoal: OutboundCampaignGoal;
};

type SplitLocationResult = {
  serviceAreaIds: string[];
  physicalLocationIds: string[];
};

type SplitWhatResult = {
  serviceId: string | undefined;
  campaignGoal: OutboundCampaignGoal;
};

const splitWhat = (data: string): SplitWhatResult => {
  const [type, id] = data.split('/');
  return {
    serviceId: type === 'LEAD_GEN_FOR_SERVICE' ? id : undefined,
    campaignGoal:
      type === 'LEAD_GEN_FOR_SERVICE'
        ? 'LEAD_GEN_FOR_SERVICE'
        : 'BRAND_AWARENESS',
  };
};

const splitLocations = (data: string[]): SplitLocationResult => {
  return data.reduce<SplitLocationResult>(
    (acc, item) => {
      const [type, id] = item.split('/');
      if (type === 'service-area') {
        acc.serviceAreaIds.push(id);
      } else if (type === 'physical-location') {
        acc.physicalLocationIds.push(id);
      }
      return acc;
    },
    { serviceAreaIds: [], physicalLocationIds: [] }
  );
};

export interface CampaignCreateDrawerProps
  extends BusinessConfigLocationSettings,
    BusinessConfigServiceSettings {
  availableChannels: Array<CampaignChannelType>;
  /**
   * The services that are available to the user to create a campaign for
   * It is expected that these are the services available in the playbook
   */
  availableServices: Array<ServiceResource>;

  /**
   * The customer profiles that are available to the user to create a campaign for.
   * It is expected that these are the profiles available in the playbook
   */
  availableCustomerProfiles: Array<PersonaResource>;
  /**
   * The physical locations that are available to the user to create a campaign for
   * It is expected that these are the physical locations available in the playbook
   */
  availablePhysicalLocations: Array<PhysicalLocationResource>;
  /**
   * The service areas that are available to the user to create a campaign for
   * It is expected that these are the service areas available in the playbook
   * */
  availableServiceAreas: Array<ServiceAreaResource>;
  /**
   * Callback function that is called when the create button is clicked.
   * It is expected that the callback will handle the creation of the campaign
   * and when the campaign is created it will navigate the user to the campaign
   * @param createCampaignRequest
   * @returns
   */
  onCreateCampaignCallback: (
    createCampaignRequest: FormSubmission
  ) => Promise<any>;

  /**
   * Callback function that is called when the user clicks the "+ Other Service" button
   * in the What radio group
   */
  onAddNewServiceClickedCallback: () => any;

  /**
   * Callback function that is called when the user clicks the "+ Someone Else" button
   * in the Who radio group
   */
  onAddNewCustomerProfileClickedCallback: () => any;

  /**
   * Callback function that is called when the user clicks the "+ Other Location" button
   * in the Where radio group
   */
  onAddNewPhysicalLocationClickedCallback: () => any;

  /**
   * Callback function that is called when the user clicks the "+ Other Service Area" button
   * in the Where radio group
   */
  onAddNewServiceAreaClickedCallback: () => any;

  /**
   * Indicates the context where the create campaign form is being used
   * The create campaign CTA opens the create campaign in a drawer while during onboarding
   * we show this form in full screen.
   * The original variant was 'drawer' but we added 'full-screen' to support the onboarding use case
   */
  variant: 'drawer' | 'full-screen';
}

export const CampaignCreateDrawer: FC<CampaignCreateDrawerProps> = ({
  availableChannels,
  availableServices: services,
  availablePhysicalLocations: physicalLocations,
  availableServiceAreas: serviceAreas,
  availableCustomerProfiles: customerProfiles,
  doesBusinessActivitySupportPhysicalLocations = true,
  doesBusinessActivitySupportServiceAreas = true,
  doesBusinessActivitySupportServices = true,
  onCreateCampaignCallback,
  onAddNewServiceClickedCallback,
  onAddNewCustomerProfileClickedCallback,
  onAddNewPhysicalLocationClickedCallback,
  onAddNewServiceAreaClickedCallback,
  variant = 'drawer',
}: CampaignCreateDrawerProps) => {
  const { popDrawer } = useDrawerService();

  const [externalFormValues, setExternalFormValues] =
    useState<CreateCampaignFormData>(initialFormState);

  /**
   * We auto-suggest a campaign name based on the selected service and channel
   */
  const [suggestedCampaignName, setSuggestedCampaignName] = useState('');

  /**
   * FORM RENDERER POC
   * This should be removed when the form renderer is updated to handle read-only mode
   */
  /**
   * Evaluates if the given fields in the given form definition should be hidden or not based on the current values.
   * @param form
   * @param values
   * @returns
   */
  const evaluateIfFieldsShouldBeHidden = useCallback(
    (
      fields: Array<CampaignField>,
      values: CreateCampaignFormData,
      displayModes: FieldDisplayModeState
    ): FieldHiddenState => {
      const hiddenFieldState: FieldHiddenState = {};

      fields?.forEach((field: CampaignField) => {
        hiddenFieldState[field.id] =
          field?.conditionalVisibilityFunction?.(values, displayModes) ?? false;
      });

      return hiddenFieldState;
    },
    []
  );

  /**
   * FORM RENDERER POC
   * This should be removed when the form renderer is updated to handle read-only mode
   */
  const [fieldViewModeMap, setFieldViewModeMap] =
    useState<FieldDisplayModeState>(
      fields.reduce((acc, field) => {
        acc[field.id] = {
          mode: 'editable',
          childRequestsForReadOnlyMode: 0,
          childRequestsForEditableMode: 0,
        };
        return acc;
      }, {} as FieldDisplayModeState)
    );

  /**
   * FORM RENDERER POC
   * This should be removed when the form renderer is updated to handle read-only mode
   */
  /**
   * Tracks the state of whether a field should be hidden or not based on the field level
   * conditional logic
   */
  const [isFieldVisibleMap, setFieldIsVisibleMap] = useState<FieldHiddenState>(
    evaluateIfFieldsShouldBeHidden(fields, initialFormState, fieldViewModeMap)
  );

  /**
   * FORM RENDERER POC
   * This should be removed when the form renderer is updated to handle read-only mode
   */
  const {
    control,
    watch,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCampaignFormData>({
    defaultValues: initialFormState,
    values: externalFormValues,
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver: async (data, context, options) => {
      // you can debug your validation schema here
      const submittedValues = await yupResolver(
        buildValidationSchemaForForm(fields, isFieldVisibleMap)
      )(data, context, options);
      return submittedValues;
    },
  });

  /**
   * FORM RENDERER POC
   * This should be removed when the form renderer is updated to handle read-only mode
   */
  const updateFieldVisibilityState = useCallback(() => {
    const values = getValues();
    setFieldIsVisibleMap(
      evaluateIfFieldsShouldBeHidden(fields, values, fieldViewModeMap)
    );
  }, [evaluateIfFieldsShouldBeHidden, fieldViewModeMap, getValues]);

  /**
   * Each time a field value is updated we will re-evaluate if the fields should be hidden or not
   */
  useEffect(() => {
    const subscription = watch((_value, { name: _name, type }) => {
      if (type != null) {
        const formState = getValues();
        if (formState.what && formState.channel) {
          const channelDetails = channelTypeSelectorOptions.find(
            (option) => option.value === formState.channel
          );
          const [whatType, whatId] = formState.what.split('/');
          if (whatType === 'LEAD_GEN_FOR_SERVICE') {
            const service = services.find((service) => service.id === whatId);
            if (service) {
              setSuggestedCampaignName(
                `${service.name} - ${channelDetails?.title}`
              );
            }
          } else {
            setSuggestedCampaignName(
              `Brand Awareness - ${channelDetails?.title}`
            );
          }
        }

        updateFieldVisibilityState();
      }
    });
    return () => subscription.unsubscribe();
  }, [
    evaluateIfFieldsShouldBeHidden,
    fieldViewModeMap,
    getValues,
    services,
    updateFieldVisibilityState,
    watch,
  ]);

  useEffect(() => {
    setExternalFormValues({ ...getValues(), name: suggestedCampaignName });
  }, [getValues, suggestedCampaignName]);

  /**
   * FORM RENDERER POC
   * This should be removed when the form renderer is updated to handle read-only mode
   */
  const onFieldDisplayModeChanged = useCallback(
    (fieldName: string, mode: FieldDisplayMode) => {
      setFieldViewModeMap((prevState) => {
        return {
          ...prevState,
          [fieldName]: {
            mode: mode,
            childRequestsForReadOnlyMode:
              mode === 'readonly'
                ? prevState[fieldName]?.childRequestsForReadOnlyMode + 1
                : prevState[fieldName]?.childRequestsForReadOnlyMode,
            childRequestsForEditableMode:
              mode === 'editable'
                ? prevState[fieldName]?.childRequestsForEditableMode + 1
                : prevState[fieldName]?.childRequestsForEditableMode,
          },
        };
      });
      clearErrors(fieldName as any);
    },
    [clearErrors]
  );

  /**
   * FORM RENDERER POC
   * This should be removed when the form renderer is updated to handle read-only mode
   */
  useEffect(() => {
    updateFieldVisibilityState();
  }, [fieldViewModeMap, updateFieldVisibilityState]);

  const handleFormSubmit = useCallback(() => {
    const deferred = new Deferred<void>();
    handleSubmit(
      async (values) => {
        let isErrored = false;
        fields.forEach((field: CampaignField) => {
          /**
           * When a field supports both editable and read-only modes and the field is in editable mode
           * and the field is visible to the user we want to show an error message to confirm the selection
           * before we submit the form.
           *
           * This is needed on locations since we expect the user to confirm their selections before
           * we ask for the channel and campaign name, if we don't do this than than technically the form
           * is valid because the "required" fields are not visible to the user.
           *
           * This forces the user to click confirm which will trigger the next fields to be visible which
           * is required.
           */
          if (
            field.displayModes?.includes('readonly') &&
            field.displayModes?.includes('editable') &&
            fieldViewModeMap[field.id].mode === 'editable' &&
            isFieldVisibleMap[field.id]
          ) {
            isErrored = true;
            setError(field.id as any, {
              type: 'custom',
              message: 'Please Confirm Your Selection Before Saving.',
            });
          }
        });
        if (isErrored) {
          deferred.reject();
          return Promise.reject();
        } else {
          const locations = splitLocations(values.where);
          const what = splitWhat(values.what);
          const createFormRequest: FormSubmission = {
            campaignName: values.name,
            channel: values.channel,
            personaId: values.who,
            ...locations,
            ...what,
          };
          await onCreateCampaignCallback(createFormRequest);
          deferred.resolve();
          return deferred.promise;
        }
      },
      (values) => {
        console.log('Invalid form', values);
        deferred.reject();
        return Promise.reject();
      }
    )();

    return deferred.promise;
  }, [
    fieldViewModeMap,
    handleSubmit,
    isFieldVisibleMap,
    onCreateCampaignCallback,
    setError,
  ]);

  return (
    <aside className='flex flex-col flex-1 justify-between'>
      <div className='flex flex-col items-start p-6 gap-6'>
        <Controller
          name={'what'}
          control={control}
          render={({ field }) => {
            return (
              <>
                <WhatSection
                  onAddNewServiceClickedCallback={
                    onAddNewServiceClickedCallback
                  }
                  doesBusinessActivitySupportServices={
                    doesBusinessActivitySupportServices
                  }
                  servicesFromPlaybook={services}
                  value={field.value}
                  displayMode={fieldViewModeMap[field.name].mode}
                  onDisplayModeChangedCallback={(mode) =>
                    onFieldDisplayModeChanged(field.name, mode)
                  }
                  onValueChangedCallback={field.onChange}
                  isLoading={false}
                />
                {errors[field.name]?.message && (
                  <ObInputCaption
                    type={'error'}
                    message={errors[field.name]?.message!}
                  />
                )}
              </>
            );
          }}
        />
        <Transition
          show={isFieldVisibleMap[fields[1].id]}
          enter='transition-opacity duration-700 delay-100'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-700'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          unmount
        >
          <div className='flex flex-col self-stretch'>
            <Controller
              name={'who'}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <WhoSection
                      onAddNewCustomerProfileClickedCallback={
                        onAddNewCustomerProfileClickedCallback
                      }
                      personasFromPlaybook={customerProfiles}
                      value={field.value}
                      onValueChangedCallback={field.onChange}
                      displayMode={fieldViewModeMap[field.name].mode}
                      onDisplayModeChangedCallback={(mode) =>
                        onFieldDisplayModeChanged(field.name, mode)
                      }
                      isLoading={false}
                    />
                    {errors[field.name]?.message && (
                      <ObInputCaption
                        type={'error'}
                        message={errors[field.name]?.message!}
                      />
                    )}
                  </>
                );
              }}
            />
          </div>
        </Transition>

        <Transition
          show={isFieldVisibleMap[fields[2].id]}
          enter='transition-opacity duration-700 delay-100'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-700'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          unmount
        >
          <div className='flex flex-col self-stretch'>
            <Controller
              name={'where'}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <WhereSection
                      onAddNewPhysicalLocationClickedCallback={
                        onAddNewPhysicalLocationClickedCallback
                      }
                      onAddNewServiceAreaClickedCallback={
                        onAddNewServiceAreaClickedCallback
                      }
                      doesBusinessActivitySupportPhysicalLocations={
                        doesBusinessActivitySupportPhysicalLocations
                      }
                      doesBusinessActivitySupportServiceAreas={
                        doesBusinessActivitySupportServiceAreas
                      }
                      physicalLocationsFromPlaybook={physicalLocations}
                      serviceAreasFromPlaybook={serviceAreas}
                      value={field.value}
                      onValueChangedCallback={field.onChange}
                      displayMode={fieldViewModeMap[field.name].mode}
                      onDisplayModeChangedCallback={(mode) =>
                        onFieldDisplayModeChanged(field.name, mode)
                      }
                      isLoading={false}
                    />
                    {errors[field.name]?.message && (
                      <ObInputCaption
                        type={'error'}
                        message={errors[field.name]?.message!}
                      />
                    )}
                  </>
                );
              }}
            />
          </div>
        </Transition>
        <Transition
          show={isFieldVisibleMap[fields[3].id]}
          enter='transition-opacity duration-700 delay-100'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-700'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          unmount
        >
          <div className='flex flex-col self-stretch'>
            <Controller
              name={'channel'}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <ChannelSection
                      availableChannels={availableChannels}
                      value={field.value as CampaignChannelType}
                      onValueChangedCallback={field.onChange}
                      displayMode={fieldViewModeMap[field.name].mode}
                      onDisplayModeChangedCallback={(mode) =>
                        onFieldDisplayModeChanged(field.name, mode)
                      }
                      isLoading={false}
                    />
                    {errors[field.name]?.message && (
                      <ObInputCaption
                        type={'error'}
                        message={errors[field.name]?.message!}
                      />
                    )}
                  </>
                );
              }}
            />
          </div>
        </Transition>
        <Transition
          show={isFieldVisibleMap[fields[4].id]}
          enter='transition-opacity duration-700 '
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-700'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          unmount
        >
          <div className='flex flex-col self-stretch'>
            <div className='flex flex-col self-stretch gap-3'>
              <SectionLabel
                label='Campaign Details'
                id={'name'}
              />
              <FormRendererField
                fieldType={FormFieldType.TEXT}
                id='name'
                size={'small'}
                label={'Campaign Name'}
                isRequired={true}
                hideLabel={true}
                helperText={''}
                errors={errors}
                control={control}
                autofocusInput={true}
                fieldTypeSettings={{}}
              />
            </div>
            {variant === 'full-screen' && (
              <ObThinkingButton
                size='large'
                onClickCallback={handleFormSubmit}
                label={'Create Campaign'}
              />
            )}
          </div>
        </Transition>
      </div>

      {variant === 'drawer' && (
        <ObDrawerFooter
          primaryActionLabel='Save'
          primaryActionCallback={handleFormSubmit}
          secondaryActionLabel='Cancel'
          secondaryActionCallback={() => {
            return popDrawer(false);
          }}
        />
      )}
    </aside>
  );
};
