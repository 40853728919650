import { ObPhotoTileStateProps } from '@outbound/design-system/src/components/organisms/ob-photo-tile/ob-photo-tile';
import {
  CreateSubServiceResourceRequest,
  SubServiceResourceIncludeAssets,
} from '@outbound/types';

/**
 * Interface for the value shape of the service form
 * used to populate and submit the form
 */
export interface SubServiceFormValues {
  name: string;
  description: string;
  //Keyword Section
  relatedKeywords: Array<string>;

  //Photo Section
  photos: Array<ObPhotoTileStateProps>;
}

/**
 * Converts the Sub Service Form Values to the API shape
 * @param values
 * @returns
 */
export const mapFormValuesToApiResource = (
  values: SubServiceFormValues
): CreateSubServiceResourceRequest => {
  return {
    name: values.name,
    description: values.description,
    relatedKeywords: values.relatedKeywords ?? [],
    keyPhotoAssetId: values.photos?.find((photo) => photo.isKeyPhoto)?.assetId,
    additionalPhotoAssetIds:
      values.photos
        ?.filter((photo) => !photo.isKeyPhoto) // Don't include the key photo in the additional photos
        .map((photo) => photo.assetId) ?? [],
  };
};

/**
 * Converts a SubServiceAreaResource to a shape that can be used to populate the create / edit service area form
 * The data passed to this conversion function should be called with "?include=assets" to ensure the assets are hydrated
 * @param resource
 * @returns
 */
export const mapApiResourceToFormValues = (
  resource: SubServiceResourceIncludeAssets
): SubServiceFormValues => {
  return {
    name: resource.name,
    description: resource.description ?? '',
    relatedKeywords: resource.relatedKeywords ?? [],
    photos: buildPhotoValueFromResource(resource),
  };
};

const buildPhotoValueFromResource = (
  resource: SubServiceResourceIncludeAssets
): Array<ObPhotoTileStateProps> => {
  const value: Array<ObPhotoTileStateProps> = [];
  if (resource.keyPhotoAssetId && resource.keyPhotoAsset?.publicUrl != null) {
    value.push({
      assetId: resource.keyPhotoAssetId,
      isKeyPhoto: true,
      assetUrl: resource.keyPhotoAsset.publicUrl,
      assetAltText: resource.keyPhotoAsset.description ?? '',
    });
  }
  //Load Any Additional Photos if they exist
  if (
    resource.additionalPhotoAssets != null &&
    resource.additionalPhotoAssets.length > 0
  ) {
    value.push(
      ...resource.additionalPhotoAssets
        .filter((photo) => photo.publicUrl != null)
        .filter((photo) => photo.id !== resource.keyPhotoAssetId) // Don't include the key photo in the additional photos (Ignore if the backend has it)
        .map((photo) => ({
          assetId: photo.id,
          isKeyPhoto: false,
          assetUrl: photo.publicUrl!, //Filter ensures this is not null
          assetAltText: photo.description ?? '',
        }))
    );
  }

  return value;
};
