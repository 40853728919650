import { v4 as uuidv4 } from 'uuid';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import { ObFormRenderer } from '../../organisms/ob-form-renderer/ob-form-renderer';
import { FormFieldType } from '../../organisms/ob-form-renderer/ob-form-renderer.types';

export const renderDialogTextContent =
  (paragraphs: string | string[]) => () => {
    const content = Array.isArray(paragraphs) ? paragraphs : [paragraphs];

    return (
      <div className='flex flex-col flex-grow justify-between'>
        {content.map((paragraph, index) => {
          const isNotLastParagraph = index !== content.length - 1;
          return (
            <div key={uuidv4()}>
              <ObTypography
                color='secondary'
                className='mb-1'
              >
                {paragraph}
              </ObTypography>
              {isNotLastParagraph && <br></br>}
            </div>
          );
        })}
      </div>
    );
  };

type RenderDialogTextContentWithInputProps = {
  paragraphs: string | string[];
  expectedValue: string;
  inputLabel: string;
  onClickAffirmativeActionCallback: () => Promise<any>;
  onClickDismissiveActionCallback: () => Promise<void>;
  affirmativeActionButtonLabel: string;
  dismissiveActionButtonLabel: string;
};

export const renderDialogTextContentWithInput =
  ({
    paragraphs,
    expectedValue,
    inputLabel,
    onClickAffirmativeActionCallback,
    onClickDismissiveActionCallback,
    affirmativeActionButtonLabel,
    dismissiveActionButtonLabel,
  }: RenderDialogTextContentWithInputProps) =>
  () => {
    const TextContent = renderDialogTextContent(paragraphs);

    return (
      <>
        <TextContent />
        <div className='pb-4'>
          <ObFormRenderer
            formDefinition={{
              id: 'confirmation-dialog',
              heading: '',
              subHeading: '',
              sections: [
                {
                  id: 'confirmation-dialog-section',
                  heading: '',
                  subHeading: '',
                  fields: [
                    {
                      id: 'confirmation-dialog-input',
                      label: inputLabel,
                      autofocus: true,
                      type: FormFieldType.TEXT,
                      helperText: `Type "${expectedValue}" to confirm`,
                      validationSchema: {
                        isRequired: true,
                        in: [expectedValue],
                      },
                      fieldTypeSettings: {},
                    },
                  ],
                },
              ],
            }}
            defaultValues={{}}
            onSubmitCallback={onClickAffirmativeActionCallback}
            submitButtonLabel={affirmativeActionButtonLabel}
            cancelButtonLabel={dismissiveActionButtonLabel}
            onCancelCallback={onClickDismissiveActionCallback}
          />
        </div>
      </>
    );
  };
