import {
  LocationMapGenerationStatus,
  LocationType,
  ServerDelta,
} from '@outbound/types';
import { makeObservable, observable, runInAction } from 'mobx';
import ImageAsset from '../../assets/image-asset';
import { BaseModel } from '../../base-model';
import { RootStore } from '../../root-store';

/**
 * A Location is a single object that represents a location in the system.
 * A location can have a type of Service Area or Physical Location.
 */
abstract class Location extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    name: '/name',
    description: '/description',
    mapImageGenerationStatus: '/mapImageGenerationStatus',
    mapImageForDarkModeAssetId: '/mapImageForDarkModeAssetId',
    mapImageForLightModeAssetId: '/mapImageForLightModeAssetId',
  };

  private _name: string;
  private _mapImageGenerationStatus: LocationMapGenerationStatus;
  private _mapImageForDarkModeAssetId?: string;
  private _mapImageForLightModeAssetId?: string;
  private _description: string;
  private _locationType: LocationType;

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    locationType: LocationType,
    name: string,
    description: string,
    mapImageGenerationStatus: LocationMapGenerationStatus,
    mapImageForDarkModeAssetId?: string,
    mapImageForLightModeAssetId?: string
  ) {
    super(rootStore, 'location', '1', id, workspaceId);
    this._name = name;
    this._description = description;
    this._locationType = locationType;
    this._mapImageGenerationStatus = mapImageGenerationStatus;
    this._mapImageForDarkModeAssetId = mapImageForDarkModeAssetId;
    this._mapImageForLightModeAssetId = mapImageForLightModeAssetId;
  }

  /**
   * A human readable description of the location
   * based on the location type and the location data.
   *
   * This is used to describe the location in the UI as a string.
   *
   * Do not confuse this with the description property which is a
   * user provided description of the location for business purposes.
   */
  abstract get geographicDescription(): string;

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get locationType(): LocationType {
    return this._locationType;
  }

  get mapImageGenerationStatus(): LocationMapGenerationStatus {
    return this._mapImageGenerationStatus;
  }

  get mapImageForDarkBackground(): ImageAsset | null {
    if (this._mapImageForDarkModeAssetId == null) {
      return null;
    }
    return (
      (this._rootStore.assetStore.getById(
        this._mapImageForDarkModeAssetId
      ) as ImageAsset) || null
    );
  }

  get mapImageForLightBackground(): ImageAsset | null {
    if (this._mapImageForLightModeAssetId == null) {
      return null;
    }
    return (
      (this._rootStore.assetStore.getById(
        this._mapImageForLightModeAssetId
      ) as ImageAsset) || null
    );
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _name: observable,
      _description: observable,
      _mapImageGenerationStatus: observable,
      _mapImageForLightModeAssetId: observable,
      _mapImageForDarkModeAssetId: observable,
    } as any);
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      for (const delta of patch) {
        switch (delta.path) {
          case Location.paths.mapImageGenerationStatus: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._mapImageGenerationStatus =
                  delta.value as LocationMapGenerationStatus;
                break;
              case 'remove':
                console.error(
                  'Invalid Delta. Cannot remove Location Image Generation Status'
                );
            }
            break;
          }
          case Location.paths.mapImageForDarkModeAssetId: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._mapImageForDarkModeAssetId = delta.value;
                break;
              case 'remove':
                console.error(
                  'Invalid Delta. Cannot remove Location Image For Dark Mode Asset Id'
                );
            }
            break;
          }
          case Location.paths.mapImageForLightModeAssetId: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._mapImageForLightModeAssetId = delta.value;
                break;
              case 'remove':
                console.error(
                  'Invalid Delta. Cannot remove Location Image For Light Mode Asset Id'
                );
            }
            break;
          }
        }
      }
    });
  }

  toJson(): Record<string, any> {
    const baseJson = super.toJson();

    return {
      ...baseJson,
      [Location.paths.name]: this._name,
      [Location.paths.description]: this._description,
      [Location.paths.mapImageGenerationStatus]: this._mapImageGenerationStatus,
      [Location.paths.mapImageForDarkModeAssetId]:
        this._mapImageForDarkModeAssetId,
      [Location.paths.mapImageForLightModeAssetId]:
        this._mapImageForLightModeAssetId,
    };
  }

  public delete(): void {
    runInAction(() => {
      this._rootStore.locationStore.delete(this._id);
    });
  }
}

export default Location;
