import { CampaignChannel, CampaignChannelType } from '@outbound/types';
import { FC, useMemo } from 'react';
import { ObAvatar, ObAvatarProps } from '../..';
import {
  billboardsIcon,
  facebookIcon,
  generalAdsIcon,
  googleAdsIcon,
  googleIcon,
  instagramIcon,
  linkedInIcon,
  mailerIcon,
  podcastsIcon,
  streamingTvIcon,
} from '../../assets';
import { ObAvatarSize } from '../avatar.types';

export interface ObChannelAvatarProps {
  channel: CampaignChannelType | CampaignChannel;
  size: ObAvatarSize;
}

const getChannelProps = (
  channel: CampaignChannelType | CampaignChannel
): Omit<ObAvatarProps, 'size'> => {
  switch (channel) {
    case CampaignChannelType.GOOGLE_SEARCH_ADS:
      return {
        profileImageUrl: googleIcon,
        fallbackText: 'Google Search Ads',
      };
    case CampaignChannelType.GOOGLE_DISPLAY_ADS:
      return {
        profileImageUrl: googleAdsIcon,
        fallbackText: 'Google Display Ads',
      };
    case CampaignChannelType.FACEBOOK_ADS:
      return {
        profileImageUrl: facebookIcon,
        fallbackText: 'Facebook Ads',
      };
    case CampaignChannelType.BILLBOARD_ADS:
      return {
        profileImageUrl: billboardsIcon,
        fallbackText: 'Billboards',
      };
    case CampaignChannelType.LINKEDIN_ADS:
      return {
        profileImageUrl: linkedInIcon,
        fallbackText: 'LinkedIn Ads',
      };
    case CampaignChannelType.INSTAGRAM_ADS:
      return {
        profileImageUrl: instagramIcon,
        fallbackText: 'Instagram Ads',
      };
    case CampaignChannelType.PODCAST_ADS:
      return {
        profileImageUrl: podcastsIcon,
        fallbackText: 'Podcast Ads',
      };
    case CampaignChannelType.STREAMING_TV_ADS:
      return {
        profileImageUrl: streamingTvIcon,
        fallbackText: 'Streaming TV Ads',
      };
    case CampaignChannelType.MAILER_ADS:
      return {
        profileImageUrl: mailerIcon,
        fallbackText: 'Mailers',
      };
    default:
      return {
        profileImageUrl: generalAdsIcon,
        fallbackText: 'Ad Channel',
      };
  }
};

export const ObChannelAvatar: FC<ObChannelAvatarProps> = ({
  channel,
  size = 'medium',
}) => {
  const channelProps = useMemo(() => getChannelProps(channel), [channel]);
  if (!channel) {
    return <div>No channel provided</div>;
  }

  return (
    <div data-testid={'channel-avatar'}>
      <ObAvatar
        size={size}
        shape='rounded'
        {...channelProps}
      />
    </div>
  );
};
