import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { BehaviorSubject, Observable } from '@reactivex/rxjs/dist/package';
import PropTypes from 'prop-types';

export interface FormServiceContextValue {
  getFormValues: () => Observable<any>;
  getCurrentFormValues: () => any;
  updateFormValues: (values: any) => void;
}

const FormServiceContext = createContext<FormServiceContextValue>({
  getFormValues: () => new Observable<any>(),
  getCurrentFormValues: () => undefined,
  updateFormValues: () => undefined,
});

interface ObFormServiceProviderProps {
  children?: ReactNode;
}

export interface FormServiceResponse {
  timestamp: number;
  formValues: any;
}

const formUpdateBehaviorSubject = new BehaviorSubject<FormServiceResponse>({
  timestamp: 0,
  formValues: {},
});

const updateFormValuesSubject = (nextValues: any) => {
  formUpdateBehaviorSubject.next({
    timestamp: Date.now(),
    formValues: nextValues,
  });
};

export const ObFormServiceProvider: FC<ObFormServiceProviderProps> = ({
  children,
}) => {
  const getFormValues = useCallback(() => {
    return formUpdateBehaviorSubject.asObservable();
  }, []);

  const updateFormValues = useCallback((updatedFormValue: any) => {
    const oldFormValues = formUpdateBehaviorSubject.value.formValues;

    const toUpdateKey = Object.keys(updatedFormValue)[0];
    const toUpdateValue = Object.values(updatedFormValue)[0];

    updateFormValuesSubject({
      ...oldFormValues,
      ...(typeof toUpdateValue === 'object'
        ? {
            // Update also custom components with more than one value
            [toUpdateKey]: {
              ...oldFormValues[toUpdateKey],
              ...toUpdateValue,
            },
          }
        : updatedFormValue),
    });
  }, []);

  const service = useMemo(
    () => ({
      getFormValues,
      getCurrentFormValues: () => formUpdateBehaviorSubject.value.formValues,
      updateFormValues,
    }),
    [getFormValues, updateFormValues]
  );

  return (
    <FormServiceContext.Provider value={service}>
      {children}
    </FormServiceContext.Provider>
  );
};

ObFormServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const FormServiceConsumer = FormServiceContext.Consumer;

export const useFormService = () => useContext(FormServiceContext);
