import { LandingPageType } from '@outbound/types';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

export const PageTitle = ({
  title,
  landingPageType,
}: {
  title: string;
  landingPageType: LandingPageType;
}) => {
  return (
    <div className='bg-light/background/default rounded-full p-1 flex flex-row flex-nowrap line-clamp-1 gap-1 justify-between items-center'>
      <div className='flex flex-row gap-1 justify-start items-center flex-nowrap pl-1'>
        <ObIcon
          icon='googleGlobe'
          color='tertiary'
          size='small'
        />
        <ObTypography
          variant='body1'
          className='line-clamp-1'
          color='invertedPrimary'
        >
          {title}
        </ObTypography>
      </div>
      <div className=''>
        <div className='rounded-full bg-light/content/tertiary px-3 py-2'>
          <ObTypography
            variant='body3'
            className='line-clamp-1'
            color='primary'
          >
            {landingPageType === 'SELF_HOSTED' ? 'Self' : 'Outbound'}
          </ObTypography>
        </div>
      </div>
    </div>
  );
};
