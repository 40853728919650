import { makeObservable, observable, runInAction } from 'mobx';

import { AssetStatus, ServerDelta } from '@outbound/types';
import Asset from './asset';

class ImageAsset extends Asset {
  static readonly paths = {
    ...Asset.paths,
    publicUrl: '/publicUrl',
    altText: '/altText',
  };

  private _mimeType: string;
  private _altText: string;
  private _classification: string;
  private _publicUrl: string;
  private _brightness: number;
  private _colorPalette: string[];
  private _dominantColor: string;
  private _recommendedBackground: 'DARK' | 'LIGHT';
  private _hasAlphaChannel: boolean;
  private _height: number;
  private _width: number;
  private _aspectRatio: number;

  constructor(
    rootStore: any,
    id: string,
    workspaceId: string,
    status: AssetStatus,
    mimeType: string,
    altText: string,
    classification: string,
    publicUrl: string,
    brightness: number,
    colorPalette: string[],
    dominantColor: string,
    recommendedBackground: 'DARK' | 'LIGHT',
    hasAlphaChannel: boolean,
    height: number,
    width: number,
    aspectRatio: number
  ) {
    super(rootStore, id, workspaceId, 'image', status);
    this._mimeType = mimeType;
    this._altText = altText;
    this._classification = classification;
    this._publicUrl = publicUrl;
    this._brightness = brightness;
    this._colorPalette = colorPalette;
    this._dominantColor = dominantColor;
    this._recommendedBackground = recommendedBackground;
    this._hasAlphaChannel = hasAlphaChannel;
    this._height = height;
    this._width = width;
    this._aspectRatio = aspectRatio;
  }

  /**
   * Turns on Observability for this class
   */
  protected makeObservableInternal(): void {
    makeObservable({
      _altText: observable,
      _publicUrl: observable,
    });
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      super.applyPatch(patch);
      for (const delta of patch) {
        switch (delta.path) {
          case ImageAsset.paths.publicUrl: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._publicUrl = delta.value;
                break;
              case 'remove':
                console.error('Invalid Delta. Cannot remove Image public url');
            }
            break;
          }
        }
      }
    });
  }
  toJson(): Record<string, any> {
    const baseJson = super.toJson();
    return {
      ...baseJson,
      mimeType: this._mimeType,
      [ImageAsset.paths.altText]: this._altText,
      [ImageAsset.paths.publicUrl]: this._publicUrl,
    };
  }

  get mimeType(): string {
    return this._mimeType;
  }

  get altText(): string {
    return this._altText;
  }

  get publicUrl(): string {
    return this._publicUrl;
  }

  get classification(): string {
    return this._classification;
  }

  get brightness(): number {
    return this._brightness;
  }

  get colorPalette(): string[] {
    return this._colorPalette;
  }

  get dominantColor(): string {
    return this._dominantColor;
  }

  get recommendedBackground(): 'DARK' | 'LIGHT' {
    return this._recommendedBackground;
  }

  get hasAlphaChannel(): boolean {
    return this._hasAlphaChannel;
  }

  get height(): number {
    return this._height;
  }

  get width(): number {
    return this._width;
  }

  get aspectRatio(): number {
    return this._aspectRatio;
  }
}

export default ImageAsset;
