import { cx } from 'class-variance-authority';
import { FC, ReactNode } from 'react';
import { ObTypography } from '../ob-typography/ob-typography';

export interface ObPillProps {
  children: ReactNode;
  className?: string;
}

/**
 * @param content
 * @constructor
 */
export const ObPill: FC<ObPillProps> = ({
  children,
  className,
  ...props
}: ObPillProps) => {
  return (
    <div
      {...props}
      className={cx(
        'inline-flex items-center justify-center  rounded-[30px] py-0.5 px-2.5 h-6  bg-primary-2 dark:bg-bgInvertedDark ',
        className
      )}
    >
      <ObTypography
        variant={'subtitle3'}
        color='primary'
        contrast={true}
        className='whitespace-nowrap text-ellipsis'
      >
        {children}
      </ObTypography>
    </div>
  );
};
