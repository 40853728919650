import { parseObrn } from '@otbnd/utils';
import {
  CampaignHighlightWithCreativeResource,
  CampaignResource,
  CampaignResourceWithCreative,
  CampaignSettingValueResource,
  ClientDelta,
} from '@outbound/types';
import { AxiosInstance, isAxiosError } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';
import Campaign from '../campaign';
import CampaignHighlight from '../campaign-highlight/campaign-highlight';
import CampaignDeploymentTransport from './campaign-deployment-transport';
import CampaignSettingTransport from './campaign-settings-transport';

/**
 * Initial implementation of the Campaign Transport functionality
 * The goal of this is to abstract the details of fetching a campaign from the server
 * from the store and allow the store to focus on managing the state of the campaign
 */
class CampaignTransport extends BaseTransport<
  CampaignResourceWithCreative | CampaignResource
> {
  readonly settingsTransport: CampaignSettingTransport;
  readonly deploymentTransport: CampaignDeploymentTransport;

  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
    this._transport = transport;
    this.settingsTransport = new CampaignSettingTransport(axiosInstance);
    this.deploymentTransport = new CampaignDeploymentTransport(axiosInstance);
  }

  public acceptEmbeddedResource(_resource: CampaignResourceWithCreative): void {
    /**
     * No resources currently embed a campaign
     */
    throw new Error('Method not implemented.');
  }

  public async internalBootstrap() {
    /**
     * This is a temporary hackly way to get the campaigns and their highlights since the list endpoint does not contain the data we need
     */
    const response = await this._axiosInstance.get('/campaigns');
    if (response.data?.items) {
      response.data.items.forEach((result: CampaignResourceWithCreative) => {
        this.onResourceFetchedFromServer(result.id, result);
        this.notifyStoreOfServiceUpdateCallback?.(result.id, result);
      });
    }
  }

  protected onResourceFetchedFromServer(
    id: string,
    resource: CampaignResourceWithCreative | CampaignResource
  ): void {
    console.log('Campaign Fetch Side Effects', id);
    if (resource.status === 'INITIALIZING') {
      this.pollForResourceInTargetState(
        id,
        (resource) => {
          return resource.status !== 'INITIALIZING';
        },
        (resource) => {
          return resource.status === 'INITIALIZATION_FAILED';
        },
        'CAMPAIGN-INIT'
      );
    } else {
      /**
       * If the campaign is initializing there will be no highlights to forward
       */

      if (resource.highlights) {
        const highlightsWithPotentialCreatives =
          resource.highlights as Array<CampaignHighlightWithCreativeResource>;
        highlightsWithPotentialCreatives.forEach((highlight) => {
          if (highlight.creative != null) {
            console.log(
              'Forwarding Embedded Creative Resource to Creative Transport',
              highlight.creative.id
            );
            this._transport.creativeTransport.acceptEmbeddedResource(
              highlight.creative
            );
          }
        });
      }
    }
  }
  /**
   * Currently the server does not have a sync endpoint that accepts our delta objects however in the future
   * we would like to have one. I have done my best to keep the stores only aware of the client deltas and
   * push the responsibility of converting the client deltas to a shape that the API can accept to the transport layer.
   *
   * This is still experimental and may not be the best approach long term.
   */
  protected async processDeltaQueueFlush(
    dedupeDeltaQueue: Array<ClientDelta>
  ): Promise<void> {
    /**
     * Create Setting Batch Updates for Each Campaign in the Queue
     * May be overkill to batch these by campaign when you think that a user can only edit one campaign at a time
     * however conceptually this sets us up for the future where there may be offline editing
     */
    const settingValuesByCampaignId: Map<
      string,
      Array<CampaignSettingValueResource>
    > = new Map();

    const campaignPatchValues: Map<string, Record<string, any>> = new Map();

    /**
     * Two Problems to Solve.
     * For each delta we need a campaign id and the setting id.
     * We are currently missing these pieces of information.
     *
     * If we update the ID object of campaign/campaignHighlight and campaign/location to include the campaign id
     * and the setting id we can then use the ID object to determine the campaign id and the setting id.
     *
     * This forces us to update the backend with a representation of these objects which is something that we want
     * to do anyway before we begin to implement landing pages.
     *
     * The other option is to include a reference to the model in the delta object which would allow us to access it
     * when we are ready to flush the queue and pull what ever information we need from the model here in this custom logic.
     */
    Object.entries(dedupeDeltaQueue).forEach(([, delta]) => {
      console.log('Processing Delta', delta);
      let campaignId: string;

      if (delta.object === 'campaign/highlight') {
        const { localPathId } = parseObrn(delta.obrn);
        campaignId = localPathId.split('/')[0];
      } else {
        campaignId = delta.id;
      }
      let settingsForCampaign: Array<CampaignSettingValueResource> | undefined =
        settingValuesByCampaignId.get(campaignId);

      if (!settingsForCampaign) {
        console.log('No Settings for Campaign Found', settingsForCampaign);
        settingsForCampaign = [];
        settingValuesByCampaignId.set(campaignId, settingsForCampaign);
        console.log('Created Settings for Campaign', settingsForCampaign);
      }
      if (delta.object === 'campaign/highlight') {
        if (delta.path === CampaignHighlight.paths.associatedLandingPageObrn) {
          console.log('Campaign Highlight Landing Page Changed', delta);
          const settingUpdateResource: CampaignSettingValueResource = {
            id: `${delta.obrn}/associatedLandingPageObrn`,
            value: delta.value,
            updatedAtTimestamp: delta.clientTimestamp,
            type: 'string',
          };
          settingsForCampaign.push(settingUpdateResource);
          console.log(
            'Settings for Campaign After Landing Page Update',
            settingsForCampaign
          );
        }
        if (delta.path === '/isEnabled') {
          console.log('Campaign Highlight Is Enabled Changed', delta);
          const settingUpdateResource: CampaignSettingValueResource = {
            id: `${delta.obrn}/enabled`,
            value: delta.value,
            updatedAtTimestamp: delta.clientTimestamp,
            type: 'boolean',
          };
          settingsForCampaign.push(settingUpdateResource);
          console.log(
            'Settings for Campaign After Highlight Update',
            settingsForCampaign
          );
        }
      } else if (delta.object === 'campaign/location') {
        if (delta.path === '/isEnabled') {
          settingsForCampaign.push({
            id: `${delta.obrn}/enabled`,
            value: delta.value,
            updatedAtTimestamp: delta.clientTimestamp,
            type: 'boolean',
          });
        }
        /**
         * Campaign Object itself does not include realtime syncing.
         * The only editable properties are the name and the daily budget.
         
         */
      } else if (delta.object === 'campaign') {
        let campaignPatch = campaignPatchValues.get(campaignId);
        if (campaignPatch == null) {
          campaignPatch = {};
          campaignPatchValues.set(campaignId, campaignPatch);
        }
        /**
         * We will manually craft a patch for the campaign object based on what is being
         * edited in the client.
         */
        switch (delta.path) {
          case Campaign.paths.name:
            campaignPatch.name = delta.value;
            break;
          case Campaign.paths.dailyBudget:
            campaignPatch.budget = {
              amount: delta.value,
            };
            break;
          case Campaign.paths.pausedByUserState:
            campaignPatch.pausedByUserState = delta.value;
            break;
        }
      }
    });

    await Promise.all(
      Array.from(campaignPatchValues.entries()).map(([campaignId, patch]) => {
        console.log('Sending Patch for Campaign', campaignId, patch);
        return this._axiosInstance.patch(`/campaigns/${campaignId}`, patch);
      })
    );

    //Send Batched Settings
    await Promise.all(
      Array.from(settingValuesByCampaignId.entries()).map(
        ([campaignId, settings]) => {
          if (settings.length > 0) {
            console.log('Sending Settings for Campaign', campaignId, settings);
            return this._axiosInstance.patch(
              `/campaigns/${campaignId}/settings`,
              {
                campaignSettingValues: settings,
              }
            );
          } else {
            return Promise.resolve();
          }
        }
      )
    );
  }

  protected async fetchById(
    id: string,
    includeCreative: boolean = true
  ): Promise<CampaignResourceWithCreative | null> {
    const include = includeCreative ? 'creative' : undefined;
    try {
      const response =
        await this._axiosInstance.get<CampaignResourceWithCreative>(
          `/campaigns/${id}`,
          {
            params: {
              include: include,
            },
          }
        );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null;
        }
      }
      throw error;
    }
  }

  public async delete(id: string): Promise<void> {
    await this._axiosInstance.delete(`/campaigns/${id}`);
  }

  public async pollForDeploymentStatus(id: string): Promise<void> {
    await this.pollForResourceInTargetState(
      id,
      (resource) => {
        return (
          resource.latestDeployment?.status === 'SUCCEEDED' ||
          resource.latestDeployment?.status === 'FAILED'
        );
      },
      () => {
        return false;
      },
      'CAMPAIGN-DEPLOY'
    );
  }
}

export default CampaignTransport;
