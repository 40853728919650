import { BRAND_GUIDE_PAGE_DEF } from './pages/brand-guide/brand-guide.def';
import { BUSINESS_OVERVIEW_PAGE_DEF } from './pages/business-overview/business-overview.def';
import { CUSTOMER_PERSONAS_PAGE_DEF } from './pages/customer-personas/customer-personas.def';
import { LOCATIONS_PAGE_DEF } from './pages/locations/locations.def';
import { PRODUCTS_AND_SERVICES_PAGE_DEF } from './pages/products-and-services/products-and-services.def';
import { PlaybookPageSectionDefinition } from './pages/playbook-pages';
import { BusinessOverview } from './pages/business-overview/business-overview';
import { CustomerPersonas } from './pages/customer-personas/customer-personas';
import { BrandGuide } from './pages/brand-guide/brand-guide';
import { Locations } from './pages/locations/locations';
import { ProductsAndServices } from './pages/products-and-services/products-and-services';
import { OnlinePresence } from './pages/online-presence/online-presence';
import { ONLINE_PRESENCE_PAGE_DEF } from './pages/online-presence/online-presence.def';

export const playbookPageSections: Array<PlaybookPageSectionDefinition> = [
  {
    id: 'about-the-company',
    title: 'About the Company',
    pages: [
      { ...BUSINESS_OVERVIEW_PAGE_DEF, component: BusinessOverview },
      { ...BRAND_GUIDE_PAGE_DEF, component: BrandGuide },
      { ...ONLINE_PRESENCE_PAGE_DEF, component: OnlinePresence },
      { ...LOCATIONS_PAGE_DEF, component: Locations },
      {
        ...PRODUCTS_AND_SERVICES_PAGE_DEF,
        component: ProductsAndServices,
      },
      {
        ...CUSTOMER_PERSONAS_PAGE_DEF,
        component: CustomerPersonas,
      },
    ],
  },
];
