import { cva } from 'class-variance-authority';
import { ObButton } from '../../elements/ob-button/ob-button';

export type QualityRanking = 'good' | 'neutral' | 'bad';
export interface ObQualityFeedbackButtonGroupProps {
  /**
   * The current value of the button group. State is managed by the parent component
   */
  value: QualityRanking;
  /**
   * A callback function provided by the parent component to handle the value change
   * internally by this component. This is a controlled component so the parent is responsible
   * for managing the state of the value.
   * @param value The new value the child component is requesting
   * @returns
   */
  onValueChangedCallback: (value: QualityRanking) => void;
}

/**
 * A button group used to rate quality as good or bad
 * Supports un-ranking as well to put the quality back to neutral
 */
export const ObQualityFeedbackButtonGroup = ({
  value,
  onValueChangedCallback,
}: ObQualityFeedbackButtonGroupProps) => {
  /**
   * Styles for the Icon Buttons
   */
  const goodQualityButtonIconStyles = cva('good-quality-button-icon', {
    variants: {
      active: {
        false: 'dark:text-dark/content/tertiary',
        true: 'dark:text-dark/content/positive',
      },
    },
  });
  const badQualityButtonIconStyles = cva('bad-quality-button-icon', {
    variants: {
      active: {
        true: 'dark:text-dark/content/on-subtle-negative',
        false: 'dark:text-dark/content/tertiary',
      },
    },
  });
  /**
   * Handles the good button click event
   * @param currentValue - The current state of the value. Used to determine the next state
   * @returns
   */
  const handleOnGoodButtonClicked = (currentValue: QualityRanking) => {
    switch (currentValue) {
      case 'good':
        /**
         * When the quality is already good, and good is clicked. We will un-rank the quality to neutral
         */
        onValueChangedCallback?.('neutral');
        return;
      case 'neutral':
      case 'bad':
        /**
         * When the quality is already neutral or bad, and good is clicked. We will set the quality to good
         */
        onValueChangedCallback?.('good');
        return;
    }
  };

  /**
   * Handles the bad button click event
   * @param currentValue - The current state of the value. Used to determine the next state
   * @returns
   */
  const handleOnBadButtonClicked = (currentValue: QualityRanking) => {
    switch (currentValue) {
      case 'bad':
        /**
         * When the quality is already good, and good is clicked. We will un-rank the quality to neutral
         */
        onValueChangedCallback?.('neutral');
        return;
      case 'neutral':
      case 'good':
        /**
         * When the quality is already neutral or bad, and good is clicked. We will set the quality to good
         */
        onValueChangedCallback?.('bad');
        return;
    }
  };

  return (
    <div className='flex flex-row'>
      <ObButton
        variant='ghost'
        aria-pressed={value === 'good'}
        buttonType='icon'
        size='medium'
        iconLeft='goodQuality'
        label='Good'
        iconLeftClassName={goodQualityButtonIconStyles({
          active: value === 'good',
        })}
        onClick={() => handleOnGoodButtonClicked(value)}
      />
      <ObButton
        variant='ghost'
        aria-pressed={value === 'bad'}
        buttonType='icon'
        size='medium'
        iconLeft='badQuality'
        label='Bad'
        iconLeftClassName={badQualityButtonIconStyles({
          active: value === 'bad',
        })}
        onClick={() => handleOnBadButtonClicked(value)}
      />
    </div>
  );
};
