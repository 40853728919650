export enum Shape {
  circle = 'circle',
  rectangle = 'rectangle',
}

export type CroppedImage = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type CroppedResult = {
  originalFileName: string;
  croppedImage: Blob;
  crop: CroppedImage;
  rotation: number;
};

export interface ImageCropperUploadInterface {
  /**
   * Label for the component slider
   */
  label?: string;
  /**
   * The heading displayed at the top of the Dialog
   */
  cropModalHeading?: string;
  /**
   * Shape of the crop area
   */
  shape?: keyof typeof Shape;
  /**
   * Aspect of the cropper. The value is the ratio between its width and its height.
   */
  aspectRatio?: number;
  /**
   * Whether the cropper grid should be shown
   */
  showGrid?: boolean;
  /**
   * The displayed text of the confirmation button
   */
  cropModalSaveButtonLabel?: string;
  /**
   * The displayed text of the cancel button
   */
  cropModalCancelButtonLabel?: string;
}
