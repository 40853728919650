import { PersonaResource } from '@outbound/types';
import { AxiosInstance } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';

export class CustomerProfileTransport extends BaseTransport<PersonaResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  public acceptEmbeddedResource(resource: PersonaResource): void {
    this.notifyStoreOfServiceUpdateCallback?.(resource.id, resource);
  }

  protected async fetchById(id: string): Promise<PersonaResource> {
    const response = await this._axiosInstance.get(
      'playbook/settings/persona/' + id
    );
    return response.data;
  }
}

export default CustomerProfileTransport;
