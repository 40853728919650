import { FC } from 'react';

import { ObAvatar, ObAvatarProps } from '../../..';
import { ObSkeleton } from '../../elements/ob-skeleton/ob-skelton';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObProfileProps {
  /**
   * Renders a Rounded Square for Entities and a Circle for Individuals
   */
  variant: 'individual' | 'entity';
  /**
   * The main image displayed for the profile
   */
  avatarImageUrl?: string;
  /**
   * The main text content of the profile, usually the name of the entity or person
   * the profile is for.
   */
  title: string;
  /**
   * A secondary detail from the profile, could be a job title, email address or anything else
   * that makes sense in context of the design system
   */
  detail?: string;
  /**
   * An optional secondary image displayed next to the detail text line.
   * This is used by the design system for an affiliated logo when the detail
   * is used to display a job title.
   */
  detailAvatarImageUrl?: string;
  loading?: boolean;
  avatarSize?: ObAvatarProps['size'];
}

/*
Individual

Author Hero
64 x 64 Border Radius 64px;
Title: H3 - Primary
Detail: Body2 - Secondary

Review
48px x 48px border-radius 48px;
Title Subtitle 1 - Primary
Detail Body 2 - Secondary

Entity

 48 x 48 Border Radius 8px ;
 40px x 40px Border Radius 12px Subtitle2, body2 Secondary;
*/

const Loading: FC<ObProfileProps> = ({
  avatarSize,
  variant,
}: ObProfileProps) => {
  return (
    <div
      className='flex gap-4 items-center w-full'
      data-testid='loading'
    >
      <div>
        <ObAvatar
          loading={true}
          size={avatarSize ?? 'medium'}
          shape={variant === 'entity' ? 'rounded' : 'circle'}
        />
      </div>
      <div className='flex flex-col  w-full'>
        <ObTypography variant='subtitle1'>
          <ObSkeleton
            variant='text'
            width='32%'
          />
        </ObTypography>
        <ObTypography variant='subtitle3'>
          <ObSkeleton
            variant='text'
            width='72%'
          />
        </ObTypography>
      </div>
      <span className='sr-only'>Loading...</span>
    </div>
  );
};

export const ObProfile: FC<ObProfileProps> = ({
  title,
  avatarImageUrl,
  detail,
  detailAvatarImageUrl,
  variant = 'individual',
  loading = false,
  avatarSize = 'medium',
}: ObProfileProps) => {
  return loading ? (
    <Loading
      avatarSize={avatarSize}
      variant={variant}
      title={''}
    />
  ) : (
    <div className='flex gap-4 items-center'>
      <ObAvatar
        size={avatarSize}
        shape={variant === 'entity' ? 'rounded' : 'circle'}
        profileImageUrl={avatarImageUrl}
        fallbackText={title}
      />
      <div className='flex flex-1 flex-col'>
        <ObTypography
          variant='subtitle1'
          className='text-left'
        >
          {title}
        </ObTypography>

        <div className='flex gap-2'>
          {detailAvatarImageUrl && (
            <ObAvatar
              size='small'
              shape='rounded'
              profileImageUrl={detailAvatarImageUrl}
              fallbackText={title}
            />
          )}
          {detail && (
            <ObTypography
              className='dark:text-contentSecondaryDark text-contentSecondaryLight'
              variant='subtitle3'
            >
              {detail}
            </ObTypography>
          )}
        </div>
      </div>
    </div>
  );
};
