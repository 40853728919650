import { ServerDelta } from '@outbound/types';
import { makeObservable, observable } from 'mobx';
import { BaseModel } from '../base-model';

/**
 * A User of the System.
 * Each user instance within the platform has a unique email address.
 * A user can be a member of multiple workspaces with different roles for those workspaces.
 */
class User extends BaseModel {
  private _profileImageUrl: string | null;
  private _givenName: string | null;
  private _familyName: string | null;
  private _email: string;

  constructor(
    rootStore: any,
    id: string,
    obrn: string,
    firstName: string | null,
    lastName: string | null,
    email: string
  ) {
    super(rootStore, 'user', '1', id, id, obrn);
    this._givenName = firstName;
    this._familyName = lastName;
    this._email = email;
    this._profileImageUrl = null;
  }

  applyPatch(_patch: ServerDelta[]): void {
    throw new Error('Method not implemented.');
  }
  toJson(): Record<string, any> {
    return {
      id: this.id,
      firstName: this._givenName,
      lastName: this._familyName,
      email: this._email,
      profileImageUrl: this._profileImageUrl,
    };
  }
  protected makeObservableInternal(): void {
    makeObservable(this, {
      _profileImageUrl: observable,
      _givenName: observable,
      _familyName: observable,
      _email: observable,
    } as any);
  }

  get givenName(): string | null {
    return this._givenName;
  }

  get familyName(): string | null {
    return this._familyName;
  }

  get email(): string {
    return this._email;
  }

  /**
   * The public URL of the profile image for a user.
   * A null profile image url indicates that the user has not uploaded a profile image.
   */
  get profileImageUrl(): string | null {
    return this._profileImageUrl;
  }

  get fullName(): string {
    return `${this._givenName ?? ''} ${this._familyName ?? ''}`;
  }
}

export default User;
