import { FormDefinition, FormFieldType } from '@outbound/design-system';
import { ObRadioOption } from '@outbound/design-system/src/components/molecules/ob-radio-group/ob-radio-group';
import { countries, usCounties, usStates } from '@outbound/types';

export const SERVICE_AREA_TYPE_ZIP_CODE: ObRadioOption = {
  value: 'POSTAL_CODE',
  label: 'Zip Code',
  description: 'Define the Service Area as one or more zip codes.',
} as const;
export const SERVICE_AREA_TYPE_COUNTY: ObRadioOption = {
  value: 'COUNTY',
  label: 'County',
  description: 'Define the Service Area as one or more counties.',
};
export const SERVICE_AREA_TYPE_COUNTRY: ObRadioOption = {
  value: 'COUNTRY',
  label: 'Country',
  description: 'Define the service area as one or more countries.',
} as const;

export const SERVICE_AREA_TYPE_STATE: ObRadioOption = {
  value: 'STATE',
  label: 'State',
  description: 'Define the service area as one or more states.',
} as const;

export const SERVICE_AREA_TYPE_DRIVING_DISTANCE_FROM_ADDRESS: ObRadioOption = {
  value: 'DRIVE_DISTANCE',
  label: 'Drive Distance',
  description:
    'Define the Service Area as how far someone will drive from an address.',
} as const;

export const SERVICE_AREA_TYPE_DRIVING_TIME_FROM_ADDRESS: ObRadioOption = {
  value: 'DRIVE_TIME',
  label: 'Drive Time',
  description:
    'Define the Service Area as how many minutes from an address you will drive.',
} as const;

export const SERVICE_AREA_FORM_DEF: FormDefinition = {
  id: 'service-area-form',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'name',
          label: 'Name',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'description',
          label: 'Description',
          helperText: '',
          type: FormFieldType.TEXT_AREA,
          fieldTypeSettings: {},
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'service-area',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'serviceAreaType',
          label: 'How do you want to define this service area?',
          helperText: '',
          defaultValue: 'COUNTRY',
          fieldTypeSettings: {
            options: [
              SERVICE_AREA_TYPE_DRIVING_DISTANCE_FROM_ADDRESS,
              SERVICE_AREA_TYPE_DRIVING_TIME_FROM_ADDRESS,
              SERVICE_AREA_TYPE_COUNTRY,
              SERVICE_AREA_TYPE_STATE,
              SERVICE_AREA_TYPE_COUNTY,
              SERVICE_AREA_TYPE_ZIP_CODE,
            ],
          },
          type: FormFieldType.RADIO,

          validationSchema: {
            isRequired: true,
          },
        },

        {
          id: 'countries',
          label: 'Countries',
          helperText: 'What countries should we include in this service area?',
          conditionallyHideFieldFunction: (values: any) => {
            return values?.serviceAreaType !== 'COUNTRY';
          },
          fieldTypeSettings: {
            options: countries.map((country) => {
              return {
                key: country.value,
                value: country.value,
                displayValue: country.description,
              };
            }),
          },
          type: FormFieldType.COMBO_BOX,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'zipCodes',
          label: 'Zip Codes',
          helperText:
            'What zip codes do you want to include in this service area?',
          conditionallyHideFieldFunction: (values: any) => {
            return values?.serviceAreaType !== 'POSTAL_CODE';
          },
          fieldTypeSettings: {},
          type: FormFieldType.CHIPS,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'counties',
          label: 'Counties',
          helperText:
            'What counties do you want to include in this service area?',
          conditionallyHideFieldFunction: (values: any) => {
            return values?.serviceAreaType !== 'COUNTY';
          },
          fieldTypeSettings: {
            properCaseDisplayValues: false, // Don't capitalize the display values. They are already in the correct case.
            options: usCounties.map((county) => {
              return {
                key: county.id,
                value: county.id,
                displayValue: `${county.county}, ${county.state}`,
              };
            }),
          },
          type: FormFieldType.COMBO_BOX,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'states',
          label: 'States',
          helperText:
            'What states do you want to include in this service area?',
          conditionallyHideFieldFunction: (values: any) => {
            return values?.serviceAreaType !== 'STATE';
          },
          fieldTypeSettings: {
            options: usStates.map((states) => {
              return {
                key: states.value,
                value: states.value,
                displayValue: states.description,
              };
            }),
          },
          type: FormFieldType.COMBO_BOX,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'address',
          label: 'Starting Location Address',
          helperText: '',
          conditionallyHideFieldFunction: (values: any) => {
            return (
              values?.serviceAreaType !== 'DRIVE_TIME' &&
              values?.serviceAreaType !== 'DRIVE_DISTANCE'
            );
          },
          fieldTypeSettings: {},
          type: FormFieldType.ADDRESS,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'driveDistance',
          label: 'Miles to Drive from Address',
          helperText:
            'How many miles are you willing to drive from this address?',
          conditionallyHideFieldFunction: (values: any) => {
            return values?.serviceAreaType !== 'DRIVE_DISTANCE';
          },
          fieldTypeSettings: {},
          type: FormFieldType.NUMBER,
          validationSchema: {
            isRequired: true,
            min: 1,
          },
        },
        {
          id: 'driveTime',
          label: 'Minutes to Drive from Address',
          helperText:
            'How many minutes are you willing to drive from this address?',
          conditionallyHideFieldFunction: (values: any) => {
            return values?.serviceAreaType !== 'DRIVE_TIME';
          },
          fieldTypeSettings: {},
          type: FormFieldType.NUMBER,
          validationSchema: {
            isRequired: true,
            min: 1,
          },
        },
      ],
    },
  ],
  heading: '',
  subHeading: '',
};
