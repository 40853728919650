import { ObTypography } from '@outbound/design-system';
import obOfficial from './ob-official-seal.svg';
import { useEffect, useState } from 'react';
export const MarketingPlanLoading = () => {
  const loadingText = [
    'Building Your Outbound Marketing Plan',
    'Checking Industry Data',
    'Updating Target Demographics',
    'Analyzing Goal',
    'Checking Realtime Keyword Pricing Quotes',
  ];

  const [loadingTextIndex, setLoadingTextIndex] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      setLoadingTextIndex(
        loadingTextIndex + 1 < loadingText.length ? loadingTextIndex + 1 : 0
      );
    }, 2000);
  }, [loadingTextIndex, loadingText.length]);

  return (
    <div className='h-screen flex flex-col justify-center items-center gap-5'>
      <img
        src={obOfficial}
        height={300}
        width={300}
        alt='Outbound Official Seal'
      />
      <ObTypography
        variant='h3'
        className='text-center'
      >
        {loadingText[loadingTextIndex]}
      </ObTypography>
    </div>
  );
};
