import { ServerDelta, UserResource } from '@outbound/types';
import { BaseTransformer } from '../base-transformer';
import User from './user';

/**
 * Convert UserResources from the API to User models for the client.
 */
class UserTransformer extends BaseTransformer<UserResource, User> {
  constructor(rootStore: any) {
    super(rootStore);
  }

  fromApiResource(resource: UserResource): User {
    return new User(
      this._rootStore,
      resource.id,
      resource.obrn,
      resource.givenName ?? null,
      resource.familyName ?? null,
      resource.email
    );
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: User,
    _resource: UserResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }
}

export default UserTransformer;
