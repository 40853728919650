import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';

export const UrlFormatted = ({ url }: { url: string }) => {
  let urlParts: URL | null;
  if (url == null || url === '') {
    urlParts = new URL('https://www.example.com');
  } else {
    try {
      urlParts = new URL(url);
    } catch (error) {
      urlParts = null;
    }
  }

  if (urlParts == null) {
    return (
      <ObTypography
        variant='body1'
        color='invertedPrimary'
      >
        Invalid URL
      </ObTypography>
    );
  }

  return (
    <div>
      <div className='flex justify-start items-center line-clamp-1 flex-nowrap'>
        <span
          className={`font-light text-sm text-[#4d5156] dark:text-[#bdc1c6] line-clamp-2`}
        >
          <span
            className='font-roboto text-sm text-[#4d5156] dark:text-[#bdc1c6]'
            data-testid={'website-url'}
          >
            {`${urlParts.protocol}//${urlParts.host}`}
          </span>
          {urlParts.pathname.split('/').map((element, index) => {
            const isLastElement =
              index === urlParts.pathname.split('/').length - 1;
            return (
              <span
                key={index}
                className={`after:pr-[1px] text-sm  ${
                  isLastElement ? 'last:after:inline-block' : ''
                } `}
              >
                {index > 0 && (
                  <div className='inline-block px-[4px] text-[#4d5156]'>›</div>
                )}
                <div className='pb-[4px] inline-block'>{element}</div>
              </span>
            );
          })}
        </span>
      </div>
    </div>
  );
};
