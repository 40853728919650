import { ServerDelta, TemplateMediumType } from '@outbound/types';
import { action, makeObservable, observable } from 'mobx';
import { BaseModel } from '../../base-model';
import SettingDefinition from './setting-definition/setting-definition';
import { SettingSectionWithSettingDefinition } from './setting-section.type';
import SettingSection from './setting-section/setting-section';

class Template extends BaseModel {
  private _medium: TemplateMediumType;
  private _name: string;
  private _renderComponent: string;
  private _sections: Map<string, SettingSection> = new Map();
  private _settingDefinitions: Map<string, SettingDefinition> = new Map();

  constructor(
    rootStore: any,
    _medium: TemplateMediumType,
    id: string,
    workspaceId: string,
    name: string,
    renderComponent: string,
    sections: Array<SettingSection>,
    settingDefinitions: Array<SettingDefinition>
  ) {
    super(rootStore, 'creative-template', '1', id, workspaceId);

    this._medium = _medium;
    this._name = name;
    this._renderComponent = renderComponent;

    settingDefinitions.forEach((definition) => {
      this._settingDefinitions.set(definition.id, definition);
    });

    sections.forEach((section) => {
      this._sections.set(section.id, section);
    });
  }

  makeObservableInternal() {
    makeObservable(this, {
      _name: observable,
      applyPatch: action,
      toJson: action,
    } as any);
  }

  get medium(): TemplateMediumType {
    return this._medium;
  }

  get name(): string {
    return this._name;
  }

  get renderComponent(): string {
    return this._renderComponent;
  }

  /**
   * Returns sections of settings that should be displayed in the builder.
   * The Settings in this object are not the actual settings, but the definitions.
   * The actual setting values are stored in the Creative object.
   *
   * This method is used by the creative builder view to construct
   * the settings display using this method plus the Creative object.
   *
   * I Think there is something mis-aligned here with sections and sub-sections.
   * I don't think it impacts Google Search because it doesn't make use of sub-sections
   * but I think it will impact Display Ads
   */
  get builderSections(): Array<SettingSectionWithSettingDefinition> {
    const sectionsArray = Array.from(this._sections.values());
    const settingDefArray = Array.from(this._settingDefinitions.values());
    return sectionsArray.map((section) => {
      return {
        id: section.id,
        name: section.name,
        subSections: section.subSections.map((subSection) => {
          return {
            id: subSection.id,
            name: subSection.name,
            settingDefinitions: settingDefArray.filter((settingDef) =>
              settingDef.assignedSettingIds.includes(subSection.id)
            ),
          };
        }),
        settingDefinitions: settingDefArray.filter((settingDef) =>
          settingDef.assignedSettingIds.includes(section.id)
        ),
      };
    });
  }

  applyPatch(_patch: ServerDelta[]): void {
    throw new Error('Templates are read-only');
  }
  toJson(): Record<string, any> {
    return {
      id: this.id,
      medium: this._medium,
      name: this._name,
      renderComponent: this._renderComponent,
      settingFields: Array.from(this._settingDefinitions.values()).map((def) =>
        def.toJson()
      ),
      settingSections: Array.from(this._sections.values()).map((section) =>
        section.toJson()
      ),
    };
  }
}

export default Template;
