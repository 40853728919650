import { create } from 'zustand';
import { StoreState } from './store.type';

/**
 * Global zustand store setup.
 * This is a global store for all state. You should add properties to this store
 * and not create a whole new store for the next data.
 */
export const useStore = create<StoreState>((set) => ({
  businessSettings: JSON.parse(
    localStorage.getItem('businessSettings') ?? '{}'
  ),
  setBusinessSettings: (businessSettings) =>
    set(() => {
      localStorage.setItem(
        'businessSettings',
        JSON.stringify(businessSettings)
      );
      return { businessSettings };
    }),
  answers: JSON.parse(localStorage.getItem('answers') ?? '{}'),
  setAnswer: (questionId, answer) =>
    set((state) => {
      const newAnswers = { ...state.answers, [questionId]: answer };
      localStorage.setItem('answers', JSON.stringify(newAnswers));
      return { answers: newAnswers };
    }),
  marketingPlanRecommendationId:
    localStorage.getItem('marketingPlanRecommendationId') ?? '',
  setMarketingPlanRecommendationId: (marketingPlanRecommendationId) =>
    set(() => {
      localStorage.setItem(
        'marketingPlanRecommendationId',
        marketingPlanRecommendationId
      );
      return { marketingPlanRecommendationId };
    }),
}));
