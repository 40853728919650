import { useAuth0 } from '@auth0/auth0-react';
import { decodeToken } from '@otbnd/utils';
import {
  UserJwtToken,
  WORKSPACE_ID_CLAIM,
} from '@otbnd/utils/src/authentication/app-jwt-decoder';
import { useCallback, useEffect, useState } from 'react';
import {
  AUTHORIZATION_DEFAULT_SCOPES,
  AUTHORIZATION_TOKEN_AUDIENCE,
} from '../environment';
import { Auth0ExtendedContext } from './auth0-extended-context';

const whiteListedRedirectModules = [
  'dashboard',
  'playbook',
  'campaigns',
  'settings',
  'onboarding-wizard',
];

export const Auth0ExtendedProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isAuthenticatedWithWorkspace, setIsAuthenticatedWithWorkspace] =
    useState<boolean>(false);

  const [
    isSwitchToUserOnlyAuthenticationInProgress,
    setIsSwitchToUserOnlyAuthenticationInProgress,
  ] = useState<boolean>(false);

  const [authenticatedWorkspaceId, setAuthenticatedWorkspaceId] = useState<
    string | undefined
  >(undefined);

  const [
    isAuthenticatedWithWorkspaceCheckInProgress,
    setIsAuthenticatedWithWorkspaceCheckInProgress,
  ] = useState<boolean>(true);

  const [permissions, setPermissions] = useState<Array<string>>([]);

  const {
    isLoading,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    getIdTokenClaims,
  } = useAuth0();

  const useAuth0Base = useAuth0();

  /**
   * Switches the current organization to the provided organization ID
   * This will always redirect to the dashboard page after switching.
   *
   * Consolidated the logic to switch organizations to this single location as it had existed in multiple places
   * This will make it easier to update to Auth0 SDK V2: https://outbound-technologies.atlassian.net/browse/OD-1007
   */
  const switchOrganization = useCallback(
    async (auth0OrgId: string, workspaceId: string) => {
      let redirectPath = workspaceId; //After a workspace switch, we always want to redirect to the dashboard
      const [, , module] = window.location.pathname.split('/');
      if (module && whiteListedRedirectModules.includes(module)) {
        redirectPath = `${redirectPath}/${module}`;
      }
      const redirectURI = `${window.location.origin}/${redirectPath}`;
      console.log(
        `Switching Organization to "${auth0OrgId}" and redirecting to "${redirectURI}"`
      );
      /**
       * Moved away from using silent authentication as it is not supported by auth0
       * https://github.com/auth0/auth0-spa-js/issues/1055
       */
      console.log('Logging in with returnTo: ', redirectPath);
      /**
       * Future enhancement check before we redirect if we are already authenticated with the organization
       * so that we can prevent unnecessary redirects.
       */
      localStorage.setItem('ob-org-id', auth0OrgId);
      await loginWithRedirect({
        authorizationParams: {
          scope: AUTHORIZATION_DEFAULT_SCOPES,
          audience: AUTHORIZATION_TOKEN_AUDIENCE,
          organization: auth0OrgId,
        },
        appState: {
          organizationSwitch: true,
          returnTo: redirectPath,
        },
      });
    },
    [loginWithRedirect]
  );

  /**
   * The intent of this function is to take a user who has a session with a specific organization and to
   * reauthenticate them without any organization.
   */
  const switchToUserOnlyAuthentication = useCallback(
    async (redirectToPath?: string) => {
      if (isLoading) {
        console.log('Waiting for Auth0 to Initialize');
        return;
      }

      if (!isAuthenticated) {
        return;
      }

      if (
        isAuthenticated &&
        !isAuthenticatedWithWorkspaceCheckInProgress &&
        !isAuthenticatedWithWorkspace
      ) {
        console.log('Already in User Only Authentication Mode');
        return;
      }
      setIsSwitchToUserOnlyAuthenticationInProgress(true);
      const redirectURI = `${window.location.origin}/${
        redirectToPath ?? window.location.pathname
      }`;
      console.log(
        `Logging out of Organization and redirecting to "${redirectURI}"`
      );
      localStorage.removeItem('ob-org-id');
      /**
       * Because Open URL is set to false this is a local only logout that clears out the token cache.
       * The next step of login with redirect should return immediately as the user is already authenticated with auth0
       * We are changing the authentication session to not include the organization so the user will be authenticated as a user only
       * after the login with redirect completes.
       *
       * Between this step and the logged out state the Auth0 SKD will show isAuthenticated = false which may have unintended side-effects since we conceptually consider the user logged in still.
       */
      await logout({ openUrl: false });

      /**
       * This will cause the app to redirect momentarily and then return to the current page. No logic should be placed after this call
       * since it will not be run.
       */
      await loginWithRedirect({
        authorizationParams: {
          scope: AUTHORIZATION_DEFAULT_SCOPES,
          audience: AUTHORIZATION_TOKEN_AUDIENCE,
        },
        appState: {
          organizationSwitch: false,
          returnTo: redirectToPath ?? window.location.pathname,
        },
      });
    },
    [
      isAuthenticated,
      isAuthenticatedWithWorkspace,
      isAuthenticatedWithWorkspaceCheckInProgress,
      isLoading,
      loginWithRedirect,
      logout,
    ]
  );

  /**
   * Side Effect that runs
   */
  useEffect(() => {
    const setWorkspaceAuthenticationState = async () => {
      const idToken = await getIdTokenClaims();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          organization: idToken?.org_id,
          scope: AUTHORIZATION_DEFAULT_SCOPES,
          audience: AUTHORIZATION_TOKEN_AUDIENCE,
        },
        cacheMode: 'off', //We can't rely on the cached token as the token changes when the user switches organizations
      });
      const claims = await decodeToken<UserJwtToken>(accessToken);
      const workspaceId = claims?.[WORKSPACE_ID_CLAIM] as string | undefined;
      setIsAuthenticatedWithWorkspace(!!workspaceId);
      setAuthenticatedWorkspaceId(workspaceId);
      setIsAuthenticatedWithWorkspaceCheckInProgress(false);
      setPermissions(claims?.permissions ?? []);
    };

    if (isAuthenticated) {
      setWorkspaceAuthenticationState();
    } else {
      /**
       * When we are not loading and not authenticated we can set the state to false
       */
      if (!isLoading) {
        setIsAuthenticatedWithWorkspaceCheckInProgress(false);
      }
      setIsAuthenticatedWithWorkspace(false);
      setAuthenticatedWorkspaceId(undefined);
      setPermissions([]);
    }
  }, [getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading]);

  return (
    <Auth0ExtendedContext.Provider
      value={{
        ...useAuth0Base,
        switchOrganization,
        switchToUserOnlyAuthentication,
        isSwitchToUserOnlyAuthenticationInProgress,
        isAuthenticatedWithWorkspaceCheckInProgress,
        isAuthenticatedWithWorkspace,
        authenticatedWorkspaceId,
        permissions,
      }}
    >
      {children}
    </Auth0ExtendedContext.Provider>
  );
};
