/**
 * @deprecated Use AssetStatus instead
 */
export enum AssetStatusType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING_FILE_UPLOAD = 'PENDING_FILE_UPLOAD',
}

export const AssetStatusValues = [
  'ACTIVE',
  'INACTIVE',
  'PENDING_FILE_UPLOAD',
] as const;

/**
 * Asset Status.
 */
export type AssetStatus = (typeof AssetStatusValues)[number];
