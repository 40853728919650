import { parseDeltaPath } from '@otbnd/utils';
import { ServerDelta } from '@outbound/types';
import { BaseModel } from '../../base-model';
import { BaseTransformer } from '../../base-transformer';

export const delegateDeltaToNestedLocalModel = (
  modelMap: Map<string, BaseModel>,
  delta: ServerDelta
) => {
  let badRequest = false;
  if (delta.op !== 'replace') {
    console.error(
      'Only Replace Operations are Supported for delegating Nested Local Models applyPatch method'
    );
    badRequest = true;
  }
  if (modelMap == null) {
    console.error(
      'Model is null. Cannot delegate patch operation to a null model'
    );
    badRequest = true;
  }
  if (badRequest) {
    return;
  } else {
    /**
     * First thing we will do is split the path by the '/' character to get the segments of the path.
     * (OBRN is URL encoded which is why this works)
     */
    const [, , obrnEncoded, ...localSegments] = delta.path.split('/');

    //Get the unencoded OBRN
    const obrn = decodeURIComponent(obrnEncoded);

    /**
     * Create a new delta that can be applied to the nested object
     * In order to do this we need to remove the path segments that are relevant to the parent.
     * Since we selective only decoded the OBRN on the parent we can assume all keys of nested objects on the child are
     * property encoded.
     */
    const delegatedDelta = {
      ...delta,
      path: `/${localSegments.join('/')}`,
    };

    //Delegate the patch down to the nested object
    modelMap.get(obrn)?.applyPatch([delegatedDelta]);
  }
};

/**
 * Local Models are models that only exist within the context of their parent model.
 * They do not have a store of their own. In order to access them you must go through the parent model.
 * Some examples include Campaign Highlights, Campaign Locations, and Campaign Customer Profiles and Creative Settings.
 * @deprecated This is was an early implementation that is no longer relevant. Once we migrate all models off of it we can remove it.
 */
export const patchNestedLocalModelMap = <T extends BaseModel, U>(
  map: Map<string, T>,
  operation: ServerDelta,
  transformer: BaseTransformer<U, T>
) => {
  const { id, childSegments, hasChildSegment, hasIdSegment } = parseDeltaPath(
    operation.path
  );

  const existingNestedModel = map.get(id!);
  switch (operation.op) {
    /**
     * In this case the server is instructing us that a new item has been added to this model.
     * We will therefore add it to the map.
     */
    case 'add': {
      if (existingNestedModel) {
        /**
         * Why would this happen?
         * Out of Sync Deltas.
         * If we are being asked to add a model that already exists this must be a stale delta for this attribute.
         */
        console.warn(
          'Existing Model Found during Add Operation. Ignoring Delta'
        );
      } else {
        if (hasIdSegment) {
          const newModel = transformer.fromApiResource(operation.value);
          map.set(id!, newModel);
          newModel.makeObservable();
        } else {
          console.error(
            'Unable to apply add operation on nested map. No ID Segment Found in path'
          );
        }
      }
      break;
    }
    case 'remove': {
      if (existingNestedModel) {
        map.delete(id!);
      } else {
        console.warn('Model Not Found during Remove Operation. Ignoring Delta');
      }
      break;
    }
    case 'replace': {
      if (existingNestedModel) {
        if (hasChildSegment) {
          existingNestedModel.applyPatch([
            {
              ...operation,
              //Forward to the setting objects applyPatch method
              path: childSegments!,
            },
          ]);
        } else {
          console.warn(
            'No Child Segment Found for Replace Operation. Ignoring Delta'
          );
        }
      }
    }
  }
};
