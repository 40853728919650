export const CreativeIcon = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='creative'>
        <g id='Icon'>
          <path
            d='M19.7071 3.2929C19.3357 2.92147 18.7402 2.90072 18.3438 3.2454L9.17537 11.218C11.4188 11.8147 13.1853 13.5812 13.782 15.8246L21.7546 6.65618C22.0993 6.25981 22.0785 5.66432 21.7071 5.2929L19.7071 3.2929Z'
            fill='currentColor'
          />
          <path
            d='M7.5 13C5.01472 13 3 15.0147 3 17.5V21C3 21.5523 3.44771 22 4 22H7.5C9.98528 22 12 19.9853 12 17.5C12 15.0147 9.98528 13 7.5 13Z'
            fill='currentColor'
          />
          <path
            d='M4.18426 7.40787C4.28103 7.35949 4.35949 7.28102 4.40787 7.18426L5.05279 5.89443C5.23705 5.5259 5.76295 5.5259 5.94721 5.89443L6.59213 7.18426C6.64051 7.28102 6.71897 7.35949 6.81574 7.40787L8.10557 8.05279C8.4741 8.23705 8.4741 8.76295 8.10557 8.94721L6.81574 9.59213C6.71897 9.64051 6.64051 9.71897 6.59213 9.81574L5.94721 11.1056C5.76295 11.4741 5.23705 11.4741 5.05279 11.1056L4.40787 9.81574C4.35949 9.71897 4.28103 9.64051 4.18426 9.59213L2.89443 8.94721C2.5259 8.76295 2.5259 8.23705 2.89443 8.05278L4.18426 7.40787Z'
            fill='currentColor'
          />
          <path
            d='M8.56019 3.71991C8.6293 3.68535 8.68535 3.6293 8.7199 3.56019L9.18056 2.63888C9.31218 2.37564 9.68782 2.37564 9.81944 2.63888L10.2801 3.56019C10.3147 3.6293 10.3707 3.68535 10.4398 3.71991L11.3611 4.18056C11.6244 4.31218 11.6244 4.68782 11.3611 4.81944L10.4398 5.28009C10.3707 5.31465 10.3147 5.37069 10.2801 5.43981L9.81944 6.36112C9.68782 6.62435 9.31218 6.62435 9.18056 6.36112L8.71991 5.43981C8.68535 5.37069 8.6293 5.31465 8.56019 5.28009L7.63888 4.81944C7.37564 4.68782 7.37564 4.31218 7.63888 4.18056L8.56019 3.71991Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
