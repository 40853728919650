import { PlaybookBusinessActivitiesResource } from '@outbound/types';
import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useAuth0Extended } from '../auth/use-auth0-extended';
import { useFetchPlaybookSettings } from '../query/playbook/use-playbook-endpoints';
import { useStore } from '../state/store';

const isServiceAreasEnabled = (
  businessActivities?: PlaybookBusinessActivitiesResource
): boolean => {
  if (businessActivities == null) {
    return false;
  }
  return (
    businessActivities?.isRetailOnline ||
    businessActivities?.isServiceOnline ||
    businessActivities?.isServiceInCustomerLocation ||
    false
  );
};

const isPhysicalLocationsEnabled = (
  businessActivities?: PlaybookBusinessActivitiesResource
): boolean => {
  if (businessActivities == null) {
    return false;
  }
  return (
    businessActivities?.isRetailInStore ||
    businessActivities?.isServiceInBusinessLocation ||
    false
  );
};

const isProductsEnabled = (
  businessActivities?: PlaybookBusinessActivitiesResource
): boolean => {
  if (businessActivities == null) {
    return false;
  }
  return (
    businessActivities?.isRetailInStore ||
    businessActivities?.isRetailOnline ||
    false
  );
};

const isServicesEnabled = (
  businessActivities?: PlaybookBusinessActivitiesResource
): boolean => {
  if (businessActivities == null) {
    return false;
  }
  return (
    businessActivities?.isServiceInBusinessLocation ||
    businessActivities?.isServiceOnline ||
    businessActivities?.isServiceInCustomerLocation ||
    false
  );
};
export const SettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  /**
   * We should only attempt to load the configuration if the user is authenticated
   * with a workspace
   */
  const { isAuthenticated, getAccessTokenSilently, authenticatedWorkspaceId } =
    useAuth0Extended();

  const { setBusinessSettings } = useStore();

  const { data: playbookSettings, isLoading: isPlaybookSettingsLoading } =
    useFetchPlaybookSettings();

  const clearSettings = useCallback(() => {
    setBusinessSettings({
      isPhysicalLocationsEnabled: false,
      isServiceAreasEnabled: false,
      isProductsEnabled: false,
      isServicesEnabled: false,
    });
  }, [setBusinessSettings]);

  /**
   * Will update the business settings data every time the playbook settings are loaded
   * or the user authentication state changes.
   *
   * These settings are set at the workspace level by playbook admins.
   * We would expect that their values would not change frequently and can typically
   * be loaded once and cached.
   */
  useEffect(() => {
    const loadConfig = async () => {
      if (isAuthenticated && !isPlaybookSettingsLoading && playbookSettings) {
        const businessActivities =
          playbookSettings?.businessDetails?.businessActivities;
        setBusinessSettings({
          isPhysicalLocationsEnabled:
            isPhysicalLocationsEnabled(businessActivities),
          isServiceAreasEnabled: isServiceAreasEnabled(businessActivities),
          isProductsEnabled: isProductsEnabled(businessActivities),
          isServicesEnabled: isServicesEnabled(businessActivities),
        });
      }
    };

    if (isAuthenticated) {
      loadConfig();
    } else {
      clearSettings();
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    playbookSettings,
    isPlaybookSettingsLoading,
    setBusinessSettings,
    authenticatedWorkspaceId,
    clearSettings,
  ]);

  return <>{children}</>;
};

export const useSettings = () => {
  const { businessSettings } = useStore();
  const {
    isPhysicalLocationsEnabled,
    isServiceAreasEnabled,
    isProductsEnabled,
    isServicesEnabled,
  } = businessSettings;
  return {
    isPhysicalLocationsEnabled,
    isServiceAreasEnabled,
    isProductsEnabled,
    isServicesEnabled,
  };
};
