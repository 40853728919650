import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ReactNode, useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  AUTHORIZATION_CLIENT_ID,
  AUTHORIZATION_DEFAULT_SCOPES,
  AUTHORIZATION_DOMAIN,
  AUTHORIZATION_TOKEN_AUDIENCE,
} from './environment';

export const Auth0ProviderWithRedirectCallback = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      console.log('Redirect From Auth0 Login Detected', appState);
      if (appState && appState.returnTo) {
        navigate(appState && appState.returnTo);
      }
    },
    [navigate]
  );

  useEffect(() => {
    console.log('Auth0ProviderWithRedirectCallback Mounted');
    return () => console.log('Auth0ProviderWithRedirectCallback Unmounted');
  });

  return (
    <Auth0Provider
      domain={AUTHORIZATION_DOMAIN}
      clientId={AUTHORIZATION_CLIENT_ID}
      authorizationParams={{
        scope: AUTHORIZATION_DEFAULT_SCOPES,
        audience: AUTHORIZATION_TOKEN_AUDIENCE,
        redirect_uri: window.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
