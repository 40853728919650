import { cx } from 'class-variance-authority';
import { useEffect, useRef, useState } from 'react';
import { StateManagedByParentProps } from '../../base-component-props.type';
import { ObTag, ObTagStateProps, ObTagVariantProps } from '../ob-tag/ob-tag';

/**
 * Describes the behavior of the container when the tags exceed the width of the container.
 * - `wrap`: Tags will wrap to the next line when they exceed the width of the container.
 * - `truncate`: Tags will be truncated when they exceed the width of the container.
 *               A final tag will be displayed with a "+X" label where X is the number of tags that were truncated.
 */
export type ObTagGroupOverflowBehavior = 'wrap' | 'truncate';

/**
 * Props for the ObTagGroup component.
 */
export interface ObTagGroupProps
  extends StateManagedByParentProps<Array<ObTagStateProps>>,
    ObTagVariantProps {
  /**
   * Describes the behavior of the container when the tags exceed the width of the container.
   */
  overflowBehavior?: ObTagGroupOverflowBehavior;
}

/**
 * A container for ObTag components that will automatically wrap the tags or truncate them if they exceed the width of the container.
 * If the tags exceed the width of the container, a final tag will be displayed with a "+X" label where X is the number of tags that were truncated.
 *
 *
 * Short Falls / Future Enhancements:
 * - Does not try to find shorter tags to fit in the container
 * - Does not account for the width of the final +N tag when calculating the width of the container
 *   (It is possible that the last tag could fit in the container if we don't include the +N tag but we are not accounting for that)
 *
 */
export const ObTagGroup: React.FC<ObTagGroupProps> = ({
  value,
  size = 'small',
  selectable = false,
  removable = false,
  overflowBehavior = 'truncate',
}: ObTagGroupProps) => {
  /**
   * For time reasons I am not implementing the wrap logic for removable or medium size tags.
   * This would require updating the useEffect and calculateVisibleTags function to account for the width of the remove button
   * and the size of the medium tags.
   *
   * We could play around with  ReactDOM.render() and ReactDOM.unmountComponentAtNode() to calculate the width of the remove button
   * instead of relying on just text. This would be a more accurate representation of the width and we can dynamically adjust the width
   * of the tags based on the width of the remove button and size without hardcoding values.
   */
  if (removable || size === 'medium') {
    throw new Error(
      'Wrap Logic not implemented for removable or medium size tags. If you need this feature, please implement it.'
    );
  }
  /**
   * Reference used by the truncate input behavior to calculate the width of the input
   * from the container width
   */
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Internal State that tracks which of the selected options are visible as chips
   * Used with the truncate input behavior to show only a few selected options as chips
   * and the remaining count as a label
   */
  const [visibleTags, setVisibleTags] = useState<Array<ObTagStateProps>>([]);
  /**
   * Internal State that tracks the number of selected options that are not visible as chips.
   * This is used to show a label indicating the number of options that are not visible.
   * Example: "+${remainingCount} more..."
   */
  const [remainingCount, setRemainingCount] = useState(0);

  /**
   * Side Effect that calculates which chips should be visible based on the input width and the width of the chips
   * Run each time the value (Which options are selected) changes
   *
   * Copied from the ob-combobox-input component. It is possible that we can replace the logic there to use the tag-group component
   * instead. For time reasons I am not pursuing this refactor
   * */
  useEffect(() => {
    const calculateVisibleTags = () => {
      if (!containerRef.current) return;

      const andMoreWidth = 80;
      const tagContainerWidth = containerRef.current.offsetWidth - andMoreWidth;

      let totalWidth = 0;
      const tagWidths: number[] = [];
      const visible: ObTagStateProps[] = [];

      if (overflowBehavior === 'truncate') {
        value.forEach((i) => {
          const chip = document.createElement('div');
          chip.className = 'chip';
          chip.style.visibility = 'hidden';
          chip.style.position = 'absolute';
          chip.innerHTML = i.content;

          document.body.appendChild(chip);
          const width = chip.offsetWidth + 16; // Adjust 24 for padding and margins
          document.body.removeChild(chip);

          tagWidths.push(width);
        });

        for (let i = 0; i < tagWidths.length; i++) {
          totalWidth += tagWidths[i];
          if (totalWidth <= tagContainerWidth || i === 0) {
            visible.push(value[i]);
            setRemainingCount(0);
          } else {
            setRemainingCount(value.length - visible.length);
            break;
          }
        }
      } else {
        /**
         * When the overflow behavior is set to wrap, all tags should be visible
         */
        visible.push(...value);
      }
      setVisibleTags(visible);
    };

    calculateVisibleTags();

    /**
     * Include a resize event listener to recalculate the visible tags when the window is resized
     * TODO CONSIDER HOW THIS WOULD BEHAVE DUE TO OTHER CONTENT BEING RESIZED (NOT JUST THE WINDOW)
     */
    window.addEventListener('resize', calculateVisibleTags);

    return () => window.removeEventListener('resize', calculateVisibleTags);
  }, [value, overflowBehavior]);

  return (
    <div className='relative'>
      <div
        data-overflow-behavior={overflowBehavior}
        ref={containerRef}
        className='w-[calc(100%)] absolute invisible '
      />
      <div
        className={cx(
          'flex flex-row gap-1 overflow-hidden',
          overflowBehavior === 'truncate' ? 'flex-nowrap' : 'flex-wrap'
        )}
      >
        {visibleTags.map((tag) => (
          <ObTag
            key={tag.content}
            {...tag}
            size={size}
            selectable={selectable}
            removable={removable}
          />
        ))}
        {/* Include the final +n tag at the end */}
        {remainingCount > 0 && (
          <ObTag
            selectable={false}
            removable={false}
            content={`+${remainingCount} more`}
          />
        )}
      </div>
    </div>
  );
};
