import {
  CreatePersonaResourceRequest,
  PersonaResource,
  TargetingGroup,
} from '@outbound/types';

export interface PersonaFormValues {
  role: string;
  description: string;
  demographics: TargetingGroup;
  interests: Array<string>;
}

export const mapFormValuesToApiResource = (
  values: PersonaFormValues
): CreatePersonaResourceRequest => {
  console.log(values);
  return {
    role: values.role,
    description: values.description,
    demographics: values.demographics,
    interests: values?.interests ?? [],
  };
};

/**
 * Converts a PersonaResource to a shape that can be used to populate the create / edit customer profile form
 * @param resource
 * @returns
 */
export const mapApiResourceToFormValues = (
  resource: PersonaResource
): PersonaFormValues => {
  return {
    role: resource.role ?? '',
    description: resource.description ?? '',
    demographics:
      resource?.demographics ??
      ({
        nodeId: 'root',
        operator: 'AND',
        children: [],
      } as TargetingGroup),
    interests: resource?.interests ?? [],
  };
};
