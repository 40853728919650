import {
  IntegrationConfigurationLifecycleStatus,
  IntegrationConfigurationOperationalStatus,
} from '@outbound/types';
import { LevelType } from '../../tokens/colors/colors';

export const lookupStatusDetailsFromOperationalAndLifecycleStatus = (
  operationalStatus: IntegrationConfigurationOperationalStatus,
  lifecycleStatus?: IntegrationConfigurationLifecycleStatus
): {
  indicatorColor: 'positive' | 'negative' | 'warning' | 'secondary';
  level: LevelType;
  label: string;
} => {
  if (lifecycleStatus == null) {
    return {
      level: 'NEUTRAL',
      indicatorColor: 'secondary',
      label: 'Unknown',
    };
  }
  switch (lifecycleStatus) {
    case 'INITIALIZING':
      return {
        label: 'Initializing',
        level: 'WARNING',
        indicatorColor: 'warning',
      };
    case 'FAILED':
    case 'DELETING':
      return {
        label: 'Error',
        level: 'ERROR',
        indicatorColor: 'negative',
      };

    case 'ACTIVE': {
      switch (operationalStatus) {
        case 'NOT_CONFIGURED':
          return {
            label: 'Not Configured',
            level: 'NEUTRAL',
            indicatorColor: 'secondary',
          };
        case 'NOMINAL':
          return {
            label: 'Active',
            level: 'SUCCESS',
            indicatorColor: 'positive',
          };
        case 'UNAVAILABLE':
          return {
            label: 'Unavailable',
            level: 'NEUTRAL',
            indicatorColor: 'secondary',
          };
        case 'ERROR':
          return {
            label: 'Error',
            level: 'WARNING',
            indicatorColor: 'warning',
          };
        case 'NEEDS_ATTENTION':
          return {
            label: 'Needs Attention',
            level: 'WARNING',
            indicatorColor: 'warning',
          };
      }
    }
  }
};
