export const UserCircle = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g
        id='solid/users/user-circle'
        clipPath='url(#clip0_1273_473)'
      >
        <g id='Solid'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.66663 6.33332C4.66663 4.49237 6.15901 2.99999 7.99996 2.99999C9.84091 2.99999 11.3333 4.49237 11.3333 6.33332C11.3333 8.17427 9.84091 9.66666 7.99996 9.66666C6.15901 9.66666 4.66663 8.17427 4.66663 6.33332Z'
            fill='currentColor'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.99996 0.666656C3.94987 0.666656 0.666626 3.9499 0.666626 7.99999C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 7.99999C15.3333 3.9499 12.05 0.666656 7.99996 0.666656ZM1.99996 7.99999C1.99996 4.68628 4.68625 1.99999 7.99996 1.99999C11.3137 1.99999 14 4.68628 14 7.99999C14 9.48073 13.4636 10.8362 12.5745 11.8827C11.9637 11.1409 11.0376 10.6667 9.99999 10.6667H5.99999C4.96241 10.6667 4.03622 11.1409 3.42546 11.8827C2.53636 10.8362 1.99996 9.48074 1.99996 7.99999Z'
            fill='currentColor'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1273_473'>
          <rect
            width='16'
            height='16'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
