import { ObLoadingSpinner } from '@outbound/design-system';
import { useFetchPlaybookSettings } from '../../../../../query/playbook/use-playbook-endpoints';
import { PlaybookPageContent } from '../_common/playbook-page-content';
import { PlaybookPageHeading } from '../_common/playbook-page-heading';
import { LOCATIONS_PAGE_DEF } from './locations.def';

import { observer } from 'mobx-react-lite';
import { BrickAndMortarSection } from './sections/brick-and-mortar-section';
import { ServiceAreaSection } from './sections/service-area-section';

export const Locations = observer(() => {
  /**
   * Temporary implementation we most likely want to store this data at a higher level as it will be used in multiple places
   * within the application. This is a quick implementation to get the page up and running and will serve as the basis
   * for the final implementation.
   */
  const { data: playbookSettings, isLoading } = useFetchPlaybookSettings();

  const businessActivities =
    playbookSettings?.businessDetails?.businessActivities;

  const isBrickAndMortarEnabled =
    businessActivities?.isRetailInStore ||
    businessActivities?.isServiceInBusinessLocation;

  const isServiceAreaEnabled =
    businessActivities?.isRetailOnline ||
    businessActivities?.isServiceOnline ||
    businessActivities?.isServiceInCustomerLocation;

  return (
    <article className='flex flex-col gap-8 flex-grow '>
      <PlaybookPageHeading title={LOCATIONS_PAGE_DEF.title} />
      {isLoading && (
        <div className='w-full h-full flex justify-center items-center mt-[50%]'>
          <ObLoadingSpinner includeBrand={true} />
        </div>
      )}
      {!isLoading && (
        <>
          <PlaybookPageContent>
            <div className='flex flex-col gap-32'>
              {isBrickAndMortarEnabled && <BrickAndMortarSection />}
              {isServiceAreaEnabled && <ServiceAreaSection />}
            </div>
          </PlaybookPageContent>
        </>
      )}
    </article>
  );
});
