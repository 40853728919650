import { ServerDelta } from '@outbound/types';
import { BaseModel } from '../../../base-model';

class SettingSubSection extends BaseModel {
  private _name: string;
  private _associatedSettingIds: Array<string> = [];

  constructor(
    rootStore: any,
    id: string,
    name: string,
    associatedSettingIds: Array<string>
  ) {
    super(
      rootStore,
      'creative-template/setting-section/sub-section',
      '1',
      id,
      'system'
    );
    this._name = name;
    this._associatedSettingIds = associatedSettingIds;
  }

  applyPatch(_patch: ServerDelta[]): void {
    //Read Only Model. No need to apply patch
  }

  toJson(): Record<string, any> {
    return {
      id: this.id,
      name: this._name,
      associatedSettingIds: this._associatedSettingIds,
    };
  }

  protected makeObservableInternal(): void {
    //Read Only Model. No need to make observable
  }

  get name(): string {
    return this._name;
  }

  get settingDefinitions(): Array<string> {
    return this._associatedSettingIds;
  }
}

export default SettingSubSection;
