import { cva } from 'class-variance-authority';
import React, { useEffect, useState } from 'react';

export interface ObProgressBarProps {
  percentage: number;
  size?: 'small' | 'medium';
  color?: 'primary' | 'success' | 'informative' | 'purple';
}

export const ObProgressBar: React.FC<ObProgressBarProps> = ({
  percentage,
  size,
  color,
}) => {
  const [validPercentage, setValidPercentage] = useState<number>(0);
  useEffect(() => {
    if (percentage != null) {
      const nextPercentage =
        isNaN(percentage) || percentage === undefined || percentage > 100
          ? 0
          : Math.max(0, Math.min(100, percentage));
      setValidPercentage(nextPercentage);
    }
  }, [percentage]);

  const barStyles = cva('w-full dark:bg-bgSurface2Dark h-1 self-center', {
    variants: {
      size: {
        small: 'h-1 rounded',
        medium: 'h-[6px] rounded-full',
      },
    },
    defaultVariants: {
      size: 'small',
    },
  });

  const filledBarStyles = cva(' transition-all duration-500', {
    variants: {
      size: {
        small: 'h-1 rounded',
        medium: 'h-[6px] rounded-md ',
      },
      color: {
        primary: 'dark:bg-dark/gradient/action/primary',
        success: 'dark:bg-dark/gradient/action/success',
        informative: 'dark:bg-dark/gradient/action/informative',
        purple: 'dark:bg-dark/gradient/action/purple',
      },
    },
    defaultVariants: {
      size: 'small',
      color: 'primary',
    },
  });

  return (
    <div
      role='progressbar'
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={validPercentage ?? 0}
      className={barStyles({ size })}
    >
      {/* We can add a nice animation with 'animate-pulse' if we want*/}
      <div
        className={filledBarStyles({ size, color })}
        style={{ width: `${validPercentage ?? 0}%` }}
      ></div>
    </div>
  );
};
