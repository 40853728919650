import {
  GoogleAdsIntegrationConfigurationResponseResource,
  HealthCheckItemExecutionResource,
  IntegrationConfigurationResource,
  ServerDelta,
} from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import { simulatePatch } from '../../sync-framework/patch-simulator/patch-simulator';
import IntegrationConfigGoogleAds from './extended/integration-configuration-google-ads';
import HealthCheckItemEvaluation from './health-check-item-execution/health-check-item-execution';
import IntegrationConfiguration from './integration-configuration';
import IntegrationConfigurationValue from './integration-configuration-value.type';

class IntegrationConfigurationTransformer extends BaseTransformer<
  IntegrationConfigurationResource,
  IntegrationConfiguration
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  /**
   * Transform the Integration Resource Shape into the Model that the client expects
   * @param resource
   * @returns
   */
  fromApiResource(
    resource: IntegrationConfigurationResource
  ): IntegrationConfiguration {
    const {
      id,
      obrn,
      integration,
      lifecycleStatus,
      operationalStatus,
      configuredAtTimestamp,
      configuredByUserId,
      healthCheckLastCompletedAtTimestamp,
      healthCheckItemExecutions,
    } = resource;

    //Instantiate Local Objects
    const healthCheckItemsEvaluationMap = new Map<
      string,
      HealthCheckItemEvaluation
    >();
    if (healthCheckItemExecutions) {
      healthCheckItemExecutions.forEach(
        (healthCheckItemExecution: HealthCheckItemExecutionResource) => {
          const healthCheckItem = new HealthCheckItemEvaluation(
            this._rootStore,
            healthCheckItemExecution.id,
            healthCheckItemExecution.obrn,
            {
              healthCheckItemId: healthCheckItemExecution.healthCheckItemId,
              name: healthCheckItemExecution.healthCheckItem.name,
              outcomeCode: healthCheckItemExecution.outcome?.outcomeCode,
              message: healthCheckItemExecution.outcome?.message,
              status: healthCheckItemExecution.outcome?.status,
              skipped: healthCheckItemExecution.skipped,
              lastRanAtTimestamp: healthCheckItemExecution.lastRunAtTimestamp,
            }
          );
          healthCheckItemsEvaluationMap.set(
            healthCheckItemExecution.obrn,
            healthCheckItem
          );
        }
      );
    }

    /**
     * First create a base model value that is common to all integrations
     * so we can use it to create the specific integration configuration model.
     */
    const baseModelValue: IntegrationConfigurationValue = {
      integrationId: integration,
      type: integration,
      configuredByUserId,
      configuredAtTimestamp,
      lifeCycleStatus: lifecycleStatus,
      operationalStatus,
      healthCheckLastCompletedAtTimestamp,
      healthCheckItemLatestExecutionsMap: healthCheckItemsEvaluationMap,
    };

    /**
     * Each Integration Configuration Type has a specific model that extends the base model
     * This is to accommodate the specific attributes that are unique to each integration
     */
    switch (integration) {
      case 'GOOGLE_AD_MANAGER': {
        /**
         * We want to include the properties specific to the Google Ads integration
         * so we are casing based on the type of integration
         */
        const googleAdsIntegrationConfigResource =
          resource as GoogleAdsIntegrationConfigurationResponseResource;

        /**
         * Finally create the google ads integration model which extends the base integration configuration model
         */
        return new IntegrationConfigGoogleAds(this._rootStore, id, obrn, {
          ...baseModelValue,
          customerId: googleAdsIntegrationConfigResource.customerId,
          connectExistingAccount:
            googleAdsIntegrationConfigResource.connectExistingAccount,
          timeZone: googleAdsIntegrationConfigResource.timeZone,
          isGoogleAdsTestAccount:
            googleAdsIntegrationConfigResource.isGoogleAdsTestAccount,
          googleAdsAccountStatus:
            googleAdsIntegrationConfigResource.googleAdsAccountStatus,
          descriptiveName: googleAdsIntegrationConfigResource.descriptiveName,
          currencyCode: googleAdsIntegrationConfigResource.currencyCode,
        });
      }
      /**
       * We have not implemented these yet. Once we do we should add a case for them and create the specific integration configuration model.
       */
      case 'GOOGLE_AD_MANAGER_GRANT':
      case 'META_AD_MANAGER':
      default:
        return new IntegrationConfiguration(
          this._rootStore,
          id,
          obrn,
          baseModelValue
        );
    }
  }

  /**
   * TEMPORARY CLIENT SIDE PATCH SIMULATION. IDEALLY THIS WOULD BE DONE ON THE SERVER AND COME VIA A WEBSOCKET
   * Takes an incoming resource from the server and the current model in the client store
   * and creates a patch that can be applied to the current model to make it match the incoming resource.
   * This is a transitional implementation until we have a sync ws connection that will send these patches from the server.
   * @param currentModel
   * @param resource
   * @returns
   */
  createPatchForCurrentModelAndIncomingResource(
    currentModel: IntegrationConfiguration,
    resource: IntegrationConfigurationResource
  ): ServerDelta[] {
    //Convert Incoming Resource to Model
    const incomingModel = this.fromApiResource(resource);
    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(), //This is a simulated server timestamp
      this._rootStore.clientId //This makes it seem like the update is coming from this client which is not true. A quick fix to satisfy the contact. Need to review of the implications of this and if it matters for now.
    );
  }
}

export default IntegrationConfigurationTransformer;
