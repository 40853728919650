'use client';
import { useCallback } from 'react';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObMenuItem } from '../../elements/ob-menu-item/ob-menu-item';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export type ObNavigationSidenavOnClickCallbackReturnProps = {
  section: string;
  item: string;
};

export interface ObNavigationSidenavProps {
  sections: Array<ObNavigationSideNavSection>;
  activeId?: string;
  onClickCallback?: ({
    section,
    item,
  }: ObNavigationSidenavOnClickCallbackReturnProps) => void;
}

export interface ObNavigationSideNavPage {
  icon: IconSystemKeys;
  id: string;
  title: string;
}

export interface ObNavigationSideNavSection {
  id: string;
  title: string;
  pages: Array<ObNavigationSideNavPage>;
}

export const ObNavigationSidenav = ({
  activeId,
  sections = [],
  onClickCallback,
}: ObNavigationSidenavProps) => {
  const handleOnClickCallback = useCallback(
    (selection: ObNavigationSidenavOnClickCallbackReturnProps) => {
      if (!onClickCallback) return;
      onClickCallback(selection);

      return selection;
    },
    [onClickCallback]
  );

  return (
    <ul className='w-[260px] px-4'>
      {sections.map((section) => (
        <div key={section.title}>
          <li className='px-4 py-3 first:pt-0 m-0'>
            <ObTypography
              className='dark:text-contentPrimaryDark'
              variant={'body1'}
            >
              {section.title}
            </ObTypography>
          </li>

          {section.pages.map((page) => (
            <ObMenuItem
              key={page.id}
              isActive={activeId?.endsWith(page.id) || false}
              leftIcon={page.icon}
              size='small'
              kind='tertiary'
              onClickCallback={() =>
                handleOnClickCallback({
                  section: section.id,
                  item: page.id,
                })
              }
            >
              {page.title}
            </ObMenuItem>
          ))}
        </div>
      ))}
    </ul>
  );
};
