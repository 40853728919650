import { ServerDelta, TemplateSettingsSectionResource } from '@outbound/types';
import { BaseTransformer } from '../../../base-transformer';
import { RootStore } from '../../../root-store';
import SettingSection from './setting-section';
import SettingSubSection from './setting-sub-section';

class SettingSectionTransformer extends BaseTransformer<
  TemplateSettingsSectionResource,
  SettingSection
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  fromApiResource(resource: TemplateSettingsSectionResource): SettingSection {
    const subSections = resource.subSections.map((subSection) => {
      return new SettingSubSection(
        this._rootStore,
        subSection.id,
        subSection.heading ?? '',
        []
      );
    });
    return new SettingSection(
      this._rootStore,
      resource.id,
      resource.heading,
      subSections
    );
  }
  createPatchForCurrentModelAndIncomingResource(
    _model: SettingSection,
    _resource: TemplateSettingsSectionResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }
}

export default SettingSectionTransformer;
