import { cva, cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObTypography } from '../ob-typography/ob-typography';
import './ob-divider.css';

export type ObDividerVariant = 'default' | 'shiny';

export interface ObDividerProps {
  className?: string;
  variant?: ObDividerVariant;
  /**
   * Optional text to place inside of the divider
   */
  label?: string;
}

export const ObDivider: FC<ObDividerProps> = ({
  className = '',
  variant = 'default',
  label,
}) => {
  const divider = cva('', {
    variants: {
      variant: {
        default: 'border-t border-borderDefaultNormalLight opacity-[0.16]',
        shiny: 'ob-divider__light dark:ob-divider__dark',
      },
      defaultVariants: {
        variant: 'default',
      },
    },
  });

  if (label) {
    return (
      <div
        data-testid='divider-with-label'
        className='flex flex-row flex-nowrap justify-between items-center w-full gap-4'
      >
        <div className={cx(' w-full', divider({ variant }), className)}></div>
        <div className='flex-1 pb-[2px]'>
          <ObTypography
            color='secondary'
            variant='body3'
          >
            {label}
          </ObTypography>
        </div>
        <div className={cx(' w-full', divider({ variant }), className)}></div>
      </div>
    );
  }

  return (
    <div
      data-testid='divider-without-label'
      className={cx('w-full', divider({ variant }), className)}
    ></div>
  );
};
