import { ObMetricCardHeader } from '../../molecules/ob-metric-card-header/ob-metric-card-header';
import { ObMetricCardSurface } from '../../molecules/ob-metric-card-surface/ob-metric-card-surface';
import {
  ObMetricCardXBar,
  ObMetricCardXBarProps,
} from '../../molecules/ob-metric-card-x-bar/ob-metric-card-x-bar';

export interface ObMetricCardBarProps {
  title: string;
  status:
    | 'nominal'
    | 'internal-action-required'
    | 'customer-action-required'
    | 'suggestion';
  context: {
    title: string;
    status:
      | 'nominal'
      | 'internal-action-required'
      | 'customer-action-required'
      | 'suggestion';
  };
  metrics: Array<ObMetricCardXBarProps>;
}
export const ObMetricCardBar = ({
  title,
  metrics = [],
}: ObMetricCardBarProps) => {
  return (
    <ObMetricCardSurface>
      <ObMetricCardHeader
        title={title}
        status='nominal'
        context={{ title: 'nominal', status: 'nominal' }}
      />
      <div className='flex flex-col gap-4'>
        {metrics.map((metric, index) => (
          <ObMetricCardXBar
            key={index}
            {...metric}
          />
        ))}
      </div>
    </ObMetricCardSurface>
  );
};
