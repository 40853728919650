import { FC } from 'react';

import { ObButton } from '../../elements/ob-button/ob-button';
import { SettingCardRow } from '../ob-setting-card-row/setting-card-row';

interface SettingCardRowButtonProps {
  /**
   * The name of the setting
   */
  title: string;
  /**
   * A text description of the value of the setting or the action of the action button
   */
  body: string;
  /**
   * The text that will display on the Button
   */
  buttonLabel: string;
  /**
   * Accessible label describing the button's functionality.
   * Such as 'Edit Workspace Name'
   */
  buttonAriaLabel?: string;
  /**
   * A callback function to be called when the button is clicked
   */
  handleButtonClickCallback: () => void;
  /**
   * Sets the component to a loading State.
   * Useful for when the data needed to populate the component is fetched asynchronously
   */
  loading?: boolean;

  /**
   * Sets the color of the card to a supported theme color.
   */
  color?: 'error' | 'info';
}
export const SettingCardRowWithButton: FC<SettingCardRowButtonProps> = ({
  title,
  body,
  buttonLabel = 'Edit',
  buttonAriaLabel,
  handleButtonClickCallback,
  loading = false,
  color,
}) => {
  return (
    <SettingCardRow
      title={title}
      body={body}
      loading={loading}
      color={color}
    >
      <div className='flex items-center justify-end'>
        <ObButton
          label={buttonLabel}
          {...(color && { color: color })}
          variant='outline'
          className='!bg-transparent'
          textClassname='whitespace-nowrap'
          aria-label={buttonAriaLabel || buttonLabel}
          onClick={handleButtonClickCallback}
          fullWidth='mobile'
          {...(loading && { disabled: true })}
          size='large'
        />
      </div>
    </SettingCardRow>
  );
};
