import { RadioGroup } from '@headlessui/react';
import { cva, cx } from 'class-variance-authority';
import { useCallback, useEffect, useState } from 'react';
import { ObBadge, ObGradientIcon, ObTypography } from '../../../';
import { Level } from '../../../tokens/colors/colors';
import { IconSize, ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { Option } from './ob-card-option-radio-group.types';

export interface ObCardOptionRadioGroupProps {
  value?: Option;
  label?: string;
  options: any[];
  gradientIconSize?: IconSize;
  onValueChangedCb: (selected: Option) => void;
}

const CheckMark = ({ checked }: { checked: boolean }) => {
  return (
    <div className='checkbox__border rounded-full flex justify-center items-center h-5 w-5 border-actionPrimaryDark border-2 transition-opacity'>
      <div
        className={cx(
          'checkbox__icon-background rounded-full flex justify-center items-center h-5 w-5  transition-opacity',
          checked
            ? 'bg-actionPrimaryDark border-borderDefaultNormalDark opacity-100'
            : 'opacity-0'
        )}
      >
        <ObIcon
          aria-hidden='true'
          classNames={checked ? 'opacity-100' : 'opacity-0'}
          icon='check'
          size='x-small'
        />
      </div>
    </div>
  );
};

export const ObCardOptionRadioGroup = ({
  value,
  label,
  options = [],
  gradientIconSize = 'small',
  onValueChangedCb,
}: ObCardOptionRadioGroupProps) => {
  const [localValue, setLocalValue] = useState<Option>(
    options.find((option) => option.value === value?.value) ?? options[0]
  );

  useEffect(() => {
    if (value != null && value?.value !== localValue?.value) {
      setLocalValue(value ?? options[0]);
    }
  }, [value, setLocalValue, options, localValue]);

  const getCardState = useCallback(
    (option: Option, active: boolean) => {
      let state: 'default' | 'active' | 'checked' | 'disabled' = 'default';

      if (option.value === localValue.value) {
        return 'checked';
      } else if (option.disabled) {
        state = 'disabled';
      } else if (active) {
        state = 'active';
      }

      return state;
    },
    [localValue.value]
  );

  const handleChange = (selected: Option) => {
    if (selected.disabled) {
      console.log('Option Disabled');
      return;
    }
    if (selected.value !== localValue.value) {
      setLocalValue(selected);
      onValueChangedCb?.(selected);
    }
  };

  const cardClasses = cva(
    ' cursor-pointer rounded-lg border bg-bgSurfaceLight dark:bg-bgSurfaceDark p-4 shadow-sm focus:outline-none',
    {
      variants: {
        state: {
          default: ['dark:border-borderDefaultNormalDark'],
          active: ['border-actionPrimaryDark ring-2 ring-actionPrimaryDark'],
          checked: ['border-actionPrimaryDark ring-2 ring-actionPrimaryDark'],
          disabled: ['opacity-50 cursor-not-allowed'],
        },
      },
    }
  );

  const buildGrid = (length: number) => {
    switch (length) {
      case 1:
        return 'grid gap-4 grid-cols-1 ';
      case 2:
        return 'grid gap-4 grid-cols-1 md:grid-cols-2';
      case 3:
        return 'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
      default:
        return 'grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';
    }
  };

  return (
    <RadioGroup
      value={localValue}
      by='value'
      onChange={handleChange}
    >
      <RadioGroup.Label className='sr-only'>{label}</RadioGroup.Label>
      <div className={cx([buildGrid(options.length)])}>
        {options.map((option: Option) => {
          return (
            <RadioGroup.Option
              key={option.title}
              value={option}
              disabled={option.disabled}
              className={({ active }) =>
                cx(cardClasses({ state: getCardState(option, active) }))
              }
            >
              {({ checked }) => (
                <article className='flex flex-col justify-between h-full  gap-3'>
                  <header className='flex flex-row gap-2 items-start justify-between'>
                    <RadioGroup.Label>
                      {/* We will place the overline here if it is present otherwise we will place the title here */}
                      <ObTypography
                        variant={option.overline != null ? 'subtitle1' : 'h4'}
                        as='span'
                        style={{ lineHeight: '20px' }}
                      >
                        {option.overline ?? option.title}
                      </ObTypography>
                    </RadioGroup.Label>
                    <CheckMark checked={checked} />
                  </header>
                  <section className='flex flex-col gap-2'>
                    {/* When no overline is present we will set the title as the overline so this is not needed */}
                    {option.overline && (
                      <ObTypography
                        as='span'
                        variant={'h4'}
                        style={{ lineHeight: '20px' }}
                      >
                        {option.title}
                      </ObTypography>
                    )}
                    {option.icon && (
                      <div className='w-[56px] h-[56px] flex items-center justify-center'>
                        <ObGradientIcon
                          icon={option.icon}
                          size='large'
                          iconSize={gradientIconSize}
                          iconRadialColorClassName={option.gradientColor}
                        />
                      </div>
                    )}
                    <RadioGroup.Description>
                      <ObTypography
                        as='span'
                        variant='h6'
                        color='secondary'
                      >
                        {option.description}
                      </ObTypography>
                    </RadioGroup.Description>
                    <RadioGroup.Description>
                      <ObTypography
                        as='span'
                        variant='body2'
                        color='secondary'
                      >
                        {option.descriptionLine2}
                      </ObTypography>
                    </RadioGroup.Description>
                  </section>
                  <footer className='flex flex-col w-full'>
                    {option.badgeDescription && (
                      <div>
                        <div className='max-w-full flex'>
                          <ObBadge
                            level={option.badgeLevel ?? Level.DEFAULT}
                            content={option.badgeDescription}
                            iconLeft={option.badgeIconLeft}
                            iconRight={option.badgeIconRight}
                          />
                        </div>
                      </div>
                    )}
                  </footer>
                </article>
              )}
            </RadioGroup.Option>
          );
        })}
      </div>
    </RadioGroup>
  );
};
