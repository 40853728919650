import { ReactNode } from 'react';
import { MarketingPlanCardBaseTitle } from './marketing-plan-card-base-title';

export const MarketingPlanCardSubTitle = ({
  title,
  actions,
}: {
  title: string;
  actions?: ReactNode;
}) => {
  return (
    <MarketingPlanCardBaseTitle
      actions={actions}
      title={title}
      level='h4'
    />
  );
};
