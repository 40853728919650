/** solid/shapes/star-01 */
export const StarO1 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={height}
      height={width}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M8.43169 1.18329C8.15946 1.05349 7.84318 1.05349 7.57094 1.18329C7.3356 1.2955 7.20584 1.49032 7.14041 1.5972C7.07276 1.7077 7.00332 1.84845 6.93563 1.98564L5.49848 4.89716L2.28378 5.36704C2.13245 5.38912 1.97718 5.41179 1.85123 5.44207C1.72939 5.47137 1.50412 5.53474 1.32485 5.72397C1.11745 5.94288 1.01992 6.24369 1.0594 6.54264C1.09352 6.80107 1.23875 6.98456 1.32022 7.07978C1.40443 7.17821 1.51686 7.28768 1.62644 7.39436L3.95164 9.6591L3.40299 12.8579C3.37709 13.0088 3.35052 13.1635 3.34029 13.2927C3.3304 13.4176 3.32084 13.6516 3.44531 13.8807C3.58928 14.1458 3.84519 14.3317 4.14177 14.3867C4.39818 14.4342 4.6177 14.3528 4.73346 14.3047C4.85316 14.2551 4.9921 14.182 5.12754 14.1107L8.00132 12.5994L10.8751 14.1107C11.0105 14.182 11.1495 14.2551 11.2692 14.3047C11.3849 14.3528 11.6045 14.4342 11.8609 14.3867C12.1574 14.3317 12.4134 14.1458 12.5573 13.8807C12.6818 13.6516 12.6722 13.4176 12.6623 13.2927C12.6521 13.1635 12.6255 13.0088 12.5996 12.858L12.051 9.6591L14.3762 7.39433C14.4858 7.28766 14.5982 7.1782 14.6824 7.07978C14.7639 6.98456 14.9091 6.80107 14.9432 6.54264C14.9827 6.24369 14.8852 5.94288 14.6778 5.72397C14.4985 5.53474 14.2732 5.47137 14.1514 5.44207C14.0255 5.41179 13.8702 5.38912 13.7189 5.36704L10.5042 4.89716L9.06703 1.98569C8.99934 1.84849 8.92988 1.7077 8.86223 1.5972C8.79679 1.49032 8.66704 1.2955 8.43169 1.18329Z'
        fill='currentColor'
      />
    </svg>
  );
};
