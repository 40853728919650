'use client';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import ReactFlow, {
  MiniMap,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  Node,
  MarkerType,
  Panel,
  ReactFlowInstance,
  ReactFlowProvider,
} from 'reactflow';

import { ObTypography } from '../../elements/ob-typography/ob-typography';

//React Flow Styles
import 'reactflow/dist/style.css';

import ObSeqWfBaseNode from '../../elements/ob-seq-wf-base-node/ob-seq-wf-base-node';
import { ObSeqWfCustomEdge } from '../../molecules/ob-seq-wf-custom-edge/ob-seq-wf-custom-edge';
import ObSeqWfPalletNode from '../../elements/ob-seq-wf-pallet-node/ob-seq-wf-pallet-node';
import { ObSeqWfZoomControl } from '../../molecules/ob-seq-wf-zoom-control/ob-seq-wf-zoom-control';

/**
 * Temporary ID Generation for dropping new nodes POC
 * We will want to replace this with the actual node ID which
 * will mostly likely be a combination of a uniqueId for the client session
 * and a UUID in the format `${clientSessionId}|${crypto.randomUUID()}` or similar
 * While UUIDs are unique this will give us the ability to track who created the node
 * for our multi-player support.
 **/
let id = 100;
const getId = () => `dndnode_${id++}`;

//We would want to pass our own node and edge schema to this component and have the component convert to this schema.
const initialNodes = [
  {
    id: '0',
    type: 'startNode',
    position: { x: -360, y: 0 },
    data: {
      id: '1',
      overline: 'Campaign',
      title: 'Facebook Ads',
      icon: 'plus',
      iconBackgroundColor: 'actionPrimaryDark',
    },
  },
  {
    id: '-1',
    type: 'startNode',
    position: { x: -360, y: 80 },
    data: {
      id: '1',
      overline: 'Campaign',
      title: 'Google Ads',
      icon: '',
      iconBackgroundColor: '',
    },
  },
  {
    id: '1',
    type: 'startNode',
    position: { x: 0, y: 0 },
    data: {
      id: '1',
      overline: 'Sequence',
      title: 'Start',
      icon: '',
      iconBackgroundColor: '',
    },
  },
  {
    id: '2',
    type: 'startNode',
    position: { x: 0, y: 200 },
    data: {
      id: '2',
      overline: 'Phone Call',
      title: 'Cold Discovery Call',
      icon: '',
      iconBackgroundColor: '',
    },
  },
  {
    id: '3',
    type: 'startNode',
    position: { x: 200, y: 400 },
    data: {
      id: '2',
      overline: 'Email',
      title: 'Step One',
      icon: '',
      iconBackgroundColor: '',
    },
  },
  {
    id: '4',
    type: 'startNode',
    position: { x: -200, y: 400 },
    data: {
      id: '2',
      overline: 'Email',
      title: 'Voice Mail Follow Up',
      icon: '',
      iconBackgroundColor: '',
    },
  },
];

//Edge as in a graph. Represents a connection between two nodes.
const initialEdges = [
  {
    id: 'e1-2',
    type: 'customEdge',
    source: '1',
    target: '2',
    markerEnd: { type: MarkerType.Arrow },
  },
  {
    id: 'e2-3',
    type: 'customEdge',
    source: '2',
    target: '3',
    markerEnd: { type: MarkerType.Arrow },
    data: { label: 'Connected' },
  },
  {
    id: 'e2-4',
    type: 'customEdge',
    source: '2',
    target: '4',
    markerEnd: { type: MarkerType.Arrow },
    data: { label: 'Left a VM' },
  },
];

export interface ObSeqWfRendererProps {
  showMiniMap: boolean;
  showControls: boolean;
  readonlyMode: boolean;
  minZoomValue?: number;
  maxZoomValue?: number;
}

export const ObSeqWfRenderer: FC<ObSeqWfRendererProps> = ({
  showMiniMap,
  showControls,
  readonlyMode,
  minZoomValue = 0.25,
  maxZoomValue = 2,
}: ObSeqWfRendererProps) => {
  //Memoize the node and edge types so they don't re-render on every state change
  const nodeTypes = useMemo(() => ({ startNode: ObSeqWfBaseNode }), []);
  const edgeTypes = useMemo(() => ({ customEdge: ObSeqWfCustomEdge }), []);

  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onInit = (reactFlowInstance: ReactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance);
  };

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  /**
   * DRAG AND DROP EVENT HANDLING
   */
  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (
        reactFlowWrapper == null ||
        reactFlowWrapper.current == null ||
        reactFlowInstance == null
      ) {
        return;
      } else {
        const reactFlowBounds =
          reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer?.getData('application/reactflow');

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) {
          return;
        }

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left || 0,
          y: event.clientY - reactFlowBounds.top || 0,
        });
        const newNode: Node = {
          id: getId(),
          type,
          position,
          data: { label: `${type} node` },
        };

        setNodes((nds: Node[]) => nds.concat(newNode));
      }
    },
    //Existing Error
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance]
  );

  return (
    <>
      <ReactFlowProvider>
        <div
          style={{ width: '100%', height: '500px' }}
          className='reactflow-wrapper'
          data-testid='sequence-wf-renderer'
          ref={reactFlowWrapper}
        >
          <ReactFlow
            onInit={onInit}
            className='dark:bg-bgSurfaceDark'
            proOptions={{ hideAttribution: true }}
            fitView
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodes={nodes}
            panOnScroll={true}
            elementsSelectable={!readonlyMode} //Disables selection of nodes
            nodesConnectable={!readonlyMode} //Disables connecting of nodes
            nodesDraggable={!readonlyMode} //Disables dragging of nodes
            edges={edges}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onClick={(event) => {
              console.log(`top level click event`, event);
            }}
            onNodeClick={(event, node) => {
              console.log(`Node ${node.id} clicked`, event);
            }}
            //let this component validate the min and max zoom because it validates the scenario where the user uses the buttons (ObSeqWfZoomControl component) and also validates the scenarios where the user uses pinch, scroll or double click.
            minZoom={minZoomValue}
            maxZoom={maxZoomValue}
          >
            {showControls && (
              <ObSeqWfZoomControl
                zoomInCallback={() => {
                  console.log('zoomInCallback');
                }}
                zoomOutCallback={() => {
                  console.log('zoomOutCallback');
                }}
                onValueUpdatedCallback={() => {
                  console.log('onValueUpdatedCallback');
                }}
                initialValue={1}
              />
            )}
            {showMiniMap && <MiniMap />}
            <Background
              variant={BackgroundVariant.Dots}
              gap={12}
              size={1}
              className='opacity-30'
            />
            {/* Shown when in edit mode and no nodes are active */}
            {!readonlyMode && (
              <Panel position={'top-right'}>
                <div className='flex flex-col gap-2 border border-borderDefaultNormalDark rounded-xl w-[400px] h-[100%] bg-bgSurfaceDark p-4 m-6'>
                  <ObTypography variant='h3'>Build Your Sequence</ObTypography>
                  <ObTypography
                    variant='body2'
                    color='secondary'
                  >
                    Drag and drop the elements below to build your sequence
                  </ObTypography>
                  <hr></hr>
                  <ObTypography variant='h4'>Touchpoints</ObTypography>
                  <ObSeqWfPalletNode
                    title={'Phone Call'}
                    overline={'Make Action'}
                  />
                  <ObSeqWfPalletNode
                    title={'Phone Call'}
                    overline={'Make Action'}
                  />
                  <ObSeqWfPalletNode
                    title={'Phone Call'}
                    overline={'Make Action'}
                  />
                  <ObTypography variant='h4'>Triggers</ObTypography>
                  <ObSeqWfPalletNode
                    title={'Phone Call'}
                    overline={'Make Action'}
                  />
                  <ObSeqWfPalletNode
                    title={'Phone Call'}
                    overline={'Make Action'}
                  />
                  <ObTypography variant='h4'>Logic</ObTypography>
                  <ObSeqWfPalletNode
                    title={'Phone Call'}
                    overline={'Make Action'}
                  />
                  <ObSeqWfPalletNode
                    title={'Phone Call'}
                    overline={'Make Action'}
                  />
                </div>
              </Panel>
            )}
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </>
  );
};
