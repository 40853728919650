import { ListResponse, WorkspaceMemberResource } from '@outbound/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';

/**
 * Interacts with the API to fetch and mutate Workspace Memberships
 */
class MembershipTransport extends BaseTransport<WorkspaceMemberResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  protected fetchById(_id: string): Promise<WorkspaceMemberResource | null> {
    throw new Error('Method not implemented.');
  }
  public acceptEmbeddedResource(_resource: WorkspaceMemberResource): void {
    throw new Error('Method not implemented.');
  }

  protected async fetchAll(): Promise<Array<WorkspaceMemberResource>> {
    const response: AxiosResponse<ListResponse<WorkspaceMemberResource>> =
      await this._axiosInstance.get('/workspace/members');
    return response.data.items;
  }

  public async bootstrap() {
    const memberships = await this.fetchAll();
    memberships.forEach((membership) => {
      this._transport.userTransport.acceptEmbeddedResource(membership.user); //Currently the only way to load a list of users for a workspace.
      this.notifyStoreOfServiceUpdateCallback?.(membership.id, membership);
    });
  }
}

export default MembershipTransport;
