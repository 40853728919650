import { AssetStatus, ServerDelta } from '@outbound/types';
import { runInAction } from 'mobx';
import { BaseModel } from '../base-model';
import { RootStore } from '../root-store';

abstract class Asset extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    status: '/status',
  };

  private _type: 'image' | 'video' | 'static-map';
  private _status: AssetStatus;

  constructor(
    rootStore: RootStore,
    id: string,
    workspaceId: string,
    type: 'image' | 'video' | 'static-map',
    status: AssetStatus
  ) {
    super(rootStore, 'asset', '1', id, workspaceId);
    this._type = type;
    this._status = status;
  }

  get status(): AssetStatus {
    return this._status;
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      for (const delta of patch) {
        switch (delta.path) {
          case Asset.paths.status: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._status = delta.value;
                break;
              case 'remove':
                console.error('Invalid Delta. Cannot remove Asset Status');
            }
            break;
          }
        }
      }
    });
  }
}

export default Asset;
