import Box from '@mui/material/Box';
import { LinkFooter } from '../../components/elements/link-footer';
import CompleteUserProfile from './complete-user-profile';
import { ObBrand } from '@outbound/design-system';

const RequiredActionsPage = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        maxWidth: '1000px',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}
          component={'header'}
        >
          <ObBrand variant='logo' />
        </Box>

        <Box
          component={'section'}
          sx={{
            padding: 4,
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <CompleteUserProfile />
        </Box>
        <Box
          component={'footer'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 1,
          }}
        >
          <LinkFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default RequiredActionsPage;
