import { FC } from 'react';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObNoContentCtaProps {
  title: string;
  description: string;
  buttonText: string;
  iconName: IconSystemKeys;
  buttonIconRight?: IconSystemKeys;
  includeGradient?: boolean;
  onButtonClick?: () => void;
}

export const ObNoContentCta: FC<ObNoContentCtaProps> = ({
  title,
  iconName,
  description,
  buttonText,
  buttonIconRight = undefined,
  onButtonClick,
  includeGradient = false,
}: ObNoContentCtaProps) => {
  return (
    <div
      data-testid='ob-nocontentcta'
      className='flex flex-col items-center justify-center p-4 gap-y-6'
    >
      <ObIcon
        icon={iconName}
        color='secondary'
        size='x-large'
      />
      <div className='text-center'>
        <ObTypography
          variant='h2'
          className='mb-4'
        >
          {title}
        </ObTypography>
        <ObTypography
          variant='body1'
          color='secondary'
        >
          {description}
        </ObTypography>
      </div>
      {onButtonClick && (
        <ObButton
          label={buttonText}
          variant='primary'
          size='large'
          onClick={onButtonClick}
          iconRight={buttonIconRight}
        />
      )}
      {includeGradient && (
        <div className='rounded-full absolute  h-[457px] w-[457px] opacity-30 bg-actionPrimaryDark blur-[200px] -z-10' />
      )}
    </div>
  );
};
