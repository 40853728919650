import { PersonaResource, ServiceResourceIncludeAssets } from '@outbound/types';
import { AxiosInstance } from 'axios';
import AssetTransport from './assets/asset-transport';
import CustomerProfileTransport from './business-context/customer-profile/customer-profile-transport';
import LocationTransport from './business-context/location/location-transport';
import ServiceTransport from './business-context/service/service-transport';
import CampaignTransport from './campaign/transport/campaign-transport';
import CreativeTransport from './creative/transport/creative-transport';
import LandingPageTransport from './landing-page/landing-page-transport';
import UserTransport from './user/user-transport';
import IntegrationConfigurationTransport from './workspace/integration-configuration/integration-configuration-transport';
import { IntegrationTransport } from './workspace/integration/integration-transport';
import MembershipTransport from './workspace/membership/membership-transport';
import WorkspaceTransporter from './workspace/workspace-transporter';

export class Transport {
  private _campaignTransport: CampaignTransport;
  private _creativeTransport: CreativeTransport;
  private _serviceTransport: ServiceTransport;
  private _locationTransport: LocationTransport;
  private _customerProfileTransport: CustomerProfileTransport;
  private _integrationTransport: IntegrationTransport;
  private _integrationConfigurationTransport: IntegrationConfigurationTransport;
  private _workspaceTransport: WorkspaceTransporter;
  private _assetTransport: AssetTransport;
  private _membershipTransport: MembershipTransport;
  private _userTransport: UserTransport;
  private _landingPageTransport: LandingPageTransport;

  private _axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this._axiosInstance = axiosInstance;
    this._campaignTransport = new CampaignTransport(this, axiosInstance);
    this._creativeTransport = new CreativeTransport(this, axiosInstance);
    this._serviceTransport = new ServiceTransport(this, axiosInstance);
    this._assetTransport = new AssetTransport(this, axiosInstance);
    this._locationTransport = new LocationTransport(this, axiosInstance);
    this._integrationTransport = new IntegrationTransport(this, axiosInstance);
    this._integrationConfigurationTransport =
      new IntegrationConfigurationTransport(this, axiosInstance);
    this._membershipTransport = new MembershipTransport(this, axiosInstance);
    this._userTransport = new UserTransport(this, axiosInstance);
    this._customerProfileTransport = new CustomerProfileTransport(
      this,
      axiosInstance
    );
    this._workspaceTransport = new WorkspaceTransporter(this, axiosInstance);
    this._landingPageTransport = new LandingPageTransport(this, axiosInstance);
  }

  async initializeBusinessContext() {
    try {
      const response = await this._axiosInstance.get(`/playbook/settings`, {
        params: {
          // include: 'assets',
        },
      });
      if (response.data && response.status === 200) {
        if (response.data.personas) {
          response.data.personas.forEach((_persona: PersonaResource) => {
            this._customerProfileTransport.acceptEmbeddedResource(_persona);
          });
        }
        if (response.data.services) {
          response.data.services.forEach(
            (service: ServiceResourceIncludeAssets) => {
              this._serviceTransport.acceptEmbeddedResource(service);
            }
          );
        }
      }
    } catch (error) {
      console.error('Error fetching business context settings', error);
    }
  }

  public destroy(): void {
    console.log('Destroying Transport');
  }

  get campaignTransport(): CampaignTransport {
    return this._campaignTransport;
  }

  get creativeTransport(): CreativeTransport {
    return this._creativeTransport;
  }

  get serviceTransport(): ServiceTransport {
    return this._serviceTransport;
  }

  get assetTransport(): AssetTransport {
    return this._assetTransport;
  }

  get locationTransport(): LocationTransport {
    return this._locationTransport;
  }

  get customerProfileTransport(): CustomerProfileTransport {
    return this._customerProfileTransport;
  }

  get integrationTransport(): IntegrationTransport {
    return this._integrationTransport;
  }

  get workspaceTransport(): WorkspaceTransporter {
    return this._workspaceTransport;
  }

  get membershipTransport(): MembershipTransport {
    return this._membershipTransport;
  }

  get userTransport(): UserTransport {
    return this._userTransport;
  }

  get integrationConfigurationTransport(): IntegrationConfigurationTransport {
    return this._integrationConfigurationTransport;
  }

  get landingPageTransport(): LandingPageTransport {
    return this._landingPageTransport;
  }
}
