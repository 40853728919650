import { PopoverButton } from '@headlessui/react';
import {
  ObColorPicker,
  ObColorsField,
  ObIcon,
  ObLoadingSpinner,
  ObThinkingButton,
  ObTypography,
} from '@outbound/design-system';
import {
  ObColorPickerOpenState,
  ObColorsFieldValue,
} from '@outbound/design-system/src/components/organisms/ob-colors-field/ob-colors-field';
import { ColorScanResource, SelectedColorResource } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { format } from 'date-fns';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AnimatedTypography } from '../components/animated-typography/animated-typography';

interface ColorSelectorButtonProps {
  color: ColorScanResource;
  onClickHandler: (color: ColorScanResource) => any;
}

const PRIMARY_COLOR = 'primaryColor';
const SECONDARY_COLOR = 'secondaryColor';

const ColorSelectorButton: FC<ColorSelectorButtonProps> = ({
  color,
  onClickHandler,
}) => {
  const handleColorButtonClick = useCallback(() => {
    if (onClickHandler) {
      onClickHandler(color);
    }
  }, [color, onClickHandler]);

  return (
    <div
      data-testid='color-option'
      role='button'
      aria-label={`Select ${color.hex} as your primary color`}
      onClick={handleColorButtonClick}
      className={`rounded-lg border-borderDefaultNormalDark border h-[108px] min-w-[110px] cursor-pointer`}
      style={{
        backgroundColor: color.hex ?? 'rgba(0,0,0,0.0)',
      }}
    ></div>
  );
};

export interface BrandColorSelectorPageProps {
  onSubmitCallback: (
    primaryColor: SelectedColorResource | undefined,
    secondaryColor: SelectedColorResource | undefined
  ) => Promise<any>;
  availableColors?: ColorScanResource[];
  initialPrimaryColor?: SelectedColorResource;
  initialSecondaryColor?: SelectedColorResource;
  hasColors: boolean;
}

export const BrandColorSelectorPage: FC<BrandColorSelectorPageProps> = ({
  onSubmitCallback,
  availableColors: scannedAvailableColors,
  initialPrimaryColor,
  initialSecondaryColor,
  hasColors,
}: BrandColorSelectorPageProps) => {
  const [primaryColor, setPrimaryColor] = useState<
    SelectedColorResource | undefined
  >(undefined);
  const [secondaryColor, setSecondaryColor] = useState<
    SelectedColorResource | undefined
  >(undefined);

  /**
   * Used to track if we want to treat the color as selected.
   * We only set this to true when a non empty value for the color exists
   * and the color picker has been closed. Due to the UX of the color picker
   * a user will click and drag and we don't want the animations to trigger on
   * the first click but only after they have clicked done or closed the picker.
   */
  const [isPrimaryColorSelected, setIsPrimaryColorSelected] = useState(false);
  const [isSecondaryColorSelected, setIsSecondaryColorSelected] =
    useState(false);

  const [colorPickerOpenedState, setColorPickerOpenedState] =
    useState<ObColorPickerOpenState>({});

  const [customAvailableColorDraft, setCustomAvailableColorDraft] = useState<
    string | undefined
  >();
  const [customAvailableColors, setCustomAvailableColors] = useState<
    ColorScanResource[]
  >([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);

  /**
   * Colors that were found during the site scan that the user can select from
   */
  const availableColors = useMemo<ColorScanResource[] | undefined>(() => {
    if (!scannedAvailableColors) return undefined;
    return [...scannedAvailableColors, ...customAvailableColors];
  }, [customAvailableColors, scannedAvailableColors]);

  const colorsToSelect = [
    {
      label: 'Primary Color',
      key: PRIMARY_COLOR,
      isRequired: true,
    },
    {
      label: 'Secondary Color',
      key: SECONDARY_COLOR,
    },
  ];

  const [subHeading, setSubHeading] = useState<ReactNode>(
    'Select your primary color'
  );

  hasColors = !!(availableColors && availableColors.length);

  useEffect(() => {
    if (initialPrimaryColor) {
      setPrimaryColor(
        availableColors?.find((color) => color.id === initialPrimaryColor.id)
      );
    }

    if (initialSecondaryColor) {
      setSecondaryColor(
        availableColors?.find((color) => color.id === initialSecondaryColor.id)
      );
    }
  }, [availableColors, initialPrimaryColor, initialSecondaryColor]);

  useEffect(() => {
    setHasErrors(
      primaryColor === undefined ||
        primaryColor === null ||
        primaryColor?.hex === ''
    );
  }, [primaryColor]);

  const handleSubmit = useCallback(async () => {
    setHasSubmitted(true);
    if (hasErrors) {
      return Promise.reject();
    }
    await onSubmitCallback(primaryColor, secondaryColor);
  }, [primaryColor, secondaryColor, hasErrors, onSubmitCallback]);

  useEffect(() => {
    if (!hasColors && !isPrimaryColorSelected) {
      setSubHeading(
        <>
          Don’t worry – you can manually pick them here.
          <br />
          Let's start with a primary color.
        </>
      );
    } else if (!hasColors && isPrimaryColorSelected) {
      setSubHeading(
        <>
          Don’t worry – you can manually pick them here.
          <br />
          Now, let’s pick a secondary color (optional).
        </>
      );
    } else if (!isPrimaryColorSelected) {
      setSubHeading('Select your primary color');
    } else if (!isSecondaryColorSelected) {
      setSubHeading('Select your secondary color');
    } else {
      setSubHeading('These colors look great!');
    }
  }, [
    hasColors,
    isPrimaryColorSelected,
    isSecondaryColorSelected,
    primaryColor,
    secondaryColor,
  ]);

  const [obColorsFieldValue, setObColorsFieldValue] = useState<
    Array<ObColorsFieldValue>
  >([
    {
      key: PRIMARY_COLOR,
      value: '',
    },
    {
      key: SECONDARY_COLOR,
      value: '',
    },
  ]);

  useEffect(() => {
    setObColorsFieldValue((currentValue) => {
      return currentValue.map((color) => {
        if (color.key === PRIMARY_COLOR) {
          return {
            ...color,
            value: primaryColor?.hex || '',
          };
        }
        return color;
      });
    });
  }, [primaryColor]);

  useEffect(() => {
    setObColorsFieldValue((currentValue) => {
      return currentValue.map((color) => {
        if (color.key === SECONDARY_COLOR) {
          return {
            ...color,
            value: secondaryColor?.hex || '',
          };
        }
        return color;
      });
    });
  }, [secondaryColor]);

  const setColorFromCircles = (color: ColorScanResource) => {
    if (primaryColor == null) {
      handlePrimaryColorSelected(color);
    } else {
      handleSecondaryColorSelected(color);
    }
  };

  const handlePrimaryColorSelected = (colorSelection?: ColorScanResource) => {
    setPrimaryColor(colorSelection);
    setIsPrimaryColorSelected(true);
  };

  const handleSecondaryColorSelected = (colorSelection?: ColorScanResource) => {
    setSecondaryColor(colorSelection);
    setIsSecondaryColorSelected(true);
  };

  const handleAddCustomAvailableColor = () => {
    if (customAvailableColorDraft) {
      setCustomAvailableColors([
        ...customAvailableColors,
        {
          id: uuidv4(),
          hex: customAvailableColorDraft,
          categorization: 'COLOR',
          createdAtTimestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
          updatedAtTimestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
        },
      ]);

      setCustomAvailableColorDraft(undefined);
    }
  };

  /**
   * Side effect of the color picker being closed for a color
   */
  useEffect(() => {
    if (
      colorPickerOpenedState[PRIMARY_COLOR] === false &&
      primaryColor != null &&
      primaryColor.hex !== ''
    ) {
      setIsPrimaryColorSelected(true);
    }
    if (
      colorPickerOpenedState[SECONDARY_COLOR] === false &&
      secondaryColor != null &&
      secondaryColor.hex !== ''
    ) {
      setIsSecondaryColorSelected(true);
    }
  }, [colorPickerOpenedState, primaryColor, secondaryColor]);

  const onValueChangedCallback = useCallback(
    (values: Array<ObColorsFieldValue>) => {
      const primaryColor = values.find(
        (field: any) => field.key === PRIMARY_COLOR
      );
      const secondaryColor = values.find(
        (field: any) => field.key === SECONDARY_COLOR
      );

      if (primaryColor?.value === '') {
        setPrimaryColor(undefined);
        setIsPrimaryColorSelected(false);
      } else if (primaryColor) {
        setPrimaryColor({ id: 'primary', hex: primaryColor.value });
        //Check to make sure the color picker is closed before setting the color as selected
        if (colorPickerOpenedState[PRIMARY_COLOR] === false) {
          setIsPrimaryColorSelected(true);
        }
      }

      if (secondaryColor?.value === '') {
        setSecondaryColor(undefined);
        setIsSecondaryColorSelected(false);
      } else if (secondaryColor) {
        setSecondaryColor({ id: 'secondary', hex: secondaryColor.value });
        //Check to make sure the color picker is closed before setting the color as selected
        if (colorPickerOpenedState[SECONDARY_COLOR] === false) {
          setIsSecondaryColorSelected(true);
        }
      }
    },
    [colorPickerOpenedState]
  );

  return (
    <div
      data-testid='color-selection-step'
      className='flex flex-col items-center justify-center gap-3'
    >
      {!availableColors && <ObLoadingSpinner includeBrand={true} />}
      {availableColors && (
        <>
          <div className='max-w-[800px] flex flex-col gap-4  items-center text-center'>
            <ObTypography
              variant='h1'
              className={cx('text-center transition-all')}
            >
              {hasColors
                ? 'Here are the colors we found on your site!'
                : "We didn't find any colors on your site!"}
            </ObTypography>
            <AnimatedTypography
              variant='h3'
              color='secondary'
              text={subHeading}
            />
          </div>

          {hasColors && (
            <div
              className={cx(
                'flex flex-row flex-wrap justify-center items-center gap-4 transition-all duration-700 mt-8',
                isPrimaryColorSelected && isSecondaryColorSelected
                  ? 'h-0 opacity-0 translate-x-[1500px]'
                  : 'h-[200px] opacity-100'
              )}
            >
              {availableColors.map((color) => (
                <ColorSelectorButton
                  key={color.id}
                  color={color}
                  onClickHandler={setColorFromCircles}
                />
              ))}
              <ObColorPicker
                onChange={({ hex }) => setCustomAvailableColorDraft(hex)}
                onClose={handleAddCustomAvailableColor}
                popoverButton={
                  <PopoverButton>
                    <div
                      style={{
                        backgroundColor:
                          customAvailableColorDraft ?? 'rgba(0,0,0,0.0)',
                      }}
                      className='rounded-lg border-borderDefaultNormalDark border-dashed border-2 h-[108px] w-[110px] sm:min-w-[88px] cursor-pointer flex flex-col items-center justify-center'
                    >
                      <ObIcon
                        icon='plus'
                        color='secondary'
                        size='medium'
                      />
                      <ObTypography
                        variant='body2'
                        color='secondary'
                      >
                        Add a Color
                      </ObTypography>
                    </div>
                  </PopoverButton>
                }
              />
            </div>
          )}

          <div
            className={cx(
              `flex justify-center transition-all duration-700 w-full max-w-[640px]`,
              !hasColors ? 'pt-20' : ''
            )}
          >
            <ObColorsField
              inputId={'id-primary'}
              isLoading={false}
              colorsToSelect={colorsToSelect}
              value={obColorsFieldValue}
              onValueChangedCallback={onValueChangedCallback}
              onColorPickerOpenedStateChangedCallback={
                setColorPickerOpenedState
              }
            />
          </div>
          <div className='-mt-6'>
            <ObTypography
              variant='body1'
              color='negative'
              className={hasSubmitted && hasErrors ? '' : 'invisible'}
            >
              Please select a primary color before continuing.
            </ObTypography>
          </div>
          <div className='w-full max-w-[720px] transition-all duration-700 text-center'>
            <ObThinkingButton
              label='Continue'
              variant='primary'
              size='large'
              disabled={false}
              fullWidth='always'
              onClickCallback={handleSubmit}
              type='submit'
            />
          </div>
        </>
      )}
    </div>
  );
};
