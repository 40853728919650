import { Disclosure, DisclosurePanel, Transition } from '@headlessui/react';
import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';
import {
  TemplateSettingsSectionHeader,
  TemplateSettingsSectionHeaderProps,
} from '../template-settings-section-header/template-settings-section-header';

export interface TemplateSettingSectionProps
  extends Omit<TemplateSettingsSectionHeaderProps, 'isDisclosureExpanded'> {
  children?: ReactNode;
  isOpen: boolean;
  FF_enableRemixFeature?: boolean;
}

/**
 * Template Setting Section
 */
export const TemplateSettingSection = (props: TemplateSettingSectionProps) => {
  return (
    <Disclosure
      defaultOpen={props.isOpen}
      as='section'
      className={'border-borderDefaultNormalDark border-b'}
    >
      {() => (
        <>
          <TemplateSettingsSectionHeader
            isDisclosureExpanded={props.isOpen}
            {...props}
          />
          <Transition
            show={props.isOpen}
            enter='transition transition-[max-height] duration-300 ease-in'
            enterFrom='transform  max-h-0'
            enterTo='transform   max-h-screen'
            leave='transition transition-[max-height,opacity] duration-300 ease-out'
            leaveFrom='transform  max-h-screen'
            leaveTo='transform max-h-0 '
          >
            <DisclosurePanel
              static
              as='div'
              className={cx(
                'pl-4 pr-0 pb-4 flex flex-col overflow-hidden',
                props.FF_enableRemixFeature ? '' : 'pr-4'
              )}
            >
              {props.children}
            </DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
