import {
  CampaignChannelType,
  CampaignGoal,
  CampaignResource,
} from '@outbound/types';
import { ObChannelAvatar } from '../../../avatars/ob-channel-avatar/ob-channel-avatar';
import { Level } from '../../../tokens/colors/colors';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { timeAgoFromIsoString } from '../../../utilities/datetime-utilities';

import { ObGoalAvatar } from '../../../business-objects/campaign/componetns/campaign-goal/campaign-goal';
import { ObBadge } from '../../../indicators';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import {
  ObMetricCardBase,
  ObMetricCardBaseProps,
} from '../../molecules/ob-metric-card-base/ob-metric-card-base';

export interface ObMetricCardCampaignProps extends ObMetricCardBaseProps {
  campaigns: Array<CampaignResource>;
}
export const ObMetricCardCampaign = ({
  title,
  status,
  context,
}: ObMetricCardCampaignProps) => {
  return (
    <ObMetricCardBase
      title={title}
      status={status}
      context={context}
    >
      <ObCampaignTable />
    </ObMetricCardBase>
  );
};

export type LeadSource = 'phone' | 'form';
export interface ObLeadSourceAvatarProps {
  leadSource: LeadSource;
}

export const ObLeadSourceAvatar = ({ leadSource }: ObLeadSourceAvatarProps) => {
  const getDetailsForLeadSource = (
    leadSource: LeadSource
  ): { label: string; icon: IconSystemKeys } => {
    switch (leadSource) {
      case 'phone':
        return {
          icon: 'phoneLead',
          label: 'Phone',
        };
      case 'form':
        return {
          icon: 'formLead',
          label: 'Form',
        };
    }
  };

  const { icon, label } = getDetailsForLeadSource(leadSource);
  return (
    <div className='flex flex-row gap-1 items-center  justify-start '>
      <ObIcon
        icon={icon}
        size='x-small'
        color='secondary'
      />
      <ObTypography
        variant='body2'
        color='secondary'
        className='text-left'
      >
        {label}
      </ObTypography>
    </div>
  );
};

export interface ObCampaignSummaryCellProps {
  channel: CampaignChannelType;
  goal: CampaignGoal;
  title?: string;
  size?: 'compact' | 'regular';
}
export const ObCampaignSummaryCell = ({
  channel,
  goal,
  title,
  size = 'regular',
}: ObCampaignSummaryCellProps) => {
  return (
    <div className='flex flex-row gap-2 flex-grow items-center'>
      <ObChannelAvatar
        channel={channel}
        size={size === 'compact' ? 'x-small' : 'medium'}
      />
      <div className='flex flex-col justify-center items-start'>
        <ObTypography>{title ?? '---'}</ObTypography>
        {size != 'compact' && (
          <button>
            <ObGoalAvatar goal={goal} />
          </button>
        )}
      </div>
    </div>
  );
};

export interface ObOptimizationCellProps {
  experimentTitle: string;
  insightText: string;
  expectedCompletionTimestamp: string;
}
const ObOptimizationCell = ({
  experimentTitle,
  insightText,
  expectedCompletionTimestamp,
}: ObOptimizationCellProps) => {
  return (
    <div className='flex flex-col'>
      <div className='flex flex-row gap-1 justify-start items-center'>
        <ObTypography variant='body2'>{experimentTitle} </ObTypography>
        <button>
          <ObIcon
            icon='context'
            classNames='mt-[1px]'
            size='x-small'
            color='secondary'
          />
        </button>
      </div>
      <div className='flex flex-col gap-1'>
        <div className='flex flex-row gap-2 justify-start items-center'>
          <ObIcon
            icon='arrowTrendingUp'
            size='x-small'
            color='positive'
          />
          <ObTypography variant='body3'>{insightText}</ObTypography>
        </div>
        <div className='flex flex-row gap-2 justify-start items-center'>
          <ObIcon
            icon='clock'
            size='xx-small'
            color='secondary'
          />
          <ObTypography
            variant='body3'
            color='secondary'
          >
            {timeAgoFromIsoString(expectedCompletionTimestamp)}
          </ObTypography>
        </div>
      </div>
    </div>
  );
};

export const ObCampaignTable = () => {
  const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton1@example.com',
      role: 'Member',
      channel: CampaignChannelType.GOOGLE_SEARCH_ADS,
      goal: CampaignGoal.LEAD_GEN,
    },
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton2@example.com',
      role: 'Member',
      channel: CampaignChannelType.FACEBOOK_ADS,
      goal: CampaignGoal.PURCHASE,
    },
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton3@example.com',
      role: 'Member',
      channel: CampaignChannelType.INSTAGRAM_ADS,
      goal: CampaignGoal.AWARENESS,
    },
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton4@example.com',
      role: 'Member',
      channel: CampaignChannelType.PODCAST_ADS,
      goal: CampaignGoal.AWARENESS,
    },
    // More people...
  ];
  return (
    <div className='-mx-4  sm:-mx-0'>
      <table className='min-w-full divide-y divide-borderDefaultNormalDark'>
        <thead>
          <tr>
            <th
              scope='col'
              className='py-3.5 pl-4 pr-3 text-left  sm:pl-0'
            >
              <ObTypography
                color='tertiary'
                variant='subtitle3'
                className='uppercase'
              >
                Campaign
              </ObTypography>
            </th>
            <th
              scope='col'
              className='hidden px-3 py-3.5 text-left md:table-cell'
            >
              <ObTypography
                color='tertiary'
                variant='subtitle3'
                className='uppercase'
              >
                Optimization
              </ObTypography>
            </th>
            <th
              scope='col'
              className='text-left py-3.5 pl-3 pr-4 sm:pr-0'
            >
              <ObTypography
                color='tertiary'
                variant='subtitle3'
                className='uppercase'
              >
                Status
              </ObTypography>
            </th>
          </tr>
        </thead>
        <tbody className='divide-y divide-borderDefaultNormalDark '>
          {people.map((campaign) => (
            <tr key={campaign.email}>
              <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0'>
                <ObCampaignSummaryCell
                  title='Campaign Title'
                  channel={campaign.channel}
                  goal={campaign.goal}
                />
                <dl className='font-normal md:hidden'>
                  <dt className='sr-only'>Optimization</dt>
                  <dd className='mt-5'>
                    <ObOptimizationCell
                      experimentTitle={'A/B Testing'}
                      insightText={'10% Increase in Leads'}
                      expectedCompletionTimestamp={'2024-04-01T00:00:00Z'}
                    />
                  </dd>
                </dl>
              </td>
              <td className='hidden px-3 py-4 text-sm  md:table-cell'>
                <ObOptimizationCell
                  experimentTitle={'A/B Testing'}
                  insightText={'10% Increase in Leads'}
                  expectedCompletionTimestamp={'2024-04-01T00:00:00Z'}
                />
              </td>

              <td className='flex py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                <ObBadge
                  level={Level.SUCCESS}
                  content={'Active'}
                  iconLeft='nominal'
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
