import { PopoverButton } from '@headlessui/react';
import { FC } from 'react';
import { ObColorPicker } from '../../..';

export interface ObInputColorIndicatorProps {
  /**
   * Open to other fill types in the future such as gradient etc
   */
  fillType: 'solid';
  /**
   * Should be made mandatory in the future
   */
  label?: string;
  value: string | undefined;
  optionalColors?: string[];
  /**
   * Callback function called each time the color picker is closed
   * @returns
   */
  onPickerClosedCallback?: () => any;
  /**
   * Callback function called each time the color picker is opened
   * @returns
   */
  onPickerOpenedCallback?: () => any;
  onChangeCallback?: (value: { hex: string }) => any;
}

export const ObInputColorIndicator: FC<ObInputColorIndicatorProps> = ({
  fillType,
  value,
  label,
  optionalColors,
  onPickerClosedCallback,
  onPickerOpenedCallback,
  onChangeCallback,
}: ObInputColorIndicatorProps) => {
  return (
    <ObColorPicker
      selectedColor={{ hex: value }}
      optionalColors={optionalColors}
      onOpen={onPickerOpenedCallback}
      onClose={onPickerClosedCallback}
      popoverButton={
        <PopoverButton
          data-testid='ob-input-color-button'
          aria-label={`Open Color Picker${
            label != null ? ' for ' + label : ''
          }`}
          className='flex justify-center items-center h-[24px] max-h-[24px] w-[24px] max-w-[24px] rounded-[4px]'
          style={{ ...(fillType === 'solid' && { backgroundColor: value }) }}
        />
      }
      onChange={onChangeCallback}
    />
  );
};
