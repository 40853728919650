import { BaseFormFieldOption } from '@outbound/design-system';
import { useEffect, useState } from 'react';
import { useFetchPlaybookSettings } from '../../../query/playbook/use-playbook-endpoints';
import { useRootStore } from '../../../state/mobx-experiment/use-root-store';

export const useCampaignSetupData = () => {
  const { data: playbookSettings, isLoading: isPlaybookSettingsLoading } =
    useFetchPlaybookSettings();

  const { locationStore } = useRootStore();
  const physicalLocations = locationStore.getPhysicalLocations();

  const serviceAreas = locationStore.getServiceAreas();

  const [personaOptions, setPersonaOptions] = useState<
    Array<BaseFormFieldOption>
  >([]);

  const [serviceOptions, setServiceOptions] = useState<
    Array<BaseFormFieldOption>
  >([]);

  const [locationOptions, setLocationOptions] = useState<
    Array<BaseFormFieldOption>
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isPlaybookSettingsLoading) {
      return;
    }
    const personaOptions =
      playbookSettings?.personas.map((persona) => {
        return {
          key: persona.id,
          displayValue: `${persona.role}`,
          value: persona.id,
        };
      }) ?? [];

    setPersonaOptions(personaOptions);

    const serviceOptions =
      playbookSettings?.services.map((service) => {
        return {
          key: service.id,
          displayValue: service.name,
          value: service.id,
        };
      }) ?? [];
    setServiceOptions(serviceOptions);

    const serviceAreaOptions =
      serviceAreas.map((location) => {
        return {
          key: `SERVICE_AREA#${location.id}`,
          displayValue: `Service Area / ${location.name}`,
          value: `SERVICE_AREA#${location.id}`,
        };
      }) ?? [];

    const physicalLocationOptions =
      physicalLocations.map((location) => {
        return {
          key: `PHYSICAL_LOCATION#${location.id}`,
          displayValue: `Physical Location / ${location.name}`,
          value: `PHYSICAL_LOCATION#${location.id}`,
        };
      }) ?? [];

    setLocationOptions([...serviceAreaOptions, ...physicalLocationOptions]);

    setIsLoading(false);
  }, [
    isPlaybookSettingsLoading,
    physicalLocations,
    playbookSettings,
    serviceAreas,
  ]);

  return {
    personaOptions,
    serviceOptions,
    locationOptions,
    isLoading,
  };
};
