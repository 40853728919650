export const DataFlow = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.21969 1.99984C4.94513 1.22304 4.20431 0.666504 3.3335 0.666504C2.22893 0.666504 1.3335 1.56193 1.3335 2.6665C1.3335 3.77107 2.22893 4.6665 3.3335 4.6665C4.20431 4.6665 4.94513 4.10997 5.21969 3.33317L7.3335 3.33317L7.3335 10.1607C7.33349 10.6973 7.33348 11.1403 7.36296 11.5011C7.39358 11.8758 7.45929 12.2203 7.62415 12.5438C7.87981 13.0456 8.28776 13.4535 8.78952 13.7092C9.11308 13.874 9.45755 13.9398 9.83228 13.9704C10.1011 13.9923 10.4156 13.9979 10.7805 13.9994C11.0549 14.7764 11.7958 15.3332 12.6668 15.3332C13.7714 15.3332 14.6668 14.4377 14.6668 13.3332C14.6668 12.2286 13.7714 11.3332 12.6668 11.3332C11.7962 11.3332 11.0555 11.8894 10.7808 12.6659C10.4285 12.6645 10.161 12.6595 9.94086 12.6415C9.64858 12.6176 9.49911 12.5743 9.39484 12.5212C9.14396 12.3933 8.93999 12.1894 8.81215 11.9385C8.75903 11.8342 8.71574 11.6848 8.69186 11.3925C8.66735 11.0924 8.66683 10.7042 8.66683 10.1332V8.6665L10.7806 8.6665C11.0552 9.4433 11.796 9.99984 12.6668 9.99984C13.7714 9.99984 14.6668 9.10441 14.6668 7.99984C14.6668 6.89527 13.7714 5.99984 12.6668 5.99984C11.796 5.99984 11.0552 6.55638 10.7806 7.33317L8.66683 7.33317V3.33317L10.7806 3.33317C11.0552 4.10997 11.796 4.6665 12.6668 4.6665C13.7714 4.6665 14.6668 3.77107 14.6668 2.6665C14.6668 1.56193 13.7714 0.666504 12.6668 0.666504C11.796 0.666504 11.0552 1.22304 10.7806 1.99984L5.21969 1.99984Z'
        fill='currentColor'
      />
    </svg>
  );
};
