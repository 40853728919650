import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0Extended } from '../../auth/use-auth0-extended';
import { FullPageLoadingSpinner } from '../../components/layouts/full-page-loading-spinner';
import { MY_AVAILABLE_WORKSPACES_ROUTE } from '../../components/navigation/NavigationConstants';
import { useAppNavigation } from '../../hooks/use-app-navigation';
import { router } from '../../main';
import { useFetchMyWorkspaces } from '../../query/my-workspaces/use-my-workspaces-endpoints';
import {
  getInviteSearchParams,
  isInvitePresentInURLSearchParams,
} from '../../services/Auth0Service';
import {
  userIsAMemberOfExactlyOneWorkspace,
  userIsNotAMemberOfAnyWorkspace,
} from '../../services/TenantService';

const AppRedirect = () => {
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    switchOrganization,
  } = useAuth0Extended();

  const { search, pathname } = useLocation();

  const { navigateToWorkspacePicker } = useAppNavigation();

  const { data: myWorkspaces, isLoading: myWorkspacesIsLoading } =
    useFetchMyWorkspaces();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (isInvitePresentInURLSearchParams(queryParams)) {
      console.log('Invite Present in URL Search Params');
      const { organization, invitation } = getInviteSearchParams(queryParams);
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}${pathname}`,
          organization: organization,
          invitation: invitation,
        },
      });
      //No Further Action is Required
      return;
    }

    /*
      When the application is initialized to the root we examine
      the current status of the users authentication and make a decision
      on where to route them. While we are waiting for the information we
      need to make a decision we will show them a full page loading indicator
    */
    if (
      isLoading || //Waiting on Auth0 to Initialize
      (isAuthenticated && myWorkspacesIsLoading) //Authenticated as User, Waiting on Tenants Query
    ) {
      console.log('Required Redirect Data Loading...');
    } else if (
      isAuthenticated &&
      userIsNotAMemberOfAnyWorkspace(myWorkspaces)
    ) {
      console.log(
        'User is Authenticated as a user but is not member on any workspace. Redirecting to My-Workspaces.'
      );
      router.navigate(MY_AVAILABLE_WORKSPACES_ROUTE);
    } else if (isAuthenticated) {
      /*
        In the case that a user has an active Auth0 session (Authenticated) and that session includes
        a tenantId and organization claim we make the determination that the user
        is authenticated to a particular tenant (workspace) the assumption that they want
        to continue on with that tenant and workspace, so we redirect them to the dashboard
      */
      if (userIsAMemberOfExactlyOneWorkspace(myWorkspaces)) {
        console.log(
          'User Only Has One Workspace; Logging In to that Workspace and Navigating ',
          user,
          myWorkspaces?.items[0]
        );

        const workspace = myWorkspaces!.items[0];

        switchOrganization(workspace.auth0OrganizationId, workspace.id);
      } else {
        console.log(
          'User is Authenticated as a user and has many workspaces. Redirecting to Dashboard.'
        );
        /*
          ProtectedWorkspaceRoute will evaluate if needs to go to -> My-Workspaces.
        */
        navigateToWorkspacePicker();
      }
    } else {
      console.log({ origin: window.location.origin, pathname: pathname });
      const redirectUri = `${window.location.origin}${pathname}`;
      console.log(
        `User is not Authenticated. Redirect to Login Page with Redirect ${redirectUri}`
      );

      loginWithRedirect({
        authorizationParams: {
          redirect_uri: redirectUri,
          organization: undefined, //Auth0 will remember the last organization the user was authenticated to unless we explicitly clear it
        },
      });
    }
  }, [
    getAccessTokenSilently,
    navigateToWorkspacePicker,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    myWorkspaces,
    myWorkspacesIsLoading,
    pathname,
    queryParams,
    switchOrganization,
    user,
  ]);

  return <FullPageLoadingSpinner reason='app-redirect-logic-processing' />;
};

export default AppRedirect;
