export const PencilLine = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.50422 1.33739C10.3764 0.465254 11.7904 0.465255 12.6625 1.33739C13.5346 2.20953 13.5346 3.62355 12.6625 4.49569L6.06117 11.097C5.92503 11.2332 5.82201 11.3363 5.70566 11.4266C5.60281 11.5064 5.49345 11.5775 5.37874 11.639C5.24898 11.7087 5.11299 11.761 4.93323 11.83L4.8602 11.8581C4.84193 11.8652 4.8238 11.8722 4.80598 11.879L1.66775 13.086C1.45259 13.1688 1.20888 13.1171 1.04587 12.9541C0.934436 12.8426 0.87501 12.6935 0.875 12.5415C0.874991 12.4712 0.887712 12.4002 0.913899 12.3321L2.16982 9.06671C2.23892 8.88692 2.29119 8.75093 2.36085 8.62115C2.42243 8.50644 2.49349 8.39708 2.57331 8.29423C2.66361 8.17787 2.76665 8.07487 2.90287 7.93872L9.50422 1.33739ZM3.13891 9.7971L2.47396 11.5259L4.2028 10.861L3.13891 9.7971Z'
        fill='currentColor'
      />
      <path
        d='M7.58333 11.6666H12.25C12.5722 11.6666 12.8333 11.9278 12.8333 12.25C12.8333 12.5721 12.5722 12.8333 12.25 12.8333H7.58333C7.26117 12.8333 7 12.5721 7 12.25C7 11.9278 7.26117 11.6666 7.58333 11.6666Z'
        fill='currentColor'
      />
    </svg>
  );
};
