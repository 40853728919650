import { ObInputDatePicker, ObInputLabel } from '../../../../';
import type { CustomFieldComponentsProps } from './custom-field-components';

type DateRangeCustomFieldProps = Omit<
  CustomFieldComponentsProps,
  'fieldType' | 'fieldTypeSettings'
> & {
  dateStartRef: any;
  dateEndRef: any;
  updateFormValues: (arg: any) => void;
};

export const DateRangeCustomField = ({
  size,
  field,
  isDisabled,
  errors,
  id,
  isRequired = false,
  hideLabel = false,
  dateStartRef,
  dateEndRef,
  updateFormValues,
}: DateRangeCustomFieldProps) => {
  const dateLabel = 'Start Date';
  const dateLabelEnd = 'End Date';

  return (
    <div className='sm:flex-row flex flex-col'>
      <div className='sm:pr-2 w-full pr-0'>
        <ObInputLabel
          id={id + '_dateStart'}
          isRequired={isRequired}
          label={dateLabel}
          visuallyHidden={hideLabel}
          className='-mt-1 mb-1'
        />
        <ObInputDatePicker
          size={size}
          inputId={id + '_dateStart'}
          isDisabled={isDisabled}
          hasError={!!errors[id]}
          ref={dateStartRef}
          onDateChangeCb={(time) => {
            updateFormValues({ [id]: { datePart: time } });
            field.onChange('');
          }}
        />
      </div>
      <div className='sm:pl-2 sm:mt-0 w-full pl-0 mt-4'>
        <ObInputLabel
          id={id + '_dateEnd'}
          isRequired={isRequired}
          label={dateLabelEnd}
          visuallyHidden={hideLabel}
          className='-mt-1 mb-1'
        />
        <ObInputDatePicker
          size={size}
          inputId={id + '_dateEnd'}
          isDisabled={isDisabled}
          hasError={!!errors[id]}
          ref={dateEndRef}
          onDateChangeCb={(time) => {
            updateFormValues({ [id]: { datePartEnd: time } });
            field.onChange('');
          }}
        />
      </div>
    </div>
  );
};
