import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObDrawerHeaderProps {
  /**
   * Primary Heading for the Drawer
   */
  title: string;
  /**
   * Optional Secondary Heading for the Drawer
   */
  description?: string;
}

export const ObDrawerHeader: React.FC<ObDrawerHeaderProps> = ({
  title,
  description,
}: ObDrawerHeaderProps) => {
  return (
    <header className='flex dark:bg-bgSurfaceDark justify-between border-b border-b-borderDefaultNormalDark'>
      <div className='py-4 px-6 flex-grow'>
        <ObTypography variant='h4'>{title}</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          {description}
        </ObTypography>
      </div>
    </header>
  );
};
