import { ObToggleSwitch } from '../../../..';
import type { CustomFieldComponentsProps } from './custom-field-components';

type ToggleSwitchCustomFieldProps = Omit<
  CustomFieldComponentsProps,
  'fieldType' | 'fieldTypeSettings' | 'errors'
> & {
  id: string;
  isDisabled?: boolean;
  onChangeCallback: (value: boolean) => void;
  value: boolean;
};

export const ToggleSwitchCustomField = ({
  id,
  value,
  onChangeCallback,
  isDisabled,
}: ToggleSwitchCustomFieldProps) => {
  return (
    <ObToggleSwitch
      id={id}
      size='small'
      disabled={!!isDisabled}
      checked={value}
      onChange={onChangeCallback} //This should be refactored to use a callback to the parent instead of directly calling field.onChange
    />
  );
};
