import { AddressResource } from '@outbound/types';

/**
 * Helper function to check if a string has content
 * @param str
 * @returns
 */
export const stringHasContent = (str: string): boolean => {
  return str !== undefined && str !== null && str.trim() !== '';
};

/**
 * Helper Function to truncate text to a specified length.
 *
 * @param text
 * @param maxLength
 * @returns
 */
export const truncateText = (
  text: string,
  maxLength: number,
  includeEllipsis = true
): { result: string; originalText: string; isTruncated: boolean } => {
  const textGreaterThanMaxLength = text.length > maxLength;
  const truncatedText = textGreaterThanMaxLength
    ? `${text.slice(0, maxLength)}${includeEllipsis ? '...' : ''}`
    : text;

  return {
    originalText: text,
    result: truncatedText,
    isTruncated: textGreaterThanMaxLength,
  };
};

/**
 * Helper function to check if a word starts with a vowel sound.
 * This is not a perfect solution, but it's a good enough approximation for most cases.
 * Used by the article functions to determine if "a" or "an" should be used.
 * @param word
 * @returns
 */
export const wordStartsWithVowelSound = (word: string): boolean => {
  // Regex for words that start with a vowel sound
  const vowelSoundRegex = /^[aeiou]/i;
  // Additional regex to catch words like "hour" where 'h' is silent
  const silentHRegex = /^h(on|ou|onest|eur)/i;
  // Regex for exceptions: words that start with a vowel letter but not a vowel sound
  const exceptionRegex = /^(uni[^aeiou]|eu[^aeiou])/i;
  // List of specific exception words that don't follow the general rule
  const exceptionsList = ['hour', 'honest', 'honor', 'heir', 'herb', 'x-ray']; // Add more as needed

  // First, check if the word is in the exceptions list and directly return true if so
  if (exceptionsList.some((exception) => word.toLowerCase() === exception)) {
    return true;
  }

  // Adjust to include or exclude specific cases as needed
  return (
    (vowelSoundRegex.test(word) || silentHRegex.test(word)) &&
    !exceptionRegex.test(word)
  );
};

/**
 * Determine the correct article to use for a word. (A or An based on the word's pronunciation)
 * @param word
 * @returns
 */
export const determineArticleForWord = (word: string): string => {
  return wordStartsWithVowelSound(word) ? 'an' : 'a';
};

/**
 * Create a grammatically correct list of words with "and" as the conjunction.
 * @param words
 * @returns
 */
export const createAGrammaticallyCorrectListWithAnd = (
  words: string[]
): string => {
  return createAGrammaticallyCorrectListWithoutOxfordComma(words, 'and');
};

/**
 * Create a grammatically correct list of words with "or" as the conjunction.
 * @param words
 * @returns
 */
export const createAGrammaticallyCorrectListWithOr = (
  words: string[]
): string => {
  return createAGrammaticallyCorrectListWithoutOxfordComma(words, 'or');
};

/**
 * Internal helper function to create a grammatically correct list of words with a conjunction. (And, Or, etc.)
 * @param words
 * @param conjunction
 * @returns
 */
const createAGrammaticallyCorrectListWithoutOxfordComma = (
  words: string[],
  conjunction: string
): string => {
  // If the list has only one item, just return that item.
  if (words.length === 1) {
    return words[0];
  }

  // If the list has two items, return them joined by the conjunction.
  if (words.length === 2) {
    return words.join(` ${conjunction.trim()} `);
  }

  // For lists of three or more items, insert the conjunction before the last item without a comma.
  if (words.length > 2) {
    let lastTwoWords = words.slice(-2);
    let beginningOfList = words.slice(0, -2);
    return `${beginningOfList.join(', ')}, ${
      lastTwoWords[0]
    } ${conjunction.trim()} ${lastTwoWords[1]}`;
  }

  // Default return to keep typescript happy. (though we've handled all logical paths above).
  return words.join(', ');
};

export const addressResourceToString = (address: AddressResource) => {
  return address?.street1
    ? `${address?.street1}, ${address?.city} ${address?.state}. ${address?.postalCode}`
    : '';
};
