import { maskitoNumberOptionsGenerator } from '@maskito/kit';

export const moneyMask = maskitoNumberOptionsGenerator({
  decimalZeroPadding: true,
  precision: 2,
  decimalSeparator: '.',
  thousandSeparator: ',',
  min: 0,
  prefix: '$',
});
