import { ObDrawerFooter } from '@outbound/design-system/src/components/molecules/ob-drawer-footer/ob-drawer-footer';

export interface PlaybookDrawerContentProps {
  children?: React.ReactNode;
  showFooter?: boolean;
  primaryActionLabel: string;
  primaryActionCallback: () => Promise<void>;
  secondaryActionLabel: string;
  secondaryActionCallback: () => Promise<void>;
}
export const PlaybookDrawerContent = ({
  children,
  showFooter = true,
  primaryActionCallback,
  primaryActionLabel,
  secondaryActionCallback,
  secondaryActionLabel,
}: PlaybookDrawerContentProps) => {
  return (
    <aside className='flex flex-col justify-between flex-1 h-full '>
      {/* ADD Margin Bottom on Footer Show to Move elements fixed to the bottom
          of the page so they are not covered (When there is room) */}
      <div
        data-footer-visible={showFooter}
        className={`px-6 pb-6 flex flex-col transition-all duration-300 h-full overflow-scroll ${
          showFooter ? ' mb-[72px] ' : ' mb-0 '
        }`}
      >
        {children}
      </div>
      {/* <div className='min-h-[88px] w-full'></div> */}
      {/* ANIMATIONS HERE TO SLIDE FOOTER IN FROM BOTTOM WHEN IT IS VISIBLE */}
      <div
        className={`absolute bottom-0 transition-all duration-300 ease-in-out w-full  ${
          showFooter ? 'opacity-100 ' : 'opacity-0 translate-y-[80px]'
        }`}
      >
        <ObDrawerFooter
          primaryActionLabel={primaryActionLabel}
          primaryActionCallback={primaryActionCallback}
          secondaryActionLabel={secondaryActionLabel}
          secondaryActionCallback={secondaryActionCallback}
        ></ObDrawerFooter>
      </div>
    </aside>
  );
};
