import {
  ObDeleteCard,
  ObFormRenderer,
  ObLoadingSpinner,
} from '@outbound/design-system';
import { useCallback, useEffect } from 'react';

import {
  useArchiveSubServiceById,
  useFetchSubServiceById,
  usePatchSubServiceById,
} from '../../../../../../../query/playbook/use-playbook-settings-service-endpoints';
import { PlaybookDrawerContent } from '../../../_common/playbook-drawer-content';
import { useDrawerEditForm } from '../../../_common/use-drawer-edit-form';
import {
  mapApiResourceToFormValues,
  mapFormValuesToApiResource,
} from './forms/sub-service-form-utils';
import {
  SUB_SERVICE_FORM_DEF,
  useFormWithAssetManagerFileUploader,
} from './forms/sub-service-form.def';

/**
 * Props that are expected when the drawer is opened
 */
export interface SubServiceEditDrawerProps {
  serviceId: string;
  subServiceId: string;
}

export const SubServiceEditDrawer: React.FC<SubServiceEditDrawerProps> = ({
  serviceId,
  subServiceId,
}: SubServiceEditDrawerProps) => {
  const {
    getRegisteredFormById,
    initialValues,
    setInitialValues,
    onFormDirtyChange,
    isInitialValuesFetched,
    setIsInitialValuesFetched,
    areChangesUnsaved,
    notificationService,
    drawerService,
  } = useDrawerEditForm();

  const { formDefinitionWithAssetManager } =
    useFormWithAssetManagerFileUploader(SUB_SERVICE_FORM_DEF);

  const { data, isFetched } = useFetchSubServiceById(serviceId, subServiceId);

  const { mutateAsync: archiveSubService } = useArchiveSubServiceById(
    serviceId,
    subServiceId
  );

  const { mutateAsync: patchSubService } = usePatchSubServiceById(
    serviceId,
    subServiceId
  );

  useEffect(() => {
    if (isFetched && !isInitialValuesFetched) {
      if (data == null) {
        throw new Error('Subservice not found');
      } else {
        setInitialValues(mapApiResourceToFormValues(data));
        setIsInitialValuesFetched(true);
      }
    }
  }, [
    data,
    isFetched,
    isInitialValuesFetched,
    setInitialValues,
    setIsInitialValuesFetched,
  ]);

  useEffect(() => {
    if (isInitialValuesFetched && data != null) {
      setInitialValues(mapApiResourceToFormValues(data));
    }
  }, [data, setInitialValues, isInitialValuesFetched]);

  const handleUpdateService = useCallback(
    async (values: any) => {
      const result = await patchSubService(mapFormValuesToApiResource(values));

      return result;
    },
    [patchSubService]
  );

  return (
    <PlaybookDrawerContent
      primaryActionLabel={'Save'}
      primaryActionCallback={async () => {
        return getRegisteredFormById(SUB_SERVICE_FORM_DEF.id)?.submitFunction();
      }}
      secondaryActionLabel={'Discard'}
      secondaryActionCallback={async () => {
        return getRegisteredFormById(
          SUB_SERVICE_FORM_DEF.id
        )?.discardChangesFunction();
      }}
      showFooter={areChangesUnsaved}
    >
      {!isInitialValuesFetched && (
        <div className='flex flex-1 justify-center items-center h-full'>
          <ObLoadingSpinner includeBrand={true} />
        </div>
      )}
      {isInitialValuesFetched && (
        <div className='flex flex-col flex-1 gap-4'>
          <ObFormRenderer
            formDefinition={formDefinitionWithAssetManager}
            hideSubmitButton={true}
            defaultValues={initialValues}
            onSubmitCallback={handleUpdateService}
            onFormDirtyChangeCallback={onFormDirtyChange}
          />

          <ObDeleteCard
            typeToConfirmValue={initialValues.name}
            isLoading={false}
            cardTitle={'Archive Subservice'}
            cardBody={
              'Archiving a subservice will make it unavailable for selection in the future. If a campaign is currently using this subservice, it will not be affected.'
            }
            cardButtonLabel={'Archive'}
            confirmationDialogTitle={'Archive Subservice'}
            confirmationDialogBody={
              'This action is permanent and cannot be undone.'
            }
            typeToConfirmInstructions={
              'Type the name of the subservice to confirm'
            }
            onDeleteConfirmedCallback={async () => {
              try {
                await archiveSubService();
                drawerService.popDrawer(true); //Cose drawer after archive is complete
              } catch (e) {
                notificationService.pushNotification({
                  title: 'Error',
                  body: 'Failed to archive subservice',
                });
              }
            }}
          />
        </div>
      )}
    </PlaybookDrawerContent>
  );
};
