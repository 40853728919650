import { FullPageAction, ObLoadingSpinner } from '@outbound/design-system';
import { PostWorkspaceRequestResource } from '@outbound/types';
import { useMemo, useState } from 'react';
import { useAuth0Extended } from '../../auth/use-auth0-extended';
import { createWorkspaceForm } from '../../components/forms/workspace-creation-form';
import { useCreateWorkspaceForUserMutation } from '../../query/workspace/use-workspace-endpoints';
import { legalLinks } from './signup-footer-links';

export const CreateWorkspacePage = () => {
  const heading = 'Create your account';
  const subHeading = undefined;

  const successHeading = 'We sent you an email';
  const successSubHeading =
    'Check your email and use the link to verify and continue setting up your account.';

  const errorHeading = 'Unable to create your account';
  const errorSubHeading =
    'We were unable to setup a workspace for your business. Please try again later.';

  const { isAuthenticated, isLoading, switchOrganization } = useAuth0Extended();

  const [userTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/New_York'
  );

  const { mutateAsync } = useCreateWorkspaceForUserMutation();

  const submitNewWorkspaceForm = async (
    values: PostWorkspaceRequestResource
  ) => {
    const workspace = await mutateAsync({ ...values, timezone: userTimezone });
    if (isAuthenticated) {
      /**
       * Since we are authenticated we don't need to confirm the email address so we will redirect them
       */
      await switchOrganization(workspace.auth0OrganizationId, workspace.id);
    }
    return workspace;
  };

  const workspaceForm = useMemo(
    () => createWorkspaceForm(isAuthenticated),
    [isAuthenticated]
  );

  if (isLoading) {
    return (
      <div className='h-screen flex items-center w-full justify-center'>
        <ObLoadingSpinner includeBrand={true} />
      </div>
    );
  }
  return (
    <FullPageAction
      heading={heading}
      subHeading={subHeading}
      form={workspaceForm}
      onSubmitCallback={submitNewWorkspaceForm}
      footerLinks={legalLinks}
      successHeading={successHeading}
      successSubHeading={successSubHeading}
      errorHeading={errorHeading}
      errorSubHeading={errorSubHeading}
    />
  );
};
