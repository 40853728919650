import { FC, ReactNode } from 'react';
import { AvatarIconStyleProps } from '../../../avatars/avatar.types';
import { ObIconAvatar } from '../../../avatars/ob-icon-avatar/ob-icon-avatar';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { IconSystemKeys } from '../../../tokens/icons/icons';

/**
 * The public props of the CardHeader component
 */
export interface ObCardHeaderBaseProps
  extends Omit<AvatarIconStyleProps, 'icon'> {
  /**
   * The heading that will appear on the top of the card
   */
  heading: string;
  /**
   * The headingAs prop allows you to change the heading element type
   */
  headingAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  afterTitleContent?: ReactNode;

  rightContent?: ReactNode;

  /**Optional Icon */
  icon?: IconSystemKeys;
}

export const ObCardHeaderBase: FC<ObCardHeaderBaseProps> = ({
  heading,
  icon,
  headingAs = 'h5',
  color,
  afterTitleContent,
  rightContent,
}: ObCardHeaderBaseProps) => {
  return (
    // Max height is set to 40px to prevent the header from expanding the card. We assume the parent will ensure the right amount of spacing for the header.
    <header className='flex flex-grow justify-between self-stretch items-start gap-3 max-h-[40px]'>
      <div className='flex flex-row flex-nowrap  gap-3'>
        {icon && (
          <ObIconAvatar
            icon={icon}
            color={color}
            size='small'
          />
        )}
        <div className='flex flex-nowrap justify-start '>
          <div className='flex-shrink-0'>
            <ObTypography
              className='line-clamp-2 text-left inline-block'
              variant='h5'
              as={headingAs}
            >
              {heading}
            </ObTypography>
          </div>
          <div>{afterTitleContent}</div>
        </div>
      </div>
      <div>{rightContent}</div>
    </header>
  );
};
