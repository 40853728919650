import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

interface Configuration {
  theme?: 'dark' | 'light';
  direction: 'ltr' | 'rtl';

  language: 'en' | 'es';
}
const CONFIGURATION_KEY = 'configuration';
const defaultConfiguration: Configuration = {
  theme:
    window != null &&
    window.matchMedia != null &&
    window.matchMedia('(prefers-color-scheme: light)').matches
      ? 'light'
      : 'dark',
  direction: 'ltr',
  language: 'en',
};

export const restoreConfiguration = (): Configuration | null => {
  let configuration = null;
  try {
    const storedData = window.localStorage.getItem(CONFIGURATION_KEY);

    if (storedData) {
      configuration = JSON.parse(storedData);
    } else {
      configuration = defaultConfiguration;
    }
  } catch (err) {
    configuration = defaultConfiguration;
  }
  return configuration;
};

export const writeConfiguration = (configuration: Configuration): void => {
  window.localStorage.setItem(CONFIGURATION_KEY, JSON.stringify(configuration));
};

export interface ConfigurationContextValue {
  configuration: Configuration;
  saveConfiguration: (update: Configuration) => void;
}

export const ConfigurationContext = createContext<ConfigurationContextValue>({
  configuration: defaultConfiguration,
  saveConfiguration: () => {},
});

interface ConfigurationProviderProps {
  children?: ReactNode;
}

export const ConfigurationProvider: FC<ConfigurationProviderProps> = ({
  children,
}) => {
  const [configuration, setConfiguration] =
    useState<Configuration>(defaultConfiguration);
  useEffect(() => {
    const restoredConfiguration = restoreConfiguration();

    if (restoredConfiguration) {
      setConfiguration(restoredConfiguration);
    }
  }, []);

  const saveConfiguration = (updatedConfiguration: Configuration): void => {
    setConfiguration(updatedConfiguration);
    writeConfiguration(updatedConfiguration);
  };
  const config = useMemo(
    () => ({
      configuration,
      saveConfiguration,
    }),
    [configuration]
  );

  return (
    <ConfigurationContext.Provider value={config}>
      {children}
    </ConfigurationContext.Provider>
  );
};

ConfigurationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ConfigurationConsumer = ConfigurationContext.Consumer;

export const useConfiguration = () => useContext(ConfigurationContext);
