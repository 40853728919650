import { cva } from 'class-variance-authority';

export const sizeClasses = cva('p-0', {
  variants: {
    sideIcon: {
      true: [],
      false: [],
    },
    /**
     * Expected sizes from the design system are: small: 34px, medium: 42px, large: 50px (Including border + padding)
     * https://www.figma.com/design/l1PsB4JNyhbHbnXedIkHJ4/Outbound-DS?node-id=335-13001&m=dev
     */
    size: {
      small: ['px-3 py-[4px]'],
      medium: ['px-4 py-[8px]'],
      large: ['px-4 py-[12px]'],
    },
  },
  compoundVariants: [
    {
      size: 'small',
      sideIcon: true,
      className: ['w-[calc(100%-28px)]'],
    },
    {
      size: 'small',
      sideIcon: false,
      className: ['w-full'],
    },
    {
      size: 'medium',
      sideIcon: true,
      className: ['w-[calc(100%-32px)]'],
    },
    {
      size: 'medium',
      sideIcon: false,
      className: ['w-full'],
    },
    {
      size: 'large',
      sideIcon: true,
      className: ['w-[calc(100%-36px)]'],
    },
    {
      size: 'large',
      sideIcon: false,
      className: ['w-full'],
    },
  ],
  defaultVariants: {
    size: 'large',
  },
});
