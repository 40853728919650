import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface NotificationContent {
  /**
   * The title of the notification appears at Top of the notification in a primary font and color
   * The default title of the Notification to use when no progress titles are provided
   */
  title?: string;
  /**
   * Title shown when the progress promise has not yet completed (Resolved or Rejected)
   * * Overwrites the default title if provided
   */
  titleInProgress?: string;
  /**
   * Title shown when the progress promise completes by resolving
   * Overwrites the default title if provided
   */
  titleResolved?: string;
  /**
   * Title shown when the progress promise completes by rejecting
   * Overwrites the default title if provided
   */
  titleRejected?: string;

  /**
   * The body appears under the Notification Title in a secondary font and color
   * The default body of the Notification to use when no progress titles are provided.
   */
  body?: string;
  /**
   * Body shown when the progress promise has not yet completed (Resolved or Rejected)
   * Overwrites the default body if provided
   */
  bodyInProgress?: string;
  /**
   * Body shown when the progress promise completes by resolving
   * Overwrites the default body if provided
   */
  bodyResolved?: string;
  /**
   * Body shown when the progress promise completes by rejecting
   * Overwrites the default body if provided
   */
  bodyRejected?: string;

  /**
   * Promise provided that communicates the status of the notification for async actions such as saving or creating
   */
  progressPromise?: Promise<any>;
}

export interface ObNotificationProps extends NotificationContent {
  id: string;
  show: boolean;
  onCloseNotificationCallback: (notificationId: string) => any;
}

export type PromiseStatus = 'in-progress' | 'resolved' | 'rejected';

/**
 * Initial implementation of the Notification or Toast Component for the Outbound Design System.
 * This initial version is decently bare-bones it will be updated as needed.
 * @returns
 */
export const ObNotification = ({
  id,
  title,
  titleInProgress,
  titleRejected,
  titleResolved,
  body,
  bodyInProgress,
  bodyResolved,
  bodyRejected,
  show,
  onCloseNotificationCallback,
  progressPromise,
}: ObNotificationProps) => {
  const [promiseStatus, setPromiseStatus] = useState<PromiseStatus>(
    progressPromise == null ? 'resolved' : 'in-progress'
  );

  const getTitle = (status: PromiseStatus) => {
    if (status === 'in-progress') {
      return titleInProgress || title;
    } else if (status === 'resolved') {
      return titleResolved || title;
    } else if (status === 'rejected') {
      return titleRejected || title;
    } else {
      return title;
    }
  };

  const getBody = (status: PromiseStatus) => {
    if (status === 'in-progress') {
      return bodyInProgress || body;
    } else if (status === 'resolved') {
      return bodyResolved || body;
    } else if (status === 'rejected') {
      return bodyRejected || body;
    } else {
      return body;
    }
  };

  useEffect(() => {
    if (progressPromise != null) {
      setPromiseStatus('in-progress');
      progressPromise.then(
        () => setPromiseStatus('resolved'),
        () => setPromiseStatus('rejected')
      );
    }
  }, [progressPromise]);

  return (
    <Transition
      show={show}
      appear={true}
      unmount={false}
      as={Fragment}
      enter='transform ease-out duration-300 transition'
      enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
      enterTo='translate-y-0 opacity-100 sm:translate-x-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg dark:bg-bgSurfaceDark shadow-lg dark:border-borderDefaultNormalDark border-borderDefaultNormalLight border '>
        <div className='p-2'>
          <div className='flex items-center gap-4'>
            <div className='flex-shrink-0'>
              {promiseStatus === 'resolved' && (
                <ObIcon
                  icon='check'
                  size='small'
                  color='positive'
                />
              )}
              {promiseStatus === 'in-progress' && (
                <ObIcon
                  icon='loadingSpinner'
                  size='small'
                  classNames='animate-spin text-contentPrimaryDark'
                />
              )}
              {promiseStatus === 'rejected' && (
                <ObIcon
                  icon='close'
                  size='small'
                  color='negative'
                />
              )}
            </div>
            <div className=' w-0 flex-1 pt-0.5'>
              <ObTypography
                variant='subtitle1'
                className='line-clamp-2'
              >
                {getTitle(promiseStatus)}
              </ObTypography>
              <ObTypography
                variant='body2'
                color='secondary'
                className='line-clamp-2'
              >
                {getBody(promiseStatus)}
              </ObTypography>
            </div>
            <div className='flex items-start flex-shrink-0 justify-end h-full'>
              <button
                type='button'
                aria-label='close'
                disabled={promiseStatus === 'in-progress'}
                className='inline-flex rounded-md dark:bg-bgSurface2Dark p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-actionPrimaryDark focus:ring-offset-2 disabled:opacity-0 transition-opacity opacity-100 duration-300'
                onClick={() => onCloseNotificationCallback(id)}
              >
                <span className='sr-only'>Close</span>
                <ObIcon
                  size='x-small'
                  icon='close'
                  aria-hidden='true'
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
