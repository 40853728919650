import {
  ObAvatar,
  ObBadge,
  ObButton,
  ObIcon,
  ObSkeleton,
  ObTypography,
} from '@outbound/design-system';
import { MarketingPlanCard } from '../common/marketing-plan-card';

export interface BrandMetaCardProps {
  companyName: string;
  industryName?: string;
  companyIcon: string;
  website: string;
  isRetailOnline: boolean;
  isRetailInStore: boolean;
  isServiceOnline: boolean;
  isServiceInCustomerLocation: boolean;
  isServiceInBusinessLocation: boolean;
  onEditButtonClickedCallback: () => any | void;
  isLoading: boolean;
}
export const BrandMetaCard = ({
  companyName,
  industryName,
  website,
  isRetailOnline,
  isRetailInStore,
  isServiceOnline,
  isServiceInCustomerLocation,
  isServiceInBusinessLocation,
  onEditButtonClickedCallback,
  isLoading,
}: BrandMetaCardProps) => {
  return (
    <MarketingPlanCard>
      <header className='flex flex-row justify-between items-center gap-2'>
        <div className='header-left flex flex-row gap-2 justify-start items-center w-full'>
          <ObAvatar
            size={'medium'}
            fallbackText={companyName}
            shape='rounded'
            loading={isLoading}
          />
          {!isLoading ? (
            <ObTypography
              variant='h2'
              className='flex-grow'
            >
              {companyName || 'Company Name Not Provided'}
            </ObTypography>
          ) : (
            <ObTypography
              variant='h2'
              className='flex-grow'
            >
              <ObSkeleton
                variant='text'
                width='88%'
              ></ObSkeleton>
            </ObTypography>
          )}
        </div>
        <div className='header-right'>
          <div className='hidden sm:block'>
            <ObButton
              label='Edit'
              disabled={isLoading}
              size='medium'
              variant='outline'
              onClick={onEditButtonClickedCallback}
            />
          </div>
          <div className='sm:hidden'>
            <ObButton
              label='Edit'
              buttonType='icon'
              size='small'
              iconLeft='pencil01'
              variant='outline'
            />
          </div>
        </div>
      </header>
      <section className='flex flex-col gap-3'>
        {/* INDUSTRY ROW  */}
        <div className='flex flex-row gap-2 justify-start items-center min-h-[26px]'>
          <ObTypography variant='h5'>Industry</ObTypography>
          {isLoading && (
            <ObTypography
              variant='subtitle1'
              className='flex-grow'
            >
              <ObSkeleton
                width='44%'
                variant='text'
              ></ObSkeleton>
            </ObTypography>
          )}
          {!isLoading && industryName && <ObBadge content={industryName} />}
          {!isLoading && !industryName && (
            <ObTypography
              variant='subtitle1'
              color='secondary'
            >
              Not Provided
            </ObTypography>
          )}
        </div>
        {/* BUSINESS TYPE ROW  */}
        <div className='flex flex-row gap-2 justify-start items-center flex-wrap  min-h-[26px]'>
          <ObTypography variant='h5'>Business Activities</ObTypography>
          {isLoading && (
            <ObTypography
              variant='subtitle1'
              className='flex-grow'
            >
              <ObSkeleton
                width='76%'
                variant='text'
              ></ObSkeleton>
            </ObTypography>
          )}
          {!isLoading && isRetailInStore && (
            <ObBadge content='Brick & Mortar Retail' />
          )}
          {!isLoading && isRetailOnline && <ObBadge content='E-Commerce' />}
          {!isLoading && isServiceInBusinessLocation && (
            <ObBadge content='Service at Business Location' />
          )}
          {!isLoading && isServiceInCustomerLocation && (
            <ObBadge content='Service at Customer Location' />
          )}
          {!isLoading && isServiceOnline && (
            <ObBadge content='Online Service' />
          )}
          {!isLoading &&
            !isRetailInStore &&
            !isRetailOnline &&
            !isServiceInBusinessLocation &&
            !isServiceInCustomerLocation &&
            !isServiceOnline && (
              <ObTypography
                variant='subtitle1'
                color='secondary'
              >
                Not Provided
              </ObTypography>
            )}
        </div>
        {/* WEBSITE ROW  */}
        <div className='flex flex-row gap-2 justify-start items-center'>
          <ObTypography variant='h5'>Website</ObTypography>
          {isLoading && (
            <ObTypography
              variant='subtitle1'
              className='flex-grow'
            >
              <ObSkeleton
                width='64%'
                variant='text'
              ></ObSkeleton>
            </ObTypography>
          )}
          {website && (
            <>
              <ObTypography
                variant='subtitle1'
                color='primaryV2'
              >
                {website}
              </ObTypography>
              <ObIcon
                icon='externalLink'
                color='tertiary'
                size='x-small'
              />
            </>
          )}
          {!website && !isLoading && (
            <ObTypography
              variant='subtitle1'
              color='secondary'
            >
              Not Provided
            </ObTypography>
          )}
        </div>
      </section>
    </MarketingPlanCard>
  );
};
