export interface PlaybookCardGridProps {
  children?: React.ReactNode;
}

export const PlaybookCardGrid = ({ children }: PlaybookCardGridProps) => {
  return (
    <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 w-full'>
      {children}
    </section>
  );
};
