import { WorkspaceResource } from '@outbound/types';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Workspace from './workspace';
import WorkspaceTransformer from './workspace-transformer';

class WorkspaceStore extends BaseStore<Workspace> {
  private readonly _workspaceTransformer: WorkspaceTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'workspace', rootStore.transport.workspaceTransport);
    this._workspaceTransformer = new WorkspaceTransformer(rootStore);
    this.rootStore.transport.workspaceTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
  }

  protected requestLoadModelFromServer(id: string): void {
    this.rootStore.transport.workspaceTransport.requestResourceById(id);
  }

  private handleServerUpdate = (
    id: string,
    resource: WorkspaceResource | null
  ): void => {
    this.handleServerUpdateGeneric(id, resource, this._workspaceTransformer);
  };
}

export default WorkspaceStore;
