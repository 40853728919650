import {
  GOOGLE_SEARCH_AD_LIMITS,
  SettingDefinitionResource,
  TemplateMedium,
  TemplateSchemaResource,
} from '@outbound/types';

const BASE_GOOGLE_SEARCH_HEADING_ID = 'google-search-heading';
const BASE_GOOGLE_SEARCH_HEADING_LABEL = 'Heading Option';

const BASE_GOOGLE_SEARCH_DESCRIPTION_ID = 'google-search-description';
const BASE_GOOGLE_SEARCH_DESCRIPTION_LABEL = 'Heading Option';

/**
 * Hardcoded Template for Google Responsive Search Ads.
 * https://support.google.com/google-ads/answer/7684791?hl=en&ref_topic=10284269&sjid=3308138028505606508-NA
 * https://support.google.com/google-ads/answer/9438230?hl=en&ref_topic=10284270&sjid=3308138028505606508-NA#zippy=%2Ctips-for-entering-your-headlines
 * This needs to be moved to the backend at some point.
 *
 * Responsive search ads will show up to three 30-character headlines,
 * a display URL with two 15-character display paths,
 * and up to two 90-character descriptions
 *
 * You can also apply ad extensions to them,
 * which expands their presence on the Search Engine Results Page even more.
 */
export const googleSearchAdTemplate: TemplateSchemaResource = {
  id: '20d44b5a-7770-450c-a30e-19fe5f680ba5',
  name: 'Google Search Ads',
  medium: TemplateMedium.GOOGLE_SEARCH,
  generativeAiPrompts: [
    {
      promptTemplateId: 'CREATIVE_GOOGLE_SEARCH_AD_GROUP',
    },
  ],
  settingDefinitions: [
    //Create 15 Headline Option Fields
    ...Array.from(Array(15).keys()).map((i): SettingDefinitionResource => {
      return {
        id: `${BASE_GOOGLE_SEARCH_HEADING_ID}-${i + 1}`,
        label: `${BASE_GOOGLE_SEARCH_HEADING_LABEL} ${i + 1}`,
        defaultValueSource: [
          {
            source: 'context-json-path',
            value: `$.completions.template.CREATIVE_GOOGLE_SEARCH_AD_GROUP.ads[0].headlines[${i}].value`, //Source the headline from Generative AI
          },
          {
            source: 'string-literal',
            value: `Google Search Ad Headline ${i + 1}`,
          },
        ],
        type: 'text',
        sectionAssignment: ['HEADINGS'],
        publishingValidation: {
          isRequired: true,
          maxLength: GOOGLE_SEARCH_AD_LIMITS.HEADING_MAX_LENGTH,
          minLength: 8,
        },
      };
    }),
    //Create 4 Description Option Fields
    ...Array.from(Array(4).keys()).map((i): SettingDefinitionResource => {
      return {
        id: `${BASE_GOOGLE_SEARCH_DESCRIPTION_ID}-${i + 1}`,
        label: `${BASE_GOOGLE_SEARCH_DESCRIPTION_LABEL} ${i + 1}`,
        defaultValueSource: [
          {
            source: 'context-json-path',
            value: `$.completions.template.CREATIVE_GOOGLE_SEARCH_AD_GROUP.ads[0].descriptions[${i}].value`, //Source the description from Generative AI
          },
          {
            source: 'string-literal',
            value: `Google Search Ad Description ${
              i + 1
            }. Replace this with a compelling description.`,
          },
        ],
        type: 'text',
        sectionAssignment: ['DESCRIPTIONS'],
        publishingValidation: {
          isRequired: true,
          minLength: 8,
          maxLength: GOOGLE_SEARCH_AD_LIMITS.DESCRIPTION_MAX_LENGTH,
        },
      };
    }),
  ],
  settingSections: [
    {
      id: 'HEADINGS',
      heading: 'Head Lines',
      subSections: [],
    },
    {
      id: 'DESCRIPTIONS',
      heading: 'Descriptions',
      subSections: [],
    },
  ],
  renderComponent: 'GoogleSearch01',
};
