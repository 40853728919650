import React, { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  console.error(error);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
        }}
      >
        <h2>An Error Occurred While Trying to Render the Page</h2>
        <p>
          {error.name} | {error.message}
        </p>
      </div>
    </>
  );
};
