import { RadioGroup } from '@headlessui/react';
import { PersonaResource } from '@outbound/types';
import { FC } from 'react';
import { StateManagedByParentInput } from '../../../../base-component-props.type';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';
import { ObRadioGroupItem } from '../../../../components/molecules/ob-radio-group/components/ob-radio-group-item';

export interface WhoRadioGroupProps extends StateManagedByParentInput<string> {
  personasFromPlaybook: Array<PersonaResource>;
  onConfirmWhatClickedCallback: () => void;
  onAddNewPersonaClickedCallback: () => void;
  /**
   * Whether the confirm button should be hidden
   */
  hideConfirmButton: boolean;
}

export const WhoRadioGroup: FC<WhoRadioGroupProps> = ({
  personasFromPlaybook = [],
  onAddNewPersonaClickedCallback,
  onConfirmWhatClickedCallback,
  value,
  onValueChangedCallback,
  hideConfirmButton = false,
}) => {
  return (
    <div className='flex flex-col gap-3'>
      <RadioGroup
        value={value}
        onChange={(value) => onValueChangedCallback(value, undefined)}
      >
        <div className='flex flex-col gap-2'>
          {personasFromPlaybook.map((persona) => (
            <ObRadioGroupItem
              key={persona.id}
              value={persona.id}
              label={persona.role}
              description={''}
              isDisabled={false}
              size={'small'}
              isLoading={false}
            />
          ))}
          <div className='pl-[2px]'>
            <ObButton
              variant='ghost'
              color='primary'
              iconLeft='plus'
              label='Someone Else'
              fullWidth='never'
              onClick={onAddNewPersonaClickedCallback}
            />
          </div>
        </div>
      </RadioGroup>
      {!hideConfirmButton && (
        <div className='pl-2'>
          <ObButton
            variant='outline'
            label='Confirm'
            size='medium'
            onClick={onConfirmWhatClickedCallback}
          />
        </div>
      )}
    </div>
  );
};
