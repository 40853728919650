import { cva, cx } from 'class-variance-authority';
import { FC, ReactNode } from 'react';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

export interface ObMenuItemProps {
  leftIcon?: IconSystemKeys;
  children: ReactNode;
  isActive: boolean;
  disabled?: boolean;
  isProcessing?: boolean;
  size?: 'small' | 'medium';
  kind?: 'primary' | 'tertiary';
  onClickCallback?: () => any;
}

/**
 * @param content
 * @constructor
 */
export const ObMenuItem: FC<ObMenuItemProps> = ({
  leftIcon,
  children,
  disabled = false,
  isProcessing = false,
  isActive,
  size = 'medium',
  kind = 'primary',
  onClickCallback,
}: ObMenuItemProps) => {
  const kindClasses = cva('rounded', {
    variants: {
      isActive: {
        true: [],
        false: [],
      },
      kind: {
        primary: [],
        tertiary: ['  hover:dark:bg-whiteAlpha-2'],
      },
    },
    compoundVariants: [
      {
        isActive: false,
        kind: 'tertiary',
        className: 'dark:text-contentSecondaryDark',
      },
      {
        isActive: true,
        kind: 'tertiary',
        className: 'dark:text-contentPrimaryDark',
      },
    ],
    defaultVariants: {
      kind: 'primary',
    },
  });

  const sizeClasses = cva('px-3 py-1.5', {
    variants: {
      size: {
        small: ['text-sm leading-[22px] py-[6px]'],
        medium: ['text-md'],
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  });

  return (
    <li
      role='menuitem'
      aria-disabled={disabled}
      className={cx(
        'border-box flex justify-start items-center transition-colors duration-300 cursor-pointer',
        kindClasses({ kind, isActive }),
        sizeClasses({ size })
      )}
      onClick={onClickCallback}
      onKeyDown={onClickCallback}
    >
      <div
        style={{ opacity: isActive ? 1 : 0 }}
        className='w-[4px] h-[24px] bg-actionPrimaryDark flex mr-2 rounded transition-opacity duration-300 ease-in-out'
      />
      {leftIcon && (
        <ObIcon
          icon={isProcessing ? 'loadingSpinner' : leftIcon}
          size={'small'}
          classNames={cx(
            'text-contentTertiaryLight dark:text-contentPrimaryDark mr-2',
            isActive && 'dark:!text-contentPrimaryDark',
            isProcessing && 'animate-spin'
          )}
        />
      )}
      {children}
    </li>
  );
};
