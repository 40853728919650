'use client';
import React from 'react';

import { GOOGLE_SEARCH_AD_LIMITS } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { ObAvatar } from '../../..';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import {
  stringHasContent,
  truncateText,
} from '../../../utilities/string-utils';
import './styles.css';

export interface GoogleSearchAdProps {
  iconUrl?: string;
  url: string;
  thumbnailUrl: string;
  heading: string;
  description: string;
  featuredLinks: Array<{ id: string; label: string; url: string }>;
  size?: 'mobile' | 'desktop';
  onHeadingClicked?: () => void;
  onDescriptionClicked?: () => void;
}

export const GoogleSearchAd: React.FC<GoogleSearchAdProps> = ({
  iconUrl,
  url,
  thumbnailUrl,
  heading,
  description,
  featuredLinks = [],
  size = 'desktop',
  onHeadingClicked,
  onDescriptionClicked,
}: GoogleSearchAdProps) => {
  let urlParts: URL;
  if (url == null || url === '') {
    urlParts = new URL('https://www.example.com');
  } else {
    urlParts = new URL(url);
  }

  const { result: truncatedHeading, isTruncated: isHeadingTruncated } =
    truncateText(heading, GOOGLE_SEARCH_AD_LIMITS.HEADING_MAX_LENGTH, true);

  const headingIsEmpty = !stringHasContent(heading);

  const { result: truncatedDescription, isTruncated: isDescriptionTruncated } =
    truncateText(
      description,
      GOOGLE_SEARCH_AD_LIMITS.DESCRIPTION_MAX_LENGTH,
      true
    );

  const descriptionIsEmpty = !stringHasContent(description);

  const forceMobileView = size === 'mobile';

  return (
    <div data-testid={'search-ad'}>
      <h4 className='font-robotoBold text-sm pb-[10px] text-[#4d5156] dark:text-[#bdc1c6]'>
        Sponsored
      </h4>
      <div className='flex'>
        <div className={forceMobileView ? 'w-full' : 'w-full'}>
          <div className='flex items-center'>
            {iconUrl ? (
              <ObAvatar
                shape='circle'
                size='small'
                profileImageUrl={iconUrl}
                fallbackText='Business Logo'
              />
            ) : (
              <div className='rounded-full bg-slate-100 dark:bg-[#2a4165] h-[25px] w-[25px] aspect-square flex items-center justify-center'>
                <ObIcon
                  icon='googleGlobe'
                  size='x-small'
                  data-testid={'user-avatar'}
                  classNames='dark:fill-[#e2eeff] fill-[#64748b]'
                />
              </div>
            )}
            <div>
              <div className='pl-2 cursor-pointer'>
                <span>
                  <h6
                    className='font-roboto text-sm text-[#4d5156] dark:text-[#bdc1c6]'
                    data-testid={'website-label'}
                  >
                    {urlParts.host}
                  </h6>
                </span>
                <div className='flex'>
                  <span
                    className='font-roboto text-xs text-[#4d5156] dark:text-[#bdc1c6]'
                    data-testid={'website-url'}
                  >
                    {`${urlParts.protocol}//${urlParts.host}`}
                  </span>
                  <span
                    className={`font-roboto text-xs text-[#4d5156] dark:text-[#bdc1c6] hidden ${
                      forceMobileView ? 'block' : 'md:block'
                    }`}
                  >
                    {urlParts.pathname.split('/').map((element, index) => {
                      const isLastElement =
                        index === urlParts.pathname.split('/').length - 1;
                      return (
                        <span
                          key={index}
                          className={`after:pr-[1px] ${
                            isLastElement ? 'last:after:inline-block' : ''
                          } after:text-[#4d5156]`}
                        >
                          {index > -1 && (
                            <ObIcon
                              icon='chevronRight'
                              size='xx-small'
                              classNames='search-ad-icon'
                            />
                          )}
                          {element}
                        </span>
                      );
                    })}
                  </span>
                  <ObIcon
                    icon='dotsVertical'
                    size='medium'
                    classNames='pt-[1px] pl-2 dark:text-[#9aa0a6]'
                  />
                </div>
              </div>
            </div>
          </div>
          <button onClick={onHeadingClicked}>
            <h3
              className={`font-normal hover:underline font-roboto text-3xl pb-[5px] overflow-hidden text-ellipsis whitespace-nowrap min-h-[33px] data-[invalid=true]:text-dark/contextual/negative data-[invalid=false]:dark:text-[#8ab4f8] data-[invalid=false]:text-blue-700`}
              data-testid={'heading'}
              data-invalid={isHeadingTruncated || headingIsEmpty}
            >
              {headingIsEmpty ? 'Missing Heading' : truncatedHeading}
            </h3>
          </button>
          <div
            className={`${
              forceMobileView ? 'pr-2' : 'md:pr-2'
            } flex items-center`}
          >
            <button
              onClick={onDescriptionClicked}
              className='inline'
            >
              <div
                className={cx(
                  `font-roboto line-clamp-4 text-left ${
                    forceMobileView ? 'line-clamp-2' : 'md:line-clamp-2'
                  } text-sm pr-2`,
                  'w-[400px] min-h-[40px]',
                  'data-[invalid=true]:text-dark/contextual/negative data-[invalid=false]:text-[#4d5156] data-[invalid=false]:dark:text-[#bdc1c6]'
                )}
                data-testid={'description'}
                data-invalid={isDescriptionTruncated || descriptionIsEmpty}
              >
                {descriptionIsEmpty
                  ? 'Missing Description'
                  : truncatedDescription}
              </div>
            </button>
            {thumbnailUrl && (
              <div
                className={`w-1/6 ${
                  forceMobileView ? 'hidden' : 'md:hidden'
                } sm:block`}
              >
                <div
                  className='h-[92px] bg-cover bg-center aspect-square rounded-lg'
                  role='img'
                  data-testid={'mobile-thumbnail-img'}
                  style={{ backgroundImage: `url(${thumbnailUrl})` }}
                ></div>
              </div>
            )}
          </div>
          <div
            role='list'
            className={`hidden ${forceMobileView ? 'block' : 'md:block'}`}
          >
            {featuredLinks.map((link) => (
              <a
                key={link.label}
                href={link.url}
                className='search-ad-featured-link text-[#1a0dab] dark:text-[#8ab4f8] after:dark:text-[#bdc1c6]'
              >
                {link.label}
              </a>
            ))}
          </div>
          <div
            role='list'
            className={`font-roboto flex flex-col ${
              forceMobileView ? 'hidden' : 'md:hidden'
            } mt-3`}
          >
            {featuredLinks.map((link) => (
              <a
                key={link.label}
                href={link.url}
                className='font-normal text-[18px] dark:border-[#303335] border-[#EBECEE] border-t-[1px] py-[16px]'
              >
                <div className='flex justify-between items-center'>
                  <span className='text-[#1f1f1f] dark:text-[#ffffff]'>
                    {link.label}
                  </span>
                  <ObIcon
                    icon='chevronRight'
                    size='x-small'
                    color='inherit'
                    classNames='text-blue-700 dark:text-[#8ab4f8]'
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
        {thumbnailUrl && (
          <div
            className={`hidden ${forceMobileView ? 'w-1/6' : 'md:w-1/6'} ${
              forceMobileView ? 'block' : 'md:block'
            }`}
          >
            <div
              className='h-[92px] bg-cover bg-center aspect-square rounded-lg'
              role='img'
              data-testid={'thumbnail-img'}
              style={{ backgroundImage: `url(${thumbnailUrl})` }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};
