import { JWTPayload, decodeJwt } from 'jose';

export interface BaseJwtToken {
  iat: number;
  iss: string;
  sub: string;
  exp: number;
  ip: string;
}

export interface Auth0ProfileConfirmationJwtToken extends JWTPayload {
  email: string;
  externalUserId: number;
  continueUri: string;
  givenName?: string;
  familyName?: string;
}

export const WORKSPACE_ID_CLAIM = 'https://outbound.com/tenant_id';

/**
 * Used during brand onboarding before a user or workspace is created
 */
export interface BrandOnboardingJwtToken extends JWTPayload {
  brandOnboardingId: string;
}

export interface UserJwtToken extends JWTPayload {
  WORKSPACE_ID_CLAIM: string;
  'https://outbound.com/family_name': string | undefined;
  'https://outbound.com/given_name': string | undefined;
  'https://outbound.com/email_verified': boolean | undefined;
  'https://outbound.com/email': string | undefined;
  permissions: string[];
}

/**
 * Decodes a JWT token.
 * THIS FUNCTION DOES NOT VALIDATE THE JWT SIGNATURE. It can be used for non-security
 * related functions or on a untrusted client side logic for display purposes.
 *
 * An example being displaying the user's name on the client side from the JWT token
 * or showing or hiding a UI element based on the user's permissions.
 *
 * In these cases it is expected the API will validate the JWT token and the client
 * would not be able to take an action that they are not authorized to do even if they
 * can see the UI element.
 *
 * @param token
 * @returns
 */
export const decodeToken = async <T extends JWTPayload>(
  token: string
): Promise<T> => {
  const payload = decodeJwt(token) as T;
  return payload;
};
