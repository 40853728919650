import { cx } from 'class-variance-authority';
import { ObBadge, ObThumbnail, ObTypography } from '../..';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { CardVariantDeprecated } from '../card.type';

interface CreativeCardActionProps {
  /**
   * Callback is required because anytime we use this card it should be able to navigate to the detail page of the service-offering
   * @returns
   */
  onNavigateCallback: (obrn: string) => void;
}
export interface ServiceOfferingCardProps extends CreativeCardActionProps {
  variant: CardVariantDeprecated;
  obrn: string;
  name: string;
  serviceName: string;
  description: string;
  keyPhotoAltText?: string;
  keyPhotoPublicUrl?: string;
}

type ServiceOfferingImplProps = Omit<ServiceOfferingCardProps, 'variant'>;

export const ServiceOfferingCard = ({
  variant,
  ...props
}: ServiceOfferingCardProps) => {
  switch (variant) {
    case 'mini':
      return <MiniCard {...props} />;
    case 'micro':
    case 'grid':
    case 'list':
    case 'detail':
      return (
        <ObTypography
          className='capitalize'
          color='negative'
        >
          {variant} Not Implemented
        </ObTypography>
      );
  }
};

const MiniCard = ({
  obrn,
  name,
  serviceName,
  description,
  keyPhotoAltText,
  keyPhotoPublicUrl,
  onNavigateCallback,
}: ServiceOfferingImplProps) => {
  return (
    <ObCardSurface isClickable={true}>
      <div
        tabIndex={0}
        onClick={() => onNavigateCallback(obrn)}
        role={'button'}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onNavigateCallback(obrn);
          }
        }}
        className={cx(
          'p-2 h-[96px] flex flex-row gap-2 items-center flex-1 self-stretch transition-colors duration-200 hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active hover:cursor-pointer w-full overflow-hidden rounded-lg',
          'hover:cursor-pointer focus-visible:shadow-interactive focus:outline-none'
        )}
      >
        <div className='flex max-w-[128px] h-full relative'>
          <ObThumbnail
            label={`View more details for ${name}`}
            altText={keyPhotoAltText}
            publicUrl={keyPhotoPublicUrl}
            imageFit={'cover'}
            includeBorder={false}
            size='responsive'
            preferredBackground={'dark'}
          />
          <ObBadge
            level='NEUTRAL'
            className='absolute bottom-1 left-[6px]'
            variant='rounded'
            content='Service Offering'
          />
        </div>

        <div className='flex flex-col justify-start h-full'>
          <ObTypography
            variant='body3'
            className='uppercase line-clamp-1'
            color='tertiary'
          >
            {serviceName}
          </ObTypography>
          <ObTypography variant='h4'>{name}</ObTypography>

          <ObTypography
            variant='body3'
            className='line-clamp-2'
            color='secondary'
          >
            {description}
          </ObTypography>
        </div>
      </div>
    </ObCardSurface>
  );
};
