'use client';

import { Switch } from '@headlessui/react';
import { cva, cx } from 'class-variance-authority';

export interface ObToggleSwitchProps {
  /**
   * The unique identifier for the switch. Used to associate the label with the input field
   */
  id: string;
  /**
   * Sets the Toggle Switch to a state where it cannot be modified
   */
  disabled: boolean;
  /**
   * Sets the state of the switch
   */
  checked: boolean;

  size?: 'small' | 'medium';
  /**
   * Callback function to call when the state of the switch is modified
   */
  onChange: (event?: any) => void;
}

export const ObToggleSwitch = ({
  id,
  size = 'small',
  disabled,
  checked,
  onChange,
}: ObToggleSwitchProps) => {
  const switchStyles = cva('border border-borderDefaultNormalLight', {
    variants: {
      size: {
        small: 'h-6 w-11',
        medium: 'h-8 w-[56px]',
      },
      checked: {
        true: ['bg-actionPrimaryDark dark:border-borderDefaultNormalDark'],
        false: ['dark:bg-bgSurfaceDark dark:border-actionOutlineNormalDark'],
      },
      disabled: {
        true: ['opacity-50 cursor-not-allowed'],
        false: ['opacity-100'],
      },
    },
    defaultVariants: {
      checked: false,
      disabled: false,
    },
  });

  const toggleStyles = cva(
    'inline-block transform rounded-full bg-bgSurfaceLight transition',
    {
      variants: {
        size: {
          small: 'h-4 w-4',
          medium: 'h-6 w-6',
        },
        checked: {
          true: ['translate-x-6', 'medium:translate-x-[28px]'],
          false: ['translate-x-1'],
        },
        disabled: {
          true: ['opacity-50'],
          false: ['opacity-100'],
        },
      },
      defaultVariants: {
        checked: false,
        disabled: false,
      },
    }
  );

  return (
    <Switch
      id={id}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      className={cx(
        'relative inline-flex items-center rounded-full transition-all duration-300 ease-in-out min-w-[44px]',
        switchStyles({ checked, size, disabled })
      )}
    >
      <span className={cx(toggleStyles({ checked, size, disabled }))} />
    </Switch>
  );
};
