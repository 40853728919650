import { FC, ReactNode } from 'react';
import {
  ObActionMenu,
  ObActionMenuItem,
  ObActionMenuItemProps,
  ObButton,
} from '../../..';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObCardHeaderBase } from '../ob-card-header-base/ob-card-header-base';

/**
 * The public props of the CardHeader with menu component
 */
export interface ObCardHeaderWithMenuProps {
  /**
   * The heading that will appear on the top of the card
   */
  heading: string;

  /**
   * Indicates that the header menu should be disabled
   */
  isDisabled?: boolean;
  /**
   * The headingAs prop allows you to change the heading element type
   */
  headingAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  /**
   * The menu items that will appear in the header menu
   */
  menuItems: Array<ObActionMenuItemProps>;

  /**Optional Icon */
  icon?: IconSystemKeys;

  rightContent?: ReactNode;
}

export const ObCardHeaderWithMenu: FC<ObCardHeaderWithMenuProps> = ({
  heading,
  headingAs = 'h5',
  isDisabled,
  menuItems,
  icon,
  rightContent,
}: ObCardHeaderWithMenuProps) => {
  return (
    // Min height is set to 40px to prevent the header from expanding the card. We assume the parent will ensure the right amount of spacing for the header.
    <ObCardHeaderBase
      heading={heading}
      headingAs={headingAs}
      afterTitleContent={
        <div className='mt-[1px]'>
          {menuItems && menuItems.length > 0 && (
            <ObActionMenu
              menuOpenButton={
                <ObButton
                  buttonType='icon'
                  variant='ghost'
                  iconLeft='dotsVertical'
                  label={`Open actions menu for ${heading}`}
                  disabled={isDisabled}
                  className='ml-1'
                />
              }
              children={menuItems.map((item) => (
                <ObActionMenuItem
                  key={item.title}
                  {...item}
                />
              ))}
            />
          )}
        </div>
      }
      icon={icon}
      rightContent={rightContent}
    />
  );
};
