import { parseObrn } from '@otbnd/utils';
import {
  LandingPageLifecycleStatus,
  LandingPageThumbnailStatus,
  LandingPageType,
  ServerDelta,
} from '@outbound/types';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../base-model';
import Campaign from '../campaign/campaign';
import { RootStore } from '../root-store';

/**
 * Expected Data to Construct a Base Landing Page
 */
export interface LandingPageConstructorData {
  pageTitle: string;
  type: LandingPageType;
  url: string;
  thumbnailUrlPublicUrl: string | null;
  thumbnailGenerationStatus: LandingPageThumbnailStatus;
  lifecycleStatus: LandingPageLifecycleStatus;
  httpsEnabled: boolean;
}

class LandingPage extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    type: '/type',
    url: '/url',
    lifecycleStatus: '/lifecycleStatus',
    pageTitle: '/pageTitle',
    httpsEnabled: '/httpsEnabled',
    thumbnailUrlPublicUrl: '/thumbnailUrlPublicUrl',
    thumbnailGenerationStatus: '/thumbnailGenerationStatus',
  };

  private _lifecycleStatus: LandingPageLifecycleStatus;
  /**
   * The type of landing page
   */
  private _type: LandingPageType;
  /**
   * The Title of the Landing Page
   * (Used to uniquely identify the landing page)
   */
  private _pageTitle: string;

  /**
   * The URL of the Landing Page including domain and path
   */
  private _url: string;

  /**
   * A publicly accessible URL to the screenshot of the landing page
   */
  private _thumbnailUrlPublicUrl: string | null;

  /**
   * Indicates if the thumbnail of the page has been generated or not.
   */
  private _thumbnailGenerationStatus: LandingPageThumbnailStatus;

  private _httpsEnabled: boolean;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    data: LandingPageConstructorData
  ) {
    const { scope } = parseObrn(obrn);
    super(rootStore, 'landing-page', '1', id, scope, obrn);
    this._pageTitle = data.pageTitle;
    this._type = data.type;
    this._url = data.url;
    this._thumbnailUrlPublicUrl = data.thumbnailUrlPublicUrl ?? null;
    this._thumbnailGenerationStatus = data.thumbnailGenerationStatus;
    this._lifecycleStatus = data.lifecycleStatus;
    this._httpsEnabled = data.httpsEnabled;
  }

  applyPatch(_patch: ServerDelta[]): void {
    runInAction(() => {
      for (const delta of _patch) {
        switch (delta.path) {
          case LandingPage.paths.lifecycleStatus:
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._lifecycleStatus = delta.value;
                break;
            }
            break;
          case LandingPage.paths.pageTitle:
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._pageTitle = delta.value;
                break;
            }
            break;
          case LandingPage.paths.thumbnailUrlPublicUrl:
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._thumbnailUrlPublicUrl = delta.value;
                break;
            }
            break;
          case LandingPage.paths.thumbnailGenerationStatus:
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._thumbnailGenerationStatus = delta.value;
                break;
            }
            break;
          case LandingPage.paths.httpsEnabled:
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._httpsEnabled = delta.value;
                break;
            }
            break;
          case LandingPage.paths.url:
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._url = delta.value;
                break;
            }
            break;
        }
      }
    });
  }

  toJson(): Record<string, any> {
    const baseJson = super.toJson();
    return {
      ...baseJson,
      [LandingPage.paths.lifecycleStatus]: this._lifecycleStatus,
      [LandingPage.paths.pageTitle]: this._pageTitle,
      [LandingPage.paths.type]: this._type,
      [LandingPage.paths.url]: this._url,
      [LandingPage.paths.httpsEnabled]: this._httpsEnabled,
      [LandingPage.paths.thumbnailUrlPublicUrl]: this._thumbnailUrlPublicUrl,
      [LandingPage.paths.thumbnailGenerationStatus]:
        this._thumbnailGenerationStatus,
    };
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _pageTitle: observable,
      _thumbnailUrlPublicUrl: observable,
      _thumbnailGenerationStatus: observable,
      _lifecycleStatus: observable,
      _url: observable,
    } as any);
  }

  /**
   * The Page Title is typically what you would find at the top of the browser tab.
   * This can be overridden by the user to be more descriptive.
   */
  get pageTitle(): string {
    return this._pageTitle;
  }

  /**
   * Indicates the type of LANDING PAGE that this is.
   * Can be used to cast to the specific subclass to access
   * type specific attributes and ctas.
   *
   * This property is immutable.
   */
  get type(): LandingPageType {
    return this._type;
  }

  /**
   * Returns either the thumbnail URL if it exists or a placeholder if we are still in the process
   * of taking the screenshot.
   * This is generated by the system on creation and it's completion is tracked by the thumbnailGenerationStatus attribute.
   * This value is read only from the frontend perspective.
   */
  get thumbnailUrl(): string | null {
    return this._thumbnailUrlPublicUrl ?? null;
  }

  /**
   * Indicates if the thumbnail of the page has been generated or not.
   * This process is an async process that is triggered when the page is created and
   * performed by the backend system. This can take a few minutes.
   * From the Frontend perspective this is read only.
   */
  get thumbnailGenerationStatus(): LandingPageThumbnailStatus {
    return this._thumbnailGenerationStatus;
  }

  /**
   * The complete URL (URI) of the landing page including the protocol, domain and path.
   * For Self Hosted this is provided at creation and is immutable, for Outbound Hosted this is a calculated value
   * based off the domain and path. ({workspaceKey}.outbound.page/{path}) or {verifiedDomain}/{path}
   */
  get url(): string {
    return this._url;
  }

  /**
   * Indicates the stage of the lifecycle of the landing page is in.
   */
  get lifecycleStatus(): LandingPageLifecycleStatus {
    return this._lifecycleStatus;
  }

  get httpsEnabled(): boolean {
    return this._httpsEnabled;
  }

  delete(): void {
    runInAction(() => {
      this._rootStore.landingPageStore.delete(this.id);
    });
  }

  /**
   * Finds all the campaigns that are using this landing page.
   */
  getCampaignsUsingLandingPage(): Array<Campaign> {
    return this._rootStore.campaignStore.list().filter((campaign) => {
      if (
        campaign.campaignHighlights.some(
          (highlight) =>
            highlight.landingPage != null && highlight.landingPage === this
        )
      ) {
        return campaign;
      }
    });
  }
}

export default LandingPage;
