import { FC } from 'react';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObViewMoreCard } from '../ob-view-more-card/ob-view-more-card';

export interface ObCustomerProfileCardProps {
  /**
   * Name of the X
   */
  role: string;
  /**
   * The description of the X
   */
  description: string;
  /**
   * Human readable descriptions of the demographics of the persona
   */
  demographicDescriptions: Array<string>;
}

export const ObCustomerProfileCard: FC<ObCustomerProfileCardProps> = ({
  role: name,
  description,
  demographicDescriptions = [],
}: ObCustomerProfileCardProps) => {
  return (
    <ObViewMoreCard
      heading={name}
      color='cyan'
      icon={'userCircle'}
      height='224px'
    >
      <div className='flex flex-col gap-4 text-left'>
        <ObTypography
          className='line-clamp-2'
          variant='body1'
        >
          {description}
        </ObTypography>
        {
          <div className='flex flex-col gap-1'>
            <ObTypography variant='h6'>Demographics</ObTypography>
            <div>
              {demographicDescriptions.length === 0 && (
                <ObTypography variant='body3'>Not configured</ObTypography>
              )}
              {demographicDescriptions.map((demoDesc) => (
                <ObTypography
                  key={demoDesc}
                  variant='body3'
                >
                  {demoDesc}
                </ObTypography>
              ))}
            </div>
          </div>
        }
      </div>
    </ObViewMoreCard>
  );
};
