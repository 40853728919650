import { cva, cx } from 'class-variance-authority';
import { FC, ReactNode } from 'react';
import './ob-skeleton.css';

export type ObSkeletonVariant = 'text' | 'circle' | 'rounded' | 'rectangle';
export interface ObSkeletonProps {
  children?: ReactNode;
  variant?: ObSkeletonVariant;
  height?: string;
  width?: string;
}
export const ObSkeleton: FC<ObSkeletonProps> = ({
  children,
  variant,
  width,
  height,
}: ObSkeletonProps) => {
  const skeletonClasses = cva(
    'ob-skeleton animate-pulse dark:bg-bgSurface2Dark bg-outboundGrey-4 block',
    {
      variants: {
        variant: {
          rounded: ['rounded'],
          circle: ['rounded-full'],
          rectangle: [],
          text: ['text-skeleton'],
        },
      },
      defaultVariants: {
        variant: 'text',
      },
    }
  );

  return (
    <span
      data-testid='ob-skeleton'
      data-variant={variant}
      style={{ width, height }}
      className={cx(skeletonClasses({ variant }))}
    >
      <span
        className='block'
        style={{ visibility: 'hidden' }}
      >
        {children}
      </span>
      {/* {variant !== 'text' && (
        <div style={{ visibility: 'hidden' }}>{children}</div>
      )}
      {variant === 'text' && (
        <span style={{ visibility: 'hidden' }}>{children}</span>
      )} */}
    </span>
  );
};
