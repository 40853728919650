import { IntegrationConfigHealthCheckStatus } from '@outbound/types';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { ObIndicatorDot } from '../../../../notifications';
import { ObColorType } from '../../../../tokens/colors/colors';
import { CardVariantProps } from '../../../card.type';

export interface HealthCheckItemCardProps extends CardVariantProps {
  name: string;
  status: IntegrationConfigHealthCheckStatus;
  message?: string;
  documentationUrl?: string;
  ranAtTimestamp: string;
  skippedDueToDependencies: boolean;
}

type HealthCheckItemCardImplementationProps = Omit<
  HealthCheckItemCardProps,
  'variant'
>;

export const HealthCheckItemCard = ({
  variant,
  ...props
}: HealthCheckItemCardProps) => {
  switch (variant) {
    case 'table':
      //   Returns a <tr> element with the health check item details
      return <TableCard {...props} />;
    case 'grid':
    case 'micro':
    case 'mini':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};

/**
 * Table Card Variant for Health Check Item
 */
const TableCard = ({
  status,
  name,
  message,
}: HealthCheckItemCardImplementationProps) => {
  const statusDetails = lookupStatusDetailsForHealthCheckItemStatus(status);
  return (
    <tr className='cursor-pointer hover:dark:bg-dark/action/neutral/hover rounded-lg overflow-clip'>
      <td className='not:first:px-4 first:pr-4 py-3 rounded-l-lg w-10'>
        <div className='flex justify-center items-center'>
          <ObIndicatorDot
            color={statusDetails.color}
            priority={
              status === 'PASS' || status === 'ABSTAIN' ? 'low' : 'high'
            }
          />
        </div>
        <div className='sr-only'>
          <ObTypography>{status}</ObTypography>
        </div>
      </td>
      <td className='px-4 py-3 '>
        <ObTypography>{name}</ObTypography>
      </td>
      <td className='not:last:px-4 last:pl-4 py-3 rounded-r-lg'>
        <ObTypography
          color='secondary'
          className='line-clamp-2'
        >
          {message}
        </ObTypography>
      </td>
    </tr>
  );
};

const lookupStatusDetailsForHealthCheckItemStatus = (
  status: IntegrationConfigHealthCheckStatus
): { label: string; color: ObColorType } => {
  let statusDetails: { label: string; color: ObColorType } = {
    color: 'content',
    label: '',
  };
  switch (status) {
    case 'PASS':
      statusDetails = {
        color: 'positive',
        label: 'Pass',
      };
      break;
    case 'WARNING':
      statusDetails = {
        color: 'warning',
        label: 'Warning',
      };
      break;
    case 'FAIL':
      statusDetails = {
        color: 'negative',
        label: 'Failed',
      };
      break;
    case 'FATAL':
      statusDetails = {
        color: 'negative',
        label: 'Fatal Error',
      };
      break;
    case 'ABSTAIN':
      statusDetails = {
        color: 'secondary',
        label: 'Out of Scope',
      };
      break;
    default:
      statusDetails = {
        color: 'content',
        label: 'Unknown',
      };
      break;
  }
  return statusDetails;
};
