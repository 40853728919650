export const Lock = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00016 1.33594C5.79102 1.33594 4.00016 3.1268 4.00016 5.33594V6.68736C3.94233 6.69056 3.88638 6.69431 3.83228 6.69873C3.45755 6.72935 3.11308 6.79506 2.78952 6.95992C2.28776 7.21558 1.87981 7.62353 1.62415 8.1253C1.45929 8.44885 1.39358 8.79332 1.36296 9.16805C1.33348 9.52885 1.33349 9.97175 1.3335 10.5084V10.8301C1.33349 11.3668 1.33348 11.8097 1.36296 12.1705C1.39358 12.5452 1.45929 12.8897 1.62415 13.2132C1.87981 13.715 2.28776 14.123 2.78952 14.3786C3.11308 14.5435 3.45755 14.6092 3.83228 14.6398C4.19308 14.6693 4.63599 14.6693 5.17265 14.6693H10.8277C11.3643 14.6693 11.8072 14.6693 12.168 14.6398C12.5428 14.6092 12.8872 14.5435 13.2108 14.3786C13.7126 14.123 14.1205 13.715 14.3762 13.2132C14.541 12.8897 14.6067 12.5452 14.6374 12.1705C14.6668 11.8097 14.6668 11.3668 14.6668 10.8302V10.5084C14.6668 9.97175 14.6668 9.52885 14.6374 9.16805C14.6067 8.79332 14.541 8.44885 14.3762 8.1253C14.1205 7.62353 13.7126 7.21558 13.2108 6.95992C12.8872 6.79506 12.5428 6.72935 12.168 6.69873C12.1139 6.69431 12.058 6.69056 12.0002 6.68736V5.33594C12.0002 3.1268 10.2093 1.33594 8.00016 1.33594ZM10.6668 6.66927V5.33594C10.6668 3.86318 9.47292 2.66927 8.00016 2.66927C6.5274 2.66927 5.3335 3.86318 5.3335 5.33594V6.66927H10.6668Z'
        fill='currentColor'
      />
    </svg>
  );
};
