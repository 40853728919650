/**
 * Displays a list of Workspaces that a user is a member of and allows them to relaunch the dashboard with a new Workspace
 * @constructor
 */
import WorkspacePicker from '../../components/organisms/workspace-picker/workspace-picker';

const WorkspaceLauncherPage = () => {
  return <WorkspacePicker />;
};

export default WorkspaceLauncherPage;
