import { PlaybookBusinessDetailsResource } from '@outbound/types';

export interface BusinessOverviewFormValues {
  industry: Array<string>;
  legalBusinessName: string;
  businessName: string;
  summary: string;
  website: string;
  relatedKeywords: Array<string>;
  negativeKeywords: Array<string>;
  languages: Array<string>;
  phoneNumber: string;
  isCustomerPhoneCallsAccepted: boolean;
  address: {
    street1: string;
    street2: string;
    state: string;
    city: string;
    postalCode: string;
    country: string;
  };
  isRetailOnline: boolean;
  isRetailInStore: boolean;
  isServiceOnline: boolean;
  isServiceInCustomerLocation: boolean;
  isServiceInBusinessLocation: boolean;
  isNonProfit: boolean;
  isDifferentiatorMadeInUsa: boolean;
  isDifferentiatorVeteranOwned: boolean;
  isDifferentiatorFamilyOwned: boolean;
  yearEstablished: number;
  uniqueSellingProposition: string;
}

export const mapFormValuesToApiResource = (
  formValues: BusinessOverviewFormValues
): PlaybookBusinessDetailsResource => {
  return {
    industryId:
      formValues.industry != null && formValues.industry.length > 0
        ? formValues.industry[0]
        : undefined,
    legalBusinessName: formValues.legalBusinessName,
    doingBusinessAsName: formValues.businessName,
    isCustomerPhoneCallsAccepted: formValues.isCustomerPhoneCallsAccepted,
    phoneNumber: formValues.phoneNumber,
    summary: formValues.summary,
    website: formValues.website,
    uniqueSellingProposition: formValues.uniqueSellingProposition,
    businessActivities: {
      isRetailOnline: formValues.isRetailOnline,
      isRetailInStore: formValues.isRetailInStore,
      isServiceOnline: formValues.isServiceOnline,
      isServiceInCustomerLocation: formValues.isServiceInCustomerLocation,
      isServiceInBusinessLocation: formValues.isServiceInBusinessLocation,
      isNonProfit: formValues.isNonProfit,
    },
    languages: formValues.languages ?? [],
    relatedKeywords: formValues.relatedKeywords ?? [],
    negativeKeywords: formValues.negativeKeywords ?? [],
    address: {
      street1: formValues.address.street1,
      street2: formValues.address.street2,
      city: formValues.address.city,
      state: formValues.address.state,
      postalCode: formValues.address.postalCode,
      country: formValues.address.country,
    },
    isDifferentiatorFamilyOwned: formValues.isDifferentiatorFamilyOwned,
    isDifferentiatorMadeInUsa: formValues.isDifferentiatorMadeInUsa,
    isDifferentiatorVeteranOwned: formValues.isDifferentiatorVeteranOwned,
    yearEstablished: formValues.yearEstablished,
  };
};

export const mapApiResourceToFormValues = (
  resource: PlaybookBusinessDetailsResource
): BusinessOverviewFormValues => {
  return {
    industry: resource?.industryId ? [resource.industryId] : [],
    uniqueSellingProposition: resource?.uniqueSellingProposition ?? '',
    businessName: resource?.doingBusinessAsName ?? '',
    legalBusinessName: resource?.legalBusinessName ?? '',
    website: resource?.website ?? '',
    summary: resource?.summary ?? '',
    phoneNumber: resource?.phoneNumber ?? '',
    isCustomerPhoneCallsAccepted:
      resource?.isCustomerPhoneCallsAccepted ?? false,
    isRetailOnline: resource?.businessActivities?.isRetailOnline ?? false,
    isRetailInStore: resource?.businessActivities?.isRetailInStore ?? false,
    isServiceOnline: resource?.businessActivities?.isServiceOnline ?? false,
    isServiceInCustomerLocation:
      resource?.businessActivities?.isServiceInCustomerLocation ?? false,
    isServiceInBusinessLocation:
      resource?.businessActivities?.isServiceInBusinessLocation ?? false,
    isNonProfit: resource?.businessActivities?.isNonProfit ?? false,
    languages: resource?.languages ?? [],
    relatedKeywords: resource?.relatedKeywords ?? [],
    negativeKeywords: resource?.negativeKeywords ?? [],
    address:
      resource?.address && resource.address.street1 != null
        ? {
            city: resource.address.city ?? '',
            postalCode: resource.address.postalCode ?? '',
            state: resource.address.state ?? '',
            street1: resource.address.street1 ?? '',
            street2: resource.address.street2 ?? '',
            country: resource.address.country ?? '',
          }
        : {
            street1: '',
            street2: '',
            state: '',
            city: '',
            postalCode: '',
            country: '',
          },
    isDifferentiatorFamilyOwned: resource?.isDifferentiatorFamilyOwned ?? false,
    isDifferentiatorMadeInUsa: resource?.isDifferentiatorMadeInUsa ?? false,
    isDifferentiatorVeteranOwned:
      resource?.isDifferentiatorVeteranOwned ?? false,
    yearEstablished: resource?.yearEstablished ?? 0,
  };
};
