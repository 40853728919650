import { cx } from 'class-variance-authority';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import {
  ObThumbnailShape,
  ObThumbnailSizes,
  buildVariantClasses,
} from '../ob-thumbnail/ob-thumbnail';

export interface ObThumbnailPlaceholderProps {
  size: ObThumbnailSizes;
  shape: ObThumbnailShape;
}
export const ObThumbnailPlaceholder = ({
  size,
  shape,
}: ObThumbnailPlaceholderProps) => {
  return (
    <div
      data-ob-component='ob-thumbnail-placeholder'
      className={cx(
        'rounded-md border text-contentSecondaryLight dark:text-contentPrimaryDark dark:border-borderDefaultNormalDark border-borderDefaultNormalLight flex items-center justify-center dark:bg-bg-gradient-dark ',
        buildVariantClasses({ size, shape })
      )}
    >
      <span className='sr-only'>No Image Provided</span>
      <ObIcon
        icon='assetLibrary'
        color='inherit'
        size={'small'}
      ></ObIcon>
    </div>
  );
};
