import { CampaignChannelType } from '../campaign/CampaignChannelType';

export const supportedChannels: Array<{
  label: string;
  type: CampaignChannelType;
  available: boolean;
  isEnabled?: boolean;
}> = [
  {
    label: 'Google Search',
    type: CampaignChannelType.GOOGLE_SEARCH_ADS,
    available: true,
    isEnabled: false,
  },
  {
    label: 'Google Display',
    type: CampaignChannelType.GOOGLE_DISPLAY_ADS,
    available: true,
  },
  {
    label: 'Facebook',
    type: CampaignChannelType.FACEBOOK_ADS,
    available: true,
  },
  {
    label: 'Billboards',
    type: CampaignChannelType.BILLBOARD_ADS,
    available: false,
  },
  {
    label: 'LinkedIn',
    type: CampaignChannelType.LINKEDIN_ADS,
    available: false,
  },
  {
    label: 'Instagram',
    type: CampaignChannelType.INSTAGRAM_ADS,
    available: false,
  },
  {
    label: 'Podcasts',
    type: CampaignChannelType.PODCAST_ADS,
    available: false,
  },
  {
    label: 'Connected TV',
    type: CampaignChannelType.STREAMING_TV_ADS,
    available: false,
  },
  {
    label: 'Mailers',
    type: CampaignChannelType.MAILER_ADS,
    available: false,
  },
];
