import { ObrnDomain, ObrnObject, ServerDelta } from '@outbound/types';
import { BaseModel } from './base-model';
import { RootStore } from './root-store';

/**
 * The BaseTransformer that is extended any time we need to convert a Server Object to a Model Object.
 * Our Model Objects work best when they represent the User Facing Objects in the Application from a UX
 * perspective. Depending on the Implementation of the API, the Server Objects may be more complex or have several objects that combine to make a single User Facing Object.
 *
 * The Transformer's job is to act as a Universal translator between the Server Objects and the Model Objects.
 *
 * The T represents the Server Object(s) that reside on the server and the U represents the Model Object that our Users interact with.
 *
 * @param T The Server Object(s) that reside on the server
 * @param U The Model Object that our Users interact with
 */
export abstract class BaseTransformer<T, U> {
  protected _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
  }

  /**
   * Convert a Server Object to a Model Object
   * @param resource The Server Object that needs to be converted to a Model Object
   * The T here can be a single object or several objects that need to be combined to make a single Model Object
   */
  abstract fromApiResource(resource: T): U;

  /**
   * Expects a list of Deltas that should be applied to the model to make it look like the resource
   * The deltas should be in the order they should be applied
   * The model itself has the final say on if these deltas are applied. This method is a way
   * to compare a model to a resource and create a list of deltas that can be applied to the model
   * to make it look like the resource. These deltas may in the future come directly from the API
   * instead of being created here on the front end.
   *
   * The point of this is to keep consistency between how versioned resources that use a Conflict Free Replicated Data Type
   * and more traditional RESTful resources are handled by our stores and models to avoid having to write a lot of
   * custom code for each model.
   *
   * @param currentModel The Store's existing model that should be used as a base for the patch
   * @param incomingResource The latest resource from the API that should be used to create the patch
   */
  abstract createPatchForCurrentModelAndIncomingResource(
    currentModel: U,
    incomingResource: T
  ): Array<ServerDelta>;

  /**
   * Temporary implementation to create a simulated server delta.
   * Ideally at some point in the future we will have a full sync mechanism in place.
   * And the server will send deltas to the client to update the client model via a websocket.
   */
  protected createSimulatedServerDelta(
    baseModel: BaseModel,
    value: any,
    path: any,
    op: 'replace' | 'add' | 'remove'
  ): ServerDelta {
    const timestamp = new Date().toISOString();
    return {
      id: baseModel.id,
      obrn: baseModel.obrn,
      clientId: this._rootStore.clientId,
      object: baseModel.object,
      clientUpdateId: crypto.randomUUID(),
      clientTimestamp: timestamp,
      objectDomain: baseModel.objectDomain,
      objectSchemaVersion: baseModel.objectVersion,
      serverTimestamp: timestamp,
      op,
      path,
      value,
    };
  }
  protected createSimulatedServerDeltaWithoutExistingModel(
    value: any,
    path: any,
    op: 'replace' | 'add' | 'remove',
    id: string,
    obrn: string,
    object: ObrnObject,
    objectDomain: ObrnDomain,
    objectSchemaVersion: string
  ): ServerDelta {
    const timestamp = new Date().toISOString();
    return {
      id: id,
      obrn: obrn,
      clientId: this._rootStore.clientId,
      object: object,
      clientUpdateId: crypto.randomUUID(),
      clientTimestamp: timestamp,
      objectDomain: objectDomain,
      objectSchemaVersion: objectSchemaVersion,
      serverTimestamp: timestamp,
      op,
      path,
      value,
    };
  }
}
