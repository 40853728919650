import { cx } from 'class-variance-authority';
import { StateManagedByParentInput } from '../../../../../base-component-props.type';
import { ObInputNumber } from '../../../../atoms/inputs/ob-input-number/ob-input-number';

import { ObTag } from '../../../../../indicators';
import { ObTypography } from '../../../../elements/ob-typography/ob-typography';

export interface NumberFieldProps {
  isEditable: boolean;
  fieldValue: number | undefined;
  onChange: (value: number | undefined) => void;
}

export const NumberField = ({
  isEditable,
  fieldValue,
  onChange,
}: NumberFieldProps) => {
  return (
    <>
      {!isEditable && fieldValue !== undefined && fieldValue !== null && (
        <ObTag
          content={fieldValue.toString()}
          removable={false}
          selectable={false}
        />
      )}

      {isEditable && (
        <div className='min-w-[48px] w-full'>
          <ObInputNumber
            size='small'
            value={fieldValue}
            onValueChangedCallback={onChange}
            isLoading={false}
            inputId={''}
          />
        </div>
      )}
    </>
  );
};

export interface NumberSingleValueFormProps
  extends StateManagedByParentInput<number | undefined> {
  isInEditMode: boolean;
}

export const NumberSingleValueForm = ({
  isInEditMode,
  value,
  onValueChangedCallback,
}: NumberSingleValueFormProps) => {
  const handleOnValueChanged = (nextValue: number | undefined) => {
    onValueChangedCallback(nextValue, undefined);
  };
  return (
    <div
      className={cx('flex flex-row justify-center items-center w-full gap-2')}
    >
      <NumberField
        isEditable={isInEditMode}
        fieldValue={value}
        onChange={handleOnValueChanged}
      />
    </div>
  );
};

export interface NumberRangeValueFormProps
  extends StateManagedByParentInput<{
    from: number | undefined;
    to: number | undefined;
  }> {
  isInEditMode: boolean;
}

export const NumberRangeValueForm = ({
  isInEditMode,
  value,
  onValueChangedCallback,
}: NumberRangeValueFormProps) => {
  const handleOnFromValueChanged = (fromValue: number | undefined) => {
    const nextValue = {
      ...value,
      from: fromValue,
    };
    onValueChangedCallback(nextValue, undefined);
  };

  const handleOnToValueChanged = (toValue: number | undefined) => {
    const nextValue = {
      ...value,
      to: toValue,
    };
    onValueChangedCallback(nextValue, undefined);
  };

  return (
    <div
      className={cx('flex flex-row justify-center items-center w-full gap-2')}
    >
      <NumberField
        isEditable={isInEditMode}
        fieldValue={value?.from}
        onChange={handleOnFromValueChanged}
      />
      <ObTypography variant='subtitle2'>and</ObTypography>
      <NumberField
        isEditable={isInEditMode}
        fieldValue={value?.to}
        onChange={handleOnToValueChanged}
      />
    </div>
  );
};
