import {
  BaseTemplateValueResource,
  TemplateSchemaResource,
} from '@outbound/types';
import { cx } from 'class-variance-authority';
import React, { FC, useEffect, useState } from 'react';
import {
  CreativeInitializingLoader,
  ObLoadingSpinner,
  templateComponentMapping,
} from '../..';

export interface ObCreativePlayerV2Props {
  template: Pick<TemplateSchemaResource, 'id' | 'renderComponent' | 'name'>; //Minimum required fields to render the template
  settings: Map<
    string,
    Pick<BaseTemplateValueResource, 'id' | 'type' | 'value' | 'obrn'>
  >;
  mobileView?: boolean;
  isLoading: boolean;
  estimatedPercentComplete?: number;
}

/**
 * Forked from V1 Creative Player.
 * This component does not drop in a creative resource directly.
 * Instead it takes only the props it needs.
 * This was done to make this component compatible with the Mobx store.
 * @param param0
 * @returns
 */
export const ObCreativePlayerV2: FC<ObCreativePlayerV2Props> = ({
  template,
  settings,
  isLoading,
  mobileView = false,
  estimatedPercentComplete = 100,
}: ObCreativePlayerV2Props) => {
  const [showCreative, setShowCreative] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && template != null) {
      setTimeout(() => {
        setShowCreative(true);
      }, 100);
    }
  }, [isLoading, template]);

  return (
    <div
      className='cursor-pointer'
      data-testid='ob-creative-player'
      data-ob-component='ob-creative-player'
    >
      {!isLoading && template != null && estimatedPercentComplete < 100 && (
        <div className='p-2'>
          <CreativeInitializingLoader
            estimatedPercentComplete={estimatedPercentComplete}
          />
        </div>
      )}
      {/* The creative resource is NOT present and we don't know if it's initialized */}
      {(isLoading || template == null) && (
        <ObLoadingSpinner
          includeBrand={true}
          fadeIn={false}
        />
      )}

      <div
        className={cx(
          'opacity-0 transition-opacity duration-500 ease-in-out',
          showCreative ? 'opacity-100' : ''
        )}
      >
        {/* The creative is available and is done initializing */}
        {!isLoading &&
          settings &&
          estimatedPercentComplete === 100 &&
          template?.renderComponent &&
          templateComponentMapping.hasOwnProperty(template.renderComponent) &&
          React.createElement(
            templateComponentMapping[template.renderComponent],
            {
              templateId: template.id,
              templateName: template.name,
              templateVersion: '',
              values: settings,
              playbackControls: {
                animate: true,
                playback: 'play',
                loop: true,
              },
              size: mobileView ? 'mobile' : 'desktop',
            }
          )}
      </div>
    </div>
  );
};
