import { AddressResource, usStates } from '@outbound/types';

import { FieldError } from 'react-hook-form';
import { InputSizeProps } from '../../../base-component-props.type';
import { ObInputListbox } from '../../atoms/inputs/ob-input-listbox/ob-input-listbox';
import { ObInputControlled } from '../../elements/ob-input-controlled/ob-input-controlled';
import { ObField } from '../../molecules/ob-field/ob-field';

export interface ObAddressFieldProps extends InputSizeProps {
  /**
   * Unique ID for the Address Field Group.
   */
  inputId: string;
  /**
   * Indicates if the field is required
   */
  isRequired?: boolean;
  /**
   * The value of the address from the parent
   */
  value: AddressResource;
  /**
   * Indicates that the data for this field is being loaded. Shows a skeleton loader in place of the address fields
   */
  isLoading?: boolean;
  /**
   * Disables the address field
   */
  isDisabled?: boolean;
  /**
   * Callback function to be called any time the address field changes
   * @param value
   * @returns
   */
  onChange: (value: AddressResource) => void;

  /**
   * An array of errors for the address field
   * Shape is from React Hook Form which is used by our form
   * renderer
   */
  errors?: { [key: string]: FieldError };
}

/**
 * Renders an Address Field for a Form
 */
export const ObAddressField = ({
  inputId,
  isRequired = false,
  value,
  onChange,
  isLoading,
  isDisabled,
  errors,
  size = 'medium',
}: ObAddressFieldProps) => {
  // Change handler for input fields
  const handleInputChange = (
    field: keyof AddressResource,
    newValue: string
  ) => {
    const updatedValue = { ...value, [field]: newValue };
    onChange(updatedValue); // Propagate changes to the parent component
  };

  return (
    <>
      <ObField
        isRequired={isRequired}
        inputId={`${inputId}.street1`}
        label={'Address 1'}
        variant={'field-in-group'}
        errorText={errors?.['street1']?.message}
      >
        <ObInputControlled
          inputId={`${inputId}.street1`}
          size={size}
          isLoading={isLoading ?? false}
          isDisabled={isDisabled}
          value={value.street1 ?? ''}
          placeholder='Address 1'
          isErrored={errors?.['street1'] != null}
          onValueChangedCallback={(value) =>
            handleInputChange('street1', value)
          }
        />
      </ObField>
      <ObField
        // Address 2 is usually not required
        isRequired={false}
        inputId={`${inputId}.street2`}
        label={'Address 2'}
        variant={'field-in-group'}
        errorText={errors?.['street2']?.message}
      >
        <ObInputControlled
          inputId={`${inputId}.street2`}
          isLoading={isLoading ?? false}
          size={size}
          isDisabled={isDisabled}
          placeholder={'Apt, Suite, etc.'}
          value={value.street2 ?? ''}
          isErrored={errors?.['street2'] != null}
          onValueChangedCallback={(value) =>
            handleInputChange('street2', value)
          }
        />
      </ObField>
      <div className='flex flex-col sm:flex-row sm:gap-6'>
        <ObField
          isRequired={isRequired}
          inputId={`${inputId}.city`}
          label={'City'}
          variant={'field-in-group'}
          errorText={errors?.['city']?.message}
        >
          <ObInputControlled
            inputId={`${inputId}.city`}
            isLoading={isLoading ?? false}
            size={size}
            value={value.city || ''}
            placeholder='City'
            isDisabled={isDisabled}
            isErrored={errors?.['city'] != null}
            onValueChangedCallback={(value) => handleInputChange('city', value)}
          />
        </ObField>
        <ObField
          isRequired={isRequired}
          inputId={`${inputId}.state`}
          label={'State'}
          variant={'field-in-group'}
          errorText={errors?.['state']?.message}
        >
          <ObInputListbox
            size={size}
            options={usStates.map((state) => ({
              key: state.value,
              value: state.value,
              label: state.description,
              description: state.description,
              data: state,
            }))}
            value={value.state}
            placeholder='State'
            isDisabled={isDisabled}
            isErrored={errors?.['state'] != null}
            onValueChangedCallback={(value) =>
              handleInputChange('state', value)
            }
            isLoading={isLoading ?? false}
            inputId={`${inputId}.state`}
          />
        </ObField>
        <ObField
          isRequired={isRequired}
          inputId={`${inputId}.postalCode`}
          label={'Zip Code'}
          variant={'field-in-group'}
          errorText={errors?.['postalCode']?.message}
        >
          <ObInputControlled
            inputId={`${inputId}.postalCode`}
            size={size}
            isLoading={isLoading ?? false}
            isDisabled={isDisabled}
            value={value.postalCode}
            placeholder='Zip Code'
            isErrored={errors?.['postalCode'] != null}
            onValueChangedCallback={(value) =>
              handleInputChange('postalCode', value)
            }
          />
        </ObField>
      </div>
      <ObField
        isRequired={isRequired}
        inputId={`${inputId}.country`}
        label={'Country'}
        variant={'field-in-group'}
        errorText={errors?.['country']?.message}
      >
        <ObInputControlled
          inputId={`${inputId}.country`}
          size={size}
          isLoading={isLoading ?? false}
          isErrored={errors?.['country'] != null}
          placeholder='Country'
          value={value.country ?? ''}
          isDisabled={isDisabled}
          onValueChangedCallback={(value) =>
            handleInputChange('country', value)
          }
        />
      </ObField>
    </>
  );
};
