import { ReactNode, useEffect, useState } from 'react';
import { ObButton } from '../../../../components/elements/ob-button/ob-button';
import { ObLoadingSpinner } from '../../../../components/elements/ob-loading-spinner/ob-loading-spinner';
import { GoogleSearchAd } from '../../../templates/google-search-ads/google-search-ad';
import { TemplateComponentProps } from '../../template-component-props.type';
import { getTextFromTextValue } from '../../template-value-utilities';

const GoogleBorder = (props: { children: ReactNode }) => {
  return (
    <div
      style={{
        backgroundColor: '#202124',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '480px',
      }}
    >
      {props.children}
    </div>
  );
};

export const GoogleSearch01: React.FC<TemplateComponentProps> = ({
  values,
  size,
  focusedSettingId,
  onSettingClicked,
}: TemplateComponentProps) => {
  const [selectedHeading, setSelectedHeading] = useState(1);
  const [selectedDescription, setSelectedDescription] = useState(1);

  /**
   * We will automatically show the setting that the user last focused
   */
  useEffect(() => {
    if (focusedSettingId != null) {
      if (focusedSettingId.includes('google-search-heading-')) {
        setSelectedHeading(parseInt(focusedSettingId.split('-')[3]));
      } else if (focusedSettingId.includes('google-search-description-')) {
        setSelectedDescription(parseInt(focusedSettingId.split('-')[3]));
      }
    }
  }, [focusedSettingId]);

  if (values == null) {
    return (
      <>
        <ObLoadingSpinner
          includeBrand={true}
          fadeIn={true}
        />
      </>
    );
  }

  return (
    <div className='flex flex-row '>
      <div className='flex flex-col  justify-end'>
        <ObButton
          variant='ghost'
          buttonType='icon'
          iconLeft='chevronLeft'
          size='large'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedHeading((current) => {
              if (current === 1) {
                return 15;
              } else {
                return current - 1;
              }
            });
          }}
        />
        <ObButton
          variant='ghost'
          buttonType='icon'
          iconLeft='chevronLeft'
          size='large'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedDescription((current) => {
              if (current === 1) {
                return 4;
              } else {
                return current - 1;
              }
            });
          }}
        />
      </div>
      <GoogleBorder>
        <GoogleSearchAd
          url={'https://my-business.outbound.page/landing-page'}
          thumbnailUrl={''}
          heading={getTextFromTextValue(
            values.get(`google-search-heading-${selectedHeading}`)
          )}
          description={getTextFromTextValue(
            values.get(`google-search-description-${selectedDescription}`)
          )}
          onHeadingClicked={() => {
            onSettingClicked?.(`google-search-heading-${selectedHeading}`);
          }}
          onDescriptionClicked={() => {
            onSettingClicked?.(
              `google-search-description-${selectedDescription}`
            );
          }}
          featuredLinks={[]}
          size={size}
        />
      </GoogleBorder>
      <div className='flex flex-col justify-end'>
        <ObButton
          variant='ghost'
          buttonType='icon'
          color='secondary'
          size='large'
          iconLeft='chevronRight'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedHeading((current) => {
              if (current === 15) {
                return 1;
              } else {
                return current + 1;
              }
            });
          }}
        />
        <ObButton
          variant='ghost'
          buttonType='icon'
          size='large'
          color='secondary'
          iconLeft='chevronRight'
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedDescription((current) => {
              if (current === 4) {
                return 1;
              } else {
                return current + 1;
              }
            });
          }}
        />
      </div>
    </div>
  );
};
