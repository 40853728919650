import { ServerDelta } from '@outbound/types';
import { action, computed, makeObservable } from 'mobx';
import { BaseModel } from '../../base-model';
import CustomerProfile from '../../business-context/customer-profile/customer-profile';
import { RootStore } from '../../root-store';

export class CampaignCustomerProfile extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
  };

  constructor(rootStore: RootStore, id: string, workspaceId: string) {
    super(
      rootStore,
      'campaign/campaign-customer-profile',
      '1',
      id,
      workspaceId
    );
  }

  applyPatch(_patch: ServerDelta[]): void {
    //This relationship is not mutable until we implement version updates
  }

  public toJson(): Record<string, any> {
    return {
      //Not Extending Base JSON here
      [CampaignCustomerProfile.paths.id]: this._id,
    };
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      customerProfile: computed,
      applyPatch: action,
    } as any);
  }

  get customerProfile(): CustomerProfile | null {
    return this._rootStore.customerProfileStore.getById(this._id);
  }
}
