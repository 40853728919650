import { ObTypography } from '@outbound/design-system';
import { ObTypographyProps } from '@outbound/design-system/src/components/elements/ob-typography/ob-typography';
import { cx } from 'class-variance-authority';
import React, { ReactNode, useEffect, useState } from 'react';

export type AnimatedTypographyProps = Omit<ObTypographyProps, 'children'> & {
  text: ReactNode;
};

export const AnimatedTypography: React.FC<AnimatedTypographyProps> = ({
  text,
  variant,
  color,
}: AnimatedTypographyProps) => {
  const ANIMATION_DURATION = 500;
  const [currentText, setCurrentText] = useState(text);
  const [previousText, setPreviousText] = useState<ReactNode | null>(null);
  const [animationInProgress, setAnimationInProgress] =
    useState<boolean>(false);

  useEffect(() => {
    if (text !== currentText) {
      setCurrentText(text);
      setPreviousText(currentText);
    }
  }, [text, currentText]);

  useEffect(() => {
    if (previousText) {
      setTimeout(() => {
        setAnimationInProgress(true);
      });
      const timeoutId = setTimeout(() => {
        setPreviousText(null);
      }, ANIMATION_DURATION + 100); // The duration of the animation, adjust as needed

      setTimeout(() => {
        setAnimationInProgress(false);
      }, ANIMATION_DURATION + 200);
      return () => clearTimeout(timeoutId);
    }
  }, [previousText]);

  //1. Replace Current with Previous in View (Instantly)
  //2. Update Current
  //3. Animate Previous Down and Fade Out while animating Current Up and Fade In
  return (
    <div className='relative w-full flex items-center justify-center'>
      {/* This is the previous text */}
      {previousText && (
        <ObTypography
          className={cx(
            'absolute top-0 mx-auto ease-in-out',
            animationInProgress
              ? `transition-all duration-500 -translate-y-full opacity-0`
              : 'opacity-100 translate-y-0'
          )}
          variant={variant}
          color={color}
        >
          {previousText}
        </ObTypography>
      )}

      {/* This is the current text */}

      <ObTypography
        className={cx(
          'absolute top-0 mx-auto ease-in-out ',
          animationInProgress
            ? `transition-all duration-500 translate-y-0 opacity-100 `
            : '',
          !animationInProgress && previousText
            ? 'translate-y-full opacity-0'
            : ''
        )}
        variant={variant}
        color={color}
      >
        {currentText}
      </ObTypography>
    </div>
  );
};
