import { FC, useMemo } from 'react';
import {
  brandIconBlack,
  brandIconWhite,
  brandIconFullColor,
  brandLogoAndTagBlack,
  brandLogoAndTagWhite,
  brandLogoBlack,
  brandLogoFullColor,
  brandLogoOneColor,
  brandLogoWhite,
} from '../../../brand';
import { cva, cx } from 'class-variance-authority';

type brandVariant = 'logo' | 'icon' | 'logo-and-tag';
type brandColorScheme = 'monochromatic' | 'one-color' | 'full-color';

type size = 'xSmall' | 'xSmallish' | 'small' | 'medium' | 'large' | undefined;

export interface ObBrandProps {
  variant: brandVariant;
  size?: size;
  color?: brandColorScheme;
  contrast?: boolean;
}

const styleMap: any = {
  'logo#monochromatic#light': {
    asset: brandLogoBlack,
    testId: 'brand-logo-black',
    alt: 'Outbound.com Logo',
  },
  //   'logo#one-color#light': UNSUPPORTED
  'logo#one-color#dark': {
    asset: brandLogoOneColor,
    testId: 'brand-logo-one-color',
    alt: 'Outbound.com Logo',
  },
  'logo#full-color#dark': {
    asset: brandLogoFullColor,
    testId: 'brand-logo-full-color',
    alt: 'Outbound.com Logo',
  },
  'logo#full-color#light': {
    asset: brandLogoFullColor,
    testId: 'brand-logo-full-color',
    alt: 'Outbound.com Logo',
  },
  'logo#monochromatic#dark': {
    asset: brandLogoWhite,
    testId: 'brand-logo-white',
    alt: 'Outbound.com Logo',
  },
  'icon#monochromatic#light': {
    asset: brandIconBlack,
    testId: 'brand-icon-black',
    alt: 'Outbound.com Icon, An O styled as a power button with a lightning bolt',
  },
  'icon#monochromatic#dark': {
    asset: brandIconWhite,
    testId: 'brand-icon-white',
    alt: 'Outbound.com Icon, An O styled as a power button with a lightning bolt',
  },
  'icon#full-color#light': {
    asset: brandIconFullColor,
    testId: 'brand-icon-full-color',
    alt: 'Outbound.com Icon, An O styled as a power button with a colorful lightning bolt',
  },
  'icon#full-color#dark': {
    asset: brandIconFullColor,
    testId: 'brand-icon-full-color',
    alt: 'Outbound.com Icon, An O styled as a power button with a colorful lightning bolt',
  },
  'logo-and-tag#monochromatic#light': {
    asset: brandLogoAndTagBlack,
    testId: 'brand-logo-and-tag-black',
    alt: 'Outbound.com Logo with the tagline Power Your Sales Marketing underneath',
  },
  'logo-and-tag#monochromatic#dark': {
    asset: brandLogoAndTagWhite,
    testId: 'brand-logo-and-tag-white',
    alt: 'Outbound.com Logo with the tagline Power Your Sales Marketing underneath',
  },
};

const sizeClasses = cva('brand', {
  variants: {
    size: {
      xSmall: ['h-[14px]'],
      xSmallish: ['h-[18px]'],
      small: ['h-[24px]'],
      medium: ['h-[36px]'],
      large: ['h-[48px]'],
      undefined: [],
    },
  },
});

interface ModeImageProps {
  contrast: boolean;
  assetOnDark: any;
  assetOnLight: any;
  size: size;
}

const DarkModeImage = ({
  contrast,
  assetOnDark,
  assetOnLight,
  size,
}: ModeImageProps) => {
  const a = contrast ? assetOnDark : assetOnLight;
  return (
    <img
      className={cx('block dark:hidden', sizeClasses({ size }))}
      src={a.asset}
      alt={'Outbound.com Logo'}
      data-testid={a.testId}
    ></img>
  );
};

const LightModeImage = ({
  contrast,
  assetOnDark,
  assetOnLight,
  size,
}: ModeImageProps) => {
  const a = contrast ? assetOnLight : assetOnDark;
  return (
    <img
      className={cx('hidden dark:block ', sizeClasses({ size }))}
      src={a.asset}
      data-testid={a.testId}
      alt={'Outbound.com Logo'}
    ></img>
  );
};

export const ObBrand: FC<ObBrandProps> = ({
  variant,
  size,
  color = 'monochromatic',
  contrast = false,
}: ObBrandProps) => {
  const lightVariant = useMemo(
    () => `${variant}#${color}#light`,
    [variant, color]
  );

  const darkVariant = useMemo(
    () => `${variant}#${color}#dark`,
    [variant, color]
  );

  const assetOnLight = useMemo(() => styleMap[lightVariant], [lightVariant]);
  const assetOnDark = useMemo(() => styleMap[darkVariant], [darkVariant]);

  if (!assetOnLight || !assetOnDark) {
    return (
      <>
        Unsupported Brand Combination
        <p>{lightVariant}</p>
        <p>{darkVariant}</p>
      </>
    );
  }
  return (
    <>
      <LightModeImage
        contrast={contrast}
        assetOnDark={assetOnDark}
        assetOnLight={assetOnLight}
        size={size}
      />
      <DarkModeImage
        contrast={contrast}
        assetOnDark={assetOnDark}
        assetOnLight={assetOnLight}
        size={size}
      />
    </>
  );
};
