'use client';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  NotificationContent,
  ObNotification,
  ObNotificationProps,
} from './ob-notification';

export interface NotificationServiceContextValue {
  pushNotification: (notification: NotificationContent) => Promise<void>;
}

const NotificationServiceContext =
  createContext<NotificationServiceContextValue>({
    pushNotification: () => Promise.resolve(),
  });

interface NotificationServiceProviderProps {
  children?: ReactNode;
}

type NotificationAction =
  | { type: 'ADD_NOTIFICATION'; payload: ObNotificationProps }
  | { type: 'REMOVE_NOTIFICATION'; payload: string }
  | { type: 'HIDE_NOTIFICATION'; payload: string };

function notificationReducer(
  state: Array<ObNotificationProps>,
  action: NotificationAction
) {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return [...state, action.payload];
    case 'HIDE_NOTIFICATION':
      console.log('Hiding Notification');
      return state.map((notification) =>
        notification.id === action.payload
          ? { ...notification, show: false }
          : notification
      );
    case 'REMOVE_NOTIFICATION':
      console.log('Removing Notification');
      return state.filter((notification) => notification.id !== action.payload);
    default:
      return state;
  }
}

export const NotificationServiceProvider: FC<
  NotificationServiceProviderProps
> = ({ children }) => {
  /**
   * How long we want the notification to show before being removed from the display
   */
  const DEFAULT_NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS = 3000;
  /**
   * Should match the leave transition duration on ob-notification
   * so we don't remove the notification before the transition completes
   **/
  const ANIMATE_NOTIFICATION_OUT_TIME_IN_MILLISECONDS = 100;

  const [notificationStack, dispatch] = useReducer(notificationReducer, []);

  /**
   * Removes a notification from display by first hiding it, and than removing it from the stack.
   * This two step process is required for the remove animations to display correctly.
   * @param notificationId
   */
  const removeNotification = (notificationId: string) => {
    dispatch({ type: 'HIDE_NOTIFICATION', payload: notificationId });
    setTimeout(() => {
      dispatch({ type: 'REMOVE_NOTIFICATION', payload: notificationId });
    }, ANIMATE_NOTIFICATION_OUT_TIME_IN_MILLISECONDS);
  };

  const pushNotification = useMemo(() => {
    return async (notificationContent: NotificationContent) => {
      const notificationId = uuidv4();
      dispatch({
        type: 'ADD_NOTIFICATION',
        payload: {
          ...notificationContent,
          show: true,
          id: notificationId,
          onCloseNotificationCallback: () => removeNotification(notificationId),
        },
      });

      // Handling timeout and promise-based notifications
      if (notificationContent.progressPromise == null) {
        setTimeout(() => {
          removeNotification(notificationId);
        }, DEFAULT_NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
      } else {
        notificationContent.progressPromise.finally(() => {
          setTimeout(() => {
            removeNotification(notificationId);
          }, DEFAULT_NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
        });
      }
    };
  }, []);

  const service = useMemo(
    () => ({
      pushNotification,
    }),
    [pushNotification]
  );

  return (
    <NotificationServiceContext.Provider value={service}>
      {children}
      {/* Global notification live region */}
      <div
        aria-live='assertive'
        className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6'
      >
        <div className='flex w-full justify-end flex-col items-end space-y-4 '>
          {notificationStack.map((n) => (
            <ObNotification
              {...n}
              onCloseNotificationCallback={() => removeNotification(n.id)}
              key={n.id}
              show={n.show}
            />
          ))}
        </div>
      </div>
    </NotificationServiceContext.Provider>
  );
};

export const useNotificationService = () =>
  useContext(NotificationServiceContext);
