import { ObButton, ObProfile } from '@outbound/design-system';
import { FC } from 'react';

interface WorkspacePickerItemProps {
  workspaceName: string;
  workspaceSubTitle: string;
  workspaceAvatarImageUrl: string;
  onOpenWorkspaceCallback: (auth0Id: string) => void;
  workspaceAuth0OrganizationId: string;
  /**
   * Indicates that the component should display a loading state
   */
  loading: boolean;
}

export const WorkspacePickerItem: FC<WorkspacePickerItemProps> = ({
  workspaceName,
  workspaceSubTitle,
  workspaceAvatarImageUrl,
  onOpenWorkspaceCallback,
  workspaceAuth0OrganizationId,
  loading = false,
}) => {
  const handleWorkspaceOpenClick = () => {
    onOpenWorkspaceCallback(workspaceAuth0OrganizationId);
  };
  return (
    <div
      data-workspace-picker-item
      className='flex flex-row items-center justify-between rounded border border-dark/border/default/normal  gap-4 p-4 bg-dark/background/surface max-w-lg w-full'
    >
      <div className='flex-grow'>
        <ObProfile
          loading={loading}
          variant={'entity'}
          title={workspaceName}
          detail={workspaceSubTitle}
          avatarImageUrl={workspaceAvatarImageUrl}
        />
      </div>
      <div className=''>
        <ObButton
          disabled={loading}
          size='medium'
          aria-label={`Open ${workspaceName} Workspace`}
          onClick={handleWorkspaceOpenClick}
          variant={'primary'}
          label='Open'
        />
      </div>
    </div>
  );
};
