import { WorkspaceResource } from '@outbound/types';
import { useEffect, useRef, useState } from 'react';
import { useAuth0Axios } from '../../../services/auth0-axios-provider';

interface PollingState {
  isAvailable: boolean;
  error: string | null;
  isPolling: boolean;
  workspace: WorkspaceResource | null;
}

/**
 * Hook that will poll for a workspace till it exists
 * @param workspaceId
 * @returns
 */
export const useWaitForWorkspace = (workspaceId?: string) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const [state, setState] = useState<PollingState>({
    isAvailable: false,
    workspace: null,
    error: null,
    isPolling: false, // Start not polling if workspaceId is unavailable
  });

  const pollingRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!workspaceId) {
      // Wait for workspaceId before starting polling
      return;
    }

    setState((prev) => ({ ...prev, isPolling: true })); // Start polling
    const poll = async () => {
      try {
        const response = await auth0AxiosClient.get('/my-workspaces');

        const ws = response.data.items.find(
          (workspace: WorkspaceResource) => workspace.id === workspaceId
        );
        if (ws != null) {
          setState({
            isAvailable: true,
            error: null,
            isPolling: false,
            workspace: ws,
          });
          return; // Stop polling
        }

        // Continue polling if the condition is not met
        pollingRef.current = setTimeout(poll, 1000); // Hard-coded 1-second interval
      } catch (error) {
        setState({
          isAvailable: false,
          error: (error as Error).message,
          isPolling: false,
          workspace: null,
        });
      }
    };

    poll(); // Start polling

    // Cleanup on unmount
    return () => {
      if (pollingRef.current) {
        clearTimeout(pollingRef.current);
      }
    };
  }, [auth0AxiosClient, workspaceId]);

  return state;
};
