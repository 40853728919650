import {
  ListResponse,
  PostWorkspaceInvitesRequestResource,
  WorkspaceInvitationResource,
} from '@outbound/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

/**
 * Get /workspace/invites
 * Query list all invites for the current workspace
 * @returns
 */
export const useFetchWorkspaceInvites = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useQuery<
    undefined,
    undefined,
    ListResponse<WorkspaceInvitationResource>
  >(['workspace:invites:get'], async () => {
    const response = await auth0AxiosClient.get('/workspace/invites');
    return response.data;
  });
};

/**
 * POST /workspace/invites
 * Mutation to send one or more invites to join the current workspace
 * @returns
 */
export const useSendInvites = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, PostWorkspaceInvitesRequestResource>(
    ['workspace:invites:post'],
    async (data: PostWorkspaceInvitesRequestResource) => {
      const response = await auth0AxiosClient.post('/workspace/invites', data);
      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['workspace:invites:get']);
      },
    }
  );
};

/**
 * DELETE /workspace/invites
 * Mutation to remove an invites by it's id from the current workspace
 * @returns
 */
export const useRemoveInvite = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const queryClient = useQueryClient();
  return useMutation(
    ['workspace:invites:delete'],
    async (inviteId: string) => {
      const response = await auth0AxiosClient.delete(
        `/workspace/invites/${inviteId}`
      );
      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['workspace:invites:get']);
      },
    }
  );
};
