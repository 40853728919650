export interface ObTableBodyProps {
  children?: React.ReactNode;
}

export const ObTableBody = ({ children }: ObTableBodyProps) => {
  return (
    <tbody className='divide-y divide-borderDefaultNormalDark '>
      {children}
    </tbody>
  );
};
