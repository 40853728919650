import { BrandGuideResource } from '@outbound/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import { playbookSettingsKeys } from './playbook-query-key';

const SERVICE_RESOURCE_PATH = '/playbook/settings/brand-guide';

const RESOURCE_NAME = 'brand-guide';

export const useFetchBrandGuide = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, BrandGuideResource>(
    playbookSettingsKeys.resource(RESOURCE_NAME),
    async () => {
      const response = await auth0AxiosClient.get(SERVICE_RESOURCE_PATH, {
        params: { include: 'assets' },
      });
      return response.data;
    }
  );
};

export const usePatchBrandGuide = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<undefined, undefined, { values: BrandGuideResource }>(
    [...playbookSettingsKeys.resource(RESOURCE_NAME), 'patch'],
    async ({ values }) => {
      console.log('Patch', values);
      const response = await auth0AxiosClient.patch(
        `${SERVICE_RESOURCE_PATH}`,
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};
