import {
  CreativeResource,
  CreativeTemplateValueResource,
  ServerDelta,
  SettingDefinitionResource,
} from '@outbound/types';
import { BaseTransformer } from '../base-transformer';
import Creative from './creative';
import BaseSetting from './setting/base-setting';
import SettingTransformer from './setting/setting-transformer';

class CreativeTransformer extends BaseTransformer<CreativeResource, Creative> {
  readonly settingTransformer: SettingTransformer;
  constructor(rootStore: any) {
    super(rootStore);
    this.settingTransformer = new SettingTransformer(rootStore);
  }

  /**
   * Examine the current model and the resource and create a patch that can be applied to the model.
   * @param model
   * @param resource
   * @returns
   */
  createPatchForCurrentModelAndIncomingResource(
    model: Creative,
    resource: CreativeResource
  ): ServerDelta[] {
    const patch: Array<ServerDelta> = [];

    if (model.id !== resource.id) {
      throw new Error('Model and Resource ID Mismatch');
    }

    /**
     * Handle changes to the Creative Status
     */
    if (resource.status !== model.lifecycleStatus) {
      patch.push(
        this.createSimulatedServerDelta(
          model,
          resource.status,
          Creative.paths.lifecycleStatus,
          'replace'
        )
      );
    }

    /**
     * Handle changes to the Creative Change
     */
    if (resource.name !== model.name) {
      patch.push(
        this.createSimulatedServerDelta(
          model,
          resource.name,
          Creative.paths.name,
          'replace'
        )
      );
    }

    /**
     * Check if Settings have been added.
     * This will typically happen if the creative has gone from initialization to active.
     * or if the template has been updated.
     */
    resource.templateSettingFieldValues.forEach((settingValue) => {
      const setting = model.settings.get(settingValue.id);
      if (!setting) {
        const settingDefinition = resource.template.settingDefinitions.find(
          (definition) => definition.id === settingValue.id
        );
        if (settingDefinition) {
          patch.push(
            this.createSimulatedServerDelta(
              model,
              this.generateCombinedSettingResourceFromDefinitionAndValue(
                resource.id,
                resource.workspaceId,
                settingDefinition,
                settingValue
              ),
              `${Creative.paths.settings}/${settingValue.id}`,
              'add'
            )
          );
        }
      }
    });

    return patch;
  }

  /**
   * The Setting Model has a complex "Resource" Type that is a combination of the Setting Definition and the Setting Value and some ids.
   * This shape does not exist on the API and is a combination of several pieces of data.
   *
   * This is a utility method to generate the combined resource from the definition and the value.
   * @param creativeId
   * @param workspaceId
   * @param settingDefinition
   * @param value
   * @returns
   */
  private generateCombinedSettingResourceFromDefinitionAndValue(
    creativeId: string,
    workspaceId: string,
    settingDefinition: SettingDefinitionResource,
    value: CreativeTemplateValueResource
  ) {
    return {
      settingSchema: settingDefinition,
      settingValue: value,
      workspaceId,
      creativeId,
    };
  }

  private generateSettingFromResource(
    creativeId: string,
    workspaceId: string,
    settingDefinition: SettingDefinitionResource,
    values: CreativeTemplateValueResource[]
  ) {
    const settingValue = values.find(
      (settingValue) => settingValue.id === settingDefinition.id
    );
    if (settingValue) {
      const setting = this.settingTransformer.fromApiResource(
        this.generateCombinedSettingResourceFromDefinitionAndValue(
          creativeId,
          workspaceId,
          settingDefinition,
          settingValue
        )
      );
      return setting;
    } else {
      throw new Error('Setting Value Not Found');
    }
  }

  public fromApiResource(resource: CreativeResource): Creative {
    const settings: Map<string, BaseSetting> = new Map();

    //It only makes sense to check these fields if the Creative is active
    if (resource.status === 'ACTIVE') {
      resource.template.settingDefinitions.forEach((settingField) => {
        const setting = this.generateSettingFromResource(
          resource.id,
          resource.workspaceId,
          settingField,
          resource.templateSettingFieldValues
        );
        settings.set(setting.id, setting);
      });
    }

    return new Creative(
      this._rootStore,
      resource.id,
      resource.workspaceId,
      resource.name,
      resource.status,
      'latestVersion',
      resource.medium,
      resource.templateId,
      settings
    );
  }
}

export default CreativeTransformer;
