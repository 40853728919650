import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';
import { AvatarIconStyleProps } from '../../avatars/avatar.types';
import { ObDivider } from '../../components/elements/ob-divider/ob-divider';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObCardSurface } from '../components/card-surface/ob-card-surface';
import { ObCardHeaderWithIcon } from '../components/ob-card-header-icon/ob-card-header-icon';

/**
 * The public props of the ViewMoreCard component
 */
export interface ObViewMoreCardProps extends AvatarIconStyleProps {
  /**
   * The heading of the card
   */
  heading: string;
  /**
   * The elements shown in the body of the card;
   * You should only provide just enough content to fill the card or screen reader users will have to listen to all of it.
   */
  children: ReactNode;
  /**
   * Height of card to create visual consistency in the grid. Must be specified with unit of measurement. Ex. '200px'
   */
  height: string;
}

/**
 * A View More Card is used when we want to indicate that there is more content available to the user;
 * Specifically this was designed for the playbook when the user was shown a grid of cards and asked to verify they were correct.
 * They said yes without clicking on the card to view the content and made the decision based on the title and description alone.
 * When using this card you should provide enough content to fill the card so that gradient effect is visible
 */
export const ObViewMoreCard = ({
  heading,
  icon: headingIcon,
  color: headingIconColor,
  children,
  height,
}: ObViewMoreCardProps) => {
  return (
    <ObCardSurface>
      <div
        className='p-4 flex flex-col flex-1 group cursor-pointer'
        style={{ height: height }}
      >
        <div className='pb-3'>
          <ObCardHeaderWithIcon
            heading={heading}
            icon={headingIcon}
            color={headingIconColor}
          />
        </div>
        <ObDivider />
        {/* Child content should not be readable or selectable */}
        <div
          className='flex-shrink overflow-hidden fade-to-transparent-mask pt-3 -mb-8 h-full'
          role='presentation'
          aria-hidden='true'
        >
          {children}
        </div>
        <div className='flex flex-1 justify-center relative items-end'>
          <div
            className={cx(
              'border border-dark/border/default/normal rounded',
              'bg-dark/background/surface group-hover:bg-dark/background/surface+1 group-active:bg-dark/action/neutral/active',
              ' pb-1 px-4',
              ''
            )}
          >
            <ObTypography
              variant='body2'
              as='span'
              className=''
            >
              View More
            </ObTypography>
          </div>
        </div>
      </div>
    </ObCardSurface>
  );
};
