import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { timeAgoFromIsoString } from '@outbound/design-system';
import { FC } from 'react';
import { CellActions } from '../../../../../components/molecules/data-grid/cell-actions';
import { CellUserProfile } from '../../../../../components/molecules/data-grid/cell-user-profile';
import { ObDataGrid } from '../../../../../components/organisms/data-grid/ob-data-grid';
import {
  useFetchWorkspaceInvites,
  useRemoveInvite,
} from '../../../../../query/workspace/use-workspace-invites-endpoints';

export const WorkspaceInvitesDataGrid: FC = () => {
  const { data: invitations, isLoading } = useFetchWorkspaceInvites();
  const {
    mutateAsync: removeInvitation,
    isLoading: removeInvitationIsLoading,
  } = useRemoveInvite();

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Invite',
      flex: 1,
      minWidth: 248,
      editable: false,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <CellUserProfile emailAddress={params.value} />;
      },
    },
    {
      field: 'expiresAtTimestamp',
      headerName: 'Expires',
      flex: 1,
      minWidth: 100,
      editable: false,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return timeAgoFromIsoString(params.value);
      },
    },
    {
      field: 'id',
      headerName: 'Actions',
      type: 'actions',
      hideable: false,
      filterable: false,
      minWidth: 72,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CellActions
            ariaActionsFor={params.row.inviteSentTo}
            actionsLoading={removeInvitationIsLoading}
            actions={[
              {
                name: 'Delete Invitation',
                icon: 'delete',
                ariaLabel: `Delete invite for ${params.row.inviteSentTo}`,
                keyCommand: '',
                isLoading: removeInvitationIsLoading,
                callback: () => {
                  return removeInvitation(params.row.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <ObDataGrid
      rows={invitations?.items ?? []}
      columns={columns}
      loading={isLoading}
      noContentCtaHeading={'No Pending Invites'}
      noContentCtaSubHeading={'Once you send an invite it will appear here'}
    />
  );
};
