import { LocationCard } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import PhysicalLocation from '../../state/mobx-experiment/business-context/location/physical-location/physical-location';
import ServiceAreaLocation from '../../state/mobx-experiment/business-context/location/service-area/service-area';

export interface LocationModelCardProps {
  location: PhysicalLocation | ServiceAreaLocation;
  variant: 'grid';
}

/**
 * Wraps the LocationCard from the design system with a mapping to the Location mobx model
 * @returns
 */
export const LocationModelCard = observer(
  ({ location, variant }: LocationModelCardProps) => {
    return (
      <LocationCard
        variant={variant}
        onNavigateCallback={(locationObrn) => {
          console.log('Navigate to location', locationObrn);
        }}
        obrn={location.obrn}
        id={location.id || 'Unknown'}
        name={location.name || 'Unknown'}
        description={location.geographicDescription || 'Unknown'}
        type={location.locationType ?? 'PHYSICAL'}
        locationImageForDarkBackgroundPublicUrl={
          location.mapImageForDarkBackground?.publicUrl
        }
        locationImageForLightBackgroundPublicUrl={
          location.mapImageForLightBackground?.publicUrl
        }
        locationImageStatus={
          location.mapImageGenerationStatus ??
          'WAITING_FOR_MAP_IMAGE_GENERATION'
        }
      />
    );
  }
);
