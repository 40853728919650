import { AssetResource } from '@outbound/types';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Asset from './asset';
import AssetTransformer from './asset-transformer';

export class AssetStore extends BaseStore<Asset> {
  private assetTransformer: AssetTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'asset');
    this.assetTransformer = new AssetTransformer(rootStore);
    this.rootStore.transport.assetTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
  }

  protected getModelFromStore(id: string): Asset | null {
    return this.modelMap.get(id) ?? null;
  }

  protected requestLoadModelFromServer(id: string): void {
    this.rootStore.transport.assetTransport.requestResourceById(id);
  }

  private handleServerUpdate = (
    id: string,
    resource: AssetResource | null
  ): void => {
    this.handleServerUpdateGeneric(id, resource, this.assetTransformer);
  };

  /**
   * Get an Asset By It's ID.
   * Null Means the Asset Does not Exist.
   * @param id
   * @returns
   * @throws Promise for Suspense if the asset is being fetched
   */
  public getById(id: string): Asset | null {
    const asset = this.getModelFromStoreOrLazyLoadFromServerById(id);

    asset?.makeObservable(); // Ensure the service is observable before returning it to the caller
    return asset;
  }
}
