export const GeneralSettings = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='solid/general/settings-04'>
        <g id='Solid'>
          <path
            d='M9.41739 5.99999L2.00004 5.99999C1.63185 5.99999 1.33337 5.70151 1.33337 5.33332C1.33337 4.96513 1.63185 4.66666 2.00004 4.66666L9.41739 4.66666C9.71341 3.51651 10.7575 2.66666 12 2.66666C13.4728 2.66666 14.6667 3.86056 14.6667 5.33332C14.6667 6.80608 13.4728 7.99999 12 7.99999C10.7575 7.99999 9.71342 7.15014 9.41739 5.99999Z'
            fill='currentColor'
          />
          <path
            d='M4.00004 7.99999C2.52728 7.99999 1.33337 9.1939 1.33337 10.6667C1.33337 12.1394 2.52728 13.3333 4.00004 13.3333C5.2426 13.3333 6.28667 12.4835 6.5827 11.3333L14 11.3333C14.3682 11.3333 14.6667 11.0348 14.6667 10.6667C14.6667 10.2985 14.3682 9.99999 14 9.99999L6.5827 9.99999C6.28667 8.84984 5.2426 7.99999 4.00004 7.99999Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
