import {
  ObFormRenderer,
  useDrawerService,
  useObFormRendererContext,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useRootStore } from '../../../../../../state/mobx-experiment/use-root-store';
import { PlaybookDrawerContent } from '../../_common/playbook-drawer-content';
import { mapFormValuesToApiResource } from '../forms/service-area-form-utils';
import { SERVICE_AREA_FORM_DEF } from '../forms/service-area-form.def';

export const ServiceAreaCreateDrawer = observer(() => {
  const { getRegisteredFormById } = useObFormRendererContext();
  const drawerService = useDrawerService();

  const { locationStore } = useRootStore();

  const handleCreateNewServiceArea = useCallback(
    async (values: any) => {
      await locationStore.createServiceArea({
        ...mapFormValuesToApiResource(values),
      });

      drawerService.popDrawer();
    },
    [drawerService, locationStore]
  );

  return (
    <PlaybookDrawerContent
      primaryActionLabel={'Create'}
      primaryActionCallback={async () => {
        return getRegisteredFormById(
          SERVICE_AREA_FORM_DEF.id
        )?.submitFunction();
      }}
      secondaryActionLabel={'Cancel'}
      secondaryActionCallback={async () => {
        getRegisteredFormById(
          SERVICE_AREA_FORM_DEF.id
        )?.discardChangesFunction();
        drawerService.popDrawer();
      }}
    >
      <ObFormRenderer
        formDefinition={SERVICE_AREA_FORM_DEF}
        hideSubmitButton={true}
        onSubmitCallback={handleCreateNewServiceArea}
      />
    </PlaybookDrawerContent>
  );
});

export const useServiceAreaCreateDrawer = () => {
  const drawerService = useDrawerService();

  const openNewLocationDrawer = () => {
    drawerService.pushDrawer({
      title: 'Add New Service Area',
      description:
        'Add and complete the information below to create a new service area.',
      drawerContent: <ServiceAreaCreateDrawer />,
      size: 'medium',
    });
  };

  return { openNewLocationDrawer };
};
