import { BaseSection } from './components/base-section';
import { SectionPlaceholder } from './components/section-placeholder';

export interface LeadsSectionProps {
  /**
   * Indicates that this campaign has attribution available
   * (Meaning we can tie actions on the landing page back to this campaign)
   *
   * This is always true when using an Outbound AI Landing Page it is not true when using
   * a customer page unless conversion codes are added to the page.
   */
  isAttributionAvailableForCampaign: boolean;
  /**
   * The current plan of the workspace that this campaign is in.
   * The Leads feature is only available on the Pro Plan and above.
   */
  currentWorkspacePlan: 'free' | 'pro' | 'enterprise';
}
export const LeadsSection = ({ currentWorkspacePlan }: LeadsSectionProps) => {
  return (
    <BaseSection
      heading={
        currentWorkspacePlan === 'free'
          ? 'Leads (Available on Pro Plan)'
          : 'Leads'
      }
      subheading='The most recent leads generated by this campaign'
      showPlaceholder={true}
      placeholderNode={
        currentWorkspacePlan === 'free' ? (
          <SectionPlaceholder
            heading='Pro Plan Required'
            message='Lead capture is only available on the Pro Plan and above. Coming Soon!'
            icon='lock'
            documentationOnClick={() => {
              console.log('Go to documentation');
            }}
          />
        ) : (
          <SectionPlaceholder
            heading='No Leads Yet'
            message='Once your first lead arrives it will show up here'
            documentationOnClick={() => {
              console.log('Go to documentation');
            }}
          />
        )
      }
    ></BaseSection>
  );
};
