import { OutboundCampaignGoal } from '@outbound/types';
import { RootStore } from '../../root-store';
import CampaignOutboundGoalContext from './base-ob-goal-context';

export class CampaignOutboundGoalBrandAwarenessContext extends CampaignOutboundGoalContext {
  constructor(_rootStore: RootStore, type: OutboundCampaignGoal) {
    super(_rootStore, type);
  }

  //Future Add Brand Awareness specific properties here
}
