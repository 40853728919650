import { ObTypography } from '@outbound/design-system';

export const FullPageSecondaryText = ({ message }: { message: string }) => {
  return (
    <div className='max-w-screen-xl m-auto min-h-screen flex items-center justify-center'>
      <ObTypography
        variant='body2'
        color='secondary'
      >
        {message}
      </ObTypography>
    </div>
  );
};
