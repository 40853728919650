import {
  ObCxStatus,
  ObIcon,
  ObInputListbox,
  ObMetricCardBar,
  ObMetricCardCampaign,
  ObQualityFeedbackButtonGroup,
  ObTable,
  ObTableBody,
  ObTableHeader,
  ObTableHeaderCell,
  ObTableRow,
  ObTypography,
  QualityRanking,
  timeAgoFromIsoString,
} from '@outbound/design-system';
import { ObMetricCardBase } from '@outbound/design-system/src/components/molecules/ob-metric-card-base/ob-metric-card-base';
import { ObMetricCardXBarProps } from '@outbound/design-system/src/components/molecules/ob-metric-card-x-bar/ob-metric-card-x-bar';
import {
  ObCampaignSummaryCell,
  ObLeadSourceAvatar,
} from '@outbound/design-system/src/components/organisms/ob-metric-card-campaign/ob-metric-card-campaign';
import { CampaignChannelType, CampaignGoal } from '@outbound/types';

import { useState } from 'react';

const dummyMetrics: Array<ObMetricCardXBarProps> = [
  {
    title: 'Total Form Submissions',
    value: 50,
    maxValue: 100,
    color: 'success',
  },
  {
    title: 'First Time Form Submissions',
    value: 26,
    maxValue: 100,
    color: 'purple',
  },
  {
    title: 'Form Submissions Follow Ups',
    value: 88,
    maxValue: 100,
    color: 'informative',
  },
  {
    title: 'Abandoned Form Submissions',
    value: 12,
    maxValue: 100,
    color: 'primary',
  },
];

export const DashboardHeader = () => {
  const [periodFilter, setPeriodFilter] = useState<string>('last-7-days');
  return (
    <header className='flex flex-row justify-between items-center w-full'>
      <div className='flex flex-col'>
        <ObTypography
          variant='h3'
          as='h1'
        >
          Hello Patrick!
        </ObTypography>
        <ObTypography
          variant='body1'
          color='tertiary'
        >
          Last Updated 5 Minutes Ago
        </ObTypography>
      </div>
      <div className='flex flex-row gap-2 justify-center items-center'>
        <button className='sm:hidden'>
          <ObIcon
            icon='calendar'
            size='large'
            color='secondary'
          />
        </button>
        <div className='hidden sm:flex flex-row gap-2 items-center'>
          <label htmlFor='period'>
            <ObTypography
              variant='body1'
              color='secondary'
              as='span'
            >
              Period:
            </ObTypography>
          </label>

          <ObInputListbox
            options={[
              {
                label: 'Last 7 Days',
                value: 'last-7-days',
                description: '',
                data: undefined,
              },
              {
                label: 'Last 30 Days',
                value: 'last-30-days',
                description: '',
                data: undefined,
              },
              {
                label: 'Last 90 Days',
                value: 'last-90-days',
                description: '',
                data: undefined,
              },
            ]}
            isDisabled={false}
            value={periodFilter}
            onValueChangedCallback={setPeriodFilter}
            isLoading={false}
            inputId={'period'}
          />
        </div>
      </div>
    </header>
  );
};

export const DashboardSummary = () => {
  return (
    <section className='flex flex-col gap-2 mt-4'>
      <div className='flex flex-row gap-2 items-center justify-start'>
        <ObCxStatus
          status='nominal'
          size='medium'
        />

        <ObTypography variant='h4'>
          Great news – everything is running smoothly!
        </ObTypography>
      </div>
      <ObTypography>
        All of your campaigns are performing as expected and are in line with
        your goals and the rest of the lawn care industry. We are currently
        running some experiments with 10% of your Ad Budget in order to improve
        your results even further! Once we have collected enough data to see if
        the changes are working, we will let you know. If this experiment
        doesn't work out we will automatically revert to the previous settings
        and try something else!
      </ObTypography>
    </section>
  );
};

export const DashboardPage = () => {
  const [quality, setQuality] = useState<QualityRanking>('neutral');
  const campaigns = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      campaign: {
        title: 'Google Search',
        channel: CampaignChannelType.GOOGLE_SEARCH_ADS,
        goal: CampaignGoal.LEAD_GEN,
      },
    },
    {
      name: 'Dries Vincent',
      title: 'Back-end Developer',
      email: 'Dries.Vincent@example.com',
      role: 'Member',
      campaign: {
        title: 'Facebook Ads',
        channel: CampaignChannelType.FACEBOOK_ADS,
        goal: CampaignGoal.PURCHASE,
      },
    },
    {
      name: 'Jenny Wilson',
      title: 'Front-end Developer',
      email: 'Jenny.Wilson@example.com',
      role: 'Member',
      campaign: {
        title: 'Google Search',
        channel: CampaignChannelType.GOOGLE_SEARCH_ADS,
        goal: CampaignGoal.LEAD_GEN,
      },
    },

    // More people...
  ];
  return (
    <div className='max-w-6xl w-full mx-auto p-6'>
      <DashboardHeader />
      <article className='flex flex-col flex-grow w-full gap-6'>
        <DashboardSummary />
        <section className='flex flex-row justify-between gap-6 w-full  flex-wrap lg:flex-nowrap'>
          <ObMetricCardCampaign
            title='Campaigns'
            status={'nominal'}
            context={{ title: '', status: 'nominal' }}
            campaigns={[]}
          />
          <div className='flex flex-col gap-6 lg:w-2/5 flex-wrap w-full'>
            <ObMetricCardBar
              title='Forms'
              status='nominal'
              metrics={dummyMetrics}
              context={{
                title: '',
                status: 'nominal',
              }}
            />
            <ObMetricCardBar
              title='Calls'
              status='nominal'
              metrics={dummyMetrics}
              context={{
                title: '',
                status: 'nominal',
              }}
            />
          </div>
        </section>
        <section>
          <ObMetricCardBase
            title={'Leads'}
            status={'suggestion'}
            context={{ status: 'nominal', title: '' }}
          >
            <ObTable>
              <ObTableHeader>
                <ObTableHeaderCell label='Contact' />
                <ObTableHeaderCell
                  label='Campaign'
                  hideOnDevicesSmallerThan='lg'
                />
                <ObTableHeaderCell label='Source' />
                <ObTableHeaderCell
                  label='Created At'
                  hideOnDevicesSmallerThan='sm'
                />
                <ObTableHeaderCell label='Lead Rating' />
              </ObTableHeader>
              <ObTableBody>
                {campaigns.map((person) => (
                  <ObTableRow key={person.email}>
                    <td
                      data-column='campaign'
                      className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0'
                    >
                      <ObTypography>{person.name}</ObTypography>
                      <dl className='font-normal lg:hidden'>
                        <dt className='sr-only sm:hidden'>Email</dt>
                        <dd className='mt-1 truncate  sm:hidden mb-2'>
                          <ObCampaignSummaryCell
                            size='compact'
                            title={person.campaign.title}
                            channel={person.campaign.channel}
                            goal={CampaignGoal.LEAD_GEN}
                          />
                        </dd>
                        <dt className='sr-only'>Created At</dt>
                        <dd className='mt-1 truncate text-gray-700'>
                          <ObTypography variant='body3'>
                            {timeAgoFromIsoString('2023-12-04T18:00:00Z')}
                          </ObTypography>
                        </dd>
                      </dl>
                    </td>
                    <td
                      data-column='campaign'
                      className='hidden px-3 py-4 text-sm  lg:table-cell'
                    >
                      <ObCampaignSummaryCell
                        size='compact'
                        title={person.campaign.title}
                        channel={person.campaign.channel}
                        goal={CampaignGoal.LEAD_GEN}
                      />
                    </td>
                    <td
                      data-column='source'
                      className='px-3 py-4 text-sm '
                    >
                      <ObLeadSourceAvatar leadSource={'phone'} />
                    </td>
                    <td className='hidden px-3 py-4 text-sm  sm:table-cell '>
                      <ObTypography>
                        {timeAgoFromIsoString('2023-12-04T18:00:00Z')}
                      </ObTypography>
                    </td>
                    <td className='pr-3 sm:pr-0 text-right'>
                      <ObQualityFeedbackButtonGroup
                        value={quality}
                        onValueChangedCallback={setQuality}
                      />
                    </td>
                  </ObTableRow>
                ))}
              </ObTableBody>
            </ObTable>
          </ObMetricCardBase>
        </section>
      </article>
    </div>
  );
};
