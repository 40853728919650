import {
  AssetClassificationType,
  AssetQueryParameters,
  AssetResource,
  AssetStatusType,
  CreateAssetResponseResource,
  CursorPaginationResponse,
  PatchAssetRequestResource,
  PostAssetRequestResource,
  UploadAssetWithPreSignedUrlRequestResource,
} from '@outbound/types';
import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import { getAssetMimeType } from '../../utilties/mimetype-utilities';
import { playbookSettingsKeys } from '../playbook/playbook-query-key';

/**
 * Query Keys for Asset Queries
 */
export const assetKeys = {
  all: ['assets'] as const,
  lists: () => [...assetKeys.all, 'list'] as const,
  list: (params: AssetQueryParameters) =>
    [...assetKeys.lists(), { ...params }] as const,
  details: () => [...assetKeys.all, 'detail'] as const,
  detail: (id: string) => [...assetKeys.details(), id] as const,
};

const assetResourceCacheKey = (id: string) => ['assets/', id];

export const useFetchAssetManagerAssets = (params: AssetQueryParameters) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const queryClient = useQueryClient();

  return useQuery<
    undefined,
    undefined,
    CursorPaginationResponse<AssetResource>
  >(
    assetKeys.list(params),
    async () => {
      const response = await auth0AxiosClient.get('/asset-manager/assets', {
        params,
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        // Update the cache for each asset in the response
        data?.items?.forEach((item) => {
          queryClient.setQueryData(assetResourceCacheKey(item.id), item);
        });
      },
    }
  );
};

export const useFetchAssetById = (id: string | undefined) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, AssetResource>(
    assetKeys.detail(id!),
    async () => {
      const response = await auth0AxiosClient.get(
        `/asset-manager/assets/${id}`
      );
      return response.data;
    },
    {
      enabled: !!id, //Don't fetch if there is no id
    }
  );
};

export const usePollForActiveAssetById = (id: string | null) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<AssetResource>(
    assetKeys.detail(id!),
    async () => {
      const response = await auth0AxiosClient.get<AssetResource>(
        `asset-manager/assets/${id}`
      );
      return response.data;
    },
    {
      enabled: !!id,
      refetchInterval: (data?: AssetResource) => {
        const shouldPoll =
          data?.status == null || data.status === 'PENDING_FILE_UPLOAD';

        return shouldPoll ? 2000 : false;
      },
      refetchIntervalInBackground: true,
      onSuccess: (data?: AssetResource) => {
        if (data?.status === AssetStatusType.ACTIVE) {
          queryClient.invalidateQueries(['asset-manager/assets:get']);
        }
      },
    }
  );
};

/**
 * Creates a new asset in the asset manager for the given file.
 * This returns a presigned URL that you must use to actually upload the file.
 */
export const useCreateAssetMutationForFile = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useMutation<
    CreateAssetResponseResource,
    undefined,
    {
      file: File;
      classification?: AssetClassificationType;
      description?: string;
    }
  >(
    ['asset-manager/assets:post'],
    async ({ file, classification, description }) => {
      //Explore moving this to a utility function
      const fileParts = file.name.split('.');
      const mineType = getAssetMimeType(fileParts[fileParts.length - 1]);

      const data: PostAssetRequestResource = {
        fileName: file.name,
        mimeType: mineType,
        classification: classification ?? AssetClassificationType.GENERAL,
        description: description,
      };

      const response = await auth0AxiosClient.post(
        '/asset-manager/assets',
        data
      );

      return response.data;
    },
    {
      onSuccess: () => {
        //
      },
    }
  );
};

export const useCreateAssetMutation = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    { id: string; presignedUrl: string },
    undefined,
    PostAssetRequestResource
  >(
    ['asset-manager/assets:post'],
    async (data: PostAssetRequestResource) => {
      const response = await auth0AxiosClient.post(
        '/asset-manager/assets',
        data
      );

      return response.data;
    },
    {
      onSuccess: () => {
        //
      },
    }
  );
};

export const usePatchAssetByIdMutation = (id: string | null | undefined) => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: Partial<PatchAssetRequestResource> }
  >(
    ['assets/update', id],
    async ({ values }) => {
      if (id == null) {
        throw new Error('Cannot Update Asset. An asset id must be provided.');
      }
      const response = await auth0AxiosClient.patch(
        '/asset-manager/assets/' + id,
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        /**
         * These are overly aggressive cache busts.
         * We should be more specific in the future if possible.
         */
        queryClient.invalidateQueries(assetKeys.all);
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

export const useUploadAssetMutation = () => {
  return useMutation<string, undefined, any>(
    ['assets:upload-asset'],
    async (data: UploadAssetWithPreSignedUrlRequestResource) => {
      const onUploadProgress = (progressEvent: any) => {
        data.uploadProgress?.(
          progressEvent,
          data.fileContents,
          data.presignedPostUrl,
          data.id
        );
      };

      const response = await axios.put(
        data.presignedPostUrl,
        data.fileContents,
        {
          headers: {
            'Content-Type': data.fileMimeType,
            'Content-Disposition': `inline`,
          },
          onUploadProgress,
        }
      );

      return response.data.statusText;
    },
    {
      onSuccess: () => {
        //
      },
    }
  );
};

export const useDeleteAssetMutation = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<string, undefined, string>(
    ['assets:delete-asset'],
    async (assetId: string) => {
      const response = await auth0AxiosClient.delete(
        '/asset-manager/assets/' + assetId
      );

      return response.data;
    },
    {
      onSuccess: () => {
        //Future cache busting here
      },
    }
  );
};
