import {
  CampaignResource,
  CampaignResourceWithCreative,
} from '@outbound/types';
import { RootStore } from '../../root-store';
import CampaignOutboundGoalContext from '../campaign-ob-goal-context/base-ob-goal-context';
import { CampaignOutboundGoalBrandAwarenessContext } from '../campaign-ob-goal-context/ob-brand-awareness-goal-context';
import CampaignOutboundGoalLeadsForServiceContext from '../campaign-ob-goal-context/ob-leads-for-service-goal-context';

class CampaignGoalContextTransformer {
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
  }

  public fromApiResource(
    campaignResource: CampaignResourceWithCreative | CampaignResource
  ): CampaignOutboundGoalContext {
    switch (campaignResource.outboundCampaignGoal) {
      case 'LEAD_GEN_FOR_SERVICE': {
        return new CampaignOutboundGoalLeadsForServiceContext(
          this._rootStore,
          'LEAD_GEN_FOR_SERVICE',
          'serviceId',
          'serviceVersion'
        );
      }
      case 'BRAND_AWARENESS': {
        return new CampaignOutboundGoalBrandAwarenessContext(
          this._rootStore,
          'BRAND_AWARENESS'
        );
      }
    }
  }
}

export default CampaignGoalContextTransformer;
