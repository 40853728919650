import { AssetResource, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../base-transformer';

import { RootStore } from '../root-store';
import { simulatePatch } from '../sync-framework/patch-simulator/patch-simulator';
import ImageAsset from './image-asset';

class ImageAssetTransformer extends BaseTransformer<AssetResource, ImageAsset> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  fromApiResource(resource: AssetResource): ImageAsset {
    /**
     * We are having to to do a lot of null checks because the typing is made for any status
     * In reality this Asset should always have a publicUrl. It only doesn't when the asset is initializing.
     * We many want to address the API Types to better reflect this.
     */
    return new ImageAsset(
      this._rootStore,
      resource.id,
      resource.workspaceId,
      resource.status,
      resource.mimeType,
      resource.description ?? '',
      resource.classification,
      resource.publicUrl!,
      resource.brightness!,
      resource.colorPalette!,
      resource.dominantColor!,
      resource.recommendedBackground!,
      resource.hasAlphaChannel!,
      resource.dimensions?.height ?? 0,
      resource.dimensions?.width ?? 0,
      resource.dimensions?.aspectRatio ?? 0
    );
  }
  createPatchForCurrentModelAndIncomingResource(
    currentModel: ImageAsset,
    resource: AssetResource
  ): ServerDelta[] {
    if (currentModel.id !== resource.id) {
      throw new Error('Model and Resource ID Mismatch');
    }

    //Convert Incoming Resource to Model
    const incomingModel = this.fromApiResource(resource);

    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(), //This is a simulated server timestamp
      this._rootStore.clientId //This makes it seem like the update is coming from this client which is not true. A quick fix to satisfy the contact. Need to review of the implications of this and if it matters for now.
    );
  }
}

export default ImageAssetTransformer;
