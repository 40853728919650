import { useState } from 'react';

/**
 * Simple hook to handle modal state
 */
export const useModal = (): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return [isOpen, handleOpen, handleClose];
};
