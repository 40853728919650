import { FC } from 'react';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { OverflowScrollableFullHeightContainer } from '../../elements/overflow-scrollable-full-height-container';
import { ObDataGridOverlayLoading } from '../../molecules/data-grid/ob-data-grid-overlay-loading';
import { ObDataGridOverlayNoRows } from '../../molecules/data-grid/ob-data-grid-overlay-no-rows';

interface ObDataGridProps {
  rows: any[];
  columns: GridColumns;
  loading: boolean;
  noContentCtaHeading?: string;
  noContentCtaSubHeading?: string;
  noContentCtaPrimaryButtonLabel?: string;
  noContentCtaPrimaryButtonCallback?: () => void;
  noContentCtaSecondaryButtonLabel?: string;
  noContentCtaSecondaryButtonCallback?: () => void;
}

/**
 * Data Grid with Default Settings for Typeless Dashboard
 * @param rows
 * @param columns
 * @param loading
 * @param noContentCtaHeading
 * @param noContentCtaSubHeading
 * @param noContentCtaPrimaryButtonLabel
 * @param noContentCtaPrimaryButtonCallback
 * @param noContentCtaSecondaryButtonLabel
 * @param noContentCtaSecondaryButtonCallback
 * @constructor
 */
export const ObDataGrid: FC<ObDataGridProps> = ({
  rows,
  columns,
  loading,
  noContentCtaHeading = 'No Results',
  noContentCtaSubHeading = 'Once there is some data it will appear here',
  noContentCtaPrimaryButtonLabel,
  noContentCtaPrimaryButtonCallback,
  noContentCtaSecondaryButtonLabel,
  noContentCtaSecondaryButtonCallback,
}) => {
  return (
    <>
      <OverflowScrollableFullHeightContainer>
        <DataGrid
          sx={(theme) => {
            return {
              borderRadius: 2,
              borderColor: theme.palette.action.focus,
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                backgroundColor: theme.palette.background.default,
              },
              '& .MuiDataGrid-footerContainer': {
                minHeight: 40,
                borderBottomLeftRadius: 7,
                borderBottomRightRadius: 7,
              },
              '& .MuiDataGrid-columnHeaders, .MuiDataGrid-cell, .MuiDataGrid-footerContainer':
                {
                  borderColor: theme.palette.action.focus,
                },
              '& .MuiTablePagination-displayedRows': {
                color: theme.palette.text.secondary,
              },
              '& .MuiDataGrid-iconSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-columnHeader': {
                padding: '0 16px',
                ':not(:last-child)': {
                  borderRight: `1px solid ${theme.palette.action.focus}`,
                },
              },
              '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderRight: 'none',
                borderLeft: 'none',
                '&--withRenderer': {
                  fontFamily: 'sofia-pro',
                  padding: '0 16px',
                  ':not(:last-child)': {
                    borderRight: `1px solid ${theme.palette.action.focus}`,
                  },
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '14px',
                minHeight: '37px',
                fontFamily: 'sofia-pro',
                color: theme.palette.text.secondary,
              },
              '& .MuiTablePagination-root': {
                width: '100%',
              },
              '& .MuiTablePagination-spacer': {
                display: 'none',
              },
              '& .MuiTablePagination-toolbar': {
                width: '100%',
                height: 40,
                minHeight: 40,
                display: 'flex',
                padding: '0 16px',
                justifyContent: 'space-between',
              },
              '& .MuiTablePagination-actions': {
                Button: {
                  marginLeft: '8px',
                },
              },
            };
          }}
          rows={loading ? [] : rows}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector={true}
          headerHeight={38}
          rowHeight={40}
          columns={columns}
          loading={loading}
          componentsProps={{
            noRowsOverlay: {
              heading: noContentCtaHeading,
              subHeading: noContentCtaSubHeading,
              ctaPrimaryButtonLabel: noContentCtaPrimaryButtonLabel,
              ctaPrimaryButtonCallback: noContentCtaPrimaryButtonCallback,
              ctaSecondaryButtonLabel: noContentCtaSecondaryButtonLabel,
              ctaSecondaryButtonCallback: noContentCtaSecondaryButtonCallback,
            },
          }}
          components={{
            LoadingOverlay: ObDataGridOverlayLoading,
            NoRowsOverlay: ObDataGridOverlayNoRows,
          }}
          pageSize={15}
          rowsPerPageOptions={[15]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </OverflowScrollableFullHeightContainer>
    </>
  );
};
