export const LockUnlocked = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width={width}
      height={height}
      fill='none'
      {...props}
    >
      <path
        id='Icon'
        d='M4.7,7.3v-2C4.7,3.5,6.2,2,8,2c1.6,0,3,1.1,3.3,2.7 M5.2,14h5.6c1.1,0,1.7,0,2.1-0.2
	c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.2-1,0.2-2.1v-0.3c0-1.1,0-1.7-0.2-2.1c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-1-0.2-2.1-0.2H5.2
	c-1.1,0-1.7,0-2.1,0.2C2.7,7.7,2.4,8,2.2,8.4C2,8.9,2,9.4,2,10.5v0.3c0,1.1,0,1.7,0.2,2.1c0.2,0.4,0.5,0.7,0.9,0.9
	C3.5,14,4.1,14,5.2,14z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
