import { cva } from 'class-variance-authority';

export type InputSize = 'small' | 'medium' | 'large';

/**
 * Sizes for ob inputs containers. These sizes assume a 1px border which is why these are 2px bigger than what is documented in the design tokens and Figma.
 * See https://www.figma.com/design/l1PsB4JNyhbHbnXedIkHJ4/Outbound-DS?node-id=335-12758&t=t3MM6MvLTo5ybNoS-4
 */
export const obInputContainerSize = cva([''], {
  variants: {
    size: {
      small: 'h-[34px] ',
      medium: 'h-[42px] ',
      large: 'h-[50px]',
    },
  },
});

export const getHeightForInputSizeInPx = (
  size: InputSize,
  includeBorder: boolean
): number => {
  const border = includeBorder ? 2 : 0;
  switch (size) {
    case 'small':
      return 34 + border;
    case 'medium':
      return 42 + border;
    case 'large':
      return 50 + border;
  }
};
