import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { CampaignCardProps } from '../campaign-card';

export const CampaignIntegrationBadge = ({
  integrationXConfigurationSlot,
}: Pick<CampaignCardProps, 'integrationXConfigurationSlot'>) => {
  return (
    <div className='flex flex-col'>
      <ObTypography
        variant='subtitle3'
        color='tertiary'
      >
        Integration
      </ObTypography>
      <div className='flex flex-shrink'>{integrationXConfigurationSlot}</div>
    </div>
  );
};
