import {
  BaseTemplateValueResource,
  ColorTemplateValueResource,
  ImageTemplateValueResource,
  TemplateSettingFieldType,
  TextTemplateValueResource,
} from '@outbound/types';
/**
 * Helper Method to pull Image URL from Image Setting Value
 * @param imageSettingValue
 * @returns
 */
export const getPublicUrlFromImageValue = (
  imageSettingValue?: BaseTemplateValueResource
) => {
  if (imageSettingValue == null) {
    return '';
  } else {
    assertSettingFieldIsOfType('image', imageSettingValue);
    return (
      (imageSettingValue as ImageTemplateValueResource).value?.asset
        ?.publicUrl ?? ''
    );
  }
};

/**
 * Helper Method to pull Hex Code from Color Setting Value
 * @param colorSettingValue
 * @returns
 */
export const getHexCodeFromColorValue = (
  colorSettingValue?: BaseTemplateValueResource
) => {
  if (colorSettingValue == null) {
    return '';
  } else {
    assertSettingFieldIsOfType('color', colorSettingValue);
    return (colorSettingValue as ColorTemplateValueResource).value?.hex ?? '';
  }
};

export const getTextFromTextValue = (
  textSettingValue?: BaseTemplateValueResource
) => {
  if (textSettingValue == null) {
    return '';
  } else {
    assertSettingFieldIsOfType('text', textSettingValue);
    return (textSettingValue as TextTemplateValueResource).value?.text ?? '';
  }
};

const assertSettingFieldIsOfType = (
  type: TemplateSettingFieldType,
  settingValue: BaseTemplateValueResource
) => {
  if (settingValue == null) {
    throw new Error('Setting Field Value is Null. Cannot Assert Type');
  }
  if (settingValue.type === type) {
    return;
  } else {
    throw new Error(
      `Setting of Type '${type}' expected, got '${settingValue.type}' instead`
    );
  }
};
