import { FC } from 'react';
import { AvatarIconStyleProps } from '../../../avatars/avatar.types';
import { ObIconAvatar } from '../../../avatars/ob-icon-avatar/ob-icon-avatar';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';

/**
 * The public props of the CardHeader component
 */
export interface ObCardHeaderWithIconProps extends AvatarIconStyleProps {
  /**
   * The heading that will appear on the top of the card
   */
  heading: string;
  /**
   * The headingAs prop allows you to change the heading element type
   */
  headingAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const ObCardHeaderWithIcon: FC<ObCardHeaderWithIconProps> = ({
  heading,
  icon,
  headingAs = 'h5',
  color,
}: ObCardHeaderWithIconProps) => {
  return (
    // Max height is set to 40px to prevent the header from expanding the card. We assume the parent will ensure the right amount of spacing for the header.
    <header className='flex flex-1 justify-start items-center gap-3 max-h-[40px]'>
      <ObIconAvatar
        icon={icon}
        color={color}
        size='medium'
      />
      <ObTypography
        className='line-clamp-2 text-left'
        variant='h5'
        as={headingAs}
      >
        {heading}
      </ObTypography>
    </header>
  );
};
