export const Playbooks = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='solid/education/book-closed'>
        <path
          id='Solid'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.02951 12.8345C2.06012 13.2093 2.12583 13.5537 2.29069 13.8773C2.54636 14.3791 2.9543 14.787 3.45607 15.0427C3.77963 15.2075 4.1241 15.2732 4.49883 15.3039C4.85962 15.3333 5.30252 15.3333 5.83916 15.3333H11.2258C11.5772 15.3333 11.8804 15.3333 12.1301 15.3129C12.3936 15.2914 12.6558 15.2439 12.908 15.1153C13.2843 14.9236 13.5903 14.6176 13.7821 14.2413C13.9106 13.9891 13.9581 13.7269 13.9797 13.4633C13.998 13.2395 14 12.9728 14 12.6667C14 9.59142 14 6.51618 14 3.44095C14.0001 3.08952 14.0001 2.78635 13.9797 2.53664C13.9581 2.27308 13.9106 2.01093 13.7821 1.75868C13.5903 1.38235 13.2843 1.07639 12.908 0.884645C12.6558 0.756116 12.3936 0.708572 12.1301 0.687038C11.8804 0.666636 11.5772 0.666646 11.2258 0.666657H5.83918C5.30255 0.666649 4.85962 0.666643 4.49883 0.696121C4.1241 0.726737 3.77963 0.792447 3.45607 0.957308C2.9543 1.21297 2.54636 1.62092 2.29069 2.12268C2.12583 2.44624 2.06012 2.79071 2.02951 3.16544C2.00003 3.52624 2.00004 3.96914 2.00004 4.50578V11.4942C2.00004 11.9415 1.9931 12.389 2.02951 12.8345ZM12.6508 13.3548C12.636 13.5359 12.6108 13.603 12.594 13.636C12.5301 13.7614 12.4281 13.8634 12.3027 13.9273C12.2697 13.9441 12.2026 13.9692 12.0215 13.984C11.8326 13.9995 11.5844 14 11.2 14H5.86671C5.29566 14 4.90746 13.9995 4.6074 13.975C4.31513 13.9511 4.16566 13.9078 4.06139 13.8547C3.81051 13.7268 3.60653 13.5229 3.4787 13.272C3.35925 13.0375 3.30018 12.8345 3.34457 12.4931C3.42964 11.8387 3.98915 11.3333 4.66671 11.3333H12.6667C12.6667 12.0033 12.7054 12.6863 12.6508 13.3548Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
