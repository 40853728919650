import { ObTypography } from '@outbound/design-system';

export interface PlaybookPageHeadingProps {
  title: string;
  controls?: React.ReactNode;
}
export const PlaybookPageHeading = ({
  title,
  controls,
}: PlaybookPageHeadingProps) => {
  return (
    <header className='flex flex-row justify-between items-center min-h-[40px]'>
      <ObTypography
        as='h1'
        variant='h3'
      >
        {title}
      </ObTypography>
      <div>{controls}</div>
    </header>
  );
};
