import { Deferred } from '@otbnd/utils';
import { useDrawerService } from '@outbound/design-system';
import { useCallback } from 'react';
import { useCreateServiceDialog } from '../create-service-dialog/use-create-service-dialog';
import { ServiceEditDrawer } from './service-edit-drawer';

export const useServiceDetailDrawer = () => {
  const drawerService = useDrawerService();
  const { openCreateServiceDialog } = useCreateServiceDialog();

  const openEditServiceDrawer = useCallback(
    (
      serviceId: string,
      popOnSave?: boolean,
      openToServiceOfferingId?: string
    ) => {
      return drawerService.pushDrawer({
        title: 'View or Edit Service',
        description: 'Edit the information below to update a service.',
        drawerContent: (
          <ServiceEditDrawer
            serviceId={serviceId}
            popOnSave={popOnSave}
            openToServiceOfferingId={openToServiceOfferingId}
          />
        ),
        size: 'medium',
      });
    },
    [drawerService]
  );

  /**
   * Opens a Dialog to create a new service and then opens the edit drawer for that service
   * Due to the information architecture of services and sub-services we need to first mint the service
   * before adding sub-services. We accomplish this by getting a service name first and than allowing the
   * user to finish the service creation process in the edit drawer.
   */
  const openCreateNewServiceDrawer = useCallback(async () => {
    const deferred = new Deferred<string>();
    openCreateServiceDialog().then((newService) => {
      openEditServiceDrawer(newService.id, true).then(() => {
        deferred.resolve(newService.id);
      });
    });
    return deferred.promise;
  }, [openCreateServiceDialog, openEditServiceDrawer]);

  return { openEditServiceDrawer, openCreateNewServiceDrawer };
};
