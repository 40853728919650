import {
  ObBadge,
  ObButton,
  ObIcon,
  ObThinkingButton,
  ObTimeline,
  ObTimelineCardProps,
  ObTypography,
  ObWhoWhatWhereCard,
} from '@outbound/design-system';

import { useNotificationService } from '@outbound/design-system/src/components/services/notification-service-provider/notification-service-provider';
import {
  CampaignResource,
  IndustryReference,
  KeywordResource,
  StrategyResource,
  referenceIndustries,
} from '@outbound/types';
import { useCallback, useEffect, useState } from 'react';
import { useAppNavigation } from '../../../../../../hooks/use-app-navigation';
import { useFetchRecommendationById } from '../../../../../../query/campaigns/use-campaigns-endpoints';
import { usePollForInitializedCreative } from '../../../../../../query/creative-manager/use-creative-endpoints';
import { OnboardingHeader } from '../../../../../onboarding/components/onboarding-header/onboarding-header';
import { BrandMetaCard } from './components/card__brand-meta/brand-meta-card';
import { LocationCard } from './components/card__location/location-card';
import { YourMarketingBudgetCard } from './components/card__your-marketing-budget/your-marketing-budget-card';
import { MarketingPlanCard } from './components/common/marketing-plan-card';
import { MarketingPlanCardSubTitle } from './components/common/marketing-plan-card-subtitle';
import './marketing-plan-loader.css';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFetchPlaybookSettings } from '../../../../../../query/playbook/use-playbook-endpoints';
import {
  useFetchBrandGuide,
  usePatchBrandGuide,
} from '../../../../../../query/playbook/use-playbook-settings-brand-guide-endpoints';
import { useFetchBusinessDetails } from '../../../../../../query/playbook/use-playbook-settings-business-details-endpoints';
import { useStore } from '../../../../../../state/store';
import { ObCreativePlayer } from '../../../../../creative-builder/template-components/template-player/creative-player';
import { useCustomerPersonaCreateDrawer } from '../../../../../dashboard/playbookv2/pages/customer-personas/drawer/customer-persona-create-drawer';
import { useServiceAreaCreateDrawer } from '../../../../../dashboard/playbookv2/pages/locations/drawer/service-area-create-drawer';

import { useServiceDetailDrawer } from '../../../../../dashboard/playbookv2/pages/products-and-services/drawer/use-service-details-drawer';
import { useCampaignSetupData } from '../../../../../onboarding/first-campaign-page/use-campaign-setup-data';
import { generateTimelineFromStrategy } from './generate-marketing-plan-timeline';

interface FirstCampaignCardProps {
  campaign?: CampaignResource;
}
const FirstCampaignCard = ({ campaign }: FirstCampaignCardProps) => {
  const { navigateToCreativeBuilder } = useAppNavigation();
  const {
    data: creative,
    isLoading: isCreativeLoading,
    isCreativeReady,
    estimatedPercentComplete,
  } = usePollForInitializedCreative(
    campaign?.highlights?.[0]?.creativeId ?? ''
  );

  return (
    <MarketingPlanCard gap='gap-6'>
      {campaign?.name ? (
        <ObTypography variant='subtitle1'>{campaign.name}</ObTypography>
      ) : (
        <ObTypography variant='subtitle1'>
          Campaign - Google Display Ads {campaign?.name}
        </ObTypography>
      )}
      <section className='flex flex-col gap-3'>
        <MarketingPlanCardSubTitle
          title={creative?.template?.name ?? 'Creative'}
          actions={
            <ObButton
              label='Edit'
              size='medium'
              variant='outline'
              disabled={!isCreativeReady}
              onClick={() => {
                if (creative?.id) {
                  navigateToCreativeBuilder(creative.id);
                }
              }}
            />
          }
        />
        <div className='w-full flex justify-center items-center'>
          <div
            className='min-w-[256px] w-[256px] min-h-[256px] h-full bg-bg-gradient-dark flex justify-center items-center shadow-2xl'
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && creative?.id) {
                navigateToCreativeBuilder(creative.id);
              }
            }}
            onClick={() => {
              if (creative?.id) {
                navigateToCreativeBuilder(creative.id);
              }
            }}
          >
            <ObCreativePlayer
              creative={creative}
              isLoading={isCreativeLoading}
              mobileView={true}
              estimatedPercentComplete={estimatedPercentComplete}
            />
          </div>
        </div>
      </section>
      <section className='flex flex-col gap-2 '>
        <MarketingPlanCardSubTitle
          title={'Keywords'}
          actions={
            <ObButton
              label='Edit'
              size='small'
              iconLeft='chevronDown'
              variant='outline'
              buttonType='icon'
            />
          }
        />

        <div className='flex flex-row flex-wrap gap-2'>
          {campaign?.targeting?.recommendedKeywords &&
            campaign?.targeting?.recommendedKeywords?.length > 0 &&
            campaign?.targeting?.recommendedKeywords?.map(
              (keyword: KeywordResource) => (
                <ObBadge
                  key={keyword.value}
                  content={keyword.value}
                />
              )
            )}
        </div>
      </section>
    </MarketingPlanCard>
  );
};

interface BrandColors {
  primary: string;
  secondary: string;
}

export const MarketingPlanPage = () => {
  const { yourMarketingPlanLocations } = useFlags();
  const { marketingPlanRecommendationId } = useStore();
  const notificationService = useNotificationService();
  const { navigateToOnboardingQuestionnaire, navigateToPickSubscriptionPage } =
    useAppNavigation();

  const { openNewCustomerPersonaDrawer } = useCustomerPersonaCreateDrawer();
  const { openNewLocationDrawer } = useServiceAreaCreateDrawer();
  const { openCreateNewServiceDrawer } = useServiceDetailDrawer();

  const {
    isLoading: isCampaignSetupDataLoading,
    personaOptions,
    serviceOptions,
    locationOptions,
  } = useCampaignSetupData();

  const { data: playbookSettings, isLoading: isPlaybookSettingsLoading } =
    useFetchPlaybookSettings();

  /**
   * Fetch the recommendation from the campaign service
   */
  const { data: recommendation, isLoading: isRecommendationLoading } =
    useFetchRecommendationById(marketingPlanRecommendationId);

  /**
   * Fetch the brand colors from the playbook
   */
  const {
    data: brandColorsApi,
    isLoading: isBrandColorsLoading,
    isError: isBrandColorsApiError,
  } = useFetchBrandGuide();

  /**
   * Fetch the business details from the playbook
   */
  const { data: businessDetailsApi, isLoading: isBusinessDetailsLoading } =
    useFetchBusinessDetails();

  const [initialBudgeSet, setInitialBudgetSet] = useState<boolean>(false);

  /**
   * Side Effect to set the initial budget from the recommendation.
   * We only want this to run once when the recommendation is loaded.
   * The user would be annoyed if the budget kept resetting to the recommendation value
   * anytime the recommendation was updated via the API
   */
  useEffect(() => {
    if (recommendation != null) {
      if (!initialBudgeSet) {
        if (recommendation?.selectedDailyBudget >= 0) {
          setDailyBudget(recommendation.selectedDailyBudget);
        }
        setInitialBudgetSet(true);
      }
    }
  }, [initialBudgeSet, recommendation]);

  /**
   * Holds the id of the service or product that the user wants to advertise in their first campaign
   */
  const [whatValue, setWhatValue] = useState<Array<string>>([]);

  /**
   * Holds the id of the persona that the user wants to target with their first campaign
   */
  const [whoValue, setWhoValue] = useState<Array<string>>([]);

  const [locationValue, setLocationValue] = useState<Array<string>>([]);

  /**
   * Temporary Initialization logic for the first marketing plan setup.
   */
  useEffect(() => {
    if (isCampaignSetupDataLoading) {
      return;
    }
    setWhoValue(personaOptions.map((option) => option.value).slice(0, 1));
    setWhatValue(serviceOptions.map((option) => option.value).slice(0, 1));
    setLocationValue(locationOptions.map((option) => option.value).slice(0, 1));
  }, [
    isCampaignSetupDataLoading,
    isPlaybookSettingsLoading,
    personaOptions,
    playbookSettings,
    serviceOptions,
    locationOptions,
  ]);

  const [width, setWidth] = useState(window.innerWidth);

  const [isMarketingPlanDataLoading, setIsMarketingPlanDataLoading] =
    useState<boolean>(true);

  useEffect(() => {
    setIsMarketingPlanDataLoading(
      isRecommendationLoading ||
        isCampaignSetupDataLoading ||
        isPlaybookSettingsLoading ||
        isBrandColorsLoading ||
        isBusinessDetailsLoading
    );
  }, [
    isRecommendationLoading,
    isCampaignSetupDataLoading,
    isPlaybookSettingsLoading,
    isBrandColorsLoading,
    isBusinessDetailsLoading,
  ]);

  const [dailyBudget, setDailyBudget] = useState<number>(0);

  const identifyStrategy = useCallback(
    (dailyBudget: number) => {
      const result = recommendation?.strategies?.find((strategy) => {
        return (
          Math.round(dailyBudget) >= strategy.minBudget &&
          Math.round(dailyBudget) <= strategy.maxBudget
        );
      });
      return result as StrategyResource;
    },
    [recommendation?.strategies]
  );

  const [activeStrategy, setActiveStrategy] = useState<StrategyResource>(
    identifyStrategy(dailyBudget)
  );

  const [marketingTimeline, setMarketingTimeline] = useState(
    generateTimelineFromStrategy(activeStrategy).phases
  );

  const [recommendedCampaign, setRecommendedCampaign] = useState<
    CampaignResource | undefined
  >();

  /**
   * Authoritative State for Brand Colors. This state is passed down to the Brand Guide
   * These values are updated as soon as the user makes a change to the color picker since it
   * is what is driving state for the color input acting as a controlled component
   */
  const [brandColors, setBrandColors] = useState<BrandColors>({
    primary: '',
    secondary: '',
  });

  /**
   * This stores the finalized state we want to persist. This is needed so we can not spam the API
   * with updates as the user is dragging the color picker around. The API Save and Inline Edit Notification
   * are triggered by this state.
   */
  const [debouncedBrandColors, setDebouncedBrandColors] = useState<BrandColors>(
    {
      primary: '',
      secondary: '',
    }
  );

  /**
   * Once data loads from the API set the frontend state with it
   */
  useEffect(() => {
    const brandColors = {
      primary: brandColorsApi?.primaryColor ?? '',
      secondary: brandColorsApi?.secondaryColor ?? '',
    };
    setBrandColors(brandColors);
    setDebouncedBrandColors(brandColors);
  }, [brandColorsApi]);

  /**
   * We will persist the colors to the API after a slight delay to ensure that no other changes are coming
   */
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedBrandColors(brandColors);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [brandColors]);

  /**
   * Calls the API to update the brand guide in the workspace playbook
   */

  const { mutateAsync: patchBrandGuide } = usePatchBrandGuide();

  useEffect(() => {
    /**
     * We ony want to call the API when we have a valid color object that is different
     * from the current value of the API
     */
    if (
      !isBrandColorsApiError &&
      debouncedBrandColors.primary != null &&
      debouncedBrandColors.secondary != null &&
      debouncedBrandColors.primary !== '' &&
      debouncedBrandColors.secondary !== '' &&
      (debouncedBrandColors.primary !== brandColorsApi?.primaryColor ||
        debouncedBrandColors.secondary !== brandColorsApi?.secondaryColor)
    ) {
      /**
       * There is existing content in the playbook page (Such as heading and body) that we do not want
       * to delete, therefore we will spread the existing content and just update what we need.
       */
      const nextBrandColorsValue = {
        primaryColor: debouncedBrandColors.primary,
        secondaryColor: debouncedBrandColors.secondary,
      };
      const updateColorsPromise = patchBrandGuide({
        values: nextBrandColorsValue,
      });

      notificationService.pushNotification({
        titleInProgress: 'Saving Brand Colors',
        titleRejected: 'Unable to Save Brand Colors',
        bodyRejected: 'An error occurred',
        bodyInProgress: 'Updating your brand colors',
        titleResolved: 'Colors Saved!',
        bodyResolved: 'Your brand colors have been updated',
        progressPromise: updateColorsPromise,
      });
    }
  }, [
    brandColors.primary,
    brandColors.secondary,
    brandColorsApi,
    brandColorsApi?.primaryColor,
    brandColorsApi?.secondaryColor,
    debouncedBrandColors,
    isBrandColorsApiError,
    notificationService,
    patchBrandGuide,
  ]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [industryReference, setIndustryReference] =
    useState<IndustryReference>();

  useEffect(() => {
    const industryId = businessDetailsApi?.industryId;
    if (industryId) {
      const industry = referenceIndustries.find(
        (industry) => industry.id === industryId
      );
      setIndustryReference(industry);
    }
  }, [businessDetailsApi]);

  const navigateToSubscriptionSelectionPage = () => {
    navigateToPickSubscriptionPage();
    return Promise.resolve();
  };

  useEffect(() => {
    setActiveStrategy(identifyStrategy(dailyBudget));
    setMarketingTimeline(generateTimelineFromStrategy(activeStrategy)?.phases);
  }, [activeStrategy, dailyBudget, identifyStrategy]);

  useEffect(() => {
    if (
      industryReference?.benchmarkDailyBudgetMax != null &&
      industryReference?.benchmarkDailyBudgetMin != null
    ) {
      const dailyBudget =
        (industryReference.benchmarkDailyBudgetMax +
          industryReference.benchmarkDailyBudgetMin) /
        2;

      setDailyBudget(dailyBudget);
    }
  }, [industryReference]);

  const handleDailyBudgetUpdated = useCallback(
    (newDailyBudget: number) => {
      setDailyBudget(newDailyBudget);
      setActiveStrategy(identifyStrategy(newDailyBudget));
      setMarketingTimeline(
        generateTimelineFromStrategy(activeStrategy)?.phases
      );
    },
    [activeStrategy, identifyStrategy]
  );

  useEffect(() => {
    setRecommendedCampaign(marketingTimeline?.[0]?.campaign);
  }, [marketingTimeline]);

  const BREAK_POINT_SM = 640;
  const BREAK_POINT_MD = 768;

  const calculateBudgetBarWidth = (width: number) => {
    if (width < BREAK_POINT_SM) {
      return width - 80;
    } else if (width < BREAK_POINT_MD) {
      return width - 190;
    } else {
      return Math.min(width, 1036) - 578;
    }
  };

  return (
    <div className='page h-screen w-full flex flex-col justify-start items-center bg-bg-gradient-top-right-dark gap-4'>
      <OnboardingHeader
        currentStep='review'
        renderNextButton={() => (
          <ObThinkingButton
            label={'Next'}
            size='large'
            onClickCallback={navigateToSubscriptionSelectionPage}
          />
        )}
      />

      <article className='max-w-[1032px] w-full p-5'>
        <header className='header w-full flex py-8'>
          <ObTypography
            variant='h1'
            className='text-center sm:text-left w-full'
          >
            Review Your Custom Marketing Plan
          </ObTypography>
        </header>

        <div className='flex flex-col md:flex-row gap-5 w-full'>
          <section className='flex flex-col gap-5 w-full flex-auto md:max-w-[656px]'>
            <BrandMetaCard
              isLoading={isMarketingPlanDataLoading}
              onEditButtonClickedCallback={() =>
                navigateToOnboardingQuestionnaire('industry')
              }
              companyIcon=''
              companyName={businessDetailsApi?.doingBusinessAsName ?? ''}
              industryName={industryReference?.name}
              website={businessDetailsApi?.website ?? ''}
              isRetailOnline={
                businessDetailsApi?.businessActivities?.isRetailOnline ?? false
              }
              isRetailInStore={
                businessDetailsApi?.businessActivities?.isRetailInStore ?? false
              }
              isServiceOnline={
                businessDetailsApi?.businessActivities?.isServiceOnline ?? false
              }
              isServiceInCustomerLocation={
                businessDetailsApi?.businessActivities
                  ?.isServiceInCustomerLocation ?? false
              }
              isServiceInBusinessLocation={
                businessDetailsApi?.businessActivities
                  ?.isServiceInBusinessLocation ?? false
              }
            />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
              <ObWhoWhatWhereCard
                variant={'who'}
                isLoading={isMarketingPlanDataLoading}
                value={whoValue}
                options={personaOptions}
                onValueChangedCallback={setWhoValue}
                onCreateClickedCallback={openNewCustomerPersonaDrawer}
              />
              {/* Temp Implementation of the create Callback behavior.
              We need to address product vs service 
               */}
              <ObWhoWhatWhereCard
                variant={'what'}
                isLoading={isMarketingPlanDataLoading}
                value={whatValue}
                options={serviceOptions}
                onValueChangedCallback={setWhatValue}
                onCreateClickedCallback={openCreateNewServiceDrawer}
              />
              {/* Temp Implementation on the Create Callback. We need
              to address services areas vs brick and mortar vs online reach
               */}
              <ObWhoWhatWhereCard
                variant={'where'}
                isLoading={isMarketingPlanDataLoading}
                value={locationValue}
                options={locationOptions}
                onValueChangedCallback={setLocationValue}
                onCreateClickedCallback={openNewLocationDrawer}
              />
            </div>
            <YourMarketingBudgetCard
              maxWidth={calculateBudgetBarWidth(width)}
              dailyBudget={dailyBudget}
              onDailyBudgetUpdatedCallback={handleDailyBudgetUpdated}
              activeStrategy={activeStrategy}
              outboundRecommendedStrategies={recommendation?.strategies}
              industryBudgetHigh={industryReference?.benchmarkDailyBudgetMax}
              industryBudgetLow={industryReference?.benchmarkDailyBudgetMin}
              isLoading={isMarketingPlanDataLoading}
            />
            <ObTimeline
              title={'Recommended Marketing Plan'}
              timelineCards={marketingTimeline as ObTimelineCardProps[]}
              isLoading={isMarketingPlanDataLoading}
            />
            {yourMarketingPlanLocations && (
              <LocationCard
                innerWidth={width}
                onlineReach={[]}
                physicalLocations={[]}
                serviceAreas={[]}
                onClickEditButtonCallback={() =>
                  navigateToOnboardingQuestionnaire('locations')
                }
              />
            )}
          </section>
          <aside className='right-column flex flex-col gap-4 md:min-w-[360px] md:max-w-[360px] m:w-[360px] max flex-none'>
            <div className='flex items-center'>
              <ObTypography variant='h2'>Your First Campaign</ObTypography>
              <ObIcon
                icon={'info'}
                color={'tertiary'}
                size={'x-small'}
              ></ObIcon>
            </div>
            <ObTypography variant='body1'>
              Based on your industry and marketing goals, we recommend starting
              with this campaign.
            </ObTypography>
            <FirstCampaignCard campaign={recommendedCampaign} />
          </aside>
        </div>
      </article>
    </div>
  );
};
