import { useState } from 'react';
import { getFilesAsyncFromFolder } from '../utilities/file-utilities';

export const useOnDragAndDropFile = (
  onFilesDropped: (files: Array<File>) => void
) => {
  const [isDragAndDropDisabled, setIsDragAndDropDisabled] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    if (isDragAndDropDisabled) return;
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    onFilesDropped(await getFilesAsyncFromFolder(e.dataTransfer));
  };

  return {
    setIsDragAndDropDisabled,
    handleDrag,
    handleDrop,
    dragActive,
  };
};
