import { ObThinkingButton, ObTypography } from '@outbound/design-system';
import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';
import { FC } from 'react';
import { useAppNavigation } from '../../../../../hooks/use-app-navigation';
import { useCreatePaymentBillingPortalForUserMutation } from '../../../../../query/payment-manager/use-payment-manager-endpoints';
import { useFetchWorkspaceDetails } from '../../../../../query/workspace/use-workspace-endpoints';
import { SubscriptionSelectionPage } from '../../../../onboarding/subscription-selection-page/subscription-selection-page';

const WorkspaceSubscription: FC = () => {
  const { navigateToPickSubscriptionPage } = useAppNavigation();
  const { data } = useFetchWorkspaceDetails();

  const { mutateAsync: billingPortal } =
    useCreatePaymentBillingPortalForUserMutation();

  const toManageSubscription = async () => {
    return new Promise<void>((reject) => {
      if (data?.paymentProcessorCustomerId) {
        billingPortal({
          customerId: data.paymentProcessorCustomerId,
        })
          .then((sessionUrl) => {
            if (sessionUrl) {
              window.location.href = sessionUrl;
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        navigateToPickSubscriptionPage();
      }
    });
  };

  return (
    <>
      <div className='flex flex-col gap-4'>
        <ObTypography variant='h2'>Plans</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          You currently are on the free plan. In order to publish campaigns,
          please select a plan. For questions, please contact{' '}
          <ObLink
            href='mailto:support@outbound.com'
            label='Support'
            variant={'primary'}
          />
        </ObTypography>
      </div>
      <SubscriptionSelectionPage adBudget={'200'} />
      <ObThinkingButton
        variant='primary'
        onClickCallback={toManageSubscription}
        label='Manage Subscription'
        iconLeft='rocket01'
      ></ObThinkingButton>
    </>
  );
};

export default WorkspaceSubscription;
