import { ObButton, ObIconAvatar, ObTypography } from '../../../..';
import { IconSystemKeys } from '../../../../tokens/icons/icons';

export interface SectionPlaceholderProps {
  heading: string;
  message: string;
  icon?: IconSystemKeys;
  actionLabel?: string;
  actionOnClick?: () => void;
  documentationOnClick?: () => void;
}
export const SectionPlaceholder = ({
  heading,
  message,
  icon,
  actionLabel,
  actionOnClick,
  documentationOnClick,
}: SectionPlaceholderProps) => {
  return (
    <div className='flex flex-col gap-4 max-w-xl'>
      <div className='flex gap-2'>
        {icon && (
          <div className='pt-1'>
            <ObIconAvatar icon={icon} />
          </div>
        )}
        <div>
          <ObTypography variant='h4'>{heading}</ObTypography>
          <ObTypography
            variant='body3'
            color='secondary'
          >
            {message}
          </ObTypography>
        </div>
      </div>
      <div>
        {actionLabel && actionOnClick && (
          <ObButton
            size='medium'
            fullWidth='mobile'
            onClick={actionOnClick}
            variant='primary'
            label={actionLabel}
          />
        )}
        {documentationOnClick && (
          <ObButton
            size='medium'
            fullWidth='mobile'
            onClick={documentationOnClick}
            variant='outline'
            label={'View Documentation'}
          />
        )}
      </div>
    </div>
  );
};
