import { ThemeOptions } from '@mui/material';
import { baseColorPalette } from './design-token-colors';
import { defaultLightPalette } from './mui-default-palette';
import { CustomTheme } from './theme-options-base';

export const themeOptionsLight: ThemeOptions & CustomTheme = {
  palette: {
    action: defaultLightPalette.action,
    primary: {
      main: baseColorPalette.typelessLakeBlue.hex,
    },
    secondary: defaultLightPalette.secondary,
    error: defaultLightPalette.error,
    warning: defaultLightPalette.warning,
    info: defaultLightPalette.info,
    success: defaultLightPalette.success,
    text: defaultLightPalette.text,
    grey: defaultLightPalette.grey,
    divider: defaultLightPalette.divider,
    background: {
      default: defaultLightPalette.common.white,
      paper: defaultLightPalette.common.white,
      paperSubtle: defaultLightPalette.grey['50'],
    },
    common: defaultLightPalette.common,
  },
};
