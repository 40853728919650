import { parseObrn } from '@otbnd/utils';
import { PhysicalLocationResource, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../../../base-transformer';
import { RootStore } from '../../../root-store';
import PhysicalLocation from './physical-location';

class PhysicalLocationTransformer extends BaseTransformer<
  PhysicalLocationResource,
  PhysicalLocation
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  fromApiResource(resource: PhysicalLocationResource): PhysicalLocation {
    const { scope } = parseObrn(resource.obrn);
    return new PhysicalLocation(
      this._rootStore,
      resource.id,
      scope,
      resource.name,
      resource.description ?? '',
      resource.address?.street1 ?? '',
      resource.address?.street2 ?? '',
      resource.address?.city ?? '',
      resource.address?.state ?? '',
      resource.address?.country ?? '',
      resource.address?.postalCode ?? '',
      resource.locationImageStatus,
      resource.locationImageForDarkBackgroundAssetId,
      resource.locationImageForLightBackgroundAssetId
    );
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: PhysicalLocation,
    _resource: PhysicalLocationResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }
}

export default PhysicalLocationTransformer;
