import { Fragment } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { NavItem } from './dashboard-route.type';

// Helper function to render routes recursively
export const renderRoutes = (navItems: NavItem[]): JSX.Element => {
  return (
    <>
      {navItems.map((navItem) => {
        const { route, component: Component, childRoutes } = navItem;
        const hasChildRoutes = childRoutes && childRoutes.length > 0;
        const indexPath: string | undefined = hasChildRoutes
          ? childRoutes.find((i) => i.index)?.route
          : undefined;

        return (
          <Fragment key={route}>
            <Route
              key={route}
              path={route}
              element={<Component />}
            >
              {hasChildRoutes && indexPath && (
                <Route
                  index
                  key={`index-${route}`}
                  element={
                    <Navigate
                      to={indexPath}
                      replace
                    />
                  }
                />
              )}
              {hasChildRoutes && renderRoutes(childRoutes)}
            </Route>
          </Fragment>
        );
      })}
    </>
  );
};
