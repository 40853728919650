import { ObButton, ObTypography } from '@outbound/design-system';

export const CompletedConfirmationPage = () => {
  return (
    <div className='h-screen w-full flex flex-col bg-bg-gradient-top-right-dark items-center justify-center'>
      <section className='max-w-3xl flex flex-col gap-3 p-4'>
        <ObTypography variant='h2'>
          You've Successfully Launched Your Marketing Plan
        </ObTypography>
        <ObTypography
          variant='subtitle1'
          color='secondary'
        >
          You did it! You just launched your Outbound.com marketing plan. Your
          first campaign has been submitted to Google and will be live once it
          has been approved! We will send you an email letting you know once it
          is live.
        </ObTypography>
        <div className='flex w-full justify-end'>
          <ObButton
            size='xlarge'
            fullWidth='mobile'
            label='Go to Dashboard'
          />
        </div>
      </section>
    </div>
  );
};
