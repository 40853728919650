export const AlertTriangle = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      {...props}
    >
      <g clipPath='url(#clip0_8645_57769)'>
        <path
          d='M12.8126 1.66861C12.2953 1.43859 11.7047 1.43859 11.1874 1.66861C10.7878 1.84627 10.5283 2.15918 10.3477 2.41416C10.1701 2.66485 9.98004 2.99327 9.77096 3.35447L1.50381 17.6341C1.2939 17.9966 1.10315 18.326 0.973806 18.6056C0.842356 18.8897 0.699752 19.2717 0.745201 19.7076C0.804012 20.2717 1.09955 20.7843 1.55827 21.1178C1.91276 21.3756 2.31476 21.4435 2.62652 21.4721C2.93327 21.5003 3.31392 21.5003 3.73281 21.5002H20.2671C20.686 21.5003 21.0667 21.5003 21.3734 21.4721C21.6852 21.4435 22.0872 21.3756 22.4417 21.1178C22.9004 20.7843 23.1959 20.2717 23.2547 19.7076C23.3002 19.2717 23.1576 18.8897 23.0261 18.6056C22.8968 18.3261 22.7061 17.9967 22.4962 17.6342L14.229 3.35443C14.0199 2.99326 13.8298 2.66484 13.6522 2.41416C13.4716 2.15918 13.2121 1.84627 12.8126 1.66861ZM13 9.00024C13 8.44796 12.5523 8.00024 12 8.00024C11.4477 8.00024 11 8.44796 11 9.00024V13.0002C11 13.5525 11.4477 14.0002 12 14.0002C12.5523 14.0002 13 13.5525 13 13.0002V9.00024ZM12 16.0002C11.4477 16.0002 11 16.448 11 17.0002C11 17.5525 11.4477 18.0002 12 18.0002H12.01C12.5623 18.0002 13.01 17.5525 13.01 17.0002C13.01 16.448 12.5623 16.0002 12.01 16.0002H12Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_8645_57769'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.333008)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
