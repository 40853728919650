/**
 * Helper function to check if a string has content
 * @param str
 * @returns
 */
export const stringHasContent = (str: string | undefined): boolean => {
  return str !== undefined && str !== null && str.trim() !== '';
};

/**
 * Helper Function to truncate text to a specified length.
 *
 * @param text
 * @param maxLength
 * @returns
 */
export const truncateText = (
  text: string,
  maxLength: number,
  includeEllipsis = true
): { result: string; originalText: string; isTruncated: boolean } => {
  const textGreaterThanMaxLength = text.length > maxLength;
  const truncatedText = textGreaterThanMaxLength
    ? `${text.slice(0, maxLength)}${includeEllipsis ? '...' : ''}`
    : text;

  return {
    originalText: text,
    result: truncatedText,
    isTruncated: textGreaterThanMaxLength,
  };
};
