export const Truck01 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.33301 4.66669H10.8912C11.0542 4.66669 11.1358 4.66669 11.2125 4.68511C11.2805 4.70144 11.3455 4.72837 11.4052 4.76493C11.4725 4.80615 11.5301 4.86381 11.6454 4.97911L14.3539 7.6876C14.4692 7.8029 14.5269 7.86055 14.5681 7.92783C14.6047 7.98748 14.6316 8.05251 14.6479 8.12053C14.6663 8.19726 14.6663 8.27879 14.6663 8.44185V10.3334C14.6663 10.644 14.6663 10.7993 14.6156 10.9218C14.5479 11.0852 14.4181 11.2149 14.2548 11.2826C14.1323 11.3334 13.977 11.3334 13.6663 11.3334M10.333 11.3334H9.33301M9.33301 11.3334V4.80002C9.33301 4.05328 9.33301 3.67992 9.18768 3.3947C9.05985 3.14382 8.85588 2.93984 8.605 2.81201C8.31978 2.66669 7.94641 2.66669 7.19967 2.66669H3.46634C2.7196 2.66669 2.34624 2.66669 2.06102 2.81201C1.81014 2.93984 1.60616 3.14382 1.47833 3.3947C1.33301 3.67992 1.33301 4.05328 1.33301 4.80002V10C1.33301 10.7364 1.92996 11.3334 2.66634 11.3334M9.33301 11.3334H6.66634M6.66634 11.3334C6.66634 12.4379 5.77091 13.3334 4.66634 13.3334C3.56177 13.3334 2.66634 12.4379 2.66634 11.3334M6.66634 11.3334C6.66634 10.2288 5.77091 9.33335 4.66634 9.33335C3.56177 9.33335 2.66634 10.2288 2.66634 11.3334M13.6663 11.6667C13.6663 12.5872 12.9201 13.3334 11.9997 13.3334C11.0792 13.3334 10.333 12.5872 10.333 11.6667C10.333 10.7462 11.0792 10 11.9997 10C12.9201 10 13.6663 10.7462 13.6663 11.6667Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
