import { Grid, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import {
  ObActionMenu,
  ObActionMenuItem,
  ObIcon,
} from '@outbound/design-system';
import { IconSystemKeys } from '@outbound/design-system/src/tokens/icons/icons';

export interface CellActionDefinition {
  name: string;
  icon: IconSystemKeys;
  keyCommand: string;
  callback: () => any;
  ariaLabel: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}
export interface CellActionsProps {
  actions: Array<CellActionDefinition>;
  /**
   * Used to create Aria Labels in the format "Actions for ${ariaActionsFor}"
   */
  ariaActionsFor: string;

  actionsLoading?: boolean;
}

interface SingleActionProps {
  action: CellActionDefinition;
}
const SingleAction = ({ action }: SingleActionProps) => {
  return (
    <>
      <Tooltip
        placement='left'
        title={action.ariaLabel}
        arrow
      >
        <IconButton
          onClick={action.callback}
          aria-label={action.ariaLabel}
        >
          <ObIcon
            icon={action.icon}
            size={'small'}
          />
        </IconButton>
      </Tooltip>
    </>
  );
};

interface MultipleActionsProps {
  ariaActionsFor: string;
  actions: CellActionDefinition[];
}
const MultipleActionsMenu = ({
  ariaActionsFor,
  actions,
}: MultipleActionsProps) => {
  return (
    <>
      <ObActionMenu
        menuOpenButton={
          <IconButton aria-label={`Actions for ${ariaActionsFor} `}>
            <ObIcon
              icon={'moreVert'}
              size={'small'}
            />
          </IconButton>
        }
      >
        {actions.map((a) => {
          return (
            <ObActionMenuItem
              key={a.name}
              title={a.name}
              icon={a.icon}
              keyCommand={a.keyCommand}
              onClickCallback={a.callback}
              ariaLabel={a.ariaLabel}
              isDisabled={a.isDisabled}
              isLoading={a.isLoading}
            />
          );
        })}
      </ObActionMenu>
    </>
  );
};

export const CellActions = ({
  actions,
  ariaActionsFor,
  actionsLoading,
}: CellActionsProps) => {
  return (
    <Grid
      container
      justifyContent={'right'}
      data-testid={'cell-actions'}
    >
      {actionsLoading && (
        <ObIcon
          icon={'calendar'}
          size={'small'}
          loading={true}
        />
      )}
      {!actionsLoading && actions.length === 1 && (
        <SingleAction action={actions[0]} />
      )}
      {!actionsLoading && actions.length > 1 && (
        <MultipleActionsMenu
          actions={actions}
          ariaActionsFor={ariaActionsFor}
        />
      )}
    </Grid>
  );
};
