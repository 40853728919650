/**
 * Can we build these routes dynamically vs. hardcoding them?
 * Should the exist here or in the appNavigation hook?
 */
export const ROOT_ROUTE: string = '/';
/**
 * @deprecated We are moving toward using the workspace slug as the root route instead of "dashboard"
 */
export const DASHBOARD_ROUTE: string = '/dashboard';

export const SETTINGS_ROUTE: string = `settings`;
export const PROFILE_ACCOUNT_SETTINGS_ROUTE: string = `${SETTINGS_ROUTE}/my-account`;
export const NOTIFICATIONS_ACCOUNT_SETTINGS_ROUTE: string = `${SETTINGS_ROUTE}/my-account/notifications`;
export const GENERAL_WORKSPACE_SETTINGS_ROUTE: string = `${SETTINGS_ROUTE}/general`;
export const MEMBERS_WORKSPACE_SETTINGS_ROUTE: string = `${SETTINGS_ROUTE}/members`;
export const INVITES_WORKSPACE_SETTINGS_ROUTE: string = `${SETTINGS_ROUTE}/invites`;
export const SUBSCRIPTION_WORKSPACE_SETTINGS_ROUTE: string = `${SETTINGS_ROUTE}/subscription`;

export const REQUIRED_ACTIONS_ROUTE: string = `/account-setup`;

export const RoutePaths = {
  WORKSPACE_CREATION_PAGE: '/signup/create-account',
};

export const MY_AVAILABLE_WORKSPACES_ROUTE: string = `/my-workspaces`;

/**
 * Currently used by the navigation drawer but it doesn't need to be hardcoded here.
 * @deprecated
 */
export const SETTINGS_ROUTES = [SETTINGS_ROUTE];
