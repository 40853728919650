import { ListResponse, WorkspaceTaskResource } from '@outbound/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

/**
 * Get /workspace/tasks
 * Query list all tasks for the current workspace
 * @returns
 */
export const useFetchWorkspaceTasks = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useQuery<undefined, undefined, ListResponse<WorkspaceTaskResource>>(
    ['workspace:tasks:get'],
    async () => {
      const response = await auth0AxiosClient.get('/workspace/tasks');
      return response.data;
    }
  );
};

/**
 * Called by Workspace Owner to complete a task.
 * Not all tasks are completable by manual user action.
 * Some more compliance / legal focuses tasks may only be completable by the system.
 *
 * If the user attempts to complete a task that is not completable by manual user action,
 * the system should return a 422 Unprocessable Entity response.
 * @returns
 */
export const useCompleteWorkspaceTaskMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<void, undefined, string>(
    ['workspace:post'],
    async (taskId: string) => {
      const response = await auth0AxiosClient.post(
        `/workspace/tasks/${taskId}/complete`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workspace:tasks:get']);
      },
    }
  );
};
