import {
  IntegrationCategory,
  IntegrationConfigurationType,
  ServerDelta,
} from '@outbound/types';
import { makeObservable, observable } from 'mobx';
import { BaseModel } from '../../base-model';
import { RootStore } from '../../root-store';

class Integration extends BaseModel {
  private _slug: string;
  private _name: string;
  private _configurationType: IntegrationConfigurationType;
  private _shortDescription: string;
  private _iconImagePublicUrl: string;
  private _category: IntegrationCategory;
  private _website: string;
  private _docsLink: string;
  private _overview: any;

  constructor(
    rootStore: RootStore,
    id: string,
    slug: string,
    name: string,
    shortDescription: string,
    iconImagePublicUrl: string,
    category: IntegrationCategory,
    website: string,
    docsLink: string,
    overview: any,
    integrationConfigurationType: IntegrationConfigurationType
  ) {
    super(rootStore, 'integration', '1', id, 'system');
    this._slug = slug;
    this._name = name;
    this._shortDescription = shortDescription;
    this._iconImagePublicUrl = iconImagePublicUrl;
    this._category = category;
    this._website = website;
    this._docsLink = docsLink;
    this._overview = overview;
    this._configurationType = integrationConfigurationType;
  }

  applyPatch(_patch: ServerDelta[]): void {
    throw new Error('Method not implemented.');
  }
  toJson(): Record<string, any> {
    throw new Error('Method not implemented.');
  }
  protected makeObservableInternal(): void {
    makeObservable(this, {
      _name: observable,
    } as any);
  }

  get slug() {
    return this._slug;
  }

  get name() {
    return this._name;
  }

  get shortDescription() {
    return this._shortDescription;
  }

  get iconImagePublicUrl() {
    return this._iconImagePublicUrl;
  }

  get category(): IntegrationCategory {
    return this._category;
  }

  get website() {
    return this._website;
  }

  get docsLink() {
    return this._docsLink;
  }

  get overview() {
    return this._overview;
  }

  get configurationType() {
    return this._configurationType;
  }
}

export default Integration;
