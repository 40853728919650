import { useEffect, useState } from 'react';
import { GoogleDisplayAdSizeConfig } from '../google-display-ad-sizes';
import './../../spark.css';
import './styles.css';

export interface MultiSceneAltDisplayAdProps {
  brandName: string;
  heading: string[];
  callToActionButtonLabel: string | undefined;
  primaryColorHex: string | undefined;
  secondaryColorHex: string | undefined;
  logoImageUrl: string | undefined;
  adImages: string[];
  adUrl: string | undefined;
  isAnimated: boolean;
  adDesignGroup: string | undefined;
  adSize: string | undefined;
  highlightText: boolean | true;
  loop: boolean;
  playback?: 'play' | 'pause';
}

export type MultiSceneAltDisplayAdRefProps = {
  togglePlaybackState: () => 'play' | 'pause';
};

export const darkenColor = (hexColor: string, factor: number): string => {
  hexColor = hexColor.replace(/^#/, '');
  const bigint = parseInt(hexColor, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  r = Math.floor(r * factor);
  g = Math.floor(g * factor);
  b = Math.floor(b * factor);

  r = Math.min(255, r);
  g = Math.min(255, g);
  b = Math.min(255, b);

  const darkenedColor = `#${((1 << 24) | (r << 16) | (g << 8) | b)
    .toString(16)
    .toUpperCase()
    .slice(1)}`;

  return darkenedColor;
};

export const calculateContrastRatio = (
  color1: string,
  color2: string
): number => {
  const luminance1 = calculateRelativeLuminance(color1);
  const luminance2 = calculateRelativeLuminance(color2);

  const lightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  const contrastRatio = (lightest + 0.05) / (darkest + 0.05);

  return contrastRatio;
};

export const calculateRelativeLuminance = (hexColor: string) => {
  hexColor = hexColor.replace(/^#/, '');

  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  const normalizedR = r / 255;
  const normalizedG = g / 255;
  const normalizedB = b / 255;

  const luminance =
    0.2126 * normalizedR + 0.7152 * normalizedG + 0.0722 * normalizedB;

  return luminance;
};

export const hasEnoughContrast = (
  color1: string | undefined,
  color2: string | undefined
): boolean => {
  if (!color1 || !color2) {
    return false;
  }

  const contrastRatio = calculateContrastRatio(color1, color2);

  return contrastRatio >= 2;
};

export const MultiSceneAltDisplayAd = ({
  brandName,
  heading,
  callToActionButtonLabel,
  primaryColorHex,
  secondaryColorHex,
  logoImageUrl,
  adImages,
  adUrl,
  adSize,
  highlightText,
  playback,
}: MultiSceneAltDisplayAdProps) => {
  const adSizeStyle = (
    GoogleDisplayAdSizeConfig as Record<
      string,
      { height: string; width: string }
    >
  )[adSize ?? 'square'];

  const adContent = Array.isArray(heading)
    ? heading.flatMap((heading, index) => [heading, adImages[index]])
    : [];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const rotateContent = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % adContent.length);
    };

    const intervalId = setInterval(() => {
      if (playback === 'play') {
        rotateContent();
      }
    }, 6000);

    return () => clearInterval(intervalId);
  }, [playback, adContent.length]);

  const showText = currentIndex % 2 === 0;

  const [isHovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  const isContrastEnough = hasEnoughContrast(
    primaryColorHex,
    secondaryColorHex
  );
  const isContrastWithWhiteBetter =
    calculateContrastRatio(primaryColorHex || '', '#333333') >
    calculateContrastRatio(primaryColorHex || '', '#FFFFFF');

  const isLightBackground =
    calculateRelativeLuminance(primaryColorHex || '') > 0.5 ||
    (primaryColorHex === '#FFFFFF' && !isContrastWithWhiteBetter) ||
    (primaryColorHex === '#FFF' && !isContrastWithWhiteBetter);

  const buttonStyle = {
    color: isHovered
      ? primaryColorHex
      : isLightBackground
      ? '#333333'
      : '#FFFFFF',
    transition: '0.25s all ease-in-out',
    backgroundColor: isHovered ? secondaryColorHex : 'transparent',
    border: isHovered
      ? `2px solid ${secondaryColorHex}`
      : isLightBackground
      ? '2px solid #333333'
      : '2px solid #FFFFFF',
    boxShadow: isHovered ? '0px 4px 20px 4px rgba(0,0,0,0.15)' : '',
  };

  const headingStyle = {
    color: highlightText
      ? primaryColorHex
      : isLightBackground
      ? '#333333'
      : '#FFFFFF',
    backgroundColor: highlightText ? secondaryColorHex : undefined,
  };

  return (
    <>
      <a
        className='container ob-spark-animated'
        href={adUrl}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
        style={{
          height: `${adSizeStyle.height}`,
          width: `${adSizeStyle.width}`,
        }}
      >
        <div
          id='multiscene-alt'
          data-testid='multi-scene-alt-google-display-ad'
          style={{
            height: `${adSizeStyle.height}`,
            width: `${adSizeStyle.width}`,
            background: `linear-gradient(to bottom left, ${darkenColor(
              primaryColorHex || '',
              0.7
            )},	${primaryColorHex})`,
          }}
        >
          <div
            className={`text contentDiv ${
              showText ? 'show ob-spark-slideInAndOut' : ''
            }`}
            style={{
              display: showText ? 'block' : 'none',
              animationDuration: '6550ms',
            }}
          >
            {logoImageUrl ? (
              <img
                src={logoImageUrl}
                id='logo'
                alt={`${brandName} Logo`}
                style={{
                  width: '400px',
                  height: '75px',
                }}
              />
            ) : (
              <h1 className='logo-placeholder'>{brandName}</h1>
            )}
            <div
              className='text-container'
              style={{
                color: isContrastEnough
                  ? secondaryColorHex || ''
                  : calculateRelativeLuminance(secondaryColorHex || '') > 0.5
                  ? '#333333'
                  : '#FFFFFF',
              }}
            >
              <h2 style={headingStyle}>{adContent[currentIndex]}</h2>
            </div>
            <a
              href={adUrl}
              id='btn'
              style={buttonStyle}
            >
              {callToActionButtonLabel}
            </a>
          </div>
          <div
            id='image'
            className={`contentDiv ${
              showText ? '' : 'show ob-spark-slideInAndOut'
            }`}
            style={{
              display: showText ? 'none' : 'block',
              animationDuration: '6050ms',
            }}
          >
            <img
              src={adContent[currentIndex]}
              alt={`${brandName}`}
              id='featured-photo'
            />
          </div>
        </div>
      </a>
    </>
  );
};

export default MultiSceneAltDisplayAd;
