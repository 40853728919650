import { toObrn } from '@otbnd/utils';
import {
  BaseTemplateSettingDefinitionResource,
  BaseTemplateValueResource,
  ServerDelta,
  TextTemplateValueResource,
} from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import BaseSetting from './base-setting';
import TextSetting from './text-setting';

export type SyntheticSettingResource = {
  settingSchema: BaseTemplateSettingDefinitionResource;
  settingValue: BaseTemplateValueResource;
  workspaceId: string;
  creativeId: string;
};

class SettingTransformer extends BaseTransformer<
  SyntheticSettingResource,
  BaseSetting
> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: BaseSetting,
    _resource: {
      settingSchema: BaseTemplateSettingDefinitionResource;
      settingValue: BaseTemplateValueResource;
    }
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }

  fromApiResource(apiResource: {
    settingSchema: BaseTemplateSettingDefinitionResource;
    settingValue: BaseTemplateValueResource | null; //If there is no value we will initialize it (Not sure if we need the null value here, the creative-transformer is checking for a truthy value...)
    workspaceId: string;
    creativeId: string;
  }): BaseSetting {
    const { settingSchema, settingValue } = apiResource;

    switch (settingSchema.type) {
      case 'text': {
        const value = settingValue as TextTemplateValueResource;
        return new TextSetting(
          this._rootStore,
          settingSchema.id,
          apiResource.workspaceId,
          apiResource.settingValue?.obrn ||
            toObrn({
              objectType: 'creative/setting',
              localPathId: `${apiResource.creativeId}/${settingSchema.id}`,
              workspaceId: apiResource.workspaceId,
            }),
          settingSchema.label,
          value?.remixLocked || false,
          value?.value || { text: '' },
          settingSchema.publishingValidation
        );
      }
      case 'image': {
        throw new Error('Not implemented');
      }
      case 'color': {
        throw new Error('Not implemented');
      }
    }
  }
}

export default SettingTransformer;
