import { ServerDelta, ServiceResource } from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import { Service } from './service';
import { ServiceOfferingTransformer } from './service-offering/service-offering-transformer';

class ServiceTransformer extends BaseTransformer<ServiceResource, Service> {
  private serviceOfferingTransformer: ServiceOfferingTransformer;
  constructor(rootStore: RootStore) {
    super(rootStore);
    this.serviceOfferingTransformer = new ServiceOfferingTransformer(rootStore);
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: Service,
    _resource: ServiceResource
  ): ServerDelta[] {
    return [];
  }

  fromApiResource(resource: ServiceResource): Service {
    const serviceOfferings = resource.subServices.map((serviceOffering) => {
      return this.serviceOfferingTransformer.fromApiResource({
        serviceId: resource.id,
        subService: serviceOffering,
        workspaceId: resource.workspaceId,
      });
    });

    return new Service(
      this._rootStore,
      resource.id,
      resource.workspaceId,
      resource.name,
      resource.description ?? '',
      new Map(
        serviceOfferings.map((serviceOffering) => [
          serviceOffering.id,
          serviceOffering,
        ])
      )
    );
  }
}

export default ServiceTransformer;
