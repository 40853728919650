import {
  ObDeleteCard,
  ObFormRenderer,
  ObSubItemProps,
} from '@outbound/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useFetchPlaybookSettings } from '../../../../../../query/playbook/use-playbook-endpoints';
import { PlaybookDrawerContent } from '../../_common/playbook-drawer-content';

import { SubServiceResourceIncludeAssets } from '@outbound/types';
import {
  useDeleteServiceById,
  usePatchServiceById,
} from '../../../../../../query/playbook/use-playbook-settings-service-endpoints';
import { useDrawerEditForm } from '../../_common/use-drawer-edit-form';
import {
  mapApiResourceToFormValues,
  mapFormValuesToApiResource,
} from '../forms/service-form-utils';
import { SERVICE_FORM_DEF } from '../forms/service-form.def';
import { SubServiceList } from './sub-service-list/sub-service-list';

export interface ServiceEditDrawerProps {
  serviceId: string;
  /**
   * Closes the drawer after saving the form.
   * Useful when creating a new service
   */
  popOnSave?: boolean;

  /**
   * If present the drawer will open to the sub-service with the given id
   */
  openToServiceOfferingId?: string;
}
export const ServiceEditDrawer = ({
  serviceId,
  popOnSave = false,
  openToServiceOfferingId,
}: ServiceEditDrawerProps) => {
  const {
    getRegisteredFormById,
    initialValues,
    setInitialValues,
    onFormDirtyChange,
    isInitialValuesFetched,
    setIsInitialValuesFetched,
    areChangesUnsaved,
    notificationService,
    drawerService,
  } = useDrawerEditForm();

  /**
   * Currently the only way to fetch persona data is to fetch the entire playbook settings
   * We may add a get endpoint to cut down on the data being fetched
   */
  const { data: playbookSettings, isFetched: isPlaybookSettingsFetched } =
    useFetchPlaybookSettings();

  const { mutateAsync: patchService } = usePatchServiceById(serviceId);

  const { mutateAsync: deleteService } = useDeleteServiceById(serviceId);

  const [subServices, setSubServices] = useState<
    Array<SubServiceResourceIncludeAssets>
  >([]);

  /**
   * Side Effect run to initialize the form
   */
  useEffect(() => {
    if (isPlaybookSettingsFetched && !isInitialValuesFetched) {
      const serviceContent = playbookSettings?.services?.find(
        (service) => service.id === serviceId
      );
      if (serviceContent == null) {
      } else {
        setInitialValues(mapApiResourceToFormValues(serviceContent));
        setSubServices(serviceContent.subServices ?? []);
        setIsInitialValuesFetched(true);
      }
    }
  }, [
    isInitialValuesFetched,
    isPlaybookSettingsFetched,
    playbookSettings?.services,
    serviceId,
    setInitialValues,
    setIsInitialValuesFetched,
  ]);

  useEffect(() => {
    const serviceContent = playbookSettings?.services?.find(
      (service) => service.id === serviceId
    );
    setSubServices(serviceContent?.subServices ?? []);
  }, [playbookSettings, serviceId]);

  const handleUpdateService = useCallback(
    async (values: any) => {
      const result = await patchService({
        values: mapFormValuesToApiResource(values),
      });

      if (popOnSave) {
        drawerService.popDrawer();
      }

      return result;
    },
    [drawerService, patchService, popOnSave]
  );

  const handleDeleteService = useCallback(async () => {
    try {
      await deleteService();
      /**
       * After we delete the service we want to close the drawer.
       */
      drawerService.popDrawer();

      notificationService.pushNotification({
        title: 'Service Deleted',
        body: 'The service has been deleted successfully.',
      });
    } catch (error) {}
  }, [deleteService, drawerService, notificationService]);

  return (
    <PlaybookDrawerContent
      primaryActionLabel={'Save'}
      primaryActionCallback={async () => {
        return getRegisteredFormById(SERVICE_FORM_DEF.id)?.submitFunction();
      }}
      secondaryActionLabel={'Discard'}
      secondaryActionCallback={async () => {
        return getRegisteredFormById(
          SERVICE_FORM_DEF.id
        )?.discardChangesFunction();
      }}
      showFooter={areChangesUnsaved}
    >
      {isInitialValuesFetched && (
        <div className='flex flex-col justify-between flex-1 h-full'>
          <div className='pb-4'>
            <ObFormRenderer
              formDefinition={SERVICE_FORM_DEF}
              hideSubmitButton={true}
              defaultValues={initialValues}
              onSubmitCallback={handleUpdateService}
              onFormDirtyChangeCallback={onFormDirtyChange}
            />
            <SubServiceList
              serviceId={serviceId}
              openToServiceOfferingId={openToServiceOfferingId}
              subItems={subServices.map(
                (subService): ObSubItemProps => ({
                  id: subService.id,
                  header: subService.name,
                  description: subService.description ?? '',
                  tags: subService.relatedKeywords ?? [],
                  keyPhotoUrl: subService.keyPhotoAsset?.publicUrl,
                  keyPhotoAlt: '', //TODO CONNECT WITH DESCRIPTION
                })
              )}
            />
          </div>
          <div className='pb-8'>
            <ObDeleteCard
              typeToConfirmValue={initialValues.name}
              isLoading={false}
              cardTitle={'Delete Service'}
              cardBody={
                "Deleting a service will delete all of it's associated data and you will no longer be able to use it to target campaigns."
              }
              cardButtonLabel={'Delete'}
              confirmationDialogTitle={'Delete Service'}
              confirmationDialogBody={
                'This action is permanent and cannot be undone.'
              }
              typeToConfirmInstructions={
                'Type the name of the service to confirm'
              }
              onDeleteConfirmedCallback={handleDeleteService}
            />
          </div>
        </div>
      )}
    </PlaybookDrawerContent>
  );
};
