import {
  PatchBusinessDetailsResourceRequest,
  PlaybookBusinessDetailsResource,
} from '@outbound/types';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';
import { playbookSettingsKeys } from './playbook-query-key';

const SERVICE_RESOURCE_PATH = '/playbook/settings/business-details';

const RESOURCE_NAME = 'business-details';

export const useFetchBusinessDetails = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, PlaybookBusinessDetailsResource>(
    playbookSettingsKeys.resource(RESOURCE_NAME),
    async () => {
      const response = await auth0AxiosClient.get(SERVICE_RESOURCE_PATH);
      return response.data ?? '';
    }
  );
};

export const usePatchBusinessDetails = () => {
  const queryClient = useQueryClient();
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useMutation<
    undefined,
    undefined,
    { values: PatchBusinessDetailsResourceRequest }
  >(
    [...playbookSettingsKeys.resource(RESOURCE_NAME), 'patch'],
    async ({ values }) => {
      const response = await auth0AxiosClient.patch(
        `${SERVICE_RESOURCE_PATH}`,
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(playbookSettingsKeys.all);
      },
    }
  );
};

/**
 * Generic polling function for playbook business details
 * @param pollingFunction A function that returns true if business details should be polled, receives the playbook business details resource as an argument
 * @param pollingInterval Optional polling interval. Default 2000ms
 * @returns
 */
export const usePollPlaybookBusinessDetails = (
  pollingFunction: (
    businessDetails?: PlaybookBusinessDetailsResource
  ) => boolean,
  pollingInterval?: number
) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, PlaybookBusinessDetailsResource>(
    playbookSettingsKeys.resource(RESOURCE_NAME),
    async () => {
      const response = await auth0AxiosClient.get(SERVICE_RESOURCE_PATH);
      return response.data;
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(['playbook:get']);
      },
      refetchInterval: (data?: PlaybookBusinessDetailsResource) => {
        const shouldPoll = pollingFunction && pollingFunction(data);
        return shouldPoll ? pollingInterval ?? 2000 : false;
      },
      // Poll even when the tab is in the background
      refetchIntervalInBackground: false,
    }
  );
};

/**
 * This will poll the server until the generative AI for business details has been generated.
 *
 * The polling will stop when the status !== 'GENERATING'
 *
 */
export const usePollForAIGeneratedBusinessDetails = (
  pollingInterval: number = 2000
) => {
  const queryClient = useQueryClient();

  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  const [isBusinessDetailsReady, setIsBusinessDetailsReady] =
    useState<boolean>(false);

  const queryKey = playbookSettingsKeys.resource(RESOURCE_NAME);

  useEffect(() => {
    //Query Key must match that in the useQuery call below
    const businessDetails =
      queryClient.getQueryData<PlaybookBusinessDetailsResource>(queryKey);
    if (
      businessDetails == null ||
      businessDetails?.generativeAiStatus === 'GENERATING'
    ) {
      setIsBusinessDetailsReady(false);
    }
  }, [queryClient, queryKey]);

  return {
    isBusinessDetailsReady,
    ...useQuery<
      PlaybookBusinessDetailsResource | undefined,
      undefined,
      PlaybookBusinessDetailsResource
    >(
      queryKey,
      async () => {
        const response = await auth0AxiosClient.get(SERVICE_RESOURCE_PATH);

        return response.data;
      },
      {
        refetchInterval: (data?: PlaybookBusinessDetailsResource) => {
          const shouldPoll =
            data == null || data?.generativeAiStatus === 'GENERATING';
          setIsBusinessDetailsReady(!shouldPoll);
          return shouldPoll ? pollingInterval : false;
        },
        // Poll even when the tab is in the background
        refetchIntervalInBackground: false,
      }
    ),
  };
};
