export const Building04 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.91683 5.83333H12.0835M7.91683 9.16667H12.0835M7.91683 12.5H12.0835M15.0002 17.5V5.16667C15.0002 4.23325 15.0002 3.76654 14.8185 3.41002C14.6587 3.09641 14.4038 2.84144 14.0901 2.68166C13.7336 2.5 13.2669 2.5 12.3335 2.5H7.66683C6.73341 2.5 6.2667 2.5 5.91018 2.68166C5.59658 2.84144 5.34161 3.09641 5.18182 3.41002C5.00016 3.76654 5.00016 4.23325 5.00016 5.16667V17.5M16.6668 17.5H3.3335'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </svg>
  );
};
