import { FormDefinition, FormFieldType } from '@outbound/design-system';

/**
 * The minimum form definition required to create a service
 */
export const CREATE_SERVICE_MINI_FORM_DEF: FormDefinition = {
  id: 'create-service-mini-form',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'name',
          autofocus: true,
          label: 'Service Name',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
      ],
    },
  ],
  heading: '',
  subHeading: '',
};

/**
 * Full form definition used for the edit service form.
 */
export const SERVICE_FORM_DEF: FormDefinition = {
  id: 'service-form',
  sections: [
    {
      id: '',
      heading: '',
      subHeading: '',
      fields: [
        {
          id: 'name',
          autofocus: true,
          label: 'Service Name',
          helperText: '',
          fieldTypeSettings: {},
          type: FormFieldType.TEXT,
          validationSchema: {
            isRequired: true,
          },
        },
        {
          id: 'description',
          label: 'Description',
          helperText: '',
          type: FormFieldType.TEXT_AREA,
          fieldTypeSettings: {},
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'pricing',
      heading: 'Pricing',
      subHeading: '',
      fields: [
        {
          id: 'averagePricePerSale',
          label: 'How much do you charge for this service on average? ',
          helperText:
            'Estimates or guesses are better than leaving this blank. With your price per sale Outbound can help you stay on budget and help you make better decisions about your marketing spend.',
          fieldTypeSettings: {},
          type: FormFieldType.NUMBER,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
    {
      id: 'keywords',
      heading: 'Keywords',
      subHeading: '',
      fields: [
        {
          id: 'relatedKeywords',
          label: 'Keywords',
          helperText:
            'What are some words or phrases a customer might search for to find this service?',
          fieldTypeSettings: {},
          type: FormFieldType.CHIPS,
          validationSchema: {
            isRequired: false,
          },
        },
      ],
    },
  ],
  heading: '',
  subHeading: '',
};
