import { BaseBuilderTemplateValueProps } from '@outbound/types';

/**
 * Takes an array of setting values and builds a map of the values.
 * Optionally takes a current map to compare the values to and only update the map if the value is newer.
 * @param settingValues
 * @param currentMap
 * @returns
 */
export const buildTemplateValueMapFromArray = (
  settingValues: Array<BaseBuilderTemplateValueProps>,
  currentMap?: Map<string, BaseBuilderTemplateValueProps>
) => {
  const templateValues = new Map<string, BaseBuilderTemplateValueProps>();
  settingValues.forEach((value) => {
    let nextValue = value;
    if (currentMap) {
      const currentValue = currentMap.get(value.id);
      if (currentValue) {
        if (currentValue.updatedAtTimestamp > value.updatedAtTimestamp) {
          nextValue = currentValue;
        }
      }
    }
    templateValues.set(value.id, nextValue);
  });
  return templateValues;
};
