'use client';
import { Dialog } from '@headlessui/react';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import { cropImage } from './image-cropper-upload-utils';
import {
  CroppedImage,
  CroppedResult,
  ImageCropperUploadInterface,
  Shape,
} from './types';

const CROPPER_SHAPE = {
  [Shape.circle]: 'round' as const,
  [Shape.rectangle]: 'rect' as const,
};

export type DialogImageCropperUploadProps = {
  /**
   * Boolean value that indicates if the modal is open. This should be passed the 'isOpen' variable from the useModal hook
   */
  show: boolean;
  /**
   * The image to be cropped
   */
  image: string;
  /**
   * Function to be called when the dialog is confirmed
   */
  onDialogConfirm: (result: CroppedResult) => void;
  /**
   * Function to be called when the dialog is canceled
   */
  onDialogCancel: () => void;
} & Required<ImageCropperUploadInterface>;

const INITIAL_CROP_STATE = { x: 0, y: 0 };

const DialogImageCropperUpload = ({
  show,
  label,
  cropModalHeading,
  image,
  shape,
  aspectRatio,
  showGrid,
  cropModalSaveButtonLabel,
  onDialogConfirm,
  cropModalCancelButtonLabel,
  onDialogCancel,
}: DialogImageCropperUploadProps) => {
  const [crop, setCrop] = useState(INITIAL_CROP_STATE);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedImage>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const resetCropperSettings = useCallback(() => {
    setZoom(1);
    setCrop(INITIAL_CROP_STATE);
  }, []);

  const onCropComplete = useCallback(
    (_croppedArea: CroppedImage, croppedAreaPixels: CroppedImage) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [setCroppedAreaPixels]
  );

  const getCroppedImage = useCallback(async (): Promise<
    CroppedResult | undefined
  > => {
    try {
      const croppedImage = await cropImage(image, croppedAreaPixels);
      return Promise.resolve({
        originalFileName: '',
        croppedImage,
        crop: croppedAreaPixels,
        rotation: 0,
      });
    } catch (e) {
      Promise.reject(e);
    }
  }, [croppedAreaPixels, image]);

  const onClose = useCallback(() => {
    onDialogCancel();
    resetCropperSettings();
  }, [onDialogCancel, resetCropperSettings]);

  const onSave = useCallback(async () => {
    const result = await getCroppedImage();
    if (result) {
      onDialogConfirm(result);
      resetCropperSettings();
    }
  }, [getCroppedImage, onDialogConfirm, resetCropperSettings]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
    >
      <div className='fixed inset-0 flex items-center justify-center w-full bg-bgDefaultDark z-50'>
        <Dialog.Panel className='w-3/4 bg-gray-900 rounded-lg px-6 py-4'>
          <ObTypography
            variant='h3'
            className='mb-4'
          >
            {cropModalHeading}
          </ObTypography>
          <div className='relative h-96'>
            <Cropper
              image={image}
              aspect={aspectRatio}
              crop={crop}
              zoom={zoom}
              showGrid={showGrid}
              cropShape={CROPPER_SHAPE[shape]}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className='my-5 mx-2.5'>
            {!!label && (
              <label
                htmlFor='slider'
                className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
              >
                {label}
              </label>
            )}
            <input
              id='slider'
              className='w-full h-2 cursor-pointer rounded-lg appearance-none dark:bg-gray-400
          [&::-webkit-slider-thumb]:appearance-none
          [&::-webkit-slider-thumb]:h-[20px]
          [&::-webkit-slider-thumb]:w-[20px]
          [&::-webkit-slider-thumb]:rounded-full
          [&::-webkit-slider-thumb]:bg-actionPrimaryDark
          hover:[&::-webkit-slider-thumb]:bg-actionPrimaryLight'
              type='range'
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby='Zoom'
              onChange={(e) => {
                setZoom(Number(e.target.value));
              }}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <ObButton
              size='large'
              onClick={onSave}
            >
              {cropModalSaveButtonLabel}
            </ObButton>
            <ObButton
              size='large'
              variant='secondary'
              onClick={onClose}
            >
              {cropModalCancelButtonLabel}
            </ObButton>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default DialogImageCropperUpload;
