export const ShoppingCart03 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.3331 9.33331H12.0902C12.7655 9.33331 13.1031 9.33331 13.3718 9.20743C13.6086 9.09653 13.8075 8.91848 13.9439 8.69542C14.0987 8.4422 14.136 8.10665 14.2105 7.43557L14.6005 3.92554C14.6233 3.72057 14.6347 3.61808 14.6017 3.53875C14.5728 3.46908 14.5211 3.41129 14.455 3.37483C14.3798 3.33331 14.2767 3.33331 14.0705 3.33331H2.99977M1.33301 1.33331H2.1653C2.34172 1.33331 2.42992 1.33331 2.49893 1.36686C2.55969 1.3964 2.61003 1.4437 2.6433 1.50249C2.68109 1.56927 2.68659 1.65731 2.6976 1.83338L3.30175 11.4999C3.31276 11.676 3.31826 11.764 3.35605 11.8308C3.38932 11.8896 3.43966 11.9369 3.50042 11.9664C3.56943 12 3.65763 12 3.83405 12H12.6663M4.99967 14.3333H5.00634M10.9997 14.3333H11.0063M5.33301 14.3333C5.33301 14.5174 5.18377 14.6666 4.99967 14.6666C4.81558 14.6666 4.66634 14.5174 4.66634 14.3333C4.66634 14.1492 4.81558 14 4.99967 14C5.18377 14 5.33301 14.1492 5.33301 14.3333ZM11.333 14.3333C11.333 14.5174 11.1838 14.6666 10.9997 14.6666C10.8156 14.6666 10.6663 14.5174 10.6663 14.3333C10.6663 14.1492 10.8156 14 10.9997 14C11.1838 14 11.333 14.1492 11.333 14.3333Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
