import { BaseTemplateValueResource, CreativeResource } from '@outbound/types';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

/**
 *
 * @param creativeId The creative who's settings should be updated
 * @param settingValues  The values of the creative settings to be updated
 *                       This can be a partial update, only the values that are being updated need to be included
 */
export const useSendSettingValueUpdatesToServer = (
  creativeId: string | undefined
) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const queryClient = useQueryClient();
  return useMutation<
    undefined,
    undefined,
    { settingValuesToUpdate: Array<BaseTemplateValueResource> }
  >(
    ['creative', creativeId, 'settings', 'patch'],
    async ({ settingValuesToUpdate }) => {
      if (creativeId == null) {
        throw new Error(
          'Cannot Update Creative. A Creative id must be provided.'
        );
      }
      const response = await auth0AxiosClient.patch(
        `/creative-manager/creatives/${creativeId}/template-setting-values`,
        {
          templateSettingValues: settingValuesToUpdate,
        }
      );

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['creative', creativeId, 'get']);
      },
    }
  );
};

/**
 * This hook is similar to the useFetchCreativeById hook, but it will poll the server until the creative is initialized.
 * This is useful when we need to wait for the creative to be initialized before we can display it.
 *
 * The creative is considered initialized when the status != 'PENDING_INITIALIZATION'
 * @param id The creative that we want to display
 */
export const usePollForInitializedCreative = (
  id: string | undefined,
  pollingInterval: number = 2000
) => {
  const queryClient = useQueryClient();

  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  const [isCreativeReady, setIsCreativeReady] = useState<boolean>(false);

  /**
   * Based on the expected Generation time, we can estimate the percent complete of the creative generation
   */
  const [estimatedPercentComplete, setEstimatedPercentComplete] =
    useState<number>(0);

  const queryKey = useMemo(() => ['creative', id, 'get'], [id]);

  /**
   * Handle the side effects of the id changing from one value to another.
   * We need to see if the creative is initialized or not and reset the estimated percent complete and isCreativeReady
   * values appropriately. Without this we may show an incorrect loading state to end users due to the stale data from
   * a previous creative. (This behavior was showing up in the marketing plan when sliding between plans with different creatives)
   */
  useEffect(() => {
    //Query Key must match that in the useQuery call below
    const creative = queryClient.getQueryData<CreativeResource>(queryKey);
    if (creative == null || creative?.status === 'PENDING_INITIALIZATION') {
      setEstimatedPercentComplete(0);
      setIsCreativeReady(false);
    }
  }, [id, queryClient, queryKey]);

  /**
   * Indicates how long we expect the generation for this creative to take. (In Seconds)
   */
  //Future Enhancement: Add this to the API as a field on the template so we can use this to make better loading estimates
  //Also add the initialized at timestamp so we can based it off of the time remaining
  //Lastly we can use this to show a "This is taking a bit longer than expected" or "just a little bit longer" type of message

  return {
    isCreativeReady,
    estimatedPercentComplete,
    ...useQuery<CreativeResource | undefined, undefined, CreativeResource>(
      queryKey,
      async () => {
        const response = await auth0AxiosClient.get(
          `/creative-manager/creatives/${id}`
        );

        const isInitializing =
          response.data == null ||
          response.data?.status === 'PENDING_INITIALIZATION';

        if (isInitializing) {
          /**
           * Very basic dummy logic that doesn't take into account estimated times on the
           * creative itself. We should move this to the API and have the API return the estimated time remaining
           */
          setEstimatedPercentComplete((currentValue) => {
            if (currentValue < 100) {
              return currentValue + 2;
            } else {
              return 100;
            }
          });
        } else {
          setEstimatedPercentComplete(100); //Important or creative would never show
        }

        return response.data;
      },
      {
        enabled: !!id,
        refetchInterval: (data?: CreativeResource) => {
          const shouldPoll =
            data == null || data?.status === 'PENDING_INITIALIZATION';
          setIsCreativeReady(!shouldPoll);
          return shouldPoll ? pollingInterval : false;
        },
        // Poll even when the tab is in the background
        refetchIntervalInBackground: false,
      }
    ),
  };
};

export const useFetchCreativeById = (id: string | undefined) => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useQuery<CreativeResource | undefined, undefined, CreativeResource>(
    ['creative', id, 'get'],
    async () => {
      const response = await auth0AxiosClient.get(
        `/creative-manager/creatives/${id}`
      );

      return response.data;
    },
    {
      enabled: !!id,
      onSuccess: () => {
        //
      },
    }
  );
};
