export const ImageIndentLeft = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.00004 2C1.63185 2 1.33337 2.29848 1.33337 2.66667C1.33337 3.03486 1.63185 3.33333 2.00004 3.33333H14C14.3682 3.33333 14.6667 3.03486 14.6667 2.66667C14.6667 2.29848 14.3682 2 14 2H2.00004Z'
        fillRule='evenodd'
        clipRule='evenodd'
        fill='currentColor'
      />
      <path
        d='M10 5.5C9.63185 5.5 9.33337 5.79848 9.33337 6.16667C9.33337 6.53486 9.63185 6.83333 10 6.83333H14C14.3682 6.83333 14.6667 6.53486 14.6667 6.16667C14.6667 5.79848 14.3682 5.5 14 5.5H10Z'
        fillRule='evenodd'
        clipRule='evenodd'
        fill='currentColor'
      />
      <path
        d='M9.33337 9.83333C9.33337 9.46514 9.63185 9.16667 10 9.16667H14C14.3682 9.16667 14.6667 9.46514 14.6667 9.83333C14.6667 10.2015 14.3682 10.5 14 10.5H10C9.63185 10.5 9.33337 10.2015 9.33337 9.83333Z'
        fillRule='evenodd'
        clipRule='evenodd'
        fill='currentColor'
      />
      <path
        d='M2.00004 12.6667C1.63185 12.6667 1.33337 12.9651 1.33337 13.3333C1.33337 13.7015 1.63185 14 2.00004 14H14C14.3682 14 14.6667 13.7015 14.6667 13.3333C14.6667 12.9651 14.3682 12.6667 14 12.6667H2.00004Z'
        fillRule='evenodd'
        clipRule='evenodd'
        fill='currentColor'
      />
      <path
        d='M3.04545 4.66667C2.87719 4.66665 2.71333 4.66663 2.57456 4.67797C2.42216 4.69042 2.24234 4.7198 2.06139 4.81199C1.81051 4.93983 1.60653 5.1438 1.4787 5.39468C1.3865 5.57563 1.35713 5.75545 1.34467 5.90785C1.33334 6.04662 1.33336 6.21048 1.33338 6.37873L1.33338 9.62127C1.33336 9.78952 1.33334 9.95339 1.34467 10.0922C1.35713 10.2446 1.3865 10.4244 1.4787 10.6053C1.60653 10.8562 1.81051 11.0602 2.06139 11.188C2.24234 11.2802 2.42216 11.3096 2.57456 11.322C2.71333 11.3334 2.8772 11.3334 3.04546 11.3333H6.28797C6.45622 11.3334 6.62009 11.3334 6.75886 11.322C6.91126 11.3096 7.09108 11.2802 7.27203 11.188C7.52291 11.0602 7.72689 10.8562 7.85472 10.6053C7.94692 10.4244 7.9763 10.2446 7.98875 10.0922C8.00009 9.95338 8.00007 9.78952 8.00005 9.62126V6.37875C8.00007 6.21049 8.00009 6.04663 7.98875 5.90785C7.9763 5.75545 7.94692 5.57563 7.85472 5.39468C7.72689 5.1438 7.52291 4.93983 7.27203 4.81199C7.09108 4.7198 6.91126 4.69042 6.75886 4.67797C6.62009 4.66663 6.45624 4.66665 6.28798 4.66667H3.04545Z'
        fillRule='evenodd'
        clipRule='evenodd'
        fill='currentColor'
      />
    </svg>
  );
};
