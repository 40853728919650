import { cva } from 'class-variance-authority';
import { ObTypography } from '../ob-typography/ob-typography';

export interface ObTableHeaderCellProps {
  label: string;
  hideOnDevicesSmallerThan?: 'sm' | 'md' | 'lg' | 'xl';
}

export const ObTableHeaderCell = ({
  label,
  hideOnDevicesSmallerThan: hideOnBreakPointSmallerThan,
}: ObTableHeaderCellProps) => {
  const tableHeaderStyles = cva('py-3.5 pl-4 pr-3 sm:pl-0 text-left', {
    variants: {
      hideOnBreakPointSmallerThan: {
        xl: 'hidden xl:table-cell',
        lg: 'hidden lg:table-cell',
        md: 'hidden md:table-cell',
        sm: 'hidden sm:table-cell',
      },
    },
  });
  return (
    <th
      scope='col'
      className={tableHeaderStyles({ hideOnBreakPointSmallerThan })}
    >
      <ObTypography
        color='tertiary'
        variant='subtitle3'
        className='uppercase'
        as='span'
      >
        {label}
      </ObTypography>
    </th>
  );
};
