export const Email = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='solid/communication/mail-01'>
        <g id='Solid'>
          <path
            d='M1.4223 5.45429C1.22235 5.31147 1.12238 5.24005 1.03521 5.23078C0.904861 5.21692 0.774222 5.28415 0.709739 5.39828C0.666621 5.47459 0.666623 5.5961 0.666626 5.83912V10.1609C0.666618 10.6975 0.666611 11.1404 0.696089 11.5012C0.726706 11.8759 0.792416 12.2204 0.957276 12.544C1.21294 13.0457 1.62089 13.4537 2.12265 13.7094C2.44621 13.8742 2.79068 13.9399 3.16541 13.9705C3.5262 14 3.9691 14 4.50574 14H11.4942C12.0308 14 12.4737 14 12.8345 13.9705C13.2092 13.9399 13.5537 13.8742 13.8773 13.7094C14.379 13.4537 14.787 13.0457 15.0426 12.544C15.2075 12.2204 15.2732 11.8759 15.3038 11.5012C15.3333 11.1404 15.3333 10.6975 15.3333 10.1609V5.83912C15.3333 5.72001 15.3333 5.66045 15.3224 5.61886C15.2731 5.43084 15.0686 5.32374 14.886 5.39021C14.8456 5.40491 14.796 5.43924 14.6968 5.50789L9.5354 9.08129C9.16944 9.33544 8.84812 9.55859 8.48309 9.64707C8.16381 9.72446 7.83038 9.72201 7.51227 9.63993C7.14858 9.54609 6.83057 9.31824 6.46839 9.05874L1.4223 5.45429Z'
            fill='currentColor'
          />
          <path
            d='M14.7552 3.84581C14.8782 3.76061 14.9398 3.71801 14.9776 3.64949C15.0069 3.59629 15.0237 3.51203 15.017 3.45163C15.0083 3.37386 14.9753 3.32251 14.9092 3.21982C14.6559 2.82643 14.2915 2.5017 13.8773 2.29065C13.5537 2.12579 13.2092 2.06008 12.8345 2.02946C12.4737 1.99999 12.0308 1.99999 11.4942 2H4.50576C3.96912 1.99999 3.5262 1.99999 3.16541 2.02946C2.79068 2.06008 2.44621 2.12579 2.12265 2.29065C1.74996 2.48054 1.42783 2.75507 1.18287 3.0889C1.1058 3.19393 1.06727 3.24644 1.05458 3.32614C1.04465 3.38846 1.05851 3.47505 1.0874 3.53116C1.12434 3.60291 1.18842 3.64868 1.31658 3.74022L7.16665 7.91884C7.65146 8.26514 7.75434 8.32539 7.84539 8.34888C7.95142 8.37624 8.06256 8.37706 8.16899 8.35126C8.26037 8.32911 8.36413 8.27038 8.85398 7.93125L14.7552 3.84581Z'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  );
};
