import { PlaybookBusinessDetailsResource } from '@outbound/types';

export interface OnlinePresenceFormValues {
  website: string;
  facebookUrl?: string;
  instagramUrl?: string;
  xUrl?: string;
  googleBusinessUrl?: string;
  tiktokUrl?: string;
}

export const mapFormValuesToApiResource = (
  formValues: OnlinePresenceFormValues
): PlaybookBusinessDetailsResource => {
  return {
    website: formValues.website,
    facebookUrl: formValues.facebookUrl,
    instagramUrl: formValues.instagramUrl,
    xUrl: formValues.xUrl,
    googleBusinessUrl: formValues.googleBusinessUrl,
    tiktokUrl: formValues.tiktokUrl,
  };
};

export const mapApiResourceToFormValues = (
  resource: PlaybookBusinessDetailsResource
): OnlinePresenceFormValues => {
  return {
    website: resource?.website ?? '',
    facebookUrl: resource?.facebookUrl ?? '',
    instagramUrl: resource?.instagramUrl ?? '',
    xUrl: resource?.xUrl ?? '',
    googleBusinessUrl: resource?.googleBusinessUrl ?? '',
    tiktokUrl: resource?.tiktokUrl ?? '',
  };
};
