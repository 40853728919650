import { useCallback, useMemo } from 'react';
import { OnboardingQuestion } from '../questionnaire.type';
import {
  SERVICE_ACTIVITIES_QUESTION,
  SERVICE_IN_BUSINESS_LOCATION,
  SERVICE_IN_CUSTOMER_LOCATION,
  SERVICE_ONLINE,
} from '../questions';
import { CheckboxGroupCardOption } from '@outbound/design-system/src/components/molecules/ob-card-option-checkbox-group/ob-card-option-checkbox-group.types';
import {
  useFetchBusinessDetails,
  usePatchBusinessDetails,
} from '../../../../query/playbook/use-playbook-settings-business-details-endpoints';

export const useServicesActivitiesQuestion = (
  questions: Array<OnboardingQuestion>
) => {
  const QUESTION_ID = SERVICE_ACTIVITIES_QUESTION;

  //Grab the Industry Question from the Question Data
  const question = questions.find((q) => q.id === QUESTION_ID);

  const { refetch: refetchBusinessDetails } = useFetchBusinessDetails();

  const { mutateAsync: patchBusinessDetails } = usePatchBusinessDetails();

  /**
   * Function that will persist the business name question
   * @param answers
   */
  const updateServiceBusinessActivitiesBooleans = useCallback(
    async (answers: { [key: string]: any }) => {
      const productActivityAnswer: Array<CheckboxGroupCardOption> =
        answers[`${QUESTION_ID}`]; //Lookup our answers by question id
      const serviceBusinessActivities = {
        isServiceInBusinessLocation: productActivityAnswer.some(
          (i) => i.value === SERVICE_IN_BUSINESS_LOCATION
        ),
        isServiceInCustomerLocation: productActivityAnswer.some(
          (i) => i.value === SERVICE_IN_CUSTOMER_LOCATION
        ),
        isServiceOnline: productActivityAnswer.some(
          (i) => i.value === SERVICE_ONLINE
        ),
      };

      const businessActivities = {
        ...serviceBusinessActivities, //Set our retail business activities to the latest values
      };
      /**
       * Spread data from current call so we do not overwrite other data
       */
      const content = {
        businessActivities: businessActivities,
      };
      return patchBusinessDetails({ values: content });
    },
    [QUESTION_ID, patchBusinessDetails]
  );

  useMemo(() => {
    //Add the Refetch Function
    question!.fetchServerState = () => {
      return new Promise((resolve) => {
        refetchBusinessDetails().then((success: any) => {
          const businessActivities = success.data?.businessActivities || '';

          const selectedOptions: Array<CheckboxGroupCardOption> = [];

          if (businessActivities.isServiceInBusinessLocation) {
            selectedOptions.push({
              value: SERVICE_IN_BUSINESS_LOCATION,
              title: 'From Business Location',
              key: SERVICE_IN_BUSINESS_LOCATION,
              displayValue: '',
            });
          }

          if (businessActivities.isServiceInCustomerLocation) {
            selectedOptions.push({
              value: SERVICE_IN_CUSTOMER_LOCATION,
              title: 'In Customer Location',
              key: SERVICE_IN_CUSTOMER_LOCATION,
              displayValue: 'In Customer Location',
            });
          }

          if (businessActivities.isServiceOnline) {
            selectedOptions.push({
              value: SERVICE_ONLINE,
              title: 'Online',
              key: SERVICE_ONLINE,
              displayValue: 'Online',
            });
          }

          resolve(selectedOptions);
        });
      });
    };

    question!.submitServerState = updateServiceBusinessActivitiesBooleans;
  }, [
    question,
    refetchBusinessDetails,
    updateServiceBusinessActivitiesBooleans,
  ]);

  return {};
};
