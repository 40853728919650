import { format } from 'date-fns';
import { CustomInputRefsProps, FormFieldType } from '../ob-form-renderer.types';
import { getRefValues } from '../hooks/useCustomRefs';
import type {
  LocalDateOutput,
  LocalTimeOutput,
  TimestampOutput,
  LocalDateRangeOutput,
  LocalTimeRangeOutput,
  TimestampRangeOutput,
} from './ob-form-submit-output-builder.types';

export const buildSubmitOutput = (
  submittedValues: any,
  customInputsRefs: CustomInputRefsProps
) => {
  const submittedOutput = {
    ...submittedValues,
  };

  const formFields = Object.keys(submittedValues);
  formFields.forEach((fieldType) => {
    const { datePartValue, datePartEndValue, timePartValue, timePartEndValue } =
      getRefValues(customInputsRefs, fieldType);

    switch (fieldType.toUpperCase()) {
      case FormFieldType.LOCAL_DATE: {
        const datePart = format(datePartValue, 'yyyy-MM-dd');
        const fieldValue: LocalDateOutput = {
          format: FormFieldType.LOCAL_DATE,
          datePart,
          value: datePart,
        };

        submittedOutput[fieldType] = fieldValue;
        return;
      }
      case FormFieldType.LOCAL_TIME: {
        const timePart = format(timePartValue, 'HH:mm:ss');
        const fieldValue: LocalTimeOutput = {
          format: FormFieldType.LOCAL_TIME,
          timePart,
          value: timePart,
        };

        submittedOutput[fieldType] = fieldValue;
        return;
      }
      case FormFieldType.TIMESTAMP: {
        const datePart = format(datePartValue, 'yyyy-MM-dd');
        const timePart = format(timePartValue, 'HH:mm:ss');
        const fieldValue: TimestampOutput = {
          format: FormFieldType.TIMESTAMP,
          datePart,
          timePart,
          offsetPart: format(new Date(), 'XXX'),
          value: format(
            new Date(datePart + ' ' + timePart),
            "yyyy-MM-dd'T'HH:mm:ssXXX"
          ),
          localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        submittedOutput[fieldType] = fieldValue;
        return;
      }
      case FormFieldType.LOCAL_DATE_RANGE: {
        const datePart = format(datePartValue, 'yyyy-MM-dd');
        const datePartEnd = format(datePartEndValue, 'yyyy-MM-dd');
        const fieldValue: LocalDateRangeOutput = {
          format: FormFieldType.LOCAL_DATE_RANGE,
          start: {
            format: FormFieldType.LOCAL_DATE,
            datePart,
            value: datePart,
          },
          end: {
            format: FormFieldType.LOCAL_DATE,
            datePart: datePartEnd,
            value: datePartEnd,
          },
        };

        submittedOutput[fieldType] = fieldValue;
        return;
      }
      case FormFieldType.LOCAL_TIME_RANGE: {
        const timePart = format(timePartValue, 'HH:mm:ss');
        const timePartEnd = format(timePartEndValue, 'HH:mm:ss');
        const fieldValue: LocalTimeRangeOutput = {
          format: FormFieldType.LOCAL_TIME_RANGE,
          start: {
            format: FormFieldType.LOCAL_TIME,
            timePart,
            value: timePart,
          },
          end: {
            format: FormFieldType.LOCAL_TIME,
            timePart: timePartEnd,
            value: timePartEnd,
          },
        };

        submittedOutput[fieldType] = fieldValue;
        return;
      }
      case FormFieldType.TIMESTAMP_RANGE: {
        const datePart = format(datePartValue, 'yyyy-MM-dd');
        const datePartEnd = format(datePartEndValue, 'yyyy-MM-dd');
        const timePart = format(timePartValue, 'HH:mm:ss');
        const timePartEnd = format(timePartEndValue, 'HH:mm:ss');

        const fieldValue: TimestampRangeOutput = {
          format: FormFieldType.TIMESTAMP_RANGE,
          start: {
            format: FormFieldType.TIMESTAMP,
            datePart,
            timePart,
            offsetPart: format(new Date(), 'XXX'),
            value: format(
              new Date(datePart + ' ' + timePart),
              "yyyy-MM-dd'T'HH:mm:ssXXX"
            ),
            localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          end: {
            format: FormFieldType.TIMESTAMP,
            datePart: datePartEnd,
            timePart: timePartEnd,
            offsetPart: format(new Date(), 'XXX'),
            value: format(
              new Date(datePartEnd + ' ' + timePartEnd),
              "yyyy-MM-dd'T'HH:mm:ssXXX"
            ),
            localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        };

        submittedOutput[fieldType] = fieldValue;
        return;
      }
    }
  });

  return submittedOutput;
};
