'use client';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ObDateTimePicker, ObInput, ObInputProps } from '../../../index';
import { validateDateInput } from './utils/validateDateInput';

export interface ObInputDatePickerProps {
  inputId?: string;
  initialDate?: any;
  hasError?: boolean;
  isDisabled?: boolean;
  size?: ObInputProps['size'];
  onDateChangeCb?: (selection: any) => void;
  onDateInputChangeCb?: (selection: any) => void;
}

export const ObInputDatePicker = forwardRef<any, ObInputDatePickerProps>(
  (
    {
      inputId,
      initialDate = null,
      hasError = false,
      isDisabled = false,
      size = 'medium',
      onDateChangeCb,
      onDateInputChangeCb,
    },
    ref
  ) => {
    const datepickerRef = useRef(null);

    const [date, setDate] = useState(initialDate);
    const [error, setError] = useState(hasError);

    useEffect(() => {
      if (!date) setError(hasError);
    }, [hasError, date]);

    const handleDateValidationAndSelect = useCallback((value: string) => {
      const validatedDate = validateDateInput(value);
      const calendarRef: any = datepickerRef?.current;

      if (calendarRef?.isCalendarOpen()) {
        return;
      }

      if (validatedDate) {
        calendarRef?.setSelected(validatedDate);
        setError(false);
      } else {
        setError(true);
      }

      calendarRef?.setOpen(false);
    }, []);

    const handleOnInputChange = useCallback(
      (value: string) => {
        if (onDateInputChangeCb) {
          onDateInputChangeCb(value);
        }
      },
      [onDateInputChangeCb]
    );

    const handleOnBlur = useCallback(
      (value: string) => {
        setTimeout(() => {
          handleDateValidationAndSelect(value);
        }, 200);
      },
      [handleDateValidationAndSelect]
    );

    const handleOnKeyDown = useCallback(
      (args: any) => {
        const { key, value, preventDefault } = args;
        if (key === 'Enter') {
          handleDateValidationAndSelect(value);
          preventDefault();
        } else if (key === 'Escape') {
          const calendarRef: any = datepickerRef?.current;
          calendarRef?.setOpen(false);
          preventDefault();
        }
      },
      [handleDateValidationAndSelect]
    );

    const handleDateChange = useCallback(
      (date: any) => {
        setDate(date);
        setError(false);

        if (onDateChangeCb) {
          onDateChangeCb(date);
        }
      },
      [onDateChangeCb]
    );

    useImperativeHandle(
      ref,
      () => ({
        id: inputId,
        getValue: () => date,
        setValue: (value: any) => handleDateValidationAndSelect(value),
        setError,
      }),
      [date, handleDateValidationAndSelect, inputId]
    );

    return (
      <ObDateTimePicker
        datepickerRef={datepickerRef}
        initialDate={date}
        dateFormat='MMMM d, yyyy'
        onChangeCb={handleDateChange}
        placeholderText='Type or Select a Date'
        onCalendarOpenCb={() => {
          const calendarRef: any = datepickerRef?.current;
          calendarRef.calendar.componentNode
            .querySelector('.react-datepicker__day[tabindex="0"]')
            .focus();
        }}
        onCalendarCloseCb={() => {
          // Fire a validation
          const calendarRef: any = datepickerRef?.current;
          calendarRef.input.focus();
          calendarRef.input.blur();
        }}
      >
        <ObInput
          inputId={inputId}
          data-testid='ob-date-picker-input'
          icon-right-data-testid='ob-date-picker-input-calendar-icon'
          onIconRightClickLabel='Open Calendar Picker'
          iconRight='calendar'
          size={size}
          error={error}
          isDisabled={isDisabled}
          onBlurCallback={handleOnBlur}
          onChangeCallback={handleOnInputChange}
          onKeyDownCallback={handleOnKeyDown}
        />
      </ObDateTimePicker>
    );
  }
);
