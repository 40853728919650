import {
  ObButton,
  ObLoadingSpinner,
  ObNoContentCta,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import { LocationModelCard } from '../../../../../../object-card/location/Location';
import { useRootStore } from '../../../../../../state/mobx-experiment/use-root-store';
import { PlaybookCardAddNewButton } from '../../_common/playbook-card-add-new-button';
import { PlaybookCardButtonWrapper } from '../../_common/playbook-card-button-wrapper';
import { PlaybookCardGrid } from '../../_common/playbook-card-grid';
import { PlaybookPageSectionHeading } from '../../_common/playbook-page-section-heading';
import { useBrickAndMortarCreateDrawer } from '../drawer/brick-and-mortar-create-drawer';
import { useBrickAndMortarEditDrawer } from '../drawer/brick-and-mortar-edit-drawer';
import { LOCATIONS_PAGE_DEF } from '../locations.def';

export interface BrickAndMortarProps {
  hideHeader?: boolean;
}

export const BrickAndMortarSection = observer(
  ({ hideHeader = false }: BrickAndMortarProps) => {
    const { locationStore } = useRootStore();
    const physicalLocations = locationStore.getPhysicalLocations();

    const { openNewBrickAndMortarLocationDrawer } =
      useBrickAndMortarCreateDrawer();

    const { openEditBrickAndMortarLocationDrawer } =
      useBrickAndMortarEditDrawer();

    const atLeastOneBrickAndMortarLocationExists =
      (physicalLocations.length ?? 0) > 0;

    const noBrickAndMortarLocationsExist = physicalLocations.length === 0;

    return (
      <section className='w-full'>
        {!hideHeader && (
          <PlaybookPageSectionHeading
            title={'Brick and Mortar Locations'}
            controls={
              <ObButton
                size='medium'
                variant='outline'
                iconLeft='plus'
                buttonType='icon'
                label='New Brick and Mortar Location'
                onClick={openNewBrickAndMortarLocationDrawer}
              />
            }
          />
        )}
        {atLeastOneBrickAndMortarLocationExists && (
          <PlaybookCardGrid>
            {physicalLocations.map((physicalLocation) => (
              <PlaybookCardButtonWrapper
                key={physicalLocation.id}
                onClickCallback={() =>
                  openEditBrickAndMortarLocationDrawer(physicalLocation.id)
                }
                label={`View ${physicalLocation.name} details`}
              >
                <Suspense
                  fallback={
                    <div className='flex justify-center items-center flex-1'>
                      <ObLoadingSpinner includeBrand={true} />
                    </div>
                  }
                >
                  <LocationModelCard
                    variant='grid'
                    location={physicalLocation}
                  />
                </Suspense>
              </PlaybookCardButtonWrapper>
            ))}
            <PlaybookCardButtonWrapper
              className='h-[200px]'
              onClickCallback={openNewBrickAndMortarLocationDrawer}
              label={'Create New Physical Location'}
            >
              <PlaybookCardAddNewButton label='Create New Physical Location' />
            </PlaybookCardButtonWrapper>
          </PlaybookCardGrid>
        )}

        {noBrickAndMortarLocationsExist && (
          <ObNoContentCta
            includeGradient={true}
            title={'Setup Your First Brick and Mortar Location'}
            description={
              'Add a physical location that you would like to advertise for!'
            }
            buttonText={'Get Started'}
            iconName={LOCATIONS_PAGE_DEF.icon}
            onButtonClick={openNewBrickAndMortarLocationDrawer}
          />
        )}
      </section>
    );
  }
);
