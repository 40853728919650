import { ReactNode } from 'react';

export interface ObCalloutProps {
  children: ReactNode;
}
export const ObCallout = ({ children }: ObCalloutProps) => {
  return (
    <section className='p-2 bg-bgSurface1Dark rounded-lg flex justify-center items-center shadow-2xl'>
      {children}
    </section>
  );
};
