'use client';
import { useClose } from '@headlessui/react';
import { cx } from 'class-variance-authority';
import { useCallback, useState } from 'react';
import { ObAvatar, ObIcon, ObTypography } from '../../../';
import { IconSystemKeys } from '../../../tokens/icons/icons';

export interface ObActionMenuItemProps {
  title: string;
  icon?: IconSystemKeys;
  rightIcon?: IconSystemKeys;
  keyCommand?: string;
  hide?: boolean;
  /**
   * Classes to be added to the menu item
   */
  className?: string;
  onClickCallback: () => void | (() => Promise<any>) | Promise<void>;
  /**
   * Indicates the action should show a loading indicator
   */
  isLoading?: boolean;
  /**
   * Indicates that the action should be disabled
   */
  isDisabled?: boolean;
  ariaLabel: string;
  /**
   * Fallback Text
   */
  fallbackText?: string;
  profileImageUrl?: string;
  handleMenuClose?: () => void;
  hideAvatarFallback?: boolean;
  closeOnAction?: boolean;
}

export const ObActionMenuItem = ({
  title,
  icon,
  rightIcon,
  className,
  keyCommand,
  ariaLabel,
  onClickCallback,
  isLoading = false,
  isDisabled = false,
  fallbackText,
  profileImageUrl,
  handleMenuClose,
  hideAvatarFallback,
  closeOnAction = false, //Pre-existing behavior
}: ObActionMenuItemProps) => {
  let close = useClose();
  const [isLoadingState, setIsLoadingState] = useState(isLoading);
  const callback = useCallback(() => {
    const promise = onClickCallback();

    if (closeOnAction) {
      close();
    }

    if (promise != null && promise instanceof Promise) {
      setIsLoadingState(true);
      promise.finally(() => {
        setIsLoadingState(false);
        handleMenuClose?.();
      });
    } else {
      handleMenuClose?.();
    }
  }, [close, closeOnAction, handleMenuClose, onClickCallback]);

  const isAvatar = !icon;

  return (
    <div
      data-testid='ob-action-menu-item'
      aria-label={ariaLabel}
      role='menuitem'
      onClick={() => {
        if (!isDisabled) {
          callback();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (!isDisabled) {
            callback();
          }
        }
      }}
      aria-disabled={isDisabled || isLoadingState}
      className={cx(
        'min-h-[24px] w-full m-0 px-1 py-0 outline-0 leading-9 flex items-center  hover:bg-whiteAlpha-2 cursor-pointer focus:outline-none focus-visible:shadow-interactive rounded ',
        (isDisabled || isLoadingState) &&
          'pointer-events-none cursor-auto opacity-[0.38]',
        className
      )}
    >
      {(hideAvatarFallback == null ||
        !hideAvatarFallback ||
        isLoadingState) && (
        <div className='w-9 min-w-[36px]'>
          {isAvatar ? (
            <ObAvatar
              shape='rounded'
              size='x-small'
              profileImageUrl={profileImageUrl}
              fallbackText={fallbackText}
            />
          ) : (
            <ObIcon
              icon={icon}
              size='small'
              color='secondary'
              loading={isLoadingState}
            />
          )}
        </div>
      )}

      <div className='flex w-full justify-between items-center'>
        <div className='mt-0 mb-0 min-w-0 h-[28px] flex justify-center pt-[2px]'>
          <ObTypography
            className={
              // A 200px width limit here ensures the label text is truncated when the tenant name is too long
              ' transition-all ease-in-out duration-300 delay-500 translate-x-0 max-w-[200px] '
            }
            variant='body2'
            as='span'
            color='primary'
            truncate
          >
            {title}
          </ObTypography>
        </div>

        {keyCommand && (
          <ObTypography
            className={
              'transition-all ease-in-out duration-300 delay-500 translate-x-0 pl-1.5'
            }
            variant='body2'
          >
            {keyCommand}
          </ObTypography>
        )}

        {rightIcon && (
          <ObIcon
            icon={rightIcon}
            size='small'
            color='secondary'
            loading={isLoadingState}
          />
        )}
      </div>
    </div>
  );
};
