import {
  ObBrand,
  ObDivider,
  ObIcon,
  ObThinkingButton,
  ObTypography,
} from '@outbound/design-system';
import { cx } from 'class-variance-authority';
import { ReactNode, useState } from 'react';

interface OnboardingHeaderProps {
  onClick?: () => Promise<void>;
  renderNextButton?: () => ReactNode;
  currentStep: 'scan' | 'review' | 'launch';
}

const steps = [
  {
    id: 'scan',
    title: `Scan Your Website`,
    shortTitle: `Scan`,
  },
  {
    id: 'review',
    title: `Review Your Marketing Plan`,
    shortTitle: `Review`,
  },
  {
    id: 'launch',
    title: `Launch Your Campaign`,
    shortTitle: `Launch`,
  },
];

export const OnboardingHeader = ({
  onClick,
  currentStep,
  renderNextButton = () => (
    <ObThinkingButton
      label={'Next'}
      size='large'
      iconRight={'rocket01'}
      onClickCallback={onClick}
    />
  ),
}: OnboardingHeaderProps) => {
  const [currentStepIndex] = useState<number>(
    steps.findIndex((step) => step.id === currentStep)
  );

  return (
    <header className='w-full'>
      <div className='flex flex-row p-6 h-full justify-between content-start'>
        <div className='flex-1'>
          <ObBrand
            size='medium'
            variant={'icon'}
            color={'monochromatic'}
          ></ObBrand>
        </div>
        <section className='flex gap-5'>
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={cx(
                ' flex-row items-center justify-center sm:flex',
                index === currentStepIndex ? 'flex' : 'hidden'
              )}
            >
              <ObIcon
                icon={currentStepIndex > index ? 'checkCircle' : 'circle'}
                color={currentStepIndex > index ? 'positive' : 'primary'}
                size='small'
              />
              <div className='pl-3'>
                <ObTypography className='hidden md:block'>
                  {step.title}
                </ObTypography>
                <ObTypography className='md:hidden'>
                  {step.shortTitle}
                </ObTypography>
              </div>
            </div>
          ))}
        </section>
        <div className='flex-1 flex justify-end'>{renderNextButton()}</div>
      </div>
      <ObDivider variant='shiny' />
    </header>
  );
};
