/** solid/shapes/star-06 */
export const Star06 = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M3.66602 1.33341C3.66602 0.965225 3.36754 0.666748 2.99935 0.666748C2.63116 0.666748 2.33268 0.965225 2.33268 1.33341V2.33341H1.33268C0.964492 2.33341 0.666016 2.63189 0.666016 3.00008C0.666016 3.36827 0.964492 3.66675 1.33268 3.66675H2.33268V4.66675C2.33268 5.03494 2.63116 5.33342 2.99935 5.33342C3.36754 5.33342 3.66602 5.03494 3.66602 4.66675V3.66675H4.66602C5.03421 3.66675 5.33268 3.36827 5.33268 3.00008C5.33268 2.63189 5.03421 2.33341 4.66602 2.33341H3.66602V1.33341Z'
        fill='currentColor'
      />
      <path
        d='M3.66602 11.3334C3.66602 10.9652 3.36754 10.6667 2.99935 10.6667C2.63116 10.6667 2.33268 10.9652 2.33268 11.3334V12.3334H1.33268C0.964492 12.3334 0.666016 12.6319 0.666016 13.0001C0.666016 13.3683 0.964492 13.6667 1.33268 13.6667H2.33268V14.6667C2.33268 15.0349 2.63116 15.3334 2.99935 15.3334C3.36754 15.3334 3.66602 15.0349 3.66602 14.6667V13.6667H4.66602C5.03421 13.6667 5.33268 13.3683 5.33268 13.0001C5.33268 12.6319 5.03421 12.3334 4.66602 12.3334H3.66602V11.3334Z'
        fill='currentColor'
      />
      <path
        d='M9.28825 1.76076C9.18922 1.5033 8.94186 1.33341 8.66602 1.33341C8.39017 1.33341 8.14281 1.5033 8.04378 1.76076L6.88767 4.76667C6.6874 5.28737 6.62447 5.43741 6.53838 5.55848C6.45201 5.67995 6.34588 5.78608 6.22441 5.87245C6.10335 5.95853 5.9533 6.02146 5.4326 6.22173L2.4267 7.37785C2.16923 7.47687 1.99935 7.72423 1.99935 8.00008C1.99935 8.27593 2.16923 8.52329 2.4267 8.62231L5.43261 9.77843C5.9533 9.9787 6.10335 10.0416 6.22441 10.1277C6.34588 10.2141 6.45201 10.3202 6.53838 10.4417C6.62447 10.5627 6.6874 10.7128 6.88767 11.2335L8.04379 14.2394C8.14281 14.4969 8.39017 14.6667 8.66602 14.6667C8.94186 14.6667 9.18922 14.4969 9.28825 14.2394L10.4444 11.2335C10.6446 10.7128 10.7076 10.5627 10.7936 10.4417C10.88 10.3202 10.9862 10.2141 11.1076 10.1277C11.2287 10.0416 11.3787 9.9787 11.8994 9.77843L14.9053 8.62231C15.1628 8.52329 15.3327 8.27593 15.3327 8.00008C15.3327 7.72423 15.1628 7.47687 14.9053 7.37785L11.8994 6.22173C11.3787 6.02146 11.2287 5.95853 11.1076 5.87245C10.9861 5.78608 10.88 5.67995 10.7936 5.55848C10.7076 5.43741 10.6446 5.28737 10.4444 4.76667L9.28825 1.76076Z'
        fill='currentColor'
      />
    </svg>
  );
};
