import { cva, cx } from 'class-variance-authority';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { IconSize, ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

export interface ObGradientIconProps {
  icon: IconSystemKeys;
  iconRadialColorClassName?: string;
  size?: 'small' | 'medium' | 'large';
  iconSize?: IconSize;
}

const gradient = cva('gradient', {
  variants: {
    size: {
      small: ['p-0.5'],
      medium: ['p-2'],
      large: ['p-4'],
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export const ObGradientIcon = ({
  icon,
  iconRadialColorClassName = 'from-indigo-600',
  size = 'medium',
  iconSize = 'medium',
}: ObGradientIconProps) => {
  return (
    <div
      className={cx(
        gradient({ size }),
        'bg-gradient-radial to-[rgba(105, 65, 198, 0.00)] rounded-lg border border-actionSecondaryBorderDark flex justify-center items-center',
        iconRadialColorClassName
      )}
    >
      <ObIcon
        icon={icon}
        size={iconSize}
      />
    </div>
  );
};
