import {
  ObFormRenderer,
  ObLoadingSpinner,
  useObFormRendererContext,
} from '@outbound/design-system';
import { useCallback, useEffect, useState } from 'react';
import {
  useFetchBrandGuide,
  usePatchBrandGuide,
} from '../../../../../query/playbook/use-playbook-settings-brand-guide-endpoints';
import { PlaybookEditFooter } from '../_common/playbook-edit-footer';
import { PlaybookPageContent } from '../_common/playbook-page-content';
import { PlaybookPageHeading } from '../_common/playbook-page-heading';
import { BRAND_GUIDE_PAGE_DEF } from './brand-guide.def';
import {
  mapApiResourceToFormValues,
  mapFormValuesToApiResource,
} from './forms/brand-guide-form-utils';
import { useBrandGuideForm } from './forms/brand-guide-form.def';

export const BrandGuide = () => {
  const { formDefinition: BRAND_GUIDE_FORM_DEF } = useBrandGuideForm();

  const { data: brandGuide, isLoading: isBrandGuideLoading } =
    useFetchBrandGuide();

  const { mutateAsync: patchBrandGuide } = usePatchBrandGuide();

  const [initialValues, setInitialValues] = useState<any>(
    isBrandGuideLoading ? {} : mapApiResourceToFormValues(brandGuide!)
  );

  const [isFormDataReady, setIsFormDataReady] = useState<boolean>(
    !isBrandGuideLoading
  );
  useEffect(() => {
    if (!isBrandGuideLoading && !isFormDataReady) {
      setInitialValues(mapApiResourceToFormValues(brandGuide!));
      setIsFormDataReady(true);
    }
  }, [isFormDataReady, isBrandGuideLoading, brandGuide]);

  const submitForm = useCallback(
    async (data: any) => {
      return patchBrandGuide({ values: mapFormValuesToApiResource(data) });
    },
    [patchBrandGuide]
  );

  const [areChangesUnsaved, setAreChangesUnsaved] = useState<boolean>(false);

  const { getRegisteredFormById } = useObFormRendererContext();

  const onFormDirtyChange = useCallback((isDirty: boolean) => {
    setAreChangesUnsaved(isDirty);
  }, []);

  return (
    <>
      <article className='flex flex-col gap-8 flex-grow '>
        <PlaybookPageHeading title={BRAND_GUIDE_PAGE_DEF.title} />
        {!isFormDataReady && (
          <div className='w-full h-full flex justify-center items-center mt-[50%]'>
            <ObLoadingSpinner includeBrand={true} />
          </div>
        )}
        {isFormDataReady && (
          <PlaybookPageContent>
            <ObFormRenderer
              formDefinition={BRAND_GUIDE_FORM_DEF}
              onSubmitCallback={submitForm}
              defaultValues={initialValues}
              hideSubmitButton={true}
              onFormDirtyChangeCallback={onFormDirtyChange}
            />
          </PlaybookPageContent>
        )}
      </article>
      <PlaybookEditFooter
        areChangesUnsaved={areChangesUnsaved}
        onSaveClickedCallback={async () => {
          return getRegisteredFormById(
            BRAND_GUIDE_FORM_DEF.id
          )?.submitFunction();
        }}
        onDiscardClickedCallback={() => {
          getRegisteredFormById(
            BRAND_GUIDE_FORM_DEF.id
          )?.discardChangesFunction();
        }}
      />
    </>
  );
};
