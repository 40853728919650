import { CampaignDeploymentStatusType, ServerDelta } from '@outbound/types';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../../base-model';

import { parseObrn } from '@otbnd/utils';
import { RootStore } from '../../root-store';

interface CampaignDeploymentValue {
  tags: string[];
  stage: CampaignDeploymentStatusType;
}

class CampaignDeployment extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    stage: '/stage',
    tags: '/tags',
  };

  private _tags: string[];
  private _stage: CampaignDeploymentStatusType;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    value: CampaignDeploymentValue
  ) {
    const scope = parseObrn(obrn).scope;
    super(rootStore, 'campaign/deployment', '1', id, scope, obrn);
    this._id = id;
    this._tags = value.tags;
    this._stage = value.stage;
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _stage: observable,
      _tags: observable,
    } as any);
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      for (const delta of patch) {
        if (delta.path === CampaignDeployment.paths.stage) {
          /**
           * Replace is the only supported operation. Stage is required.
           */
          if (delta.op === 'replace') {
            this._stage = delta.value as CampaignDeploymentStatusType;
          }
        } else if (delta.path === CampaignDeployment.paths.tags) {
          /**
           * Replace is the only supported operation for tags. Tags is required.
           * We don't support adding or removing tags. We will replace the entire array.
           **/
          if (delta.op === 'replace') {
            this._tags = delta.value as string[];
          }
        }
      }
    });
  }

  toJson(): Record<string, any> {
    const base = super.toJson();
    return {
      ...base,
      [CampaignDeployment.paths.stage]: this._stage,
      [CampaignDeployment.paths.tags]: this._tags,
    };
  }

  get stage(): CampaignDeploymentStatusType {
    return this._stage;
  }

  get tags(): string[] {
    return this._tags;
  }

  get isLatest(): boolean {
    /**
     * Campaign Deployments have a docker like tag system.
     * The latest tag is a special tag that is always the latest deployment and helps
     * us to query for the latest deployment without having to query all deployments.
     */
    return this._tags.includes('latest');
  }
}

export default CampaignDeployment;
