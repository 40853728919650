import { Description, Label, Radio } from '@headlessui/react';
import { cva, cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObSkeleton, ObTypography } from '../../../..';
import { AvatarComponent } from '../../../../avatars/avatar.types';
import './ob-radio-group.css';

export interface ObRadioGroupItemProps {
  /**
   * The value returned when the radio button is selected
   */
  value: string;

  /**
   * How the radio button is displayed to the user
   */
  label: string;
  /**
   * A secondary description to provide more context to the user
   * (Not shown on small size)
   */
  description: string;
  /**
   * Indicates if the radio button is selectable by the user
   */
  isDisabled: boolean;

  /**
   * The size of the radio item
   * The small size does not show the description
   */
  size: 'small' | 'medium';

  /**
   * Replaces the radio item with a loading skeleton
   */
  isLoading: boolean;

  /**
   * Content that shows between the radio button and the label.
   * This should only be an avatar or an icon with the xx-small or 20px x 20px size
   */
  avatarContent?: AvatarComponent;
}

const indicatorStyles = cva(
  'relative block rounded-full bg-dark/background/surface dark:border-dark/action/outline/normal cursor-pointer after:empty-content after:absolute after:top-1/2 after:left-1/2 after:w-3 after:h-3 after:rounded-full after:-translate-x-1/2 after:-translate-y-1/2 after:scale-0 after:transition-transform after:duration-300',
  {
    variants: {
      size: {
        medium: 'w-6 h-6 border-2',
        small: 'w-5 h-5 border-[1px]',
      },
      checked: {
        true: 'dark:border-dark/action/primary/normal after:scale-100  after:bg-dark/action/primary/normal',
        false: '',
      },
      disabled: {
        false: '',
      },
    },
    compoundVariants: [
      {
        checked: true,
        disabled: false,
        class: 'opacity-100',
      },
      {
        checked: true,
        disabled: true,
        class: 'opacity-75 cursor-not-allowed',
      },
    ],
  }
);

const itemContainerStyles = cva('', {
  variants: {
    size: {
      small: 'pt-[2px] pb-[5px] px-2 gap-3 rounded-lg',
      medium: 'pt-2 pb-[10px] px-4 gap-4 rounded-xl',
    },
  },
});

export const ObRadioGroupItem: FC<ObRadioGroupItemProps> = ({
  value,
  label,
  isDisabled = false,
  size = 'medium',
  description,
  isLoading = false,
  avatarContent,
}) => {
  if (isLoading) {
    return (
      // Show an indeterminate loading state
      <div className='w-full'>
        <div className='flex gap-4 py-2 px-4 w-full hover:bg-actionNeutralHoverDark rounded-md'>
          <div
            className={cx(size === 'medium' ? 'mt-[9px] ml-[2px]' : 'mt-[5px]')}
          >
            <ObSkeleton
              variant='circle'
              height={size === 'medium' ? '24px' : '21px'}
              width={size === 'medium' ? '24px' : '21px'}
            />
          </div>
          <div className='flex flex-col w-full '>
            {size === 'medium' && (
              <>
                <ObSkeleton
                  variant='text'
                  width='75%'
                >
                  <ObTypography variant='body1' />
                </ObSkeleton>
                <ObSkeleton
                  variant='text'
                  width='100%'
                >
                  <ObTypography variant='body3' />
                </ObSkeleton>
              </>
            )}
            {size === 'small' && (
              <ObSkeleton
                variant='text'
                width='100%'
              >
                <ObTypography variant='body2' />
              </ObSkeleton>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Radio
      key={value}
      value={value}
      disabled={isDisabled}
      className={cx(
        itemContainerStyles({ size }),
        'focus:outline-none focus:shadow-interactive', //Focus Styles
        'border border-transparent data-[checked]:dark:border-dark/border/default/normal', //Border Styles
        'data-[checked]:dark:bg-dark/action/primary/on-subtle  dark:hover:bg-dark/action/neutral/hover dark:active:bg-dark/action/neutral/active data-[checked]:hover:bg-actionPrimaryDark', //Background Styles
        'flex ',
        'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-75 data-[disabled]:pointer-events-none',
        'cursor-pointer'
      )}
    >
      {({ checked }) => (
        <>
          <div
            className={cx(
              'flex',
              size === 'small'
                ? 'justify-center items-center mt-[3px]'
                : 'justify-center items-start mt-[4px]'
            )}
          >
            <div
              className={indicatorStyles({
                checked,
                disabled: isDisabled,
                size,
              })}
            />
          </div>
          <div
            className={cx(
              'flex ',
              size === 'medium'
                ? 'flex-col justify-center'
                : 'flex-row gap-2 flex-nowrap'
            )}
          >
            <div className='flex flex-row items-center justify-start gap-2'>
              {avatarContent && (
                <div className='mt-[2px]'>{avatarContent} </div>
              )}
              <Label>
                <ObTypography
                  as='span'
                  variant={size === 'medium' ? 'body1' : 'body2'}
                  color='primary'
                  className='leading-none line-clamp-2'
                >
                  {label ?? value}
                </ObTypography>
              </Label>
            </div>
            {description && (
              <Description>
                <ObTypography
                  variant={size === 'medium' ? 'body3' : 'body2'}
                  as='span'
                  color='secondary'
                  className='leading-none'
                >
                  {description}
                </ObTypography>
              </Description>
            )}
          </div>
        </>
      )}
    </Radio>
  );
};
