import { LandingPageLifecycleStatus } from '@outbound/types';
import { cva, cx } from 'class-variance-authority';
import { ObAvatar, ObIconAvatar } from '../../../../avatars';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { ObIcon } from '../../../../tokens/icons/ob-icon/ob-icon';
import { AgencyBrandingProps } from '../../../../white-label.type';

const lineLeftStyles = cva(
  ' h-[2px] flex flex-1 rounded-md transition-colors duration-300',
  {
    variants: {
      stage: {
        INITIALIZING: ['bg-dark/contextual/positive animate-pulse'],
        ACTIVE: ['bg-dark/contextual/positive'],
        INITIALIZATION_FAILED: ['bg-dark/contextual/negative'],
        ARCHIVED: ['bg-dark/contextual/positive'],
      },
    },
  }
);

const lineRightStyles = cva(
  ' h-[2px] flex flex-1 rounded-md transition-colors duration-300',
  {
    variants: {
      stage: {
        INITIALIZING: ['bg-dark/content/tertiary animate-pulse'],
        ACTIVE: ['bg-dark/contextual/positive'],
        INITIALIZATION_FAILED: ['bg-dark/contextual/negative'],
        ARCHIVED: ['bg-dark/contextual/warning animate-pulse'],
      },
    },
  }
);

const configuringIconPosition = cva(
  'absolute left-[14px] transition-all duration-300  ease-in-out',
  {
    variants: {
      stage: {
        INITIALIZING: ['top-[14px] opacity-100'],
        ACTIVE: ['top-[50px]  opacity-0'],
        INITIALIZATION_FAILED: ['top-[50px]  opacity-0'],
        ARCHIVED: ['top-[50px]  opacity-0'],
      },
    },
  }
);

const successIconPosition = cva(
  ' absolute left-[14px] transition-all duration-300 ease-in-out',
  {
    variants: {
      stage: {
        //Indicate where the success icon should be placed during each stage
        INITIALIZING: ['top-[-8px] opacity-0'],
        ACTIVE: ['top-[14px]  opacity-100'],
        INITIALIZATION_FAILED: ['top-[50px]  opacity-0'],
        ARCHIVED: ['top-[-8px]  opacity-0'],
      },
    },
  }
);

const errorIconPosition = cva(
  ' absolute left-[14px] transition-all duration-300 ease-in-out',
  {
    variants: {
      stage: {
        INITIALIZING: ['top-[50px] opacity-0'],
        ACTIVE: ['top-[50px] opacity-0'],
        INITIALIZATION_FAILED: ['top-[14px]  opacity-100'],
        ARCHIVED: ['top-[50px]  opacity-0'],
      },
    },
  }
);

export interface InitializingSelfHostedProps extends AgencyBrandingProps {
  lifecycleStatus: LandingPageLifecycleStatus;
  url: string;
}

export const InitializingSelfHosted = ({
  url,
  agencyIconUrl,
  agencyName,
  lifecycleStatus,
}: InitializingSelfHostedProps) => {
  let domain: string;
  try {
    domain = new URL(url).hostname;
  } catch (e) {
    domain = '';
  }

  return (
    <div className='flex flex-col gap-10 justify-center items-center  max-w-xl m-auto'>
      <div className='flex flex-col justify-center items-center flex-1'>
        <ObTypography
          variant='h2'
          className='text-center'
        >
          Verifying Your Landing Page
        </ObTypography>
        <ObTypography
          variant='h4'
          color='secondary'
          className='text-center'
        >
          This will just take a moment!
        </ObTypography>
      </div>

      <div className='flex flex-row gap-4 items-center justify-between self-stretch'>
        <div className='flex flex-col items-center justify-center min-w-[124px] gap-1'>
          <ObAvatar
            size={'large'}
            shape='rounded'
            profileImageUrl={agencyIconUrl}
          />
          <ObTypography
            color='secondary'
            variant='body2'
            className='text-center line-clamp-2 min-h-[44px]'
          >
            {agencyName}
          </ObTypography>
        </div>
        <div className='flex flex-row gap-1 flex-1 justify-between items-center pb-[44px]'>
          <div
            className={lineLeftStyles({
              stage: lifecycleStatus,
            })}
          />
          <div className='relative w-[48px] h-[48px]'>
            <div
              className={cx(
                successIconPosition({
                  stage: lifecycleStatus,
                })
              )}
            >
              <ObIcon
                icon={'check'}
                size='medium'
              />
            </div>
            <div
              className={cx(
                errorIconPosition({
                  stage: lifecycleStatus,
                })
              )}
            >
              <ObIcon
                icon={'errorMessage'}
                size='medium'
                color='negative'
              />
            </div>
            <div
              className={cx(
                configuringIconPosition({
                  stage: lifecycleStatus,
                })
              )}
            >
              <ObIcon
                size='small'
                icon='RefreshCw05'
                color='content'
                classNames='animate-spin'
              />
            </div>
          </div>

          <div
            className={lineRightStyles({
              stage: lifecycleStatus,
            })}
          />
        </div>
        <div
          className={cx(
            'flex flex-col items-center justify-center min-w-[124px] gap-1'
          )}
        >
          <ObIconAvatar
            size={'large'}
            shape='rounded'
            color={'ghost'}
            icon='googleGlobe'
          />
          <div className='relative h-10'>
            <div className='absolute left-1/2 transform -translate-x-1/2'>
              <ObTypography
                color={
                  lifecycleStatus != 'INITIALIZATION_FAILED'
                    ? 'secondary'
                    : 'negative'
                }
                variant='body2'
                className='text-center line-clamp-2 min-h-[44px]'
              >
                {domain ?? url}
              </ObTypography>
            </div>
          </div>
        </div>
      </div>
      <ObTypography
        variant='body2'
        color={
          lifecycleStatus === 'INITIALIZATION_FAILED' ? 'negative' : 'tertiary'
        }
        className='text-center pb-4'
      >
        {lifecycleStatus === 'INITIALIZATION_FAILED' &&
          'We were unable to reach your landing page. Please check the URL and try again.'}
        {lifecycleStatus === 'INITIALIZING' &&
          `We are reaching out to ${url} to verify we can connect to it. This should just take a moment.`}
      </ObTypography>
    </div>
  );
};
