import {
  ObButton,
  ObLoadingSpinner,
  ObNoContentCta,
  ObServiceCard,
} from '@outbound/design-system';

import { useFetchPlaybookSettings } from '../../../../../../query/playbook/use-playbook-endpoints';
import { PlaybookCardButtonWrapper } from '../../_common/playbook-card-button-wrapper';
import { PlaybookCardGrid } from '../../_common/playbook-card-grid';

import { PlaybookCardAddNewButton } from '../../_common/playbook-card-add-new-button';
import { PlaybookPageSectionHeading } from '../../_common/playbook-page-section-heading';

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useServiceDetailDrawer } from '../drawer/use-service-details-drawer';
import { PRODUCTS_AND_SERVICES_PAGE_DEF } from '../products-and-services.def';

export interface ServiceSectionProps {
  hideHeader?: boolean;
}

export const ServiceSection = ({ hideHeader = false }: ServiceSectionProps) => {
  //Get Query Params
  const [searchParams] = useSearchParams();

  // Get the value of a specific query param
  const serviceId = searchParams.get('service-id');
  const serviceOfferingId = searchParams.get('service-offering-id');

  const { data: playbookSettings, isLoading: isPlaybookSettingsLoading } =
    useFetchPlaybookSettings();

  const { openEditServiceDrawer, openCreateNewServiceDrawer } =
    useServiceDetailDrawer();

  /**
   * Handles opening the edit service drawer if a serviceId is present in the query params
   * Product wants to eventually implement actual detail pages for the services so this edit shelf is a
   * transitionary solution until we have time to come back around
   */
  useEffect(() => {
    if (serviceId) {
      openEditServiceDrawer(serviceId, false);
    }
  }, [serviceId, openEditServiceDrawer, serviceOfferingId]);

  const atLeastOneServiceExists =
    !isPlaybookSettingsLoading && (playbookSettings?.services?.length ?? 0) > 0;

  const noServicesExist =
    !isPlaybookSettingsLoading && playbookSettings?.services?.length === 0;

  return (
    <section className='w-full'>
      {!hideHeader && (
        <PlaybookPageSectionHeading
          title={'Services'}
          controls={
            <ObButton
              size='medium'
              variant='outline'
              buttonType='text'
              label='New Service'
              onClick={openCreateNewServiceDrawer}
            />
          }
        />
      )}
      {isPlaybookSettingsLoading && (
        <div className='w-full h-full flex justify-center items-center mt-[50%]'>
          <ObLoadingSpinner includeBrand={true} />
        </div>
      )}
      {!isPlaybookSettingsLoading && atLeastOneServiceExists && (
        <PlaybookCardGrid>
          {playbookSettings?.services.map((service) => (
            <PlaybookCardButtonWrapper
              key={service.id}
              onClickCallback={() => openEditServiceDrawer(service.id)}
              label={`View ${service.name} details`}
            >
              <ObServiceCard
                name={service.name}
                description={service.description}
                subServices={service.subServices}
              />
            </PlaybookCardButtonWrapper>
          ))}
          <PlaybookCardButtonWrapper
            className='h-[200px]'
            onClickCallback={openCreateNewServiceDrawer}
            label={'Create New Service'}
          >
            <PlaybookCardAddNewButton label='Create New Service' />
          </PlaybookCardButtonWrapper>
        </PlaybookCardGrid>
      )}
      {!isPlaybookSettingsLoading && noServicesExist && (
        <ObNoContentCta
          includeGradient={true}
          title={'Create Your First Service'}
          description={'Start adding services you want to advertise!'}
          buttonText={'New Service'}
          iconName={PRODUCTS_AND_SERVICES_PAGE_DEF.icon}
          onButtonClick={openCreateNewServiceDrawer}
        />
      )}
    </section>
  );
};
