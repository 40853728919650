import { ObThumbnail } from '@outbound/design-system';

import ad1 from './sample-templates/Sample-Ad-001.png';
import ad2 from './sample-templates/Sample-Ad-002.png';
import ad3 from './sample-templates/Sample-Ad-003.png';
import ad4 from './sample-templates/Sample-Ad-004.png';
import ad5 from './sample-templates/Sample-Ad-005.png';
import ad6 from './sample-templates/Sample-Ad-006.png';
import ad7 from './sample-templates/Sample-Ad-007.png';

import ad9 from './sample-templates/Sample-Ad-009.png';
import ad10 from './sample-templates/Sample-Ad-010.png';
import ad11 from './sample-templates/Sample-Ad-011.png';
import ad12 from './sample-templates/Sample-Ad-012.png';
import ad13 from './sample-templates/Sample-Ad-013.png';
import ad14 from './sample-templates/Sample-Ad-014.png';
import ad15 from './sample-templates/Sample-Ad-015.png';
import ad16 from './sample-templates/Sample-Ad-016.png';
import ad17 from './sample-templates/Sample-Ad-017.png';
import ad18 from './sample-templates/Sample-Ad-018.png';

const sampleAds = [
  ad1,
  ad2,
  ad3,
  ad4,
  ad5,
  ad6,
  ad7,

  ad9,
  ad10,
  ad11,
  ad12,
  ad13,
  ad14,
  ad15,
  ad16,
  ad17,
  ad18,
];

export const TemplateGalleryDrawer = () => {
  return (
    <article className='flex flex-col gap-6 p-4'>
      <section className='flex flex-col gap-6 items-center'>
        {sampleAds.map((ad, index) => (
          <ObThumbnail
            key={index}
            publicUrl={ad}
            size='x-large'
            preferredBackground={'light'}
            shape='rectangle'
          />
        ))}
      </section>
    </article>
  );
};
