import { ObButton, ObDivider, ObTypography } from '@outbound/design-system';
import { FC, useCallback } from 'react';
import { useModal } from '../../../../../hooks/use-modal';
import { WorkspaceInvitesDataGrid } from './workspace-invites-data-grid';
import WorkspaceInvitesDialog from './workspace-invites-dialog';

const WorkspaceInvites: FC<{}> = () => {
  const [isInviteModalOpen, handleOpenInviteModal, closeModal] = useModal();

  const handleCloseInviteModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-4'>
        <ObTypography variant='h2'>Invites</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          Invite new team members to your workspace
        </ObTypography>
      </div>

      <div className='flex flex-col gap-8'>
        <ObDivider />
        <div className='flex flex-row justify-between'>
          <div className='flex flex-col justify-center'>
            <ObTypography variant='h4'>Pending Invites</ObTypography>
            <ObTypography
              variant='body2'
              color='secondary'
            >
              Manage your existing invites
            </ObTypography>
          </div>
          <ObButton
            variant='primary'
            size='medium'
            onClick={handleOpenInviteModal}
            label='Create Invite'
          />
        </div>
      </div>
      <WorkspaceInvitesDataGrid />
      <WorkspaceInvitesDialog
        show={isInviteModalOpen}
        handleHideModal={handleCloseInviteModal}
      />
    </div>
  );
};

export default WorkspaceInvites;
