import {
  CampaignChannelType,
  channelTypeSelectorOptions,
} from '@outbound/types';
import { FC, useEffect, useRef, useState } from 'react';
import { ObChannelAvatar } from '../../../../avatars';
import { StateManagedByParentInput } from '../../../../base-component-props.type';
import { stringHasContent } from '../../../../utilities/string-utils';
import { FieldDisplayModeProps } from '../../form-renderer-poc';
import { ChannelRadioGroup } from '../channel-radio-group/channel-radio-group';
import { ObSelectedOptionGroup } from '../ob-selected-option-group/ob-selected-option-group';
import { ObSelectedOption } from '../ob-selected-option/ob-selected-option';
import { SectionLabel } from '../section-label/section-label';

export interface ChannelSectionProps
  extends StateManagedByParentInput<string>,
    FieldDisplayModeProps {
  availableChannels: Array<CampaignChannelType>;
}

export const ChannelSection: FC<ChannelSectionProps> = ({
  value,
  onValueChangedCallback,
  displayMode = 'editable',
  onDisplayModeChangedCallback,
  availableChannels = [],
}: ChannelSectionProps) => {
  const firstTimeMode = useRef(!stringHasContent(value));
  const [channelLabel, setChannelLabel] = useState<string>('');

  useEffect(() => {
    setChannelLabel(
      channelTypeSelectorOptions.find((option) => option.value === value)
        ?.title || ''
    );
    if (stringHasContent(value) && firstTimeMode.current) {
      firstTimeMode.current = false;
      onDisplayModeChangedCallback('readonly');
    }
  }, [onDisplayModeChangedCallback, value]);
  return (
    <div className='flex flex-col gap-3 self-stretch'>
      <SectionLabel
        label='What kind of Ad do you want to create?'
        id={'channel'}
      />
      {displayMode === 'readonly' && (
        <ObSelectedOptionGroup
          onEditClickedCallback={() => onDisplayModeChangedCallback('editable')}
        >
          <ObSelectedOption
            id={'channel'}
            avatarContent={
              <ObChannelAvatar
                size='xx-small'
                channel={value as CampaignChannelType}
              />
            }
            value={channelLabel}
          />
        </ObSelectedOptionGroup>
      )}
      {displayMode === 'editable' && (
        <ChannelRadioGroup
          value={value}
          hideConfirmButton={firstTimeMode.current}
          availableChannels={availableChannels}
          onValueChangedCallback={onValueChangedCallback}
          onConfirmWhatClickedCallback={() =>
            onDisplayModeChangedCallback('readonly')
          }
          isLoading={false}
        />
      )}
    </div>
  );
};
