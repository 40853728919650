import { cx } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import {
  StateDependsOnAsyncSourceProps,
  StateManagedByParentInput,
} from '../../../base-component-props.type';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObInputCombobox } from '../../atoms/inputs/ob-input-combobox/ob-input-combobox';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObSkeleton } from '../../elements/ob-skeleton/ob-skelton';
import { ObTypography } from '../../elements/ob-typography/ob-typography';
import { BaseFormFieldOption } from '../../organisms/ob-form-renderer/ob-form-renderer.types';

export interface ObWhoWhatWhereCardProps
  extends StateManagedByParentInput<Array<string>>,
    StateDependsOnAsyncSourceProps {
  variant: 'who' | 'what' | 'where';
  options: Array<BaseFormFieldOption>;
  /**
   * Notify the parent that the create button was clicked
   */
  onCreateClickedCallback?: () => void;
}

const getVariantDetails = (variant: 'who' | 'what' | 'where') => {
  switch (variant) {
    case 'who':
      return {
        icon: 'userSquare' as IconSystemKeys,
        title: 'Who?',
        contextualText: 'Who are you targeting?',
        contentType: 'Persona',
      };
    case 'what':
      return {
        icon: 'tag01' as IconSystemKeys,
        title: 'What?',
        contextualText: 'What are you advertising?',
        contentType: 'Product or Service',
      };
    case 'where':
      return {
        icon: 'locations' as IconSystemKeys,
        title: 'Where?',
        contextualText: 'Where are you targeting?',
        contentType: 'Location or Service Area',
      };
  }
};

export const ObWhoWhatWhereCard = ({
  variant,
  value,
  onValueChangedCallback,
  options = [],
  onCreateClickedCallback,
  isLoading,
}: ObWhoWhatWhereCardProps) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  /**
   * Tracks if there are existing options to display. If not the user will need
   * to create one, null means we don't know yet because data is still loading.
   */
  const [hasExistingOptions, setHasExistingOptions] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setHasExistingOptions(options != null && options.length > 0);
  }, [options, isLoading]);

  const { icon, title, contextualText, contentType } =
    getVariantDetails(variant);

  const noContentButtonText = `Create ${contentType}`;

  const handleEditClick = () => {
    setIsInEditMode(!isInEditMode);
  };

  return (
    <div className='whowhatwhere-card flex flex-col gap-0 w-full h-full'>
      <div className='flex justify-between items-center p-4 rounded-t-xl border-b border-borderDefaultNormalDark bg-bgSurfaceDark'>
        <div className='flex gap-2 items-center'>
          <ObIcon
            icon={icon}
            size='small'
          />
          <ObTypography
            variant='h5'
            color='primary'
          >
            {title}
          </ObTypography>
        </div>
        <ObButton
          variant='outline'
          buttonType='text'
          size='medium'
          disabled={isLoading}
          label={isInEditMode ? 'Save' : 'Edit'}
          onClick={handleEditClick}
        />
      </div>

      <div
        className={cx(
          'flex flex-col p-4 gap-2 bg-bgSurfaceDark h-full',
          hasExistingOptions === true ? 'rounded-b-xl' : ''
        )}
      >
        <ObTypography
          variant='body3'
          color='secondary'
        >
          {contextualText}
        </ObTypography>
        {isLoading ? (
          <ObSkeleton variant='text'>
            <ObTypography variant='body2'></ObTypography>
          </ObSkeleton>
        ) : (
          <>
            {isInEditMode ? (
              <ObInputCombobox
                value={value}
                isLoading={isLoading}
                onValueChangedCallback={onValueChangedCallback}
                options={options.map((option) => ({
                  id: option.value,
                  label: option.value,
                  displayValue: option.displayValue,
                }))}
                inputId={variant}
              />
            ) : (
              <ObTypography
                variant='body2'
                color='primary'
              >
                {hasExistingOptions
                  ? `${value
                      .map(
                        (v) =>
                          options.find((option) => option.value == v)
                            ?.displayValue
                      )
                      .join(', ')}`
                  : `You haven't created any ${contentType}s yet.`}
              </ObTypography>
            )}
          </>
        )}
      </div>
      {hasExistingOptions === false ? (
        <div className='rounded-b-xl bg-bgSurfaceDark w-full'>
          <ObButton
            variant='ghost'
            buttonType='text'
            size='medium'
            label={noContentButtonText}
            onClick={onCreateClickedCallback}
            iconRight='plus'
            className='w-full border-t border-borderDefaultNormalDark py-7'
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
