import {
  CampaignResource,
  CampaignResourceWithCreative,
} from '@outbound/types';
import { action } from 'mobx';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Campaign from './campaign';
import CampaignTransformer from './transformer/campaign-transformer';

class CampaignStore extends BaseStore<Campaign> {
  readonly campaignTransformer: CampaignTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'campaign', rootStore.transport.campaignTransport);
    this.campaignTransformer = new CampaignTransformer(rootStore);
    /**
     * Register a callback handler to handle server side updates to campaigns
     */
    this.rootStore.transport.campaignTransport.registerServerUpdateCallbackHandler(
      this.handleServerSideCampaignUpdate.bind(this)
    );
  }

  protected requestLoadModelFromServer(id: string): void {
    this.rootStore.transport.campaignTransport.requestResourceById(id);
  }

  /**
   * Will Update the Mobx Campaign Model with Server Side Updates
   * Intention to be called by the Campaign Transport Layer when a campaign is updated on the server
   * This is publicly accessible but not by intention. There may be a better way to ensure this logic
   * is encapsulated though some registration method etc. This is an area for improvement when we have
   * more time to refactor.
   * @param id
   * @param resource
   */
  handleServerSideCampaignUpdate(
    id: string,
    resource: CampaignResourceWithCreative | CampaignResource | null
  ) {
    this.handleServerUpdateGeneric(id, resource, this.campaignTransformer);
  }

  /**
   * Optimistically delete the Campaign from the store and send the request to the server.
   * @param id
   */
  @action
  public async delete(id: string) {
    const model = this.modelMap.get(id);
    if (model) {
      this.optimisticDeleteModel(id);
      try {
        await this.rootStore.transport.campaignTransport.delete(id);
      } catch (error) {
        //TODO THIS IS NOT TRIGGERING A RE-RERENDER IN THE UI. FIX THIS
        console.error('Error Deleting Campaign', error);
        this.undoOptimisticDeleteModel(id);
        //Snackbar or Toast Error
      }
    }
  }
}

export default CampaignStore;
