import { ServiceResource } from '@outbound/types';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import { Service } from './service';
import ServiceTransformer from './service-transformer';

class ServiceStore extends BaseStore<Service> {
  private serviceTransformer: ServiceTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'service');
    this.serviceTransformer = new ServiceTransformer(rootStore);

    this.rootStore.transport.serviceTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
  }

  protected requestLoadModelFromServer(id: string): void {
    this.rootStore.transport.serviceTransport.requestResourceById(id);
  }

  /**
   * As full resources arrive from the server we will be notified here.
   * We will then update the client side store with the new resource or update the existing resource.
   * @param id
   * @param resource
   */
  private handleServerUpdate = (
    id: string,
    resource: ServiceResource | null
  ): void => {
    const clientCachedModel = this.modelMap.get(id);
    if (resource == null) {
      this.updateSyncMetaForNotFoundModel(id);
      this.modelMap.delete(id);
    } else if (clientCachedModel) {
      const patch =
        this.serviceTransformer.createPatchForCurrentModelAndIncomingResource(
          clientCachedModel,
          resource
        );
      clientCachedModel.applyPatch(patch);
      this.updateSyncMetaForUpdatedModel(id, clientCachedModel);
    } else {
      const service = this.serviceTransformer.fromApiResource(resource);
      this.modelMap.set(id, service);
      console.log('Added New Service From Server', id, service.name);
      this.updateSyncMetaForLoadedModel(id, service);
    }
  };
}

export default ServiceStore;
