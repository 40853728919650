export const CheckBoxIcon = ({
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='checkbox'>
        <rect
          id='box'
          width='20'
          height='20'
          rx='6'
          fill='currentColor'
        />
        <path
          id='Icon'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.91006 12.4959L5.7071 10.2929C5.31658 9.90239 4.68342 9.90239 4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071L7.29288 14.7071C7.7168 15.131 8.4159 15.0892 8.7863 14.6178L15.7863 6.61786C16.1275 6.18359 16.0521 5.55494 15.6178 5.21372C15.1835 4.87251 14.5549 4.94795 14.2136 5.38222L7.91006 12.4959Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
