import { IntegrationConfigurationType } from '@outbound/types';
import { IntegrationConfigurationResource } from '@outbound/types/src/workspace/integration-configuration/integration-configuration-resource.type';
import { BaseTransport } from '../../base-transport';

class IntegrationConfigurationTransport extends BaseTransport<IntegrationConfigurationResource> {
  protected async fetchById(
    _id: string
  ): Promise<IntegrationConfigurationResource | null> {
    const response = await this._axiosInstance.get(
      `workspace/integration-configurations/${_id}`
    );
    return response.data;
  }
  public acceptEmbeddedResource(
    _resource: IntegrationConfigurationResource
  ): void {
    throw new Error('Method not implemented.');
  }

  public async fetchAll(): Promise<Array<IntegrationConfigurationResource>> {
    const response = await this._axiosInstance.get(
      '/workspace/integration-configurations'
    );
    return response.data.items;
  }

  /**
   * Calls the API to create a new integration configuration.
   * @param integration
   * @param integrationSpecificConfig
   */
  public async createNewIntegrationConfiguration(
    integration: IntegrationConfigurationType,
    integrationSpecificConfig: any
  ): Promise<void> {
    const now = new Date();
    await this._axiosInstance.post('/workspace/integration-configurations', {
      integration,
      ...integrationSpecificConfig,
    });

    /**
     * The Integration Configuration takes some time to initialize.
     * We will poll the server to check if the integration configuration is in the INITIALIZING state.
     * In the future this would be replaced with a websocket event on the sync endpoint.
     */
    this.pollForResourceInTargetState(
      integration,
      (resource: IntegrationConfigurationResource) => {
        //Poll till we have a different status than INITIALIZING
        return resource.lifecycleStatus !== 'INITIALIZING';
      },
      (resource: IntegrationConfigurationResource) => {
        return resource.lifecycleStatus === 'FAILED';
      },
      'integration-initialization-status'
    );

    this.pollForNextHealthCheck(integration, now);
  }

  public async deleteIntegrationConfiguration(
    integration: IntegrationConfigurationType
  ): Promise<void> {
    await this._axiosInstance.delete(
      `/workspace/integration-configurations/${integration}`
    );
  }

  public async runHealthChecks(
    integration: IntegrationConfigurationType
  ): Promise<void> {
    const now = new Date();

    await this._axiosInstance.post(
      `/workspace/integration-configurations/${integration}/run-health-checks`
    );

    this.pollForNextHealthCheck(integration, now);
  }

  protected async internalBootstrap(): Promise<void> {
    const integrationConfigurations = await this.fetchAll();
    integrationConfigurations.forEach((integrationConfiguration) => {
      this.notifyStoreOfServiceUpdateCallback?.(
        integrationConfiguration.integration,
        integrationConfiguration
      );
    });
  }

  private async pollForNextHealthCheck(
    integration: IntegrationConfigurationType,
    now: Date
  ) {
    console.log('Polling for next health check');
    this.pollForResourceInTargetState(
      integration,
      (resource: IntegrationConfigurationResource) => {
        console.log('Checking for health check update');
        /**
         * Poll till we get a health check update (Null means no health check has been run)
         */
        if (
          resource.healthCheckLastCompletedAtTimestamp != null &&
          new Date(resource.healthCheckLastCompletedAtTimestamp) > now
        ) {
          return true;
        } else {
          return false;
        }
      },
      (_resource: IntegrationConfigurationResource) => {
        return false;
      },
      'health-check-update'
    );
  }
}

export default IntegrationConfigurationTransport;
