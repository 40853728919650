'use client';
import {
  CloseButton,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { cx } from 'class-variance-authority';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { ObButton } from '../ob-button/ob-button';
import { CustomColorPicker } from './componnents/custom-color-picker';

export type ObColorPickerProps = {
  selectedColor?: any;
  optionalColors?: string[];

  /**
   * Custom button to open the color picker
   * You will want to pass this. We only provide a default button for testing purposes
   */
  popoverButton?: React.ReactNode;

  onChange?: (value: { hex: string }) => any;
  /**
   * Callback function called each time the color picker is opened
   */
  onOpen?: () => any;
  /**
   * Callback function called each time the color picker is closed
   */
  onClose?: () => any;
};

export const ObColorPicker = ({
  selectedColor,
  optionalColors,
  popoverButton,
  onChange,
  onOpen,
  onClose,
}: ObColorPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState<any>(selectedColor);
  const popoverRef = useRef<HTMLDivElement>(null);

  /**
   * The mutation observer will watch for changes to the data-open attribute on the popover element
   * This is done because we want to expose the open state of the popover to the parent component
   * but the headless UI popover does not provide a native callback to do this. Using the render prop
   * to set the state causes React Render Errors in the console so this approach is used instead.
   * If in the future Headless UI provides a way to do this natively, we should switch to that
   */
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-open'
        ) {
          const target = mutation.target as Element;
          const isOpenAttribute = target.getAttribute('data-open');
          //This is a string with no value
          const isOpen = isOpenAttribute != null;
          setIsOpen(isOpen);
        }
      });
    });

    if (popoverRef.current) {
      observer.observe(popoverRef.current, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleChange = useCallback(
    (newColor: any) => {
      setColor(newColor);
      onChange?.(newColor);
    },
    [onChange]
  );

  /**
   * Side effect of the isOpen state changing to closed.
   * We will fire the onClose callback when this happens
   */
  const hasOpened = useRef(false);

  useEffect(() => {
    if (isOpen) {
      //Prevent calling onOpen multiple times
      if (!hasOpened.current) {
        hasOpened.current = true;
        onOpen?.();
      }
    } else if (hasOpened.current) {
      onClose?.();
      hasOpened.current = false; // Reset after calling onClose
    }
  }, [isOpen, onClose, onOpen]);

  /**
   * Side effect of the selectedColor prop changing.
   * We will update the color state to match the selectedColor prop
   */
  useEffect(() => {
    if (selectedColor?.hex) {
      setColor({ hex: selectedColor?.hex });
    }
  }, [selectedColor?.hex]);

  return (
    <Popover ref={popoverRef}>
      <div data-testid='ob-color-picker'>
        {popoverButton ?? (
          // DEFAULT BUTTON FOR TESTING PURPOSES ONLY; PASS YOUR OWN BUTTON TO THE POPOVER BUTTON PROP
          <PopoverButton>Open Color Picker</PopoverButton>
        )}
        <PopoverPanel
          anchor='bottom'
          portal={true}
          focus={true}
          className={cx(
            'ob-color-picker-panel z-10 mt-3 select-none w-[344px] m-3'
          )}
          data-testid='ob-color-picker-panel'
        >
          <Transition
            appear
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
          >
            <div className='rounded-xl p-3 dark:bg-dark/background/surface border border-light/border/default/normal dark:border-dark/border/default/normal'>
              <CustomColorPicker
                color={color}
                optionalColors={optionalColors}
                onChange={handleChange}
                // onChangeComplete={handleChange}
              />
              <CloseButton as={Fragment}>
                <ObButton
                  fullWidth='always'
                  label='Done'
                  size='medium'
                  onClick={() => setIsOpen(false)}
                />
              </CloseButton>
            </div>
          </Transition>
        </PopoverPanel>
      </div>
    </Popover>
  );
};
