import { MEDIUM_DISPLAY_ADS_ANIMATED } from '@outbound/types';
import { ObLoadingSpinner } from '../../../../../components/elements/ob-loading-spinner/ob-loading-spinner';

import { AnimatedTemplateProps } from '../../../template-component-props.type';
import {
  getHexCodeFromColorValue,
  getPublicUrlFromImageValue,
  getTextFromTextValue,
} from '../../../template-value-utilities';
import { GoogleDisplayAd } from '../../../../templates/google-display-ads/multi-scene/google-display-ad';

export const DisplayAdAnimated0001: React.FC<AnimatedTemplateProps> = ({
  values,
  playbackControls,
}: AnimatedTemplateProps) => {
  if (values == null) {
    console.log('No Template Values Passed to Creative');
    return (
      <>
        <ObLoadingSpinner
          includeBrand={true}
          fadeIn={true}
        />
      </>
    );
  }
  return (
    <GoogleDisplayAd
      adSize='square'
      heading={[
        getTextFromTextValue(
          values.get(MEDIUM_DISPLAY_ADS_ANIMATED.SCENE_1_HEADING)
        ),
      ]}
      callToActionButtonLabel={getTextFromTextValue(
        values.get(MEDIUM_DISPLAY_ADS_ANIMATED.SCENE_1_CALL_TO_ACTION)
      )}
      primaryColorHex={getHexCodeFromColorValue(values.get('primaryColor'))}
      secondaryColorHex={getHexCodeFromColorValue(values.get('secondaryColor'))}
      logoImageUrl={getPublicUrlFromImageValue(values.get('logo'))}
      adImages={[getPublicUrlFromImageValue(values.get('heroImage'))]}
      adUrl={undefined}
      isAnimated={playbackControls?.animate ?? true}
      adDesignGroup={'base'}
      playback={playbackControls?.playback ?? 'play'}
      loop={playbackControls?.loop ?? true}
    />
  );
};
