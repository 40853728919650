import { ServerDelta, TemplateSchemaResource } from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';

import { RootStore } from '../../root-store';
import SettingDefinition from './setting-definition/setting-definition';
import SettingSectionTransformer from './setting-section/setting-section-transformer';
import Template from './template';

class TemplateTransformer extends BaseTransformer<
  TemplateSchemaResource,
  Template
> {
  private _settingSectionTransformer: SettingSectionTransformer;
  constructor(rootStore: RootStore) {
    super(rootStore);
    this._settingSectionTransformer = new SettingSectionTransformer(rootStore);
  }

  fromApiResource(resource: TemplateSchemaResource): Template {
    const settingDefinitions = resource.settingDefinitions.map((definition) => {
      return new SettingDefinition(
        this._rootStore,
        definition.id,
        definition.label,
        definition.type,
        definition.sectionAssignment ?? []
      );
    });

    const sections = resource.settingSections.map((section) => {
      return this._settingSectionTransformer.fromApiResource(section);
    });
    return new Template(
      this._rootStore,
      resource.medium,
      resource.id,
      'system',
      resource.name,
      resource.renderComponent,
      sections,
      settingDefinitions
    );
  }

  createPatchForCurrentModelAndIncomingResource(
    _model: Template,
    _resource: TemplateSchemaResource
  ): ServerDelta[] {
    throw new Error('Method not implemented.');
  }
}

export default TemplateTransformer;
