import {
  ObBudgetGoalsSlider,
  ObChannelGoal,
  ObChannelSwitch,
  ObSkeleton,
  ObTypography,
} from '@outbound/design-system';
import {
  CampaignChannelType,
  StrategyResource,
  supportedChannels,
} from '@outbound/types';
import { useState } from 'react';
import { MarketingPlanCard } from '../common/marketing-plan-card';
import { MarketingPlanCardTitle } from '../common/marketing-plan-card-title';

export const ChannelMenu = ({
  activeChannelTypes,
  onChannelToggledCallback,
}: {
  activeChannelTypes: Array<CampaignChannelType>;
  onChannelToggledCallback: (channelType: CampaignChannelType) => any;
}) => {
  return (
    <div className='grid grid-cols-2 sm:grid-cols-3 gap-6'>
      {supportedChannels.map((channel) => {
        return (
          <ObChannelSwitch
            key={channel.type}
            onToggledCallback={onChannelToggledCallback}
            label={channel.label}
            available={channel.available}
            type={channel.type}
            isEnabled={activeChannelTypes.includes(channel.type)}
          />
        );
      })}
    </div>
  );
};

export interface RecommendBudgetProps {
  maxWidth: number;

  workspaceDailyBudgetValue: number;
  /**
   * Callback function to be called each time the Marketing Goal Card updates the daily budget
   */
  onDailyBudgetUpdatedCallback: (dailyBudget: number) => any;

  /**
   * Current set of recommended strategies
   */
  strategies: Array<StrategyResource>;
  activeStrategy?: StrategyResource;

  industryName?: string;
  industryBudgetHigh?: number;
  industryBudgetLow?: number;
  isLoading: boolean;
}

export type IndustryBudgetRange = 'low' | 'in-range' | 'high';

export const compareBudgetToIndustryRange = (
  currentDailyBudget: number,
  industryHigh?: number,
  industryLow?: number
): IndustryBudgetRange => {
  if (industryLow != null && currentDailyBudget < industryLow) {
    return 'low';
  } else if (industryHigh != null && currentDailyBudget > industryHigh) {
    return 'high';
  } else {
    return 'in-range';
  }
};

export const RecommendedBudget = ({
  workspaceDailyBudgetValue,
  maxWidth,
  onDailyBudgetUpdatedCallback,
  strategies,
  activeStrategy,
  industryBudgetHigh,
  industryBudgetLow,
  isLoading,
}: RecommendBudgetProps) => {
  return (
    <div
      className='flex flex-col gap-6'
      data-testid='budget-slider'
    >
      {!isLoading ? (
        <ObBudgetGoalsSlider
          industryBudgetHigh={industryBudgetHigh}
          industryBudgetLow={industryBudgetLow}
          costPerGoalUnit={5}
          dailyMaxBudget={
            industryBudgetHigh ? Math.round(industryBudgetHigh * 1.1) : 0
          }
          strategies={strategies}
          activeStrategy={activeStrategy}
          onBudgetUpdated={onDailyBudgetUpdatedCallback}
          componentWidth={maxWidth}
          budgetValue={workspaceDailyBudgetValue}
          isLoading={isLoading}
        />
      ) : (
        <div className='flex justify-center'>
          <div className='my-[5px] flex flex-row gap-2 items-center w-full pl-[8px]'>
            <div className='flex flex-col justify-start'>
              <ObSkeleton
                width={'74px'}
                height={'54px'}
              />
              <ObSkeleton
                width={'74px'}
                height={'20px'}
              />
            </div>
            <div
              style={{
                width: `calc(100% - 108px)`,
                height: `calc(100% + 32px)`,
              }}
            >
              <ObSkeleton
                width='100%'
                height='100%'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ManualBudget = () => {
  const [activeChannels, setActiveChannels] = useState<CampaignChannelType[]>([
    CampaignChannelType.GOOGLE_DISPLAY_ADS,
  ]);

  const defaultGoal = {
    value: '1',
    displayValue: 'Increase Brand Awareness',
    key: '1',
  };

  const [, setChannelGoals] = useState<string>(
    CampaignChannelType.GOOGLE_DISPLAY_ADS
  );

  const onChannelToggledCallback = (channelType: CampaignChannelType) => {
    if (activeChannels.includes(channelType)) {
      setActiveChannels(activeChannels.filter((c) => c !== channelType));
    } else {
      setChannelGoals(defaultGoal.value);
      setActiveChannels([...activeChannels, channelType]);
    }
  };

  return (
    <>
      <ObTypography>Manual Budget</ObTypography>
      {activeChannels.map((channel) => {
        return (
          <ObChannelGoal
            key={channel}
            channel={channel}
            goalValue={channel}
            onGoalChangedCallback={(goal) => {
              setChannelGoals(goal);
            }}
            onChannelToggledCallback={onChannelToggledCallback}
          />
        );
      })}
      <div className='p-4'></div>

      <ChannelMenu
        activeChannelTypes={activeChannels}
        onChannelToggledCallback={onChannelToggledCallback}
      />
    </>
  );
};

export interface YourMarketingGoalCardProps {
  outboundRecommendedStrategies?: Array<StrategyResource>;
  activeStrategy?: StrategyResource;
  dailyBudget: number;
  maxWidth: number;
  /**
   * Callback function to be called each time the Marketing Goal Card updates the daily budget
   */
  onDailyBudgetUpdatedCallback: (dailyBudget: number) => any;
  industryBudgetHigh?: number;
  industryBudgetLow?: number;
  isLoading: boolean;
}

export const YourMarketingBudgetCard = ({
  dailyBudget,
  maxWidth,
  onDailyBudgetUpdatedCallback,
  outboundRecommendedStrategies,
  activeStrategy,
  industryBudgetHigh,
  industryBudgetLow,
  isLoading,
}: YourMarketingGoalCardProps) => {
  return (
    <MarketingPlanCard className='xl:min-w-[655px]'>
      <MarketingPlanCardTitle
        title='Your Budget'
        wrapOnMobile={false}
        variant='primary-card'
      />
      <ObTypography variant='body1'>
        Drag the budget slider to select different strategies based on what you
        want to spend.
      </ObTypography>
      <RecommendedBudget
        maxWidth={maxWidth}
        activeStrategy={activeStrategy}
        strategies={outboundRecommendedStrategies ?? []}
        workspaceDailyBudgetValue={dailyBudget}
        onDailyBudgetUpdatedCallback={onDailyBudgetUpdatedCallback}
        industryBudgetHigh={industryBudgetHigh}
        industryBudgetLow={industryBudgetLow}
        isLoading={isLoading}
      />
    </MarketingPlanCard>
  );
};
