import { Outlet } from 'react-router-dom';

export const SettingsPageOutlet = () => {
  return (
    <div
      data-testid='workspace-setting-page-component'
      className='max-w-screen-md p-6 w-full mx-auto'
    >
      <div>
        <Outlet />
      </div>
    </div>
  );
};
