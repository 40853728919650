import { cx } from 'class-variance-authority';
import { useWindowDimensions } from '../../../../hooks/use-window-dimensions';
import { NavItemWithAbsolutePath } from '../../../navigation/dashboard-route.type';
import { DrawerItem } from '../drawer-item/drawer-item';

interface DrawerItemListProps {
  className?: string;
  isDrawerExpanded: boolean;
  navigationItems: NavItemWithAbsolutePath[];
  onItemClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const DrawerItemList = ({
  className,
  isDrawerExpanded,
  navigationItems,
  onItemClick,
}: DrawerItemListProps) => {
  const { isSmallOrLargerViewport: isDesktopView } = useWindowDimensions();
  const shouldNotFocusItem = !isDesktopView && !isDrawerExpanded;

  return (
    <ul className={cx('', className)}>
      {navigationItems.map((i) => (
        <li
          key={i.title}
          data-route={i.absolutePath}
        >
          <DrawerItem
            icon={i.icon}
            label={i.title}
            navigatesToRoute={i.absolutePath}
            expanded={isDrawerExpanded}
            tabIndex={shouldNotFocusItem ? -1 : undefined}
            onClickHandler={onItemClick}
          />
        </li>
      ))}
    </ul>
  );
};
