import { cva, cx } from 'class-variance-authority';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseFormFieldOption, ObTypography } from '../../../../index';

import {
  AnyFieldSchema,
  ENUM_SUPPORTED_OPERATORS,
  EnumRuleBuilderFieldSchema,
  NUMBER_SUPPORTED_OPERATORS,
  RuleBuilderComparisonOperator,
  RuleBuilderFieldDataType,
  RuleBuilderOperator,
  RuleBuilderSchema,
  STRING_SUPPORTED_OPERATORS,
  operatorToLabelMap,
} from '@outbound/types';
import { ObInputCombobox } from '../../../atoms/inputs/ob-input-combobox/ob-input-combobox';
import {
  ObInputListbox,
  ObInputListboxOption,
} from '../../../atoms/inputs/ob-input-listbox/ob-input-listbox';
import { TargetingConditionForFrontend } from '../ob-rule-builder.types';
import {
  NumberRangeValueForm,
  NumberSingleValueForm,
} from './operator-value-forms/number-value-forms';
import { RuleBodyBadgesArray } from './rule-body-badges-array';

export interface RuleBodyProps {
  /**
   * Parent managed state for this rule.
   * It is possible the value is not fully configured yet which is why it is wrapped in a Partial
   *
   * We add a node ID on the frontend to use to track and manipulate the various nodes
   */
  value: TargetingConditionForFrontend;

  /**
   * Callback to notify the parent that the value for this rule should be updated.
   * The parent is responsible for updating the value prop based on this function being called.
   */
  onValueUpdatedCallback: (value: TargetingConditionForFrontend) => void;
  /**
   * Defines the fields that are available for this rule to operate on.
   * These will appear in the dropdown on the rule for the user to select from.
   *
   * Ex. First Name, Age, Last Updated At, etc.
   */
  schema: RuleBuilderSchema;

  /**
   * Indicates that the rule body should be displayed in edit mode
   */
  isInEditMode: boolean;
}

export const RuleBody = ({
  value,
  onValueUpdatedCallback,
  schema,
  isInEditMode,
}: RuleBodyProps) => {
  /**
   * Since we never expect the schema to change we can memoize the field options
   * to prevent unnecessary re-renders. We may also want to do this in the parent
   * so we do not need to recompute the field options on every render of individual rules.
   */
  const fieldOptions: Array<BaseFormFieldOption> = useMemo(
    () =>
      schema.fields.map((field) => {
        return {
          key: field.key,
          value: field.key,
          displayValue: field.label,
        };
      }),
    [schema.fields]
  );

  const findFieldSchemaByFieldKey = useCallback(
    (fieldKey?: string): AnyFieldSchema | undefined => {
      return schema.fields.find((field) => field.key === fieldKey);
    },
    [schema.fields]
  );

  /**
   * Tracks the schema value of the currently selected field.
   * This saves us from having to find the field in the schema each time we need it.
   *
   * This reacts to changes in value.fieldKey only. This should not be updated internally and should
   * rely on the parent to update the value prop.
   */
  const [currentlySelectedFieldSchema, setCurrentlySelectedFieldSchema] =
    useState<AnyFieldSchema | undefined>(undefined);

  useEffect(() => {
    if (value.fieldKey) {
      const newField = findFieldSchemaByFieldKey(value.fieldKey);
      setCurrentlySelectedFieldSchema(newField);
    } else {
      setCurrentlySelectedFieldSchema(undefined);
    }
  }, [findFieldSchemaByFieldKey, value.fieldKey]);

  /**
   * Stores the supported operators for the currently selected field.
   */
  const [supportedOperatorOptions, setSupportedOperatorOptions] =
    useState<Array<BaseFormFieldOption>>();

  /**
   * If for some reason the component is mounted with a fieldKey we will need to set the operators.
   * There may be a more elegant way to handle this from a lifecycle perspective
   * but without spending the time to analyze the lifecycle and state interactions this was a simple solution.
   */
  useEffect(() => {
    if (value.fieldKey) {
      setOperatorsForField(value.fieldKey);
    }
    //We only want to run this effect once when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Called each time a user selects a new field from the dropdown.
   * This is effectively saying they want to change what field the rule is targeting.
   *
   * This has the potential to change the available operators and values for the rule if the field
   * is of a different datatype or the same datatype with different options.
   *
   * @param fieldOption
   */
  const handleOnFieldIdChanged = (
    currentFieldKey?: string,
    nextFieldKey?: string
  ) => {
    const newField = findFieldSchemaByFieldKey(currentFieldKey);
    const oldField = findFieldSchemaByFieldKey(nextFieldKey);

    /**
     * Holds the value of the next operator that should be set.
     * This could be undefined if there is more than one operator that could be set,
     * a default operator if there is only one,
     * or the same operator if the field is the same data type or the operation values are compatible between the two fields.
     */
    let nextOperator: RuleBuilderOperator | undefined = undefined;

    let operationValue: any = value.operationValue;

    if (isNewFieldAndOldFieldTheSameDataType(newField, oldField)) {
      //The fields are the same datatype. We will check if the operationValues are compatible
      if (oldField == null) {
        //Since the old field is null there should be no operator or values currently set so we will keep nextOperator as undefined
      } else if (!isDataTypeOperationValuesPortable(oldField)) {
        //Since the operation values are not portable we must reset the operator and values
        nextOperator = undefined;
        operationValue = undefined;
      } else {
        /**
         * If the fields are the same data type and the operation values are portable we will keep the operator and values the same.
         */
        nextOperator = value.operator;
        operationValue = value.operationValue;
      }
    } else {
      nextOperator = undefined;
      operationValue = undefined;
      //Since the new fields are different data types we will need to clear out the operator and values
      setOperatorsForField(nextFieldKey);
    }

    onValueUpdatedCallback({
      ...value,
      fieldKey: nextFieldKey!,
      operator: nextOperator,
      operationValue: operationValue,
    });
  };

  /**
   * @param operator
   */
  const handleOnOperatorChanged = (operator: any) => {
    console.log('Operator Changed', operator);
    let nextOperatorValue: any = getDefaultValueForOperator(operator); //This will be overwritten if the operation values are compatible
    const previousOperator = value.operator;
    if (operator == null || previousOperator == null) {
      /**
       * This case is triggered when the operator is changed to null or the previous operator was null.
       * In either case the value should be cleaned up to undefined. Either there is no operator set so there should be no value
       * or the operator was just set so there is no value.
       */
    } else {
      /**
       * This case is triggered when the operator is changed from a non-null value to another non-null value.
       * An example is a user has a number field configured and they want to go from is greater than to is less than.
       * The value should be preserved in cases where the operation values are compatible between the two operators.
       * An example where this would not be the case is the user goes from an operator like "is greater than" to "between"
       * since is greater than only has one value and between expects a range of values.
       */
      const previousOperatorDataType =
        getDataTypeForOperatorValue(previousOperator);
      const nextOperatorDataType = getDataTypeForOperatorValue(operator);
      if (
        previousOperatorDataType !== 'UNKNOWN' &&
        nextOperatorDataType !== 'UNKNOWN' &&
        previousOperatorDataType === nextOperatorDataType
      ) {
        //The operation values are compatible
        nextOperatorValue = value.operationValue;
      }
    }
    onValueUpdatedCallback({
      ...value,
      operator,
      operationValue: nextOperatorValue,
    });
  };

  const getDefaultValueForOperator = (operator: RuleBuilderOperator) => {
    switch (operator) {
      case RuleBuilderComparisonOperator.BETWEEN:
        return { from: undefined, to: undefined };
      case RuleBuilderComparisonOperator.EQUALS:
      case RuleBuilderComparisonOperator.GREATER_THAN:
      case RuleBuilderComparisonOperator.LESS_THAN:
        return undefined;
      case RuleBuilderComparisonOperator.ANY_OF_ENUM:
        return [];
      default:
        return undefined;
    }
  };

  const getDataTypeForOperatorValue = (operator: RuleBuilderOperator) => {
    switch (operator) {
      case RuleBuilderComparisonOperator.BETWEEN:
        return 'NUMBER_RANGE';
      case RuleBuilderComparisonOperator.EQUALS:
      case RuleBuilderComparisonOperator.GREATER_THAN:
      case RuleBuilderComparisonOperator.LESS_THAN:
        return 'NUMBER';
      case RuleBuilderComparisonOperator.ANY_OF_ENUM:
        return 'ARRAY_STRING';
      default:
        return 'UNKNOWN';
    }
  };

  const handleOnOperationValueChanged = (operationValue: any) => {
    onValueUpdatedCallback({
      ...value,
      operationValue,
    });
  };

  /**
   * Each Field Type will have a different set of operators that are supported.
   * @param fieldKey
   */
  const setOperatorsForField = (fieldKey?: string) => {
    const field = findFieldSchemaByFieldKey(fieldKey);
    if (field == null) {
      setSupportedOperatorOptions([]);
    } else {
      const supportedOperators = lookupSupportedOperatorsForDataType(
        field.dataType
      );
      setSupportedOperatorOptions(
        supportedOperators.map((operator) => {
          return {
            key: operator,
            value: operator,
            displayValue: operatorToLabelMap[operator],
          };
        })
      );
    }
  };

  const lookupSupportedOperatorsForDataType = (
    fieldDataType: RuleBuilderFieldDataType
  ): Array<RuleBuilderOperator> => {
    switch (fieldDataType) {
      case RuleBuilderFieldDataType.ENUM:
        return ENUM_SUPPORTED_OPERATORS;
      case RuleBuilderFieldDataType.STRING:
        return STRING_SUPPORTED_OPERATORS;
      case RuleBuilderFieldDataType.NUMBER:
        return NUMBER_SUPPORTED_OPERATORS;
      default:
        return [];
    }
  };

  const isNewFieldAndOldFieldTheSameDataType = (
    newField?: AnyFieldSchema,
    oldField?: AnyFieldSchema
  ) => {
    if (newField == null || oldField == null) {
      return false;
    }
    return newField.dataType === oldField.dataType;
  };

  const isDataTypeOperationValuesPortable = (field: AnyFieldSchema) => {
    if (field.dataType === RuleBuilderFieldDataType.ENUM) {
      //Enum fields have constrained options. We cannot assume they are portable
      return false;
    } else {
      //All other data types are portable (Strings, numbers, etc)
      return true;
    }
  };

  const ruleFieldStyles = cva('overflow-hidden', {
    variants: {
      isValueSet: {
        true: '',
        false: '',
      },
      isInEditMode: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        isValueSet: true,
        isInEditMode: true,
        className: '@md/rule-body:flex-shrink-[2] @md/rule-body:min-w-[33%]',
      },
      {
        isValueSet: false,
        isInEditMode: true,
        className: 'flex-1',
      },
    ],
  });

  return (
    <div
      data-ob-component='rule-body'
      className='@container/rule-body px-4 py-4 '
    >
      <div
        className={cx(
          ' flex gap-2 ',
          isInEditMode
            ? 'flex-col @md/rule-body:flex-row @md/rule-body:flex-nowrap @md/rule-body:items-center'
            : 'flex-row items-center flex-wrap'
        )}
      >
        <div
          data-ob-component='rule-field'
          // Once we know the field we  can set the width to fit the content so we leave all available space for the operator and operation value
          className={ruleFieldStyles({
            isValueSet: value.fieldKey != null,
            isInEditMode,
          })}
        >
          {/* 
        ---------------------------------------------------------------
                                  FIELD
        ---------------------------------------------------------------
      */}
          {isInEditMode ? (
            <ObInputListbox
              size='small'
              value={
                fieldOptions.find((option) => option.key === value.fieldKey)
                  ?.value
              }
              onValueChangedCallback={(nextValue) =>
                handleOnFieldIdChanged(value.fieldKey, nextValue)
              }
              options={fieldOptions.map((o) => {
                return {
                  value: o.key as string,
                  label: o.displayValue ?? '',
                  description: '',
                  data: undefined,
                };
              })}
              isDisabled={false}
              isLoading={false}
              inputId={''}
            />
          ) : (
            <ObTypography
              variant='subtitle2'
              className='whitespace-nowrap dark:text-actionPrimaryV2NormalDark text-light/action/primary/on-subtle'
            >
              {currentlySelectedFieldSchema?.label ?? value.fieldKey}
            </ObTypography>
          )}
        </div>

        {/* 
        ---------------------------------------------------------------
                                  OPERATOR
        ---------------------------------------------------------------
        Renders a dropdown of operator options from supportedOperatorOptions
        This section should only ever be rendered if there is a Field Selected
      */}
        {value.fieldKey && (
          <div
            data-ob-component='rule-operator'
            // Once the operator is selected we can set the width to fit the content so we leave all available space for the operation value
            className={ruleFieldStyles({
              isValueSet: value.operator != null,
              isInEditMode,
            })}
          >
            {isInEditMode && (
              <ObInputListbox
                size='small'
                options={
                  supportedOperatorOptions?.map(
                    (operator): ObInputListboxOption => {
                      return {
                        label: operator.displayValue ?? '',
                        value: operator.key as string,
                        description: '',
                        data: undefined,
                      };
                    }
                  ) ?? []
                }
                isDisabled={false}
                value={
                  value
                    ? (supportedOperatorOptions?.find(
                        (option) => option.key === value.operator
                      )?.key as string)
                    : ''
                }
                onValueChangedCallback={(nextValue) =>
                  handleOnOperatorChanged(
                    nextValue as RuleBuilderComparisonOperator
                  )
                }
                isLoading={false}
                inputId={''}
              />
            )}
            {!isInEditMode && value.fieldKey && value.operator ? (
              <div className='flex items-center'>
                <ObTypography
                  variant='subtitle2'
                  className='whitespace-nowrap'
                >
                  {/* 
                  Opertunity here to reformat this based on what is selected to make it more readable as a sentence.
                  For example Gender is any of Male; could be rewritten Gender is Male
                  We could do this anytime a single value is selected for an any of operator
                  We would most
                     */}
                  {operatorToLabelMap[value.operator]}
                </ObTypography>
              </div>
            ) : null}
          </div>
        )}
        {/* 
        ---------------------------------------------------------------
                             OPERATION VALUE
        ---------------------------------------------------------------
        Will render a unique form based on the operator
      */}
        {value?.fieldKey && value?.operator && (
          <div
            data-ob-component='rule-operator-form'
            className={cx([
              'flex items-center',
              isInEditMode
                ? 'w-full min-w-0 @md/rule-body:min-w-[33%] @md/rule-body:flex-shrink-[1.5]'
                : 'w-fit',
            ])}
          >
            <OperationValueForm
              field={currentlySelectedFieldSchema!}
              operator={value.operator!}
              isInEditMode={isInEditMode}
              operationValue={value.operationValue}
              onOperationValueUpdatedCallback={handleOnOperationValueChanged}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface OperationValueFormProps {
  /**
   * The currently selected field
   */
  field: AnyFieldSchema;
  /**
   * The currently selected operator
   */
  operator: RuleBuilderOperator;
  /**
   * If the field is in edit mode or not
   */
  isInEditMode: boolean;
  /**
   * The current operation value
   */
  operationValue: any;
  /**
   * Callback to be called when the operation value changes
   * (Parent manages state so it is up to the parent to update the operationValue based on this callback)
   */
  onOperationValueUpdatedCallback: (value: any) => void;
}

/**
 * Sets up the form for the operation value based on the operator
 */
const OperationValueForm = ({
  operator,
  operationValue,
  onOperationValueUpdatedCallback,
  field,
  isInEditMode,
}: OperationValueFormProps) => {
  /**
   * We only render this component if there is an operator present.
   */
  if (field == null || operator == null) {
    return <></>;
  }
  switch (operator) {
    case RuleBuilderComparisonOperator.BETWEEN:
      return (
        <NumberRangeValueForm
          value={operationValue}
          isInEditMode={isInEditMode}
          onValueChangedCallback={onOperationValueUpdatedCallback}
          isLoading={false}
        />
      );
    case RuleBuilderComparisonOperator.EQUALS:
    case RuleBuilderComparisonOperator.GREATER_THAN:
    case RuleBuilderComparisonOperator.LESS_THAN:
      return (
        <NumberSingleValueForm
          value={operationValue}
          isInEditMode={isInEditMode}
          onValueChangedCallback={onOperationValueUpdatedCallback}
          isLoading={false}
        />
      );
    case RuleBuilderComparisonOperator.ANY_OF_ENUM:
      return (
        <OperationValueAnyOfEnum
          field={field as EnumRuleBuilderFieldSchema}
          isInEditMode={isInEditMode}
          operationValue={operationValue}
          onOperationValueUpdatedCallback={onOperationValueUpdatedCallback}
        />
      );
    default:
      return <></>;
  }
};

interface OperationValueAnyOfProps {
  field: EnumRuleBuilderFieldSchema;
  isInEditMode: boolean;
  operationValue: Array<string>;
  onOperationValueUpdatedCallback: (value: Array<string>) => void;
}

/**
 * Form for the Any Of Enum Operator
 */
const OperationValueAnyOfEnum = ({
  field,
  isInEditMode,
  operationValue = [],
  onOperationValueUpdatedCallback,
}: OperationValueAnyOfProps) => {
  const [selectedOptions, setSelectedOptions] = useState<
    Array<BaseFormFieldOption>
  >([]);

  const [options, setOptions] = useState<Array<BaseFormFieldOption>>([]);

  /**
   * When the field is updated we will need to update the options.
   * In practice this shouldn't happen as this component will most likely
   * be unmounted and remounted when the field is updated.
   */
  useEffect(() => {
    //If there are no options there is nothing to do
    if (field.dataTypeConfig?.options == null) {
      return;
    }
    setOptions(
      field.dataTypeConfig.options.map((o) => {
        return { key: o.value, value: o.value, displayValue: o.label };
      })
    );
  }, [field]);

  /**
   * Each time the parent updates the selected options we will need to lookup
   * the option objects from the options array to pass to the ob combobox
   */
  useEffect(() => {
    setSelectedOptions(
      options.filter((option) => operationValue.includes(option.key as string))
    );
  }, [options, operationValue]);

  return (
    <div
      data-testid='operation-value-any-of-enum-form'
      className={cx([' w-full flex ', !isInEditMode && 'flex items-center '])}
    >
      {isInEditMode ? (
        <ObInputCombobox
          value={selectedOptions.map((option) => option.key as string)}
          size='small'
          onValueChangedCallback={onOperationValueUpdatedCallback}
          isLoading={false}
          inputId={''}
          options={options.map((o) => {
            return {
              label: o.displayValue ?? (o.key as string),
              id: o.key as string,
              value: o.key as string,
            };
          })}
        />
      ) : (
        <RuleBodyBadgesArray values={selectedOptions} />
      )}
    </div>
  );
};
