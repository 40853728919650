import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useState } from 'react';

type RequiresRoleProps = {
  /**
   * The roles that the children should be visible for
   */
  roles: ROLE[];
  hideWhenRoleIsPresent?: boolean;
  children?: ReactNode;
};

export enum ROLE {
  ANY = 'Any',
  EXPERT = 'Expert',
  EXPERT_RELATIONSHIP_MANAGER = 'Expert Relationship Manager',
  WORKSPACE_OWNER = 'Workspace Owner',
  WORKSPACE_MEMBER = 'Workspace Member',
}

/**
 * This component helps to provide a good user experience by hiding parts of the UI
 * that the user does not have rights to access. It should be noted this is not security.
 * All authorization decisions must be implemented on the backend. This is for UX purposes only.
 * A user can easily bypass this with the inspector. It is imperative you don't rely on hiding UI
 * for security purposes.
 * @param children
 * @param roles
 * @param hideWhenRoleIsPresent
 * @constructor
 */
export const RequiresRole = ({
  children,
  roles,
  hideWhenRoleIsPresent = false,
}: RequiresRoleProps) => {
  const { getIdTokenClaims, isAuthenticated, isLoading } = useAuth0();

  const [shouldBeVisible, setShouldBeVisible] = useState(false);
  useEffect(() => {
    if (roles.includes(ROLE.ANY)) {
      setShouldBeVisible(true);
      return;
    }
    /*
    User Roles are taken from the Users ID Token.
     */
    const getRolesForCurrentUser = async (): Promise<string[]> => {
      let idToken = await getIdTokenClaims();
      return Promise.resolve(
        idToken == null ? [] : idToken['https://outbound.com/roles'] || []
      );
    };

    if (isLoading || !isAuthenticated) {
      return;
    }

    getRolesForCurrentUser().then((userRoles: string[]) => {
      if (roles.some((allowedRole) => userRoles.includes(allowedRole.trim()))) {
        setShouldBeVisible(!hideWhenRoleIsPresent);
      } else {
        setShouldBeVisible(hideWhenRoleIsPresent);
      }
    });
  }, [
    roles,
    isAuthenticated,
    isLoading,
    hideWhenRoleIsPresent,
    getIdTokenClaims,
  ]);

  /*
  If Roles Any is present then nothing else matters.
  Otherwise, when Auth0 is initializing or if we know the user is not authenticated then
  we don't have enough information to make a decision, so we will not render the children
   */
  if (!roles.includes(ROLE.ANY) && (isLoading || !isAuthenticated)) {
    return <></>;
  }

  return shouldBeVisible ? <>{children}</> : <></>;
};

export default RequiresRole;
