import { cva, cx } from 'class-variance-authority';
import { ObCheckerboardCanvas } from '../ob-checkerboard-canvas/ob-checkerboard-canvas';
import { ObSkeleton } from '../ob-skeleton/ob-skelton';
import { ObThumbnailPlaceholder } from '../ob-thumbnail-placeholder/ob-thumbnail-placeholder';

export type ObThumbnailSizes =
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'responsive';
export type ObThumbnailShape = 'square' | 'rectangle';

export interface ObThumbnailProps {
  id?: string;
  publicUrl?: string;
  altText?: string;
  imageFit?: 'contain' | 'cover';
  shape?: ObThumbnailShape;
  size?: ObThumbnailSizes;
  preferredBackground: 'light' | 'dark';
  onClickHandler?: (e: any) => Promise<any> | void;
  isLoading?: boolean;
  /**
   * The label that should be applied to the button that wraps the image
   */
  label?: string;

  /**
   * If true, the border will be rounded on the bottom
   * Default: true
   */
  roundedOnBottom?: boolean;

  /**
   * If true, a border will be included around the thumbnail.
   * This does not impact border radius
   * Default: true
   */
  includeBorder?: boolean;
}

export const buildVariantClasses = cva('', {
  variants: {
    includeBorder: {
      true: 'border dark:border-dark/border/default/normal',
      false: '',
    },
    roundedOnBottom: {
      true: 'rounded-md',
      false: 'rounded-t-md',
    },
    shape: {
      rectangle: [],
      square: [],
    },
    size: {
      responsive: ['w-full h-full'],
      small: ['min-h-[64px]  h-[64px]'],
      medium: ['min-h-[88px] h-[88px]'],
      large: ['min-h-[100px] h-[100px]'],
      'x-large': ['min-h-[176px] h-[176px]'],
    },
  },
  compoundVariants: [
    {
      shape: 'rectangle',
      size: 'responsive',
      class: 'aspect-video',
    },
    {
      shape: 'square',
      size: 'responsive',
      class: 'aspect-square',
    },
    {
      shape: 'rectangle',
      size: 'small',
      class: 'min-w-[106px] w-[106px]',
    },
    {
      shape: 'rectangle',
      size: 'medium',
      class: 'min-w-[152px] w-[152px]',
    },
    {
      shape: 'rectangle',
      size: 'large',
      class: 'min-w-[148px] w-[148px]',
    },
    {
      shape: 'rectangle',
      size: 'x-large',
      class: 'min-w-[312px]  w-[312px]',
    },
    {
      shape: 'square',
      size: 'small',
      class: 'min-w-[64px]  w-[64px]',
    },
    {
      shape: 'square',
      size: 'medium',
      class: 'min-w-[88px] w-[88px]',
    },
    {
      shape: 'square',
      size: 'x-large',
      class: 'min-w-[176px] w-[176px]',
    },
  ],
  defaultVariants: {
    size: 'small',
    shape: 'rectangle',
    includeBorder: true,
    roundedOnBottom: true,
  },
});

/**
 * Displays a thumbnail of the selected image or a placeholder if no image is selected.
 * Clicking on the thumbnail will open the image picker
 */
export const ObThumbnail: React.FC<ObThumbnailProps> = ({
  id,
  publicUrl,
  imageFit = 'cover',
  shape = 'rectangle',
  size = 'small',
  preferredBackground = 'light',
  onClickHandler,
  isLoading,
  altText,
  label,
  roundedOnBottom = true,
  includeBorder = true,
}: ObThumbnailProps) => {
  /**
   * When we are in a loading state return the loading skeleton
   */
  if (isLoading) {
    return (
      <ObSkeleton variant='rounded'>
        <div
          className={buildVariantClasses({ size, shape, roundedOnBottom })}
        ></div>
      </ObSkeleton>
    );
  }

  if (publicUrl == null || publicUrl === '') {
    return (
      <button
        onClick={onClickHandler}
        className='overflow-hidden'
      >
        <ObThumbnailPlaceholder
          size={size}
          shape={shape}
        />
      </button>
    );
  }

  return (
    <button
      onClick={onClickHandler}
      aria-label={label ?? 'Select Asset'}
      data-image-id={id}
      className={cx(
        ' overflow-hidden',
        buildVariantClasses({ size, shape, roundedOnBottom, includeBorder })
      )}
    >
      <ObCheckerboardCanvas mode={preferredBackground}>
        <div className='relative w-full h-full'>
          <img
            className={cx(
              buildVariantClasses({
                size,
                shape,
                roundedOnBottom,
                includeBorder: false, //Prevent double border
              }),
              imageFit === 'contain' ? 'object-contain' : 'object-cover'
            )}
            src={publicUrl}
            alt={altText}
          />
        </div>
      </ObCheckerboardCanvas>
    </button>
  );
};
