import { useDialogService } from '@outbound/design-system';
import {
  DocumentContentDispositionType,
  DocumentResource,
  DocumentStatus,
  PostDocumentsGenerateDownloadUrlRequestResource,
} from '@outbound/types';
import { useState } from 'react';
import { useAuth0Axios } from '../services/auth0-axios-provider';

export const useDocumentManagerPollAndDownloadHandler = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const { openAlert } = useDialogService();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);

  const exportAndDownloadDocument = async (
    exportDocument: () => Promise<string>,
    contentDisposition?: DocumentContentDispositionType
  ) => {
    try {
      setIsSubmitting(true);
      const result = await exportDocument();

      const document = await waitForActiveStatusOnDocument(result);

      const downloadUrl = await generateDownloadLink(
        document.id,
        contentDisposition
      );

      downloadFile(downloadUrl, contentDisposition);

      setIsSubmitting(false);
    } catch (error) {
      setHasError(true);
      setIsSubmitting(false);
      openAlert({
        body: `Document export failed. Please try again.`,
        title: 'Unable to export document',
      });
      return Promise.reject(new Error('Document export failed'));
    }
  };

  const downloadFile = (
    url: string,
    contentDisposition?: DocumentContentDispositionType
  ) => {
    const link = document.createElement('a');
    link.href = url;
    if (contentDisposition === DocumentContentDispositionType.INLINE) {
      link.setAttribute('target', '_blank');
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateDownloadLink = async (
    id: string,
    contentDisposition?: DocumentContentDispositionType
  ) => {
    const result = await auth0AxiosClient.post<
      PostDocumentsGenerateDownloadUrlRequestResource,
      { data: { downloadUrl: string } }
    >(`documents/${id}/generate-download-url`, {
      contentDisposition:
        contentDisposition ?? DocumentContentDispositionType.ATTACHMENT,
    });

    return result.data.downloadUrl;
  };

  const waitForActiveStatusOnDocument = (
    id: string
  ): Promise<DocumentResource> => {
    return new Promise((resolve, reject) => {
      const activePollInterval = setInterval(async () => {
        try {
          const document = await getDocumentResourceById(id);
          if (document.data.status === DocumentStatus.ACTIVE) {
            clearInterval(activePollInterval);
            resolve(document.data);
          } else {
            console.log('Document not active yet, polling again in 1 second');
          }
        } catch (error) {
          clearInterval(activePollInterval);
          setHasError(true);
          openAlert({
            body: `Document export failed. Please try again.`,
            title: 'Unable to export document',
          });
          reject(new Error('Polling for Document failed'));
        }
      }, 1000);
    });
  };

  /**
   *
   * @param id
   * @returns
   */

  const getDocumentResourceById = async (id: string) => {
    return auth0AxiosClient.get<DocumentResource>(`documents/${id}`);
  };

  return {
    exportAndDownloadDocument,
    isSubmitting,
    hasError,
  };
};
