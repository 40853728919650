'use client';
import { cva, cx } from 'class-variance-authority';
import { ReactNode, forwardRef, useEffect, useRef } from 'react';

import {
  BaseExposedTextInputProps,
  InputSizeProps,
  StateManagedByParentInputValueOnly,
} from '../../../base-component-props.type';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { sizeClasses } from '../ob-input/ob-input-styles';
import { ObSkeleton } from '../ob-skeleton/ob-skelton';

/**
 * A version two of the OBInput component.
 * This component assumes it is controlled by the parent component.
 */
export interface ObInputControlledProps
  extends StateManagedByParentInputValueOnly<string>,
    BaseExposedTextInputProps,
    InputSizeProps {
  iconLeft?: IconSystemKeys;
  iconRight?: IconSystemKeys;
  name?: string;
  required?: boolean;
  type?: 'text' | 'number';

  step?: string;
  min?: number;
  max?: number;
  /**
   * Optional Label to use if the Icon Right Click Handler is provided.
   * Ignored if onIconRightClick is not provided.
   */
  onIconRightClickLabel?: string;
  /**
   * I (@pjcahill) attempted to update this to be more specific to onClickRightIcon.
   * But it breaks the obTimePicker component which passes ob-input as a prop called "customInput" on the React DatePicker component.
   * There is something "Magic" (TM) about this being called onClick in that component.
   */
  onIconRightClick?: () => any;
  /**
   * A callback function that is called when the input gains focus
   * @param event
   * @returns
   */
  onFocusCallback?: (event: React.FocusEvent<HTMLInputElement>) => any;

  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => any;

  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
}

export const InputIcon = ({ icon }: { icon: IconSystemKeys }) => {
  return (
    <div
      className={cx(
        'h-full flex flex-col items-end justify-center first:pl-3 last:pr-3'
      )}
    >
      <ObIcon
        icon={icon}
        size='small'
        color='tertiary'
      />
    </div>
  );
};

export const InputIconButtonWrapper = ({
  label,
  onClickHandler,
  children,
}: {
  onClickHandler: () => any;
  label: string;
  children?: ReactNode;
}) => {
  return (
    <button
      aria-label={label}
      onClick={onClickHandler}
    >
      {children}
    </button>
  );
};

const fontStyles = cva([], {
  variants: {
    size: {
      small: ['text-[12px] '],
      medium: [' text-[14px] '],
      large: ['text-[14px] '],
    },
  },
});

const inputContainerStyles = cva(
  [
    'w-full flex flex-col items-center content-center text-center relative rounded-[4px] border border-solid appearance-none box-border dark:text-dark/content/primary text-light/content/primary ',
  ],
  {
    variants: {
      size: {
        small: 'h-[34px] ',
        medium: 'h-[42px] ',
        large: 'h-[50px]',
      },
      isErrored: {
        true: '',
        false: '',
      },
      isDisabled: {
        true: 'dark:bg-dark/background/surface bg-light/background/default dark:text-dark/content/primary opacity-[50%]',
        false:
          'dark:bg-dark/background/surface bg-light/background/default text-[14px]',
      },
    },
    compoundVariants: [
      /**
       * We use the same border for both normal and disabled states so long as there is not an error
       */
      {
        isErrored: false,
        isDisabled: [false, true],
        className: 'dark:border-dark/border/default/normal',
      },
      {
        isErrored: false,
        isDisabled: false,
        className: 'dark:hover:border-dark/border/default/hover ',
      },
      {
        isErrored: true,
        isDisabled: false,
        className:
          'dark:border-dark/border/negative/normal   dark:focus-within:ring-dark/border/negative/normal',
      },
    ],
    defaultVariants: {
      isDisabled: false,
      isErrored: false,
    },
  }
);

export const ObInputControlled = forwardRef<
  HTMLInputElement,
  ObInputControlledProps
>(
  (
    {
      inputId,
      value,
      onValueChangedCallback,
      autoFocus,
      onBlur,
      type = 'text',
      min,
      max,
      step,
      iconLeft,
      iconRight,
      size = 'medium',
      name = 'ob-input',
      placeholder = '',
      isErrored = false,
      isDisabled = false,
      onIconRightClickLabel,
      onIconRightClick,
      onFocusCallback,
      isLoading,
      onKeyDown,
    },
    ref
  ) => {
    const internalRef = useRef<HTMLInputElement>(null);
    const combinedRef = ref ?? internalRef;

    /**
     * Focus the input if autoFocus is set to true.
     */
    useEffect(() => {
      if (
        autoFocus &&
        combinedRef &&
        'current' in combinedRef &&
        combinedRef.current
      ) {
        console.log('Focusing on input with id:', inputId);
        combinedRef.current.focus();
      }
    }, [autoFocus, combinedRef, inputId]);

    if (isLoading) {
      let skeletonHeight = '';
      if (size === 'large') {
        skeletonHeight = '50px';
      } else if (size === 'medium') {
        skeletonHeight = '42px';
      } else {
        skeletonHeight = '34px';
      }

      return (
        <ObSkeleton
          variant='rounded'
          height={skeletonHeight}
        />
      );
    }

    return (
      <div
        className={cx([
          'flex items-center content-center text-center w-full',
          inputContainerStyles({ isDisabled, isErrored, size }),
        ])}
      >
        {iconLeft && <InputIcon icon={iconLeft} />}
        <input
          id={inputId}
          ref={ref}
          type={type}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          min={min}
          max={max}
          step={step}
          disabled={isDisabled}
          autoFocus={autoFocus}
          autoComplete='off'
          autoCorrect='off'
          className={cx(
            ` w-full  border-none rounded-[4px] outline-none bg-transparent  text-ellipsis focus:outline-none focus:shadow-interactive`,
            sizeClasses({
              size,
              sideIcon: iconLeft != null || iconRight != null,
            }),
            fontStyles({ size })
          )}
          placeholder={placeholder}
          name={name}
          value={value}
          // Used instead of onChange, Needed for Maskito to work correctly. See https://maskito.dev/frameworks/react
          onInput={(e) => {
            onValueChangedCallback(e.currentTarget.value);
          }}
          onFocus={onFocusCallback}
        />
        {iconRight && !onIconRightClick && <InputIcon icon={iconRight} />}
        {iconRight && onIconRightClick && (
          <InputIconButtonWrapper
            onClickHandler={onIconRightClick}
            label={onIconRightClickLabel ?? inputId}
          >
            <InputIcon icon={iconRight} />
          </InputIconButtonWrapper>
        )}
      </div>
    );
  }
);
