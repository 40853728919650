'use client';
import React, { forwardRef, useState } from 'react';
import { ObIcon, ObInputCaption, ObTypography } from '../../../';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { getFilesAsyncFromFolder } from '../../../utilities/file-utilities';

export interface ObInputFileProps {
  multipleFiles?: boolean;
  icon?: IconSystemKeys;
  description?: string | JSX.Element;
  additionalDescription?: string;
  disabled?: boolean;
  error?: boolean;
  value: Array<File>;
  name: string;
  maxFileSizeInKB?: number;
  allowedExtensions?: string;
  onBlur?: () => void;
  onChange: (files: Array<File>) => void;
}

export const ObInputFile = forwardRef<HTMLInputElement, ObInputFileProps>(
  (
    {
      multipleFiles = true,
      icon,
      description = (
        <ObTypography
          variant='body2'
          className='dark:text-contentPrimaryDark'
        >
          Drag and Drop or{' '}
          <ObTypography
            variant='subtitle2'
            className='dark:!text-actionPrimaryV2NormalDark inline'
            as='span'
          >
            Choose file
          </ObTypography>{' '}
          to upload
        </ObTypography>
      ),
      additionalDescription,
      disabled,
      error,
      onChange,
      name,
      value,
      onBlur,
      maxFileSizeInKB,
      allowedExtensions,
    },
    ref
  ) => {
    const [dragActive, setDragActive] = useState(false);
    const [focus, setFocus] = useState(false);
    const [hasError, setHasError] = useState(false);

    const clearFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.currentTarget.value = '';
    };

    const handleFiles = (files: File[] | null) => {
      if (files) {
        const prev = multipleFiles ? value : [];
        const newFiles = [...prev, ...(files ? Array.from(files) : [])];
        onChange?.(newFiles);
      }
    };

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
      if (disabled) return;
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      handleFiles(await getFilesAsyncFromFolder(e.dataTransfer));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setHasError(false);

      let files = [...(e.target.files as Iterable<any>)] as File[];
      if (maxFileSizeInKB) {
        files = files.filter((file) => {
          const isAValidSize = file.size / 1000 < maxFileSizeInKB;
          if (!isAValidSize) {
            console.log('Invalid File size');
            setHasError(true);
          }

          return isAValidSize;
        });
      }

      handleFiles(files);
      clearFileSelection(e);
    };

    const classDragActive = `${
      dragActive
        ? 'dark:bg-bgSurface2Dark dark:border-actionSecondaryHoverBorderDark'
        : 'dark:bg-bgSurfaceDark dark:border-whiteAlpha-2'
    }`;
    const classError = `${
      error
        ? 'border rounded border-dashed dark:border-contextualNegativeDark'
        : ''
    }`;
    const classFocus = `${focus ? 'ring-1 ring-focusDark border-0' : ''}`;

    return (
      <>
        <div
          className={`h-[138px] w-full max-w-full text-center relative ${classError}`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          {disabled && (
            <div className='absolute w-full h-full dark:bg-bgSurfaceDark/[.84] border rounded border-dashed dark:border-actionSecondaryHoverBorderDark/[.16]' />
          )}
          <input
            ref={ref}
            data-testid='upload-input'
            type='file'
            className={`opacity-0 absolute w-full h-full inset-0 cursor-pointer`}
            id={name}
            name={name}
            multiple={multipleFiles}
            accept={allowedExtensions}
            onChange={handleChange}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setFocus(false);
              onBlur?.();
            }}
          />
          <div
            className={`h-full flex items-center justify-center border rounded border-dashed ${classDragActive} ${classFocus}`}
          >
            <div className='flex flex-col items-center justify-center'>
              {icon && (
                <ObIcon
                  icon={icon}
                  size={'small'}
                  classNames='dark:text-contentSecondaryDark m-1'
                />
              )}
              {typeof description === 'string' ? (
                <ObTypography
                  variant='body2'
                  className='m-1 dark:text-contentPrimaryDark'
                >
                  {description}
                </ObTypography>
              ) : (
                description
              )}
              {additionalDescription && (
                <ObTypography
                  variant='body3'
                  className='dark:text-contentSecondaryDark m-1'
                >
                  {additionalDescription}
                </ObTypography>
              )}
            </div>
          </div>
        </div>

        {hasError && maxFileSizeInKB ? (
          <div className='mt-2'>
            <ObInputCaption
              type='error'
              message={`Max file size exceeded (${maxFileSizeInKB / 1024}MB)`}
            />
          </div>
        ) : null}
      </>
    );
  }
);
