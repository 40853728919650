import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';

export const LinkTermsOfService = () => {
  return (
    <ObLink
      href='https://legal.outbound.com/terms-of-service'
      openInNewTab={true}
      label='Terms of Service'
      variant={'primary'}
    />
  );
};
