import { BaseStore } from '../base-store';
import User from './user';
import UserTransformer from './user-transformer';

class UserStore extends BaseStore<User> {
  private _userTransformer: UserTransformer;
  constructor(rootStore: any) {
    super(rootStore, 'user');
    this.rootStore.transport.userTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
    this._userTransformer = new UserTransformer(rootStore);
  }

  protected requestLoadModelFromServer(_id: string): void {
    throw new Error('Method not implemented.');
  }

  private handleServerUpdate(id: string, resource: any): void {
    const clientCachedModel = this.modelMap.get(id);
    if (resource == null) {
      this.updateSyncMetaForNotFoundModel(id);
      this.modelMap.delete(id);
    } else if (clientCachedModel) {
      const patch =
        this._userTransformer.createPatchForCurrentModelAndIncomingResource(
          clientCachedModel,
          resource
        );
      clientCachedModel.applyPatch(patch);
      this.updateSyncMetaForUpdatedModel(id, clientCachedModel);
    } else {
      const model = this._userTransformer.fromApiResource(resource);
      this.modelMap.set(id, model);
      this.updateSyncMetaForLoadedModel(id, model);
    }
  }
}

export default UserStore;
