import { ObIcon } from '../../../../index';

export const RuleDragZone = () => (
  <div
    data-testid='ob-rule-builder-drag-zone'
    className='w-[44px] min-w-[44px] min-h-[60px] flex items-center justify-center cursor-pointer border-r dark:border-actionSecondaryHoverBorderDark/[.16]'
  >
    <ObIcon
      size='medium'
      icon='drag'
    />
  </div>
);
