import { cx } from 'class-variance-authority';
import {
  ReactNode,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DisabledProps } from '../../../base-component-props.type';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

/**
 * Returns the width that the segment should be (In a percentage) based on the min and max value of the segment and the max value of the slider
 * @param segmentStartsAtValue
 * @param segmentEndsAtValue
 * @param sliderMaxValue
 * @param firstSegment
 * @returns
 */
const calculateSegmentPercentageOfSliderWidth = (
  segmentStartsAtValue: number,
  segmentEndsAtValue: number,
  sliderMaxValue: number,
  firstSegment: boolean
) => {
  /**
   * If the index is not 0, subtract one from the minBudget
   * This is to ensure when calculating the range span we account for the gap between
   * the previous strategy maxBudget and the current strategy minBudget
   */
  const subtractOne = !firstSegment ? 1 : 0;

  let rangeSpan = 0;
  /**
   * - Why would the strategy ever be greater than the initialMaxBudget?
   * - We need to decide if we want the minBudget to be 0 or 1 and if so we should truncate the segment min to match
   *
   */
  if (segmentEndsAtValue >= sliderMaxValue) {
    rangeSpan = sliderMaxValue - (segmentStartsAtValue - subtractOne);
  }
  if (segmentEndsAtValue < sliderMaxValue) {
    rangeSpan = segmentEndsAtValue - (segmentStartsAtValue - subtractOne);
  }

  const segmentWidth = (rangeSpan * 100) / sliderMaxValue;

  return segmentWidth;
};

const SliderBgSegment = ({
  index = 0,
  segmentStartsAtValue,
  segmentEndsAtValue,
  sliderMaxValue,
  strategyBelowSliderDecoration,
}: {
  index: number;
  segmentStartsAtValue: number;
  segmentEndsAtValue: number;
  sliderMaxValue: number;
  strategyBelowSliderDecoration?: ReactNode;
}) => {
  const segmentPercentageWidthOfSlider =
    calculateSegmentPercentageOfSliderWidth(
      segmentStartsAtValue,
      segmentEndsAtValue,
      sliderMaxValue,
      index === 0
    );

  /**
   * Slowly decrease the opacity of the segment as the index increases
   * This may need to be adjusted based on the number of segments if we end up with many segments
   */
  const opacity = 0.15 - index * 0.05;

  return (
    <div
      data-testid='segments'
      className='relative '
      style={{
        width: `${segmentPercentageWidthOfSlider}%`,
        height: '64px',
        backgroundColor: `rgba(217, 217, 217, ${opacity})`,
      }}
    >
      {strategyBelowSliderDecoration && (
        <div className='relative '>
          <div className='absolute top-[72px] m-auto w-full'>
            {strategyBelowSliderDecoration}
          </div>
        </div>
      )}
    </div>
  );
};

const UnfilledSliderBar = forwardRef(
  (
    {
      children,
    }: {
      children: ReactNode;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div className='flex flex-row self-stretch items-center gap-2'>
        <div
          ref={ref}
          style={{ width: '100%', height: '8px', zIndex: '1' }}
          className='relative rounded bg-bgSurface2Dark'
        >
          {children}
        </div>
      </div>
    );
  }
);

const END_CAP_WIDTH_PX: number = 20;

/**
 *
 * The left end cap of the slider bar background
 * @returns
 */
const LeftEndCap = () => {
  return (
    <div
      className='rounded-l-lg'
      style={{
        width: `${END_CAP_WIDTH_PX}px`,
        height: '64px',
        backgroundColor: `rgba(217, 217, 217, .15)`,
      }}
    />
  );
};

const SegmentBGs = ({
  sortedSegments,
  showActiveSegmentDecoration,
  maxValue,
}: {
  maxValue: number;
  showActiveSegmentDecoration: boolean;
  sortedSegments: Array<ObSliderSegment>;
  componentWidth: number;
}) => {
  return (
    <div
      className='flex flex-row flex-nowrap'
      // style={{ width: `${componentWidth - END_CAP_WIDTH_PX * 2}px` }}
      style={{ width: `100%` }}
    >
      {/* When no segments are provided we will default to a single segment */}
      {(!sortedSegments || sortedSegments.length === 0) && (
        <SliderBgSegment
          index={0}
          segmentStartsAtValue={0}
          segmentEndsAtValue={maxValue}
          sliderMaxValue={maxValue}
        />
      )}

      {/* When multiple segments are provided we will enumerate over the segments to create visual zones on the slider */}
      {sortedSegments &&
        sortedSegments.length > 0 &&
        sortedSegments.map((segment, index) => {
          return (
            <SliderBgSegment
              key={`segment-${segment.startsAtValue}-${segment.endsAtValue}`}
              index={index}
              segmentStartsAtValue={segment.startsAtValue}
              segmentEndsAtValue={segment.endsAtValue}
              sliderMaxValue={maxValue}
              strategyBelowSliderDecoration={
                segment.decoration && showActiveSegmentDecoration
                  ? segment.decoration
                  : null
              }
            />
          );
        })}
    </div>
  );
};

/**
 *
 * The right end cap of the slider bar background
 * @returns
 */
const RightEndCap = ({ totalSegments = 1 }: { totalSegments: number }) => {
  return (
    <div
      className={cx(`rounded-r-lg `)}
      style={{
        width: `${END_CAP_WIDTH_PX}px`,
        height: '64px',
        /**
         * The opacity of the right end cap is based on the number of segments.
         * This calculation needs to match the segment opacity calculation so the
         * */
        backgroundColor: `rgba(217, 217, 217, ${
          0.15 - (totalSegments - 1) * 0.05
        })`,
      }}
    ></div>
  );
};

interface DragHandleProps extends DisabledProps {
  value: number;
  maxValue: number;
  onDragChange: (isDragging: boolean) => void;
  decoration?: ReactNode;
}

const DragHandle = ({
  value,
  maxValue,
  onDragChange,
  decoration,
  isDisabled = false,
}: DragHandleProps) => {
  return (
    <DragHandlePosition
      value={value}
      maxValue={maxValue}
      onDragChange={onDragChange}
      isDisabled={isDisabled}
    >
      {decoration != null && decoration}
      <InvisibleDragContainer
        isDisabled={isDisabled}
        debugMode={false}
      >
        <DragHandleCenterDot isDisabled={isDisabled} />
        <DragHandleOuterRing isDisabled={isDisabled} />
      </InvisibleDragContainer>
    </DragHandlePosition>
  );
};

interface InvisibleDragContainerProps extends DisabledProps {
  children: ReactNode;
  debugMode?: boolean;
}

/**
 * The invisible drag container is used to increase the hit area of the drag handle
 */
const InvisibleDragContainer = ({
  children,
  debugMode,
  isDisabled = false,
}: InvisibleDragContainerProps) => {
  return (
    <div
      data-invisible-drag-container
      data-disabled={isDisabled}
      className={cx(
        'group h-[44px] w-[44px] absolute top-[-14px] left-[-14px] flex items-center justify-center data-[disabled=false]:cursor-ew-resize data-[disabled=true]:cursor-not-allowed',
        debugMode && 'border border-red-500'
      )}
    >
      {children}
    </div>
  );
};

interface DragHandlePositionProps extends DisabledProps {
  value: number;
  maxValue: number;
  onDragChange: (isDragging: boolean) => void;
  children?: ReactNode;
}

/**
 * Controls the X position of the drag handle within the slider bar
 * @returns
 */
const DragHandlePosition = ({
  value,
  maxValue,
  onDragChange,
  children,
  isDisabled = false,
}: DragHandlePositionProps) => {
  return (
    <div
      className='relative top-[-12px] left-[-12px] w-[16px]'
      style={{ left: `calc(${(value / maxValue) * 100}% - 8px)`, zIndex: '1' }}
      onMouseDown={() => {
        if (isDisabled) {
          return;
        }
        onDragChange(true);
      }}
      onTouchStart={() => {
        if (isDisabled) {
          return;
        }
        onDragChange(true);
      }}
    >
      {children}
    </div>
  );
};

/**
 * The semi-transparent outer ring of the drag handle
 * Will resize when the user hovers over the hit area of the drag handle
 * @returns
 */
const DragHandleOuterRing = ({ isDisabled = false }: DisabledProps) => {
  return (
    <div
      data-disabled={isDisabled}
      className={cx(
        ' m-auto origin-center ease-in-out scale-100 data-[disabled=false]:group-hover:scale-110 data-[disabled=false]:group-active:scale-110 duration-500 ',
        'rounded-full',
        'absolute ', // Center the ring around the center dot
        'h-[28px] w-[28px]',
        ' bg-dark/action/secondary/normal/[.2] data-[disabled=true]:bg-dark/action/secondary/normal/[.1]'
      )}
    />
  );
};

/**
 * The center dot of the drag handle.
 * This aspect of the design also covers the hard transition of the "filled" to "unfilled" portion of the slider bar
 * @returns
 */
const DragHandleCenterDot = ({ isDisabled = false }: DisabledProps) => {
  return (
    <div
      data-disabled={isDisabled}
      className='data-[disabled=false]:dark:text-dark/content/secondary data-[disabled=true]:dark:text-dark/content/tertiary w-[16px] duration-500 ease-in-out'
    >
      <ObIcon
        color='inherit'
        icon='circle'
        size='x-small'
        classNames='w-[16px]'
      />
    </div>
  );
};

/**
 * As the slider is dragged from left to right the this will fill the right sie with a colored bg (Hard-coded green at the moment)
 * @returns
 */
const FilledSliderBg = ({
  isInitialized,
  value,
  minValue,
  maxValue,
}: {
  /**
   * The current value of the slider
   */
  value: number;
  /**
   * The minimum value of the slider
   */
  minValue: number;
  /**
   * The maximum value of the slider
   */
  maxValue: number;
  /**
   * Controls the fade in animation of the slider bar filled bg
   */
  isInitialized: boolean;
}) => {
  return (
    <div
      className={cx(
        'bg-money-gradient h-[8px] w-[20px] relative rounded-l duration-500 ease-in-out',
        !isInitialized ? 'transition-all' : 'transition-opacity' //After the initial load, we want to disable the transition to prevent the bar from lagging behind the slider when the value changes
      )}
      style={{
        width: `${((value - minValue) / (maxValue - minValue)) * 100}%`,
        opacity: `${value != null && value != minValue ? 1 : 0}`,
      }}
    />
  );
};

export interface ObSliderSegment {
  startsAtValue: number;
  endsAtValue: number;
  decoration?: ReactNode;
}

export interface ObBudgetSliderBaseProps extends DisabledProps {
  /**
   * The current value of the slider
   */
  value: number;
  /**
   * The value of the slider when it is fully set to the left
   */
  minValue?: number;
  /**
   * The value of the slider when it is fully set to the right
   */
  maxValue: number;
  /**
   * Each Slider Segment can have some "Decoration" that is displayed below the slider
   * when that segment is "Active" (The slider is within the range of that segment).
   * This value controls if the decoration is shown or not from a global level
   */
  showActiveSegmentDecoration?: boolean;

  /**
   * Callback that is called when the value of the slider changes
   * The parent manages the state of the slider it is responsible for updating the value of the slider
   * and passing that value back to the slider component via the value prop
   */
  onChangeCallback?: (value: number) => any;

  /**
   * Array of segments that will be displayed on the slider
   * Each segment will have a start and end value that will determine the range of the segment.
   * If no segments are provided the slider will default to a single segment that spans the entire slider
   */
  segments?: Array<ObSliderSegment>;

  /**
   * Used if the slider depends on an async source to load it's min, max or current value.
   * Will prevent the slider from fully rendering until the async source has loaded
   */
  isLoading: boolean;
}

export const ObSliderBase = ({
  onChangeCallback,
  value,
  minValue = 0, // Default to 0 if minValue is not provided
  maxValue,
  segments = [],
  showActiveSegmentDecoration = true,
  isDisabled = false,
  isLoading = false,
}: ObBudgetSliderBaseProps) => {
  const [componentWidth, setComponentWidth] = useState<number>();

  /**
   * We use a ref to get access to the javascript touch / mouse events to update the value of the slider as its dragged
   * One current limitation of this calculation is that we do not take into account the offset of where the user clicks on the slider
   * from the center of the slider handle. This has the effect of immediately jumping the slider to the position of the mouse.
   * We should fix this as a future optimization once we have time
   */
  const rangeRef = useRef<HTMLDivElement>(null);

  /**
   * Used to add event listeners to the slider container to listen for keyboard events
   */
  const sliderRef = useRef<HTMLDivElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      // Initialize ResizeObserver
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentRect) {
            setComponentWidth(entry.contentRect.width);
          }
        }
      });

      // Observe the container
      resizeObserver.observe(containerRef.current);

      // Set initial width
      setComponentWidth(containerRef.current.offsetWidth);

      // Cleanup on unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const [isInitialized, setIsInitialized] = useState(
    value != null && value != 0 && !isLoading
  );

  /**
   * Boolean that indicates that the user is currently dragging the slider wither their mouse our finger
   */
  const [isDragging, setIsDragging] = useState(false);

  const updateValue = useCallback(
    (clientX: number) => {
      if (rangeRef.current) {
        const rangeRect = rangeRef.current.getBoundingClientRect();
        const newValue = Math.max(
          minValue,
          Math.min(
            maxValue,
            ((clientX - rangeRect.left) / rangeRect.width) * maxValue
          )
        );
        if (onChangeCallback) {
          onChangeCallback(newValue);
        }
      }
    },
    [minValue, maxValue, onChangeCallback]
  );

  /**
   * Used to track the first load of the slider.
   * This timeout matches the transition duration of the slider bar background.
   * We want the bar to animate up to the value when it first loads but we don't want it to animate when the value changes.
   * This timeout allows the bar to animate up to the value when it first loads and then disables the transition.
   */
  useEffect(() => {
    if (isLoading && isInitialized) {
      setIsInitialized(false);
      return;
    }

    if (value != null && value != minValue && !isInitialized && !isLoading) {
      setIsInitialized(true);
    }
  }, [value, minValue, isInitialized, isLoading]);

  /**
   * Maintain the cursor state when dragging the slider
   */
  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'ew-resize';
    } else {
      document.body.style.cursor = 'auto';
    }
  }, [isDragging]);

  const handleKeyboardEvents = useCallback(
    (e: KeyboardEvent) => {
      //Ignore keyboard events if the slider is disabled or the user is dragging the slider
      if (isDisabled) {
        return;
      }
      if (isDragging) {
        return;
      }

      switch (e.key) {
        case 'ArrowLeft':
        case 'ArrowDown':
          onChangeCallback?.(Math.max(value - 5, minValue));
          break;
        case 'ArrowRight':
        case 'ArrowUp':
          onChangeCallback?.(Math.min(value + 5, maxValue));
          break;
        case 'Home':
          onChangeCallback?.(0);
          break;
        case 'End':
          onChangeCallback?.(maxValue);
          break;
      }
    },
    [isDisabled, isDragging, onChangeCallback, value, minValue, maxValue]
  );

  /**
   * Handle drag with a mouse
   */
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        updateValue(e.clientX);
      }
    },
    [isDragging, updateValue]
  );

  /**
   * Handle drag on touch devices
   */
  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (isDragging && e.touches.length) {
        updateValue(e.touches[0].clientX);
      }
    },
    [isDragging, updateValue]
  );

  /**
   * Handle when a user stops dragging with a mouse
   */
  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  /**
   * Handle when a user stops dragging with their finger
   */
  const handleTouchEnd = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  useEffect(() => {
    /**
     * Add Event Listeners for the following:
     * •	Arrow Left / Arrow Down: Decrease the slider value by a small amount.
     * •	Arrow Right / Arrow Up: Increase the slider value by a small amount.
     * •	Home: Sets the slider value to the minimum.
     * •	End: Sets the slider value to the maximum.
     */

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [
    handleKeyboardEvents,
    handleMouseMove,
    handleMouseUp,
    handleTouchEnd,
    handleTouchMove,
  ]);

  useEffect(() => {
    const sliderElement = sliderRef.current;

    if (sliderElement) {
      sliderElement.addEventListener('keydown', handleKeyboardEvents);

      return () => {
        sliderElement.removeEventListener('keydown', handleKeyboardEvents);
      };
    }
  }, [handleKeyboardEvents]);

  useEffect(() => {
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  }, [value, onChangeCallback]);

  return (
    <>
      <div
        ref={sliderRef}
        role='slider'
        tabIndex={0}
        aria-valuenow={value}
        aria-valuemax={maxValue}
        aria-valuemin={0}
        aria-orientation='horizontal'
        className='slider-content  select-none flex flex-1 flex-col w-full min-w-0  focus:outline-none focus:shadow-none focus-visible:shadow-interactive'
      >
        <div
          ref={containerRef}
          data-testid='slider-container'
          className='flex flex-1 self-stretch w-full '
        />
        <SliderBG
          maxValue={maxValue}
          segments={segments}
          showActiveSegmentDecoration={showActiveSegmentDecoration}
          componentWidth={componentWidth ?? 0}
        />
        <SliderControl
          ref={rangeRef}
          isInitialized={isInitialized}
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          setIsDragging={setIsDragging}
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
};

interface SliderControlProps extends DisabledProps {
  value: number;
  minValue: number;
  maxValue: number;
  isInitialized: boolean;
  setIsDragging: (value: boolean) => void;
}

const SliderControl = forwardRef(
  (
    {
      isInitialized,
      value,
      minValue,
      maxValue,
      setIsDragging,
      isDisabled = false,
    }: SliderControlProps,
    rangeRef: Ref<HTMLDivElement>
  ) => {
    return (
      <div className='relative flex flex-1 self-stretch'>
        <div
          className='absolute transition-opacity duration-200 ease-in-out'
          style={{
            opacity: `${isInitialized ? 1 : 0}`,
            width: `100%`,
            left: '0px',
            top: '-36px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <UnfilledSliderBar ref={rangeRef}>
            <FilledSliderBg
              isInitialized={isInitialized}
              value={value}
              minValue={minValue}
              maxValue={maxValue}
            />
            <DragHandle
              value={value}
              maxValue={maxValue}
              onDragChange={setIsDragging}
              isDisabled={isDisabled}
            />
          </UnfilledSliderBar>
        </div>
      </div>
    );
  }
);

const sortSegments = (segments: Array<ObSliderSegment> = []) => {
  return [...segments].sort((a, b) => a.startsAtValue - b.startsAtValue);
};

/**
 *
 * Controls the rounded rectangle that is displayed around the slider control
 * @returns
 */
const SliderBG = ({
  maxValue,
  segments,
  showActiveSegmentDecoration,
  componentWidth,
}: {
  maxValue: number;
  componentWidth: number;
  segments: Array<ObSliderSegment>;
  showActiveSegmentDecoration: boolean;
}) => {
  /**
   * We need to sort the segments by their starting value so we can render them in the correct order
   */
  const sortedSegments = sortSegments(segments);

  return (
    <div className='flex flex-1 self-stretch w-full flex-row flex-nowrap'>
      <LeftEndCap />
      <SegmentBGs
        maxValue={maxValue}
        showActiveSegmentDecoration={showActiveSegmentDecoration}
        sortedSegments={sortedSegments}
        componentWidth={componentWidth}
      />
      <RightEndCap totalSegments={Math.max(sortedSegments.length, 1)} />
    </div>
  );
};
