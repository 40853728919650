import {
  AddressResource,
  CreatePhysicalLocationResourceRequest,
} from '@outbound/types';
import PhysicalLocation from '../../../../../../state/mobx-experiment/business-context/location/physical-location/physical-location';

export interface BrickAndMortarFormValues {
  name: string;
  description: string;
  website: string;
  address: AddressResource;
  phone: string;
}

export const mapFormValuesToApiResource = (
  values: BrickAndMortarFormValues
): CreatePhysicalLocationResourceRequest => {
  return {
    name: values.name,
    description: values.description,
    website: values.website,
    phone: values.phone,
    address: values.address,
    type: 'PHYSICAL',
  };
};

/**
 * Converts a Physical Location to a shape that can be used to populate the create / edit service area form
 * @param resource
 * @returns
 */
export const mapApiResourceToFormValues = (
  resource: PhysicalLocation
): BrickAndMortarFormValues => {
  return {
    name: resource.name,
    description: resource.description ?? '',
    website: resource.website ?? '',
    phone: resource.phone ?? '',
    address: {
      city: resource.city ?? '',
      state: resource.state ?? '',
      street1: resource.addressLine1 ?? '',
      street2: resource.addressLine2 ?? '',
      country: resource.country ?? '',
      postalCode: resource.postalCode ?? '',
    },
  };
};
