import {
  EnumRuleBuilderFieldSchema,
  RuleBuilderFieldDataType,
  RuleBuilderSchema,
} from '../../core/rule-builder/rule-builder.type';

const createSchemaFieldForEnumType = (
  key: string,
  label: string,
  options: Array<{ value: string; label: string }>
): EnumRuleBuilderFieldSchema => {
  return {
    key,
    dataType: RuleBuilderFieldDataType.ENUM,
    label,
    dataTypeConfig: {
      options: options,
    },
  };
};

export const PERSONA_TARGETING_SCHEMA: RuleBuilderSchema = {
  fields: [
    {
      key: 'AGE_FIELD',
      dataType: RuleBuilderFieldDataType.NUMBER,
      label: 'Age',
    },
    createSchemaFieldForEnumType('GENDER_FIELD', 'Gender', [
      { value: 'GENDER_MALE', label: 'Male' },
      { value: 'GENDER_FEMALE', label: 'Female' },
    ]),
    createSchemaFieldForEnumType('HOUSEHOLD_STATUS_FIELD', 'Household Status', [
      { value: 'STATUS_OWN', label: 'Own' },
      { value: 'STATUS_RENT', label: 'Rent' },
      { value: 'STATUS_LIVES_WITH_PARENTS', label: 'Lives with Parents' },
    ]),
    {
      key: 'YEARLY_INCOME_FIELD',
      dataType: RuleBuilderFieldDataType.NUMBER,
      label: 'Yearly Household Income',
    },
    {
      key: 'AGE_OF_CHILDREN_IN_HOUSEHOLD_FIELD',
      dataType: RuleBuilderFieldDataType.NUMBER,
      label: 'Age of Children in Household',
    },
    {
      key: 'NUMBER_OF_CHILDREN_IN_HOUSEHOLD_FIELD',
      dataType: RuleBuilderFieldDataType.NUMBER,
      label: 'Number of Children in Household',
    },
    createSchemaFieldForEnumType(
      'RELATIONSHIP_STATUS_FIELD',
      'Relationship Status',
      [
        { value: 'RElATIONSHIP_STATUS_SINGLE', label: 'Single' },
        { value: 'RElATIONSHIP_STATUS_MARRIED', label: 'Married' },
        { value: 'RElATIONSHIP_STATUS_DIVORCED', label: 'Divorced' },
        { value: 'RElATIONSHIP_STATUS_WIDOWED', label: 'Widowed' },
        { value: 'RElATIONSHIP_STATUS_SEPARATED', label: 'Separated' },
        {
          value: 'RElATIONSHIP_STATUS_IN_A_RELATIONSHIP',
          label: 'In a relationship',
        },
        { value: 'RElATIONSHIP_STATUS_ENGAGED', label: 'Engaged' },
      ]
    ),
    createSchemaFieldForEnumType(
      'EMPLOYMENT_STATUS_FIELD',
      'Employment Status',
      [
        { value: 'EMPLOYMENT_STATUS_EMPLOYED', label: 'Employed' },
        {
          value: 'EMPLOYMENT_STATUS_SELF_EMPLOYED_BUSINESS_OWNER',
          label: 'Self Employed Business Owner',
        },
        { value: 'EMPLOYMENT_STATUS_GIG_WORKER', label: 'Gig Worker' },
        { value: 'EMPLOYMENT_STATUS_UNEMPLOYED', label: 'Unemployed' },
        { value: 'EMPLOYMENT_STATUS_HOMEMAKER', label: 'Homemaker' },
        { value: 'EMPLOYMENT_STATUS_STUDENT', label: 'Student' },
        { value: 'EMPLOYMENT_STATUS_RETIRED', label: 'Retired' },
        { value: 'EMPLOYMENT_STATUS_PART_TIME', label: 'Part-Time' },
      ]
    ),
  ],
};
