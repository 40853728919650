import { useContext } from 'react';
import { Auth0ExtendedContext } from './auth0-extended-context';

export const useAuth0Extended = () => {
  const context = useContext(Auth0ExtendedContext);
  if (!context) {
    throw new Error(
      'useAuth0Extended must be used within an Auth0ExtendedProvider'
    );
  }
  return context;
};
