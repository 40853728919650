import { BaseTemplateValueResource } from '@outbound/types';
import { CreativeCard, CreativeCardProps } from '../../../../business-objects';

/**
 * Properties needed to populate a Preview for a specific Creative
 */
export type CreativePreviewCreativeDetailProps = Omit<
  CreativeCardProps,
  'variant'
> & {
  settings: Map<
    string,
    Pick<BaseTemplateValueResource, 'id' | 'type' | 'value' | 'obrn'>
  >;
};

export interface CreativePreviewSideBarProps {
  creatives: Array<CreativePreviewCreativeDetailProps>;
  selectedCreativeId: string | null;
}

export const CreativePreviewSideBar = ({
  creatives,
  selectedCreativeId,
}: CreativePreviewSideBarProps) => {
  return (
    <div className='flex flex-col justify-between items-start flex-1 bg-dark/background/surface px-3 py-4 m-4 rounded-lg gap-8 min-w-[200px]'>
      <div className='flex flex-col gap-1'>
        {creatives.map((creative) => {
          return (
            <div
              key={creative.id}
              data-selected={selectedCreativeId === creative.id}
              className='p-1 rounded-md data-[selected=true]:bg-dark/action/primary-v2/active'
            >
              <CreativeCard
                variant={'mini'}
                {...creative}
              />
            </div>
          );
        })}
      </div>
      <div className='flex flex-col gap-2 p-3 self-stretch'>
        {/* <label id='preview-size'>
          <ObTypography
            as='span'
            variant='subtitle2'
            color='secondary'
          >
            Preview For
          </ObTypography>
        </label> */}
        {/* <ObInputListbox
          isDisabled={!selectedCreativeId}
          options={
            campaignCreativeSlots.find((slot) => slot.slotId === activeSlotId)
              ?.creativePreviewContextOptions ?? []
          }
          value={activeCreativePreviewContext}
          onValueChangedCallback={onCreativeSizeUpdated}
          isLoading={false}
          inputId={'preview-size'}
        /> */}
      </div>
    </div>
  );
};
