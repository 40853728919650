import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';
import { ObChannelAvatar } from '../../avatars';
import { ObButton } from '../../components/elements/ob-button/ob-button';
import { ObDivider } from '../../components/elements/ob-divider/ob-divider';
import { ObLoadingSpinner } from '../../components/elements/ob-loading-spinner/ob-loading-spinner';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { SettingCardRowWithButton } from '../../components/organisms/ob-setting-card-with-button/setting-card-row-with-button';
import { BaseSection } from '../../detail-page-sections/base-section';
import { SectionPlaceholder } from '../../detail-page-sections/section-placeholder';
import { ObBadge } from '../../indicators';
import { timeAgoFromIsoString } from '../../utilities/datetime-utilities';
import { CampaignListNested } from '../campaign/campaign-list-nested';
import { CardVariant } from '../card.type';
import placeholder from './components/landing-page-placeholder.png';
import { PageTitle } from './components/page-title';
import { UrlFormatted } from './components/url-formatted';
import { LandingPageCardProps } from './landing-page-card';

interface LandingPageDetailFeatureFlags {
  ffLandingPageGoogleTagManager: boolean;
  ffLandingPageGoogleAnalytics: boolean;
  ffLandingPageMetaPixel: boolean;
  ffLandingPageAnalytics: boolean;
  ffLandingPageSslInstalled: boolean;
}

interface LandingPageDetailAttributeProps {
  /**
   * Indicates if the page has Google Tag Manager installed
   */
  googleTagManagerInstalled: boolean;
  /**
   * Indicates if the page has Google Analytics installed
   */
  googleAnalyticsInstalled: boolean;
  /**
   * Indicates if the page has a meta pixel installed
   */
  metaPixelInstalled: boolean;
  /**
   * Indicates if the page has a certificate and it is valid
   */
  sslInstalled: boolean;
  /**
   * The result of the last health check
   */
  lastHealthCheckResult: 'UP' | 'DOWN';
  /**
   * Date and time that the last health check was run at
   */
  lastHealthCheckRanAtTimestamp: string;
  /**
   * The obrns of the campaigns that are using this landing page
   * Used internally to decided if no content states should be shown or not.
   */
  campaignsUsingLandingPage: Array<string>;
  /**
   * Render Function provided by the parent to render the campaign cards.
   * This is used by the web project to wrap the cards in a mobx observer
   */
  campaignRenderFunction: (
    campaignObrn: string,
    variant: CardVariant
  ) => ReactNode | null;
}

/**
 * Call to Actions that specifically appear on the Landing Page Detail Page
 * (Call to Actions that are on the Card are inherited from the Landing Page Card Props)
 */
interface LandingPageDetailCtaProps {
  /**
   * Callback called when the user wants to archive the landing page
   */
  onArchiveLandingPage?: () => void;
  /**
   * Callback called when the user wants to delete the landing page
   */
  onDeleteLandingPage: () => void;
  /**
   * Callback called when the user wants to scan the landing page
   */
  onHealthCheckLandingPage: () => void;
}

export interface LandingPageDetailProps
  extends Omit<LandingPageCardProps, 'onCardClickedCallback' | 'variant'>,
    LandingPageDetailCtaProps,
    LandingPageDetailAttributeProps,
    LandingPageDetailFeatureFlags {}
export const LandingPageDetail = (props: LandingPageDetailProps) => {
  return (
    <div className='flex flex-col gap-8 pb-10'>
      <HeroSection {...props} />
      <HealthSection {...props} />
      <AnalyticsSection {...props} />
      <LeadsFromLandingPageSection />
      {/* Phase 3  https://www.notion.so/join-outbound/Has-0-or-More-Page-Sections-13c040e853e2800bab02d2f01d775e7a?pvs=4*/}
      {/* <FeaturedSectionsSection /> */}
      <CampaignsUsingLandingPageSection {...props} />
      <SettingsSection {...props} />
    </div>
  );
};

export const HeroSection = ({
  pageTitle,
  url,
  landingPageType,
  landingPageScreenshotPublicUrl,
  onPreviewCallback,
}: LandingPageDetailProps) => {
  return (
    <div className='flex flex-col gap-2 p-3 min-h-[36px]'>
      <div className='relative overflow-hidden rounded-sm'>
        <div
          className={cx(
            'w-full bg-no-repeat bg-top bg-cover h-[288px] rounded ',
            landingPageScreenshotPublicUrl == null && ' opacity-60'
          )}
          style={{
            backgroundImage: `url(${
              landingPageScreenshotPublicUrl ?? placeholder
            })`,
          }}
        />
        {landingPageScreenshotPublicUrl == null && (
          <div className='absolute bottom-0 right-0 '>
            <div className='flex flex-row gap-2 justify-center p-2'>
              <ObLoadingSpinner
                size={16}
                includeBrand={false}
              />
              <ObTypography
                variant='subtitle2'
                color='secondary'
              >
                Generating Thumbnail
              </ObTypography>
            </div>
          </div>
        )}
      </div>
      <div className='ml-[-24px] left-[-8px]'>
        <PageTitle
          title={pageTitle}
          landingPageType={landingPageType}
        />
      </div>
      <UrlFormatted url={url} />
      <div className='flex flex-row justify-end items-center gap-3'>
        <ObButton
          variant='ghost'
          iconLeft='externalLink'
          buttonType='icon'
          size='medium'
          onClick={onPreviewCallback}
          label='Preview'
        />
      </div>
    </div>
  );
};

export const HealthSection = ({
  lastHealthCheckResult,
  lastHealthCheckRanAtTimestamp,
  sslInstalled,
}: Pick<
  LandingPageDetailAttributeProps,
  'lastHealthCheckResult' | 'lastHealthCheckRanAtTimestamp' | 'sslInstalled'
>) => {
  return (
    <BaseSection
      heading={'Page Health'}
      subheading={
        'Provides Information about if you page is available and secure'
      }
    >
      <div className='flex flex-col gap-4 border border-dark/border/default/normal rounded-lg p-4 flex-1 self-stretch'>
        <div className='flex flex-row gap-2 justify-between items-center'>
          <ObTypography variant='body2'>Status</ObTypography>
          {lastHealthCheckResult === 'UP' ? (
            <ObBadge
              level='SUCCESS'
              content='Page Healthy'
              variant='rounded'
            />
          ) : (
            <ObBadge
              level='ERROR'
              content='Page Down'
              variant='rounded'
            />
          )}
        </div>
        <ObDivider />
        <div className='flex flex-row gap-2 justify-between items-center'>
          <ObTypography variant='body2'>Page Security</ObTypography>
          {lastHealthCheckResult === 'UP' ? (
            sslInstalled ? (
              <ObBadge
                level='SUCCESS'
                content='SSL Installed'
                variant='rounded'
              />
            ) : (
              <ObBadge
                level='WARNING'
                content='SSL Not Installed'
                variant='rounded'
              />
            )
          ) : (
            <ObBadge
              level='NEUTRAL'
              content='Unknown'
              variant='rounded'
            />
          )}
        </div>
        <ObDivider />
        <div className='flex flex-row gap-2 justify-between items-center'>
          <ObTypography variant='body2'>Last Check</ObTypography>
          <ObTypography
            variant='body2'
            color='secondary'
          >
            {lastHealthCheckRanAtTimestamp == null ||
            lastHealthCheckRanAtTimestamp.trim() === ''
              ? 'Never'
              : timeAgoFromIsoString(lastHealthCheckRanAtTimestamp)}
          </ObTypography>
        </div>
      </div>
    </BaseSection>
  );
};

/**
 * Shows the installed tracking code on the Landing Page specifically Google Analytics, Meta Pixel, etc.
 */
export const AnalyticsSection = ({
  googleTagManagerInstalled,
  googleAnalyticsInstalled,
  metaPixelInstalled,
  ffLandingPageAnalytics,
  ffLandingPageGoogleAnalytics,
  ffLandingPageGoogleTagManager,
  ffLandingPageMetaPixel,
}: Pick<
  LandingPageDetailAttributeProps,
  | 'googleTagManagerInstalled'
  | 'googleAnalyticsInstalled'
  | 'metaPixelInstalled'
> &
  Pick<
    LandingPageDetailFeatureFlags,
    | 'ffLandingPageAnalytics'
    | 'ffLandingPageGoogleAnalytics'
    | 'ffLandingPageGoogleTagManager'
    | 'ffLandingPageMetaPixel'
  >) => {
  if (!ffLandingPageAnalytics) {
    return <></>;
  }

  return (
    <BaseSection
      heading={'Analytics & Attribution'}
      subheading={'Advertising Tracking Code Detected on this Landing Page'}
    >
      <div className='flex flex-col gap-4 border border-dark/border/default/normal rounded-lg p-4 flex-1 self-stretch'>
        {ffLandingPageGoogleAnalytics && (
          <>
            <div className='flex flex-row gap-2 justify-between items-center'>
              <div className='flex flex-row gap-2'>
                <ObChannelAvatar
                  channel={'GOOGLE_SEARCH_ADS'}
                  size={'x-small'}
                />
                <ObTypography variant='body1'>Google Analytics</ObTypography>
              </div>
              {googleAnalyticsInstalled ? (
                <ObBadge
                  level='SUCCESS'
                  content='Installed'
                  variant='rounded'
                />
              ) : (
                <ObBadge
                  level='NEUTRAL'
                  content='Not Installed'
                  variant='rounded'
                />
              )}
            </div>
            <ObDivider />
          </>
        )}
        {ffLandingPageGoogleTagManager && (
          <>
            <div className='flex flex-row gap-2 justify-between items-center'>
              <div className='flex flex-row gap-2'>
                <ObChannelAvatar
                  channel={'GOOGLE_SEARCH_ADS'}
                  size={'x-small'}
                />
                <ObTypography variant='body1'>Google Tag Manager</ObTypography>
              </div>
              {googleTagManagerInstalled ? (
                <ObBadge
                  level='SUCCESS'
                  content='Installed'
                  variant='rounded'
                />
              ) : (
                <ObBadge
                  level='NEUTRAL'
                  content='Not Installed'
                  variant='rounded'
                />
              )}
            </div>
            <ObDivider />
          </>
        )}
        {ffLandingPageMetaPixel && (
          <>
            <div className='flex flex-row gap-2 justify-between items-center'>
              <div className='flex flex-row gap-2'>
                <ObChannelAvatar
                  channel={'FACEBOOK_ADS'}
                  size={'x-small'}
                />
                <ObTypography variant='body1'>Meta Pixel</ObTypography>
              </div>
              {metaPixelInstalled ? (
                <ObBadge
                  level='SUCCESS'
                  content='Installed'
                  variant='rounded'
                />
              ) : (
                <ObBadge
                  level='NEUTRAL'
                  content='Not Installed'
                  variant='rounded'
                />
              )}
            </div>
          </>
        )}
      </div>
    </BaseSection>
  );
};

/**
 * Renders a list of Campaigns that are currently using the Landing Page.
 * Campaigns are black-boxed in this component. We expect that parent components will
 * render them appropriately.
 */
export const CampaignsUsingLandingPageSection = ({
  campaignsUsingLandingPage,
  campaignRenderFunction,
}: Pick<
  LandingPageDetailProps,
  'campaignsUsingLandingPage' | 'campaignRenderFunction'
>) => {
  return (
    <BaseSection
      heading={'Campaigns Using Landing Page'}
      subheading={'Shows All Campaigns currently using this Landing Page'}
      showPlaceholder={
        campaignsUsingLandingPage == null ||
        campaignsUsingLandingPage.length === 0
      }
      placeholderNode={
        <SectionPlaceholder
          heading='No Campaigns Using Landing Page'
          message='This Landing Page is not currently being used in any Campaigns.'
          icon='lock'
          documentationOnClick={() => {
            console.log('Go to documentation');
          }}
        />
      }
    >
      <CampaignListNested
        campaignObrns={campaignsUsingLandingPage}
        campaignRenderFunction={campaignRenderFunction}
      />
    </BaseSection>
  );
};

/**
 * Renders any Leads that were generated from the Landing Page.
 * This section will need to be expanded when we implement Leads.
 */
export const LeadsFromLandingPageSection = () => {
  return (
    <BaseSection
      heading={'Leads From Landing Page'}
      subheading={'Recent Leads Generated from this Landing Page'}
      showPlaceholder={true}
      placeholderNode={
        <SectionPlaceholder
          heading='Outbound Leads Not Supported on Self Hosted Pages'
          message='Lead capture is only available on Outbound Landing Pages.'
          icon='lock'
          documentationOnClick={() => {
            console.log('Go to documentation');
          }}
        />
      }
    ></BaseSection>
  );
};

/**
 * Renders a checkbox group all available sections on the landing page.
 * Users can select which ones should be optionally featured on Ads that use the Landing Page.
 *
 * Placeholder Implementation for Future Phase when we Implement Site Links.
 */
export const FeaturedSectionsSection = () => {
  return (
    <div className='flex flex-col self-stretch gap-4'>
      <ObTypography variant='h3'>Featured Sections</ObTypography>
      {/* TODO IMPLEMENT CHECKBOX GROUP WITH SECTION NAMES */}
    </div>
  );
};

/**
 * Renders the Settings Section of the Detail Page
 * @returns
 */
export const SettingsSection = ({
  onArchiveLandingPage,
  onDeleteLandingPage,
}: Pick<
  LandingPageDetailCtaProps,
  'onArchiveLandingPage' | 'onDeleteLandingPage'
>) => {
  return (
    <BaseSection
      heading={'Settings'}
      subheading={'Manage the settings for this Landing Page'}
    >
      <div className='flex flex-col gap-4'>
        {onArchiveLandingPage && (
          <SettingCardRowWithButton
            title='Archive Landing Page'
            body='Archiving this Landing Page will prevent it from being used in any new Campaigns. Since the Landing Page is self hosted it will not impact the page itself only the use of it in Outbound.'
            buttonLabel='Archive Landing Page'
            handleButtonClickCallback={onArchiveLandingPage}
            color={'error'}
            loading={false}
          />
        )}
        {onDeleteLandingPage && (
          <SettingCardRowWithButton
            title='Delete Landing Page'
            body='Deleting this Landing Page will complete remove it from Outbound. Since the Landing Page is self hosted it will not impact the page itself only the use of it in Outbound.'
            buttonLabel='Delete Landing Page'
            handleButtonClickCallback={onDeleteLandingPage}
            color={'error'}
            loading={false}
          />
        )}
      </div>
    </BaseSection>
  );
};
