import { ReactNode } from 'react';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObBadge } from '../../indicators';
import { CardVariantDeprecatedProps } from '../card.type';

interface CampaignLocationAttributeProps {
  /**
   * Indicates if the location is currently enabled for the campaign
   */
  isEnabled: boolean;

  locationId: string;
}

interface CampaignLocationActionProps {
  onIsEnabledChanged: (isEnabled: boolean) => void;
  onRemoveLocationFromCampaignClicked: () => void;
}

/**
 * React Nodes for each "Slot" of Black Boxed Embedded Content in the Campaign Location Card
 */
interface CampaignLocationBlackBoxedEmbeddedContentProps {
  /**
   * React Node that renders the highlighted object
   */
  locationNode?: ReactNode;
}

/**
 * Public Interface for the Campaign Location Card
 */
export interface CampaignLocationCardProps
  extends CardVariantDeprecatedProps,
    CampaignLocationActionProps,
    CampaignLocationAttributeProps,
    CampaignLocationBlackBoxedEmbeddedContentProps {}

/**
 * Interface passed to different variants of this card
 */
export type CampaignLocationImplementationProps = Omit<
  CampaignLocationCardProps,
  'variant'
>;

export const CampaignLocationCard = ({
  variant,
  ...props
}: CampaignLocationCardProps) => {
  /**
   * Unique ID for the campaign highlight that can be used as a base for other identifiers such as labels.
   */
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'micro':
    case 'mini':
    case 'list':
    case 'detail':
      return (
        <ObTypography color='negative'>
          Campaign Location <span>{variant}</span> Card Not Implemented
        </ObTypography>
      );
  }
};

/**
 * Renders the Grid Variant of the Campaign Location Card.
 * This card is a subtle wrapper around a location card that adds some
 * additional Campaign <-> Location relationship attributes and CTAs.
 * @returns
 */
const GridCard = ({
  locationId,
  locationNode,
  isEnabled,
}: CampaignLocationImplementationProps) => {
  return (
    <div className='flex flex-col-reverse relative'>
      {/* RENDER LOCATION CARD HERE */}
      <div
        className='border border-dark/border/default/normal border-t-0 mx-2  px-2 pb-1 pt-1 rounded-b-lg '
        style={{
          background: 'linear-gradient(to bottom,  #121422, #1b1d2b)',
        }}
      >
        <div
          data-location-id={locationId}
          className='flex flex-col gap-3 '
        >
          <div className='flex flex-row gap-2'>
            <ObTypography
              variant='body2'
              color='tertiary'
            >
              Advertising for this Location is
            </ObTypography>
            <ObBadge
              variant='rounded'
              content={isEnabled ? 'Enabled' : 'Disabled'}
              level={isEnabled ? 'SUCCESS' : 'NEUTRAL'}
            />
          </div>
        </div>
      </div>
      {locationNode}
    </div>
  );
};
