import { ObButton } from '@outbound/design-system';

export interface RemixFieldButtonProps {
  isDisabled: boolean;
  variant: 'icon' | 'text';
  onClickRemixCallback: () => void;
}

/**
 * Small Remix Button that is next to fields in the Creative Builder Template
 */
export const RemixFieldButton = ({
  isDisabled,
  onClickRemixCallback: onClickCallback,
  variant = 'icon',
}: RemixFieldButtonProps) => {
  return (
    <ObButton
      variant='ghost'
      buttonType={variant}
      iconLeft='magicWand'
      size='small'
      disabled={isDisabled}
      onClick={onClickCallback}
      label='Remix'
    />
  );
};
