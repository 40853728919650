import { cx } from 'class-variance-authority';
import { ReactNode, useRef } from 'react';

interface RuleContentWrapperProps {
  hasError: boolean;
  children: ReactNode;
}

export const RuleContentWrapper = ({
  hasError,
  children,
}: RuleContentWrapperProps) => {
  const ref = useRef<any>();

  return (
    <div
      ref={ref}
      data-testid='rule-content-wrapper'
      className={cx([
        'w-full h-full  flex flex-row justify-between min-h-[62px] dark:bg-bgSurfaceDark/[.84] border rounded dark:border-actionSecondaryHoverBorderDark/[.16] mb-[10px]',
        hasError && 'ring-1 ring-contextualNegativeDark border-transparent',
      ])}
    >
      {children}
    </div>
  );
};
