import {
  ListResponse,
  PutWorkspaceMemberRoleRequestResource,
  WorkspaceMemberPathParameters,
  WorkspaceMemberResource,
} from '@outbound/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

/**
 * Get /workspace/members
 * Query list all members for the current workspace
 * @returns
 */
export const useFetchWorkspaceMembers = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  return useQuery<undefined, undefined, ListResponse<WorkspaceMemberResource>>(
    ['workspace:members:get'],
    async () => {
      const response = await auth0AxiosClient.get('/workspace/members');
      return response.data;
    }
  );
};

/**
 * DELETE /workspace/members/:memberId
 * Mutation to remove an members by it's id from the current workspace
 * @returns
 */
export const useRemoveMember = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const queryClient = useQueryClient();
  return useMutation(
    ['workspace:members:delete'],
    async (memberId: string) => {
      const response = await auth0AxiosClient.delete(
        `/workspace/members/${memberId}`
      );
      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['workspace:members:get']);
      },
    }
  );
};

/**
 * PUT /workspace/members/:memberId/role
 * Mutation to update a member's role in the current workspace
 * @returns
 */
export const useUpdateMemberRole = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();
  const queryClient = useQueryClient();
  return useMutation<
    undefined,
    undefined,
    WorkspaceMemberPathParameters & PutWorkspaceMemberRoleRequestResource
  >(
    ['workspace:members:role:update'],
    async (data) => {
      const response = await auth0AxiosClient.put(
        `/workspace/members/${data.memberId}/role`,
        {
          role: data.role,
        }
      );
      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['workspace:members:get']);
      },
    }
  );
};
