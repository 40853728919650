import React, { useEffect, useState } from 'react';
import { ObIcon } from '../../../../tokens/icons/ob-icon/ob-icon';
import { removeSparkClasses } from '../../spark';
import { GoogleDisplayAdSizeConfig } from '../google-display-ad-sizes';
import './../../spark.css';
import './styles.css';

export interface CreativeAdProps {
  heading: string[] | undefined;
  callToActionButtonLabel: string | undefined;
  primaryColorHex: string | undefined;
  secondaryColorHex: string | undefined;
  logoImageUrl: string | undefined;
  adImages: string[] | undefined;
  adUrl: string | undefined;
  isAnimated: boolean;
  adDesignGroup: string | undefined;
  adSize: string | undefined;
  playback: 'play' | 'pause';
  loop: boolean;
}

export const GoogleDisplayAd: React.FC<CreativeAdProps> = ({
  heading,
  callToActionButtonLabel,
  primaryColorHex,
  secondaryColorHex,
  logoImageUrl,
  adImages,
  adUrl,
  isAnimated,
  adDesignGroup,
  adSize,
  playback,
}: CreativeAdProps) => {
  const adSizeStyle = (
    GoogleDisplayAdSizeConfig as Record<
      string,
      { height: string; width: string }
    >
  )[adSize ?? 'square'];

  const [headingIndex, setHeadingIndex] = useState(0);
  const [adImageIndex, setAdImageIndex] = useState(0);
  const [addAnimationClass, setAddAnimationClass] = useState(false);
  const rotationInterval = 4000;
  const animationDuration = 2000;
  const totalAnimationTime = rotationInterval + animationDuration;
  const adjustedInterval = totalAnimationTime;

  const [isHovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    if (playback !== 'pause') {
      const rotateContent = () => {
        setHeadingIndex(
          (prevIndex) => (prevIndex + 1) % (heading?.length ?? 1)
        );
        setAdImageIndex(
          (prevIndex) => (prevIndex + 1) % (adImages?.length ?? 1)
        );
        setAddAnimationClass(true);

        setTimeout(() => {
          setAddAnimationClass(false);
        }, animationDuration);
      };

      const intervalId = setInterval(() => {
        rotateContent();
      }, adjustedInterval);

      return () => clearInterval(intervalId);
    }
  }, [
    heading,
    adImages?.length,
    adjustedInterval,
    animationDuration,
    playback,
  ]);

  useEffect(() => {
    if (!isAnimated) {
      const elementsWithSparkClass = document.querySelectorAll('.ob-spark');
      elementsWithSparkClass.forEach((element) => {
        removeSparkClasses(element as HTMLElement);
      });
    }
  }, [isAnimated]);

  const buttonStyle = {
    color: isHovered ? secondaryColorHex : '',
    transition: '0.25s all ease-in-out',
  };

  let adGroupComponent = null;

  switch (adDesignGroup) {
    case 'base':
      adGroupComponent = (
        <>
          <div
            id='featured-photo'
            className={`${addAnimationClass ? 'ob-spark-slideIn' : ''}`}
            style={{ backgroundImage: `url(${adImages?.[adImageIndex]})` }}
          ></div>
          <div
            id='base'
            className=''
          >
            <img
              src={logoImageUrl}
              id='logo'
              className={`${addAnimationClass ? 'ob-spark-fadeIn' : ''}`}
              alt='Business Logo'
            />
            <div className='text'>
              <h1
                className={`text-highlight ${
                  addAnimationClass ? 'ob-spark-fadeIn' : ''
                }`}
                style={{ color: `${primaryColorHex}` }}
              >
                {heading?.[headingIndex]}
              </h1>
            </div>
            <a
              href={adUrl}
              id='btn'
              className={`${addAnimationClass ? 'ob-spark-fadeIn' : ''}`}
              style={buttonStyle}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
            >
              {callToActionButtonLabel}
              <ObIcon
                icon='chevronRight'
                size='small'
                color='inherit'
              />
            </a>
          </div>
        </>
      );
      break;

    case 'wideRectangles':
      adGroupComponent = <>{/* Wide Rectangles */}</>;
      break;

    case 'tallRectangles':
      adGroupComponent = <>{/* Tall Rectangles */}</>;
      break;

    case 'mobile':
      adGroupComponent = <>{/* Mobile */}</>;
      break;

    default:
      adGroupComponent = <>{/* Default */}</>;
      break;
  }

  return (
    <div
      id='multiscene'
      className={isAnimated && playback !== 'pause' ? 'ob-spark-animated' : ''}
      data-testid='multi-scene-google-display-ad'
      style={{
        backgroundColor: `${primaryColorHex}`,
        height: `${adSizeStyle.height}`,
        width: `${adSizeStyle.width}`,
      }}
    >
      {adGroupComponent}
    </div>
  );
};
