import { PersonaResource } from '@outbound/types';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import CustomerProfile from './customer-profile';
import CustomerProfileTransformer from './customer-profile-transformer';

class CustomerProfileStore extends BaseStore<CustomerProfile> {
  private _customerProfileTransformer: CustomerProfileTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'customer-profile');
    this._customerProfileTransformer = new CustomerProfileTransformer(
      rootStore
    );
    this.rootStore.transport.customerProfileTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
  }

  protected requestLoadModelFromServer(id: string): void {
    this.rootStore.transport.customerProfileTransport.requestResourceById(id);
  }

  private handleServerUpdate = (
    id: string,
    resource: PersonaResource | null
  ): void => {
    const clientCachedModel = this.modelMap.get(id);
    if (resource == null) {
      this.updateSyncMetaForNotFoundModel(id);
      this.modelMap.delete(id);
    } else if (clientCachedModel) {
      const patch =
        this._customerProfileTransformer.createPatchForCurrentModelAndIncomingResource(
          clientCachedModel,
          resource
        );
      clientCachedModel.applyPatch(patch);
      this.updateSyncMetaForUpdatedModel(id, clientCachedModel);
    } else {
      const model = this._customerProfileTransformer.fromApiResource(resource);
      this.modelMap.set(id, model);
      this.updateSyncMetaForLoadedModel(id, model);
    }
  };
}

export default CustomerProfileStore;
